<template>
    <div class="support wrap">
        <div class="filters">
            <a href="javascript:void(0);" class="filters__item active">공지사항</a>
            <a href="javascript:void(0);" class="filters__item" @click="$goMenu('faq')">자주하는 질문</a>
            <a href="javascript:void(0);" class="filters__item" @click="openRemoteSupport()">원격지원</a>
        </div>
        <div>
            <div class="notice">
                <div class="layout__half--wrap">
                    <div class="layout__half--group notice__side-space--wrap">
                        <div class="notice__side-space--group">
                            <h2 class="menu__title">비드톡톡 공지사항</h2>
                        </div>
                    </div>
                    <div class="layout__half--group notice__cont--wrap">
                        <ul class="table__subject notice__cont--subject">
                            <li class="table__row notice__cont--row num">번호</li>
                            <li class="table__row notice__cont--row title">제목</li>
                            <li class="table__row notice__cont--row date">작성일</li>
                        </ul>
                        <ul class="table__cont--wrap notice__cont--group" v-for="(item,index) in displayedBoardList" :key="item.value">
                            <!-- 상단 고정 > li에 important 클래스 추가  -->
                            <!-- 새글 뱃지 > li에 new 클래스 추가  -->
                            <li class="table__cont--item notice__cont--item important new"  v-if="item.topNoticeYn === 'Y'" :class="{'important': true, 'new': isToday(item.firstInputDt)}"  @click="goMenu('noticeView', item.id)">
                                <span class="table__row notice__cont--row num">[공지]</span>
                                <div class="table__row notice__cont--row title"><a class="txt__title">{{ item.boardTitle }}</a></div>
                                <span class="table__row notice__cont--row date">{{$moment(item.firstInputDt).format('YY-MM-DD') }}</span>
                            </li>
                            <li class="table__cont--item notice__cont--item" v-if="item.topNoticeYn !== 'Y'" :class="{'new': isToday(item.firstInputDt)}"  @click="goMenu('noticeView', item.id)">
                                <span class="table__row notice__cont--row num">{{boardList.length - (currentPage - 1) * itemsPerPage - index }}</span>
                                <div class="table__row notice__cont--row title"><a class="txt__title">{{ item.boardTitle }}</a></div>
                                <span class="table__row notice__cont--row date">{{$moment(item.firstInputDt).format('YY-MM-DD') }}</span>
                            </li>
                        </ul>
                        <div class="pagination notice__pagination">
                            <button type="button" class="prev" :disabled="!hasPreviousPage"  @click="currentPage--"><span></span></button>
                            <ul>
                                <li v-for="pageNumber in pageNumbers" :key="pageNumber" :class="{ active: pageNumber === currentPage }">
                                    <a href="#" onclick="return false" @click="currentPage = pageNumber">{{ pageNumber }}</a>
                                </li>
                            </ul>
                            <button type="button" class="next" :disabled="!hasNextPage" @click="currentPage++"><span></span></button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Notice",
        metaInfo: {
            title: '[비드톡톡 공지사항] - 전자입찰 나라장터(g2b) 공고 투찰 낙찰',
            link: [{ rel: "canonical", href: 'https://www.bidtok.co.kr/' }],
            meta: [
                { vmid: "description", name: 'description', content: '비드톡톡에 관한 공지사항을 확인해 보세요! 비드톡톡의 업데이트 소식, 이벤트 소식, 새로운 기능의 소개, 나라장터 소식 등 비드톡톡의 중요한 공지사항을 확인하실 수 있습니다.' },
                { vmid: "og:title", property: 'og:title', content: '[비드톡톡 공지사항] - 전자입찰 나라장터(g2b) 공고 투찰 낙찰' },
                { vmid: "og:url", property: 'og:url', content: 'https://www.bidtok.co.kr/' },
                { vmid: "og:description", property: 'og:description', content: '비드톡톡에 관한 공지사항을 확인해 보세요! 비드톡톡의 업데이트 소식, 이벤트 소식, 새로운 기능의 소개, 나라장터 소식 등 비드톡톡의 중요한 공지사항을 확인하실 수 있습니다.' },
                { vmid: "og:image", property: "og:image" , content : "favicon.ico"},
            ]
        },
        data(){
            return{
                boardList : [],
                currentPage: 1,
                itemsPerPage: 10,
            }
        },

        watch: {
            boardDetail() {
                window.scrollTo({ top: 0, behavior: 'smooth' })
            },
            currentPage() {
                window.scrollTo({ top: 0, behavior: 'smooth' });
            }
        },

        computed: {

            pageNumbers() {
                const maxVisiblePages = window.innerWidth <= 800 ? 5 : 10;
                const firstVisiblePage = Math.max(1, this.currentPage - Math.floor(maxVisiblePages / 2));
                const lastVisiblePage = Math.min(this.totalPages, firstVisiblePage + maxVisiblePages - 1);
                return Array.from({ length: lastVisiblePage - firstVisiblePage + 1 }, (_, i) => firstVisiblePage + i);
            },

            //페이징 시작
            displayedBoardList() {


                //최상단고정 세팅
                // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                const sortedList = this.boardList.sort((a, b) => {
                    if (a.topNoticeYn === 'Y' && b.topNoticeYn !== 'Y') {
                        return -1;
                    } else if (a.topNoticeYn !== 'Y' && b.topNoticeYn === 'Y') {
                        return 1;
                    }
                    return 0;
                });

                const startIndex = (this.currentPage - 1) * this.itemsPerPage;
                const endIndex = startIndex + this.itemsPerPage;
                return sortedList.slice(startIndex, endIndex);

            },

            totalPages() {
                return Math.ceil(this.boardList.length / this.itemsPerPage);
            },

            hasPreviousPage() {
                return this.currentPage > 1;
            },

            hasNextPage() {
                return this.currentPage < this.totalPages;
            },
            //페이징 끝

        },

        methods: {

            goMenu(menuUrl, id) {
                if (this.$route.path != "/"+menuUrl) // 현재 route와 다른 경우만 페이지 이동
                    this.$router.push('/'+menuUrl+'/'+id);
            },


            //오늘날짜 확인
            isToday(date) {
                const today = new Date();
                const inputDate = new Date(date);
                return (
                    inputDate.getFullYear() === today.getFullYear() &&
                    inputDate.getMonth() === today.getMonth() &&
                    inputDate.getDate() === today.getDate()
                );
            },

            fnSearchBoard(){

                var params = {
                    pageUnit: 9999,
                    pageIndex: this.currentPage,
                    strtRecordIntex: (this.currentPage - 1) * this.itemsPerPage,
                    boardCd: 0,
                };

                this.$http.post('/api/board/boardlist',params)
                // eslint-disable-next-line no-unused-vars
                    .then(res => {
                        this.boardList = res.data.boardList;
                        // this.boardList = this.boardList.filter(item => item.noticeUseYn === 'Y');

                    })
                    .catch(error => {console.log(error);}
                    );

            },
            openRemoteSupport() {
                window.open("https://939.co.kr/sysoft/", "_blank", "width=865,height=700,scrollbars=yes");

            },

        },
        created() {

        },

        mounted() {
            this.fnSearchBoard();

            // 페이지네이션 클릭 이벤트
            let pagenation = document.querySelectorAll('.pagination > ul > li');
            pagenation.forEach(function (element) {
                element.addEventListener('click', function(){
                    for (let i = 0; i < pagenation.length; i++) {
                        pagenation[i].classList.remove('active');
                    }
                    element.classList.add('active');
                    window.scroll({top: 0, left: 0,behavior: 'smooth'});
                });
            });
        }
    }


</script>



<!--<style src="./NotiSuggest.scss" lang="scss" scoped />-->