<template>
    <div class="bid-helper help-home">
        <div class="help-home__wrap analysis">
            <!-- 탭메뉴 -->
            <div class="filters analysis__filter help-home__filter">
                <a href="javascript:void(0);" class="filters__item" :class="tabNo == 0 ? 'active' : ''" @click="tabNo = 0">분석 현황</a>
                <a href="javascript:void(0);" class="filters__item" :class="tabNo == 1 ? 'active' : ''" @click="tabNo = 1">개찰 결과</a>
            </div>
            <!-- /탭메뉴 -->
            <!-- 분석 현황-->
            <CustNotiComp v-show="tabNo == 0"></CustNotiComp>
            <!-- /분석 현황-->

            <!-- 개찰 결과-->
            <OpbdNotiComp v-show="tabNo == 1"></OpbdNotiComp>
            <!-- /개찰 결과-->
        </div>
    </div>
</template>
<script>
    import CustNotiComp from './CustNotiComp'
    import OpbdNotiComp from './OpbdNotiComp'

    export default {
        name: "analysis",
        metaInfo: {
            title: '[비드톡톡 분석실] - 전자입찰 나라장터(g2b) 공고 투찰 낙찰',
            link: [{rel: "canonical", href: 'https://www.bidtok.co.kr/'}],
            meta: [
                {vmid: "description", name: 'description', content: '비드톡톡의 분석실에서 분석제시가를 확인해 보세요! 고도화된 AI 분석가가 해주는 분석제시가부터 다년간 노하우를 가진 분석 기업들의 분석제시가까지 확인해 보실 수 있습니다.'},
                {vmid: "og:title", property: 'og:title', content: '[비드톡톡 분석실] - 전자입찰 나라장터(g2b) 공고 투찰 낙찰 '},
                {vmid: "og:url", property: 'og:url', content: 'https://www.bidtok.co.kr/'},
                {vmid: "og:description", property: 'og:description', content: '비드톡톡의 분석실에서 분석제시가를 확인해 보세요! 고도화된 AI 분석가가 해주는 분석제시가부터 다년간 노하우를 가진 분석 기업들의 분석제시가까지 확인해 보실 수 있습니다.'},
                {vmid: "og:image", property: "og:image", content: "favicon.ico"},
            ]
        },
        components: {
            CustNotiComp,
            OpbdNotiComp
        },
        data() {
            return {
                tabNo: 0, //탭 번호
                loginInfo: {},
            }
        },
        async mounted() {
            this.loginInfo = JSON.parse(localStorage.getItem("custInfo"));
            this.insTracking('/bidHelper/bidHelperAnalysis');

        },
        created() {
            if (localStorage.getItem("authToken") == null || localStorage.getItem("authToken") == '') {
                this.$router.replace('/bidHelperLogin');
            }
        },
        methods:{
            async insTracking(path) {
                var tracking = {
                    "trackingCd": "3",
                    "trackingAction" : "ACCESS",
                    "trackingNm": "페이지 이동",
                    "trackingDesc" : path,
                    "trackingType" : 'BIDHELPER',
                };
                this.$insertTracking(tracking);

            },
        }
    }


</script>
<style src="../../../../assets/scss/common/bid-helper.scss" lang="scss" scoped/>