<template>
    <div class="pay-info-enter" :class="isMobile ? 'rsp': ''">
        <div class="pay-info-enter--container">
            <div class="pay-info-enter--group">
                <h3 class="pay-info-enter--title">결제 정보 입력</h3>
                <form id="SendPayForm_id" name="" method="POST">
                    <input type="hidden" name="version" v-model="version"> <!--version-->
                    <input type="hidden" name="mid" value=""> <!--결제방식에 따라다름-->
                    <input type="hidden" name="mKey" value=""> <!--결제방식에 따라다름-->
                    <input type="hidden" name="oid" v-model="oid">
                    <input type="hidden" name="currency" value="WON">
                    <input type="hidden" name="languageView" value="ko">
                    <input type="hidden" name="charset" value="UTF-8">
                    <input type="hidden" name="payViewType" value="overlay">
                    <input type="hidden" name="timestamp" v-model="timestamp">
                    <input type="hidden" name="signature" v-model="signature">
                    <input type="hidden" name="offerPeriod" value=""> <!--제공기간(정확한 기간 산정이 어려워 미사용)-->
                    <input type="hidden" name="acceptmethod" v-model="acceptmethod"> <!--기타옵션-->
                    <input type="hidden" name="returnUrl" v-model="resDomainReturnUrl"> <!--결제완료시-->
                    <input type="hidden" name="closeUrl" v-model="resDomainCloseUrl"> <!--취소버튼 클릭시-->
                    <input type="hidden" name="popupUrl" v-model="resDomainPopupUrl"> <!--팝업방식 사용시(미사용)-->
                    <!--카드 사용시-->
                    <input type="hidden" name="quotabase" value=""> <!--할부 개월 설정-->
                    <input type="hidden" name="ini_onlycardcode" value=""> <!--중복 카드 코드-->
                    <input type="hidden" name="ini_cardcode" value=""> <!--카드 코드-->
                    <input type="hidden" name="ansim_quota" value=""> <!--할부 선택-->
                    <!--추가 옵션-->
                    <input type="hidden" name="merchantData" v-model="merchantData"> <!--인증결과 리턴시 함께 전달-->
                    <!--필수 옵션-->
                    <input type="hidden" name="goodname" v-model="goodNm"> <!--상품명-->
                    <input type="hidden" name="price" v-model="price"> <!--결제금액-->
                    <input type="hidden" name="gopaymethod" v-model="gopaymethod"> <!--결제방식-->

                    <ul class="info-enter ful">
                    <li class="info-enter--item">
                        <span class="info-enter--tit">결제 수단</span>
                        <div class="info-enter--cont select-box">
                            <button type="button" class="select-box__sel">가상계좌</button>
                            <ul class="select-box__list">
                                <li><button type="button" value="Card">신용카드</button></li>
                                <li><button type="button" value="VBank">가상계좌</button></li>
                            </ul>
                        </div>
                    </li>
                    <li class="info-enter--item">
                        <span class="info-enter--tit">상품명</span>
                        <p class="info-enter--cont bt-c-main">{{goodNm}}</p>
                    </li>
                    <li class="info-enter--item">
                        <span class="info-enter--tit">결제금액</span>
                        <p class="info-enter--cont bt-c-main">{{price | currency}}원</p>
                    </li>
                    <li class="info-enter--item">
                        <span class="info-enter--tit">고객명</span>
                        <div class="info-enter--cont input">
                            <input type="text" name="buyername" placeholder="회사명" v-model="buyerNm">
                        </div>
                    </li>
                    <li class="info-enter--item">
                        <span class="info-enter--tit">휴대전화</span>
                        <div class="info-enter--cont input">
                            <input type="text" name="buyertel" placeholder="예) 01012345678" v-model="buyerTel" >
                        </div>
                    </li>
                    <li class="info-enter--item">
                        <span class="info-enter--tit">이메일</span>
                        <div class="info-enter--cont input">
                            <input type="text" name="buyeremail" placeholder="이메일을 입력해주세요." v-model="buyerEmail">
                        </div>
                    </li>
                    <li class="info-enter--item">
                        <span class="info-enter--tit">사업자번호</span>
                        <div class="info-enter--cont input">
                            <input type="text" name="INIregno" class="disable" v-model="buyerBizNo">
                        </div>
                    </li>
                </ul>
                </form>
            </div>
            <a href="#" onclick="return false" class="pay-info-enter--btn btn-basic btn-cta" @click="insertPayRcptmn()">결제하기</a>
        </div>
    </div>
</template>

<script language="javascript" type="text/javascript" src="'https://stdpay.inicis.com/stdjs/INIStdPay.js" charset="UTF-8"></script>

<script>
    export default {
        data() {
            return {
                resDomain: '',
                midCard: '',
                signKeyCard: '',
                midVact: '',
                signKeyVact: '',
                requestUrl: '',
                version: '',
                rpctmnId: '',
                price: 0,
                goodNm: '',
                buyerNm: '',
                buyerTel: '',
                buyerEmail: '',
                buyerBizNo: '',
                vbankDt: '',
                oid: '',
                timestamp: '',
                signature: '',
                acceptmethod: '',
                resDomainReturnUrl: '',
                resDomainCloseUrl: '',
                resDomainPopupUrl: '',
                payRcptmnId: '',
                gopaymethod: 'VBank',
                merchantData: '',
                isMobile: false,
            }
        },
        mounted() {
            this.loginInfo = JSON.parse(localStorage.getItem("custInfo"));

            let recaptchaScript = document.createElement('script')
            recaptchaScript.setAttribute('src', 'https://stdpay.inicis.com/stdjs/INIStdPay.js')
            document.head.appendChild(recaptchaScript);
            this.getRcpmn();
            this.selectBoxScript();
            this.scrollScript();
            this.mobileScript();

        },
        methods: {
            mobileScript(){
                const userAgent = navigator.userAgent || navigator.vendor || window.opera;
                // 모바일 기기 User-Agent 문자열의 일부를 체크합니다.
                this.isMobile = /android|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(userAgent.toLowerCase());
                if(!this.isMobile){
                    document.querySelector('body').style.overflowY = 'hidden';
                }
            },
            scrollScript() {
                let fullHight = document.querySelectorAll('.ful');

                function updateVh() {
                    var vh = window.innerHeight * 0.01;
                    fullHight.forEach(function(element) {
                        element.style.setProperty('--vh', vh + 'px');
                    });
                }
                window.addEventListener('resize', updateVh);
                window.addEventListener('load', updateVh);
            },
            selectBoxScript(){
                /* 셀렉트박스 */
                const selectBox = document.querySelector('.select-box__sel');
                if (selectBox) {
                    selectBox.addEventListener('click', () => selectBox.classList.toggle('on'));

                    /* 셀렉트박스 활성화 중 다른 곳 클릭시 꺼짐 */
                    document.addEventListener('click', (event) => {
                        const target = event.target;
                        if (!selectBox.contains(target)) {
                            selectBox.classList.remove('on');
                        }
                    });
                    /* /셀렉트박스 활성화 중 다른 곳 클릭시 꺼짐 */
                }

                const selectBoxItem = document.querySelector('.select-box__list');
                if (selectBoxItem) {
                    selectBoxItem.addEventListener('click', (event) => {
                        selectBox.innerText = event.target.innerText;
                        this.gopaymethod = event.target.value;
                        selectBox.classList.remove('on');
                    });
                }
            },
            getRcpmn() {
                this.$get('/api/bilcltMngt/rcptmn/' +  this.$route.params.id)
                    .then(res => {
                        if(res.status == 'OK'){

                            this.goodNm = 'AI멤버십';
                            this.buyerNm = this.loginInfo.cmpNm;
                            this.buyerTel = this.loginInfo.custTelno.replaceAll('-', '');
                            this.buyerEmail = this.loginInfo.custEmail;
                            this.buyerBizNo = this.loginInfo.bizNo;
                            this.price = res.rcptmn.rcptmnAmt;

                            //테스트 계정일 때
                            if(this.loginInfo.bizNo < 10) {
                                this.price = 10;
                            }
                        }
                    })
                    .catch(error => {
                            console.log(error);
                        }
                    );
            },
            getPayInfo() {

                this.$get('/api/web/request/' +  this.$route.params.id)
                    .then(res => {
                        if(res.status == 'OK'){
                            var domain = window.location.protocol + '//' +  window.location.host;

                            this.resDomainReturnUrl = domain + '/api/web/response';
                            this.resDomainCloseUrl = domain + '/api/web/close';
                            this.resDomainPopupUrl = domain + '/api/web/popup';
                            this.midCard = res.midCard;
                            this.signKeyCard = res.signKeyCard;
                            this.midVact = res.midVact;
                            this.signKeyVact = res.signKeyVact;
                            this.requestUrl = res.requestUrl;
                            this.version = res.version;
                            this.rpctmnId = res.rpctmnId;
                            // this.price = res.price;
                            // this.goodNm = res.goodNm;
                            // this.buyerNm = this.loginInfo.cmpNm;
                            // this.buyerTel = this.loginInfo.custTelno;
                            // this.buyerEmail = this.loginInfo.custEmail;
                            // this.buyerBizNo = this.loginInfo.bizNo;
                            this.oid = res.oid;
                            this.timestamp = res.timestamp;
                            this.vbankDt = res.vbankDt;
                            this.signature = res.signature;
                            this.acceptmethod = "CARDPOINT:HPP(1):no_receipt:va_receipt:vbanknoreg(0):below1000:vbank(" + this.vbankDt + ")"

                            this.fnPay();

                        }
                    })
                    .catch(error => {
                            console.log(error);
                        }
                    );

            },
            insertPayRcptmn() {
                var oForm = document.getElementById('SendPayForm_id');
                //
                // 핸드폰번호 유효성체크1
                var pNumberExp =  /^(010[0-9]{8}|011[0-9]{7}|016[0-9]{7}|017[0-9]{7}|018[0-9]{7}|019[0-9]{7})$/
                if(!pNumberExp.test(this.buyerTel)) {
                    alert("핸드폰번호를 입력해주세요. (숫자만)");
                    return false;
                }
                // 이메일 유효성체크
                var emailExp = /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/i;
                if(this.buyerEmail == "" || !emailExp.test(this.buyerEmail)) {
                    alert("이메일을 정확히 입력해주세요.");
                    return false;
                }

                this.merchantData = this.$CryptoJS.AES.encrypt(
                    this.$route.params.id +',' + this.price + ',' + this.goodNm + ',' + this.buyerNm + ',' + this.buyerTel + ',' + this.buyerEmail + ',' + this.buyerBizNo + ',',"T@kRINisis?.?.?").toString();
                this.getPayInfo();
                // var param = {
                //     rcptmnId: this.$route.params.id,
                //     price: this.price,
                //     goodNm: this.goodNm,
                //     buyerNm: this.buyerNm,
                //     buyerTel: this.buyerTel,
                //     buyerEmail: this.buyerEmail,
                //     buyerBizNo: this.buyerBizNo,
                //
                // };
                //
                // this.$post('/api/bilcltMngt/insertPayRcptmn',param)
                //     .then(res => {
                //         if(res.status == 'OK'){
                //
                //         }
                //         this.payRcptmnId = res.payRcptmnId;
                //
                //         //결재정보 입력
                //         this.getPayInfo();
                //     })
                //     .catch(error => console.log(error));
                //


            },
            fnPay() {
                var oForm = document.getElementById('SendPayForm_id');

                if (this.gopaymethod === 'Card') {
                    oForm.mid.value = this.midCard;
                    oForm.mKey.value = this.$CryptoJS.SHA256(this.signKeyCard).toString();
                } else if (this.gopaymethod === 'VBank') {
                    oForm.mid.value = this.midVact;
                    oForm.mKey.value = this.$CryptoJS.SHA256(this.signKeyVact).toString();
                }
                // document.querySelector('#SendPayForm_id').submit()
                INIStdPay.pay('SendPayForm_id');
                // document.querySelector('#PayForm').submit()
            }
        }
    }
</script>