<template>
    <div class="clause__group clause__charge agree__cost">
        <h2 class="clause__group--tit clause__charge--subject vat">※ 분석가에 따른 성공보수료</h2>
        <ul class="clause__list--wrap clause__charge--list"  v-for="(items, index) in anlsFeeGroup"  v-bind:key="items.id">
            <li class="clause__list--item" v-for="item in items"  v-bind:key="item.id" :class="index == 0 ? 'clause__charge--border' : ''">
                <span class="clause__list--title clause__charge--title">{{item.anlstNickNm}}</span>
                <span class="clause__list--sub clause__charge--sub">낙찰가의 {{item.anlsFee.toFixed(1)}}%</span>
            </li>
        </ul>
              <p class="clause__group--info"><span>&lt;표1&gt;</span></p>
    </div>
</template>

<script>
    export default {
        name: 'anlsFee',
        data() {
            return {
                anlsFeeList: [],
                anlsFeeGroup: [],
            }
        },
        created() {
            //수수료 가져오기
            this.getAnlsFee()
        },
        methods: {
            //수수료 가져오기
            getAnlsFee() {
                this.$get('/api/anlsFee/list')
                    .then(res => {
                        this.anlsFeeList = res.anlsFeeList;
                        this.refactAnlsFeeList();

                    })
                    .catch(error => console.log(error));
            },

            refactAnlsFeeList() {
                this.anlsFeeGroup = [];
                var groupIndex = 0;
                for (var i in this.anlsFeeList) {
                    if (i % 3 == 0 && i != 0) {
                        groupIndex++;
                    }

                    if (!this.anlsFeeGroup[groupIndex]) {
                        this.anlsFeeGroup[groupIndex] = []; // Initialize an array for the category
                    }
                    this.anlsFeeGroup[groupIndex].push(this.anlsFeeList[i]);

                }
            },
        },
    };
</script>

