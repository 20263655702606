<template>
    <div class="gate">
        <div class="gate__wrap">
            <h1 class="gate__logo"></h1>
            <p class="gate__title">비드톡톡앱 설치를 위한<br />다운로드 스토어를 선택해 주세요.</p>
            <ul class="gate__box">
                <li class="gate__item">
                    <p class="gate__item--txt"><span>안드로이드폰</span>을 사용중이시라면,</p>
                    <a href="https://play.google.com/store/search?q=%EB%B9%84%EB%93%9C%ED%86%A1%ED%86%A1&c=apps&hl=ko-KR" class="gate__item--btn google"></a>
                    <p class="gate__item--title">구글플레이스토어</p>
                </li>
                <li class="gate__item">
                    <p class="gate__item--txt"><span>아이폰</span>을 사용중이시라면,</p>
                    <a href="https://apps.apple.com/kr/app/%EB%B9%84%EB%93%9C%ED%86%A1%ED%86%A1-%EB%82%B4-%EC%86%90%EC%95%88%EC%9D%98-%EB%98%91%EB%98%91%ED%95%9C-%EC%A0%84%EC%9E%90%EC%9E%85%EC%B0%B0/id6450491526" class="gate__item--btn app"></a>
                    <p class="gate__item--title">애플앱스토어</p>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Gate"
    }
</script>

<style scoped>

</style>