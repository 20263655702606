<template>
  <div class="join-form__wrap">
    <div class="join__container">
      <a href="/home" class="link__logo"></a>
      <p class="txt__title">비드톡톡에서 분석을 시작하세요!</p>
      <div class="join__area">
        <div class="join__group accordion__head start" :class="isBizNoExist == true ? 'active':''">
          <p class="join-form__title essen business">① 사업자등록번호(아이디)</p>
          <div class="input join-form__wrap--input pair business">
            <input type="text" placeholder="- 없이 10자리 숫자만 입력해 주세요."  tabIndex="1" ref="bizNo" v-mask="['###-##-#####']" v-model="bizNo" @input="checkInput('bizNo',bizNo.length == 0 )"
                   @keyup="bizNoKeyUp()">
            <button class="btn-basic" type="button" @click="getIsBizNoExist();" :class="bizNo=='' ? 'btn__non-active':'btn__active'" v-show="!isBizLoading">조회</button>
            <button class="btn-basic loading-box" type="button" :class="bizNo=='' ? 'btn__non-active':'btn__active'" v-show="isBizLoading"><LoadingSpinner></LoadingSpinner>ㅤ</button>
            <p id='bizNo' class="input-noti incorr not-input" style="display:none">사업자등록번호를 입력해 주세요.</p>
          </div>
        </div>
        <div class="join__group accordion__body join__group__body" v-show="isBizNoExist">
          <div class="join-form__box">
            <p class="join-form__title">② 정보 입력</p>
            <p class="join-form__title--sub essen">법인명(상호)</p>
            <div class="input join-form__wrap--input single company refer-character">
              <input type="text" placeholder="상호명을 입력해 주세요." tabIndex="2" ref="corpNm" v-model="corpNm" @input="checkInput('corpNm',corpNm.length == 0 )">
              <p id='corpNm' class="input-noti incorr not-input" style="display:none">상호명을 입력해 주세요.</p>
            </div>
            <p class="join-form__title--sub essen">대표자명</p>
            <div class="input join-form__wrap--input single boss">
              <input type="text" placeholder="대표자명을 입력해 주세요." tabIndex="3" ref="userNm" v-model="userNm" @input="checkInput('userNm',userNm.length == 0 )">
              <p id='userNm'  class="input-noti incorr not-input" style="display:none">대표자명을 입력해 주세요.</p>
            </div>
            <p class="join-form__title--sub essen">주소</p>
            <div class="input join-form__wrap--input single">
              <input type="text" placeholder="주소를 입력해 주세요." tabIndex="4" ref="custAddr" v-model="custAddr" @click="openPostcode" @focusout="checkInput('custAddr',custAddr.length == 0 )" readonly>
              <p id='custAddr' class="input-noti incorr not-input" style="display:none" >주소를 입력해 주세요.</p>
            </div>
            <p class="join-form__title--sub">상세주소</p>
            <div class="input join-form__wrap--input single">
              <input type="text" placeholder="상세주소를 입력해 주세요." tabIndex="5" v-model="custDtlAdrs">
              <p class="input-noti incorr not-input"></p>
            </div>
            <p class="join-form__title--sub">일반전화</p>
            <div class="input join-form__wrap--input single">
              <input type="text" placeholder="일반전화를 입력해 주세요." tabIndex="6" v-mask="['##-###-####','###-###-####','###-####-####']" v-model="custLandLineNo">
              <p class="input-noti incorr not-input"></p>
            </div>
            <div class="join-form__wrap--dott">
              <p>위의 정보를 확인하시고, <span class="txt__mobile">잘못된 정보는 수정해 주세요.</span></p>
            </div>
          </div>
<!--          <form>-->
          <div class="join-form__box">
            <p class="join-form__title">③ 비밀번호 설정</p>
            <p class="join-form__title--sub essen">비밀번호</p>
            <div class="input input__combo join-form__wrap--input single password">

              <input id="inputPw" :type="showPassword ? 'text': 'password'" classs="text" placeholder="비밀번호를 입력해 주세요." tabIndex="6" ref="password" v-model="password" autoComplete="off"
                     @input="checkInput('password',password.length == 0 );checkInput('passwordTrue',checkPassword(password));checkInput('passwordFalse',!checkPassword(password))">

              <button>
                <i class="input__combo--hide-password" title="문자 숨기기" v-show="!showPassword" @click="fnShowPassword(true)"></i>
                <i class="input__combo--show-password" title="문자 보이기" v-show="showPassword" @click="fnShowPassword(false)"></i>
              </button>
              <p id='passwordFalse' class="input-noti incorr">영문소문자, 숫자 포함 6~15자로 입력해 주세요.</p>
              <p id='passwordTrue' class="input-noti corr" style="display:none">영문소문자, 숫자 포함 6~15자로 입력해 주세요.</p>
              <p id='password' class="input-noti incorr not-input not-input" style="display:none">비밀번호를 입력해 주세요.</p>
            </div>
            <p class="join-form__title--sub essen">비밀번호 확인</p>
            <div class="input input__combo join-form__wrap--input single password-confirm">
              <input id="inputPwConf" :type="showPasswordConfirm ? 'text': 'password'" placeholder="비밀번호를 한 번 더 입력해 주세요." tabIndex="7" ref="passwordConfirm" autoComplete="off"
                     v-model="passwordConfirm" @input="checkInput('passwordConfirm',passwordConfirm.length == 0 );checkInput('passwordConfirm2',passwordConfirm != password )">
              <button>
                <i class="input__combo--hide-password" title="문자 숨기기" v-show="!showPasswordConfirm" @click="fnShowPasswordConfirm(true)"></i>
                <i class="input__combo--show-password" title="문자 보이기" v-show="showPasswordConfirm" @click="fnShowPasswordConfirm(false)"></i>
              </button>
              <p id='passwordConfirm' class="input-noti incorr not-input" style="display:none" >비밀번호확인을 입력해 주세요.</p>
              <p id='passwordConfirm2' class="input-noti incorr not-same" style="display:none">비밀번호가 일치하지 않습니다. 다시 입력해 주세요.</p>
            </div>
          </div>
<!--          </form>-->
          <div class="join-form__box">
            <p class="join-form__title essen">④ 휴대폰번호</p>
            <div class="input join-form__wrap--input pair phone">
              <input type="text" placeholder="예 01012345678" tabIndex="8" ref="phoneNo" v-model="phoneNo" v-mask="['###-###-####','###-####-####']" @input="checkPhoneNo(); checkInput('phoneNo',phoneNo.length == 0 )">
              <button class="btn-basic" type="button" :class="!phoneValidate ? 'btn__non-active':'btn__active'"  @click="sendcertNo">인증번호발송</button>
              <p id='phoneNo' class="input-noti incorr not-input" style="display:none">휴대폰번호를 입력해 주세요.</p>
            </div>
            <div class="input join-form__wrap--input single phone-confirm">
              <input type="text" placeholder="인증번호를 입력해 주세요." tabIndex="9" ref="certNo" maxlength="6"  v-model='certNo'
                     @input="checkInput('certNo',certNo.length == 0 ); checkInput('certNo2',!smsValidate );checkSms();">
              <span class="certi-time" v-show="Timer!=null && TimeCounter != 0">{{TimerStr}}</span>
              <p id='certNo' class="input-noti incorr not-input" style="display:none">인증번호를 입력해 주세요.</p>
              <p  id='certNo2' class="input-noti incorr" style="display:none">인증번호가 일치하지 않습니다. 다시 입력해 주세요.</p>
            </div>
          </div>
          <div class="join-form__box">
            <p class="join-form__title consent-title">⑤ 약관동의</p>
            <ul class="input join-form__wrap--link">
              <li class="join-form__wrap--move use-consent" @click="openUtlzCaluseAgree()">
                <a href="#" onclick="return false" class="btn-basic btn-line-cw link__consent " @click=openUtlzCaluseAgree() >(필수)이용약관 동의
                <span class="link__consent--check">
<!--                                    <i class="check-none" title="체크 비활성화" v-show="!utlzClauseAgreYn" style="display: inline-block"></i>-->
<!--                                    <i class="check-active" title="체크 활성화" v-show="utlzClauseAgreYn" style="display: inline-block"></i>-->
                                     <i class="check-none" title="체크 비활성화" :style="{ display: !utlzClauseAgreYn ? 'inline-block' : 'none' }"></i>
                                    <i class="check-active" title="체크 활성화" :style="{ display: utlzClauseAgreYn ? 'inline-block' : 'none' }"></i>
                  동의
                                </span>
                </a>
              </li>
              <li class="join-form__wrap--move info-consent" @click=openIndvdlinfoClauseAgree()>
                <a href="#" onclick="return false" class="btn-basic btn-line-cw link__consent" @click=openIndvdlinfoClauseAgree()  ref="agree">(필수)개인정보 수집 동의
                <span class="link__consent--check">
<!--                  <i class="check-none" title="체크 비활성화" v-show="!indvdlinfoClauseAgreYn" style="display: inline-block"></i>-->
<!--                                    <i class="check-active" title="체크 활성화" v-show="indvdlinfoClauseAgreYn" style="display: inline-block"></i>-->
                   <i class="check-none" title="체크 비활성화" :style="{ display: !indvdlinfoClauseAgreYn ? 'inline-block' : 'none' }"></i>
                                    <i class="check-active" title="체크 활성화" :style="{ display: indvdlinfoClauseAgreYn ? 'inline-block' : 'none' }"></i>
                  동의
                                </span>
                </a>
              </li>
            </ul>
            <div class="join-form__wrap--checkbox">
              <span><input type="checkbox" id="check" v-model="mrktgClauseAgreYn"><label for="check" class="test"></label><label for="check">광고성 정보 수신 동의(선택)</label></span>
              <a href="#" onclick="return false" class="join-form__wrap--move adver" @click="openMktAgree()">보기</a>
            </div>
          </div>
          <button type="submit" class="btn-basic btn__active"  tabIndex="10" @click="join()"  v-show="!isJoinLoading">회원가입</button>
          <button type="submit" class="btn-basic btn__active loading-box"  tabIndex="10" v-show="isJoinLoading"><LoadingSpinner></LoadingSpinner>ㅤ</button>
        </div>
      </div>
    </div>
    <!-- 이용약관 동의 팝업 -->
    <div id='useAgreePop' class="popup__wrap use-consent__wrap">
      <div class="popup__item--container use-consent__container">
        <div class="popup__item--group use-consent__wrap--group">
          <div class="popup__item--head">
            <h3 class="txt__title">비드톡톡 이용약관 동의</h3>
            <button type="button" class="popup__close" @click="closeUseAgreePop(false)"></button>
          </div>
          <div id="useAgreeScroll" class="popup__item--body use-consent__wrap--body">
            <div class="use-consent__wrap--box">
              <!-- 이곳에 약관 붙여주세요 -->
              <div class="clause clause__app wrap">
                <!-- 제목 -->
                <div class="clause__txt--box">
                  <h2 class="menu__title clause__txt--title">이용약관</h2>
                  <h2 class="clause__txt--sub">비드톡톡 정보 관련 제반 서비스의 이용과 관련하여 필요한 사항을 규정합니다.</h2>
                </div>
                <!-- 상단 테이블 -->
                <div class="clause__group clause__index">
                  <!-- 이용약관 -->
                  <!-- 제1조 ~ 제12조 -->
                  <ul class="clause__list--wrap clause__index--list">
                    <li class="clause__list--item">
                      <a href="#" onclick="return false" @click="move('list1')" class="clause__list--title clause__index--title">제 1 조</a>
                      <a href="#" onclick="return false" @click="move('list1')" class="clause__list--sub clause__index--sub">(목적)</a>
                    </li>
                    <li class="clause__list--item">
                      <a href="#" onclick="return false" @click="move('list2')" class="clause__list--title clause__index--title">제 2 조</a>
                      <a href="#" onclick="return false" @click="move('list2')" class="clause__list--sub clause__index--sub">(약관의 명시와 개정)</a>
                    </li>
                    <li class="clause__list--item">
                      <a href="#" onclick="return false" @click="move('list3')" class="clause__list--title clause__index--title">제 3 조</a>
                      <a href="#" onclick="return false" @click="move('list3')" class="clause__list--sub clause__index--sub">(약관 이외의 준칙)</a>
                    </li>
                    <li class="clause__list--item">
                      <a href="#" onclick="return false" @click="move('list4')" class="clause__list--title clause__index--title">제 4 조</a>
                      <a href="#" onclick="return false" @click="move('list4')" class="clause__list--sub clause__index--sub">(용어의 정의)</a>
                    </li>
                    <li class="clause__list--item">
                      <a href="#" onclick="return false" @click="move('list5')" class="clause__list--title clause__index--title">제 5 조</a>
                      <a href="#" onclick="return false" @click="move('list5')" class="clause__list--sub clause__index--sub">(이용계약의 성립)</a>
                    </li>
                    <li class="clause__list--item">
                      <a href="#" onclick="return false" @click="move('list6')" class="clause__list--title clause__index--title">제 6 조</a>
                      <a href="#" onclick="return false" @click="move('list6')" class="clause__list--sub clause__index--sub">(이용자 번호(ID) 부여 및 변경 등)</a>
                    </li>
                    <li class="clause__list--item">
                      <a href="#" onclick="return false" @click="move('list7')" class="clause__list--title clause__index--title">제 7 조</a>
                      <a href="#" onclick="return false" @click="move('list7')" class="clause__list--sub clause__index--sub">(이용자 번호(ID) 및 비밀번호(PW)에 대한 의무)</a>
                    </li>
                    <li class="clause__list--item">
                      <a href="#" onclick="return false" @click="move('list8')" class="clause__list--title clause__index--title">제 8 조</a>
                      <a href="#" onclick="return false" @click="move('list8')" class="clause__list--sub clause__index--sub">(이용자 정보의 변경)</a>
                    </li>
                    <li class="clause__list--item">
                      <a href="#" onclick="return false" @click="move('list9')" class="clause__list--title clause__index--title">제 9 조</a>
                      <a href="#" onclick="return false" @click="move('list9')" class="clause__list--sub clause__index--sub">(서비스 이용 / 이용대금)</a>
                    </li>
                    <li class="clause__list--item">
                      <a href="#" onclick="return false" @click="move('list10')" class="clause__list--title clause__index--title">제 10 조</a>
                      <a href="#" onclick="return false" @click="move('list10')" class="clause__list--sub clause__index--sub">(회원의 등급 및 톡포인트의 제공)</a>
                    </li>
                    <li class="clause__list--item replenish">
                      <a href="#" onclick="return false" class="clause__list--title clause__index--title"></a>
                    </li>
                  </ul>
                  <!-- //제1조 ~ 제12조 -->

                  <!-- 제9조 ~ 부칙 -->
                  <ul class="clause__list--wrap clause__index--list">
                    <li class="clause__list--item">
                      <a href="#" onclick="return false" @click="move('list11')" class="clause__list--title clause__index--title">제 11 조</a>
                      <a href="#" onclick="return false" @click="move('list11')" class="clause__list--sub clause__index--sub">(대금결제방법)</a>
                    </li>
                    <li class="clause__list--item">
                      <a href="#" onclick="return false" @click="move('list12')" class="clause__list--title clause__index--title">제 12 조</a>
                      <a href="#" onclick="return false" @click="move('list12')" class="clause__list--sub clause__index--sub">(제시가격의 이행여부 인정기준)</a>
                    </li>
                    <li class="clause__list--item">
                      <a href="#" onclick="return false" @click="move('list13')" class="clause__list--title clause__index--title">제 13 조</a>
                      <a href="#" onclick="return false" @click="move('list13')" class="clause__list--sub clause__index--sub">(이용자 정보의 보호)</a>
                    </li>
                    <li class="clause__list--item">
                      <a href="#" onclick="return false" @click="move('list14')" class="clause__list--title clause__index--title">제 14 조</a>
                      <a href="#" onclick="return false" @click="move('list14')" class="clause__list--sub clause__index--sub">(회원의 의무)</a>
                    </li>
                    <li class="clause__list--item">
                      <a href="#" onclick="return false" @click="move('list15')" class="clause__list--title clause__index--title">제 15 조</a>
                      <a href="#" onclick="return false" @click="move('list15')" class="clause__list--sub clause__index--sub">(게시물의 관리)</a>
                    </li>
                    <li class="clause__list--item">
                      <a href="#" onclick="return false" @click="move('list16')" class="clause__list--title clause__index--title">제 16 조</a>
                      <a href="#" onclick="return false" @click="move('list16')" class="clause__list--sub clause__index--sub">(제공 서비스)</a>
                    </li>
                    <li class="clause__list--item">
                      <a href="#" onclick="return false" @click="move('list17')" class="clause__list--title clause__index--title">제 17 조</a>
                      <a href="#" onclick="return false" @click="move('list17')" class="clause__list--sub clause__index--sub">(서비스의 중지 및 공지)</a>
                    </li>
                    <li class="clause__list--item">
                      <a href="#" onclick="return false" @click="move('list18')" class="clause__list--title clause__index--title">제 18 조</a>
                      <a href="#" onclick="return false" @click="move('list18')" class="clause__list--sub clause__index--sub">(회원의 탈퇴 및 자격상실)</a>
                    </li>
                    <li class="clause__list--item">
                      <a href="#" onclick="return false" @click="move('list19')" class="clause__list--title clause__index--title">제 19 조</a>
                      <a href="#" onclick="return false" @click="move('list19')" class="clause__list--sub clause__index--sub">(면책)</a>
                    </li>
                    <li class="clause__list--item">
                      <a href="#" onclick="return false" @click="move('list20')" class="clause__list--title clause__index--title">제 20 조</a>
                      <a href="#" onclick="return false" @click="move('list20')" class="clause__list--sub clause__index--sub">(분쟁의 해결)</a>
                    </li>
                    <li class="clause__list--item">
                      <a href="#" onclick="return false" @click="move('list21')" class="clause__list--title clause__index--title">부&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;칙</a>
                      <a href="#" onclick="return false" @click="move('list21')" class="clause__list--sub clause__index--sub">(부칙)</a>
                    </li>
                  </ul>
                  <!-- //제13조 ~ 부칙 -->
                </div>
                <!-- //상단 테이블 -->

                <!-- 제 1 조 (목 적) -->
                <div class="clause__group" id="list1">
                  <h2 class="clause__group--tit">제 1 조 (목 적)</h2>
                  <p class="clause__group--info"><span>본 약관은 (주)비드톡톡(이하 “당사”)이 제공하는 입찰정보 서비스 (한글명 : 비드톡톡, 도메인 주소 :
                                        http://www.bidtok.co.kr, 어플리에이션 : 비드톡톡 이하 '당 사이트')의 이용조건 및 가입절차, 이용자와 당 사이트의 권리, 의무, 책임사항과 기타 필요한 사항 등을 규정함을
                                        목적으로 합니다.</span></p>
                </div>
                <!-- 제 1 조 (목 적) -->

                <!-- 제 2 조 (약관의 명시와 개정) -->
                <div class="clause__group" id="list2">
                  <h2 class="clause__group--tit">제 2 조 (약관의 명시와 개정)</h2>
                  <p class="clause__group--info">
                    <span>①  당 사이트는 이 약관의 내용을 이용자가 쉽게 알 수 있도록 초기화면 내 또는 별도의 연결화면에 게시하거나 팝업화면 등으로 제공합니다.</span><br />
                    <span> ② 당 사이트는 약관의규제에관한법률, 전자거래기본법, 전자서명법, 정보통신망이용촉진 및 정보보호이용등에관한법률, 방문판매등에관한법률, 소비자보호법 등 관련 법령에 저촉되지
                                        않는 범위 내에서 본 약관을 개정할 수 있습니다.</span><br />
                    <span>③ 본 약관의 개정은 적용일자 및 개정사유를 명시하여 당 사이트의 초기화면에 그 적용일자 이전에 상당기간을 공지함으로써 그 효력이 발생합니다.<br />
                                        단, 약관의 유료 계약조건에 관한 기회원의 권리가 회원에게 불리한 경우는 그러하지 않습니다.</span><br />
                    <span>④ 당 사이트는 이용자가 본 약관 내용에 동의하는 것을 조건으로 서비스를 제공하며, 귀하가 본 약관의 내용에 동의하는 경우, 당 사이트의 서비스 제공 행위 및 이용자의
                                            서비스 사용 행위는 본 약관이 우선적으로 적용됩니다.</span><br />
                    <span>⑤ 이 약관에 동의하는 것은 정기적으로 웹사이트 또는 어플리케이션을 방문하여 약관의 변경사항을 확인하는 것에 동의함을 의미합니다. 변경된 약관에 대한 정보를 알지 못해 발생하는 이용자의 피해는 당 사이트에서 책임지지 않습니다.</span><br />
                    <span>⑥ 회원은 변경된 약관에 동의하지 않을 경우 회원은 탈퇴(해지)를 요청할 수 있습니다.<br />다만, 변경된 약관의 효력 발생일로부터 7일 이후에도 거부의사를 표시하지 아니하고 서비스를 계속 사용할 경우 약관의 변경 사항에 동의한 것으로 의제됩니다.</span>
                  </p>
                </div>
                <!-- //제 2 조 (약관의 명시와 개정) -->

                <!-- 제 3 조 (약관 이외의 준칙) -->
                <div class="clause__group" id="list3">
                  <h2 class="clause__group--tit">제 3 조 (약관 이외의 준칙)</h2>
                  <p class="clause__group--info">
                    <span>① 본 약관에 명시되지 않은 사항은 전기통신기본법, 전기통신사업법, 정보통신윤리위원회 심의규정, 정보통신 윤리강령, 프로그램 보호법 및 기타 관련 법령에 의합니다.</span>
                  </p>
                </div>
                <!-- //제 3 조 (약관 이외의 준칙) -->

                <!-- 제 4 조 (용어의 정의) -->
                <div class="clause__group" id="list4">
                  <h2 class="clause__group--tit">제 4 조 (용어의 정의)</h2>
                  <span class="clause__group--info">
                                        <span>① 본 약관에서 사용하는 용어는 다음과 같습니다.</span>
                                        <span class="clause__group--info indent">
                                            <span>1. ‘이용자’ 라 함은 회원제 서비스를 이용하는 이용자를 의미합니다.</span>
                                            <span>2. ‘이용계약’ 이라 함은 서비스 이용과 관련하여 당 사이트와 이용자 간에 체결하는 계약을 의미합니다.</span>
                                            <span>3. ‘가입’ 이라 함은 당 사이트가 제공하는 신청서 양식에 해당 정보를 기입하고, 본 약관에 동의하여 서비스 이용계약을 완료시키는 행위를 의미합니다.</span>
                                            <span>4.  ‘회원’ 이라 함은 당 사이트에 회원가입에 필요한 개인 정보를 제공하여 회원 등록을 한 자로서, 당 사이트의 정보 및 서비스를 이용할 수 있는 자를 말합니다.</span>
                                            <span>5. ‘이용자 번호 (ID)' 라 함은 이용자를 구별할 수 있는 사업자 번호를 의미합니다.</span>
                                            <span>6. ‘비밀번호 (PW)' 라 함은 이용자가 등록회원과 동일인인지 신원을 확인하고, 통신상의 개인정보보호를 위하여 이용자 자신이 선정한 문자와 숫자의 조합을
                                                의미합니다.</span>
                                            <span> 7. ‘탈퇴 또는 해지’ : 회원이 이용계약을 종료시키는 행위를 의미합니다.</span>
                                        </span>
                                        <span>  ② 본 약관에서 정의하지 않은 용어 및 본 약관의 해석은 전자거래소비자보호지침 및 약관의규제에관한법률 등의 관련법규 및 상관습에 의합니다.</span>
                                    </span>
                </div>
                <!-- //제 4 조 (용어의 정의) -->

                <!-- 제 5 조 (이용계약의 성립) -->
                <div class="clause__group" id="list5">
                  <h2 class="clause__group--tit">제 5 조 (이용계약의 성립)</h2>
                  <p class="clause__group--info">
                    <span>① 이용계약은 이용신청자의 청약(이용신청)과 당사의 승낙(이용승낙)으로 성립합니다.</span><br />
                    <span>② 이용신청자의 청약은 본 약관의 ‘동의’ 버튼을 표시함으로 갈음합니다.</span><br />
                    <span>③ 당사는 이용신청자의 청약에 대해 이용자 번호(ID)를 선정하여 주는 것으로 승낙의 의사표시를 갈음합니다.</span><br />
                    <span>④ 당사는 각 호에 해당하는 경우에는 승낙 제한 사유가 해소될 때까지 제3항의 승낙을 유보할 수 있습니다.</span>
                    <span class="clause__group--info indent">
                                            <span>1. 서비스 관련 제반 용량이 부족한 경우</span>
                                            <span>2. 기술상 장애 사유가 있는 경우</span>
                                            <span>3. 기타 회사가 필요하다고 인정되는 경우</span>
                                       </span>
                    <span>⑤ 당사는 각 호에 해당하는 경우에는 제3항의 승낙을 아니 하거나, 승낙의 의사표시를 취소, 철회할 수 있습니다.</span>
                    <span class="clause__group--info indent">
                                            <span>1. 다른 사람의 명의를 사용하여 신청한 경우</span>
                                            <span>2. 이용신청 시 이용자 정보를 허위로 기재하여 신청한 경우</span>
                                            <span>3. 사회의 안녕질서 혹은 미풍양속을 저해할 목적으로 신청한 경우</span>
                                            <span>4. 타 회원의 당 사이트 서비스 이용을 방해하거나 그 정보를 도용하는 등의 행위를 한 경우</span>
                                            <span>5. 당 사이트의 정보를 도용할 목적으로 신청하거나 신청하였을 경우</span>
                                            <span>6. 본 약관에 의해 이전에 회원 자격을 상실한 적이 있는 경우</span>
                                            <span>7. 기타 당 사이트가 정한 이용신청요건을 충족하지 못한 경우 ⑥ 분석서비스 계약은 별도의 낙찰수수료 및 서비스 요금을 산정한 "전자입찰 분석정보 제공계약서"를 통해 낙찰분석서비스를 의뢰함으로써 성립합니다.</span>
                                       </span>
                  </p>
                </div>
                <!-- //제 5 조 (이용계약의 성립) -->

                <!-- 제 6 조 (이용자 번호(ID) 부여 및 변경 등) -->
                <div class="clause__group" id="list6">
                  <h2 class="clause__group--tit">제 6 조 (이용자 번호(ID) 부여 및 변경 등)</h2>
                  <p class="clause__group--info">
                    <span>① 당사는 전 조에 따른 이용 신청자에 대해 약관이 정하는 방에 따라 이용자 번호(ID)를 부여합니다.</span><br />
                    <span>② 당사는 이용자 번호(ID)에 의하여 고객계좌 입금 등 모든 제반 회원 관리업무를 수행하므로, 한번 가입한 이용자 번호(ID)는 변경되지 않습니다. 다만, 각 호에 해당하는 경우에는 회원 또는 당사의 요청으로 변경할 수 있습니다.</span>
                    <span class="clause__group--info indent">
                                            <span>1. 이용자 번호(ID)가 이용자의 전화번호 등으로 등록되어 사생활침해가 우려되는 경우</span>
                                            <span>2. 타인에게 혐오감을 주거나 미풍양속에 어긋나는 경우</span>
                                            <span>3. 기타 합리적인 사유가 있는 경우</span>
                                        </span>
                  </p>
                </div>
                <!-- //제 6 조 (이용자 번호(ID) 부여 및 변경 등) -->

                <!-- 제 7 조 (이용자 번호(ID) 및 비밀번호(PW)에 대한 의무) -->
                <div class="clause__group" id="list7">
                  <h2 class="clause__group--tit">제 7 조 (이용자 번호(ID) 및 비밀번호(PW)에 대한 의무)</h2>
                  <p class="clause__group--info">
                    <span>① 이용자 번호(ID) 및 비밀번호(PW)에 관한 관리책임은 회원에게 있습니다.</span>
                    <span>② 회원은 이용자 번호(ID) 및 비밀번호(PW)를 제3자에게 알려 주거나 이용하게 하여서는 안됩니다.</span>
                    <span>③ 회원은 이용자 번호(ID) 및 비밀번호(PW)를 도용당하거나 제3자가 사용하고 있음을 인지한 경우에는 즉시 당사에 연락하여 조치를 취할 수 있도록 하여야 합니다.</span>
                  </p>
                </div>
                <!-- //제 7 조 (이용자 번호(ID) 및 비밀번호(PW)에 대한 의무) -->
                <!-- //제 8 조 (이용자 정보의 변경) -->
                <div class="clause__group" id="list8">
                  <h2 class="clause__group--tit">제 8 조 (이용자 정보의 변경)</h2>
                  <p class="clause__group--info">
                    <span>① 이용자는 이용신청시 기재한 이용자의 정보가 변경되었을 경우에는 온라인으로 수정하거나 당사에 통보하여 수정하도록 하여야 하며, 미변경으로 인하여 발생되는 문제의 책임은 이용자가 부담합니다.</span>
                  </p>
                </div>
                <!-- 제 9 조 (서비스의 요금) -->
                <div class="clause__group" id="list9">
                  <h2 class="clause__group--tit">제 9 조 (서비스 이용 / 이용대금)</h2>
                  <p class="clause__group--info">
                    <span>① 이용자가 서비스를 이용하기 위해서는 회사가 공지사항에 게시하거나 해당서비스 이용안내에서 제시하는 절차를 준수하여야 하며, 해당 서비스의 내용 및 책임한계 또한 공지사항에 게시된 내용 및 해당 서비스 이용안내에 따릅니다.</span>
                    <span>② 서비스 이용대금은 회사가 공지사항에 게시하거나 해당 서비스 이용안내에서 제시하는 바에 따릅니다. 회사가 제공하는 서비스는 그 이용대금을 별도 게시하거나 제시하지 않는 한 무료로 합니다.</span>
                    <span>③ 분석서비스(유료서비스)는 이용자가 분석요청 시 회사가 이용안내 또는 그 연결화면에 게시하는 바에 따라 분석정보 제공계약서를 확인하고 동의 후 회사가 승인함으로써 성립됩니다.</span>
                  </p>
                </div>
                <!-- //제 9 조 (이용자 정보의 보호) -->

                <!-- 제 10 조 (회원의 의무) -->
                <div class="clause__group" id="list10">
                  <h2 class="clause__group--tit">제 10 조 (회원의 등급 및 톡포인트의 제공)</h2>
                  <p class="clause__group--info">
                    <span>① 회사는 서비스를 이용하는 회원에게 할인 혜택이 제공되는 등급을 이용 여부에 따라 부여할 수 있으며, 일부 서비스의 결제 수단으로 사용할 수 있는 톡포인트를 지급 할 수 있습니다.</span><br />
                    <span>② 톡포인트는 비드톡톡 서비스 내에서만 사용 가능하며, 어떠한 경우에도 현금으로 보상되지 않습니다.</span><br />
                    <span>③ 회원은 지급받은 등급 또는 톡포인트에 대해 회원 본인만 사용할 수 있는 권한을 가지며, 어떠한 경우에도 이를 타인에게 매매 또는 양도할 수 없으며, 부정적 목적이나 용도로 사용할 수 없습니다. 만약 이를 어긴 경우, 사유를 미리(미리 통지할 수 없는 경우에는 사후 통지함) 해당 회원에게 통지하고, 지급된 등급 또는 톡포인트를 회수 할 수 있습니다.</span><br />
                    <span>④ 톡포인트는 회사가 지정한 서비스 내에서만 사용 가능합니다.</span><br />
                    <span>⑤ 결제 취소나 환불 등 톡포인트 발행의 원인이 된 사건이 무효화 경우, 해당 톡포인트의 사용이 불가능 할 수 있습니다. ⑥ 등급 또는 톡포인트의 유효기간, 사용조건 및 제한에 관한 사항을 서비스 화면에 별도로 게시하거나 통지하며, 해당 사항은 회사 정책에 따라 달라 질 수 있습니다. ⑦ 등급 또는 톡포인트의 유효기간이 만료 되거나 이용 계약이 취소, 종료되면 자동으로 소멸 됩니다.</span><br />
                    <span>⑧ 등급 또는 톡포인트는 회원에게 지급된 시점부터 사용 가능 합니다. 단. 회사의 사정에 의해 사용 가능 시간이 지연 될 수 있습니다.</span>
                  </p>
                </div>
                <!-- //제 10 조 (회원의 의무) -->

                <!-- 제 11 조 (게시물의 관리) -->
                <div class="clause__group" id="list11">
                  <h2 class="clause__group--tit">제 11 조 (대금결제방법)</h2>
                  <p class="clause__group--info">
                    <span>① 분석서비스를 이용하여 최종 낙찰자로 선정되어 성공보수료 등에 대한 대금지급 방법은 다음 각 호의 하나로 할 수 있습니다.</span>
                    <span class="clause__group--info indent">
                                            <span>1. 온라인 무통장 입금, 폰 뱅킹, 인터넷 뱅킹 등의 각종 계좌 이체</span>
                                            <span>2. 선불카드, 직불카드, 신용카드 등 각종 카드 결제</span>
                                            <span>3. 전자 화폐에 의한 결제</span>
                                            <span>4. 마일리지 등 비드톡톡이 제공한 포인트에 의한 결제</span>
                                            <span>5. 기타 전자적 지급 방법에 의한 대금 지급 등</span>
                                        </span>
                    <span>② 회사가 제공하는 분석서비스(유료서비스)에 대한 이용 요금의 결제방법은 회사가 정하는 바에 따르며, 각 유료서비스마다 결제 방법의 차이가 있을 수 있습니다.</span>
                  </p>
                </div>
                <!-- //제 11 조 (게시물의 관리) -->

                <!-- 제 12 조 (제시가격의 이행여부 인정기준) -->
                <div class="clause__group standard" id="list12">
                  <h2 class="clause__group--tit">제 12 조 (제시가격의 이행여부 인정기준)</h2>
                  <p class="clause__spanst--item clause__standard--subject">
                    <span class="clause__list--title clause__standard--title">ex) 비드톡톡 제시가 1,234,567,891 원</span>
                  </p>
                  <div class="clause__standard--group">
                    <ul class="clause__list--wrap clause__standard--list clause__standard--border">
                      <li class="clause__list--item clause__standard--item clause__standard--subhead">
                        <span class="clause__list--title clause__standard--title">만 단위 (10,000원)</span>
                      </li>
                      <li class="clause__list--item clause__standard--item">
                        <span class="clause__list--title clause__standard--title">절상↑</span>
                        <span class="clause__list--title clause__standard--sub">1,234,5<strong>7</strong>7,891</span>
                      </li>
                      <li class="clause__list--item clause__standard--item">
                        <span class="clause__list--title clause__standard--title">절하↓</span>
                        <span class="clause__list--title clause__standard--sub">1,234,5<strong>5</strong>7,891</span>
                      </li>
                    </ul>
                    <ul class="clause__list--wrap clause__standard--list clause__standard--border">
                      <li class="clause__list--item clause__standard--item clause__standard--subhead">
                        <span class="clause__list--title clause__standard--title">십만 단위 (100,000원)</span>
                      </li>
                      <li class="clause__list--item clause__standard--item">
                        <span class="clause__list--title clause__standard--title">절상↑</span>
                        <span class="clause__list--title clause__standard--sub">1,234,<strong>6</strong>67,891</span>
                      </li>
                      <li class="clause__list--item clause__standard--item">
                        <span class="clause__list--title clause__standard--title">절하↓</span>
                        <span class="clause__list--title clause__standard--sub">1,234,<strong>4</strong>67,891</span>
                      </li>
                    </ul>
                    <ul class="clause__list--wrap clause__standard--list clause__standard--border">
                      <li class="clause__list--item clause__standard--item clause__standard--subhead">
                        <span class="clause__list--title clause__standard--title">백만 단위 (1,000,000원)</span>
                      </li>
                      <li class="clause__list--item clause__standard--item">
                        <span class="clause__list--title clause__standard--title">절상↑</span>
                        <span class="clause__list--title clause__standard--sub">1,23<strong>5</strong>,567,891</span>
                      </li>
                      <li class="clause__list--item clause__standard--item">
                        <span class="clause__list--title clause__standard--title">절하↓</span>
                        <span class="clause__list--title clause__standard--sub">1,23<strong>3</strong>,567,891</span>
                      </li>
                    </ul>
                    <ul class="clause__list--wrap clause__standard--list clause__standard--border">
                      <li class="clause__list--item clause__standard--item clause__standard--subhead">
                        <span class="clause__list--title clause__standard--title">천만 단위 (10,000,000원)</span>
                      </li>
                      <li class="clause__list--item clause__standard--item">
                        <span class="clause__list--title clause__standard--title">절상↑</span>
                        <span class="clause__list--title clause__standard--sub">1,2<strong>4</strong>4,567,891</span>
                      </li>
                      <li class="clause__list--item clause__standard--item">
                        <span class="clause__list--title clause__standard--title">절하↓</span>
                        <span class="clause__list--title clause__standard--sub">1,2<strong>2</strong>4,567,891</span>
                      </li>
                    </ul>
                  </div>
                  <p class="clause__group--info"><br />
                    <span>위와 같이 비드톡톡 제시가를 기준으로 단위의 숫자가 동일한 가운데 한가지 또는 두가지 숫자가 변경된 경우 비드톡톡 제시가로 이행여부의 범위를 인정한다.</span>
                  </p>
                </div>
                <!-- //제 12 조 (제시가격의 이행여부 인정기준) -->

                <!-- 제 13 조 (이용자 정보의 보호) -->
                <div div class="clause__group" id="list13">
                  <h2 class="clause__group--tit">제 13 조 (이용자 정보의 보호)</h2>
                  <p class="clause__group--info">
                    <span>① 이용자의 개인 정보에 대해서는 당 사이트의 개인정보 보호정책이 적용됩니다.</span>
                    <span class="clause__group--info indent">
                                            <span>1. 당사자의 개인정보 보호정책은 정부의 법률 및 지침 변경이나 회사의 내부 방침 변경 등으로 인하여 수시로 변경될 수 있고 , 회사는 그 변경사항에 대하여 지체 없이 사용자에게 통보합니다.</span>
                                        </span>
                    <span>② 이용자 정보의 관리에 관한 내용은 당 사이트의 개인정보 보호정책에 위임합니다. 단, 다음과 같은 경우에는 합리적인 이유로 이용자 정보를 공개할 수 있습니다.</span>
                    <span class="clause__group--info indent">
                                            <span>1. 서비스 이용계약의 이행 및 요금정산을 위하여 필요한 경우</span>
                                            <span>2. 배송업무가 필요한 경우(배송업무에 필요한 최소한의 범위로 한정)</span>
                                            <span>3. 통계작성, 학술연구 또는 시장조사를 위하여 필요한 경우(특정개인을 식별할 수 없는 형태로 제공)</span>
                                            <span>4. 기타 관련 법률에서 정한 적법절차에 의한 요청이 있는 경우</span>
                                        </span>
                  </p>
                </div>
                <!-- //제 13 조 (이용자 정보의 보호) -->

                <!-- 제 14 조 (회원의 의무) -->
                <div class="clause__group" id="list14">
                  <h2 class="clause__group--tit">제 14 조 (회원의 의무)</h2>
                  <p class="clause__group--info">
                    <span>① 회원의 서비스 이용시 다음 각 호의 행위는 금지 되어 집니다.</span>
                    <span class="clause__group--info indent">
                                              <span>1. 다른 회원의 이용자 번호(ID)를 부정하게 사용하는 행위</span>
                                              <span>2. 서비스에서 얻은 정보를 회사의 사전승낙 없이 이용고객의 이용 이외의 목적으로 복제하거나 이를 변경, 출판 및 방송 등에 사용하거나 타인에게 제공하는 행위</span>
                                              <span>3. 당사의 저작권 또는 타인의 저작권 등 기타 권리를 침해하는 행위</span>
                                              <span>4. 공공질서 및 미풍양속에 위반되는 내용의 정보, 문장, 도형 등을 타인에게 유포하는 행위</span>
                                              <span>5. 범죄와 결부된다고 객관적으로 판단되는 행위</span>
                                              <span>6. 관계 법령에 위배되는 행위</span>
                                              <span>7. 기타 당사가 객관적으로 부적절하다고 판단되는 행위</span>
                                        </span>
                    <span>② 회원은 관계 법령 및 당 약관에서 규정하는 사항, 서비스 이용안내 및 주의 사항을 준수하여야 합니다.</span><br />
                    <span>③ 회원은 내용별로 당사가 서비스 공지사항에 게시하거나 별도로 공지한 이용제한 사항을 준수하여야 합니다.</span><br />
                    <span>④ 회원은 회사의 사전 승낙 없이 서비스를 이용하여 어떠한 영리행위도 할 수 없습니다.</span>
                  </p>
                </div>
                <!-- //제 14 조 (회원의 의무) -->
                <!-- 제 15 조 (게시물의 관리) -->
                <div class="clause__group" id="list15">
                  <h2 class="clause__group--tit">제 15 조 (게시물의 관리)</h2>
                  <p class="clause__group--info">
                    <span>① 당사는 다음 각 호에 해당하는 게시물이나 자료를 사전통지 없이 삭제하거나 이동 또는 등록 거부를 할 수 있습니다.</span>
                    <span class="clause__group--info indent">
                                              <span>1. 다른 회원 또는 제3자에게 모욕을 주거나 명예를 훼손하는 내용인 경우</span>
                                              <span>2. 공공질서 및 미풍양속에 위반되는 내용을 유포하거나 링크시키는 경우</span>
                                              <span>3. 불법복제 또는 해킹을 조장하는 경우</span>
                                              <span>4. 영리를 목적으로 하는 광고일 경우</span>
                                              <span>5. 범죄와 결부된다고 객관적으로 인정되는 경우</span>
                                              <span>6. 다른 회원 또는 제3자의 저작권 등 기타 권리를 침해하는 내용인 경우</span>
                                              <span>7. 당사에서 규정한 게시물 원칙에 반하거나, 게시판 성격에 부합하지 않는 경우</span>
                                              <span>8. 기타 관련 법령에 위반된다고 판단되는 경우</span>
                                        </span>
                  </p>
                </div>
                <!-- //제 15 조 (게시물의 관리) -->
                <!--제 16 조 (제공 서비스)  -->
                <div class="clause__group" id="list16">
                  <h2 class="clause__group--tit">제 16 조 (제공 서비스)</h2>
                  <p class="clause__group--info">
                    <span>① 당 사이트는 당사와 회원의 계약의 종류에 따라 다음과 같은 내용을 서비스 합니다.</span>
                    <span class="clause__group--info indent">
                                              <span>1. 입찰공고의 제공</span>
                                              <span>2. 낙찰공고의 제공</span>
                                              <span>3. 분석서비스 회원에 한하여 낙찰 예상가격의 제시</span>
                                              <span>4. 기타 입찰 관련 서비스</span>
                                              <span>※ 위와 같은 내용을 고객이 요청시에는 문자나 카카오톡으로도 서비스합니다.</span>
                                        </span>
                  </p>
                </div>
                <!--//제 16 조 (제공 서비스) -->
                <!-- 제 17 조 (서비스의 중지 및 공지) -->
                <div class="clause__group" id="list17">
                  <h2 class="clause__group--tit">제 17 조 (서비스의 중지 및 공지)</h2>
                  <p class="clause__group--info">
                    <span>① 당사는 전시, 사변, 천재지변 또는 이에 준하는 국가비상사태가 발생하거나 발생할 우려가 있는 경우와 전기통신사업법에 의한 기간 통신사업자가 전기통신 서비스를 중지하는 등 기타 불가항력적 사유가 있는 경우에는 서비스의 전부 또는 일부를 제한하거나 중지할 수 있습니다.</span><br />
                    <span>② 당사는 전 항의 규정에 의하여 서비스의 이용을 제한하거나 중지하는 경우에는 지체 없이 회원에게 공지하여야 합니다. 단, 공지의 방법은 당 사이트의 초기화면에 게재한 것으로 갈음할 수 있습니다.</span><br />
                    <span>③ 당사의 귀책 있는 사유로 다음과 같은 서비스의 장애가 발생하는 경우에는 회원은 각 호와 같은 보상을 요구할 수 있습니다. 단, 장애시간은 회원의 통지시점으로 기산합니다.</span>
                    <span class="clause__group--info indent">
                                            <span>1. 장애 시간이 연속하여 72시간 또는 1개월 누적시간이 총 72시간을 초과하는 경우에는 회원은 해지를 요구할 수 있으며 그 효과는 본 약관에 따릅니다. 단, 위약금에 관한 규정은 적용하지 않습니다.</span>
                                            <span>2. 장애 시간이 연속하여 4시간을 초과하는 경우에는 장애시간의 2배를 연장합니다.</span>
                                      </span>
                  </p>
                </div>
                <!-- //제 17 조 (서비스의 중지 및 공지) -->
                <!-- 제 18 조 (회원의 탈퇴 및 자격상실) -->
                <div class="clause__group" id="list18">
                  <h2 class="clause__group--tit">제 18 조 (회원의 탈퇴 및 자격상실)</h2>
                  <p class="clause__group--info">
                    <span>① 회원이 해지하고자 하는 경우에는 회원 본인이 당 사이트 내에서 언제든지 할 수 있습니다. 단, 회원과 당사 간의 계약내용이 유료인 경우에는 당 사이트 운영자에게 회원이 직접 해지통보를 하여야 합니다.</span><br />
                    <span>② 당사는 회원이 다음 각 호에 해당하는 행위를 하였을 경우 사전통지 없이 서비스 이용에 제한을 둘 수 있습니다.</span>
                    <span class="clause__group--info indent">
                                              <span>1. 타인의 서비스 ID 및 비밀번호를 도용한 경우</span>
                                              <span>2. 서비스 운영을 고의로 방해한 경우</span>
                                              <span>3. 공공질서 및 미풍양속에 저해되는 내용을 고의로 유포시킨 경우</span>
                                              <span>4. 이용고객이 국익 또는 사회적 공익을 저해할 목적으로 서비스 이용을 계획 또는 실행 하는 경우</span>
                                              <span>5. 타인의 명예를 손상시키거나 불이익을 주는 행위를 한 경우</span>
                                              <span>6. 서비스의 안정적 운영을 방해할 목적으로 다량의 정보를 전송하거나 광고성 정보를 전송 하는 경우</span>
                                              <span>7. 정보통신설비의 오작동이나 정보 등의 파괴를 유발시키는 컴퓨터 바이러스 프로그램 등을 유포하는 경우</span>
                                              <span>8. 회사, 다른 이용고객 또는 타인의 지적재산권을 침해하는 경우</span>
                                              <span>9. 정보통신윤리위원회 등 외부기관의 시정요구가 있거나 불법선거운동과 관련하여 선거 관리 위원회의 유권해석을 받은 경우</span>
                                              <span>10. 타인의 개인정보, 이용자 ID 및 비밀번호를 부정하게 사용하는 경우</span>
                                              <span>11. 회사의 서비스 정보를 이용하여 얻은 정보를 회사의 사전 승낙 없이 복제 또는 유통 시키거나 상업적으로 이용하는 경우</span>
                                              <span>12. 이용고객이 게시판에 음란물을 게재하거나 음란사이트를 링크하는 경우</span>
                                              <span>13. 본 약관을 포함하여 기타 회사가 정한 이용조건 및 관계 법령에 위반한 경우</span>
                                              <span>14. 월별 ID 당 트래픽( 전송량 )이 300M 를 초과하는 경우( 해당월은 사용할 수 없습니다. )</span>
                                        </span>
                    <span>③ 당사는 다음 각 호에 행위를 하였을 경우 사전통지 후 개선되지 않을시 강제탈퇴 시킬 수 있습니다.</span>
                    <span class="clause__group--info indent">
                                            <span>1. 기계적인 정보수집 , 매크로 사용 , 정보수집기를 이용하여 정보를 취득하는 경우</span>
                                            <span>2. 당사의 어떠한 정보라도 취득하여 상업적인 용도로 사용하는 경우</span>
                                      </span>
                  </p>
                </div>
                <!--//제 18 조 (회원의 탈퇴 및 자격상실)  -->
                <!--제 19 조 (면책) -->
                <div class="clause__group" id="list19">
                  <h2 class="clause__group--tit">제 19 조 (면책)</h2>
                  <p class="clause__group--info">
                    <span>① 회사는 이용고객이 회사의 서비스 제공으로부터 기대되는 이익을 얻지 못하였거나 서비스 자료에 대한 취사선택 또는 이용으로 발생하는 손해 등에 대해서는 책임이 면제됩니다.</span><br />
                    <span>② 회사는 이용고객의 귀책사유로 인하여 서비스 이용의 장애가 발생한 경우에는 책임이 면제됩니다.</span><br />
                    <span>③ 회사는 이용자가 게시 또는 전송한 자료의 내용에 대해서는 책임이 면제됩니다.</span><br />
                    <span>④ 회사는 이용자 상호간 또는 이용자와 제3자 상호간에 서비스를 매개로 하여 물품거래 등을 한 경우에는 책임이 면제됩니다</span><br />
                    <span>⑤ 회사는 모든 서비스의 자료 보관 및 전송에 관한 책임이 없으며 자료의 손실이 있는 경우 에도 책임이 면제됩니다.</span><br />
                    <span>⑥ 회사는 천재지변 기타 이에 준하는 불가항력으로 인하여 서비스를 제공할 수 없을 경우에는 서비스 제공 중지에 관한 책임을 면합니다.</span><br />
                    <span>⑦ 회사는 회사가 제공하는 서비스망을 통해 제공하는 정보의 신뢰도나 정확성에 대하여는 책임을 면합니다.</span><br />
                    <span>⑧ 회사는 입찰공고 누락 건에 대하여 책임을 면합니다.</span><br />
                    <span>⑨ 회사는 서비스를 통하여 기대하는 손익이나 서비스를 통하여 얻은 자료로 인한 손해에 관하여 책임을 지지 않습니다.</span>
                  </p>
                </div>
                <!-- //제 19 조 (면책)-->
                <!-- 제 20 조 (분쟁의 해결)-->
                <div class="clause__group" id="list20">
                  <h2 class="clause__group--tit">제 20 조 (분쟁의 해결)</h2>
                  <p class="clause__group--info">
                    <span>① 본 약관은 서비스 이용과 관련하여 회사와 이용자간에 발생한 분쟁에 대해서는 수원지방법원 성남 지원을 제1심 관할법원으로 하여 해결한다.</span>
                  </p>
                </div>
                <!-- //제 20 조 (분쟁의 해결) -->
                <!-- 부칙 -->
                <div class="clause__group" id="list21">
                  <h2 class="clause__group--tit">부칙</h2>
                  <h2 class="clause__group--tit">제 1조</h2>
                  <p class="clause__group--info">
                    <span>① 본 약관은 2023.05.22부터 적용됩니다.</span><br />
                    <span>② 기존의 약관은 본 약관으로 대체합니다.</span>
                  </p>
                </div>

                <!-- //부칙 -->
              </div>
            </div>
          </div>
        </div>
        <div class="input popup__button--wrap use-consent__wrap--button single">
          <button id="useAgreeBtn" type="submit" class="btn-basic btn__non-active"  @click="closeUseAgreePop(true)"><p class="txt__empha">동의</p>*약관을 끝까지 보신 후, 동의 가능합니다.</button>
        </div>
      </div>
    </div>
    <!-- /이용약관 동의 팝업 -->
    <!-- 개인정보 수집 동의 팝업 -->
    <div id='etcPrivacyPop' class="popup__wrap use-consent__wrap">
      <div class="popup__item--container use-consent__container">
        <div class="popup__item--group use-consent__wrap--group">
          <div class="popup__item--head">
            <h3 class="txt__title">개인정보 수집 동의</h3>
            <button type="button" class="popup__close" @click="closeEtcPrivacyPop(false)"></button>
          </div>
          <div id="etcAgreeScroll" class="popup__item--body use-consent__wrap--body">
            <div class="use-consent__wrap--box">
              <!-- 이곳에 약관 붙여주세요 -->
              <div class="etcAgree">
                <!-- 제목 -->
                <div class="etcAgree__title-box"><h2 class="etcAgree__title"><i class="sy-icon sy-circle-o"></i>개인정보처리방침</h2></div>
                <!-- 상단 내용 -->
                <div class="agree__box">
                  <p class="agree__privacy--tit">
                    (주)비드톡톡 (www.bidtok.co.kr 이하 “회사” 또는”비드톡톡”이라 함)는 통신비밀보호법, 전기통신사업법, 정보통신망 이용촉진 및 정보 보호 등에 관한 법률 등 정보통신서비스제공자가 준수하여야 할 관련 법령상의 개인정보보호 규정을 준수하며, 관련 법령에 의거한 개인정보처리방침을 정하여 이용자가 제공하는 개인정보가 어떠한 용도와 방식으로 이용하고 있으며, 최선을 다해서 보호하고 있음에 대해 알려드립니다.<br/>
                    비드톡톡의 개인정보처리방침은 아래 내용에 의해 시행됩니다.
                  </p>
                  <ul class="agree__privacy--btn">
                    <li><a href="#" onclick="return false" @click="move('etcAgree_list1')">1. 수집하는 개인정보의 항목 및 수집방법</a></li>
                    <li><a href="#" onclick="return false" @click="move('etcAgree_list2')">2. 개인정보의 수집 및 이용목적</a></li>
                    <li><a href="#" onclick="return false" @click="move('etcAgree_list3')">3. 개인정보 공유 및 제공</a></li>
                    <li><a href="#" onclick="return false" @click="move('etcAgree_list4')">4. 개인정보의 취급위탁</a></li>
                    <li><a href="#" onclick="return false" @click="move('etcAgree_list5')">5. 개인정보의 보유 및 이용기간</a></li>
                    <li><a href="#" onclick="return false" @click="move('etcAgree_list6')">6. 개인정보 파기절차 및 방법</a></li>
                    <li><a href="#" onclick="return false" @click="move('etcAgree_list7')">7. 이용자 및 법정대리인의 권리와 그 행사방법</a></li>
                    <li><a href="#" onclick="return false" @click="move('etcAgree_list8')">8. 개인정보 자동 수집 장치의 설치/운영 및 거부에 관한 사항</a></li>
                    <li><a href="#" onclick="return false" @click="move('etcAgree_list9')">9. 개인정보의 기술적/관리적 보호 대책</a></li>
                    <li><a href="#" onclick="return false" @click="move('etcAgree_list10')">10. 개인정보관리책임자 및 담당자의 연락처</a></li>
                    <li><a href="#" onclick="return false" @click="move('etcAgree_list11')">11. 기타</a></li>
                    <li><a href="#" onclick="return false" @click="move('etcAgree_list12')">12. 고지의 의무</a></li>
                  </ul>
                </div>
                <!-- 상단 내용 -->

                <!-- #1 수집하는 개인정보의 항목 및 수집방법 -->
                <div class="agree__box" id="etcAgree_list1">
                  <h2 class="agree__box--tit">1. 수집하는 개인정보의 항목 및 수집방법</h2>
                  <p class="agree__box--info">
                    1) 수집하는 개인정보의 항목<br/>
                    ①회사는 회원가입, 원활한 고객상담, 각종 서비스의 제공을 위해 최초 회원가입 당시 아래와 같은 개인정보를 수집하고 있습니다.<br/><br/>

                    ＜회원가입 시＞<br/>
                    - 필수항목 : 성명, 아이디, 비밀번호, 본인확인 문답, 이메일 주소<br/>
                    - 선택사항 : 휴대폰번호<br/><br/>

                    ②서비스 이용과정이나 사업처리 과정에서 아래와 같은 정보들이 자동으로 생성되어 수집될 수 있습니다.<br/>
                    - IP Address, 쿠키, 방문 일시, 서비스 이용 기록, 불량 이용 기록<br/><br/>

                    ③부가 서비스 및 맞춤식 서비스 이용 또는 이벤트 응모 과정에서 해당 서비스의 이용자에 한해서만 아래와 같은 정보들이 수집될 수 있습니다.<br/>
                    - 개인정보 추가 수집에 대해 동의를 받는 경우<br/><br/>

                    ④유료 서비스 이용 과정에서 아래와 같은 결제 정보들이 수집될 수 있습니다.<br/>
                    - 신용카드 결제시 : 카드사명, 카드번호 등<br/>
                    - 계좌이체시 : 은행명, 계좌번호 등 <br/>
                    - 상품권 이용시 : 상품권 번호<br/><br/>


                    2) 개인정보 수집방법 <br/>
                    회사는 다음과 같은 방법으로 개인정보를 수집합니다.<br/>
                    - 홈페이지, 서면양식, 팩스, 전화, 상담 게시판, 이메일, 이벤트 응모, 배송요청<br/>
                    - 협력회사로부터의 제공<br/>
                    - 생성정보 수집 툴을 통한 수집
                  </p>
                </div>
                <!-- //#1 수집하는 개인정보의 항목 및 수집방법 -->

                <!-- #2 개인정보의 수집 및 이용목적 -->
                <div class="agree__box" id="etcAgree_list2">
                  <h2 class="agree__box--tit">2. 개인정보의 수집 및 이용목적</h2>
                  <p class="agree__box--info">
                    1) 서비스 제공에 관한 계약 이행 및 서비스 제공에 따른 요금정산<br/>
                    컨텐츠 제공, 특정 맞춤 서비스 제공, 물품배송 또는 청구서 등 발송, 본인인증, 구매 및 요금 결제, 요금추심<br/><br/>


                    2) 회원관리<br/>
                    회원제 서비스 이용 및 제한적 본인 확인제에 따른 본인확인, 개인식별, 불량회원(비드톡톡 이용약관 제20조 1항 목중 제11조 1항 1호~8호 위반사유로 인한 영구이용정지 및 2항에 따라 계약해지된 영구이용정지 회원)의 부정 이용방지와 비인가 사용방지, 가입의사 확인, 가입 및 가입횟수 제한, 만14세 미만 아동 개인정보 수집 시 법정 대리인 동의여부 확인, 추후 법정 대리인 본인확인, 분쟁 조정을 위한 기록보존, 불만처리 등 민원처리, 고지사 항 전달<br/><br/>


                    3) 신규 서비스 개발 및 마케팅<br/>
                    신규 서비스 개발 및 맞춤 서비스 제공, 통계학적 특성에 따른 서비스 제공 및 광고 게재, 서비스의 유효성 확인, 이벤트 및 광고성 정보 제공 및 참여기회 제공, 접속빈도 파악, 회원의 서비스이용에 대한 통계
                  </p>
                </div>
                <!-- //#2 개인정보의 수집 및 이용목적 -->

                <!-- #3 개인정보 공유 및 제공 -->
                <div class="agree__box" id="etcAgree_list3">
                  <h2 class="agree__box--tit">3. 개인정보의 공유 및 제공</h2>
                  <p class="agree__box--info">
                    회사는 이용자들의 개인정보를 "2. 개인정보의 수집목적 및 이용목적"에서 고지한 범위내에서 사용하며, 이용 자의 사전 동의 없이는 동 범위를 초과하여 이용하거나 원칙적으로 이용자의 개인정보를 외부에 공개하지 않습니다. 다만, 아래의 경우에는 예외로 합니다.<br/><br/>

                    - 이용자들이 사전에 공개에 동의한 경우<br/>
                    - 법령의 규정에 의거하거나, 수사 목적으로 법령에 정해진 절차와 방법에 따라 수사기관의 요구가 있는 경우
                  </p>
                </div>
                <!-- //#3 개인정보 공유 및 제공 -->

                <!-- #4 개인정보의 취급위탁 -->
                <div class="agree__box" id="etcAgree_list4">
                  <h2 class="agree__box--tit">4. 개인정보의 취급위탁</h2>
                  <p class="agree__box--info">
                    회사는 서비스 향상을 위해서 아래와 같이 개인정보를 위탁하고 있으며, 관계 법령에 따라 위탁계약 시 개인정 보가 안전하게 관리될 수 있도록 필요한 사항을 규정하고 있습니다.<br/>
                    회사의 개인정보 위탁처리 기관 및 위탁업무 내용은 아래와 같습니다.<br/><br/>

                    - 수탁업체 : (주)비드톡톡<br/>
                    - 위탁업무 내용 : 개인정보의 보관/관리, 서비스 제공을 위한 시스템 개발 및 운영, 서비스 개발 및 테스트 <br/>
                    - 위탁기간 : 회원탈퇴시 혹은 위탁계약 종료 시까지
                  </p>
                </div>
                <!-- /#4 개인정보의 취급위탁 -->

                <!-- #5 개인정보의 보유 및 이용기간 -->
                <div class="agree__box" id="etcAgree_list5">
                  <h2 class="agree__box--tit">5. 개인정보의 보유 및 이용기간</h2>
                  <p class="agree__box--info">
                    이용자의 개인정보는 원칙적으로 개인정보의 수집 및 이용목적이 달성되면 지체 없이 파기합니다. 단, 다음의 정보에 대해서는 아래의 이유로 명시한 기간 동안 보존합니다.<br/><br/>
                    1) 회사 내부 방침에 의한 정보보유 사유 <br/>
                    - 부정이용기록<br/>
                    보존 이유 : 부정 이용 방지<br/>
                    보존 기간 : 1년<br/><br/>


                    2) 관련법령에 의한 정보보유 사유<br/>
                    상법, 전자상거래 등에서의 소비자보호에 관한 법률 등 관계법령의 규정에 의하여 보존할 필요가 있는 경우 회 사는 관계법령에서 정한 일정한 기간 동안 회원정보를 보관합니다. 이 경우 회사는 보관하는 정보를 그 보관의 목적으로만 이용하며 보존기간은 아래와 같습니다.<br/><br/>


                    ＜계약 또는 청약철회 등에 관한 기록><br/>
                    - 보존 이유 : 전자상거래 등에서의 소비자보호에 관한 법률<br/>
                    - 보존 기간 : 5년<br/><br/>

                    ＜대금결제 및 재화 등의 공급에 관한 기록＞<br/>
                    - 보존 이유 : 전자상거래 등에서의 소비자보호에 관한 법률 <br/>
                    - 보존 기간 : 5년 <br/><br/>

                    ＜소비자의 불만 또는 분쟁처리에 관한 기록＞<br/>
                    - 보존 이유 : 전자상거래 등에서의 소비자보호에 관한 법률 <br/>
                    - 보존 기간 : 3년<br/><br/>

                    ＜본인확인에 관한 기록><br/>
                    - 보존 이유 : 정보통신 이용촉진 및 정보보호 등에 관한 법률<br/>
                    - 보존 기간 : 6개월<br/><br/>

                    ＜방문에 관한 기록><br/>
                    - 보존 이유 : 통신비밀보호법<br/>
                    - 보존 기간 : 3개월
                  </p>
                </div>
                <!-- //#5 개인정보의 보유 및 이용기간 -->

                <!-- #6 개인정보 파기절차 및 방법 -->
                <div class="agree__box" id="etcAgree_list6">
                  <h2 class="agree__box--tit">6. 개인정보 파기절차 및 방법</h2>
                  <p class="agree__box--info">
                    이용자의 개인정보는 원칙적으로 개인정보의 수집 및 이용목적이 달성되면 지체 없이 파기합니다.<br/>
                    회사의 개인정보 파기절차 및 방법은 다음과 같습니다.<br/><br/>


                    1) 파기절차<br/>
                    - 이용자가 회원가입 등을 위해 입력한 정보는 목적이 달성된 후 별도의 DB로 옮겨져(종이의 경우 별도의 서류 함) 내부 방침 및 기타 관련 법령에 의한 정보보호 사유에 따라(보유 및 이용기간 참조)일정 기간 저장된 후 파 기됩니다.<br/>
                    - 동 개인정보는 법률에 의한 경우가 아니고서는 보유되는 이외의 다른 목적으로 이용되지 않습니다.<br/><br/>


                    2) 파기방법<br/>
                    - 종이에 출력된 개인정보는 분쇄기로 분쇄하거나 소각을 통하여 파기합니다.<br/>
                    - 전자적 파일 형태로 저장된 개인정보는 기록을 재생할 수 없는 기술적 방법을 사용하여 삭제합니다.
                  </p>
                </div>
                <!-- //#6 개인정보 파기절차 및 방법 -->

                <!-- #7 이용자 및 법정대리인의 권리와 그 행사방법 -->
                <div class="agree__box" id="etcAgree_list7">
                  <h2 class="agree__box--tit">7. 이용자 및 법정대리인의 권리와 그 행사방법</h2>
                  <p class="agree__box--info">
                    - 이용자 및 법정 대리인은 언제든지 등록되어 있는 자신 혹은 당해 만 14세 미만 아동의 개인정보를 조회하거 나 수정할 수 있으며 가입해지를 요청할 수도 있습니다.<br/><br/>

                    - 이용자 혹은 만 14세 미만 아동의 개인정보 조회, 수정을 위해서는 '개인정보변경'(또는 '회원정보수정' 등), 가입해지(동의철회)를 위해서는 "회원탈퇴"를 클릭하여 본인 확인 절차를 거치신 후 직접 열람, 정정 또는 탈 퇴가 가능합니다.<br/><br/>

                    - 혹은 개인정보관리책임자에게 서면, 전화 또는 이메일로 연락하시면 지체 없이 조치하겠습니다.<br/><br/>

                    - 이용자가 개인정보의 오류에 대한 정정을 요청하신 경우에는 정정을 완료하기 전까지 당해 개인정보를 이용 또는 제공하지 않습니다. 또한 잘못된 개인정보를 제3 자에게 이미 제공한 경우에는 정정 처리결과를 제3자에 게 지체 없이 통지하여 정정이 이루어지도록 하겠습니다.<br/><br/>

                    - 회사는 이용자 혹은 법정 대리인의 요청에 의해 해지 또는 삭제된 개인정보는 "5. 개인정보의 보유 및 이용기 간"에 명시된 바에 따라 처리하고 그 외의 용도로 열람 또는 이용할 수 없도록 처리하고 있습니다.
                  </p>
                </div>
                <!-- //#7 이용자 및 법정대리인의 권리와 그 행사방법 -->

                <!-- #8 개인정보 자동 수집 장치의 설치/운영 및 거부에 관한 사항 -->
                <div class="agree__box" id="etcAgree_list8">
                  <h2 class="agree__box--tit">8. 개인정보 자동 수집 장치의 설치/운영 및 거부에 관한 사항</h2>
                  <p class="agree__box--info">
                    회사는 이용자들에게 특화된 맞춤서비스를 제공하기 위해서 이용자들의 정보를 저장하고 수시로 불러오는 '쿠 키(cookie)'를 사용합니다. 쿠키는 웹사이트를 운영하는데 이용되는 서버(HTTP)가 이용자의 컴퓨터 브라우저 에게 보내는 소량의 정보이며 이용자들의 PC 컴퓨터내의 하드디스크에 저장되기도 합니다.<br/><br/>


                    1) 쿠키의 사용 목적 <br/>
                    이용자들이 방문한 더비스의 각 서비스와 웹 사이트들에 대한 방문 및 이용형태, 인기 검색어, 보안접속 여부, 뉴스편집, 이용자 규모 등을 파악하여 이용자에게 최적화된 정보 제공을 위하여 사용합니다.<br/><br/>


                    2) 쿠키의 설치/운영 및 거부 <br/>
                    - 이용자는 쿠키 설치에 대한 선택권을 가지고 있습니다. 따라서, 이용자는 웹브라우저에서 옵션을 설정함으로 써 모든 쿠키를 허용하거나, 쿠키가 저장될 때마다 확인을 거치거나, 아니면 모든 쿠키의 저장을 거부할 수도 있습니다. <br/>
                    - 쿠키 설정을 거부하는 방법으로는 이용자가 사용하는 웹 브라우저의 옵션을 선택함으로써 모든 쿠키를 허용 하거나 쿠키를 저장할 때마다 확인을 거치거나, 모든 쿠키의 저장을 거부할 수 있습니다.<br/>
                    - 설정방법 예(인터넷 익스플로어의 경우) : 웹 브라우저 상단의 도구 > 인터넷 옵션 > 개인정보 <br/>
                    - 다만, 쿠키의 저장을 거부할 경우에는 로그인이 필요한 더비스 일부 서비스는 이용에 어려움이 있을 수 있습니다. <br/>
                  </p>
                </div>
                <!-- //#8 개인정보 자동 수집 장치의 설치/운영 및 거부에 관한 사항 -->

                <!-- #9 개인정보의 기술적/관리적 보호 대책 -->
                <div class="agree__box" id="etcAgree_list9">
                  <h2 class="agree__box--tit">9. 개인정보의 기술적/관리적 보호 대책</h2>
                  <p class="agree__box--info">
                    회사는 이용자들의 개인정보를 취급함에 있어 개인정보가 분실, 도난, 누출, 변조 또는 훼손되지 않도록 안전성 확보를 위하여 다음과 같은 기술적/관리적 대책을 강구하고 있습니다.<br/><br/>


                    1) 비밀번호 암호화<br/>
                    회원 아이디(ID)의 비밀번호는 암호화되어 저장 및 관리되고 있어 본인만이 알고 있으며, 개인정보의 확인 및 변경도 비밀번호를 알고 있는 본인에 의해서만 가능합니다.<br/><br/>


                    2) 해킹 등에 대비한 대책<br/>
                    회사는 해킹이나 컴퓨터 바이러스 등에 의해 회원의 개인정보가 유출되거나 훼손되는 것을 막기 위해 최선을 다하고 있습니다. 개인정보의 훼손에 대비해서 자료를 수시로 백업하고 있고, 최신 백신프로그램을 이용하여 이용자들의 개인정 보나 자료가 누출되거나 손상되지 않도록 방지하고 있으며, 암호화통신 등을 통하여 네트워크상에서 개인정보 를 안전하게 전송할 수 있도록 하고 있습니다. 그리고 침입차단시스템을 이용하여 외부로부터의 무단 접근을 통제하고 있으며, 기타 시스템적으로 보안성을 확보하기 위한 가능한 모든 기술적 장치를 갖추려 노력하고 있습니다.<br/><br/>


                    3) 취급 직원의 최소화 및 교육<br/>
                    회사의 개인정보관련 취급 직원은 담당자에 한정시키고 있고 이를 위한 별도의 비밀번호를 부여하여 정기적으 로 갱신하고 있으며, 담당자에 대한 수시 교육을 통하여 더비스 개인정보처리방침의 준수를 항상 강조하고 있 습니다.<br/><br/>

                    4) 개인정보보호전담기구의 운영<br/>
                    그리고 사내 개인정보보호전담기구 등을 통하여 비드톡톡 개인정보처리방침의 이행사항 및 담당자의 준수여부를 확인하여 문제가 발견될 경우 즉시 수정하고 바로 잡을 수 있도록 노력하고 있습니다. 단, 이용자 본인의 부주의나 인터넷상의 문제로 ID, 비밀번호 등 개인정보가 유출되어 발생한 문제에 대해 회사는 일체의 책임을 지지 않습니다.
                  </p>
                </div>
                <!-- //#9 개인정보의 기술적/관리적 보호 대책 -->

                <!-- #10 개인정보관리책임자 및 담당자의 연락처 -->
                <div class="agree__box" id="etcAgree_list10">
                  <h2 class="agree__box--tit">10. 개인정보관리책임자 및 담당자의 연락처</h2>
                  <p class="agree__box--info">
                    귀하께서는 회사의 서비스를 이용하시며 발생하는 모든 개인정보보호 관련 민원을 개인정보관리책임자 혹은 담당부서로 신고하실 수 있습니다.
                    회사는 이용자들의 신고사항에 대해 신속하게 충분한 답변을 드릴 것입니다.<br/><br/>

                    <span ng-if="privacyInfo.seniorName">
                개인정보 관리 책임자<br/><br/>

                이 름 : 최경호<br/>
                소 속 : 비드톡톡<br/>
                전 화 : 1670-0508<br/>
                메 일 : khchoi@bidtok.co.kr<br/><br/>
            </span>

                    <span>
                개인정보 관리 담당자<br/><br/>

                이 름 : 이정훈<br/>
                소 속 : 비드톡톡<br/>
                전 화 : 1670-0508<br/>
                메 일 : jhlee@bidtok.co.kr<br/><br/>
            </span>


                    기타 개인정보침해에 대한 신고나 상담이 필요하신 경우에는 아래 기관에 문의하시기 바랍니다.<br/>

                    - 개인정보분쟁조정위원회 (<a href="https://www.kopico.go.kr/" target="_blank">www.kopico.go.kr</a> / 1833-6972)<br>
                    - 정보보호마크인증위원회 (<a href="https://www.eprivacy.or.kr/" target="_blank">www.eprivacy.or.kr</a> / 02-550-9500)<br>
                    - 대검찰청 인터넷범죄수사센터 (<a href="http://www.spo.go.kr/" target="_blank">www.spo.go.kr</a> / 국번없이 1301(유료))<br>
                    - 경찰청 사이버안전국 (<a href="http://www.police.go.kr/" target="_blank">www.police.go.kr</a> / 민원대표전화 182(유료)) <br>
                  </p>
                </div>
                <!-- //#10 개인정보관리책임자 및 담당자의 연락처 -->

                <!-- #11 기타 -->
                <div class="agree__box" id="etcAgree_list11">
                  <h2 class="agree__box--tit">11. 기타</h2>
                  <p class="agree__box--info">
                    비드톡톡에 링크되어 있는 웹사이트들이 개인정보를 수집하는 행위에 대해서는 본 "비드톡톡 개인정보처리방침"이 적용되지 않음을 알려 드립니다.
                  </p>
                </div>
                <!-- //#11 기타 -->

                <!-- #12 고지의 의무 -->
                <div class="agree__box" id="etcAgree_list12">
                  <h2 class="agree__box--tit">12.고지의 의무</h2>
                  <p class="agree__box--info">
                    현 개인정보처리방침 내용 추가, 삭제 및 수정이 있을 시에는 개정 최소 7일전부터 홈페이지의 '공지사항'을 통해 고지할 것입니다.<br/><br/>


                    - 공고일자 : 2023.05.22<br/>
                    - 시행일자 : 2023.05.22
                  </p>
                </div>
                <!-- //#12 고지의 의무 -->
              </div>
            </div>
          </div>
        </div>
        <div class="input popup__button--wrap use-consent__wrap--button single">
          <button id="etcAgreeBtn" type="submit" class="btn-basic btn__non-active" @click="closeEtcPrivacyPop(true)"><p class="txt__empha">동의</p>*약관을 끝까지 보신 후, 동의 가능합니다.</button>
        </div>
      </div>
    </div>
    <!-- /개인정보 수집 동의 팝업 -->
    <!-- 광고성 정보 수신 동의(선택) -->
    <div id='mktAgreePop' class="popup__wrap ad-accept">
      <div class="popup__item--container">
        <a class="popup__item--group" href="#" onclick="return false">
          <div class="popup__item--head">
            <p class="txt__title">광고성 정보 수신 동의</p>
          </div>
          <div class="popup__item--body ad-accept__item--body">
            <p class="txt__sub">
              비드톡톡에서 제공하는<br>
              혜택과 이벤트 등 광고성 정보를<br>
              휴대전화(전화, SMS, 푸시 알림 등)로<br>
              받아보실 수 있습니다.<br>
              <span class="ad-accept__txt--empha">광고성 정보 수신에 동의하십니까?</span>
            </p>
          </div>
        </a>
        <div class="popup__button--wrap pair">
          <a href="#" onclick="return false" class="btn-basic btn-line-cb popup__close" @click="closeMktPop(false)">동의하지 않음</a>
          <a href="#" onclick="return false" class="btn-basic btn-cta popup__close" @click="closeMktPop(true)">동의</a>
        </div>
      </div>
      <!--     // popup-->
    </div>
    <!-- /광고성 정보 수신 동의(선택) -->

  </div>


<!--    <div>-->
<!--      <input type="text" placeholder="우편번호" v-model="zonecode" readonly />-->
<!--      <button id="postcode" @click="openPostcode">검색</button><br />-->
<!--      <input type="text" v-model="roadAddress" placeholder="주소" readonly /><br />-->
<!--      <input type="text" v-model="detailAddress" placeholder="상세주소" />-->
<!--    </div>-->
</template>

<script>
  import LoadingSpinner from '@/components/common/LoadingSpinner.vue';

  export default {
    name: 'JoinForm',
    metaInfo: {
      title: '[비드톡톡 회원가입] - 전자입찰 나라장터(g2b) 공고 투찰 낙찰',
      link: [{ rel: "canonical", href: 'https://www.bidtok.co.kr/' }],
      meta: [
        { vmid: "description", name: 'description', content: '비드톡톡의 회원이 되시고 분석을 시작해 보세요! 사업자등록번호로 회원가입을 하실 수 있습니다. 비드톡톡의 회원이 되시면, 실시간 공고 확인, 무제한 공고 가격 분석 요청, 시간과 장소에 상관 없고 빠른 AI 분석 기능을 이용하실 수 있습니다.' },
        { vmid: "og:title", property: 'og:title', content: '[비드톡톡 회원가입] - 전자입찰 나라장터(g2b) 공고 투찰 낙찰' },
        { vmid: "og:url", property: 'og:url', content: 'https://www.bidtok.co.kr/' },
        { vmid: "og:description", property: 'og:description', content: '비드톡톡의 회원이 되시고 분석을 시작해 보세요! 사업자등록번호로 회원가입을 하실 수 있습니다. 비드톡톡의 회원이 되시면, 실시간 공고 확인, 무제한 공고 가격 분석 요청, 시간과 장소에 상관 없고 빠른 AI 분석 기능을 이용하실 수 있습니다.' },
        { vmid: "og:image", property: "og:image" , content : "favicon.ico"},
      ]
    },
    components: {
      LoadingSpinner,
    },
    data() {
      return {
        bizNo: '',
        corpNm: '',
        userNm: '',
        rpsntvNm: '',
        custPw: '',
        custTelno: '',
        custEmail: '',
        custDstnctCd: '9',
        custAddr: '',
        custDtlAdrs: '',
        custLandLineNo: '',
        utlzClauseAgreYn: false,
        indvdlinfoClauseAgreYn: false,
        mrktgClauseAgreYn: false,
        isBizNoExist: false,
        smsValidate: false,
        zonecode: "",
        roadAddress: "",
        detailAddress: "",
        areaList: [],
        licnsCdList: [],
        password: "",
        passwordConfirm: "",
        phoneNo: "",
        certNo: '',

        showPassword: false,
        showPasswordConfirm: false,
        phoneValidate: false,
        sendCryalTelno: '',

        Timer: null,
        TimeCounter: 180,
        TimerStr: "03:00",
        etcAgreeBtn: false,

        isBizLoading: false,
        isJoinLoading: false,
      };
    },
    mounted() {
      const script = document.createElement('script');
      script.src = 'https://t1.daumcdn.net/mapjsapi/bundle/postcode/prod/postcode.v2.js';
      document.head.appendChild(script);

      this.getAreaList();

      /* 이용약관 스크롤*/
      const useAgreeScroll = document.getElementById('useAgreeScroll');

      useAgreeScroll.addEventListener('scroll', function(){
        let popScrollHeight = useAgreeScroll.scrollHeight; // 스크롤 전체 높이
        let popScrollTop = useAgreeScroll.scrollTop; // 스크롤 현재 높이
        if (useAgreeScroll.getBoundingClientRect().height + popScrollTop > popScrollHeight - 3) {
          const useAgreeBtn = document.getElementById('useAgreeBtn');
          useAgreeBtn.classList.remove('btn__non-active');
          useAgreeBtn.classList.add('btn__active');
          // useAgreeBtn.disabled = false;
          // this.useAgreeRead = true;
          // console.log(this.useAgreeRead);
          //요기!!!!!!
        }
      });
      /* 개인정보 스크롤 이벤트*/
      const etcAgreeScroll = document.getElementById('etcAgreeScroll');

      etcAgreeScroll.addEventListener('scroll', function(){
        let popScrollHeight = etcAgreeScroll.scrollHeight; // 스크롤 전체 높이
        let popScrollTop = etcAgreeScroll.scrollTop; // 스크롤 현재 높이
        if (etcAgreeScroll.getBoundingClientRect().height + popScrollTop > popScrollHeight - 3) {
          const etcAgreeBtn = document.getElementById('etcAgreeBtn');
          // etcAgreeBtn.disabled = false;
          etcAgreeBtn.classList.remove('btn__non-active');
          etcAgreeBtn.classList.add('btn__active');
          //요기!!!!!!
        }
      });

      if (document.querySelector('.join-form__wrap') && window.innerWidth > 800 ) {
        document.querySelector('body').style.backgroundColor = '#f5f6fa';
      }

      /* 모바일 - 주소창 높이 뺀 높이 계산하기*/
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty("--vh", `${vh}px`);

      /*fomm submit 막기*/
      // const inputPw = document.getElementById('inputPw');

      document.getElementById('inputPw').addEventListener('keydown', function(event) {
        if (event.keyCode === 13) {
          event.preventDefault();
        }
      }, true);

      document.getElementById('inputPwConf').addEventListener('keydown', function(event) {
        if (event.keyCode === 13) {
          event.preventDefault();
        }
      }, true);

      // const popScrollArray = Array.from(document.querySelectorAll('.popup__item--body'));
      //
      // popScrollArray.forEach(element => {
      //   element.addEventListener('scroll', function(){
      //     let popScrollHeight = element.scrollHeight; // 스크롤 전체 높이
      //     let popScrollTop = element.scrollTop; // 스크롤 현재 높이
      //     if (element.getBoundingClientRect().height + popScrollTop > popScrollHeight - 3) {
      //       console.log('요기');
      //       //요기!!!!!!
      //     }
      //   });
      // });
    },
    methods: {
      move(message) {
        //페이지 이동
        document.getElementById(message).scrollIntoView({behavior:'smooth'});
      },
      timerStart: function() {
        // 1초에 한번씩 start 호출
        this.TimeCounter = 180;
        var interval = setInterval(() => {
          this.TimeCounter--; //1초씩 감소
          this.TimerStr = this.prettyTime();
          if (this.TimeCounter <= 0) this.timerStop(interval);
        }, 1000);
        return interval;
      },
      timerStop: function(Timer) {
        clearInterval(Timer);
        this.TimeCounter = 0;
      },
      prettyTime: function() {
        // 시간 형식으로 변환 리턴
        let time = this.TimeCounter / 60;
        let minutes = parseInt(time);
        let secondes = Math.round((time - minutes) * 60);
        return (
                minutes.toString().padStart(2, "0") +
                ":" +
                secondes.toString().padStart(2, "0")
        );
      },
      openPostcode() {
        new window.daum.Postcode({
          oncomplete: (data) => {
            // 팝업에서 검색결과 항목을 클릭했을때 실행할 코드를 작성하는 부분
            // this.zonecode = data.zonecode;
            this.custAddr = data.roadAddress;
          },
        }).open();
      },
      bizNoKeyUp(){
        if(window.event.keyCode==13){
          this.getIsBizNoExist()
        }
      },
      checkInput(target,value){
        const html = document.getElementById(target);

        if(value){
          html.style.display = 'block';
        } else {
          html.style.display = 'none';
        }
      },

      checkPassword() {
        // 비밀번호 형식 검사(영문, 숫자, 특수문자)
        const validatePassword = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d$@$!%*#?~^<>,.&+=]{6,15}$$/
        const matches = validatePassword.test(this.password);

        return matches;
      },

      checkPhoneNo() {
        // 비밀번호 형식 검사(영문, 숫자, 특수문자)
        const validatePassword = /^(?:(010-\d{4})|(01[1|6|7|8|9]-\d{3,4}))-(\d{4})$/
        const matches = validatePassword.test(this.phoneNo);

        this.phoneValidate = matches;
        return matches;
      },
      validation() {
        if (!this.isBizNoExist) {
          this.$toast.error("사업자등록번호를 조회해 주세요.");
          return false;
        }
        if (this.corpNm.length==0) {
          this.$toast.error("상호명을 입력해 주세요.");
          this.$refs.corpNm.focus();
          return false;
        }
        if (this.userNm.length==0) {
          this.$toast.error("사용자명을 입력해 주세요.");
          this.$refs.userNm.focus();
          return false;
        }
        if (this.password.length==0) {
          this.$toast.error("비밀번호를 입력해 주세요.");
          this.$refs.password.focus();
          return false;
        }
        if (this.password.length!=0 && !this.checkPassword()) {
          this.$toast.error("비밀번호가 조건에 맞지 않습니다.\n조건에 맞게 다시 입력해 주세요.");
          this.$refs.password.focus();
          return false;
        }
        if (this.password != this.passwordConfirm) {
          this.$toast.error("비밀번호가 일치하지 않습니다. 다시 입력해 주세요.");
          this.$refs.passwordConfirm.focus();
          return false;
        }
        if (this.phoneNo.length == 0) {
          this.$toast.error("휴대폰번호를 입력해 주세요.");
          this.$refs.phoneNo.focus();
          return false;
        }
        if (this.phoneNo.length!=0 && !this.checkPhoneNo()) {
          this.$toast.error("휴대폰번호 양식이 일치하지 않습니다.");
          this.$refs.phoneNo.focus();
          return false;
        }

        if (this.sendCryalTelno != this.phoneNo.replaceAll('-', '')) {
          this.$toast.error("인증번호를 인증해 주세요.");
          this.$refs.certNo.focus();
          return false;
        }
        if (this.smsValidate == false) {
          this.$toast.error("인증번호를 인증해 주세요.");
          this.$refs.certNo.focus();
          return false;
        }
        if (this.utlzClauseAgreYn == false) {
          this.$toast.error("이용약관에 동의해 주세요.");
          return false;
        }

        if (this.indvdlinfoClauseAgreYn == false) {
          this.$toast.error("개인정보 수집에 동의해 주세요.");
          return false;
        }
        return true;
      },
      async join(){
        if(!this.validation()){
          return ;
        }
        this.isJoinLoading = true;
        var _body = {
          "custIdNm": this.bizNo.replaceAll('-', ''), //id 를 사업자 번호로 우선 지정
          "bizNo": this.bizNo.replaceAll('-', ''),
          "cmpNm": this.corpNm,
          "rpsntvNm": this.userNm,
          "userNm": this.userNm,
          "custPw": this.password,
          "custTelno": this.phoneNo,
          "custEmail": '',
          "custDstnctCd": '9', // 우선 진비드로 다 넣음
          "custAddr": this.custAddr,
          "custDtlAdrs": this.custDtlAdrs,
          "custLandlineno": this.custLandLineNo,
          "utlzClauseAgreYn": this.utlzClauseAgreYn ? 'Y' : 'N',
          "indvdlinfoClauseAgreYn": this.indvdlinfoClauseAgreYn ? 'Y' : 'N',
          "mrktgClauseAgreYn": this.mrktgClauseAgreYn ? 'Y' : 'N',
          "custTelnoList": [
            {
              "custTelnoDstnctCd": '1', // 대표자
              "recvr": this.corpNm, // 대표자명
              "custTelno": this.phoneNo, // 대표자 연락처
            },
          ],
          "custBizList": [
            {
              "utlzClauseAgreYn": 'Y', // 이용약관 동의 (임시)
              "areaCd": this.areaCd,
              "custLicnsList": this.licnsCdList
            }
          ],
        };

        await this.$http.post('/api/cust/insert',_body)
                .then(async res => {
                  if (res.data['code'] == 'OK') {

                    await this.sendSms();
                  }
                })
                .catch(error => console.log(error));
        setTimeout(() => {
          this.isJoinLoading = false;
        }, 300);

      },
      sendcertNo(){
        if(this.checkPhoneNo()){
          var param = {"cryalTelno": this.phoneNo.replaceAll('-', '')};

          this.sendCryalTelno = this.phoneNo.replaceAll('-', '');

          this.$http.post('/api/launcher/extrnl/sendSms',param)
                  .then(res => {
                    if(res['status'] == 'OK'){
                      this.$toast.success("인증번호가 발송되었습니다.\n인증번호를 입력해 주세요.");
                    }
                    //문자발송성공시 호출
                    if(this.Timer != null){
                      this.timerStop(this.Timer);
                      this.Timer = null;
                    }
                    this.Timer = this.timerStart();
                  })
                  .catch(error => console.log(error));
        }

      },
      checkSms() {
        if(this.certNo.length == 6){
          var param = {"cryalTelno": this.phoneNo.replaceAll('-', ''), "authzNo": this.certNo,};

          this.$http.post('/api/launcher/extrnl/checkSms',param)
                  .then(res => {
                    if(res.data['status'] == 'OK'){
                      this.smsValidate = true;
                      this.checkInput('certNo2',!this.smsValidate );
                      this.$toast.success("휴대폰번호 인증 되었습니다.", );
                      this.timerStop( this.Timer);
                    }else{
                      this.$toast.error("휴대폰 인증 번호를 다시 확인해 주세요.");
                    }
                  })
                  .catch(()=>{
                    this.$toast.error("휴대폰 인증 번호를 다시 확인해 주세요.");
                  });
                  // .catch(error => console.log(error));
        }
      },
      openUtlzCaluseAgree(){
        var useAgreePop = document.getElementById('useAgreePop');

        if (!useAgreePop.classList.contains('active')) {
          useAgreePop.classList.add('active');
          document.querySelector('body').classList.add('expand');
        }
      },

      openIndvdlinfoClauseAgree(){
        var etcPrivacyPop = document.getElementById('etcPrivacyPop');

        if (!etcPrivacyPop.classList.contains('active')) {
          etcPrivacyPop.classList.add('active');
          document.querySelector('body').classList.add('expand');
        }
      },
      closeUseAgreePop(value){
        var useAgreePop = document.getElementById('useAgreePop');
        document.querySelector('body').classList.remove('expand');

        if(value){
          const useAgreeBtn = document.getElementById('useAgreeBtn');

          if (useAgreeBtn.classList.contains('btn__active')) {
            this.utlzClauseAgreYn = true;
            useAgreePop.classList.remove('active');
          } else {
            this.$toast.error("약관을 끝까지 보신 후, 동의 가능합니다.");
          }
        } else {
          useAgreePop.classList.remove('active');
        }
        this.$refs.agree.focus();
      },
      closeEtcPrivacyPop(value){
        var etcPrivacyPop = document.getElementById('etcPrivacyPop');
        document.querySelector('body').classList.remove('expand');

        if(value){
          const etcAgreeBtn = document.getElementById('etcAgreeBtn');

          if (etcAgreeBtn.classList.contains('btn__active')) {
            this.indvdlinfoClauseAgreYn = true;
            etcPrivacyPop.classList.remove('active');
          } else {
            this.$toast.error("약관을 끝까지 보신 후, 동의 가능합니다.");
          }
        } else {
          etcPrivacyPop.classList.remove('active');
        }
        this.$refs.agree.focus();

      },

      closeMktPop(value){
        var mktAgreePop = document.getElementById('mktAgreePop');

        if (mktAgreePop.classList.contains('active')) {
          if(value){
            this.mrktgClauseAgreYn = true;
          }else{
            this.mrktgClauseAgreYn = false;
          }
          mktAgreePop.classList.remove('active');
        }
      },
      checkUtlzCaluseAgree(){
        let isBottom = (this.useAgreeScreen.document.documentElement.scrollTop + window.innerHeight + 200 > this.useAgreeScreen.document.documentElement.offsetHeight);
        if(isBottom){
          this.utlzClauseAgreYn = true;
        }

      },
      openMktAgree() {
        var mktAgreePop = document.getElementById('mktAgreePop');

        if (!mktAgreePop.classList.contains('active')) {
          mktAgreePop.classList.add('active');
        }
      },
      checkIndvdlinfoClauseAgree(){
        let isBottom = (this.etcPrivacyScreen.document.documentElement.scrollTop + window.innerHeight + 200 > this.etcPrivacyScreen.document.documentElement.offsetHeight);
        if(isBottom){
          this.indvdlinfoClauseAgreYn = true;
        }
      },
      fnShowPassword(value){
        this.showPassword = value;

      },
      fnShowPasswordConfirm(value){
        this.showPasswordConfirm = value;

      },
      getAreaList() {
        this.$http.get('/api/noti/areaCodeList')
                .then(res => {
                  this.areaList = res.data['areaList'];
                })
                .catch(error => console.log(error));
      },
      async getIsBizNoExist() {
        if(!this.isBizLoading){
          var postData = {
            bizNo: this.bizNo.replaceAll('-',''),
            custDstnctCd: 9,
          };
          if( this.bizNo.replaceAll('-','').length != 10){
            this.$toast.error("사업자등록번호 양식이 일치하지 않습니다.");
            return;
          }

          this.isBizLoading = true;

          await this.$http.post('/api/cust/isBizNoExist',postData)
                  .then(async res => {
                    //사용중인 사용자가 없을때
                    if (!res.data['isBizNoExist']) {
                      await this.getG2bBizInfo();
                    } else {
                      this.$toast.error("이미 가입한 사업자 입니다.");
                    }
                  })
                  .catch(()=>{
                  });

          setTimeout(() => this.isBizLoading = false, 500);

        }
      },
      async sendSms() {
        var clintIP = await this.$getPublicIP();

        var params = {
          "recptnTelno": this.phoneNo.replaceAll('-', ''),
          "clientIp": clintIP,
          "indvdlinfoAuthzYn": 'Y'
        };

        await this.$http.post('/api/launcher/extrnl/sendAppLink',params)
        // eslint-disable-next-line no-unused-vars
                .then(res => {
                  // this.$toast.success("문자 발송이 완료되었습니다.");

                  window.location.href = '/joinComplete';
                })
                .catch(()=>{
                  window.location.href = '/joinComplete';
                });

      },

        joinScrollFocus(){
            let scrollFocusTrigger = document.querySelector('.join-form__wrap .refer-character'); // 이벤트 트리거
            // let scrollFocusTarget = document.querySelector('.join-form__wrap--input.password input'); // 포커스 대상

            let targetLocation = scrollFocusTrigger.getBoundingClientRect().top;
            let moveLocation = targetLocation;

            window.scroll({top: moveLocation, left: 0,behavior: 'smooth'});
        },
      async getG2bBizInfo() {

        var postData = {
          bizNo: this.bizNo.replaceAll('-',''),
        };

        await this.$http.post('/api/g2bApi/g2bBizInfo',postData)
                .then(res => {
                  var data = res.data['data'];
                  if(res.data.status == 'OK'){

                    this.isBizNoExist = true;
                    this.corpNm = data['corpNm'];
                    this.userNm = data['ceoNm'];

                    this.custAddr = data['adrs'];
                    this.custDtlAdrs = data['dtlAdrs'];
                    this.custLandLineNo = data['telNo'].toString().indexOf("*") >= 0 ? '' : data['telNo'];

                    if(this.areaList.find((element) => element['areaCd'] == data["rgnCd"] + '00000')) {
                      this.areaCd = data['rgnCd'] + '00000';
                    } else if (this.areaList.find((element) => element['areaCd'] == data["rgnCd"].toString().substring(0, 4) + '000000')) {
                      this.areaCd = data["rgnCd"].toString().substring(0, 4) + '000000';
                    }else{
                      this.areaCd = data['rgnCd'] + '00000';
                    }

                    var licnsList = res.data["licns"];


                    if (res.data["licnsStatus"] == 'OK') {
                      this.licnsCdList = [];
                      for (var idx in licnsList) {
                        var temp = {
                          licnsCd : licnsList[idx]["indstrytyCd"].toString()
                        };
                        this.licnsCdList.push(temp);
                      }
                    }

                    this.$toast.success("사업자등록번호 조회가 완료되었습니다.");
                  } else {
                    this.isBizNoExist = true;
                    this.$toast.error("일시적인 오류로 사업자 정보를 조회하지 못하였습니다.\n직접 입력 부탁드립니다.");
                  }

                  setTimeout(() =>this.$refs.password.focus(), 500);
                })
                .catch(()=>{
                  this.isBizNoExist = true;
                  this.$toast.error("네트워크 상태를 체크해 주세요.");
                });
      },
    },
  }

</script>
