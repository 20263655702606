<template>
    <div class="bid-history wrap">
        <div class="history__group">
            <p class="txt__title"><span>2023년,</span><br>베일에 쌓인 비드톡톡 정체 밝혀져..</p>
            <ul class="history__list">
                <li class="history__list--item"><p class="txt__sub"><span>06월</span> 비드톡톡 앱 구글스토어 오픈!</p></li>
                <li class="history__list--item"><p class="txt__sub"><span>06월</span> 연혁표시</p></li>
                <li class="history__list--item"><p class="txt__sub"><span>06월</span> 연혁표시</p></li>
                <li class="history__list--item"><p class="txt__sub"><span>06월</span> 연혁표시</p></li>
                <li class="history__list--item"><p class="txt__sub"><span>06월</span> 연혁표시</p></li>
                <div class="history__btn">
                    <button type="button" class="more-btn btn-basic">더보기</button>
                </div>
            </ul>
        </div>
        <div class="history__group">
            <p class="txt__title"><span>2022년,</span><br> 비드톡톡 첫 삽을 뜨다!</p>
            <ul class="history__list">
                <li class="history__list--item"><p class="txt__sub"><span>03월</span> 연혁 내용 표시</p></li>
                <li class="history__list--item"><p class="txt__sub"><span>02월</span> 연혁 내용 표시</p></li>
                <li class="history__list--item"><p class="txt__sub"><span>01월</span> 연혁 내용 표시</p></li>
                <div class="history__btn">
                    <button type="button" class="more-btn btn-basic">더보기</button>
                </div>
            </ul>
        </div>
        <div class="btn__moveTop">
            <a href="#" onclick="return false"></a>
        </div>
    </div>
</template>


<script>
    export default {
        name: "History",
        methods: {
            //페이지 최상단 이동
            moveTop() {
                //페이지 이동
                window.scroll({top: 0, left: 0,behavior: 'smooth'});
            }
        },
        created() {

        },

        mounted() {
            // 히스토리 스크립트
            const listContainers = document.querySelectorAll(".history__group");

            listContainers.forEach((element) => {
                const list = element.querySelector(".history__list");
                const moreBtn = element.querySelector(".more-btn");
                const hiddenItems = [];

                // 3개 이상 리스트는 숨기고, 더보기 버튼 표시
                if (list.children.length > 4) {
                    for (let i = 3; i < list.children.length; i++) {
                        // 인덱스 3번부터 hiddenItems에 저장
                        hiddenItems.push(list.children[i]);
                        list.children[i].classList.add("hidden");
                    }
                    moreBtn.style.display = 'block';
                }

                const showMoreItems = () => {
                    // 클릭할 때마다 hidden 클래스 추가/삭제
                    hiddenItems.forEach((item) => {
                        item.classList.toggle("hidden");
                    });

                    // 버튼 텍스트 변경
                    if (moreBtn.innerText === "더보기") {
                        moreBtn.innerText = "닫기";
                    } else {
                        moreBtn.innerText = "더보기";
                    }
                };

                moreBtn.addEventListener("click", showMoreItems);
            });

        }
    }


</script>



<!--<style src="./History.scss" lang="scss" scoped />-->