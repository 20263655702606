<template>
    <div class="pay-info-enter pay-info-fin">
        <div class="popup__wrap" :class="isVbank? 'active': ''">
            <div class="popup__item--container">
                <div class="popup__item--group">
                    <div class="popup__item--head">
                        <p class="txt__title">가상계좌가 발급 되었습니다.</p>
                    </div>
                    <div class="popup__item--body">
                        <p class="txt__sub bt-bold">예금주 : <span class=" line-ud">{{vactName}}</span></p>
                        <p class="txt__sub bt-bold">은행 : <span class=" line-ud">{{vactBank}}</span></p>
                        <p class="txt__sub bt-bold">계좌번호 : <span class=" line-ud">{{vactNum}}</span></p>
                        <p class="txt__sub bt-bold">입금금액 : <span class=" line-ud">{{Number(totPrice).toLocaleString()}}원</span></p>
                        <p class="txt__sub bt-bold">입금유효기간 : <span class=" line-ud">{{vactDate}}</span></p>
                        <p class="txt__sub noti bt-bold">위의 가상계좌로 결제금액을 입금해 주세요.<br>자세한 정보는 마이페이지에서 확인 가능합니다.</p>
                    </div>
                </div>
                <div class="popup__button--wrap single">
                    <a href="#" onclick="return false" class="btn-basic btn-cta popup__close" @click="closePop()">닫기</a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                isVbank: false,
                payMethod: '',
                resultCode: '',
                resultMsg: '',
                vactBank: '',
                vactDate: '',
                vactName: '',
                vactNum: '',
                totPrice: '',
            }
        },
        mounted() {
            this.payResult();
        },
        methods: {
            payResult() {
                this.resultCode = this.$route.query.RESULT_CODE;
                if (this.resultCode != '00') {
                    alert("결제가 실패했습니다.");

                    window.parent.close();
                } else {
                    this.payMethod = this.$route.query.PAY_METHOD;

                    this.resultMsg = this.$route.query.RESULT_MST;
                    this.vactBank = this.$route.query.VACT_BANK;
                    this.vactName = this.$route.query.VACT_NAME;
                    this.vactNum = this.$route.query.VACT_NUM;
                    this.totPrice = this.$route.query.TOT_PRICE;
                    if (this.vactBank != null && this.vactBank != '') {
                        this.isVbank = true;
                        if (this.$route.query.VACT_DATE != null && this.$route.query.VACT_DATE != '') {
                            this.vactDate = this.$moment(this.$route.query.VACT_DATE).format('YYYY년 MM월 DD일')
                        }
                    } else {
                        this.isVbank = false;
                        alert("결제되었습니다.");

                        window.parent.close();
                    }
                }


            },
            closePop() {
                window.parent.close();
            }
        }
    }
</script>

<style>

</style>