import Vue from 'vue'
import App from './App.vue'
import router from './Routes'
import store from './store/index'
//import vuetify from './plugins/vuetify'
import Element from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css';
import locale from 'element-ui/lib/locale/lang/ko';
import * as VueGoogleMaps from 'vue2-google-maps';
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
import Axios from 'axios';
import vueMoment from 'vue-moment';
import moment from 'moment';
import VueClipboard from 'vue-clipboard2';
import VueTheMask from 'vue-the-mask'
import vueCookies from "vue-cookies";
//import VueAnalytics from 'vue-analytics'
//import * as firebase from "firebase/app";
//import * as analytics  from "firebase/analytics";
//import 'firebase/analytics'
import * as VueGtag from 'vue-gtag';
import Directives from './plugins/directives'
import VCalendar from 'v-calendar';
import VueSlickCarousel from 'vue-slick-carousel'
import VueTyperPlugin from 'vue-typer'

// import io from 'socket.io-client';
// const socket = io('http://localhost:3001');

// const socket = io.connect(`http://115.68.59.120:3001`,{
//     transports: ["websocket"],
//     withCredentials: true,
// });

import Meta from 'vue-meta';
import globals from './global/global.js';

// Vue.use(VueAnalytics, {
//   id: 'G-PF3BMY83KX'
// })

router.beforeEach((to, from, next) => {
  document.body.style.height = '';
  document.body.style.overflow = '';
  if (to.matched.some(record => record.meta.requiresAuth)) {
    //토큰이 있을때만 해당 페이지로 이동
    let token = window.localStorage.getItem('authToken');
    if (token == null) {
      alert('로그인이 필요한 페이지 입니다.')
      next({
        path: '/login',
      });
    } else {
      next()
    }
  } else {
    next() // 반드시 next()를 호출하십시오!
  }
})

Vue.use(VueGtag, {
  config: {
    id: 'G-PF3BMY83KX',	// 측정 ID
  },
}, router);

// const firebaseConfig = {
//   apiKey: "AIzaSyBvGIjEISZeeR4SpYIGkOdyDxc3qwq6yc4",
//   authDomain: "bidtoktok-aecb8.firebaseapp.com",
//   projectId: "bidtoktok-aecb8",
//   storageBucket: "bidtoktok-aecb8.appspot.com",
//   messagingSenderId: "308151289439",
//   appId: "1:308151289439:web:b5dcf0a6ada4ef63227dea",
//   measurementId: "G-PF3BMY83KX"
// };

//firebase.initializeApp(firebaseConfig);
//analytics(fApp);
//firebase.analytics();

moment.locale("ko");

// Vue.prototype.$socket = socket;
Vue.use(Directives)
Vue.use(VueTheMask)

Vue.use(Toast, {
  position:'bottom-center',
  timeout: 2000,
  closeOnClick: true,
  pauseOnHover: true,
  transitionDuration: 750,
  draggable: true,
  draggablePercent: 0.6,
  showCloseButtonOnHover: false,
  hideProgressBar: true,
  closeButton: "button",
  icon: true,
  toastClassName: 'custom',
  bodyClassName: ['custom'],
});
Vue.use(vueMoment,{moment});
Vue.use(VueClipboard);
Vue.use(vueCookies);
Vue.use(VCalendar, {});
Vue.use(VueSlickCarousel);
Vue.$cookies.config("1d");

Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyB7OXmzfQYua_1LEhRdqsoYzyJOPh9hGLg',
  },
});

Vue.use(Meta, {
  attribute: 'data-vue-meta',
  tagIDKeyName: 'vmid'
});

Vue.use(Element, { locale });

Vue.config.productionTip = false

Vue.prototype.$http = Axios;

Vue.use(VueTyperPlugin);

Vue.use(globals);

// window.Kakao.init('ed90614904999727c87e7d776fe0131e');

new Vue({
  //vuetify,
  router,
  render: h => h(App), store
}).$mount('#app')