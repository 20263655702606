<template>
    <div class="login">
        <div class="login__wrap">
            <a href="/home" class="link__logo"></a>
            <div class="login__title">
                <p class="menu__title"><span>비드톡톡 웹사이트에</span><br> 로그인하시고 서비스를 <span class="txt__mobile">이용해 보세요</span></p>
            </div>
            <ul class="tab login__tab">
                <li>
                    <input type="radio" id="tab1-1" name="tab-1" checked>
                    <label for="tab1-1">로그인</label>
                </li>
                <!--                <li>-->
                <!--                    <input type="radio" id="tab1-2" name="tab-1">-->
                <!--                    <label for="tab1-2">간편번호</label>-->
                <!--                </li>-->
            </ul>
            <div class="tabcont login__tabcont--wrap">
                <div class="tabcont__row login__tabcont--group" style="display: block;">
                    <div>
                        <div class="input login__input">
                            <input type="text" placeholder="사업자번호를 입력하세요" v-mask="['###-##-#####']" v-model="bizNo">
                        </div>
                        <div class="input login__input">
                            <input type="password" placeholder="비밀번호를 입력하세요" v-model="pwNo" v-on:keyup.enter="doLogin">
                        </div>
                    </div>
                    <p class="alert-text"></p>
                    <div class="toggle login__option--wrap">
                        <div class="login__option--group">
                            <div class="login__option--toggle">
                                <input type="checkbox" id="toggle" hidden>
                                <label for="toggle" class="toggle__switch">
                                    <span class="toggle__btn"></span>
                                </label>
                                <span class="txt__title">로그인 유지</span>
                            </div>
                            <div class="login__option--move pc">
                                <a href="/joinForm" class="go_join"><span class="txt__title">회원가입</span></a>
                                <a href="#" onclick="return false" class="go_find" @click="$router.push('/findId')"><span class="txt__title">아이디/ 비밀번호 재설정</span></a>
                            </div>
                        </div>
                    </div>
                    <div class="login__btn">
                        <button type="submit" class="btn-basic btn-cta" v-bind:disabled="bizNo.length == 0 || pwNo.length == 0" @click="doLogin"><span>로그인</span></button>
                    </div>
                    <div class="login__option--move mobile">
                        <a href="/joinForm" class="go_join"><span class="txt__title">회원가입</span></a>
                        <a href="#" onclick="return false" class="go_find" @click="$router.push('/findId')"><span class="txt__title">아이디/ 비밀번호 재설정</span></a>
                    </div>
                    <!-- <div class="divid-line"></div>
                    <a href="javascript:void(0);" class="btn-basic btn__sns naver" @click="loginWithNaver()"><p class="txt__title">네이버로 로그인</p></a>
                    <a href="javascript:void(0);" class="btn-basic btn__sns kakao" @click="loginWithKakao()"><p class="txt__title">카카오로 로그인</p></a> -->
                </div>
                <!--                <div id="naver_id_login" class="login-bar__sns" style="display:block;"></div>-->
                <div class="tabcont__row login__tabcont--group easy-login">
                    <p class="txt__title">간편번호로 로그인 하세요</p>
                    <div class="input login__input">
                        <input type="text" placeholder="간편번호 입력하세요">
                    </div>
                    <div class="login__btn">
                        <button type="submit" disabled="disabled" class="btn-basic btn-cta"><span>로그인</span></button>
                    </div>
                    <div class="login__option--group easy-login__option--wrap">
                        <a href="javascript:void(0);" class="easy-login__reset"><span  class="txt__title">간편번호 재설정</span></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
    import {mapActions} from 'vuex'

    export default {
        name: "login",
        metaInfo: {
            title: '[비드톡톡 로그인] - 전자입찰 나라장터(g2b) 공고 투찰 낙찰',
            link: [{ rel: "canonical", href: 'https://www.bidtok.co.kr/' }],
            meta: [
                { vmid: "description", name: 'description', content: '비드톡톡에 로그인하시면, 더욱더 많은 비드톡톡의 기능을 사용하실 수 있습니다. 휴대폰에서든, 홈페이지에서든 로그인을 통해 더욱더 많은 비드톡톡의 기능을 이용해 보세요. 실시간 공고 확인, 정확하고 빠른 AI 분석, 다년간의 노하우를 갖춘 분석기업의 분석, 나라장터 투찰까지 비드톡톡에서 모두 가능합니다.' },
                { vmid: "og:title", property: 'og:title', content: '[비드톡톡 로그인] - 전자입찰 나라장터(g2b) 공고 투찰 낙찰' },
                { vmid: "og:url", property: 'og:url', content: 'https://www.bidtok.co.kr/' },
                { vmid: "og:description", property: 'og:description', content: '비드톡톡에 로그인하시면, 더욱더 많은 비드톡톡의 기능을 사용하실 수 있습니다. 휴대폰에서든, 홈페이지에서든 로그인을 통해 더욱더 많은 비드톡톡의 기능을 이용해 보세요. 실시간 공고 확인, 정확하고 빠른 AI 분석, 다년간의 노하우를 갖춘 분석기업의 분석, 나라장터 투찰까지 비드톡톡에서 모두 가능합니다.' },
                { vmid: "og:image", property: "og:image" , content : "favicon.ico"},
            ]
        },
        data(){
            return {
                bizNo: '',
                pwNo: '',
                custInfo: {},
                isMobile : false,
            }
        },
        methods: {
            ...mapActions([ 'TOGGLE_DRAWER' ]),
            init() {
                localStorage.removeItem("authToken");
                this.existToken = false;
                this.bizNo = "";
                this.pwNo = "";
                this.$router.push('/home');
                document.querySelector('.login-bar--wrap').classList.toggle('on');

            },

            doLogin() {
                if (this.bizNo.trim() == "") {
                    document.querySelector('.alert-text').innerHTML = "사업자번호를 입력해 주세요.";
                    document.querySelector('.alert-text').classList.add('on');
                    return false;
                } else if (this.pwNo.trim() == "") {
                    document.querySelector('.alert-text').innerHTML = "비밀번호를 입력해 주세요.";
                    document.querySelector('.alert-text').classList.add('on');
                } else {
                    var params = {
                        "custIdNm": this.bizNo.replaceAll('-',''),
                        "custPw": this.pwNo,
                        "loginDstnctCd": this.isMobile ? "1" : "0",
                        "webVersionName" :'1.0.2',
                        //"fbToken": params["fbToken"].toString() // firebase token
                        "custDstnctCd": '9',
                    };

                    this.$http.post('/api/login',params)
                        .then(res => {
                            localStorage.setItem("authToken",res.data.authToken);
                            localStorage.setItem("refreshToken",res.data.refreshToken);
                            localStorage.setItem("custInfo",JSON.stringify(res.data.cust));
                            //this.saveStateToStorage(res.data.authToken);
                            this.existToken = true;
                            this.custInfo = JSON.parse(localStorage.getItem("custInfo"));

                            // this.$socket.emit('login',{
                            //     id: this.custInfo.id,
                            //     alias: this.custInfo.cmpNm
                            //
                            // });

                            this.$router.replace('/home');
                        })
                        .catch(error => {
                                console.log(error);
                                document.querySelector('.alert-text').innerHTML = "계정정보를 다시 확인해 주세요.";
                                document.querySelector('.alert-text').classList.add('on');
                            }
                        );
                }
            },
            // loginWithNaver() {
            //     window.naver_id_login_anchor.click();
            // },
            // loginWithKakao() {
            //     // window.open("","_blank");
            //     window.open('/kakaoLogin', '카카오 로그인',
            //         'width=500, height=700, scrollbars=yes, resizable=no')
            //     // window.Kakao.Auth.authorize({
            //     //     redirectUri: `${window.location.origin}/login/kakao`,
            //     //     scope: "profile, account_email",
            //     // });
            // },
            doLogout() {
                this.init();
            },
            checkIfMobile() {
                const userAgent = navigator.userAgent || navigator.vendor || window.opera;
                // 모바일 기기 User-Agent 문자열의 일부를 체크합니다.
                this.isMobile = /android|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(userAgent.toLowerCase());

            },

        },
        created() {

        },

        mounted() {
            this.checkIfMobile();
            //body 색상 변경
            if (document.querySelector('.login__wrap')) {
                document.querySelector('body').style.backgroundColor = '#f5f6fa';
            }
            // 탭에 따른 메뉴 표시
            const tabMenu = document.querySelectorAll('.tab > li');
            const tabCont = document.querySelectorAll('.tabcont__row');

            tabMenu.forEach(function(element, index){
                element.addEventListener('click', function(){
                    for (let i = 0; i < tabMenu.length; i++) {
                        tabCont[i].style.display = 'none';
                    }
                    tabCont[index].style.display = 'block';
                });
            });
            // /탭에 따른 메뉴 표시

            // input 이벤트
            const inputs = document.querySelectorAll('input');
            inputs.forEach((element) => {

                element.addEventListener('keyup', () => {
                    const word =  element.value.length;
                    if (word > 0 && word < 6) {
                        element.classList.add('input-invalid');
                    } else {
                        element.classList.remove('input-invalid');
                        element.classList.add('input-valid');
                    }
                });
                element.addEventListener('focus', () => {
                    //블라블라
                });
                element.addEventListener('blur', () => {
                    const word =  element.value.length;
                    if (word > 0 && word < 6) {
                        element.classList.add('input-invalid');
                    } else {
                        element.classList.remove('input-invalid');
                        element.classList.remove('input-valid');
                    }
                });
            });
            // /input 이벤트
            /* 네이버 로그인 처리*/
            // const naver_id_login = new window.naver_id_login("gyKhMT5uLjW6l8AmB6H8", `${window.location.origin}/login/naver`);
            // const state = naver_id_login.getUniqState();
            // naver_id_login.setButton("green", 1,40); // 버튼 설정
            // naver_id_login.setState(state);
            // naver_id_login.setPopup(); // popup 설정을 위한 코드
            // naver_id_login.init_naver_id_login();
        }
    }


</script>
