<template>
  <el-main>
    <el-row :gutter="20">
      <el-col :span="3.5">
        <el-date-picker
                v-model="strtDate"
                type="date"
                placeholder="시작날짜"
                format="yyyyMMdd"
        >
        </el-date-picker>
      </el-col>
      <el-col :span="3.5">
        <el-date-picker
                v-model="finDate"
                type="date"
                placeholder="종료날짜"
                format="yyyyMMdd"
        >
        </el-date-picker>
      </el-col>
      <el-col :span="3"><el-button type="primary" plain @click="doSearch">검색</el-button></el-col>
    </el-row>
    <el-row>
      <el-col>
        <el-switch
                v-model="toggle1"
                inactive-text="면허"
                @click="licnsToggleBtn">
        </el-switch>
      </el-col>
    </el-row>
    <el-row :gutter="20">
      <el-col :span="20">
        <div style="margin-top: 1px" @change="clkLicns" v-show="toggle1">
          <el-checkbox-group v-model="checkboxGroup1" size="medium">
            <el-checkbox-button v-for="licns in licnsValList" :label="licns" :key="licns">{{licns}}</el-checkbox-button>
          </el-checkbox-group>
        </div>
      </el-col>
    </el-row>
    <el-row>
      <el-col>
        <el-switch
                v-model="toggle2"
                inactive-text="지역"
                @click="areaToggleBtn">
        </el-switch>
      </el-col>
    </el-row>
    <el-row :gutter="20">
      <el-col :span="20">
        <div style="margin-top: 1px" @change="clkPrvncl" v-show="toggle2">
          <el-checkbox-group v-model="checkboxGroup2" size="medium">
            <el-checkbox-button v-for="prvncl in prvnclValList" :label="prvncl" :key="prvncl">{{prvncl}}</el-checkbox-button>
          </el-checkbox-group>
        </div>
      </el-col>
    </el-row>
    <el-row type="flex" class="row-bg" justify="center">
      <el-col :span="20">
        <el-tag type="warning">±3</el-tag>
        <el-table
                border
                v-loading="loading"
                :data="realNoticeList"
                :header-cell-style="{background: '#f5f5f5'}"
        >
          <el-table-column
                  prop="사정율(예가)"
                  label="예가"
                  header-align="center"
          >
          </el-table-column>
          <el-table-column
                  prop="1구간"
                  label="1구간 (~98.9)"
                  header-align="center"
                  align="right"
          >
          </el-table-column>
          <el-table-column
                  prop="2구간"
                  label="2구간 (99.0~99.4)"
                  header-align="center"
                  align="right"
          >
          </el-table-column>
          <el-table-column
                  prop="3구간"
                  label="3구간 (99.5~99.9)"
                  header-align="center"
                  align="right"
          >
          </el-table-column>
          <el-table-column
                  prop="4구간"
                  label="4구간 (100.0~100.4)"
                  header-align="center"
                  align="right"
          >
          </el-table-column>
          <el-table-column
                  prop="5구간"
                  label="5구간 (100.5~100.9)"
                  header-align="center"
                  align="right"
          >
          </el-table-column>
          <el-table-column
                  prop="6구간"
                  label="6구간 (101.0~)"
                  header-align="center"
                  align="right"
          >
          </el-table-column>
          <el-table-column
                  prop="합계"
                  label="합계"
                  header-align="center"
                  align="right"
                  width="80px"
          >
          </el-table-column>
        </el-table>
      </el-col>
    </el-row>
    <el-row type="flex" class="row-bg" justify="center">
      <el-col :span="20">
        <el-tag type="warning">±2</el-tag>
        <el-table
                border
                v-loading="loading"
                :data="realNoticeList_PM2"
                :header-cell-style="{background: '#f5f5f5'}"
        >
          <el-table-column
                  prop="사정율(예가)"
                  label="예가"
                  header-align="center"
          >
          </el-table-column>
          <el-table-column
                  prop="1구간"
                  label="1구간 (~98.9)"
                  header-align="center"
                  align="right"
          >
          </el-table-column>
          <el-table-column
                  prop="2구간"
                  label="2구간 (99.0~99.4)"
                  header-align="center"
                  align="right"
          >
          </el-table-column>
          <el-table-column
                  prop="3구간"
                  label="3구간 (99.5~99.9)"
                  header-align="center"
                  align="right"
          >
          </el-table-column>
          <el-table-column
                  prop="4구간"
                  label="4구간 (100.0~100.4)"
                  header-align="center"
                  align="right"
          >
          </el-table-column>
          <el-table-column
                  prop="5구간"
                  label="5구간 (100.5~100.9)"
                  header-align="center"
                  align="right"
          >
          </el-table-column>
          <el-table-column
                  prop="6구간"
                  label="6구간 (101.0~)"
                  header-align="center"
                  align="right"
          >
          </el-table-column>
          <el-table-column
                  prop="합계"
                  label="합계"
                  header-align="center"
                  align="right"
                  width="80px"
          >
          </el-table-column>
        </el-table>
      </el-col>
    </el-row>
    <el-row type="flex" class="row-bg" justify="center">
      <el-col :span="20">
        <el-tag type="warning">기타</el-tag>
        <el-table
                border
                v-loading="loading"
                :data="realNoticeList_PM0"
                :header-cell-style="{background: '#f5f5f5'}"
        >
          <el-table-column
                  prop="사정율(예가)"
                  label="예가"
                  header-align="center"
          >
          </el-table-column>
          <el-table-column
                  prop="1구간"
                  label="1구간 (~98.9)"
                  header-align="center"
                  align="right"
          >
          </el-table-column>
          <el-table-column
                  prop="2구간"
                  label="2구간 (99.0~99.4)"
                  header-align="center"
                  align="right"
          >
          </el-table-column>
          <el-table-column
                  prop="3구간"
                  label="3구간 (99.5~99.9)"
                  header-align="center"
                  align="right"
          >
          </el-table-column>
          <el-table-column
                  prop="4구간"
                  label="4구간 (100.0~100.4)"
                  header-align="center"
                  align="right"
          >
          </el-table-column>
          <el-table-column
                  prop="5구간"
                  label="5구간 (100.5~100.9)"
                  header-align="center"
                  align="right"
          >
          </el-table-column>
          <el-table-column
                  prop="6구간"
                  label="6구간 (101.0~)"
                  header-align="center"
                  align="right"
          >
          </el-table-column>
          <el-table-column
                  prop="합계"
                  label="합계"
                  header-align="center"
                  align="right"
                  width="80px"
          >
          </el-table-column>
        </el-table>
      </el-col>
    </el-row>
    <el-row>
      <el-col>
        <el-table
                :data="corpList"
                style="width: 700px"
                v-loading="loading">
          <el-table-column
                  prop="bizNo"
                  label="사업자번호"
                  width="250">
          </el-table-column>
          <el-table-column
                  prop="cmpNm"
                  label="업체명"
                  width="300">
          </el-table-column>
          <el-table-column
                  prop="cnt"
                  label="합계">
          </el-table-column>
        </el-table>
      </el-col>
    </el-row>
  </el-main>
</template>

<script>
    import * as _ from 'lodash'

    export default {
        name: "Dashboard",
        data() {
            return {
                strtDate: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()-2).toLocaleDateString(),
                finDate: new Date(),
                config: {
                    headers: {
                        'Content-Type': 'application/json',
                        'auth-token': localStorage.getItem("authToken"), //localStorage.getItem("authToken"),
                        'refresh-token': localStorage.getItem("refreshToken") // localStorage.getItem("refreshToken"),
                    },
                },
                searchParam: {
                    rangeCndtn: 'DESC', // 정렬 (ASC,DESC)
                    findDtDstnct: 'bddprFinDt', // 검색일자구분
                },
                realNoticeList: [{'1구간':0,'2구간':0,'3구간':0,'4구간':0,'5구간':0,'6구간':0}],
                realNoticeList_PM2: [{'1구간':0,'2구간':0,'3구간':0,'4구간':0,'5구간':0,'6구간':0}],
                realNoticeList_PM0: [{'1구간':0,'2구간':0,'3구간':0,'4구간':0,'5구간':0,'6구간':0}],
                licnsCodeList: [],
                licnsCodeGroupList: [],
                areaCodeList: [],
                loading: false,
                checkboxGroup1: [],
                licnsValList: [],
                licnsKeyList: [],
                licnsCntList: [],
                checkboxGroup2: [],
                prvnclList: [],
                prvnclValList: [],
                prvnclKeyList: [],
                areaValList: [],
                areaKeyList: [],
                areaCntList: [],
                toggle1: true,
                toggle2: true,
                corpList: [],
            }
        },
        methods: {
            licnsToggleBtn() {
                this.toggle1 = !this.toggle1;

            },
            areaToggleBtn() {
                this.toggle2 = !this.toggle2;
            },
            clkLicns() {
                this.searchParam.licnsNmList = [];

                for (var i=0 ; i<this.checkboxGroup1.length ; i++) {
                    // console.log(this.checkboxGroup1[i]);
                    // console.log(this.licnsValList[0]);
                    // if (this.licnsValList[0] == this.checkboxGroup1[i]) {
                    // console.log("같다");
                    // }
                    this.searchParam.licnsNmList.push(this.licnsKeyList[_.indexOf(this.licnsValList, this.checkboxGroup1[i])]);
                    //console.log(_.findIndex(this.licnsValList, '수질방지'));
                }

                //console.log(this.searchParam.licnsNmList);

                this.getRealNoticeList();
            },
            clkPrvncl() {
                this.searchParam.prvnclCdList = [];

                for (var i=0 ; i<this.checkboxGroup2.length ; i++) {
                    // console.log(this.checkboxGroup1[i]);
                    // console.log(this.licnsValList[0]);
                    // if (this.licnsValList[0] == this.checkboxGroup1[i]) {
                    // console.log("같다");
                    // }
                    this.searchParam.prvnclCdList.push(this.prvnclKeyList[_.indexOf(this.prvnclValList, this.checkboxGroup2[i])]);
                    //console.log(_.findIndex(this.licnsValList, '수질방지'));
                }

                //console.log(this.searchParam.prvnclCdList);

                this.getRealNoticeList();
            },
            doReset() {
                this.sum= 0;
                //this.checkboxGroup1= [];
                //this.licnsValList= [];
                //this.licnsKeyList= [];
                //this.licnsCntList= [];
                //this.checkboxGroup2= [];
                //this.prvnclList= [];
                //this.prvnclValList= [];
                //this.prvnclKeyList= [];
                //this.searchParam.searchLicnsList = [];
            },
            doSearch() {
                this.doReset();
                this.getRealNoticeList();
            },
            getLicnscodeList() {

                this.loading = true;

                var postData = {
                    notiKindCd: 0
                };

                this.$http.post('/api/comnCode/licnsCodeList', postData, this.config).then(res => {

                    this.licnsCodeList = res.data.licnsCodeList;
                    //console.log(res.data.licnsCodeList);
                    // this.licnsCodeList = (_.orderBy(this.licnsCodeList, ['licnsDstnctCd', 'rpsntOrgnzCd', 'licnsCd', 'licnsCtgyNm']));
                    // console.log(this.licnsCodeList);

                    this.licnsCodeGroupList = _.keys(_.groupBy(res.data.licnsCodeList, function(o) {
                        return o.licnsCtgyNm;
                    }));

                    //console.log(this.licnsCodeGroupList);

                    // this.licnsCodeList = _.filter(this.licnsCodeGroupList, function(o) {
                    //     if (o.length == 1) {
                    //         return o;
                    //     }
                    // });

                    // for (var j=0 ; j<this.licnsCodeList.length; j++) {
                    //     if (this.licnsCodeList[j].licnsCd != '0006' && this.licnsCodeList[j].licnsCd != '0018') {
                    //         //console.log(this.licnsCodeList[j].licnsNm);
                    //         //console.log(this.licnsCodeList[j].licnsCd);
                    //         //this.checkboxGroup1.push(this.licnsCodeList[j].licnsNm);
                    //         this.licnsValList.push(this.licnsCodeList[j].licnsNm);
                    //         this.licnsKeyList.push(this.licnsCodeList[j].licnsCtgyNm);
                    //         //this.licnsCntList.push(res.data.notiLicnCntList[j].count);
                    //     }
                    // }

                    for (var j=0 ; j<this.licnsCodeGroupList.length; j++) {
                        //if (this.licnsCodeList[j].licnsCd != '0006' && this.licnsCodeList[j].licnsCd != '0018') {
                        //console.log(this.licnsCodeList[j].licnsNm);
                        //console.log(this.licnsCodeList[j].licnsCd);
                        //this.checkboxGroup1.push(this.licnsCodeList[j].licnsNm);
                        this.licnsValList.push(this.licnsCodeGroupList[j]);
                        this.licnsKeyList.push(this.licnsCodeGroupList[j]);
                        //this.licnsCntList.push(res.data.notiLicnCntList[j].count);
                        //}
                    }

                    //console.log(this.checkboxGroup1);
                    this.loading = false;
                }).catch(error => console.log(error));
            },
            getAreacodeList() {
                this.loading = true;

                var postData = {

                };

                this.$http.post('/api/realnotice/areaCodeList', postData, this.config).then(res => {
                    this.prvnclList = _.filter(res.data.areaCodeList, function(o) {
                        return o.areaNm == "";
                    });
                    //console.log(this.prvnclList);
                    this.areaCodeList = res.data.areaCodeList;
                    //console.log(res.data.areaCodeList);
                    // this.licnsCodeList = (_.orderBy(this.licnsCodeList, ['licnsDstnctCd', 'rpsntOrgnzCd', 'licnsCd', 'licnsCtgyNm']));
                    // console.log(this.licnsCodeList);

                    // this.licnsCodeGroupList = _.groupBy(res.data.licnsCodeList, function(o) {
                    //    return o.licnsCtgyNm;
                    // });

                    // this.licnsCodeList = _.filter(this.licnsCodeGroupList, function(o) {
                    //     if (o.length == 1) {
                    //         return o;
                    //     }
                    // });

                    for (var j=0 ; j<this.prvnclList.length; j++) {
                        //console.log(this.licnsCodeList[j].licnsNm);
                        //console.log(this.licnsCodeList[j].licnsCd);
                        //this.checkboxGroup1.push(this.licnsCodeList[j].licnsNm);
                        this.prvnclValList.push(this.prvnclList[j].prvnclNm);
                        this.prvnclKeyList.push(this.prvnclList[j].prvnclCd);
                        //this.licnsCntList.push(res.data.notiLicnCntList[j].count);

                    }

                    //console.log(this.checkboxGroup1);
                    this.loading = false;
                }).catch(error => console.log(error));
            },
            getRealNoticeList() {

                this.searchParam.findStrtDt = this.$moment(this.strtDate).format('YYYY-MM-DD');
                this.searchParam.findEndDt = this.$moment(this.finDate).format('YYYY-MM-DD');
                this.loading = true;

                var postData = _.defaults({
                    pageUnit: 9999,
                    pageIndex: 0,
                    strtRecordIntex: 0,
                    areaCdList: [],
                    areaChk:'1'
                }, this.searchParam);

                this.$http.post('/api/realnotice/list', postData, this.config).then(res => {


                    var resList = _.cloneDeep(res.data.realNoticeList);


                    resList = _.filter(resList, function(o) {
                        if (o.bizNo != null){
                            return o;
                        }
                    });

                    var resList_PM3 = _.filter(resList, function(o) { // 사정율 97~103
                        if (o.bizNo != null && o.minAsRt == 97){
                            return o;
                        }
                    });

                    var resList_PM2 = _.filter(resList, function(o) { // 사정율 98~102
                        if (o.bizNo != null && o.minAsRt == 98){
                            return o;
                        }
                    });

                    var resList_PM0 = _.filter(resList, function(o) { // 기타
                        if (o.bizNo != null && o.minAsRt != 98 && o.minAsRt != 97){
                            return o;
                        }
                    });

                    var realNoticeGroupList = _.groupBy(resList, function(o) {
                        return o.bizNo;
                    });

                    this.corpList = _.map(realNoticeGroupList, function(o) {
                        var obj = {};
                        obj.cmpNm = o[0].cmpNm;
                        obj.bizNo = o[0].bizNo;
                        obj.cnt = o.length;

                        return obj;
                    });

                    this.corpList = _.sortBy(this.corpList, 'cnt').reverse();

                    this.corpList = _.filter(this.corpList, function(o,idx) {
                        if (idx < 20) {
                            return o;
                        }
                    });

                    this.realNoticeList[0]['사정율(예가)'] = '예가출현';
                    this.realNoticeList[0]['1구간'] = _.filter(resList_PM3, function(o) {
                        return o['opbdPrargRt'] <= 98.9999;
                    }).length;
                    this.realNoticeList[0]['2구간'] = _.filter(resList_PM3, function(o) {
                        return o['opbdPrargRt'] >= 99.0 && o['opbdPrargRt'] <= 99.4999;
                    }).length;
                    this.realNoticeList[0]['3구간'] = _.filter(resList_PM3, function(o) {
                        return o['opbdPrargRt'] >= 99.5 && o['opbdPrargRt'] <= 99.9999;
                    }).length;
                    this.realNoticeList[0]['4구간'] = _.filter(resList_PM3, function(o) {
                        return o['opbdPrargRt'] >= 100.0 && o['opbdPrargRt'] <= 100.4999;
                    }).length;
                    this.realNoticeList[0]['5구간'] = _.filter(resList_PM3, function(o) {
                        return o['opbdPrargRt'] >= 100.5 && o['opbdPrargRt'] <= 100.9999;
                    }).length;
                    this.realNoticeList[0]['6구간'] = _.filter(resList_PM3, function(o) {
                        return o['opbdPrargRt'] >= 101.0;
                    }).length;
                    //  this.realNoticeList[0]['사정율(예가)']

                    var v = this.realNoticeList[0];
                    const sum = v['1구간']+v['2구간']+v['3구간']+v['4구간']+v['5구간']+v['6구간'];

                    v['1구간'] = v['1구간'] +' (' + _.round(v['1구간']/sum*100,2).toFixed(2) + ")";
                    v['2구간'] = v['2구간'] +' (' + _.round(v['2구간']/sum*100,2).toFixed(2) + ")";
                    v['3구간'] = v['3구간'] +' (' + _.round(v['3구간']/sum*100,2).toFixed(2) + ")";
                    v['4구간'] = v['4구간'] +' (' + _.round(v['4구간']/sum*100,2).toFixed(2) + ")";
                    v['5구간'] = v['5구간'] +' (' + _.round(v['5구간']/sum*100,2).toFixed(2) + ")";
                    v['6구간'] = v['6구간'] +' (' + _.round(v['6구간']/sum*100,2).toFixed(2) + ")";
                    v['합계'] = sum;

                    this.realNoticeList_PM2[0]['사정율(예가)'] = '예가출현';
                    this.realNoticeList_PM2[0]['1구간'] = _.filter(resList_PM2, function(o) {
                        return o['opbdPrargRt'] <= 98.9999;
                    }).length;
                    this.realNoticeList_PM2[0]['2구간'] = _.filter(resList_PM2, function(o) {
                        return o['opbdPrargRt'] >= 99.0 && o['opbdPrargRt'] <= 99.4999;
                    }).length;
                    this.realNoticeList_PM2[0]['3구간'] = _.filter(resList_PM2, function(o) {
                        return o['opbdPrargRt'] >= 99.5 && o['opbdPrargRt'] <= 99.9999;
                    }).length;
                    this.realNoticeList_PM2[0]['4구간'] = _.filter(resList_PM2, function(o) {
                        return o['opbdPrargRt'] >= 100.0 && o['opbdPrargRt'] <= 100.4999;
                    }).length;
                    this.realNoticeList_PM2[0]['5구간'] = _.filter(resList_PM2, function(o) {
                        return o['opbdPrargRt'] >= 100.5 && o['opbdPrargRt'] <= 100.9999;
                    }).length;
                    this.realNoticeList_PM2[0]['6구간'] = _.filter(resList_PM2, function(o) {
                        return o['opbdPrargRt'] >= 101.0;
                    }).length;
                    //  this.realNoticeList_PM2[0]['사정율(예가)']

                    var v2 = this.realNoticeList_PM2[0];
                    const sum2 = v2['1구간']+v2['2구간']+v2['3구간']+v2['4구간']+v2['5구간']+v2['6구간'];

                    v2['1구간'] = v2['1구간'] +' (' + _.round(v2['1구간']/sum2*100,2).toFixed(2) + ")";
                    v2['2구간'] = v2['2구간'] +' (' + _.round(v2['2구간']/sum2*100,2).toFixed(2) + ")";
                    v2['3구간'] = v2['3구간'] +' (' + _.round(v2['3구간']/sum2*100,2).toFixed(2) + ")";
                    v2['4구간'] = v2['4구간'] +' (' + _.round(v2['4구간']/sum2*100,2).toFixed(2) + ")";
                    v2['5구간'] = v2['5구간'] +' (' + _.round(v2['5구간']/sum2*100,2).toFixed(2) + ")";
                    v2['6구간'] = v2['6구간'] +' (' + _.round(v2['6구간']/sum2*100,2).toFixed(2) + ")";
                    v2['합계'] = sum2;

                    this.realNoticeList_PM0[0]['사정율(예가)'] = '예가출현';
                    this.realNoticeList_PM0[0]['1구간'] = _.filter(resList_PM0, function(o) {
                        return o['opbdPrargRt'] <= 98.9999;
                    }).length;
                    this.realNoticeList_PM0[0]['2구간'] = _.filter(resList_PM0, function(o) {
                        return o['opbdPrargRt'] >= 99.0 && o['opbdPrargRt'] <= 99.4999;
                    }).length;
                    this.realNoticeList_PM0[0]['3구간'] = _.filter(resList_PM0, function(o) {
                        return o['opbdPrargRt'] >= 99.5 && o['opbdPrargRt'] <= 99.9999;
                    }).length;
                    this.realNoticeList_PM0[0]['4구간'] = _.filter(resList_PM0, function(o) {
                        return o['opbdPrargRt'] >= 100.0 && o['opbdPrargRt'] <= 100.4999;
                    }).length;
                    this.realNoticeList_PM0[0]['5구간'] = _.filter(resList_PM0, function(o) {
                        return o['opbdPrargRt'] >= 100.5 && o['opbdPrargRt'] <= 100.9999;
                    }).length;
                    this.realNoticeList_PM0[0]['6구간'] = _.filter(resList_PM0, function(o) {
                        return o['opbdPrargRt'] >= 101.0;
                    }).length;

                    var v3 = this.realNoticeList_PM0[0];
                    const sum3 = v3['1구간']+v3['2구간']+v3['3구간']+v3['4구간']+v3['5구간']+v3['6구간'];

                    v3['1구간'] = v3['1구간'] +' (' + _.round(v3['1구간']/sum3*100,2).toFixed(2) + ")";
                    v3['2구간'] = v3['2구간'] +' (' + _.round(v3['2구간']/sum3*100,2).toFixed(2) + ")";
                    v3['3구간'] = v3['3구간'] +' (' + _.round(v3['3구간']/sum3*100,2).toFixed(2) + ")";
                    v3['4구간'] = v3['4구간'] +' (' + _.round(v3['4구간']/sum3*100,2).toFixed(2) + ")";
                    v3['5구간'] = v3['5구간'] +' (' + _.round(v3['5구간']/sum3*100,2).toFixed(2) + ")";
                    v3['6구간'] = v3['6구간'] +' (' + _.round(v3['6구간']/sum3*100,2).toFixed(2) + ")";
                    v3['합계'] = sum3;

                    this.loading = false;
                }).catch(error => console.log(error));
            }
        },
        mounted() {
            this.getLicnscodeList();
            this.getAreacodeList();
        }
    }
</script>