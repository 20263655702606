<template>
    <div class="etcAgree">
        <!-- 제목 -->
        <div class="etcAgree__title-box"><h2 class="etcAgree__title"><i class="sy-icon sy-circle-o"></i>개인정보처리방침</h2></div>
        <!-- 상단 내용 -->
        <div class="agree__box">
            <p class="agree__privacy--tit">
                (주)비드톡톡 (www.bidtok.co.kr 이하 “회사” 또는”비드톡톡”이라 함)는 통신비밀보호법, 전기통신사업법, 정보통신망 이용촉진 및 정보 보호 등에 관한 법률 등 정보통신서비스제공자가 준수하여야 할 관련 법령상의 개인정보보호 규정을 준수하며, 관련 법령에 의거한 개인정보처리방침을 정하여 이용자가 제공하는 개인정보가 어떠한 용도와 방식으로 이용하고 있으며, 최선을 다해서 보호하고 있음에 대해 알려드립니다.<br/>
                비드톡톡의 개인정보처리방침은 아래 내용에 의해 시행됩니다.
            </p>
            <ul class="agree__privacy--btn">
                <li><a href="#" onclick="return false" @click="move('list1')">1. 수집하는 개인정보의 항목 및 수집방법</a></li>
                <li><a href="#" onclick="return false" @click="move('list2')">2. 개인정보의 수집 및 이용목적</a></li>
                <li><a href="#" onclick="return false" @click="move('list3')">3. 개인정보 공유 및 제공</a></li>
                <li><a href="#" onclick="return false" @click="move('list4')">4. 개인정보의 취급위탁</a></li>
                <li><a href="#" onclick="return false" @click="move('list5')">5. 개인정보의 보유 및 이용기간</a></li>
                <li><a href="#" onclick="return false" @click="move('list6')">6. 개인정보 파기절차 및 방법</a></li>
                <li><a href="#" onclick="return false" @click="move('list7')">7. 이용자 및 법정대리인의 권리와 그 행사방법</a></li>
                <li><a href="#" onclick="return false" @click="move('list8')">8. 개인정보 자동 수집 장치의 설치/운영 및 거부에 관한 사항</a></li>
                <li><a href="#" onclick="return false" @click="move('list9')">9. 개인정보의 기술적/관리적 보호 대책</a></li>
                <li><a href="#" onclick="return false" @click="move('list10')">10. 개인정보관리책임자 및 담당자의 연락처</a></li>
                <li><a href="#" onclick="return false" @click="move('list11')">11. 기타</a></li>
                <li><a href="#" onclick="return false" @click="move('list12')">12. 고지의 의무</a></li>
            </ul>
        </div>
        <!-- 상단 내용 -->

        <!-- #1 수집하는 개인정보의 항목 및 수집방법 -->
        <div class="agree__box" id="list1">
            <h2 class="agree__box--tit">1. 수집하는 개인정보의 항목 및 수집방법</h2>
            <p class="agree__box--info">
                1) 수집하는 개인정보의 항목<br/>
                ①회사는 회원가입, 원활한 고객상담, 각종 서비스의 제공을 위해 최초 회원가입 당시 아래와 같은 개인정보를 수집하고 있습니다.<br/><br/>

                ＜회원가입 시＞<br/>
                - 필수항목 : 성명, 아이디, 비밀번호, 본인확인 문답, 이메일 주소<br/>
                - 선택사항 : 휴대폰 번호<br/><br/>

                ②서비스 이용과정이나 사업처리 과정에서 아래와 같은 정보들이 자동으로 생성되어 수집될 수 있습니다.<br/>
                - IP Address, 쿠키, 방문 일시, 서비스 이용 기록, 불량 이용 기록<br/><br/>

                ③부가 서비스 및 맞춤식 서비스 이용 또는 이벤트 응모 과정에서 해당 서비스의 이용자에 한해서만 아래와 같은 정보들이 수집될 수 있습니다.<br/>
                - 개인정보 추가 수집에 대해 동의를 받는 경우<br/><br/>

                ④유료 서비스 이용 과정에서 아래와 같은 결제 정보들이 수집될 수 있습니다.<br/>
                - 신용카드 결제시 : 카드사명, 카드번호 등<br/>
                - 계좌이체시 : 은행명, 계좌번호 등 <br/>
                - 상품권 이용시 : 상품권 번호<br/><br/>


                2) 개인정보 수집방법 <br/>
                회사는 다음과 같은 방법으로 개인정보를 수집합니다.<br/>
                - 홈페이지, 서면양식, 팩스, 전화, 상담 게시판, 이메일, 이벤트 응모, 배송요청<br/>
                - 협력회사로부터의 제공<br/>
                - 생성정보 수집 툴을 통한 수집
            </p>
        </div>
        <!-- //#1 수집하는 개인정보의 항목 및 수집방법 -->

        <!-- #2 개인정보의 수집 및 이용목적 -->
        <div class="agree__box" id="list2">
            <h2 class="agree__box--tit">2. 개인정보의 수집 및 이용목적</h2>
            <p class="agree__box--info">
                1) 서비스 제공에 관한 계약 이행 및 서비스 제공에 따른 요금정산<br/>
                컨텐츠 제공, 특정 맞춤 서비스 제공, 물품배송 또는 청구서 등 발송, 본인인증, 구매 및 요금 결제, 요금추심<br/><br/>


                2) 회원관리<br/>
                회원제 서비스 이용 및 제한적 본인 확인제에 따른 본인확인, 개인식별, 불량회원(비드톡톡 이용약관 제20조 1항 목중 제11조 1항 1호~8호 위반사유로 인한 영구이용정지 및 2항에 따라 계약해지된 영구이용정지 회원)의 부정 이용방지와 비인가 사용방지, 가입의사 확인, 가입 및 가입횟수 제한, 만14세 미만 아동 개인정보 수집 시 법정 대리인 동의여부 확인, 추후 법정 대리인 본인확인, 분쟁 조정을 위한 기록보존, 불만처리 등 민원처리, 고지사 항 전달<br/><br/>


                3) 신규 서비스 개발 및 마케팅<br/>
                신규 서비스 개발 및 맞춤 서비스 제공, 통계학적 특성에 따른 서비스 제공 및 광고 게재, 서비스의 유효성 확인, 이벤트 및 광고성 정보 제공 및 참여기회 제공, 접속빈도 파악, 회원의 서비스이용에 대한 통계
            </p>
        </div>
        <!-- //#2 개인정보의 수집 및 이용목적 -->

        <!-- #3 개인정보 공유 및 제공 -->
        <div class="agree__box" id="list3">
            <h2 class="agree__box--tit">3. 개인정보의 공유 및 제공</h2>
            <p class="agree__box--info">
                회사는 이용자들의 개인정보를 "2. 개인정보의 수집목적 및 이용목적"에서 고지한 범위내에서 사용하며, 이용 자의 사전 동의 없이는 동 범위를 초과하여 이용하거나 원칙적으로 이용자의 개인정보를 외부에 공개하지 않습니다. 다만, 아래의 경우에는 예외로 합니다.<br/><br/>

                - 이용자들이 사전에 공개에 동의한 경우<br/>
                - 법령의 규정에 의거하거나, 수사 목적으로 법령에 정해진 절차와 방법에 따라 수사기관의 요구가 있는 경우
            </p>
        </div>
        <!-- //#3 개인정보 공유 및 제공 -->

        <!-- #4 개인정보의 취급위탁 -->
        <div class="agree__box" id="list4">
            <h2 class="agree__box--tit">4. 개인정보의 취급위탁</h2>
            <p class="agree__box--info">
                회사는 서비스 향상을 위해서 아래와 같이 개인정보를 위탁하고 있으며, 관계 법령에 따라 위탁계약 시 개인정 보가 안전하게 관리될 수 있도록 필요한 사항을 규정하고 있습니다.<br/>
                회사의 개인정보 위탁처리 기관 및 위탁업무 내용은 아래와 같습니다.<br/><br/>

                - 수탁업체 : (주)비드톡톡<br/>
                - 위탁업무 내용 : 개인정보의 보관/관리, 서비스 제공을 위한 시스템 개발 및 운영, 서비스 개발 및 테스트 <br/>
                - 위탁기간 : 회원탈퇴시 혹은 위탁계약 종료 시까지
            </p>
        </div>
        <!-- /#4 개인정보의 취급위탁 -->

        <!-- #5 개인정보의 보유 및 이용기간 -->
        <div class="agree__box" id="list5">
            <h2 class="agree__box--tit">5. 개인정보의 보유 및 이용기간</h2>
            <p class="agree__box--info">
                이용자의 개인정보는 원칙적으로 개인정보의 수집 및 이용목적이 달성되면 지체 없이 파기합니다. 단, 다음의 정보에 대해서는 아래의 이유로 명시한 기간 동안 보존합니다.<br/><br/>
                1) 회사 내부 방침에 의한 정보보유 사유 <br/>
                - 부정이용기록<br/>
                보존 이유 : 부정 이용 방지<br/>
                보존 기간 : 1년<br/><br/>


                2) 관련법령에 의한 정보보유 사유<br/>
                상법, 전자상거래 등에서의 소비자보호에 관한 법률 등 관계법령의 규정에 의하여 보존할 필요가 있는 경우 회 사는 관계법령에서 정한 일정한 기간 동안 회원정보를 보관합니다. 이 경우 회사는 보관하는 정보를 그 보관의 목적으로만 이용하며 보존기간은 아래와 같습니다.<br/><br/>


                ＜계약 또는 청약철회 등에 관한 기록><br/>
                - 보존 이유 : 전자상거래 등에서의 소비자보호에 관한 법률<br/>
                - 보존 기간 : 5년<br/><br/>

                ＜대금결제 및 재화 등의 공급에 관한 기록＞<br/>
                - 보존 이유 : 전자상거래 등에서의 소비자보호에 관한 법률 <br/>
                - 보존 기간 : 5년 <br/><br/>

                ＜소비자의 불만 또는 분쟁처리에 관한 기록＞<br/>
                - 보존 이유 : 전자상거래 등에서의 소비자보호에 관한 법률 <br/>
                - 보존 기간 : 3년<br/><br/>

                ＜본인확인에 관한 기록><br/>
                - 보존 이유 : 정보통신 이용촉진 및 정보보호 등에 관한 법률<br/>
                - 보존 기간 : 6개월<br/><br/>

                ＜방문에 관한 기록><br/>
                - 보존 이유 : 통신비밀보호법<br/>
                - 보존 기간 : 3개월
            </p>
        </div>
        <!-- //#5 개인정보의 보유 및 이용기간 -->

        <!-- #6 개인정보 파기절차 및 방법 -->
        <div class="agree__box" id="list6">
            <h2 class="agree__box--tit">6. 개인정보 파기절차 및 방법</h2>
            <p class="agree__box--info">
                이용자의 개인정보는 원칙적으로 개인정보의 수집 및 이용목적이 달성되면 지체 없이 파기합니다.<br/>
                회사의 개인정보 파기절차 및 방법은 다음과 같습니다.<br/><br/>


                1) 파기절차<br/>
                - 이용자가 회원가입 등을 위해 입력한 정보는 목적이 달성된 후 별도의 DB로 옮겨져(종이의 경우 별도의 서류 함) 내부 방침 및 기타 관련 법령에 의한 정보보호 사유에 따라(보유 및 이용기간 참조)일정 기간 저장된 후 파 기됩니다.<br/>
                - 동 개인정보는 법률에 의한 경우가 아니고서는 보유되는 이외의 다른 목적으로 이용되지 않습니다.<br/><br/>


                2) 파기방법<br/>
                - 종이에 출력된 개인정보는 분쇄기로 분쇄하거나 소각을 통하여 파기합니다.<br/>
                - 전자적 파일 형태로 저장된 개인정보는 기록을 재생할 수 없는 기술적 방법을 사용하여 삭제합니다.
            </p>
        </div>
        <!-- //#6 개인정보 파기절차 및 방법 -->

        <!-- #7 이용자 및 법정대리인의 권리와 그 행사방법 -->
        <div class="agree__box" id="list7">
            <h2 class="agree__box--tit">7. 이용자 및 법정대리인의 권리와 그 행사방법</h2>
            <p class="agree__box--info">
                - 이용자 및 법정 대리인은 언제든지 등록되어 있는 자신 혹은 당해 만 14세 미만 아동의 개인정보를 조회하거 나 수정할 수 있으며 가입해지를 요청할 수도 있습니다.<br/><br/>

                - 이용자 혹은 만 14세 미만 아동의 개인정보 조회, 수정을 위해서는 '개인정보변경'(또는 '회원정보수정' 등), 가입해지(동의철회)를 위해서는 "회원탈퇴"를 클릭하여 본인 확인 절차를 거치신 후 직접 열람, 정정 또는 탈 퇴가 가능합니다.<br/><br/>

                - 혹은 개인정보관리책임자에게 서면, 전화 또는 이메일로 연락하시면 지체 없이 조치하겠습니다.<br/><br/>

                - 이용자가 개인정보의 오류에 대한 정정을 요청하신 경우에는 정정을 완료하기 전까지 당해 개인정보를 이용 또는 제공하지 않습니다. 또한 잘못된 개인정보를 제3 자에게 이미 제공한 경우에는 정정 처리결과를 제3자에 게 지체 없이 통지하여 정정이 이루어지도록 하겠습니다.<br/><br/>

                - 회사는 이용자 혹은 법정 대리인의 요청에 의해 해지 또는 삭제된 개인정보는 "5. 개인정보의 보유 및 이용기 간"에 명시된 바에 따라 처리하고 그 외의 용도로 열람 또는 이용할 수 없도록 처리하고 있습니다.
            </p>
        </div>
        <!-- //#7 이용자 및 법정대리인의 권리와 그 행사방법 -->

        <!-- #8 개인정보 자동 수집 장치의 설치/운영 및 거부에 관한 사항 -->
        <div class="agree__box" id="list8">
            <h2 class="agree__box--tit">8. 개인정보 자동 수집 장치의 설치/운영 및 거부에 관한 사항</h2>
            <p class="agree__box--info">
                회사는 이용자들에게 특화된 맞춤서비스를 제공하기 위해서 이용자들의 정보를 저장하고 수시로 불러오는 '쿠 키(cookie)'를 사용합니다. 쿠키는 웹사이트를 운영하는데 이용되는 서버(HTTP)가 이용자의 컴퓨터 브라우저 에게 보내는 소량의 정보이며 이용자들의 PC 컴퓨터내의 하드디스크에 저장되기도 합니다.<br/><br/>


                1) 쿠키의 사용 목적 <br/>
                이용자들이 방문한 더비스의 각 서비스와 웹 사이트들에 대한 방문 및 이용형태, 인기 검색어, 보안접속 여부, 뉴스편집, 이용자 규모 등을 파악하여 이용자에게 최적화된 정보 제공을 위하여 사용합니다.<br/><br/>


                2) 쿠키의 설치/운영 및 거부 <br/>
                - 이용자는 쿠키 설치에 대한 선택권을 가지고 있습니다. 따라서, 이용자는 웹브라우저에서 옵션을 설정함으로 써 모든 쿠키를 허용하거나, 쿠키가 저장될 때마다 확인을 거치거나, 아니면 모든 쿠키의 저장을 거부할 수도 있습니다. <br/>
                - 쿠키 설정을 거부하는 방법으로는 이용자가 사용하는 웹 브라우저의 옵션을 선택함으로써 모든 쿠키를 허용 하거나 쿠키를 저장할 때마다 확인을 거치거나, 모든 쿠키의 저장을 거부할 수 있습니다.<br/>
                - 설정방법 예(인터넷 익스플로어의 경우) : 웹 브라우저 상단의 도구 > 인터넷 옵션 > 개인정보 <br/>
                - 다만, 쿠키의 저장을 거부할 경우에는 로그인이 필요한 더비스 일부 서비스는 이용에 어려움이 있을 수 있습니다. <br/>
            </p>
        </div>
        <!-- //#8 개인정보 자동 수집 장치의 설치/운영 및 거부에 관한 사항 -->

        <!-- #9 개인정보의 기술적/관리적 보호 대책 -->
        <div class="agree__box" id="list9">
            <h2 class="agree__box--tit">9. 개인정보의 기술적/관리적 보호 대책</h2>
            <p class="agree__box--info">
                회사는 이용자들의 개인정보를 취급함에 있어 개인정보가 분실, 도난, 누출, 변조 또는 훼손되지 않도록 안전성 확보를 위하여 다음과 같은 기술적/관리적 대책을 강구하고 있습니다.<br/><br/>


                1) 비밀번호 암호화<br/>
                회원 아이디(ID)의 비밀번호는 암호화되어 저장 및 관리되고 있어 본인만이 알고 있으며, 개인정보의 확인 및 변경도 비밀번호를 알고 있는 본인에 의해서만 가능합니다.<br/><br/>


                2) 해킹 등에 대비한 대책<br/>
                회사는 해킹이나 컴퓨터 바이러스 등에 의해 회원의 개인정보가 유출되거나 훼손되는 것을 막기 위해 최선을 다하고 있습니다. 개인정보의 훼손에 대비해서 자료를 수시로 백업하고 있고, 최신 백신프로그램을 이용하여 이용자들의 개인정 보나 자료가 누출되거나 손상되지 않도록 방지하고 있으며, 암호화통신 등을 통하여 네트워크상에서 개인정보 를 안전하게 전송할 수 있도록 하고 있습니다. 그리고 침입차단시스템을 이용하여 외부로부터의 무단 접근을 통제하고 있으며, 기타 시스템적으로 보안성을 확보하기 위한 가능한 모든 기술적 장치를 갖추려 노력하고 있습니다.<br/><br/>


                3) 취급 직원의 최소화 및 교육<br/>
                회사의 개인정보관련 취급 직원은 담당자에 한정시키고 있고 이를 위한 별도의 비밀번호를 부여하여 정기적으 로 갱신하고 있으며, 담당자에 대한 수시 교육을 통하여 더비스 개인정보처리방침의 준수를 항상 강조하고 있 습니다.<br/><br/>

                4) 개인정보보호전담기구의 운영<br/>
                그리고 사내 개인정보보호전담기구 등을 통하여 비드톡톡 개인정보처리방침의 이행사항 및 담당자의 준수여부를 확인하여 문제가 발견될 경우 즉시 수정하고 바로 잡을 수 있도록 노력하고 있습니다. 단, 이용자 본인의 부주의나 인터넷상의 문제로 ID, 비밀번호 등 개인정보가 유출되어 발생한 문제에 대해 회사는 일체의 책임을 지지 않습니다.
            </p>
        </div>
        <!-- //#9 개인정보의 기술적/관리적 보호 대책 -->

        <!-- #10 개인정보관리책임자 및 담당자의 연락처 -->
        <div class="agree__box" id="list10">
            <h2 class="agree__box--tit">10. 개인정보관리책임자 및 담당자의 연락처</h2>
            <p class="agree__box--info">
                귀하께서는 회사의 서비스를 이용하시며 발생하는 모든 개인정보보호 관련 민원을 개인정보관리책임자 혹은 담당부서로 신고하실 수 있습니다.
                회사는 이용자들의 신고사항에 대해 신속하게 충분한 답변을 드릴 것입니다.<br/><br/>

                <span ng-if="privacyInfo.seniorName">
                개인정보 관리 책임자<br/><br/>

                이 름 : 최경호<br/>
                소 속 : 비드톡톡<br/>
                전 화 : 1670-0508<br/>
                메 일 : khchoi@bidtok.co.kr<br/><br/>
            </span>

                <span>
                개인정보 관리 담당자<br/><br/>

                이 름 : 이정훈<br/>
                소 속 : 비드톡톡<br/>
                전 화 : 1670-0508<br/>
                메 일 : jhlee@bidtok.co.kr<br/><br/>
            </span>


                기타 개인정보침해에 대한 신고나 상담이 필요하신 경우에는 아래 기관에 문의하시기 바랍니다.<br/>

                - 개인정보분쟁조정위원회 (<a href="https://www.kopico.go.kr/" target="_blank">www.kopico.go.kr</a> / 1833-6972)<br>
                - 정보보호마크인증위원회 (<a href="https://www.eprivacy.or.kr/" target="_blank">www.eprivacy.or.kr</a> / 02-550-9500)<br>
                - 대검찰청 인터넷범죄수사센터 (<a href="http://www.spo.go.kr/" target="_blank">www.spo.go.kr</a> / 국번없이 1301(유료))<br>
                - 경찰청 사이버안전국 (<a href="http://www.police.go.kr/" target="_blank">www.police.go.kr</a> / 민원대표전화 182(유료)) <br>
            </p>
        </div>
        <!-- //#10 개인정보관리책임자 및 담당자의 연락처 -->

        <!-- #11 기타 -->
        <div class="agree__box" id="list11">
            <h2 class="agree__box--tit">11. 기타</h2>
            <p class="agree__box--info">
                비드톡톡에 링크되어 있는 웹사이트들이 개인정보를 수집하는 행위에 대해서는 본 "비드톡톡 개인정보처리방침"이 적용되지 않음을 알려 드립니다.
            </p>
        </div>
        <!-- //#11 기타 -->

        <!-- #12 고지의 의무 -->
        <div class="agree__box" id="list12">
            <h2 class="agree__box--tit">12.고지의 의무</h2>
            <p class="agree__box--info">
                현 개인정보처리방침 내용 추가, 삭제 및 수정이 있을 시에는 개정 최소 7일전부터 홈페이지의 '공지사항'을 통해 고지할 것입니다.<br/><br/>


                - 공고일자 : 2023.05.22<br/>
                - 시행일자 : 2023.05.22
            </p>
        </div>
        <!-- //#12 고지의 의무 -->
    </div>
</template>
<script>
    export default {
        data() {
            return {
                siteNm: '비드톡톡',
                siteUrl: 'https://www.bidtok.co.kr',
            }
        },
        mounted () {
            window.addEventListener('scroll', this.checkBottom);
        },
        methods: {
            move(message) {
                //페이지 이동
                document.getElementById(message).scrollIntoView({behavior:'smooth'});
            },
            checkBottom () {
                let isBottom = (document.documentElement.scrollTop + window.innerHeight + 200 > document.documentElement.offsetHeight);
                if(isBottom && window.innerWidth <= 800){
                    // eslint-disable-next-line no-undef
                    JavaScriptChannel.postMessage("Y");
                }
            },
        }
    }
</script>
<style src="./EtcUseAgree.scss" lang="scss" scoped />