import { render, staticRenderFns } from "./BidHelperFooter.vue?vue&type=template&id=bb0be8a8&scoped=true"
import script from "./BidHelperFooter.vue?vue&type=script&lang=js"
export * from "./BidHelperFooter.vue?vue&type=script&lang=js"
import style0 from "../../assets/scss/common/bid-helper.scss?vue&type=style&index=0&id=bb0be8a8&prod&lang=scss&scoped=true&external"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bb0be8a8",
  null
  
)

export default component.exports