<template>
    <div class="analysis__container help-home__container--analy">
        <!-- 분석 현황  > 공고 카운트 container -->
        <div class="anno-count__container">
            <div class="anno-count__item--num" v-show="notiProgInfoCnt >= 0 ">
                <span class="empha">1</span>/<span class="total">{{notiProgInfoCnt}}</span><span class="txt">번째 공고</span>
            </div>
            <button class="anno-count__item--reload" @click="initCustNotiList()"><i class="ic_reload"></i></button>
        </div>
        <!-- /분석 현황  > 공고 카운트 container -->

        <div class="layout__half announce">
            <!-- 분석요청한 공고가 없을 경우 -->
            <div class="announce__board--state non-item" v-show="notiProgInfoCnt == 0 && loginInfo != null">
                <div class="announce__board--img"></div>
                <div class="announce__board--txt">
                    <p class="title">투찰 가능한 공고가 없습니다.</p>
                    <p class="sub">홈페이지에서 공고 분석 요청 해주세요.</p>
                </div>
            </div>
            <!-- 분석요청한 공고가 없을 경우 -->

            <!-- 분석공고 있을 경우 -->
            <div v-show="loginInfo != null && notiProgInfoCnt != 0">
                <!-- 분석중 -->
                <div class="layout__item announce--item" v-for="(item) in notiProgInfoList" v-bind:key="item.id">
                    <!-- *투찰 현황 condition-badge__item 뒤에 클래스 덧붙임
                    투찰전 > before, 투찰가능 > ing, 투찰마감 > fin, 개찰완료 > announce,  참가신청 > application -->
                    <div class="announce__group condition-badge announce--item--badge">
                        <p class="condition-badge__item ing"><span></span></p>
                        <button class="application-check" v-show="item.regstVisibleYn == 'Y'" :class="item.bddprRegstYn== 'Y' ? 'on': ''" @click="updateCustNotiRegstStatus(item)">참가신청완료</button>
                    </div>
                    <a class="announce__group announce--item--move-detail">
                        <p class="txt__title">
                            <span class="condition-badge__item application" v-show="item.regstVisibleYn == 'Y' && item.notiStatus == '투찰가능'"><span></span></span>
                            <span class="bdg-code code07 mr4" v-show="item.rebidNo > 0">재입찰</span>
                            {{item.notiNm}}
                        </p>
                        <div class="announce__copy">
                            <div class="announce__copy--item">
                                <span class="txt__sub">{{item.notiNo}}{{item.notiSeq != null ? ('-' + item.notiSeq) : ''}}</span>
                                <button type="button" class="btn__copy" @click="copyNotiNo(item.notiNo)"></button>
                            </div>
                        </div>
                        <ul class="announce__info">
                            <li class="announce__info--list announce__info--company"><span
                                    class="txt__title">지역</span><span class="txt__sub">{{item.areaNmConcat}}</span></li>
                            <li class="announce__info--list announce__info--company">
                                <span class="txt__title">면허</span>
                                <div class="main-lisc bdg-lic">
                                    <i class="main-lisc--ic bdg-lic--ic main" v-show="item.noticeMainLicnsList.length > 0">주력</i>
                                    <div class="viw-all">
                                        <p class="txt__title">{{item.noticeMainLicnsList.map((e) => e.licnsNm).join(" | ")}}</p>
                                    </div>
                                    <span class="txt__sub">
                                     {{item.licnsNmConcat}}
                                    </span>
                                </div>
                            </li>
                            <li class="announce__info--list"><span class="txt__title">기초금액</span><span
                                    class="txt__sub">{{item.baseAmt | currency}}</span></li>
                        </ul>
                    </a>
                    <div class="announce__group announce__period">
                        <p class="txt__title" v-show="item.regstVisibleYn == 'Y' ">참가마감</p>
                        <span class="txt__sub" :class="item.isRegDt ? 'announce__period--today' : ''"
                              v-show="item.regstVisibleYn == 'Y'">{{ $moment(item.regstFinDt).format('MM/DD(ddd)  HH:mm') }} </span>
                        <span class="dividing-line" v-show="item.regstVisibleYn == 'Y' && item.notiStatus == '투찰가능'"></span>
                        <p class="txt__title">투찰마감</p>
                        <span class="txt__sub" :class="item.isBddprDt ? 'announce__period--today' : ''">{{ $moment(item.bddprFinDt).format('MM/DD(ddd)  HH:mm') }}</span>
                    </div>
                    <div class="announce__group announce__analysis--info">
                        <img :src="item.iconImg" alt="분석가 이미지"
                             class="announce__analysis--analysist">
                        <span class="txt__title">{{item.anlstNickNm}}</span>
                    </div>
                    <div class="announce__group active" v-show="item.anlstChoiceFlag != 'Y' && item.anlsBddprAmt == 0">
                        <button type="button" class="announce--cta-btn btn-basic btn__non-active"><span>분석 중</span></button>
                    </div>
                    <div class="announce__group announce--price" v-show="item.anlsBddprAmt != 0" :class="item.anlstChoiceFlag=='Y' ? 'active' : ''">
                        <button type="button" class="announce--cta-btn btn-basic announce--price--view" @click="confirmAmt(item)"><span>분석제시가 확인</span></button>
                        <p class="txt--title price" @click="copyAnlsBddprAmt(item.anlstChoiceFlag=='Y' ? item.anlsBddprAmt.toString() : '0')"><span>{{item.anlstChoiceFlag=='Y' ? item.anlsBddprAmt : '0' | currency}}</span>
                        </p>
                        <button class="btn-basic btn-cta announce--price--btn bid-go" :class="item.bddprConfYn == 'Y' ? 'active' : ''"
                                @click="goSite(item)"><span>투찰</span></button>
                        <button class="btn-basic btn-line-cb announce--price--btn bid-fin" :class="item.bddprConfYn == 'Y' ? 'active' : ''"
                                @click="updateCustNotiBddprStatus(item)"><span>투찰완료</span></button>
                    </div>
                    <!-- /분석중일 때-->
                </div>
            </div>
        </div>
        <!-- /분석 현황 컨테이너 -->
        <!-- 분석제시가 열람 제한 팝업 -->
        <div class="popup__wrap analy-limit active" v-show="isPopAnlsRestrain">
            <div class="popup__item--container">
                <div class="popup__item--group">
                    <div class="popup__item--head">
                        <div class="txt__title">분석제시가 확인 제한</div>
                    </div>
                    <div class="popup__item--body">
                        <p class="txt__sub">분석제시가 확인은 <span>투찰 시작 이후 가능합니다.</span></p>
                        <p class="txt time">투찰 시작: <span>{{bddprDt}}</span></p>
                    </div>
                </div>
                <div class="popup__button--wrap single popup__close">
                    <a href="#" onclick="return false" class="btn-basic btn-cta" @click="isPopAnlsRestrain = false;">확인</a>
                </div>
            </div>
        </div>
        <!-- 분석제시가 열람 제한 팝업  -->

        <!-- 참가신청 미완료 팝업 -->
        <div class="popup__wrap confim application-cek active" v-show="isPopRegstConfirm">
            <div class="popup__item--container">
                <div class="popup__item--group application-cek--group">
                    <div class="popup__item--head">
                        <p class="txt__title">참가신청 미완료</p>
                    </div>
                    <div class="popup__item--body">
                        <p class="txt__sub">를 체크하신 후,
                            분석제시가를 확인할 수 있어요.</p>
                    </div>
                </div>
                <div class="popup__button--wrap single">
                    <a href="#" onclick="return false" class="btn-basic btn-cta popup__close" @click="isPopRegstConfirm = false;">닫기</a>
                </div>
            </div>
        </div>
        <!-- /참가신청 미완료 팝업 -->
        <!-- 분석제시가 확인 팝업 -->
        <div class="popup__wrap confim price-cek active" v-show="isPopBddprConfirm">
            <div class="popup__item--container">
                <div class="popup__item--group price-cek-limit--group">
                    <div class="popup__item--head">
                        <p class="txt__title">분석제시가 확인</p>
                    </div>
                    <div class="popup__item--body">
                        <p class="txt__sub">분석제시가를 확인하시겠습니까?</p>
                    </div>
                </div>
                <div class="popup__button--wrap pair">
                    <a href="#" onclick="return false" class="btn-basic btn-line-cb popup__close" @click="isPopBddprConfirm = false">아니오</a>
                    <a href="#" onclick="return false" class="btn-basic btn-cta popup__close" @click="anlsAmtChoice()">네</a>
                </div>
            </div>
        </div>
        <!-- /분석제시가 확인 팝업 -->
    </div>
</template>
<script>
    import $ from 'jquery';

    export default {
        data() {
            return {
                loginInfo: {},
                notiProgInfoList: [],
                notiProgInfoCnt: -1,
                notiAnlstList: [],
                anlsPageUnit: 9999,
                anlsPage: 0,

                isPopAnlsRestrain: false,   //분석제시가확인 제한
                isPopRegstConfirm: false,   //참가신청 미완료
                isPopBddprConfirm: false,   //분석제시가 확인 컨펌

                bddprDt: '',
            }
        },
        watch: {
            // isPopAnlsRestrain(newValue) {
            //     if (newValue == true) {
            //         this.$scrollNon();
            //     } else {
            //         this.$scrollActive();
            //     }
            // },
            // isPopRegstConfirm(newValue) {
            //     if (newValue == true) {
            //         this.$scrollNon();
            //     } else {
            //         this.$scrollActive();
            //     }
            // },
            // isPopBddprConfirm(newValue) {
            //     if (newValue == true) {
            //         this.$scrollNon();
            //     } else {
            //         this.$scrollActive();
            //     }
            // },
        },
        created() {
            window.addEventListener('scroll', this.handleScroll);
        },

        destroyed() {
            window.removeEventListener('scroll', this.handleScroll);
        },
        async mounted() {
            this.loginInfo = JSON.parse(localStorage.getItem("custInfo"));

            await this.getNotiAnlst();
            if (this.loginInfo != null) {
                //분석실 목록
                this.initCustNotiList();
            }
        },
        methods: {
            copyNotiNo(value) {
                this.$copyText(value);
                this.snackbar = true;
                this.copyText = value;
                this.$stopEventBubble();

                // this.$toast('HelloWorld', { position: "bottom-right" });

                this.$toast.success("공고번호를 복사했습니다.");

                // alert("공고번호를 복사했습니다.");
            },
            goSite(noti) {
                var url = '';
                if( noti.rpsntOrgnzCd == 1 ) { //나라장터
                    url = 'https://www.g2b.go.kr';
                    var AddUrl = "?bidno="+ noti.notiNo +"&searchType=2&bidSearchType=1";
                    url += encodeURI("/pt/menu/selectSubFrame.do?framesrc=/pt/menu/frameTgong.do?url=https://www.g2b.go.kr:8101/ep/tbid/tbidList.do"+AddUrl);
                }

                else if( noti.rpsntOrgnzCd == 3 ) { //국방부
                    this.copyNotiNo(noti.notiNo);
                    if(noti.notiKindCd=='0') {
                        if(noti.vltrnNotiYn=='Y') { // 수의여부
                            url = 'http://www.d2b.go.kr/Internet/jsp/peb/HI_PEB_Main.jsp?md=441&cfn=HI_PEB_OpenNego_Lst&txtCsrtName='+ noti.notiNm;
                        } else {
                            url = 'http://www.d2b.go.kr/Internet/jsp/peb/HI_PEB_Main.jsp?md=421&cfn=HI_PEB_Announce_Lst&txtG2bNumb='+ noti.notiNo;
                        }
                    } else if(noti.notiKindCd=='1') {
                        if(noti.vltrnNotiYn=='Y') { // 수의여부
                            url = 'http://www.d2b.go.kr/Internet/jsp/pdb/HI_PDB_Main.jsp?md=241&cfn=HI_PDB_OpenNego_Lst&txtRpstItnm='+ noti.notiNm;
                        } else {
                            url = 'http://www.d2b.go.kr/Internet/jsp/peb/HI_PEB_Main.jsp?md=421&cfn=HI_PEB_Announce_Lst&txtG2bNumb='+ noti.notiNo;
                        }
                    } else if(noti.notiKindCd=='2') {
                        if(noti.vltrnNotiYn=='Y') { // 수의여부
                            url = 'http://www.d2b.go.kr/Internet/jsp/pdb/HI_PDB_Main.jsp?md=241&cfn=HI_PDB_OpenNego_Lst&txtCsrtName='+ noti.notiNm;
                        } else {
                            url = 'http://www.d2b.go.kr/Internet/jsp/pdb/HI_PDB_Main.jsp?md=221&cfn=HI_PDB_Announce_Lst&txtG2bNumb='+ noti.notiNo;
                        }
                    }
                }

                else if( noti.rpsntOrgnzCd == 6 ) { //도로
                    var token = noti.notiNo.split( '-' );

                    if(noti.notiKindCd=='0' || noti.notiKindCd=='1') {
                        url = 'http://ebid.ex.co.kr/ebid/jsps/ebid/const/bidNoti/bidNotiCompanyList.jsp?';
                        url += 'in_out=O';
                        url += '&bid_type='+ (noti.notiKindCd=='0' ? 'C' : 'S');
                        url += '&list_gubun=N&status=E&startnum=1&endnum=10';
                        url += '&noti_no1='+ token[0];
                        url += '&noti_no2='+ token[1];
                        url += '&noti_title=&bid_cnt=0&s_noti_date=&e_noti_date=&method=';
                    } else {
                        url = 'http://ebid.ex.co.kr/ebid/jsps/ebid/buy/bidNoti/bidNotiCompanyList.jsp?';
                        url += 'notiNo1='+ token[0];
                        url += '&noti_no2='+ token[1];
                        url += '&notiTitle=&multiCnt=0&startDate=&endDate=&method=';
                    }
                }

                else if( noti.rpsntOrgnzCd == 8 ) { //마사회
                    // url = 'http://ebid.kra.co.kr/bid/bd_list_notice.jsp?b_code=&work_type=&is_sell=&is_from_main=true&b_to_me=&is_sell_result=&page=&order_place=&bid_name='+ noti.notiNm;
                    url = 'http://ebid.kra.co.kr/bid/notice/all/view.do?b_code='+ noti.notiNo.replace('KRA','');
                }

                // else if( noti.rpsntOrgnzCd == 13 ) { //수력원자력
                // 	url = 'http://ebiz.khnp.co.kr/MB_BID/B/mbqb400.jsp?gongoNum=' + noti.notiNo;
                // }

                // 정의된 사이트가 없을경우 메인 페이지로
                if(url == '') {
                    this.copyNotiNo(noti.notiNo);
                    url = this.rpsntOrgnzLink(noti.rpsntOrgnzCd);
                }

                window.open(url, "popup", "width=1024, height=600, left=30, top=30, scrollbars=no,titlebar=no,status=no,resizable=no,fullscreen=no");
            },
            rpsntOrgnzLink( rpsntOrgnzCd ) {

                var Link = null;

                if( rpsntOrgnzCd == 1 ){ //나라장터
                    Link = 'https://www.g2b.go.kr';
                }
                if( rpsntOrgnzCd == 2 ){ //한전
                    Link = 'http://srm.kepco.net';
                }
                if( rpsntOrgnzCd == 3 ){ //국방부
                    Link = 'http://www.d2b.go.kr';
                }
                if( rpsntOrgnzCd == 5 ){ //토주
                    Link = 'http://ebid.lh.or.kr';
                }
                if( rpsntOrgnzCd == 6 ){ //도로
                    Link = 'https://ebid.ex.co.kr';
                }
                if( rpsntOrgnzCd == 13 ){ //수력원자력
                    Link = 'http://ebiz.khnp.co.kr';
                }
                if( rpsntOrgnzCd == 10 ){ //수자원
                    Link = 'http://ebid.kwater.or.kr';
                }
                if( rpsntOrgnzCd == 11 ){ //인천공항
                    Link = 'http://ebid.airport.kr/UserMain.dc';
                }
                if( rpsntOrgnzCd == 9 ){ //가스
                    Link = 'http://bid.kogas.or.kr';
                }
                if( rpsntOrgnzCd == 7 ){ //한국통신
                    Link = 'http://silkroad.kt.co.kr';
                }
                if( rpsntOrgnzCd == 8 ){ //마사회
                    Link = 'http://ebid.kra.co.kr';
                }
                if( rpsntOrgnzCd == 4 ){ //전자통신연구원
                    Link = 'http://ebid.etri.re.kr/ebid/';
                }
                if( rpsntOrgnzCd == 20 ){ //철도공사
                    Link = 'http://ebid.korail.com';
                }
                if( rpsntOrgnzCd == 19 ){ //철도시설공단
                    Link = 'http://ebid.kr.or.kr';
                }
                if( rpsntOrgnzCd == 24 ){ //석유공사
                    Link = 'http://ebid.knoc.co.kr';
                }
                if( rpsntOrgnzCd == 25 ){ //학교장터
                    Link = 'http://www.s2b.kr';
                }

                return Link;
            },
            anlsAmtChoice() {
                const currentDate = new Date();
                const bddprFinDt = new Date(this.selNoti.bddprFinDt);

                if (currentDate > bddprFinDt) {
                    this.isPopBddprConfirm = false;
                    this.$toast.success("투찰 마감일이 지난 공고입니다.");
                    return;
                }

                var praram = {
                    id: this.selNoti.custNotiAnlsId,
                    // anlsBddprAmt: this.selNoti.anlsBddprAmt,
                    // anlsBddprRt: this.selNoti.anlsBddprRt,
                    custNotiId: this.selNoti.custNotiId,
                    notiId: this.selNoti.notiId,
                    custBizId: this.selNoti.custBizId,
                    anlstChoiceFlag: 'Y',
                    mobileYn: 'N'
                };

                this.$post('/api/noti/updateAnlsChoiceFlag', praram)
                    .then(res => {
                        if (res.code == "OK") {
                            $('#radio' + this.selNoti.custNotiId).prop("checked", true);
                            this.selNoti.anlsBddprAmt = res.anlsBddprAmt;
                            this.selNoti.anlstChoiceFlag = 'Y';
                            this.copyAnlsBddprAmt(this.selNoti.anlstChoiceFlag=='Y' ? this.selNoti.anlsBddprAmt.toString() : '0');
                        } else if(res.errorCode == 3){
                            this.$toast.error(res.msg);
                            this.selNoti.anlsBddprAmt = 0;
                        } else {
                            this.selNoti.anlsBddprAmt = 0;
                        }
                        this.isPopBddprConfirm = false;
                        // this.$router.go();
                    })
                    .catch(error => {
                            console.log(error);
                            this.isPopBddprConfirm = false;
                        }
                    );
            },
            confirmAmt(item) {
                const currentDate = new Date();

                const bddprStrtDt = new Date(item.bddprStrtDt);
                if (item.regstVisibleYn == 'Y' && item.bddprRegstYn == 'N') {
                    this.isPopRegstConfirm = true;
                } else if (currentDate > bddprStrtDt) {
                    this.selNoti = item;
                    this.isPopBddprConfirm = true;
                } else {
                    var year = bddprStrtDt.getFullYear();
                    var month = ('0' + (bddprStrtDt.getMonth() + 1)).slice(-2);
                    var day = ('0' + bddprStrtDt.getDate()).slice(-2);
                    var hours = ('0' + bddprStrtDt.getHours()).slice(-2);
                    var minutes = ('0' + bddprStrtDt.getMinutes()).slice(-2);

                    var week = ['일', '월', '화', '수', '목', '금', '토'];
                    var dayOfWeek = week[new Date(bddprStrtDt).getDay()];

                    var dateString = year + '.' + month + '.' + day + '(' + dayOfWeek + ') ' + hours + ':' + minutes;

                    this.bddprDt = dateString;
                    this.isPopAnlsRestrain = true;
                }

                this.$stopEventBubble();
            },
            updateCustNotiBddprStatus(item) {
                if (item.anlstChoiceFlag != 'Y') {
                    this.$toast.error('분석제시가 확인 후, 투찰완료처리 가능합니다.');
                    return;
                }

                var param = {
                    id: item.id,
                    custBizId: item.custBizId,
                    bddprConfYn: item.bddprConfYn == 'Y' ? 'N' : 'Y',
                    mobileYn: 'N'
                };


                this.$post('/api/noti/updateCustNotiBddprStatus', param)
                    .then(res => {
                        if (res.code == "OK") {
                            item.bddprConfYn = item.bddprConfYn == 'Y' ? 'N' : 'Y';
                        }

                        // $('#check'+item['id']).classList.add("btn-active");
                        // this.$router.go();
                    })
                    .catch(error => console.log(error));
                this.$stopEventBubble();
            },
            copyAnlsBddprAmt(value) {
                this.$copyText(value);
                this.snackbar = true;
                this.copyText = value;
                this.$stopEventBubble();

                this.$toast.success("분석제시가를 복사했습니다.",);

                // alert("분석투찰금액을 복사했습니다.");
            },
            /** 참가신청완료 클릭*/
            updateCustNotiRegstStatus(item) {
                var param = {
                    id: item.id,
                    custBizId: item.custBizId,
                    bddprRegstYn: item.bddprRegstYn == 'Y' ? 'N' : 'Y',
                    mobileYn: 'N'
                };

                this.$post('/api/noti/updateCustNotiRegstStatus', param)
                    .then(res => {
                        if (res.code == "OK") {
                            item.bddprRegstYn = item.bddprRegstYn == 'Y' ? 'N' : 'Y';
                        }

                        // $('#check'+item['id']).classList.add("btn-active");
                        // this.$router.go();
                    })
                    .catch(error => console.log(error));
                this.$stopEventBubble();
            },

            /** 분석가 목록*/
            async getNotiAnlst() {
                this.notiAnlstList = [];

                await this.$http.get('/api/noti/anlstList')
                    .then(res => {
                        var notiAnlstList = res.data.notiAnlstList;
                        this.notiAnlstList = notiAnlstList;
                    })
                    .catch(error => console.log(error));
            },
            /** 분석공고 초기화*/
            initCustNotiList() {
                this.notiProgInfoList = [];

                this.getNotiAnlsProgInfoList();
            },
            getNotiAnlsProgInfoList() {

                if (this.loginInfo == null) {
                    return;
                }

                var param = {
                    pageUnit: this.anlsPageUnit,
                    pageIndex: this.anlsPage,
                    strtRecordIntex: this.anlsPageUnit * this.anlsPage,
                    searchDate: this.$moment().format('YYYYMMDD HH:mm'),
                    custBizId: this.loginInfo.custBizList[0].id,
                    kword: this.kword,
                    findText: this.findText,
                    searchCulum: this.searchCulum,
                    nothQuqlfYn: this.nothQuqlfYn,
                    strtDate: this.custNotiStrtDate,
                    finDate: this.custNotiFinDate,
                    delYn: this.delYn,

                    // orderBySt: 'bddprFinDt',
                    // notiOpbdListYn: 'Y',
                };

                this.$post('/api/noti/notiAnlsProgInfoList', param)
                    .then(res => {
                        var notiList = res.notiProgInfoList;
                        this.notiProgInfoCnt = res.notiProgInfoCnt;

                        const today = this.$moment();

                        for (var i in notiList) {
                            //투찰완료시 리스트에서 제외
                            if(notiList[i].bddprConfYn == 'Y'){
                                this.notiProgInfoCnt--;
                                continue;
                            }


                            // 분석제시목록
                            var custNotiAnlsList = notiList[i]['custNotiAnlsList'];
                            var anlsBddprAmt = 0;
                            var anlsBddprRt = 0;
                            var custNotiId = '';
                            var anlstChoiceFlag = '';
                            var custNotiAnlsId = '';
                            // var iconImg = '';
                            //var anlstNickNm = '';
                            for (var j in custNotiAnlsList) {
                                if (j == 0) {
                                    anlsBddprAmt = custNotiAnlsList[j].anlsBddprAmt; // 분석제시가
                                    anlsBddprRt = custNotiAnlsList[j].anlsBddprRt; // 분석지점
                                    custNotiId = custNotiAnlsList[j].custNotiId; // 분석지점
                                    anlstChoiceFlag = custNotiAnlsList[j].anlstChoiceFlag;
                                    custNotiAnlsId = custNotiAnlsList[j].id;
                                    //anlstNickNm = custNotiAnlsList[j].anlstNickNm; // AI닉네임
                                    // iconImg = '/'+custNotiAnlsList[j].iconImg; // AI닉네임
                                }
                            }

                            // <img :src="mock.notiTable.notiAnlstList.find((e) => e.id == item.notiAnlstId).iconImg" alt="캐릭터 이미지" class="Announce__analysis--character" width="40px" height="40px">
                            //         <span class="txt__title">&nbsp;&nbsp;{{mock.notiTable.notiAnlstList.find((e) => e.id == item.notiAnlstId).anlstNickNm}}</span>
                            var iconImg = '';
                            var anlstNickNm = '';

                            if (this.notiAnlstList.findIndex((e) => e.id == notiList[i].notiAnlstId) != -1) {
                                iconImg = '/' + this.notiAnlstList.find((e) => e.id == notiList[i].notiAnlstId).iconImg;
                                anlstNickNm = this.notiAnlstList.find((e) => e.id == notiList[i].notiAnlstId).anlstNickNm;
                            }

                            var regstFinDt = this.$moment(notiList[i].regstFinDt);
                            var bddprFinDt = this.$moment(notiList[i].bddprFinDt);

                            //오늘 날짜 여부
                            if (regstFinDt.format('YYYY-MM-DD') == today.format('YYYY-MM-DD') && regstFinDt.isAfter(today)) {
                                notiList[i].isRegDt = true;
                            } else {
                                notiList[i].isRegDt = false;
                            }
                            if (bddprFinDt.format('YYYY-MM-DD') == today.format('YYYY-MM-DD') && bddprFinDt.isAfter(today)) {
                                notiList[i].isBddprDt = true;
                            } else {
                                notiList[i].isBddprDt = false;
                            }

                            var obj = {
                                id: notiList[i].id,
                                notiNo: notiList[i].notiNo,
                                notiSeq: notiList[i].notiSeq,
                                baseAmt: notiList[i].baseAmt,
                                notiNm: notiList[i].notiNm,
                                anlsBddprAmt: anlsBddprAmt,
                                anlsBddprRt: anlsBddprRt,
                                custNotiId: custNotiId,
                                notiId: notiList[i].notiId,
                                custBizId: notiList[i].custBizId,
                                anlstChoiceFlag: anlstChoiceFlag,
                                custNotiAnlsId: custNotiAnlsId,
                                iconImg: iconImg,
                                anlstNickNm: anlstNickNm,
                                // iconImg: require(iconImg),
                                notiAnlstId: notiList[i].notiAnlstId,
                                rpsntOrgnzCd: notiList[i].rpsntOrgnzCd,
                                bddprFinDt: notiList[i].bddprFinDt,
                                regstFinDt: notiList[i].regstFinDt,
                                notiStatus: notiList[i].notiStatus,
                                regstVisibleYn: notiList[i].regstVisibleYn,
                                rpsntOrgnzCdNm: notiList[i].rpsntOrgnzCdNm,
                                tlwtOrgnzNm: notiList[i].tlwtOrgnzNm,
                                bddprConfYn: notiList[i].bddprConfYn,
                                bddprRegstYn: notiList[i].bddprRegstYn,
                                bddprStrtDt: notiList[i].bddprStrtDt,
                                licnsNmConcat: notiList[i].licnsNmConcat,
                                areaNmConcat: notiList[i].areaNmConcat,
                                isRegDt: notiList[i].isRegDt,
                                isBddprDt: notiList[i].isBddprDt,
                                noticeMainLicnsList: notiList[i].noticeMainLicnsList,
                            }

                            this.notiProgInfoList.push(obj);

                        }
                        setTimeout(() => {

                            const container = document.querySelector('.help-home__container--analy');
                            // const annoItem = container.querySelectorAll('.layout__item');
                            const annoCount = document.querySelector('.anno-count__item--num .empha');
                            // let cardStatus = [];


                            function cardPage() {

                                var index = container.scrollTop / 370;

                                if(container.scrollTop<20)
                                    index = 1

                                annoCount.innerHTML = Math.ceil(index);

                                // annoItem.forEach((card, index) => {
                                //
                                //     const topDistance = Math.round(card.getBoundingClientRect().top);
                                //
                                //     // 해당 카드의 상태를 확인
                                //     if (topDistance < 199 && topDistance > 156) {
                                //         // 카드가 새롭게 번호를 받아야 하는 경우
                                //         if (cardStatus[index] !== 'active') {
                                //             cardStatus[index] = 'active';
                                //             annoCount.innerHTML = index + 1;
                                //         }
                                //     } else if (topDistance < 156 && topDistance > -220) {
                                //         // 카드가 이전 번호를 유지해야 하는 경우
                                //         cardStatus[index] = 'maintain';
                                //     } else {
                                //         // 스크롤을 너무 많이 내렸거나 위로 올렸을 때
                                //         cardStatus[index] = 'inactive';
                                //
                                //     }
                                // });
                            }

                            container.addEventListener('scroll', cardPage);
                        }, 1000);

                    })
                    .catch(error => console.log(error));
            },
        }
    }


</script>
<style src="../../../../assets/scss/common/bid-helper.scss" lang="scss" scoped/>