<template>
    <!-- footer -->
    <div class="footer">
        <div class="wrap rows">
            <div class="group toktok__info">
                <p class="txt__title">고객문의: 1670-0508</p>
                <p class="txt">평일 08:00 ~ 17:00 <span class="txt__mobile">(점심시간: 11:30 ~ 13:00 / 주말 및 공휴일 휴무)</span></p>
                <p class="txt email">E-mail. webmaster@bidtok.co.kr</p>
                <p class="toktok__adress">
                    (주) 비드톡톡 ㅣ 사업자등록번호: 895-86-03369 ㅣ 대표자: 최경호<br>
                    통신판매번호 제 2024-대전서구-2262호<br>
                    주소: 대전광역시 서구 계룡로 553번길 18, <span class="txt__mobile">조이빌딩 9F (우 35262)</span>
                </p>
                <div class="group toktok__site mobile">
                    <div class="toktok__gnb">
                        <a href="#" onclick="return false" class="toktok__gnb--link home" @click="$goMenu('home')">홈</a>
                        <a href="#" onclick="return false" class="toktok__gnb--link analysis" @click="$goMenu('analysis')">분석실</a>
                        <a href="#" onclick="return false" class="toktok__gnb--link notice" @click="$goMenu('notice')">공지사항</a>
                        <a href="#" onclick="return false" class="toktok__gnb--link faq" @click="$goMenu('faq')">자주하는 질문</a>
                    </div>
                    <div class="toktok__sns">
                        <a href="https://www.youtube.com/@BIDTOKTOK" target="_blank" class="toktok__sns--link youtube" aria-label="전자입찰 비드톡톡 유튜브"></a>
                        <a href="https://blog.naver.com/bidtoktok" target="_blank" class="toktok__sns--link blog" aria-label="전자입찰 비드톡톡 블로그"></a>
                        <a href="https://pf.kakao.com/_QmQPxj?from=qr" target="_blank" class="toktok__sns--link chnnel" aria-label="전자입찰 비드톡톡 카카오채널"></a>
                    </div>
                </div>
                <div class="toktok__policy">
                    <a href="#" onclick="return false" @click="$goMenu('etcUseAgree')" class="toktok__policy--link">이용약관</a>
                    <a href="#" onclick="return false" @click="$goMenu('etcPrivacy')" class="toktok__policy--link">개인정보처리방침</a>
                </div>
                <p class="toktok__right">© Bidtoktok Corp. All Rights Reserved</p>

            </div>
            <div class="group toktok__site pc">
                <div class="toktok__gnb">
                    <a href="#" onclick="return false" class="toktok__gnb--link home" @click="$goMenu('home')">홈</a>
                    <a href="#" onclick="return false" class="toktok__gnb--link analysis" @click="$goMenu('analysis')">분석실</a>
                    <a href="#" onclick="return false" class="toktok__gnb--link notice" @click="$goMenu('notice')">공지사항</a>
                    <a href="#" onclick="return false" class="toktok__gnb--link faq" @click="$goMenu('faq')">자주하는 질문</a>
                </div>
                <div class="toktok__sns">
                    <a href="https://www.youtube.com/@bidtoktok_official" target="_blank" class="toktok__sns--link youtube" aria-label="비드톡톡 유튜브"></a>
                    <a href="https://blog.naver.com/bidtoktok_official" target="_blank" class="toktok__sns--link blog" aria-label="비드톡톡 블로그"></a>
                    <a href="https://pf.kakao.com/_QmQPxj?from=qr" target="_blank" class="toktok__sns--link chnnel" aria-label="비드톡톡 카카오채널"></a>
                </div>
            </div>
        </div>
    </div>
    <!-- footer -->
</template>

<script>

    export default {
        name: 'Footer',

        data: () => ({}),
        watch: {
            $route(to, form) {
                if (to.path !== form.path) {
                    if (to.path == '/home') {
                        this.homeFooter();
                    } else {
                        this.notHomeFooter();
                    }
                    // document.querySelector('#'+to.path.toString().substring(1,to.path.length)).classList.add('active');
                }
            },
        },
        methods: {
            homeFooter() {
                // margin-top 속성을 변경하려는 경우
                document.querySelector('.footer').style.marginTop = '-508px';

                // 특정 요소에 색상을 변경하려는 경우
                const elements = document.querySelectorAll('.footer .toktok__gnb--link, .footer .toktok__policy--link, .footer .toktok__right, .footer .toktok__info .txt__title, .footer .toktok__info .txt, .footer .toktok__adress');
                elements.forEach(element => {
                    element.style.color = '#e2e2e2';
                });


                // 호버 시 색상 변경을 위한 이벤트 처리
                const hoverElements = document.querySelectorAll('.footer .toktok__gnb--link, .footer .toktok__policy--link');
                hoverElements.forEach(element => {
                    element.addEventListener('mouseover', () => {
                        element.style.color = '#fff';
                    });
                    element.addEventListener('mouseout', () => {
                        element.style.color = '#e2e2e2';
                    });
                });
            },
            notHomeFooter() {
                // margin-top 속성을 변경하려는 경우
                document.querySelector('.footer').style.marginTop = '0px';

                // 특정 요소에 색상을 변경하려는 경우
                const elements = document.querySelectorAll('.footer .toktok__info .txt__title, .footer .toktok__info .txt, .footer .toktok__adress');
                elements.forEach(element => {
                    element.style.color = '#373F56';
                });

                const elements2 = document.querySelectorAll('.footer .toktok__gnb--link, .footer .toktok__policy--link, .footer .toktok__right' );
                elements2.forEach(element => {
                    element.style.color = '#888';
                });

                // 호버 시 색상 변경을 위한 이벤트 처리
                const hoverElements = document.querySelectorAll('.footer .toktok__gnb--link, .footer .toktok__policy--link');
                hoverElements.forEach(element => {
                    element.addEventListener('mouseover', () => {
                        element.style.color = '#373F56';
                    });
                    element.addEventListener('mouseout', () => {
                        element.style.color = '#373F56';
                    });
                });
            }
        },
        mounted() {
            if(this.$route.path == '/home'){
                this.homeFooter();
            } else {
                this.notHomeFooter();
            }
        }

    }
</script>