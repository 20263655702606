import * as XLSX from 'xlsx';

export default {

    // install(Vue) {
    //
    //
    //         // [로컬 스토리지 데이터 저장]
    //         Vue.config.globalProperties.$setLocalStoage = function(key, value){
    //             localStorage.setItem(key, value);
    //         },
    //
    //
    //         // [로컬 스토리지 저장 값 호출]
    //         Vue.config.globalProperties.$getLocalStoage = function(key){
    //             return localStorage.getItem(key);
    //         },
    //
    //
    //         // [로컬 스토리지 특정 값 삭제]
    //         Vue.config.globalProperties.$delItemLocalStoage = function(key){
    //             localStorage.removeItem(key);
    //         },
    //
    //
    //         // [로컬 스토리지 전체 데이터 삭제]
    //         Vue.config.globalProperties.$delAllLocalStoage = function(){
    //             localStorage.clear();
    //         }
    // },

    install(Vue) {
        Vue.filter('currency', function(value) {
            if (value == null) {
                return 0
            } else if (value == '-') {
                return '-';
            }

            return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        });
        Vue.filter('bizNo', function(value) {
            if (!value) {
                return '-';
            }

            var formattedBizno = value.replace(/^(\d{3})(\d{2})(\d{5})$/, "$1-$2-$3");
            return formattedBizno;
        });
        Vue.filter('phoneNumber', function(value) {
            if (!value) return '';

            var numbers = value.replace(/\D/g, '');

            if (numbers.length === 10) {
                // 일반 전화번호 (011-123-4567 형식)
                return numbers.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3');
            } else if (numbers.length === 11) {
                // 휴대폰 번호 (010-1234-5678 형식)
                return numbers.replace(/(\d{3})(\d{4})(\d{4})/, '$1-$2-$3');
            }

            return value;
        });
        Vue.prototype.$mainClick = function($event) {
            console.log($event);
            if($event.target.parentElement.className === 'close-btn'){
                $event.target.closest('.active').classList.toggle('active');
            }
            console.log("화면클릭!");
        },
        Vue.prototype.$goMenu = function(menuUrl) {
            if (this.$route.path != "/"+menuUrl) {// 현재 route와 다른 경우만 페이지 이동
                window.scrollTo(0,0);

                if (document.querySelector('body').classList.contains('expand')) {
                    document.querySelector('body').classList.remove('expand');
                }
                document.querySelector('.header__ham--wrap').classList.remove('on');
                document.querySelector('.btn__ham--top').classList.remove('btn__ham--top-click');
                document.querySelector('.btn__ham--middle').classList.remove('btn__ham--middle-click');
                document.querySelector('.btn__ham--bottom').classList.remove('btn__ham--bottom-click');
                this.$router.push('/'+menuUrl);
            } else {
                window.scrollTo(0,0);
            }
            if (menuUrl == 'home') { // home 클릭 시 active 삭제
            }
        },
        Vue.prototype.$getBrowserInfo = function() {
            const userAgent = navigator.userAgent;
            let browserName = 'Unknown';

            if (userAgent.includes("Chrome") && userAgent.includes("Edg/")) {
                browserName = 'Edge';
            } else if (userAgent.includes("Chrome")) {
                browserName = 'Chrome';
            } else if (userAgent.includes("Safari") && !userAgent.includes("Chrome")) {
                browserName = 'Safari';
            } else if (userAgent.includes("Firefox")) {
                browserName = 'Firefox';
            } else if (userAgent.includes("MSIE") || userAgent.includes("Trident/")) {
                browserName = 'Internet Explorer';
            }

            return browserName;
        },
            Vue.prototype.$getDeviceType = function() {
                const userAgent = navigator.userAgent;
                if (/mobile/i.test(userAgent)) {
                    return 'Mobile';
                } else if (/tablet/i.test(userAgent)) {
                    return 'Tablet';
                } else if (/iPad|Android|Touch/i.test(userAgent)) {
                    return 'Tablet';
                } else {
                    return 'Desktop';
                }
            },
        Vue.prototype.$insertTracking = async function (tracking) {
            var clientIp = await this.$getPublicIP();
            var loginInfo =JSON.parse(localStorage.getItem("custInfo"));
            var trackingType;
            if(tracking.trackingType != null){
                trackingType = tracking.trackingType;
            }
            else{
                trackingType = window.innerWidth <= 800 ? 'MOBILE_WEB' : 'WEB';
            }
            // var webVersion = process.env.VUE_APP_WEB_VERSION == null ? '' : process.env.VUE_APP_WEB_VERSION;

            var params = {
                "custId": loginInfo != null ? loginInfo.id : '',
                "clientIp": clientIp,
                "device":  this.$getDeviceType(),
                "brwsrCd" : this.$getBrowserInfo(),
                "webVersion" :'1.0.2',
                "trackingType" : trackingType,
                "trackingAction" : tracking.trackingAction,
                "trackingCd": tracking.trackingCd,
                "trackingNm": tracking.trackingNm,
                "trackingDesc" : tracking.trackingDesc
            };

            /* 트레킹 기록 저장*/
            this.$http.post('/api/tracking/insert', params)
            // eslint-disable-next-line no-unused-vars
                .then(res => {

                })

                .catch(error => {
                        console.log(error);
                    }
                );
        },
            Vue.prototype.$getPublicIP = async function () {
                try {
                    let response = await fetch('https://api.ip.pe.kr/json/');
                    if (response.ok) {
                        const data = await response.json();
                        return data.ip;
                    } else {
                        throw new Error('Failed to fetch IP address');
                    }
                } catch (error) {
                    return '';
                }
            },
        Vue.prototype.$scrollNon = async function () {
            document.body.style.overflow = 'hidden';
        },
        Vue.prototype.$scrollActive = async function () {
            document.body.style.overflow = '';
        },
        Vue.prototype.$stopEventBubble = function(){
            // IE 제외
            if(event.stopPropagation()){
                event.stopPropagation();
            }

            // IE 전용
            event.cancelBubble = true;
        },
        Vue.prototype.$get = function(url){
            return new Promise((resolve, reject) => {
                const config = {
                    headers: {
                        'Content-Type': 'application/json',
                        'auth-token': localStorage.getItem("authToken"),
                        'refresh-token': localStorage.getItem("refreshToken"),
                    },
                };

                this.$http.get(url, config)
                    .then(res => {
                        if(res.headers.new_token != null){
                            localStorage.setItem("authToken",res.headers.new_token);
                        }
                        resolve(res.data);
                    })
                    .catch(error => {
                        // 토큰 만료 시 스토리지 삭제
                        if(error.response.data.status == 403 && error.response.data.message == "Access Denied"){
                            window.localStorage.setItem('authenticated', false);
                            // this.$router.push('/home');
                            // localStorage.removeItem("authToken");
                            // localStorage.removeItem("refreshToken");
                            // localStorage.removeItem("custInfo");
                            // if(confirm('토큰이 만료되어 로그인 페이지로 이동합니다')) {
                            //     window.location = '/home';
                            // }
                        }
                        reject(error);
                    });
            })
        },
        Vue.prototype.$post = function(url,data){
            return new Promise((resolve, reject) => {
                const config = {
                    headers: {
                        'Content-Type': 'application/json',
                        'auth-token': localStorage.getItem("authToken"),
                        'refresh-token': localStorage.getItem("refreshToken"),
                    },
                };

                this.$http.post(url, data, config)
                    .then(res => {
                        if(res.headers.new_token != null){
                            localStorage.setItem("authToken",res.headers.new_token);
                        }
                        resolve(res.data);
                    })
                    .catch(error => {
                        // 토큰 만료 시 스토리지 삭제
                        if(error.response.data.status == 403 && error.response.data.message == "Access Denied"){
                            window.localStorage.setItem('authenticated', false);
                            // this.$router.push('/home');
                            // localStorage.removeItem("authToken");
                            // localStorage.removeItem("refreshToken");
                            // localStorage.removeItem("custInfo");
                            // if(confirm('토큰이 만료되어 로그인 페이지로 이동합니다')) {
                            //     window.location = '/home';
                            // }
                        }
                        reject(error);
                    });
            })
        }
        Vue.prototype.$excelDown = function(excel, worksheetName, fileName) {

            const dataWS = XLSX.utils.json_to_sheet(excel);
            dataWS["!cols"] = [
                {wpx: 90},
                {wpx: 250},
                {wpx: 100},
                {wpx: 50},
                {wpx: 100},
                {wpx: 100},
                {wpx: 100},
                {wpx: 100},
                {wpx: 100},
                {wpx: 100},
            ];
            const wb = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, dataWS, worksheetName);
            XLSX.writeFile(wb, fileName);
        };
    }
    ,
}