<template>
    <div class="ser-detail intro-anlst">
        <div class="wrap">
            <h3 class="h3"><span>분석가 소개</span></h3>
            <h2 class="h2">내가 원하는 분석가를 <span>톡톡!</span></h2>
        </div>
        <div class="intro-anlst__area ai-intro">
            <h2 class="intro-anlst__area--title">AI 분석가</h2>
            <div class="ai-intro__group ai-bg">
                <div class="ai-bg__unit--bg big">
                    <div class="ai-bg__item peng">
                        <ul>
                            <li class="ai-bg--img"></li>
                            <li class="ai-bg--name">
                                <div class="ai-bg__element">
                                    <p class="sub">외곽분석</p>
                                    <p class="title">펭</p>
                                </div>
                            </li>
                        </ul>
                    </div>
                    <div class="ai-bg__item suri">
                        <ul>
                            <li class="ai-bg--img"></li>
                            <li class="ai-bg--name">
                                <div class="ai-bg__element">
                                    <p class="sub">중심/외곽 분석</p>
                                    <p class="title">수리</p>
                                </div>
                            </li>
                        </ul>
                    </div>
                    <div class="ai-bg__item dog">
                        <ul>
                            <li class="ai-bg--img"></li>
                            <li class="ai-bg--name">
                                <div class="ai-bg__element">
                                    <p class="sub">중심 분석</p>
                                    <p class="title">도그</p>
                                </div>
                            </li>
                        </ul>
                    </div>
                    <div class="ai-bg__item--mini left"></div>
                    <div class="ai-bg__item--mini right"></div>
                </div>
            </div>
            <!-- ai 분석가 카드 -->
            <ul class="intro-anlst--wrap intro-anlst__group ai-card__group">
                <li id="int-anlst-dog" class="intro-anlst__box--card dog">
                    <p class="txt--title intro-anlst__box--img mobile">도그</p>
                    <div class="intro-anlst__element">
                        <p class="txt--sub">예정 가격이 가장 많이 분포하는 <span>중심을 분석</span>하는 도그입니다.</p>
                        <p class="txt--sub">예가 분석을 원할 때 추천합니다.</p>
                        <i class="intro-anlst__box--img pc"></i>
                    </div>
                </li>
                <li id="int-anlst-suri" class="intro-anlst__box--card suri">
                    <p class="txt--title intro-anlst__box--img mobile">수리</p>
                    <div class="intro-anlst__element">
                        <p class="txt--sub"><span>중심과 외곽을 넘나들며</span> 예정 가격 분포 표의 가장 적합한 지점을 분석하는 수리입니다.</p>
                        <p class="txt--sub">분석 지점이 고민 될 때 추천합니다.</p>
                        <i class="intro-anlst__box--img pc"></i>
                    </div>
                </li>
                <li id="int-anlst-peng" class="intro-anlst__box--card peng">
                    <p class="txt--title intro-anlst__box--img mobile">펭</p>
                    <div class="intro-anlst__element">
                        <p class="txt--sub">예가 출현 빈도수 대비 경쟁률이 낮은 구간인 <span>외곽을 분석</span>하는 펭입니다.</p>
                        <p class="txt--sub">틈새 시장 공략을 원할 때 추천합니다.</p>
                        <i class="intro-anlst__box--img pc"></i>
                    </div>
                </li>

            </ul>
            <!-- /ai 분석가 카드 -->
        </div>
        <div class="intro-anlst--wrap intro-anlst__area cooper-intro">
            <h2 class="intro-anlst__area--title">분석 기업</h2>
            <!--  분석기업 카드 -->
            <ul class="intro-anlst__group cooper-card__group">
                <li id="int-anlst-jin" class="intro-anlst__box--card jin">
                    <p class="txt--title intro-anlst__box--img mobile">진비드</p>
                    <div class="intro-anlst__element">
                        <p class="txt--sub">진비드는 가치와 미래를 최우선으로 생각합니다. 다년간 쌓아온 경력을 가진 분석가들이 최고의 가격을 제공합니다.</p>
                        <i class="intro-anlst__box--img pc"></i>
                    </div>
                </li>
                <li id="int-anlst-inbid" class="intro-anlst__box--card inbid">
                    <p class="txt--title intro-anlst__box--img mobile">인비드</p>
                    <div class="intro-anlst__element">
                        <p class="txt--sub">좋은 인연과 함께하는 인비드입니다. 좋은 인연, 좋은 결과로 보답해 드리겠습니다.</p>
                        <i class="intro-anlst__box--img pc"></i>
                    </div>
                </li>
                <li id="int-anlst-plus" class="intro-anlst__box--card bidplus">
                    <p class="txt--title intro-anlst__box--img mobile">비드 플러스</p>
                    <div class="intro-anlst__element">
                        <p class="txt--sub">입찰에 낙찰을 더하는 비드플러스입니다. 최고의 분석서비스로 항상 신뢰할 수 있는 벗이되겠습니다.</p>
                        <i class="intro-anlst__box--img pc"></i>
                    </div>
                </li>
                <li id="int-anlst-sol" class="intro-anlst__box--card sol">
                    <p class="txt--title intro-anlst__box--img mobile">솔비드</p>
                    <div class="intro-anlst__element">
                        <p class="txt--sub">소나무의 푸르름과 우직함을 본받은 열정의 솔비드입니다. 분석가들의 상호 커뮤니케이션을 통해 최고의 성과를 내겠습니다.</p>
                        <i class="intro-anlst__box--img pc"></i>
                    </div>
                </li>
                <li id="int-anlst-bidin" class="intro-anlst__box--card bidin">
                    <p class="txt--title intro-anlst__box--img mobile">비드인</p>
                    <div class="intro-anlst__element">
                        <p class="txt--sub">비드인은 업계 최초, 최고의 전자입찰 분석 컨설팅 기업입니다. 혁신적인 아이디어와 전문지식을 통해 고객님의 입찰을 지원합니다.</p>
                        <i class="intro-anlst__box--img pc"></i>
                    </div>
                </li>
                <li id="int-anlst-chung" class="intro-anlst__box--card chung">
                    <p class="txt--title intro-anlst__box--img mobile">청비드</p>
                    <div class="intro-anlst__element">
                        <p class="txt--sub">고객님의 성공이 바로 저희 청비드의 가장 큰 기쁨이며, 함께 빛나는 성공의 길을 걸어가고자 합니다.</p>
                        <i class="intro-anlst__box--img pc"></i>
                    </div>
                </li>
            </ul>
            <!--  /분석기업 카드 -->
        </div>
    </div>
</template>

<script>
    export default {
        name: "ServiceDetailSuit",
        mounted() {
            document.addEventListener("DOMContentLoaded", function() {

                const targetId = window.location.hash;

                setTimeout(function() {
                    let targetElement = document.querySelector(`${targetId}`);

                    if (targetElement) {
                        targetElement.scrollIntoView({ behavior: 'smooth' });
                    }
                }, 500);
            });

            ////////////////////////////////////////////////////////////////////

            /*  스크롤 애니메이션  */

            ////////////////////////////////////////////////////////////////////
            //
            // /*  기준 영역 변수 설정  */
            // const boxes = document.querySelectorAll('.std');
            //
            // //1. 스크린 크기에 따른 애니메이션 구간 설정
            //
            // function calculateAnimationAreas() {
            //     const windowWidth = window.innerWidth;
            //
            //     if (windowWidth >= 1001) {
            //         return {
            //             areaTxt: window.innerHeight / 5 * 4
            //         };
            //     } else if (windowWidth >= 801) {
            //         return {
            //             areaTxt: window.innerHeight * 0.8
            //         };
            //     } else  {
            //         return {
            //             areaTxt: window.innerHeight * 0.8
            //         };
            //     }
            // }
            //
            //
            // //2. 텍스트 애니메이션 영역에 해당할 때 클래스 추가 / 제거
            // function applyTxtAni(elements, condition) {
            //     elements.forEach((element, index) => {
            //         setTimeout(() => {
            //             if (condition) {
            //                 element.classList.add('active');
            //             } else {
            //                 element.classList.remove('active');
            //             }
            //         }, index * 200);
            //     });
            // }
            //
            //
            //
            //
            //
            // // 3. 텍스트 애니메이션이 될 클래스 & 애니메이션이 될 영역 매개변수로 넘김
            // function txtAni() {
            //
            //     const { areaTxt } = calculateAnimationAreas();
            //     // 스크린에 따라 애니메이션 되어야 할 영역 가져옴
            //
            //     boxes.forEach((box) => {
            //
            //         const boxTop = box.getBoundingClientRect().top;
            //
            //         const fIElements = box.querySelectorAll('.f-i');
            //
            //         applyTxtAni(fIElements, boxTop <=  areaTxt);
            //
            //     });
            // }
            // txtAni();
            // window.addEventListener('resize', txtAni);
            // window.addEventListener('load', txtAni);
            // window.addEventListener('scroll', txtAni);
            //
            const aiIcon = document.querySelectorAll('.ai-bg__item');
            setTimeout(function () {
                let i = 0;
                function aiIconAddClass() {
                    if (i < aiIcon.length) {
                        aiIcon[i].classList.add('loca-active');
                        i++;
                        setTimeout(aiIconAddClass, 200);
                    }
                } aiIconAddClass();
            }, 800);

        }
    }


</script>



<!--<style src="@/IntroduceView.scss" lang="scss" scoped />-->