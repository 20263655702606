<template>
  <!-- gate -->
  <div class="gate">
<!--    <div class="gate__wrap">-->
<!--      <h1 class="gate__logo"></h1>-->
<!--      <p class="gate__title">비드톡톡앱 설치를 위한<br />다운로드 스토어를 선택해주세요.</p>-->
<!--      <ul class="gate__box">-->
<!--        <li class="gate__item">-->
<!--          <p class="gate__item&#45;&#45;txt"><span>안드로이드폰</span>을 사용중이시라면,</p>-->
<!--          <a href="https://play.google.com/store/apps/details?id=kr.co.newjini.bidtoktok&hl=ko" class="gate__item&#45;&#45;btn google"></a>-->
<!--          <p class="gate__item&#45;&#45;title">구글플레이스토어</p>-->
<!--        </li>-->
<!--        <li class="gate__item">-->
<!--          <p class="gate__item&#45;&#45;txt"><span>아이폰</span>을 사용중이시라면,</p>-->
<!--          <a href="https://itunes.apple.com/app/6450491526" class="gate__item&#45;&#45;btn app"></a>-->
<!--          <p class="gate__item&#45;&#45;title">애플앱스토어</p>-->
<!--        </li>-->
<!--      </ul>-->
<!--    </div>-->
  </div>
  <!-- /gate -->
</template>

<script>
export default {
  name: 'download',
  data() {
    return {
      message3: ''
    }
  },
  methods: {
  },
    created() {
        window.location.href = 'https://v01appflutter.page.link/openApp';
    }
};
</script>

