<template>
  <div class="popUp">
    <a href="#" onclick="return false" @click="goSurvey()" class="popUp__viewMore"><img src="@/assets/survey/img_viewMore.svg" alt="설문조사 바로가기 이미지"></a>
    <img src="@/assets/survey/img_survay02.png" alt="설문조사 이미지">
  </div>
</template>
<!--<style>-->
<!--  body {-->
<!--    border-radius: 20px 20px 0 0;-->
<!--    background: rgba(0,0,0,0.55);-->
<!--  }-->
<!--</style>-->
<script>
export default {
  name: 'Typography',
  data() {
    return {
      message3: ''
    }
  },
  methods: {
    goSurvey: function() {
      // eslint-disable-next-line no-undef
      JavaScriptChannel.postMessage("https://form.office.naver.com/form/responseView.cmd?formkey=ODk5YjQ0ZmQtZWMxNS00YTU5LWIyOGQtZTRkNzkwYzJjNzBl&sourceId=editor");
    }
  }
};
</script>

<style src="./HomePop.scss"  lang="scss" scoped/>