<template>
    <div class="recom-announce">
        <!-- ai 멤버십 배너 -->
        <a href="javascript:void(0);" class="ai-mbr--banner" v-if="Object.keys($parent.loginInfo).length == 0 || (this.custCntrctList.length > 0 && $parent.loginInfo.subStatusCd != '1')" @click="$router.push('/membership');">
            <p class="txt">AI멤버십으로 가볍게 시작하세요!
                <span class="pc">AI멤버십으로 AI분석요청 할 경우 성공보수료 0원!</span>
                <span class="mobile"></span>
            </p>
            <span class="btn btn-cta">AI멤버십 신청하기</span>
        </a>
        <!-- /ai 멤버십 배너 -->
        <div class="wrap">
        <!-- 내 경영/면허 정보 -->
        <div class="recom-announce__container user-info">
            <div class="nonlog-banner" v-if="Object.keys($parent.loginInfo).length == 0">
                <div class="nonlog-banner__txt--group">
                    <p class="title">경영 정보와 면허 정보를 계산하여<br><span class="empha">공고를 추천</span>해드려요.</p>
                    <p class="sub">자동 분석 기능으로 간편하게<br>분석을 요청해 보세요.</p>
                    <div class="nonlog-banner--bg recom"></div>
                </div>
            </div>
            <p class="recom-announce--help" v-if="Object.keys($parent.loginInfo).length != 0 && $moment('2024-06-01').isBefore(this.$moment()) && $moment().isBefore('2024-07-01')" >*일반면허 또는 전문면허를 보유한 고객님은 3년실적, 5년실적을 변경해주세요</p>
            <p class="recom-announce--help" v-if="Object.keys($parent.loginInfo).length != 0 && $moment('2024-07-01').isBefore(this.$moment()) && $moment().isBefore('2024-07-31')">*일반면허 또는 전문면허를 보유한 고객님은 부채비율, 유동비율을 변경해주세요</p>
            <div class="recom-announce__container--headline" v-if="Object.keys($parent.loginInfo).length != 0">
                <h3 class="h3">내 경영/면허 정보</h3>
                <div class="user-info--tool">
                    <a href="#" onclick="return false"  class="btn-basic btn-line-cw recom-announce--btn user-info--edit" @click="popModifyiLicns()">수정</a>
                    <a href="javascript:void(0);" class="btn-basic recom-announce--btn accordion-func--btn" @click="clickAccordian()">{{!accordionActive? '상세보기' : '숨기기'}}
                        <i class="ic--view--accordion" :class="accordionActive? 'active' : ''"></i>
                    </a>
                </div>
            </div>
            <div class="recom-announce__area user-info__area accordion-func__body" :class="accordionActive? 'active' : ''"  v-if="Object.keys($parent.loginInfo).length != 0">
                <a href="#" onclick="return false"  class="recom-announce--btn user-info--edit mobile" @click="popModifyiLicns()">수정</a>
                <!-- 경영 정보 -->
                <div class="user-info__list manage">
                    <div class="user-info__list--head">
                        <h4 class="h4">경영 정보</h4>
                    </div>
                    <ul class="user-info__list--cont">
                        <li class="user-info__list--item">
                            <p class="txt--title">부채비율</p>
                            <p class="txt--sub">{{custBiz.debtRate != null ? custBiz.debtRate+'%' : '-'}}</p>
                        </li>
                        <li class="user-info__list--item">
                            <p class="txt--title">유동비율</p>
                            <p class="txt--sub">{{custBiz.crasRate != null ? custBiz.crasRate+'%' : '-'}}</p>
                        </li>
                        <li class="user-info__list--item">
                            <p class="txt--title">신용평가</p>
                            <p class="txt--sub" v-show="custBiz.custPrfsh !=null && custBiz.custPrfsh.proofRnwlDe != null">{{custBiz.custPrfsh !=null  ? custBiz.custPrfsh.prfshEtcNm :''}}
                                <span v-show="custBiz.custPrfsh !=null">(~{{ custBiz.custPrfsh !=null  ? $moment(custBiz.custPrfsh.proofRnwlDe).format('YYYY-MM-DD') : ''}}
                                    l {{ custBiz.custPrfsh !=null ? custBiz.custPrfsh.prfshOrgnzNm : ''}})</span>
                                <span v-show="custBiz.custPrfsh ==null">-</span>
                            </p>
                            <p  class="txt--sub" v-show="custBiz.custPrfsh ==null || custBiz.custPrfsh.proofRnwlDe == null">
                                -
                            </p>

                        </li>
                    </ul>
                </div>
                <!-- /경영 정보 -->

                <div class="user-info__list license">
                    <div class="user-info__list--head">
                        <h4 class="h4">면허 정보</h4>
                        <h5 class="h5 help"> 면허를 각각 클릭하여</h5>
                        <div class="user-info__unit" v-show="selectLicns.custPrtcAmtList != null && selectLicns.custPrtcAmtList.length > 0">
                        </div>
                    </div>
                    <div class="user-info__list--cont">
                        <!-- 면허 종류 슬라이드 -->
                        <div class="tok-slide__wrap user-info__slide">
                            <div class="tok-slide__container">
                                <ul class="tok-slide--list user-info__slide--list">
                                    <li class="tok-slide__item user-info__slide--item" :class="selectLicns == licns ? 'active' : '' " v-for="licns in custLicnsList" v-bind:key="licns.id">
                                        <a href="#" onclick="return false" @click="selectLicns = licns;">{{licns.licnsNm}}</a></li>
                                </ul>
                            </div>
                            <ul class="tok-slide__arrow">
                                <li class="tok-slide__arrow--item pre user-info__slide--arrow--item" @click="previos">previos </li>
                                <li class="tok-slide__arrow--item next user-info__slide--arrow--item" @click="next">next</li>
                            </ul>
                        </div>
                        <!-- /면허 종류 슬라이드 -->

                        <!-- 면허 정보 -->
                        <h4 class="txt--sel-license"><span>{{selectLicns.licnsNm}}</span></h4>
                        <ul class="user-info__list">
                            <li class="user-info__list--item">
                                <p class="txt--title">3년 실적</p>
                                <p class="txt--sub">{{selectLicns.y3AcrsltAmt | currency}}<span v-show="selectLicns.y3AcrsltAmt != null && selectLicns.y3AcrsltAmt != '-'"> 원</span></p>
                            </li>
                            <li class="user-info__list--item">
                                <p class="txt--title">5년 실적</p>
                                <p class="txt--sub">{{selectLicns.y5AcrsltAmt | currency}}<span v-show="selectLicns.y3AcrsltAmt != null && selectLicns.y3AcrsltAmt != '-'"> 원</span></p>
                            </li>
                            <li class="user-info__list--item">
                                <p class="txt--title">추가 실적</p>
                                <p class="txt--sub">{{selectLicns.lcettSuplmtAcrsltAmt | currency}}<span v-show="selectLicns.y3AcrsltAmt != null && selectLicns.y3AcrsltAmt != '-'"> 원</span></p>
                            </li>
                            <li  class="user-info__list--box">
                                <div class="user-info__list--item">
                                    <p class="txt--title">시공능력 평가액</p>
                                    <p class="txt--sub">{{selectLicns.cswkCapaEvltnAmt | currency}}<span v-show="selectLicns.y3AcrsltAmt != null && selectLicns.y3AcrsltAmt != '-'"> 원</span></p>
                                </div>
                                <div class="user-info__list--item">
                                    <p class="txt--title">영업 기간</p>
                                    <p class="txt--sub" v-show="selectLicns.bsnRegstDe != null">{{selectLicns.bsnRegstDe}}<span v-show="selectLicns.bsnRegstDe != '-'"> ({{ selectLicns.bsnPerdCd | bsnPerdText}})</span></p>
                                    <p class="txt--sub" v-show="selectLicns.bsnRegstDe == null">-</p>
                                </div>
                            </li>
                        </ul>
                        <!-- /면허 정보 -->
                    </div>
                </div>
                <!-- 최대 참여 가능 공고 -->
                <div class="user-info__list cost" v-show="selectLicns.custPrtcAmtList != null && selectLicns.custPrtcAmtList.length > 0">
                    <div class="user-info__list--head">
                        <h4 class="h4">더 정확한 맞춤공고 <span class="line-break">서비스 받기</span></h4>
                    </div>
                    <ul class="user-info__list--cont">
                        <li class="cost--list" @click="$refs.BefLicnsLayerPop.isPopBefLicnsPop = true;" v-show="isBefLmtAmtYn == false">
                            2022년 기준 <span class="line-break">내 최대 참여 가능한 공고 금액 알아보기 <i class="ic--arrow"></i></span>

                        </li>
                        <li class="cost--list" >
                            <span class="user-info--tooltip--title" @click="isPopPrtcAmt = true"> 내 최대 참여 가능한 공고 금액은?<i class="ic--arrow"></i></span>
                            <div class="tooltip__group user-info--tooltip" >
                                <span class="tooltip__box">?</span>
                                <span id="prtcAmtToolTip" class="tooltip--txt user-info--tooltip--txt">
                                경영상태와 실적을 바탕으로 <span class="line-break--01">계산된 금액입니다.</span>
                            </span>
                            </div>
                        </li>
                    </ul>
                </div>
                <!-- /최대 참여 가능 공고 -->
            </div>
        </div>
        <!-- /내 경영/면허 정보 -->

        <!-- 자동분석 -->
        <div class="recom-announce__container auto-analy" v-if="Object.keys($parent.loginInfo).length != 0">
            <div class="recom-announce__container--headline">
                <h3 class="h3">자동분석</h3>
                <div class="toggle auto-analy--toggle" @click.stop="clickAutoAnls()" :class="isAutoAnls ? 'active' : ''">
                    <div class="toggle__switch auto-analy--toggle--switch">
                        <span class="toggle__btn auto-analy--toggle--btn"></span>
                    </div>
                </div>
                <div class="auto-analy--tool" v-show="isAutoAnls">
                    <p class="txt--title">기간 <span class="txt--sub">{{anlstStartDt}} ~ {{anlstEndDt}}</span></p>
                    <p class="txt--title">분석가 <span class="txt--sub" v-show="autoAnlsConfigList.length > 1">자동배정 ({{autoAnlsConfigList.length}}명)</span>
                        <span class="txt--sub" v-show="autoAnlsConfigList.length == 1">{{autoAnlsConfigList[0]!= null ? autoAnlsConfigList[0].anlstNickNm : ''}}</span></p>
                    <a href="#" onclick="return false"  class="btn-basic btn-line-cw recom-announce--btn auto-analy--edit" @click="clickModifyAutoAnls">변경</a>
                </div>
            </div>
            <!-- 배너 - 자동분석 꺼짐 -->
            <div class="recom-announce__area auto-analy__banner off" :style="isAutoAnls ? 'display: none' : 'display: block'">
                <p class="txt--title txt--stand">매번 분석가를 선택하기 번거로우셨다면?</p>
                <p class="txt--sub">원클릭으로 매일매일 자동으로 분석 요청을 해드립니다.<br>지금 바로 ‘자동분석’ 켜짐을 눌러보세요!</p>
            </div>
            <!-- /배너 - 자동분석 꺼짐 -->

            <!-- 배너 - 자동분석 켜짐 -->
            <div class="recom-announce__area auto-analy__banner on" :style="!isAutoAnls ? 'display: none' : 'display: block'">
                <div class="auto-analy--tool mobile">
                    <a href="#" onclick="return false"  class="recom-announce--btn auto-analy--edit mobile" @click="isPopSetAutoAnls = true">변경</a>
                    <p class="txt--title"><span>기간</span> <span class="txt--sub">{{anlstStartDt}} ~ {{anlstEndDt}}</span></p>
                    <p class="txt--title"><span>분석가</span> <span class="txt--sub">자동배정 ({{autoAnlsConfigList.length}}명)</span></p>
                </div>
                <p class="txt--sub txt--stand">투찰 마감일 기준 4일치(당일 포함) 공고가</p>
                <p class="txt--title">매일 오전 8시 자동분석 요청 중입니다.</p>
                <p class="txt--sub"><span class="strong">분석실</span>에서 분석제시가를 확인해 주세요.</p>
            </div>
            <!-- /배너 - 자동분석 켜짐 -->
        </div>
        <!-- /자동분석 -->

        <!-- 맞춤 공고 리스트 -->
        <div class="recom-announce__container analist analysis">
            <div class="search-banner analist__area--tool">
                <p class="inquiry__total txt__title analysis__total">총<span>{{notiSugguestListCnt}}</span>건</p>
                <div class="analist__box">
                    <div class="analist--check class">
                        <input type="checkbox" id="check01" @click="clickFacilityYn" :checked="facilityYn">
                        <label for="check01">용역, 물품 제외</label>
                    </div>
                    <div class="analist--check local">
                        <input type="checkbox" id="check02" @click="clickWnctyYn" :checked="wnctyYn">
                        <label for="check02">전국 제외</label>
                    </div>
                </div>
                <div class="bt-srch--rev recom--search">
                    <div class="bt-srch--btn analist--show-toggle" :class="isHideYn ? 'active' : ''" @click="isHideYn = !isHideYn; searchKeyword()"><span class="tit">숨김공고 보기</span></div>
                    <div class="search">
                        <input type="text" placeholder="공고명, 공고번호를 검색해 주세요." v-model="searchString" @keyup.enter="searchKeyword()">
                        <button type="reset" class="btn__cancel" @click="searchString='';searchKeyword()">취소</button>
                    </div>
                </div>
            </div>
            <div class="analist__list layout__half announce">
                <!-- 비로그인 - 공고 리스트 -->
                <div class="announce__board--state non-log" v-if="Object.keys($parent.loginInfo).length == 0">
                    <div class="announce__board--img"></div>
                    <div class="announce__board--txt">
                        <p class="title">로그인 후, 이용 가능한 기능입니다.</p>
                        <p class="sub">회원이 아닌 경우, 회원가입 후 로그인해 주세요.</p>
                    </div>
                    <div class="announce__board--button">
                        <a href="#" onclick="return false" class="btn-basic btn-line-cb" @click="$goMenu('joinForm')">회원가입</a>
                        <a href="#" onclick="return false" class="btn-basic btn-cta" @click="$goMenu('login')">로그인</a>
                    </div>
                </div>
                <!-- /비로그인 - 공고 리스트 -->
                <!-- 경영면허 정보 미입력시 정보입력 유도 -->
                <div class="entire-search entire-search--no-res no-res" v-show="isNoValueCustLicns == true">
                  <div class="no-res__group">
                    <div class="no-res--img"></div>
                    <p class="title">경영/면허정보가 없어 <br>맞춤공고를 추천하지 못했습니다.</p>
                    <p class="sub">경영/면허정보를 입력하시고 맞춤공고를 추천받으세요.</p>
                  </div>
                  <a href="#" onclick="return false" class="btn-basic btn-line-cb" @click="popModifyiLicns()">경영/면허 정보 입력하기</a>
                </div>
                <!-- /추천 공고가 없을 경우 -->
                <!-- /경영면허 정보 미입력시 정보입력 유도 -->
                <!-- 추천 공고가 없을 경우 -->
                <div class="entire-search entire-search--no-res no-res" v-show="Object.keys($parent.loginInfo).length != 0 && (isPopNotExistCustLicns != true && isNoValueCustLicns!= true) && (notiSuggestList.length == 0 && isHideYn == false && !isProcessing)">
                    <div class="no-res--img"></div>
                    <p class="title">추천된 맞춤공고가 없습니다.</p>
                    <p class="sub">찾는 공고가 없으시다면 통합검색을 이용해주세요.</p>
                </div>
                <!-- /추천 공고가 없을 경우 -->

                <!-- 숨김 공고가 없을 경우 -->
                <div class="entire-search entire-search--no-res no-res" v-show="Object.keys($parent.loginInfo).length != 0 && (isPopNotExistCustLicns != true && isNoValueCustLicns!= true) && (notiSuggestList.length == 0 && isHideYn == true && !isProcessing)">
                    <div class="no-res--img"></div>
                    <p class="title">숨기기된 공고가 없습니다.</p>
                </div>
                <!-- /숨김 공고가 없을 경우 -->
                <span v-for="items in suggestNotiGroup" v-bind:key="items.id">
                <!-- 날짜, 건수 표기 -->
                    <ul class="announce__date">
                        <li class="announce__date--mmdd">
                            <h3 class="txt__title">{{ $moment(items[0].bddprFinDt).format('MM월 DD일 (ddd)') }}</h3>
                        </li>
                        <li class="announce__date--count">
                            <p>{{items.length}}</p><span>건</span>
                        </li>
                    </ul>
                    <!-- /날짜, 건수 표기 -->

                    <div>
                <div class="layout__item announce--item" v-for="noti in items"  v-bind:key="noti.id">
                    <!-- *투찰 현황 condition-badge__item 뒤에 클래스 덧붙임
                           투찰전 > before, 투찰가능 > ing, 투찰마감 > fin, 개찰완료 > announce,  참가신청 > application -->
                    <div class="announce__group condition-badge announce--item--badge">
                        <p class="condition-badge__item" :class="noti.notiStatus == '투찰전' ? 'before' :
                        noti.notiStatus == '투찰가능' ? 'ing' :
                        noti.notiStatus == '투찰마감' ? 'fin' : noti.notiStatus == '개찰완료' ? 'announce': ''"><span></span></p>
                        <button class="announce--toggle-btn show" v-if="isHideYn" @click="isPopNotiHideConf=true; selectNotiId = noti.id"><span class="tit">보이기</span></button>
                        <button class="announce--toggle-btn hide" v-if="!isHideYn" @click="isPopNotiHideConf=true; selectNotiId = noti.id"><span class="tit">숨기기</span></button>
                    </div>
                    <a href="#" onclick="return false" class="announce__group announce--item--move-detail" @click.prevent="notiDetailPop('notiDetail',noti.notiId)">
                        <p class="txt__title">
                            <span class="condition-badge__item application" v-show="noti.regstVisibleYn == 'Y' && noti.notiStatus == '투찰가능'"><span></span></span>
                            <span class="bdg-code code07 mr4" v-show="noti.rebidNo > 0">재입찰</span>
                            {{noti.notiNm}}</p>
                        <div class="viw-all" v-show="deviceType == 'Desktop'"><p class="txt__title">{{noti.notiNm}}</p></div>
                        <div class="announce__copy">
                            <div class="announce__copy--item">
                                <span class="txt__sub">{{noti.notiNo}}{{noti.notiSeq != null && noti.notiSeq != '' ? ('-' + noti.notiSeq) : ''}}</span>
                                <button type="button" class="btn__copy" @click.prevent="copyNotiNo(noti.notiNo)"></button>
                            </div>
                        </div>
                        <ul class="announce__info">
                            <li class="announce__info--list announce__info--company"><span class="txt__title">지역</span><span class="txt__sub">{{noti.areaNmConcat}}</span></li>
<!--                            <li class="announce__info&#45;&#45;list announce__info&#45;&#45;company"><span class="txt__title">면허</span><span class="txt__sub">{{noti.licnsNmConcat}}</span></li>-->
                            <li class="announce__info--list announce__info--company">
                                <span class="txt__title">면허</span>
                                <div class="main-lisc bdg-lic">
                                    <i class="main-lisc--ic bdg-lic--ic main" v-show="noti.noticeMainLicnsList.length > 0">주력</i>
                                    <div class="viw-all" v-show="deviceType == 'Desktop'">
                                        <p class="txt__title">{{noti.noticeMainLicnsList.map((e) => e.licnsNm).join(" | ")}}</p>
                                    </div>
                                    <span class="txt__sub">
                                     {{noti.licnsNmConcat}}
                                    </span>
                                </div>
                            </li>
<!--                            <li class="announce__info&#45;&#45;list announce__info&#45;&#45;company"><span class="txt__title"  v-show="noti.noticeMainLicnsList != null ">주력</span>-->
<!--                                <span class="txt__sub" v-for="licns in noti.noticeMainLicnsList" v-bind:key="licns.id">{{licns.licnsNm}}</span>-->
<!--                            </li>-->
                            <li class="announce__info--list"><span class="txt__title">기초금액</span><span class="txt__sub">{{noti.baseAmt | currency}}</span></li>
                        </ul>
                    </a>
                    <div class="announce__period">
                        <p class="txt__title" v-show="noti.regstVisibleYn == 'Y' && noti.notiStatus == '투찰가능'">참가마감</p>
                        <span class="txt__sub" :class="noti.isRegDt ? 'announce__period--today' : ''" v-show="noti.regstVisibleYn == 'Y' && noti.notiStatus == '투찰가능'">{{noti.regstFinDtText}}</span>
                        <span class="dividing-line" v-show="noti.regstVisibleYn == 'Y' && noti.notiStatus == '투찰가능'"></span>
                        <p class="txt__title">투찰마감</p>
                        <span class="txt__sub" :class="noti.isBddprDt ? 'announce__period--today' : ''">{{noti.bddprFinDtText}}</span>
                    </div>
                    <button type="button" class="announce--cta-btn btn-basic announce--request" @click="checkCustCntrct(noti.notiId)"><span>분석 요청</span></button>
                </div>
                    </div>
                </span>
            </div>
            <button class="btn-basic btn-line-cb analist--btn--more" v-show="notiSuggestList.length < notiSugguestListCnt" @click="page++; getNotiSuggestList()"><span>{{notiSugguestListCnt - notiSuggestList.length > pageUnit ? pageUnit : notiSugguestListCnt - notiSuggestList.length}}개 더 보기&#8595;</span></button>
            <div class="analist__list--fin active"><span class="analist__list--fin--txt" v-show="notiSuggestList.length >= notiSugguestListCnt && notiSugguestListCnt != 0 && notiSugguestListCnt > pageUnit">모두 확인하셨습니다.</span></div>
        </div>
        <!-- /맞춤 공고 리스트 -->

        <!-- 맞춤공고 설명 팝업
            recom-announce__pop--container 뒤에 active를 넣으면 켜집니다.  -->
        <div class="recom-announce__pop--container pop--floating--container" :class="  isPopSuggestNotiDesc ? 'active' : ''">
            <div class="pop--floating__content">
                <p class="txt--title">맞춤공고는 입력한 <span>경영 정보와 면허 정보를</span><br> 계산하여 추천된 공고입니다.
                </p>
            </div>
            <div class="pop--floating__bottom">
                <a href="#" onclick="return false" class="btn-basic btn-line-cb close-pop pop--floating--btn" @click="setIsPopSuggestNotiCookie">3일간 보지 않기</a>
                <a href="#" onclick="return false" class="btn-basic btn-cta close-pop pop--floating--btn" @click="isPopSuggestNotiDesc = false;">확인</a>
            </div>
        </div>
        <!-- /맞춤공고 설명 팝업 -->

        <BefLicnsLayerPop ref="BefLicnsLayerPop"></BefLicnsLayerPop>
        <!-- 경영/면허 정보 수정 팝업-->
        <div class="popup__wrap recom-announce__pop--wrap edit-pop__wrap" :class="isPopSetMyInfo ? 'active' : ''" >
            <div class="popup__item--container recom-announce__pop--container">
                <div class="popup__item--group recom-announce__pop--group enter-pop__group">
                    <div class="popup__item--head">
                        <p class="txt__title">경영/면허 정보 수정</p>
                    </div>
                    <div class="popup__item--body recom-announce__pop--body">
                        <div class="edit-pop__unit manage tooltip">
                            <div class="tooltip--manage">
                                <h3 class="h3">① 경영 정보상태</h3>
                                <div class="tooltip__group">
                                    <span class="tooltip__box">?</span>
                                    <div class="tooltip--txt">{{tooltipTxt1}}</div>
                                </div>
                            </div>
                            <h4 class="h4">부채비율</h4>
                            <div class="input edit-pop--input manage--input">
                                <input id="debtRate" type="text" placeholder="부채비율을 입력해 주세요." v-model="debtRateComputed">
                            </div>
                            <h4 class="h4">유동비율</h4>
                            <div class="input edit-pop--input manage--input currnt-ratio">
                                <input id="crasRate" type="text" placeholder="유동비율을 입력해 주세요." v-model="crasRateComputed">
                            </div>
                            <div class="tooltip--credit">
                                <h4 class="h4">신용 평가</h4>
                                <div class="tooltip__group">
                                    <span class="tooltip__box">?</span>
                                    <div class="tooltip--txt">공공기관 입찰용으로 발급받은 신용평가등급확인서로만 사용할 수 있습니다.</div>
                                </div>
                            </div>
                            <div class="sel-box edit-pop--select">
                                <select name="grade" v-model="selGrd">
                                    <option value="none" disabled>등급을 선택해 주세요.</option>
                                    <option value="">해당사항 없음</option>
                                    <option v-for="cdlt in cdltStList" v-bind:key="cdlt.id" :value="cdlt.id">
                                        <li>{{cdlt.cdltGrd}}</li></option>
                                </select>
                            </div>
                            <div class="sel-box edit-pop--select" :class="selGrd == '' ? 'disable' : ''">
                                <select name="agency" v-model="selOrgnz" :disabled="selGrd == '' ? true : false">
                                    <option value="" disabled>발급기관을 선택해 주세요.</option>
                                    <option v-for="orgnz in cdltStOrgList" v-bind:key="orgnz.id"  :value="orgnz.comnCdNm">{{orgnz.comnCdNm}}</option>
                                </select>
                            </div>
                                <v-date-picker v-model="cdltStDe" >
                                    <template v-slot="{ inputValue, togglePopover }">
                                        <div class="input edit-pop--input manage--input calendar-btn expi-date disable" v-show="!selGrd == ''"
                                             @click="togglePopover">
                                            <input
                                                    class="input edit-pop--input manage--input"
                                                    placeholder="만료일을 선택해 주세요."
                                                    :value="inputValue"
                                            />
                                        </div>
                                        <div class="input edit-pop--input manage--input calendar-btn expi-date disable" v-show="selGrd == ''">
                                            <input type="text" class="disable" placeholder="만료일을 선택해주세요.">
                                        </div>
                                    </template>
                                </v-date-picker>
                        </div>
                        <div class="edit-pop__unit license">
                            <h3 class="h3">② 면허 정보<span class="txt--sub"> *면허별로 정보를 입력해주세요.</span></h3>
                            <div class="tok-slide__wrap user-info__slide">
                                <div class="tok-slide__container">
                                    <ul  class="tok-slide--list user-info__slide--list">
                                        <li class="tok-slide__item user-info__slide--item" :class="modifySelectLicns == licns ? 'active' : '' " v-for="licns in modifyCustLicnsList" v-bind:key="licns.id">
                                            <a href="#" onclick="return false" @click="clickLicns(licns)">{{licns.licnsNm}}</a>
                                        </li>
                                    </ul>
                                </div>
                                <ul class="tok-slide__arrow">
                                    <li class="tok-slide__arrow--item pre user-info__slide--arrow--item edit-pop__slide--arrow--item" @click="previos">previos </li>
                                    <li class="tok-slide__arrow--item next user-info__slide--arrow--item edit-pop__slide--arrow--item" @click="next">next</li>
                                </ul>
                            </div>
                            <div class="txt--title txt--sel-license lic-big">
                                <div class="bdg-lic" v-show="modifySelectLicns.mainLicnsCd == null && modifySelectLicns.licnsNm != null && modifySelectLicns.licnsNm.indexOf('(대)') != -1">
                                    <!-- 주력 main / 대업종 big-->
                                    <i class="bdg-lic--ic big">대업종</i>
                                </div>
                                <div class="bdg-lic" v-show="modifySelectLicns.mainLicnsCd != null">
                                    <!-- 주력 main / 대업종 big-->
                                    <i class="bdg-lic--ic main" >주력</i>
                                </div>
                                <span>{{modifySelectLicns.licnsNm}} </span>
                            </div>
                            <div class="tooltip--3year">
                                <h4 class="h4">3년 실적</h4>
                                <div class="tooltip__group">
                                    <span class="tooltip__box">?</span>
                                    <div class="tooltip--txt">{{tooltipTxt2}}</div>
                                </div>
                            </div>
                            <div class="input edit-pop--input license--input">
                                <input id="y3AcrsltAmt" type="text"  placeholder="3년 실적을 입력해 주세요." v-model="y3AcrsltAmtComputed" >
                            </div>
                            <div class="tooltip--5year">
                                <h4 class="h4">5년 실적</h4>
                                <div class="tooltip__group">
                                    <span class="tooltip__box">?</span>
                                    <div class="tooltip--txt">{{tooltipTxt3}}</div>
                                </div>
                            </div>
                            <div class="input edit-pop--input license--input">
                                <input id="y5AcrsltAmt"  type="text" placeholder="5년 실적을 입력해 주세요." v-model="y5AcrsltAmtComputed">
                            </div>
                            <div class="tooltip--perform">
                                <h4 class="h4">추가 실적</h4>
                                <div class="tooltip__group">
                                    <span class="tooltip__box">?</span>
                                    <div class="tooltip--txt">협회에 등록되지는 않았지만 최종 준공이 완료된 공사 실적 합계액입니다.</div>
                                </div>
                            </div>
                            <div class="input edit-pop--input license--input">
                                <input id="lcettSuplmtAcrsltAmt" type="text" placeholder="추가 실적을 입력해 주세요." v-model="lcettSuplmtAcrsltAmtComputed">
                            </div>
                            <div class="tooltip--capa">
                                <h4 class="h4">시공능력평가액</h4>
                                <div class="tooltip__group">
                                    <span class="tooltip__box">?</span>
                                    <div class="tooltip--txt">{{tooltipTxt4}}</div>
                                </div>
                            </div>
                            <div class="input edit-pop--input license--input">
                                <input id="cswkCapaEvltnAmt" type="text" placeholder="시공능력평가액을 입력해 주세요." v-model="cswkCapaEvltnAmtComputed">
                            </div>
                            <div class="tooltip--dura">
                                <h4 class="h4">영업기간</h4>
                                <div class="tooltip__group">
                                    <span class="tooltip__box">?</span>
                                    <div class="tooltip--txt">{{tooltipTxt5}}</div>
                                </div>
                            </div>
                            <v-date-picker v-model="bsnRegstDe" >
                                <template v-slot="{ inputValue, togglePopover }">
                                    <div class="input edit-pop--input manage--input calendar-btn expi-date"
                                         @click="togglePopover">
                                        <input
                                                class="input edit-pop--input manage--input"
                                                placeholder="영업기간을 선택해 주세요."
                                                :value="inputValue"
                                        />
                                    </div>
                                </template>
                            </v-date-picker>
                        </div>
                    </div>
                </div>
                <div class="popup__button--wrap pair">
                    <a href="#" onclick="return false" class="btn-basic btn-line-cb  popup__close" @click="isPopSetMyInfo = false;">취소</a>
                    <a href="#" onclick="return false" class="btn-basic btn-cta popup__close" @click="modifyCustBiz()">저장</a>
                </div>
            </div>
        </div>
        <!-- /경영/면허 정보 수정 팝업 -->

        <!-- 자동 분석 설정 변경 -->
        <div class="popup__wrap  recom-announce__pop--wrap set-pop__wrap recom-announce" :class="isPopSetAutoAnls ? 'active' : ''" >
            <AutoAnlsPop ref="AutoAnlsPop" v-if="isPopSetAutoAnls"></AutoAnlsPop>
        </div>

        <!-- /자동 분석 설정 변경 -->

        <!-- 경영면허 입력 강제 팝업 -->
        <div class="popup__wrap  recom-announce__pop--wrap enter-pop__wrap" :class="isPopForceSetMyInfo ? 'active' : ''" >
            <!-- 경영 면허 정보 입력 - 시작 -->
            <div class="popup__item--container recom-announce__pop--container enter-pop__container enter-pop--cover step-start" :class="isPopForceSetMyInfoStep1 ? 'active' : ''" >
                <div class="popup__item--group recom-announce__pop--group enter-pop__group step-start__group">
                    <div class="popup__item--head">
                        <p class="txt__title">경영/면허 정보를<br>입력해 주세요.</p>
                    </div>
                    <div class="popup__item--body recom-announce__pop--body enter-pop--body">
                        <div class="enter-pop--cover--img"></div>
                        <p class="txt--sub">맞춤공고는<br>입력한 경영/면허 정보를 계산하여<br>입찰에 참여 가능한 공고를 추천하므로</p>
                        <p class="txt--title">경영/면허 정보 입력이 필요합니다.</p>
                    </div>
                </div>
                <div class="popup__button--wrap pair">
                    <a href="#" onclick="return false" class="btn-basic btn-line-cb  popup__close" @click="isPopForceSetMyInfo = false;">취소</a>
                    <a href="#" onclick="return false" class="btn-basic btn-cta popup__next" @click="isPopForceSetMyInfoStep1 = false; isPopForceSetMyInfoStep2 = true;">정보입력</a>
                </div>
            </div>
            <!-- /경영 면허 정보 입력 - 시작 -->

            <!-- 경영 면허 정보 입력 - 경영 정보 -->
            <div class="popup__item--container recom-announce__pop--container enter-pop__container step-01" :class="isPopForceSetMyInfoStep2 ? 'active' : ''" >
                <div class="popup__item--group recom-announce__pop--group enter-pop__group">
                    <div class="popup__item--head">
                        <p class="txt__title">경영 정보</p>
                    </div>
                    <div class="popup__item--body recom-announce__pop--body edit-pop__wrap">
                        <div class="manage tooltip">
                            <div class="tooltip--manage">
                                <h3 class="h3">① 경영 정보상태</h3>
                                <div class="tooltip__group">
                                    <span class="tooltip__box">?</span>
                                    <div class="tooltip--txt">22년 경영상태동의확인서 적용입니다.</div>
                                </div>
                            </div>
                            <h4 class="h4">부채비율</h4>
                            <div class="input edit-pop--input manage--input">
                                <input id="forceDebtRate" type="text" placeholder="부채비율을 입력해 주세요." v-model="debtRateComputed">
                            </div>
                            <h4 class="h4">유동비율</h4>
                            <div class="input edit-pop--input manage--input currnt-ratio">
                                <input id="forceCrasRate" type="text" placeholder="유동비율을 입력해 주세요." v-model="crasRateComputed">
                            </div>
                            <div class="tooltip--credit">
                                <h4 class="h4">신용 평가</h4>
                                <div class="tooltip__group">
                                    <span class="tooltip__box">?</span>
                                    <div class="tooltip--txt">공공기관 입찰용으로 발급받은 신용평가등급확인서로만 사용할 수 있습니다.</div>
                                </div>
                            </div>
                            <div class="sel-box edit-pop--select">
                                <select name="grade" v-model="selGrd">
                                    <option value="none" disabled>등급을 선택해 주세요.</option>
                                    <option value="">해당사항 없음</option>
                                    <option v-for="cdlt in cdltStList" v-bind:key="cdlt.id" :value="cdlt.id">{{cdlt.cdltGrd}}</option>
                                </select>
                            </div>
                            <div class="sel-box edit-pop--select" :class="selGrd == '' ? 'disable' : ''">
                                <select name="agency" v-model="selOrgnz" :disabled="selGrd == '' ? true : false">
                                    <option value="">발급기관을 선택해 주세요.</option>
                                    <option v-for="orgnz in cdltStOrgList" v-bind:key="orgnz.id"  :value="orgnz.comnCdNm">{{orgnz.comnCdNm}}</option>
                                </select>
                            </div>
                            <v-date-picker v-model="cdltStDe" >
                                <template v-slot="{ inputValue, togglePopover }">
                                    <div class="input edit-pop--input manage--input calendar-btn expi-date disable" v-show="!selGrd == ''"
                                         @click="togglePopover">
                                        <input
                                                class="input edit-pop--input manage--input"
                                                placeholder="만료일을 선택해 주세요."
                                                :value="inputValue"
                                        />
                                    </div>
                                    <div class="input edit-pop--input manage--input calendar-btn expi-date disable" v-show="selGrd == ''">
                                        <input type="text" class="disable" placeholder="만료일을 선택해주세요.">
                                    </div>
                                </template>
                            </v-date-picker>
                        </div>
                    </div>
                </div>
                <div class="popup__button--wrap pair">
                    <a href="#" onclick="return false" class="btn-basic btn-line-cb popup__before" @click="isPopForceSetMyInfoStep1 = true; isPopForceSetMyInfoStep2 = false;">이전</a>
                    <a href="#" onclick="return false" class="btn-basic btn-cta popup__next" @click="clickForceSetMyInfoStep()">다음</a>
                </div>
            </div>
            <!-- /경영 면허 정보 입력 - 경영 정보 -->

            <!-- 경영 면허 정보 입력 - 면허 정보 -->
            <div class="popup__item--container recom-announce__pop--container enter-pop__container step-02" :class="isPopForceSetMyInfoStep3 ? 'active' : ''">
                <div class="popup__item--group recom-announce__pop--group enter-pop__group">
                    <div class="popup__item--head">
                        <p class="txt__title">면허 정보</p>
                    </div>
                    <div class="popup__item--body recom-announce__pop--body edit-pop__wrap">
                        <div class="license">
                            <div class="step-02--title">
                                <h3 class="h3">② 면허 정보</h3>
                                <p class="txt--title">총 <span class="count">{{custLicnsList.length}}</span> 개</p>
                                <span class="txt--sub">*면허별로 정보를 입력해주세요.</span>
                            </div>
                            <div class="tok-slide__wrap user-info__slide">
                                <div class="tok-slide__container">
                                    <ul class="tok-slide--list user-info__slide--list">
                                        <li class="tok-slide__item user-info__slide--item" :class="modifySelectLicns == licns ? 'active' : '' " v-for="licns in modifyCustLicnsList" v-bind:key="licns.id">
                                            <a href="#" onclick="return false" @click="forceClickLicns(licns)">{{licns.licnsNm}}</a></li>
                                    </ul>
                                </div>
                                <ul class="tok-slide__arrow">
                                    <li class="tok-slide__arrow--item pre user-info__slide--arrow--item edit-pop__slide--arrow--item" @click="previos">previos </li>
                                    <li class="tok-slide__arrow--item next user-info__slide--arrow--item edit-pop__slide--arrow--item" @click="next">next</li>
                                </ul>
                            </div>
                            <div class="txt--title txt--sel-license">
                                <div class="bdg-lic" v-show="modifySelectLicns.mainLicnsCd == null && modifySelectLicns.licnsNm != null && modifySelectLicns.licnsNm.indexOf('(대)') != -1">
                                    <!-- 주력 main / 대업종 big-->
                                    <i class="bdg-lic--ic big">대업종</i>
                                </div>
                                <div class="bdg-lic" v-show="modifySelectLicns.mainLicnsCd != null">
                                    <!-- 주력 main / 대업종 big-->
                                    <i class="bdg-lic--ic main" >주력</i>
                                </div>
                                <span>{{modifySelectLicns.licnsNm}}</span>
                            </div>
                            <div class="tooltip__group tooltip--3year">
                                <h4 class="h4">3년 실적</h4>
                            </div>
                            <div class="input edit-pop--input license--input">
                                <input id="forceY3AcrsltAmt" type="text" placeholder="3년 실적을 입력해 주세요." v-model="y3AcrsltAmtComputed">
                            </div>
                            <div class="tooltip__group tooltip--5year">
                                <h4 class="h4">5년 실적</h4>
                            </div>
                            <div class="input edit-pop--input license--input">
                                <input id="forceY5AcrsltAmt" type="text" placeholder="5년 실적을 입력해 주세요." v-model="y5AcrsltAmtComputed">
                            </div>
                            <div class="tooltip__group tooltip--perform">
                                <h4 class="h4">추가 실적</h4>
                            </div>
                            <div class="input edit-pop--input license--input">
                                <input id="forceLcettSuplmtAcrsltAmt" type="text" placeholder="추가 실적을 입력해 주세요." v-model="lcettSuplmtAcrsltAmtComputed">
                            </div>
                            <p class="comment-btn step-02--comment"><span>추가 실적: 협회에는 등록되지 않았지만 최종 준공이 완료된 공사 실적 합계액</span></p>
                            <div class="tooltip__group tooltip--capa">
                                <h4 class="h4">시공능력평가액</h4>
                            </div>
                            <div class="input edit-pop--input license--input">
                                <input id="forceCswkCapaEvltnAmt" type="text" placeholder="시공능력평가액을 입력해 주세요." v-model="cswkCapaEvltnAmtComputed">
                            </div>
                            <div class="tooltip__group tooltip--dura">
                                <h4 class="h4">영업기간</h4>
                            </div>
                            <v-date-picker v-model="bsnRegstDe" >
                                <template v-slot="{ inputValue, togglePopover }">
                                    <div class="input edit-pop--input manage--input calendar-btn expi-date"
                                         @click="togglePopover">
                                        <input
                                                class="input edit-pop--input manage--input"
                                                placeholder="영업기간을 선택해 주세요."
                                                :value="inputValue"
                                        />
                                    </div>
                                </template>
                            </v-date-picker>
                        </div>
                    </div>
                </div>
                <div class="popup__button--wrap pair">
                    <a href="#" onclick="return false" class="btn-basic btn-line-cb  popup__before" @click="isPopForceSetMyInfoStep3 = false; isPopForceSetMyInfoStep2 = true;">이전</a>
                    <a href="#" onclick="return false" class="btn-basic btn-cta popup__next" @click="checkLastLicns(); ">{{this.modifySelectLicns == this.modifyCustLicnsList[this.modifyCustLicnsList.length-1] ? '완료': '다음'}}</a>
                </div>
            </div>
            <!-- /경영 면허 정보 입력 - 면허 정보 -->

            <!-- 경영 면허 정보 입력 - 마지막 -->
            <div class="popup__item--container recom-announce__pop--container enter-pop__container enter-pop--cover step-last" :class="isPopForceSetMyInfoStep4 ? 'active' : ''">
                <div class="popup__item--group recom-announce__pop--group enter-pop__group step-last__group">
                    <div class="popup__item--head">
                        <p class="txt__title">경영/면허 정보 입력이<br>완료되었어요.</p>
                    </div>
                    <div class="popup__item--body recom-announce__pop--body enter-pop--body">
                        <div class="enter-pop--cover--img"></div>
                        <p class="txt--sub">입력한 경영/면허 정보는<br>맞춤공고 메뉴의 내 경영/면허 정보에서<br>수정 가능합니다.</p>
                    </div>
                </div>
                <div class="popup__button--wrap single">
                    <a href="#" onclick="return false" class="btn-basic btn-cta popup__close" @click="reloadNotiSuggestList(); isPopForceSetMyInfo = false;">맞춤공고 보기</a>
                </div>
            </div>
            <!-- /경영 면허 정보 입력 - 마지막 -->
            <!-- 경영 면허 정보 입력 진행률
                item 뒤에 active를 붙이면 진행됩니다-->
            <ul class="enter-pop--step">
                <li class="enter-pop--step--item item01"></li>
                <li class="enter-pop--step--item item-dash"></li>
                <li class="enter-pop--step--item item02"></li>
            </ul>
            <!-- /경영 면허 정보 입력 진행률  -->
        </div>
        <!-- /경영면허 입력 강제 팝업 -->
        </div>

        <!-- 분석서비스 이용 동의 팝업 -->
        <div class="popup__wrap recom-announce__pop--wrap consent-use__pop--wrap" :class="isPopCustCntrct ? 'active' : ''">
            <!-- 시작 -->
            <div class="popup__item--container recom-announce__pop--container consent-use__pop--container cover" :class="isPopCustCntrctStep1 ? 'active' : ''">
                <div class="popup__item--group recom-announce__pop--group consent-use__pop--group">
                    <div class="popup__item--head">
                        <p class="txt__title">분석서비스 이용 동의</p>
                        <a href="#" onclick="return false" class="popup__close" @click="isPopCustCntrct = false">닫기버튼</a>
                    </div>
                    <div class="popup__item--body recom-announce__pop--body consent-use__pop--body">
                        <p class="txt--sub">분석 서비스를 이용을 위해<br> 이용약관 및 계약서 동의가 필요합니다.</p>
                        <p class="txt--title">지금 바로 동의하고 분석 서비스를 이용해 보세요!</p>
                        <a href="#" onclick="return false" class="btn-basic btn-cta popup__next consent-use__pop--btn" @click=" isPopCustCntrctStep1 = false; isPopCustCntrctStep2 = true;">네.<br>동의하고 바로 이용할게요.</a>
                        <a href="#" onclick="return false" class="btn-basic btn-line-cb consent-use__pop--btn consent-use__pop--btn--close" @click="isPopCustCntrct = false">아니오.<br>그냥 둘러만 볼래요.</a>
                    </div>
                </div>
            </div>
            <!-- /시작 -->

            <!-- 내용 -->
            <div class="popup__item--container recom-announce__pop--container consent-use__pop--container step-01" :class="isPopCustCntrctStep2 ? 'active' : ''">
                <div class="popup__item--group recom-announce__pop--group consent-use__pop--group">
                    <div class="popup__item--head">
                        <p class="txt__title">분석서비스 이용 동의</p>
                        <a href="#" onclick="return false" class="popup__close" @click="isPopCustCntrct = false">닫기버튼</a>
                    </div>
                    <div class="popup__item--body recom-announce__pop--body consent-use__pop--body">
                        <p class="txt__title border-bottom">① 분석서비스 이용 동의</p>
                        <div class="join-form__box">
                            <p class="join-form__title consent-title">⑤ 약관동의</p>
                            <ul class="input join-form__wrap--link consent-use__pop--link">
                                <li class="join-form__wrap--move use-consent">
                                    <a href="#" onclick="return false" class="btn-basic btn-line-cw link__consent" @click="isPopUseAgree = true;">(필수)분석서비스 이용 약관 동의
                                        <span class="link__consent--check">
                                            <i class="check-none" title="체크 비활성화" :style="{ display: !anlsAgreeYn ? 'inline-block' : 'none' }"></i>
                                            <i class="check-active" title="체크 활성화" :style="{ display: anlsAgreeYn ? 'inline-block' : 'none' }"></i>
                                            동의
                                        </span>
                                    </a>
                                </li>
                                <li class="join-form__wrap--move info-consent">
                                    <a href="#" onclick="return false" class="btn-basic btn-line-cw link__consent" @click="isPopCntrct = true;">(필수)분석서비스 제공계약서
                                    <span class="link__consent--check">
                                    <i class="check-none" title="체크 비활성화" :style="{ display: !cntrctAgreeYn ? 'inline-block' : 'none' }"></i>
                                    <i class="check-active" title="체크 활성화" :style="{ display: cntrctAgreeYn ? 'inline-block' : 'none' }"></i>
                                    동의
                                     </span>
                                    </a>
                                </li>
                            </ul>

                        </div>
                        <p class="txt__title">② 세금계산서 수신 이메일(필수)</p>
                        <div class="input step-01--input">
                            <input type="text" placeholder="이메일을 입력해주세요." v-model="email">
                        </div>
<!--                        <p class="txt__sub">* 성공보수료의 세금계산서를 수신할 이메일을 입력해 주세요.</p>-->
<!--                        <div class="input&#45;&#45;check">-->
<!--                            <input type="checkbox" id="email" v-model="checkEmail">-->
<!--                            <label for="email"></label>-->
<!--                        </div>-->
<!--                        <label for="email">이메일로 분석서비스 제공계약서 받기</label>-->
                    </div>
                </div>
                <div class="popup__button--wrap single">
                    <a href="#" onclick="return false" class="btn-basic btn-cta popup__next"
                       :class="anlsAgreeYn && cntrctAgreeYn && email != ''? 'btn__active':'btn__non-active'"
                    @click="insertAnlsAgree()">
                        완료
                    </a>
                </div>
            </div>
            <!-- / 내용 -->

            <!-- 내용2 -->
            <div class="popup__item--container recom-announce__pop--container consent-use__pop--container step-01" :class="isPopCustCntrctStep2_1 ? 'active' : ''">
                <div class="popup__item--group recom-announce__pop--group consent-use__pop--group">
                    <div class="popup__item--head">
                        <p class="txt__title">AI멤버십 서비스 이용 동의</p>
                        <a href="#" onclick="return false" class="popup__close" @click="isPopCustCntrct = false">닫기버튼</a>
                    </div>
                    <div class="popup__item--body recom-announce__pop--body consent-use__pop--body">
                        <p class="txt__title border-bottom">① AI멤버십 서비스 이용 동의</p>
                        <div class="join-form__box">
                            <p class="join-form__title consent-title">⑤ 약관동의</p>
                            <ul class="input join-form__wrap--link consent-use__pop--link">
                                <li class="join-form__wrap--move use-consent">
                                    <a href="#" onclick="return false" class="btn-basic btn-line-cw link__consent" @click="clkSubscription()">(멤버십)AI구독서비스 제공계약서
                                        <span class="link__consent--check">
                                            <i class="check-none" title="체크 비활성화" :style="{ display: !subscriptionYn ? 'inline-block' : 'none' }"></i>
                                            <i class="check-active" title="체크 활성화" :style="{ display: subscriptionYn ? 'inline-block' : 'none' }"></i>
                                            동의
                                        </span>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="popup__button--wrap single">
                    <a href="#" onclick="return false" class="btn-basic btn-cta popup__next"
                       :class="subscriptionYn ? 'btn__active':'btn__non-active'"
                       @click="insertAISubAgree()">
                        완료
                    </a>
                </div>
            </div>
            <!-- / 내용2 -->

            <!-- 마지막 -->
            <div class="popup__item--container recom-announce__pop--container consent-use__pop--container step-last" :class="isPopCustCntrctStep3 ? 'active' : ''">
                <div class="popup__item--group recom-announce__pop--group consent-use__pop--group">
                    <div class="popup__item--head">
                        <p class="txt__title">분석서비스 이용 동의</p>
                        <a href="#" onclick="return false" class="popup__close" @click="isPopCustCntrct = false;$router.go(0);">닫기버튼</a>
                    </div>
                    <div class="popup__item--body recom-announce__pop--body consent-use__pop--body">
                        <p class="txt__title">분석서비스 이용 동의가<br>완료되었습니다.</p>
                        <div class="grid__wrap">
                            <div class="grid__container consent-use__pop--grid">
                                <ul class="grid__group head">
                                    <li class="grid__cell consent-use__pop--grid--head">동의날짜</li>
                                    <li class="grid__cell consent-use__pop--grid--body">{{agreeDt}}</li>

                                </ul>
                                <ul class="grid__group head">
                                    <li class="grid__cell consent-use__pop--grid--head">기간</li>
                                    <li class="grid__cell consent-use__pop--grid--body">{{agreeDate}}(1년)</li>
                                </ul>
                            </div>
                        </div>
<!--                        <p class="txt__sub" v-show="email != '' && checkEmail">분석서비스 제공계약서는 이메일로 발송되었습니다.</p>-->
                        <p class="txt__sub">멤버십 혜택 받아가세요!</p>
                        <a class="ai-mbr--promo" @click="$goMenu('membership')"></a>
                    </div>
                </div>
                <div class="popup__button--wrap single">
                    <a href="#" onclick="return false" class="btn-basic btn-cta popup__close" @click="isPopCustCntrct = false;$router.go(0);">확인</a>
                </div>
            </div>
            <!-- /마지막 -->

            <!-- 마지막2 -->
            <div class="popup__item--container recom-announce__pop--container consent-use__pop--container step-last" :class="isPopCustCntrctStep3_1 ? 'active' : ''">
                <div class="popup__item--group recom-announce__pop--group consent-use__pop--group">
                    <div class="popup__item--head">
                        <p class="txt__title">AI멤버십 서비스 이용 동의</p>
                        <a href="#" onclick="return false" class="popup__close" @click="isPopCustCntrct = false">닫기버튼</a>
                    </div>
                    <div class="popup__item--body recom-announce__pop--body consent-use__pop--body">
                        <p class="txt__title">AI멤버십 서비스 이용<br>이용 동의 및 신청이 완료되었습니다.</p>
                        <div class="grid__wrap">
                            <div class="grid__container consent-use__pop--grid">
                                <ul class="grid__group head">
                                    <li class="grid__cell consent-use__pop--grid--head">은행명 / 예금주명</li>
                                    <li class="grid__cell consent-use__pop--grid--body">KB국민은행 / 주식회사 비드톡톡</li>
                                </ul>
                                <ul class="grid__group head">
                                    <li class="grid__cell consent-use__pop--grid--head">계좌번호</li>
                                    <li class="grid__cell consent-use__pop--grid--body">740901-01-693861</li>
                                </ul>
                                <ul class="grid__group head">
                                    <li class="grid__cell consent-use__pop--grid--head">요금</li>
                                    <li class="grid__cell consent-use__pop--grid--body">월 149,000원</li>
                                </ul>
                            </div>
                        </div>
                        <p class="txt__sub">
                            AI멤버십은 입금 확인 후부터 사용하실 수 있습니다.<br>
                            기타 문의사항은 고객센터 1670-0508로 연락주세요.<br><br>
                            ※ 본 계약에 따라 서비스 이용 금액은 자동 결제되지 않으며, 고객은 매월 수동으로 결제해야 합니다.
                        </p>
                        <!--                        <p class="txt__sub" v-show="email != '' && checkEmail">분석서비스 제공계약서는 이메일로 발송되었습니다.</p>-->
                    </div>
                </div>
                <div class="popup__button--wrap single">
                    <a href="#" onclick="return false" class="btn-basic btn-cta popup__close" @click="isPopCustCntrct = false;getCustInfo3(function(){});">확인</a>
                </div>
            </div>
            <!-- /마지막2 -->
        </div>

        <!-- 분석요청 팝업 -->
        <div class="popup__wrap  recom-announce__pop--wrap set-pop__wrap request__pop--wrap" :class="isPopSelectAnlst ? 'active' : ''">
            <div class="popup__item--container recom-announce__pop--container">
                <div class="popup__item--group recom-announce__pop--group enter-pop__group request__pop--group">
                    <div class="popup__item--head request__pop--head">
                        <p class="txt__title">분석 요청</p>
                    </div>
                    <div class="popup__item--body recom-announce__pop--body">
                        <div class="set-pop__unit analist__unit request__pop--unit">
                            <h3 class="h3">가격 분석을 요청할 분석가를 선택해 주세요.</h3>
                            <div class="set-pop__box analist__box">
                                <label  v-for="anlst in notiAnlstList" v-bind:key="anlst.id" :for="'anls_'+anlst.id"
                                        class="analist--btn analist--btn--label" :class="(anlst.anlstType=='AI' ? 'ai' : '') + (selAnlst == anlst.id ? ' active':'')"
                                        @click.prevent="clickAnlst(anlst.id)"  >
                                    <div class="input--check analist--btn--check">
                                        <input type="checkbox" :id="'anls_'+anlst.id" name="analist" value="suri" :checked="(selAnlst == anlst.id ? 'checked':'')">
                                        <label :for="'anls_'+anlst.id" ></label>
                                    </div>
                                    <img :src="anlst.iconImg" alt="분석가 수리" class="analist--btn--img suri">
                                    <span class="analist--btn--txt">{{anlst.anlstNickNm}}</span>
                                </label>
                            </div>
                            <p class="txt--announce">
                                * 성공보수료<span class="deco"> -  </span><span class="line-break">AI분석가 : 낙찰가의 {{aiFee}}% (부가세 포함)</span><span class="deco"> / </span><span class="line-break">분석기업 : 낙찰가의 {{coFee}}% (부가세 포함)</span><br/>
                                * AI멤버십 이용 성공보수료<span class="deco"> -  </span><span class="line-break">AI분석가 : 무료</span><span class="deco"> / </span><span class="line-break">분석기업: 낙찰가의 2% (부가세포함)</span>
                            </p>
                            <div class="set-pop__box analist__box request__pop--warning">
                                <p class="txt--title">잠깐, 참가자격 확인하셨나요?</p>
                                <p class="txt--sub">공고의 참가자격을 숙지해 주시고<br>입찰에 신중히 참여해 주시기 바랍니다.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="popup__button--wrap pair">
                    <a href="#" onclick="return false" class="btn-basic btn-line-cb  popup__close" @click="isPopSelectAnlst = false;">취소</a>
                    <a href="#" onclick="return false" class="btn-basic btn-cta popup__close" :class="isProcessing ? 'loading-box' : ''" @click="insertCustNoti(selectNotiId)"><LoadingSpinner v-show="isProcessing"></LoadingSpinner>{{isProcessing ? 'ㅤ' : '분석 요청'}}</a>
                </div>
            </div>
        </div>
        <!-- /분석요청 팝업 -->


        <!-- 분석서비스 이용 약관 동의 팝업-->
        <div class="popup__wrap use-consent__wrap use" :class="isPopUseAgree ? 'active' : ''">
            <div class="popup__item--container use-consent__container">
                <div class="popup__item--group use-consent__wrap--group">
                    <div class="popup__item--head">
                        <h3 class="txt__title">분석서비스 이용 약관 동의</h3>
                        <button type="button" class="popup__close" @click="isPopUseAgree = false;"></button>
                    </div>
                    <div id="useAgreeScroll" class="popup__item--body use-consent__wrap--body">
                        <EtcUseAgree></EtcUseAgree>
                    </div>
                </div>
                <div class="input popup__button--wrap use-consent__wrap--button single">
                    <button id="anlsAgreeBtn" type="submit" class="btn-basic btn__non-active" @click="closeAnlsAgreePop(true)"><p class="txt__empha">동의</p>*약관을 끝까지 보신 후, 동의 가능합니다.</button>
                </div>
            </div>
        </div>
        <!-- /분석서비스 이용 약관 동의 -->

        <!-- 분석 서비스 제공 계약서 팝업-->
        <div class="popup__wrap use-consent__wrap Provis" :class="isPopCntrct ? 'active' : ''">
            <div class="popup__item--container use-consent__container">
                <div class="popup__item--group use-consent__wrap--group">
                    <div class="popup__item--head">
                        <h3 class="txt__title">분석 서비스 제공 계약서</h3>
                        <button type="button" class="popup__close" @click="isPopCntrct = false"></button>
                    </div>
                    <div id="etcAgreeScroll" class="popup__item--body use-consent__wrap--body">
                        <div class="clause wrap agree">
                            <div class="agree__table--wrap">
                                <ul class="agree__table--group">
                                    <li class="agree__table--item subject"><span class="agree__txt">계약자</span></li>
                                    <li class="agree__table--item content">
                                        <div class="agree__table--box">
                                            <div class="agree__table--element head couple"><span class="agree__txt agree__txt--head">정보 제공자
                                                (“동”) </span></div>
                                            <div class="agree__table--element body">
                                                <p class="agree__txt agree__table--sub"><span>회 사 명</span><span class="agree__table--title">
                                                    주식회사 비드톡톡</span></p>
                                                <p class="agree__txt agree__table--sub"><span>대 표 이 사</span><span class="agree__table--title">
                                                    최경호</span></p>
                                            </div>
                                        </div>
                                        <div class="agree__table--box">
                                            <div class="agree__table--element head couple"><span class="agree__txt agree__txt--head">정보 이용자
                                                (“행”) </span></div>
                                            <div class="agree__table--element body">
                                                <p class="agree__txt agree__table--sub"><span>회 사 명</span><span id="cmpNm"
                                                                                                                class="agree__table--title"><strong>{{custBiz.cmpNm}}</strong></span></p>
                                                <p class="agree__txt agree__table--sub"><span>대 표 이 사</span><span id="userNm"
                                                                                                                  class="agree__table--title"><strong>{{custBiz.rpsntvNm}}</strong></span></p>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                                <ul class="agree__table--group">
                                    <li class="agree__table--item subject"><span class="agree__txt">계약내용</span></li>
                                    <li class="agree__table--item content">
                                        <div class="agree__table--box couple">
                                            <div class="agree__table--element head"><span class="agree__txt agree__txt--head">계약명</span></div>
                                            <div class="agree__table--element body">
                                                <p class="agree__txt agree__table--sub"><span>전자입찰 분석정보 제공계약서 </span></p>
                                            </div>
                                        </div>
                                        <div class="agree__table--box couple">
                                            <div class="agree__table--element head"><span class="agree__txt agree__txt--head">계약 금액</span></div>
                                            <div class="agree__table--element body">
                                                <p class="agree__txt agree__table--sub">금<span
                                                        class="agree__table--title"><strong>0</strong></span>원 (VAT포함) </p>
                                            </div>
                                        </div>
                                        <div class="agree__table--box couple">
                                            <div class="agree__table--element head"><span class="agree__txt agree__txt--head">성공 보수료</span>
                                            </div>
                                            <div class="agree__table--element body">
                                                <p class="agree__txt agree__table--sub agree__table--title">※아래 <span>&lt;표1&gt;</span>에서 제시하는
                                                    바에 따름 (분석가 선택 시 수수료율 적용)</p>
                                            </div>
                                        </div>
                                        <div class="agree__table--box">
                                            <div class="agree__table--element head couple"><span class="agree__txt agree__txt--head">성공 보수료 지급
                                                조건</span></div>
                                            <div class="agree__table--element body">
                                                <p class="agree__txt agree__table--sub agree__table--title">※낙찰일로 부터 14일 이내 (별첨 계약내용 참조)</p>
                                            </div>
                                        </div>
                                        <div class="agree__table--box">
                                            <div class="agree__table--element head couple"><span class="agree__txt agree__txt--head">계약
                                                기간</span></div>
                                            <div class="agree__table--element body">
                                                <p class="agree__txt agree__table--sub agree__table--title">{{currentDate}} ~ {{nextYearDate}}</p>
                                            </div>
                                        </div>
                                        <div class="agree__table--box">
                                            <div class="agree__table--element head couple"><span class="agree__txt agree__txt--head">기타</span>
                                            </div>
                                            <div class="agree__table--element body">
                                                <p class="agree__txt agree__table--sub agree__table--title">※ 별첨 계약 내용에 정한 바에 따름 *계약 기간 경과 후 상호
                                                    이의가 없을 경우 자동 연장 되며 계약금은 발생하지 않습니다.</p>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                            <div class="clause__group">
                                <p class="clause__group--info agree__table--notice"><span> 주식회사 비드톡톡과 정보이용자(“행”)는 표지 및 별첨계약내용에 따라 본 “분석서비스 제공
                                    계약”을 체결하고 신의에 따라 성실하게 계약상 의무를 이행할 것을 확약하였으며 이 계약의 증거로서 내용 확인 후 동의함으로서 계약 성립은 유효하다.</span></p>
                            </div>
                            <AnlsFee></AnlsFee>
                            <div class="clause__group agree__company">
                                <h2 class="clause__group--tit align-c auto-input">{{currentDate}}</h2>
                                <p class="clause__group--info agree__company--announce"><span>※ 주식회사 비드톡톡 임직원들은 전자입찰 분석정보 제공계약서에 명시된 계약금 및 성공보수료
                                    이외에 어떠한 금품, 향응, 선물 그리고 편의를 수수하거나 제공을 요청하지 않으며 이를 위배한 임직원은 사규에 따라 징계합니다.</span></p>
                                <div class="comppany-info__group agree__company--info">
                                    <p class="clause__group--info align-c"><span>대전광역시 서구 계룡로553번길 18, 조이빌딩 9층</span></p>
                                    <p class="clause__group--info align-c"><span>주식회사 비드톡톡 </span></p>
                                    <p class="clause__group--info align-c"><span>TEL 1670-0508</span><span>FAX 042-367-3313</span></p>
                                </div>
                            </div>
                            <h2 class="menu__title clause__txt--title add-message">계 약 내 용</h2>
                            <div class="clause__group">
                                <h2 class="clause__group--tit">제 1조 (계약의 목적)</h2>
                                <p class="clause__group--info"><span>본 계약은 “동”이 “행”에게 전자 입찰 분석 정보를 제공하고 “행”은 “동”이 제공하는 전자 입찰 분석 정보를 이용하며 그 대가를
                                    “동”에게 지급하는 것과 관련하여 필요한 사항을 정함을 목적으로 한다.</span></p>
                            </div>
                            <div class="clause__group">
                                <h2 class="clause__group--tit">제2조 (“동”의 의무)</h2>
                                <p class="clause__group--info"><span>본 계약에 따라 “동”은 “동”이 운영하는 인터넷 웹페이지(www.bidtok.co.kr)와 어플리케이션(비드톡톡)을 통하여 국가나
                                    지방자치단체가 공고한 각종 입찰 관련 정보를 “행”에게 제공하기로 한다. 또한 “행”이 “동”에게 요청하는 입찰건에 대해서도 동일하게 정보를 제공하기로 한다. “동”이 “행”에게 제공하는
                                    정보의 종류와 범위는 “동”의 웹페이지와 어플리케이션을 통해 제공되고 있는 내용을 기본으로 하며 유선, 문자, SNS, 이메일, 팩스 등도 포함된다.</span></p>
                            </div>
                            <div class="clause__group">
                                <h2 class="clause__group--tit">제3조 (“행”의 의무) </h2>
                                <p class="clause__group--info"><span class="clause__group--info indent"><span>① “행”은 “동”으로부터 제2조의 정보를 제공 받는데에 대한
                                        대가로 제4조 ①항에 정한 액수에 따라 “동”에게 이용대금을 지급하여야 한다. 계약 금액이 지급된 후부터 분석실을 통한 가격 제시가 시작되는 것으로 한다.</span><span>②
                                        “행”은 본 계약 체결일로부터 분석요청한 입찰 공고에 대하여 분석실을 통하여 제시 가격을 확인하며 확인한 가격에 대하여 가격 이행을 하여야 한다. 단, 가격 불이행을 했을 경우
                                        횟수에 따라 “동”은 “행”에 대해 등급을 변동 할 수 있다. (단, 가격 불이행 가격이 낙찰가가 된 경우에는 횟수에 관계없이 즉시 등급을 변동할 수 있다) (비드톡톡 이용약관
                                        제10조 참조)</span></span></p>
                            </div>
                            <div class="clause__group">
                                <h2 class="clause__group--tit">제4조 (이용 대금) </h2>
                                <p class="clause__group--info"><span class="clause__group--info indent"><span>① “행”은 본 계약 체결과 동시에 “동”에게 본 계약서
                                        표지에 정한 “계약 금액”을 지급한다. “동”의 귀책사유로 “행”에 대한 정보 제공이 불가능해진 경우를 제외하고 “행”은 “동”에게 기본 계약 금액의 반환을 청구할 수
                                        없다.</span><span>② “행”이 “동”으로부터 제시 받은 가격으로 입찰에 참가하여 최종 낙찰자로 선정 된 경우(“행”이 동가의 1순위가 된 후 추첨을 통하여 최종 낙찰이
                                        확정된 경우, “행”보다 선 순위의 입찰 참가자가 있음에도 불구하고 “행”이 최종 낙찰이 된 경우 등을 포함) “행”은 “동”에게 본 계약서 표지에 정한 “성공 보수료”를
                                        지급한다.단, 단가 계약(공사, 납품, 구매 설치, 용역 수행 등)의 경우 총 공사 예정 금액 또는 총 배정 예산으로 성공 보수료를 지급한다.</span><span>③ 공동 도급을
                                        진행하여(구성 업체,분담 업체) 최종 낙찰자가될 경우 최종 공사 비율에 따라 본 계약서 표지에 정한 성공 보수료를 지급한다. </span><span>④ “행”은 “동”의 웹페이지
                                        및 어플리케이션 상의 “분석실”을 통해 “분석제시가 확인” 버튼을 클릭한 공고 건에 관여하는 “행”이 해당 공고건의 입찰에 참여한 경우 “동”으로부터 제시 받은 가격으로 입찰에
                                        참여한 것으로 보아 ②항을 적용하기로 한다. </span><span>⑤ 가격이행 여부의 표준 오차는 ±0.1% 범위로 인정하거나 또는 비드톡톡 가격 제시 기준 절상,절하 (ex
                                        만원단위, 십만단위, 백만단위 등) 일때 혹은 임의로 수정할 경우 또한 이행 여부 범위로 인정하여 성공 보수료를 지급한다. (분석서비스 이용약관 제13조 참조)
                                    </span><span>⑥ “행”은 본 계약서 표지에 정한 “성공 보수료”를 “동”의 성공 낙찰 일로부터 14 일 이내에 지급하여야 하며 지체하는 경우 “동”은 “행”에 대한 정보 제공을
                                        중단할 수 있다. 다만 성공 보수 금액이 1,000만원 이상일 경우 “행”은 성공 보수 금액 중 50%의 지급 유예를 신청할 수 있으며, “동”은 “행”의 지불 능력 기타 제반
                                        사정을 참작하여 지급 유예를 승인할 수 있다.(단,지급 유예 기간은 최장 60일 이내로 한다.)</span></span></p>
                            </div>
                            <div class="clause__group">
                                <h2 class="clause__group--tit">제5조(비밀 보장) </h2>
                                <p class="clause__group--info"><span class="clause__group--info indent"><span>① “행”은 본 계약에 따라 “동”으로부터 제시 받은 가격을
                                        제3자에게 공개하거나 양도 할 수 없으며 이를 위반함으로 인해 “동” 이 입은 손해를 배상해야 한다. 또한 “행”은 본 계약에 의하여 지득한 “동”의 자료 및 정보 등의 비밀
                                        (이미 일반에 알려진 정보는 제외)을 타인에게 누설 또는 공개 할 수 없으며 이를 위반함으로 인해 “동” 이 입은 손해를 배상해야 한다.</span></span></p>
                            </div>
                            <div class="clause__group">
                                <h2 class="clause__group--tit">제6조(분쟁의 해결) </h2>
                                <p class="clause__group--info"><span>본 계약과 관련하여 발생하는 분쟁은 수원지방법원 성남지원(택일 결정 필요)을 제1심 관할법원으로 하여 해결한다.</span></p>
                            </div>
                            <div class="agree__check--area">
                                <div class="agree__check--group pc">
                                    <div class="agree__check--box content clause__group--info"><span>“행”은 본 계약서 내용을 반드시 숙지하고 동의하며 원만히 계약이 성사되었음을
                                        인정합니다. 위 계약 내용에 대해 동의 하십니까?</span></div>
                                    <div class="agree__check--box company clause__group--info"><span>회사명:&nbsp;{{custBiz.cmpNm}}</span><span></span></div>
                                    <div class="agree__check--box agree clause__group--info"><span>동의</span><input type="checkbox"
                                                                                                                   id="check14" :checked="anlsAgreeFlag" @click="anlsAgreeFlag = !anlsAgreeFlag"><label for="check14"></label></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="input popup__button--wrap use-consent__wrap--button single">
                    <button id="cntrctAgreeBtn" type="submit" class="btn-basic btn__non-active"><p class="txt__empha" @click="closeCntrctAgreePop(true)">동의</p>*약관을 끝까지 보신 후, 동의 가능합니다.</button>
                </div>
            </div>
        </div>
        <!-- /분석 서비스 제공 계약서 -->

        <!-- 구독 서비스 제공 계약서 팝업-->
        <div class="popup__wrap use-consent__wrap Provis" :class="isPopSubscription ? 'active' : ''">
            <div class="popup__item--container use-consent__container">
                <div class="popup__item--group use-consent__wrap--group">
                    <div class="popup__item--head">
                        <h3 class="txt__title">구독 서비스 제공 계약서</h3>
                        <button type="button" class="popup__close" @click="isPopSubscription = false"></button>
                    </div>
                    <div id="subscriptionScroll" class="popup__item--body use-consent__wrap--body">
                        <div class="clause wrap agree">
                            <div class="agree__table--wrap">
                                <ul class="agree__table--group">
                                    <li class="agree__table--item subject"><span class="agree__txt">계약자</span></li>
                                    <li class="agree__table--item content">
                                        <div class="agree__table--box">
                                            <div class="agree__table--element head couple"><span class="agree__txt agree__txt--head">정보 제공자
                                                (“동”) </span></div>
                                            <div class="agree__table--element body">
                                                <p class="agree__txt agree__table--sub"><span>회 사 명</span><span class="agree__table--title">
                                                    주식회사 비드톡톡</span></p>
                                                <p class="agree__txt agree__table--sub"><span>대 표 이 사</span><span class="agree__table--title">
                                                    최경호</span></p>
                                            </div>
                                        </div>
                                        <div class="agree__table--box">
                                            <div class="agree__table--element head couple"><span class="agree__txt agree__txt--head">정보 이용자
                                                (“행”) </span></div>
                                            <div class="agree__table--element body">
                                                <p class="agree__txt agree__table--sub"><span>회 사 명</span><span id="cmpNm2"
                                                                                                                class="agree__table--title"><strong>{{custBiz.cmpNm}}</strong></span></p>
                                                <p class="agree__txt agree__table--sub"><span>대 표 이 사</span><span id="userNm2"
                                                                                                                  class="agree__table--title"><strong>{{custBiz.rpsntvNm}}</strong></span></p>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                                <ul class="agree__table--group">
                                    <li class="agree__table--item subject"><span class="agree__txt">계약내용</span></li>
                                    <li class="agree__table--item content">
                                        <div class="agree__table--box couple">
                                            <div class="agree__table--element head"><span class="agree__txt agree__txt--head">계약명</span></div>
                                            <div class="agree__table--element body">
                                                <p class="agree__txt agree__table--sub"><span>AI멤버십 서비스 이용 계약</span></p>
                                            </div>
                                        </div>
                                        <div class="agree__table--box couple">
                                            <div class="agree__table--element head"><span class="agree__txt agree__txt--head">계약 금액</span></div>
                                            <div class="agree__table--element body">
                                                <p class="agree__txt agree__table--sub">금<span
                                                        class="agree__table--title"><strong>149,000</strong></span>원 (VAT포함) </p>
                                            </div>
                                        </div>
                                        <div class="agree__table--box couple">
                                            <div class="agree__table--element head"><span class="agree__txt agree__txt--head">성공 보수료</span>
                                            </div>
                                            <div class="agree__table--element body">
                                                <p class="agree__txt agree__table--sub agree__table--title">※ 아래 <span>&lt;표1&gt;</span>에서 제시하는
                                                    바에 따름</p>
                                            </div>
                                        </div>
                                        <div class="agree__table--box">
                                            <div class="agree__table--element head couple"><span class="agree__txt agree__txt--head">성공 보수료 지급
                                                조건</span></div>
                                            <div class="agree__table--element body">
                                                <p class="agree__txt agree__table--sub agree__table--title">※ 아래 <span>&lt;표1&gt;</span>에서 제시하는
                                                    바에 따름</p>
                                            </div>
                                        </div>
                                        <div class="agree__table--box">
                                            <div class="agree__table--element head couple"><span class="agree__txt agree__txt--head">계약
                                                기간</span></div>
                                            <div class="agree__table--element body">
                                                <p class="agree__txt agree__table--sub agree__table--title">이용 대금이 납부된 날로부터 시작하며, 1개월간 이용 가능함<br/>
                                                    (*1개월이라고 함은 30일로 계산)</p>
                                            </div>
                                        </div>
                                        <div class="agree__table--box">
                                            <div class="agree__table--element head couple"><span class="agree__txt agree__txt--head">기타</span>
                                            </div>
                                            <div class="agree__table--element body">
                                                <p class="agree__txt agree__table--sub agree__table--title">※ 별첨 계약 내용에 정한 바에 따름 *계약 기간 경과 후 상호
                                                    이의가 없을 경우 자동 연장 되며 계약금은 발생하지 않습니다.</p>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                            <div class="clause__group">
                                <p class="clause__group--info agree__table--notice"><span> 주식회사 비드톡톡과 정보이용자(“행”)는 표지 및 별첨계약내용에 따라 본 “분석서비스 제공
                                    계약”을 체결하고 신의에 따라 성실하게 계약상 의무를 이행할 것을 확약하였으며 이 계약의 증거로서 내용 확인 후 동의함으로서 계약 성립은 유효하다.</span></p>
                            </div>
                            <div class="clause__group clause__charge agree__cost">
                                <h2 class="clause__group--tit clause__charge--subject">※ 분석가에 따른 성공보수료</h2>
                                <div class="agree__table--wrap">
                                    <p class="clause__spanst--item clause__standard--subject">
                                        <span class="clause__list--title clause__standard--title">AI멤버십 동의 후 <i class="unline">이용 대금을 납부</i>한 경우 아래와 같은 성공 보수료율 적용</span>
                                    </p>
                                    <ul class="agree__table--group">
                                        <li class="agree__table--item content">
                                            <div class="agree__table--box">
                                                <div class="agree__table--element head">
                                                    <span class="agree__txt agree__txt--head">AI분석 펭, 도그, 수리</span>
                                                </div>
                                                <div class="agree__table--element body">
                                                    <span class="agree__txt agree__txt--head">없음</span>
                                                </div>
                                            </div>
                                            <div class="agree__table--box">
                                                <div class="agree__table--element head">
                                                    <span class="agree__txt agree__txt--head">분석기업<br>진비드, 인비드, 청비드, 솔비드, 비드플러스, 인비드</span>
                                                </div>
                                                <div class="agree__table--element body vm">
                                                    <p class="agree__txt agree__table--sub agree__table--title">낙찰가의 2% (부가세 포함)</p>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                                <p class="clause__group--info"><span>&lt;표1&gt;</span></p>
                                <div class="clause__group">
                                    <p class="clause__group--info agree__table--notice">
                                        <span> ※ AI분석으로 낙찰할 경우 성공보수료 없으며, 분석기업으로 낙찰할 경우 낙찰가의 2% 성공보수료율이 적용됩니다</span>
                                    </p>
                                </div>
                                <div class="agree__table--wrap">
                                    <p class="clause__spanst--item clause__standard--subject">
                                        <span class="clause__list--title clause__standard--title">AI멤버십 동의 후 <i class="unline">이용 대금을 납부하지 않은 경우</i>에는 아래와 같은 성공 보수료율 적용</span>
                                    </p>
                                    <ul class="agree__table--group">
                                        <li class="agree__table--item content">
                                            <div class="agree__table--box">
                                                <div class="agree__table--element head">
                                                    <span class="agree__txt agree__txt--head">AI분석 펭, 도그, 수리</span>
                                                </div>
                                                <div class="agree__table--element body">
                                                    <span class="agree__txt agree__txt--head">낙찰가의 2.5% (부가세 포함)</span>
                                                </div>
                                            </div>
                                            <div class="agree__table--box">
                                                <div class="agree__table--element head">
                                                    <span class="agree__txt agree__txt--head">분석기업<br>진비드, 인비드, 청비드, 솔비드, 비드플러스, 인비드</span>
                                                </div>
                                                <div class="agree__table--element body vm">
                                                    <p class="agree__txt agree__table--sub">
                                                        <span class="agree__table--title">낙찰가의 2% (부가세 포함)</span>
                                                        * 분석기업 성공 보수료 지급은 낙찰일로부터 14일 이내 (별첨 계약내용 참조)
                                                    </p>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                                <p class="clause__group--info"><span>&lt;표2&gt;</span></p>
                            </div>
                            <div class="clause__group agree__company">
                                <h2 class="clause__group--tit align-c auto-input">{{currentDate}}</h2>
                                <p class="clause__group--info agree__company--announce"><span>※ 주식회사 비드톡톡 임직원들은 전자입찰 분석정보 제공계약서에 명시된 계약금 및 성공보수료
                                    이외에 어떠한 금품, 향응, 선물 그리고 편의를 수수하거나 제공을 요청하지 않으며 이를 위배한 임직원은 사규에 따라 징계합니다.</span></p>
                                <div class="comppany-info__group agree__company--info">
                                    <p class="clause__group--info align-c"><span>대전광역시 서구 계룡로553번길 18, 조이빌딩 9층</span></p>
                                    <p class="clause__group--info align-c"><span>주식회사 비드톡톡 </span></p>
                                    <p class="clause__group--info align-c"><span>TEL 1670-0508</span><span>FAX 042-367-3313</span></p>
                                </div>
                            </div>
                            <h2 class="menu__title clause__txt--title add-message">계 약 내 용</h2>
                            <div class="clause__group">
                                <h2 class="clause__group--tit">제 1조 (계약의 목적)</h2>
                                <p class="clause__group--info"><span>본 계약은 “동”이 “행”에게 전자 입찰 분석 정보를 제공하고 “행”은 “동”이 제공하는 전자 입찰 분석 정보를 이용하며 그 대가를
                                    “동”에게 지급하는 것과 관련하여 필요한 사항을 정함을 목적으로 한다.</span></p>
                            </div>
                            <div class="clause__group">
                                <h2 class="clause__group--tit">제2조 (“동”의 의무)</h2>
                                <p class="clause__group--info"><span>본 계약에 따라 “동”은 “동”이 운영하는 인터넷 웹페이지(www.bidtok.co.kr)와 어플리케이션(비드톡톡)을 통하여 국가나
                                    지방자치단체가 공고한 각종 입찰 관련 정보를 “행”에게 제공하기로 한다. 또한 “행”이 “동”에게 요청하는 입찰건에 대해서도 동일하게 정보를 제공하기로 한다. “동”이 “행”에게 제공하는
                                    정보의 종류와 범위는 “동”의 웹페이지와 어플리케이션을 통해 제공되고 있는 내용을 기본으로 하며 유선, 문자, SNS, 이메일, 팩스 등도 포함된다.</span></p>
                            </div>
                            <div class="clause__group">
                                <h2 class="clause__group--tit">제3조 (“행”의 의무) </h2>
                                <p class="clause__group--info"><span class="clause__group--info indent"><span>① “행”은 “동”으로부터 제2조의 정보를 제공 받는데에 대한
                                        대가로 제4조 ①항에 정한 액수에 따라 “동”에게 이용대금을 지급하여야 한다. 계약 금액이 지급된 후부터 분석실을 통한 가격 제시가 시작되는 것으로 한다.</span><span>②
                                        “행”은 본 계약 체결일로부터 분석기업(진비드, 인비드, 비드인, 청비드, 솔비드, 비드플러스를 칭하며 이하 “분석기업”으로 정한다)으로 분석요청한 입찰 공고에 대하여 분석실을 통하여 제시 가격을 확인하며 확인한 가격에 대하여 가격 이행을 하여야 한다. 단, 가격 불이행을 했을 경우
                                        횟수에 따라 “동”은 “행”에 대해 등급을 변동 할 수 있다. (단, 가격 불이행 가격이 낙찰가가 된 경우에는 횟수에 관계없이 즉시 등급을 변동할 수 있다) (비드톡톡 이용약관
                                        제10조 참조)</span></span></p>
                            </div>
                            <div class="clause__group">
                                <h2 class="clause__group--tit">제4조 (이용 대금) </h2>
                                <p class="clause__group--info"><span class="clause__group--info indent"><span>① “행”은 본 계약 체결과 동시에 “동”에게 본 계약서
                                        표지에 정한 “계약 금액”을 지급한다. “동”의 귀책사유로 “행”에 대한 정보 제공이 불가능해진 경우를 제외하고 “행”은 “동”에게 기본 계약 금액의 반환을 청구할 수
                                        없다.</span><span>② “행”이 “동”으로부터 분석기업에게 분석요청하여 제시 받은 가격으로 입찰에 참가하여 최종 낙찰자로 선정 된 경우(“행”이 동가의 1순위가 된 후 추첨을 통하여 최종 낙찰이
                                        확정된 경우, “행”보다 선 순위의 입찰 참가자가 있음에도 불구하고 “행”이 최종 낙찰이 된 경우 등을 포함) “행”은 “동”에게 본 계약서 표지에 정한 “성공 보수료”를
                                        지급한다.단, 단가 계약(공사, 납품, 구매 설치, 용역 수행 등)의 경우 총 공사 예정 금액 또는 총 배정 예산으로 성공 보수료를 지급한다.</span><span>③ 공동 도급을
                                        진행하여(구성 업체,분담 업체) 최종 낙찰자가될 경우 최종 공사 비율에 따라 본 계약서 표지에 정한 성공 보수료를 지급한다. </span><span>④ “행”은 “동”의 웹페이지
                                        및 어플리케이션 상의 “분석실”을 통해 “분석제시가 확인” 버튼을 클릭한 공고 건에 관여하는 “행”이 해당 공고건의 입찰에 참여한 경우 “동”으로부터 제시 받은 가격으로 입찰에
                                        참여한 것으로 보아 ②항을 적용하기로 한다. </span><span>⑤ 분석기업으로 분석요청한 공고의 가격이행 여부의 표준 오차는 ±0.1% 범위로 인정하거나 또는 비드톡톡 가격 제시 기준 절상,절하 (ex
                                        만원단위, 십만단위, 백만단위 등) 일때 혹은 임의로 수정할 경우 또한 이행 여부 범위로 인정하여 성공 보수료를 지급한다. (분석서비스 이용약관 제13조 참조)
                                    </span><span>⑥ “행”은 본 계약서 표지에 정한 “성공 보수료”를 “동”의 성공 낙찰 일로부터 14 일 이내에 지급하여야 하며 지체하는 경우 “동”은 “행”에 대한 정보 제공을
                                        중단할 수 있다. 다만 성공 보수 금액이 1,000만원 이상일 경우 “행”은 성공 보수 금액 중 50%의 지급 유예를 신청할 수 있으며, “동”은 “행”의 지불 능력 기타 제반
                                        사정을 참작하여 지급 유예를 승인할 수 있다.(단,지급 유예 기간은 최장 60일 이내로 한다.)</span></span></p>
                            </div>
                            <div class="clause__group">
                                <h2 class="clause__group--tit">제5조 (환불 규정) </h2>
                                <p class="clause__group--info"><span class="clause__group--info indent"><span>① “행”이 계약 체결 및 입금이 확인된 이후 1회 이상 분석제시가를 확인한 후에는 지불한 “계약 금액” 환불이 불가.</span>
                                    <span>단, 계약기간 내 분석제시가를 한 번도 확인하지 않았다면 100% 환불이 가능함.</span></span></p>
                            </div>
                            <div class="clause__group">
                                <h2 class="clause__group--tit">제6조 (비밀 보장) </h2>
                                <p class="clause__group--info"><span class="clause__group--info indent"><span>① “행”은 본 계약에 따라 “동”으로부터 제시 받은 가격을
                                        제3자에게 공개하거나 양도 할 수 없으며 이를 위반함으로 인해 “동” 이 입은 손해를 배상해야 한다. 또한 “행”은 본 계약에 의하여 지득한 “동”의 자료 및 정보 등의 비밀
                                        (이미 일반에 알려진 정보는 제외)을 타인에게 누설 또는 공개 할 수 없으며 이를 위반함으로 인해 “동” 이 입은 손해를 배상해야 한다.</span></span></p>
                            </div>
                            <div class="clause__group">
                                <h2 class="clause__group--tit">제7조 (분쟁의 해결) </h2>
                                <p class="clause__group--info"><span>본 계약과 관련하여 발생하는 분쟁은 수원지방법원 성남지원(택일 결정 필요)을 제1심 관할법원으로 하여 해결한다.</span></p>
                            </div>
                            <div class="agree__check--area">
                                <div class="agree__check--group pc">
                                    <div class="agree__check--box content clause__group--info"><span>“행”은 본 계약서 내용을 반드시 숙지하고 동의하며 원만히 계약이 성사되었음을
                                        인정합니다. 위 계약 내용에 대해 동의 하십니까?</span></div>
                                    <div class="agree__check--box company clause__group--info"><span>회사명:&nbsp;{{custBiz.cmpNm}}</span><span></span></div>
                                    <div class="agree__check--box agree clause__group--info"><span>동의</span><input type="checkbox"
                                                                                                                   id="check2" :checked="subscriptionFlag" @click="subscriptionFlag = !subscriptionFlag"><label for="check2"></label></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="input popup__button--wrap use-consent__wrap--button single">
                    <button id="subscriptionBtn" type="submit" class="btn-basic btn__non-active"><p class="txt__empha" @click="closeSubscriptionPop(true)">동의</p>*약관을 끝까지 보신 후, 동의 가능합니다.</button>
                </div>
            </div>
        </div>
        <!-- /구독 서비스 제공 계약서 -->

        <!--  자동분석 끄기 팝업 active 추가 -->
        <div class="popup__wrap off-auto-anal" :class="isPopOffAutoAnal? 'active' : ''">
            <div class="popup__item--container">
                <div class="popup__item--group">
                    <div class="popup__item--head">
                        <!-- 체크 이미지 있을 경우 -->
<!--                        <div class="popup__img"></div>-->
                        <!-- 타이틀이 있을경우 -->
                         <h3 class="txt__title">자동 분석 끄기</h3>
                    </div>
                    <div class="popup__item--body">
                        <p class="txt__sub">자동 분석을 끄시겠습니까?</p>
                    </div>
                </div>
                <!-- 버튼이 하나일 때 popup__button--wrap 클래스 뒤에 single 추가,
                     버튼이 두개일 때 popup__button--wrap 클래스 뒤에 pair 추가-->
                <!--                <div class="popup__button&#45;&#45;wrap single">-->
                <!--                    <a href="#" onclick="return false" class="btn-basic btn-cta"><span>분석실로 이동</span></a>-->
                <!--                </div>-->
                <div class="popup__button--wrap pair">
                    <a href="#" onclick="return false" class="btn-basic btn-line-cb" @click="isPopOffAutoAnal = false;">취소</a>
                    <a href="#" onclick="return false" class="btn-basic btn-cta" @click="offAutoAnal(true)">끄기</a>
                </div>
            </div>
            <!--     // popup-->
        </div>
        <!--  /자동분석 끄기 팝업 active 추가 -->

        <!--  분석실 이동 성공 팝업 active 추가 -->
        <div class="popup__wrap off-auto-anal" :class="isPopSuccessCustNoti? 'active' : ''">
            <div class="popup__item--container">
                <div class="popup__item--group">
                    <div class="popup__item--head">
                        <!-- 체크 이미지 있을 경우 -->
                        <!--                        <div class="popup__img"></div>-->
                        <!-- 타이틀이 있을경우 -->
                        <h3 class="txt__title">가격 분석 요청 완료</h3>
                    </div>
                    <div class="popup__item--body">
                        <p class="txt__sub">가격 분석 요청이 완료되었습니다.</p>
                    </div>
                </div>
                <!-- 버튼이 하나일 때 popup__button--wrap 클래스 뒤에 single 추가,
                     버튼이 두개일 때 popup__button--wrap 클래스 뒤에 pair 추가-->
                <!--                <div class="popup__button&#45;&#45;wrap single">-->
                <!--                    <a href="#" onclick="return false" class="btn-basic btn-cta"><span>분석실로 이동</span></a>-->
                <!--                </div>-->
                <div class="popup__button--wrap pair">
                    <a href="#" onclick="return false" class="btn-basic btn-line-cb" @click="isPopSuccessCustNoti = false;">닫기</a>
                    <a href="#" onclick="return false" class="btn-basic btn-cta" @click="$scrollActive(); $goMenu('analysis')">분석실 이동</a>
                </div>
            </div>
            <!--     // popup-->
        </div>
        <!--  /분석실 이동 성공 팝업  active 추가 -->

        <!-- 분석 요청 제한 안내 팝업 -->
        <div class="popup__wrap analy-rstrt" :class="isPopNonFulfliment ? 'active' : ''">
            <div class="popup__item--container analy-rstrt--container">
                <div class="popup__item--group">
                    <div class="popup__item--head analy-rstrt--head">
                        <h3 class="txt__title">분석 요청 제한 안내</h3>
                    </div>
                    <div class="popup__item--body analy-rstrt--body">
                        <p class="txt__title">비드톡톡 분석 가격 이행률이 낮아</p>
                        <p class="txt__title strong">분석 요청 서비스 이용이<br>
                            <span class="limit-count">{{custFulfilmentMngt != null ? custFulfilmentMngt.lockCnt : ''}}회</span> 제한되었습니다.
                        </p>
                        <p class="txt__sub mtb24">
                            <span class="date"> {{custFulfilmentMngt != null  ? $moment(custFulfilmentMngt.lockEndDt).add(1,'days').format('MM.DD (ddd)'): ''}}</span>부터 분석 요청 서비스를<br>이용하실 수 있습니다.
                        </p>
                        <p class="txt__sub rstrt-durin">분석 요청 제한 기간</p>
                        <p class="txt__sub date">
                            {{custFulfilmentMngt != null  ? $moment(custFulfilmentMngt.lockStartDt).format('YYYY.MM.DD (ddd)') : ''}} ~
                            {{custFulfilmentMngt != null  ? $moment(custFulfilmentMngt.lockEndDt).format('YYYY.MM.DD (ddd)'): ''}}</p>
                    </div>
                </div>
                <div class="popup__button--wrap single">
                    <a href="#" onclick="return false" class="btn-basic btn-cta popup__close" @click="updateLockConfirm">확인</a>
                </div>
            </div>
        </div>
        <!-- /분석 요청 제한 안내 팝업 -->

        <!-- 최대참여 공고 금액 팝업 -->
        <div class="popup__wrap max-amount__wrap" :class="isPopPrtcAmt ? 'active' : ''">
            <div class="popup__item--container">
                <div class="popup__item--group max-amount__group">
                    <div class="popup__item--head max-amount--head">
                        <h3 class="txt__title">최대참여 공고 금액</h3>
                    </div>
                    <div class="popup__item--body max-amount--body">
                        <ul class="tab02 max-amount__tab">
                            <li class="tab02--list max-amount__tab--list" :class="isPopPrtcAmtTab1 ? 'active' : ''">
                                <a href="#" onclick="return false" @click="isPopPrtcAmtTab1 = true">현재기준</a>
                            </li>
                            <li class="tab02--list max-amount__tab--list" :class="!isPopPrtcAmtTab1 ? 'active' : ''" v-show="isBefLmtAmtYn">
                                <a href="#" onclick="return false" @click="isPopPrtcAmtTab1 = false">{{avlblPerdExpyDe}} 이전 기준</a>
                            </li>
                        </ul>
                        <div class="max-amount__unit">
                            <!-- 현재기준 -->
                            <div class="grid__wrap max-amount__grid" v-show="isPopPrtcAmtTab1" :class="isNotCandidate ?  'not-candidate' : ''">
                                <div class="not-candidate--cont"><p>수의 계약 공고 참여 가능<br>(적격심사 비대상)</p></div>
                                <div class="grid__container" v-show="selectLicns.custPrtcAmtList != null">
                                    <ul class="grid__group head max-amount__grid--head">
                                        <li class="grid__cell max-amount__grid--cell">발주처</li>
                                        <li class="grid__cell max-amount__grid--cell">기초금액</li>
                                    </ul>
                                    <ul class="grid__group body max-amount__grid--body" v-for="custPrtcAmt in selectLicns.custPrtcAmtList" v-bind:key="custPrtcAmt.id">
                                        <li class="grid__cell max-amount__grid--cell">{{custPrtcAmt.cfmtUndwrtStIdNm}}</li>
                                        <li class="grid__cell max-amount__grid--cell">{{custPrtcAmt.lmtAmt != null ? ('~' + custPrtcAmt.lmtAmt.replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,") + '원') : ('-') }}</li>
                                    </ul>
                                </div>
                            </div>
                            <!-- /현재기준 -->

                            <!-- 특정 시점 기준 -->
                            <div class="grid__wrap max-amount__grid" v-show="!isPopPrtcAmtTab1 && isBefLmtAmtYn" :class="isBefNotCandidate ?  'not-candidate' : ''">
                                <div class="not-candidate--cont"><p>수의 계약 공고 참여 가능<br>(적격심사 비대상)</p></div>
                                <div class="grid__container" v-show="selectLicns.custPrtcAmtList">
                                    <ul class="grid__group head max-amount__grid--head">
                                        <li class="grid__cell max-amount__grid--cell">발주처</li>
                                        <li class="grid__cell max-amount__grid--cell">기초금액</li>
                                    </ul>
                                    <ul class="grid__group body max-amount__grid--body" v-for="custPrtcAmt in selectLicns.custPrtcAmtList" v-bind:key="custPrtcAmt.id">
                                        <li class="grid__cell max-amount__grid--cell">{{custPrtcAmt.cfmtUndwrtStIdNm}}</li>
                                        <li class="grid__cell max-amount__grid--cell">{{custPrtcAmt.befLmtAmt != null ? ('~' + custPrtcAmt.befLmtAmt.replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,") + '원') : ('-') }}</li>
                                    </ul>
                                </div>
                            </div>
                            <!-- /특정 시점 기준 -->
                        </div>
                    </div>
                </div>
                <div class="popup__button--wrap single">
                    <a href="#" onclick="return false" class="btn-basic btn-cta popup__close" @click="isPopPrtcAmt = false">확인</a>
                </div>
            </div>
        </div>
        <!-- /최대참여 공고 금액 팝업 -->
        <!-- 공고 숨김처리 팝업 -->
        <div class="popup__wrap recom-anno-show-hide__wrap hide" :class="isPopNotiHideConf ? 'active' : ''">
            <div class="popup__item--container">
                <div class="popup__item--group">
                    <div class="popup__item--head">
                        <div class="popup__img exclam"></div>
                    </div>
                    <div class="popup__item--body">
                        <p class="txt__sub">해당 공고를 <span class="empha">맞춤공고에서</span>
                            <span class="line-break">
                            <span class="empha">{{!isHideYn ? '보이지 않도록' : '보이도록'}}</span>
                            하시겠습니까?
                        </span>
                        </p>
                    </div>
                </div>
                <div class="popup__button--wrap pair">
                    <a href="#" onclick="return false" class="btn-basic btn-line-cw popup__close" @click="isPopNotiHideConf = false">닫기</a>
                    <a href="#" onclick="return false" class="btn-basic btn-cta popup__close" @click="isPopNotiHideConf = false; updateCustNotiHideYn()">{{!isHideYn ? '숨기기' : '보이기'}}</a>
                </div>
            </div>
        </div>
        <!-- /공고 숨김처리 팝업 -->
        <!-- 면허 정보 없을 때 >  수정버튼 누를 시 팝업 -->
        <div class="popup__wrap licns-empty" :class="isPopNotExistCustLicns ? 'active' : ''">
            <div class="popup__item--container">
                <div class="popup__item--group">
                    <div class="popup__item--body no-res licns-empty--body">
                        <div class="no-res--img"></div>
                        <p class="title">죄송합니다.</p>
                        <p class="sub">고객님의 면허 정보가 파악되지 않았습니다.<br>정확한 정보 확인을 위해<br>고객센터 1670-0508로<br>연락 부탁드립니다.</p>
                    </div>
                </div>
                <div class="popup__button--wrap single">
                    <a href="#" onclick="return false" class="btn-basic btn-cta popup__close" @click="isPopNotExistCustLicns = false">닫기</a>
                </div>
            </div>
        </div>
        <!-- /면허 정보 없을 때 >  수정버튼 누를 시 팝업 -->
    </div>
</template>

<script>
    // import $ from 'jquery';
    import 'slick-carousel/slick/slick.css';
    import 'slick-carousel/slick/slick.min.js';
    import * as _ from 'lodash';
    import LoadingSpinner from '@/components/common/LoadingSpinner.vue';
    import EtcUseAgree from '@/pages/Comn/EtcUseAgree'
    import AnlsFee from '@/pages/Comn/AnlsFee'
    import BefLicnsLayerPop from '@/pages/V2/Popup/BefLicnsLayerPop'
    import AutoAnlsPop from '@/pages/V2/Popup/AutoAnlsPop'

    export default {
        name: "NotiSuggest",
        components: {
            LoadingSpinner,
            EtcUseAgree,
            AnlsFee,
            AutoAnlsPop,
            BefLicnsLayerPop
        },
        metaInfo: {
            title: '[비드톡톡 맞춤공고] - 전자입찰 나라장터(g2b) 공고 투찰 낙찰',
            link: [{ rel: "canonical", href: 'https://www.bidtok.co.kr/' }],
            meta: [
                { vmid: "description", name: 'description', content: '비드톡톡에 관한 공지사항을 확인해 보세요! 비드톡톡의 업데이트 소식, 이벤트 소식, 새로운 기능의 소개, 나라장터 소식 등 비드톡톡의 중요한 공지사항을 확인하실 수 있습니다.' },
                { vmid: "og:title", property: 'og:title', content: '[비드톡톡 공지사항] - 전자입찰 나라장터(g2b) 공고 투찰 낙찰' },
                { vmid: "og:url", property: 'og:url', content: 'https://www.bidtok.co.kr/' },
                { vmid: "og:description", property: 'og:description', content: '비드톡톡에 관한 공지사항을 확인해 보세요! 비드톡톡의 업데이트 소식, 이벤트 소식, 새로운 기능의 소개, 나라장터 소식 등 비드톡톡의 중요한 공지사항을 확인하실 수 있습니다.' },
                { vmid: "og:image", property: "og:image" , content : "favicon.ico"},
            ]
        },
        data(){
            return{
                loginInfo: {},
                deviceType: 'Desktop',

                custInfo : {},      //고객 정보
                custLicnsList : [],     //고객 면허 목록
                modifyCustLicnsList : [],     //수정 고객 면허 목록
                autoAnlsConfigList : [],    //자동분석설정목록
                holidayMngmtList: [],   //공휴일 목록
                custBiz: [],    //사업자 목록
                cdltStList: [], //신용평가 목록
                cdltStOrgList: [], //발급기관 목록
                custCntrctList: [], //계약서 정보
                notiAnlstList: [],  //분석가목록


                notiSuggestList: [],    //추천공고 목록
                notiSugguestListCnt : 0,        //추천공고 수
                suggestNotiGroup: {},
                page: 0,    //현재 페이지
                pageUnit: 30,   //페이지당 숫자
                anlsPeriod: 7, //분석기간
                startDt: this.$moment().format('YYYY-MM-DD'),    //시작일
                endDt: this.$moment().format('YYYY-MM-DD'),    //시작일
                bddprStartDt: this.$moment().format('YYYY-MM-DD'),    //시작일
                bddprEndDt: this.$moment().format('YYYY-MM-DD'),  // 종료일
                searchString: '',   // 검색어
                facilityYn: true,  //용역,물품 제외 여부
                wnctyYn: true, //전국 제외 여부

                //css 변경구문
                accordionActive: true, // 아코디언 열기
                isPopSuggestNotiDesc: false,    //맞춤공고 설명 팝업
                isPopSetMyInfo: false,    //경영/면허 정보 수정 팝업
                isPopSetAutoAnls: false,    //자동 분석 설정 변경
                isPopForceSetMyInfo: false,    //경영면허 입력 강제 팝업
                isPopForceSetMyInfoStep1: false,    //경영면허 입력 강제 팝업
                isPopForceSetMyInfoStep2: false,    //경영면허 입력 강제 팝업
                isPopForceSetMyInfoStep3: false,    //경영면허 입력 강제 팝업
                isPopForceSetMyInfoStep4: false,    //경영면허 입력 강제 팝업

                isPopCalendar: false,   //달력 팝업
                isPopCustCntrct: false, //약관 동의 팝업
                isPopCustCntrctStep1: false,
                isPopCustCntrctStep2: false,
                isPopCustCntrctStep2_1: false, //AI멤버십 동의 팝업
                isPopCustCntrctStep3: false,
                isPopCustCntrctStep3_1: false, //AI멤버십 동의 완료 팝업
                isPopUseAgree: false,   //분석서비스 약관동의팝업
                isPopCntrct: false,     //분석서비스 제공 계약서
                isPopSubscription: false,   //구독서비스 제공 계약서
                isPopSelectAnlst: false,    //분석가 선택 팝업
                isPopOffAutoAnal: false,    //자동분석 끄기 컨펌
                isPopSuccessCustNoti: false,    //분석실 이동 성공
                isPopNonFulfliment: false, //불이행 팝업
                isPopPrtcAmt: false,    //최대 참여 금액 표 팝업
                isPopPrtcAmtTab1: true,    //최대 참여 금액 표 팝업 탭1 활성화여부
                isPopNotiHideConf: false,    //공고숨기가 확인 팝업 여부
                isPopNotExistCustLicns: false, //면허가 없을 때 수정 버튼 누를때 팝업 여부
                isNoValueCustLicns: false, // 면허의 값이 없을 때 활성화여부

                isBefLmtAmtYn: false, //이전 정보 여부
                isBefNotCandidate: false, //이전 수의 여부
                isNotCandidate: false, //수의 여부

                isAutoAnls: false,  //자동분석 여부
                isHideYn: false,    //숨기기 여부

                /// input box
                anlstStartDt: '',   //분석 시작일
                anlstEndDt: '',     //분석 종료일
                selectLicns: {},  //선택 라이센스
                modifySelectLicns: {},  //선택 라이센스
                selAnlstList: [], //분석가리스트 선택
                selfacilityYn: true,  //자동분석 용역,물품 제외 여부
                selwnctyYn: true, //자동분석 전국 제외 여부
                selAnlst: '', // 분석가 선택
                debtRate: '',   //부채비율
                crasRate: '',   //유동비율
                y3AcrsltAmt: '',    //3년실적
                y5AcrsltAmt: '',    //5년 실적
                lcettSuplmtAcrsltAmt: '',   //추가실적
                cswkCapaEvltnAmt: '',   //시공능력평가금액
                cdltStDe: '',   //신용평가 만료일
                bsnRegstDe: '',     //영업기간

                selGrd: '', //선택등급
                selOrgnz: '', // 선택 발급 기관

                orgnzText: '발급기관을 선택해 주세요.', // 선택 발급 기관
                cdltText: '등급을 선택해 주세요.',   //선택 등급

                siteNm: '비드톡톡',
                siteUrl: 'https://www.bidtok.co.kr',

                activeScroll: false,    //스크롤 활성화 여부
                anlsAgreeYn: false, //분석서비스 이용 약관 동의 여부
                cntrctAgreeYn: false, // /분석서비스 제공 계약서 동의 여부
                subscriptionYn: false, // /AI구독서비스 제공 계약서 동의 여부
                anlsAgreeFlag: false,
                subscriptionFlag: false,

                agreeDt: '',    //계약서 동의 일
                agreeDate: '',  //계약서 유효 기간

                email: '', //이메일
                checkEmail: false, //이메일 동의 여부

                selectNotiId: '', //마지막 선택 공고ID

                isProcessing: false, //클릭여부

                custFulfilmentMngt: [], // 불이행 정보

                aiFee: 2.5, // 내 AI 수수료 정보
                coFee: 2, // 내 협력사 수수료 정보

                avlblPerdExpyDe: '',  // 최대 참여 금액 기준일

                tooltipList: [], //툴팁리스트
                tooltipTxt1: '',    //경영상태 툴팁
                tooltipTxt2: '',    //3년실적 툴팁
                tooltipTxt3: '',    //5년실적 툴팁
                tooltipTxt4: '',    //시공평가액 툴팁
                tooltipTxt5: '',    //영업기간 툴팁
                tooltipActive : false,
            }
        },
        computed: {
            currentDate() {
                const today = new Date();
                const year = today.getFullYear();
                const month = today.getMonth() + 1;
                const day = today.getDate();

                const formattedDate = `${year}년 ${month}월 ${day}일`;

                return formattedDate;
            },
            nextYearDate(){
                const today = new Date();
                const year = today.getFullYear() + 1;
                const month = today.getMonth() + 1;
                const day = today.getDate() - 1;

                const formattedDate = `${year}년 ${month}월 ${day}일`;

                return formattedDate;
            },
            debtRateComputed: {
                get() {
                    try{
                        return this.debtRate.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                    }catch{

                        return '';
                    }
                },
                set(value) {
                    if(value == ''){
                        this.debtRate = '';
                    } else {
                        this.debtRate = parseFloat(value.replace(/,/g, ''));
                    }
                }
            },
            crasRateComputed: {
                get() {
                    try{
                        return this.crasRate.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                    }catch{
                        return '';
                    }
                },
                set(value) {
                    if(value == ''){
                        this.crasRate = '';
                    } else {
                        this.crasRate = parseFloat(value.replace(/,/g, ''));
                    }
                }
            },
            y3AcrsltAmtComputed: {
                get() {

                    return this.y3AcrsltAmt.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                },
                set(value) {
                    if(value == ''){
                        this.y3AcrsltAmt = 0;
                    } else {
                        this.y3AcrsltAmt = parseFloat(value.replace(/,/g, ''));
                    }
                }
            },
            y5AcrsltAmtComputed: {
                get() {
                    return this.y5AcrsltAmt.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                },
                set(value) {
                    if(value == ''){
                        this.y5AcrsltAmt = 0;
                    } else {
                        this.y5AcrsltAmt = parseFloat(value.replace(/,/g, ''));
                    }
                }
            },
            cswkCapaEvltnAmtComputed: {
                get() {
                    return this.cswkCapaEvltnAmt.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                },
                set(value) {
                    if(value == ''){
                        this.cswkCapaEvltnAmt = 0;
                    } else {
                        this.cswkCapaEvltnAmt = parseFloat(value.replace(/,/g, ''));
                    }
                }
            },
            lcettSuplmtAcrsltAmtComputed: {
                get() {
                    return this.lcettSuplmtAcrsltAmt.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                },
                set(value) {
                    if(value == ''){
                        this.lcettSuplmtAcrsltAmt = 0;
                    } else {
                        this.lcettSuplmtAcrsltAmt = parseFloat(value.replace(/,/g, ''));
                    }
                }
            },
        },
        filters: {
            bsnPerdText:  function(value) {

                if (value == '0') {
                    return '1년미만';
                } else if (value == '1') {
                    return '1년~3년';
                } else if (value == '2') {
                    return '3년~5년';
                }  else if (value == '3') {
                    return '5년이상';
                }
                return '';
            },
            yyyyMMdd : function(value) {
                // 들어오는 value 값이 공백이면 그냥 공백으로 돌려줌
                if(value == '') return '';

                // 현재 Date 혹은 DateTime 데이터를 javaScript date 타입화
                var js_date = new Date(value);

                // 연도, 월, 일 추출
                var year = js_date.getFullYear();
                var month = js_date.getMonth() + 1;
                var day = js_date.getDate();

                // 월, 일의 경우 한자리 수 값이 있기 때문에 공백에 0 처리
                if(month < 10){
                    month = '0' + month;
                }

                if(day < 10){
                    day = '0' + day;
                }

                // 최종 포맷 (ex - '2021-10-08')
                return year + '-' + month + '-' + day;
            }
        },
        watch: {
            selectLicns(){
                if (this.selectLicns.custPrtcAmtList.length > 0) {
                    if (this.selectLicns.custPrtcAmtList[0].avlblPerdExpyDe != null &&
                        this.$moment().isAfter(this.$moment(this.selectLicns.custPrtcAmtList[0].avlblPerdExpyDe)) > 0 &&
                        this.selectLicns.custPrtcAmtList[0].befLmtAmt != null) {
                        this.isBefLmtAmtYn = true;
                        this.avlblPerdExpyDe = this.$moment(this.selectLicns.custPrtcAmtList[0].avlblPerdExpyDe).format('MM월 DD일');
                        if(this.selectLicns.custPrtcAmtList[0].rateGrd == 'C') {
                            this.isNotCandidate = true;
                        } else {
                            this.isNotCandidate = false;
                        }
                        if(this.selectLicns.custPrtcAmtList[0].befRateGrd == 'C') {
                            this.isBefNotCandidate = true;
                        } else {
                            this.isBefNotCandidate = false;
                        }
                    } else {
                        if(this.selectLicns.custPrtcAmtList[0].rateGrd == 'C') {
                            this.isNotCandidate = true;
                        } else {
                            this.isNotCandidate = false;
                        }
                    }
                }
                if(this.tooltipList.length > 0){
                    this.changeTooltipText();
                }
            },
            selGrd(newValue){
                if(newValue == ''){
                    this.selOrgnz = '';
                    this.cdltStDe = '';
                }
            },
            isPopNotiHideConf(newValue){
                if(newValue == true){
                    this.$scrollNon();
                }else{
                    this.$scrollActive();
                }
            },
            isPopPrtcAmt(newValue){
                if(newValue == true){
                    this.$scrollNon();
                }else{
                    this.$scrollActive();
                }
            },
            isPopSetMyInfo(newValue){
                if(newValue == true){
                    this.$scrollNon();
                }else{
                    this.$scrollActive();
                }
            },
            isPopSetAutoAnls(newValue){
                if(newValue == true){
                    this.$scrollNon();
                }else{
                    this.$scrollActive();
                }
            },
            isPopForceSetMyInfo(newValue){
                if(newValue == true){
                    this.$scrollNon();
                }else{
                    this.$scrollActive();
                }
            },
            isPopCustCntrct(newValue){
                if(newValue == true){
                    this.$scrollNon();
                }else{
                    this.$scrollActive();
                }
            },
            isPopSelectAnlst(newValue){
                if(newValue == true){
                    this.$scrollNon();
                }else{
                    this.$scrollActive();
                }
            },
            isPopOffAutoAnal(newValue){
                if(newValue == true){
                    this.$scrollNon();
                }else{
                    this.$scrollActive();
                }
            },
            isPopSuccessCustNoti(newValue){
                if(newValue == true){
                    this.$scrollNon();
                }else{
                    this.$scrollActive();
                }
            },
            isPopNonFulfliment(newValue){
                if(newValue == true){
                    this.$scrollNon();
                }else{
                    this.$scrollActive();
                }
            },
        },

        methods: {
            /**공고 숨기기 여부 변경*/
            updateCustNotiHideYn() {
                var param = {
                    "notiId": this.selectNotiId,
                    "custBizId" : this.loginInfo.custBizList[0].id,
                    "hideYn": this.isHideYn ? 'N' : 'Y',
                };

                this.$post('/api/custNotiHide/insert', param)
                    .then(res => {
                        if(res.code == 'OK'){
                            this.notiSuggestList = this.notiSuggestList.filter((e) => e.id != this.selectNotiId);
                            this.notiSugguestListCnt--;
                            this.refactSuggestNotiList();
                        }
                    })
                    .catch(error => console.log(error));
                this.$stopEventBubble();
            },
            /** 툴팁 리스트 가져오기*/
            getTooltipList() {
                var stringList = [];
                for (var i in this.custLicnsList) {
                    stringList.push(this.custLicnsList[i].licnsCd)
                }

                var param = {
                    licnsCdList: stringList,
                    tooltipCdList: [1, 2, 3, 4, 5]
                };

                this.$post('/api/comn/tooltipList', param)
                    .then(res => {
                        this.tooltipList = res.tooltipList;
                        this.changeTooltipText();
                    })
                    .catch(error => console.log(error));
            },
            /** 툴팁 텍스트 변경*/
            changeTooltipText() {
                var index1 = this.tooltipList.findIndex((e) => e.licnsCd == this.modifySelectLicns.licnsCd && e.tooltipCd == '1');
                var index2 = this.tooltipList.findIndex((e) => e.licnsCd == this.modifySelectLicns.licnsCd && e.tooltipCd == '2');
                var index3 = this.tooltipList.findIndex((e) => e.licnsCd == this.modifySelectLicns.licnsCd && e.tooltipCd == '3');
                var index4 = this.tooltipList.findIndex((e) => e.licnsCd == this.modifySelectLicns.licnsCd && e.tooltipCd == '4');
                var index5 = this.tooltipList.findIndex((e) => e.licnsCd == this.modifySelectLicns.licnsCd && e.tooltipCd == '5');

                if (index1 !=  -1) {
                    this.tooltipTxt1 = this.tooltipList[index1].contents
                } else {
                    this.tooltipTxt1 = '22년 경영상태동의확인서 적용입니다.';
                }
                if (index2 !=  -1) {
                    this.tooltipTxt2 = this.tooltipList[index2].contents
                } else {
                    this.tooltipTxt2 = '최근 22년, 21년, 20년도의 최종 준공 완료된 공사 실적 합계액입니다.';
                }
                if (index3 !=  -1) {
                    this.tooltipTxt3 = this.tooltipList[index3].contents
                } else {
                    this.tooltipTxt3 = '최근 22년, 21년, 20년, 19년, 18년도의 최종 준공 완료된 공사 실적 합계액입니다.';
                }
                if (index4 !=  -1) {
                    this.tooltipTxt4= this.tooltipList[index4].contents
                } else {
                    this.tooltipTxt4 = '업체가 공사를 수행할 수 있는 능력을 금액으로 환산한 것으로 현재 23년 시공능력평가액 적용됩니다.';
                }
                if (index5 !=  -1) {
                    this.tooltipTxt5 = this.tooltipList[index5].contents
                } else {
                    this.tooltipTxt5 = '해당 면허의 영업시작일로 공사업 등록증 또는 경영상태등의확인서에서 확인할 수있습니다.';
                }

                if(!this.tooltipActive) {
                    this.tooltipScript();
                    this.tooltipActive = true;
                }
            },
            /** 툴팁 스크립트*/
            tooltipScript() {
            //툴팁 수정 (before after 선택자에 내용 x)
                let toolTip01 = document.getElementById('prtcAmtToolTip');

                if(toolTip01 != null){
                    toolTip01.addEventListener('click', function(event){
                        toolTipClass01(event.target);
                        event.stopPropagation();
                    });
                }

                function toolTipClass01(target) {
                    let parent = target.parentElement;
                    while (parent) {
                        if (parent && parent.classList.contains('tooltip')) {
                            parent.classList.toggle('active');
                            break;
                        }
                        parent = parent.parentElement; // 부모 요소 업데이트
                    }
                }

                document.addEventListener('click', function (event) {
                    const clickedElement = event.target;
                    if (!clickedElement.classList.contains('tooltip__box') && toolTip01 != null) {
                        // toolTip01.forEach(element => {
                            toolTip01.classList.remove('active');
                        // });
                    }
                });


//툴팁 (before after 선택자에 내용)
                let toolTip = document.querySelectorAll('.tooltip__group');
                if (toolTip.length > 0) {
                    toolTip.forEach(element => {
                        element.addEventListener('click', function (event) {
                            toolTipClass(event.target);
                            event.stopPropagation(); // 이벤트 전파 중단
                        });
                    });
                }

                function toolTipClass(target) {
                    let parent = target.parentElement;
                    while (parent) {
                        if (parent && parent.classList.contains('tooltip__group')) {
                            parent.classList.toggle('active');
                            break;
                        }
                        parent = parent.parentElement; // 부모 요소 업데이트
                    }
                }

                document.addEventListener('click', function (event) {
                    const clickedElement = event.target;
                    if (!clickedElement.classList.contains('tooltip__box')) {
                        toolTip.forEach(element => {
                            element.classList.remove('active');
                        });
                    }
                });
            },
            //불이행 해제 팝업 확인
            updateLockConfirm(){
                var param = {
                    "id":  this.custFulfilmentMngt.id
                };

                this.$post('/api/cust/fulfilmentMngt/lockConfirm',param)
                    .then(res => {
                        if(res.code == "OK"){
                            this.isPopNonFulfliment = false;
                        } else {
                            this.isPopNonFulfliment = false;
                        }
                    })
                    .catch(error => console.log(error));
            },
            //분석요청 제한 기간 체크
            async checkNonFulfilmentMngt(){

                await this.$get('/api/cust/fulfilmentMngt/info')
                    .then(res => {
                        this.custFulfilmentMngt = res.data;
                    })
                    .catch(error => console.log(error));



                if(this.custFulfilmentMngt == null){
                    return false;
                }

                const today = this.$moment();

                var lockStartDt= this.$moment(this.custFulfilmentMngt.lockStartDt);
                var lockEndDt= this.$moment(this.custFulfilmentMngt.lockEndDt).add(1,'days');

                //오늘날짜가 분석요청제한기간일 경우
                if( today.isAfter(lockStartDt) && today.isBefore(lockEndDt) && this.custFulfilmentMngt.lockStatus == 'Y') {
                    return true;
                } else {
                    return false;
                }
            },
            //불이행 여부 체크
            getFulfilmentMngt(){
                this.$get('/api/cust/fulfilmentMngt/info')
                    .then(res => {
                        this.custFulfilmentMngt = res.data;
                    })
                    .catch(error => console.log(error));
            },

            //내 수수료 정보
            getMyAnlsFee(){
                this.$get('/api/anlsFee/myAnlsFee')
                    .then(res => {
                        this.myAnlsFee = res.myAnlsFee;
                        if(this.myAnlsFee.length > 0){
                            this.aiFee = res.myAnlsFee.find((e) => e.anlstType == 'AI').anlsFee;
                            this.coFee = res.myAnlsFee.find((e) => e.anlstType == 'CO').anlsFee;
                        }
                    })
                    .catch(error => console.log(error));
            },

            formatInput() {
                // 입력된 값을 숫자로 파싱하고 천 단위 콤마 추가
                const parsedAmount = parseFloat(this.formattedAmount.replace(/,/g, "")) || 0;
                this.amount = parsedAmount;
                this.formattedAmount = parsedAmount.toLocaleString("en-US");
            },
            //강제입력팝업 다음 클릭
            clickForceSetMyInfoStep() {
                if(this.validateCustBiz()){
                    this.isPopForceSetMyInfoStep3 = true;
                    this.isPopForceSetMyInfoStep2 = false;
                }
            },

            //자동분석 변경 클릭
            clickModifyAutoAnls() {
                this.isPopSetAutoAnls = true;
                this.selAnlstList = [];
                // this.$refs.AutoAnlsPop.selAnlstList = [];
                for (var i in this.autoAnlsConfigList) {
                    this.selAnlstList.push(this.autoAnlsConfigList[i].notiAnlstId);
                    // this.$refs.AutoAnlsPop.selAnlstList.push(this.autoAnlsConfigList[i].notiAnlstId);
                }

            },
            popModifyiLicns(){
                var index = this.custLicnsList.findIndex((element) => element == this.selectLicns);

                if(index != -1) {
                    this.isPopSetMyInfo = true;
                    this.modifyCustLicnsList = _.cloneDeep(this.custLicnsList);
                    this.modifySelectLicns = this.modifyCustLicnsList[index];
                    this.debtRate = this.custBiz.debtRate;
                    this.crasRate = this.custBiz.crasRate;
                    this.y3AcrsltAmt = this.modifySelectLicns.y3AcrsltAmt;
                    this.y5AcrsltAmt = this.modifySelectLicns.y5AcrsltAmt;
                    this.cswkCapaEvltnAmt = this.modifySelectLicns.cswkCapaEvltnAmt;
                    this.lcettSuplmtAcrsltAmt = this.modifySelectLicns.lcettSuplmtAcrsltAmt;
                } else {
                    this.isPopNotExistCustLicns = true;
                    //this.$goMenu('login');
                }
            },
            forceClickLicns(licns) {

                this.licnsChange(licns);
            },
            clickLicns(licns) {

                this.licnsChange(licns);
            },
            licnsChange(licns){
                var index = this.modifyCustLicnsList.findIndex((element) => element == this.modifySelectLicns);

                var bsnPerdCd = "";
                var bsnPerdDeText = "";

                var today = this.$moment();
                var bsnRegstDe =  this.dateToString(this.bsnRegstDe);
                var selectDay = this.$moment(bsnRegstDe);
                var year = today.diff(selectDay,'years');

                if (year >= 5) {
                    bsnPerdCd = '3';
                    bsnPerdDeText = '5년이상';
                } else if (year >= 3) {
                    bsnPerdCd = '2';
                    bsnPerdDeText = '3년~5년';
                } else if (year >= 1) {
                    bsnPerdCd = '1';
                    bsnPerdDeText = '1년~3년';
                } else {
                    bsnPerdCd = '0';
                    bsnPerdDeText = '1년미만';
                }

                this.modifyCustLicnsList[index].y3AcrsltAmt = this.y3AcrsltAmt;
                this.modifyCustLicnsList[index].y5AcrsltAmt = this.y5AcrsltAmt;
                this.modifyCustLicnsList[index].cswkCapaEvltnAmt =  this.cswkCapaEvltnAmt;
                this.modifyCustLicnsList[index].lcettSuplmtAcrsltAmt = this.lcettSuplmtAcrsltAmt;
                this.modifyCustLicnsList[index].bsnPerdCd = bsnPerdCd;
                this.modifyCustLicnsList[index].bsnRegstDe = bsnRegstDe;
                this.modifyCustLicnsList[index].bsnPerdDeText = bsnPerdDeText;

                this.modifySelectLicns = licns;
                this.y3AcrsltAmt = this.modifySelectLicns.y3AcrsltAmt;
                this.y5AcrsltAmt = this.modifySelectLicns.y5AcrsltAmt;
                this.cswkCapaEvltnAmt = this.modifySelectLicns.cswkCapaEvltnAmt;
                this.lcettSuplmtAcrsltAmt = this.modifySelectLicns.lcettSuplmtAcrsltAmt;
                this.bsnRegstDe = this.modifySelectLicns.bsnRegstDe;
                this.changeTooltipText() ;
            },
            offAutoAnal() {
                this.isPopOffAutoAnal = false;
                this.anlsPeriod = 7;
                this.calsNextDate(7);
                this.insertAutoAnlsConfig(false);
            },
            previos(){
                let slideCont = event.target.parentElement.parentElement.querySelector('.tok-slide--list');

                if (this.currentPosition < 40) {
                    this.currentPosition += 160;
                    slideCont.style.transform = `translateX(${this.currentPosition}px)`;
                    this.count += 1;
                }
            },
            next(){
                let slideCont = event.target.parentElement.parentElement.querySelector('.tok-slide--list');

                if (this.count > 0 ) {
                    this.currentPosition -= 160;
                    slideCont.style.transform = `translateX(${this.currentPosition}px)`;
                    this.count -= 1;
                } else {
                    return;
                }

            },
            insertEmailInfo() {
                var param = {
                    "custEmail": this.email,
                };

                this.$post('/api/cust/updateEmail',param)
                    .then(res => {
                        console.log(res);
                    })
                    .catch(error => console.log(error));
            },
            //분석이용 약관 동의
            insertAnlsAgree(){

                if(!this.anlsAgreeYn || !this.cntrctAgreeYn) {
                    this.$toast.error("약관에 동의해 주세요.");
                    return;
                }
                if(!this.email) {
                    this.$toast.error("이메일을 입력해 주세요.");
                    return;
                }
                // if(this.email != '' && this.checkEmail) {
                if(this.email != '') {
                    this.insertEmailInfo();
                }

                this.getCntrctTmple();

            },
            //AI멤버십 계약 동의
            insertAISubAgree(){

                if(!this.subscriptionYn) {
                    this.$toast.error("약관에 동의해 주세요.");
                    return;
                }

                //this.insertAISubCntrct('','');
                this.getAISubCntrctTmple();

            },
            //템플릿 가져오기
            getCntrctTmple() {
                var param = {};

                this.$post('/api/cust/custCntrctTmpl',param)
                    .then(res => {
                        if(res.status == 'OK'){
                            var custCntrctTmpl= res.custCntrctTmplList[0];
                            var custtmplHtml = custCntrctTmpl.cntrctTmplHtml;
                            var today = this.$moment();

                            custtmplHtml = custtmplHtml.replaceAll('#{CMP_NM}', this.loginInfo.cmpNm);
                            custtmplHtml = custtmplHtml.replaceAll('#{USER_NM}', this.loginInfo.userNm);
                            custtmplHtml = custtmplHtml.replaceAll('#{currentDate}',  today.format('YYYY년 MM월 DD일'));
                            custtmplHtml = custtmplHtml.replaceAll('#{nextYearDate}', today.add(1, 'year').subtract(1,'days').format('YYYY년 MM월 DD일'));
                            custtmplHtml = custtmplHtml.replaceAll('#{todayTime}', today.format('HH:mm'));

                            this.insertCntrct(custCntrctTmpl,custtmplHtml);


                        } else {
                            this.$toast.error('계약서 등록 실패');
                        }
                    })
                    .catch(error => console.log(error));
            },
            //AI멤버십 템플릿 가져오기
            getAISubCntrctTmple() {
                var param = {};

                this.$post('/api/cust/custCntrctTmpl',param)
                        .then(res => {
                            if(res.status == 'OK'){

                                var custCntrctTmpl= _.filter(res.custCntrctTmplList,function(o) {
                                    return o.id == 2;
                                })[0];
                                var custtmplHtml = custCntrctTmpl.cntrctTmplHtml;
                                var today = this.$moment();
                                var subApplyDt = today.format('YYYY-MM-DD HH:mm:ss');

                                custtmplHtml = custtmplHtml.replaceAll('#{CMP_NM}', this.loginInfo.cmpNm);
                                custtmplHtml = custtmplHtml.replaceAll('#{USER_NM}', this.loginInfo.userNm);
                                custtmplHtml = custtmplHtml.replaceAll('#{subApplyDt}', subApplyDt);
                                //custtmplHtml = custtmplHtml.replaceAll('#{currentDate}',  today.format('YYYY년 MM월 DD일'));
                                //custtmplHtml = custtmplHtml.replaceAll('#{nextYearDate}', today.add(1, 'year').subtract(1,'days').format('YYYY년 MM월 DD일'));
                                custtmplHtml = custtmplHtml.replaceAll('#{todayTime}', today.format('HH:mm'));

                                this.insertAISubCntrct(custCntrctTmpl, custtmplHtml, subApplyDt);

                            } else {
                                this.$toast.error('계약서 등록 실패');
                            }
                        })
                        .catch(error => console.log(error));
            },
            //계약서 저장
            insertCntrct(custCntrctTmpl,custtmplHtml){
                var param = {
                    "custId": this.loginInfo.id,
                    "anlsAgreeYn": this.anlsAgreeYn ? 'Y' : 'N',
                    "cntrctAgreeYn": this.cntrctAgreeYn ? 'Y': 'N',
                    "cntrctTmplId": custCntrctTmpl.id,
                    "cntrctHtml": custtmplHtml,
                };

                this.$post('/api/cust/insertCntrct',param)
                    .then(res => {
                        if(res.status == 'OK'){
                            this.isPopCustCntrctStep1= false;
                            this.isPopCustCntrctStep2= false;
                            this.isPopCustCntrctStep3 = true;
                            this.agreeDt = this.$moment().format('YYYY.MM.DD');
                            this.agreeDate = this.$moment().format('YYYY.MM.DD') + ' ~ ' + this.$moment().add(1,"years").subtract(1,'days').format('YYYY.MM.DD');
                            this.getCustCntrct();

                        } else {
                            this.$toast.error('계약서 등록 실패');
                        }
                    })
                    .catch(error => console.log(error));
            },
            //AI멤버십 계약서 저장
            insertAISubCntrct(custCntrctTmpl, custtmplHtml, subApplyDt){
                var param = {
                    "custBizId": this.loginInfo.custBizList[0].id,
                    "custCntrctId": this.custCntrctList[0].id,
                    "planId": '1',
                    "cntrctTmplId": custCntrctTmpl.id,
                    "cntrctHtml": custtmplHtml,
                    "lastAmndr": this.loginInfo.cmpNm,
                    "custTelno": this.loginInfo.custTelno,
                    "subApplyDt": subApplyDt,
                };

                if (this.subscriptionYn == true) {
                    this.$post('/api/cust/insertSubscription',param)
                            .then(res => {
                                if(res.status == 'OK'){
                                    this.isPopCustCntrctStep2_1= false;
                                    this.isPopCustCntrctStep3_1 = true;

                                    this.getCustInfo3( function(o) {
                                       console.log(o)
                                    });
                                } else {
                                    this.$toast.error('계약서 등록 실패');
                                }
                            })
                            .catch(error => console.log(error));
                }
            },
            closeAnlsAgreePop(value){

                if(value){
                    const anlsAgreeBtn = document.getElementById('anlsAgreeBtn');

                    if (anlsAgreeBtn.classList.contains('btn__active')) {
                        this.anlsAgreeYn = true;
                        this.isPopUseAgree = false;
                    } else {
                        this.$toast.error("약관을 끝까지 보신 후, 동의 가능합니다.");
                    }
                } else {
                    this.isPopUseAgree = false;
                }
                // this.$refs.agree.focus();
            },
            closeCntrctAgreePop(value){
                if(value){
                    const cntrctAgreeBtn = document.getElementById('cntrctAgreeBtn');

                    if (cntrctAgreeBtn.classList.contains('btn__active')) {
                        this.cntrctAgreeYn = true;
                        this.anlsAgreeFlag = true;
                        this.isPopCntrct = false;
                    } else {
                        this.$toast.error("약관을 끝까지 보신 후, 동의 가능합니다.");
                    }
                    // if (this.anlsAgreeFlag) {
                    //     this.cntrctAgreeYn = true;
                    //     this.isPopCntrct = false;
                    // } else {
                    //     this.$toast.error("약관을 끝까지 보신 후, 동의 가능합니다.");
                    // }
                } else {
                    this.isPopCntrct = false;
                }
                // this.$refs.agree.focus();

            },
            closeSubscriptionPop(value) {
                if(value){
                    const subscriptionBtn = document.getElementById('subscriptionBtn');

                    if (subscriptionBtn.classList.contains('btn__active')) {
                        this.subscriptionYn = true;
                        this.subscriptionFlag = true;
                        //this.anlsAgreeFlag = true;
                        //this.isPopCntrct = false;
                        this.isPopSubscription = false;
                    } else {
                        this.$toast.error("약관을 끝까지 보신 후, 동의 가능합니다.");
                    }
                    // if (this.anlsAgreeFlag) {
                    //     this.cntrctAgreeYn = true;
                    //     this.isPopCntrct = false;
                    // } else {
                    //     this.$toast.error("약관을 끝까지 보신 후, 동의 가능합니다.");
                    // }
                } else {
                    this.isPopSubscription = false;
                }
                // this.$refs.agree.focus();
            },
            activeScrollEvent(){
                if(!this.active){

                    this.active = true;
                    const useAgreeScroll = document.getElementById('useAgreeScroll');

                    useAgreeScroll.addEventListener('scroll', function(){
                        let popScrollHeight = useAgreeScroll.scrollHeight; // 스크롤 전체 높이
                        let popScrollTop = useAgreeScroll.scrollTop; // 스크롤 현재 높이
                        if (useAgreeScroll.getBoundingClientRect().height + popScrollTop > popScrollHeight - 3) {
                            const anlsAgreeBtn = document.getElementById('anlsAgreeBtn');
                            anlsAgreeBtn.classList.remove('btn__non-active');
                            anlsAgreeBtn.classList.add('btn__active');
                        }
                    });

                    // /* 개인정보 스크롤 이벤트*/
                    const etcAgreeScroll = document.getElementById('etcAgreeScroll');

                    etcAgreeScroll.addEventListener('scroll', function(){
                        let popScrollHeight = etcAgreeScroll.scrollHeight; // 스크롤 전체 높이
                        let popScrollTop = etcAgreeScroll.scrollTop; // 스크롤 현재 높이
                        if (etcAgreeScroll.getBoundingClientRect().height + popScrollTop > popScrollHeight - 3) {
                            const cntrctAgreeBtn = document.getElementById('cntrctAgreeBtn');
                            // cntrctAgreeBtn.disabled = false;
                            cntrctAgreeBtn.classList.remove('btn__non-active');
                            cntrctAgreeBtn.classList.add('btn__active');
                            //요기!!!!!!
                        }
                    });

                    // /* 구독형 스크롤 */
                    const subscriptionScroll = document.getElementById('subscriptionScroll');

                    subscriptionScroll.addEventListener('scroll', function(){
                        let popScrollHeight = subscriptionScroll.scrollHeight; // 스크롤 전체 높이
                        let popScrollTop = subscriptionScroll.scrollTop; // 스크롤 현재 높이
                        if (subscriptionScroll.getBoundingClientRect().height + popScrollTop > popScrollHeight - 3) {
                            const subscriptionBtn = document.getElementById('subscriptionBtn');
                            // subscriptionBtn.disabled = false;
                            subscriptionBtn.classList.remove('btn__non-active');
                            subscriptionBtn.classList.add('btn__active');
                        }
                    });
                }
            },
            move(message) {
                //페이지 이동
                document.getElementById(message).scrollIntoView({behavior:'smooth'});
            },

            /** AI멤버십 체크 클릭시 */
            clkSubscription() {
                // if (this.cntrctAgreeYn != true) {
                //     this.isPopSubscription = false;
                //     this.$toast.error('분석서비스 제공계약서에 동의 후 확인 가능합니다.');
                // } else {
                if (this.loginInfo.subStatusCd != 1) {
                    this.isPopSubscription = true;
                }
                //}
            },
            /** AI멤버십 신청 팝업 */
            async chkCustAISub() {
                var self = this;
                console.log(this.loginInfo.subStatusCd);
                this.getCustInfo3(function() {
                     if (self.loginInfo.subStatusCd == null) {
                         self.isPopCustCntrct = true;
                         self.isPopCustCntrctStep2_1 = true;
                     } else if (self.loginInfo.subStatusCd == 0) {
                         self.isPopCustCntrct = true;
                         self.isPopCustCntrctStep3_1 = true;
                     }
                });
                this.activeScrollEvent();
            },
            //계약정보 체크
            async checkCustCntrct(notiId) {
                this.selectNotiId = notiId;
                if (this.custCntrctList.length == 0) {
                    // 계약서가 없으면 체크 약관 동의 팝업
                    this.isPopCustCntrct = true;
                    this.isPopCustCntrctStep1 = true;
                    this.activeScrollEvent();

                } else if (await this.checkNonFulfilmentMngt()) {
                    this.isPopNonFulfliment = true;
                } else {
                    this.selAnlst = '';
                    //분석요청 팝업
                    this.isPopSelectAnlst = true;
                }
            },
            //계약서 정보 로드
            getCustCntrct() {

                var param = {
                  "custId": this.loginInfo.id,
                };
                this.$post('/api/cust/custCntrct',param)
                    .then(res => {
                        this.custCntrctList = res.custCntrctList;
                    })
                    .catch(error => console.log(error));

            },
            selectBox(target) {
                target.classList.toggle('on');
                const selectBox = document.querySelector('.select-box__sel');

                /* 셀렉트박스 활성화 중 다른 곳 클릭시 꺼짐 */
                document.addEventListener('click', (event) => {
                    const target = event.target;
                    if (!selectBox.contains(target)) {
                        selectBox.classList.remove('on');
                    }
                });
                /* /셀렉트박스 활성화 중 다른 곳 클릭시 꺼짐 */
            },
            selectGrd() {
                var btn = event.target.parentElement.parentElement.parentElement.querySelector('.select-box__sel');
                if (event.target.nodeName === "BUTTON") {
                    this.cdltText =  event.target.innerText;
                    this.selGrd = event.target.value;
                    btn.classList.remove('on');
                    if(this.selGrd == ''){
                        this.selOrgnz = '';
                        this.orgnzText = '발급기관을 선택해 주세요.';
                        this.cdltStDe = '';
                    }
                }

            },
            selectOrgnz() {
                var btn = event.target.parentElement.parentElement.parentElement.querySelector('.select-box__sel');
                if (event.target.nodeName === "BUTTON") {
                    this.orgnzText =  event.target.innerText;
                    this.selOrgnz = event.target.value;
                    btn.classList.remove('on');
                }

            },
            select() {
                var btn = event.target.parentElement.querySelector('select-box__sel');
                if (event.target.nodeName === "BUTTON") {
                    btn.innerText = event.target.innerText;
                    this.kword = event.target.value;
                    btn.classList.remove('on');
                }
            },
            inputNumberFormat() {
                var obj = event.target;
                obj.value = this.comma(this.uncomma(obj.value));

            },
            comma(str) {
                str = String(str);
                return str.replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,');
            },

            uncomma(str) {
                str = String(str);
                return str.replace(/[^\d]+/g, '');
            },
            //자동분석 토글 선택
            async clickAutoAnls() {
                // 회원정보 갱신
                var self = this;
                this.$parent.getCustInfoComn(function() {
                    self.loginInfo = self.$parent.loginInfo;
                    if (self.loginInfo == null) {
                        self.$goMenu('login');
                        return;
                    }
                });

                if (await this.checkNonFulfilmentMngt()) {
                    this.isPopNonFulfliment = true;
                } else if (this.isAutoAnls) {
                    this.selAnlstList = [];
                    this.isPopOffAutoAnal = true;
                } else {
                    //경영면허 정보 입력 팝업
                    for (var i in this.custLicnsList) {
                        if (this.custLicnsList[i].updateYn == 'N') {
                            this.isPopForceSetMyInfo = true;
                            this.isPopForceSetMyInfoStep1 = true;
                            this.isNoValueCustLicns = true;

                            this.modifyCustLicnsList = _.cloneDeep(this.custLicnsList);
                            this.modifySelectLicns = this.modifyCustLicnsList[0];
                            this.debtRate = this.custBiz.debtRate;
                            this.crasRate = this.custBiz.crasRate;
                            this.y3AcrsltAmt = this.modifySelectLicns.y3AcrsltAmt;
                            this.y5AcrsltAmt = this.modifySelectLicns.y5AcrsltAmt;
                            this.cswkCapaEvltnAmt = this.modifySelectLicns.cswkCapaEvltnAmt;
                            this.lcettSuplmtAcrsltAmt = this.modifySelectLicns.lcettSuplmtAcrsltAmt;
                            return;
                        }
                    }

                    await this.getCustCntrct();
                    if (this.custCntrctList.length == 0) {
                        // 계약서가 없으면 체크 약관 동의 팝업
                        // this.isPopCustCntrct = true;
                        // this.isPopCustCntrctStep1 = true;
                        this.$parent.showCustCntrctPop();
                        // this.$parent.$refs.headers.isPopCustCntrctStep1 = true;
                        this.activeScrollEvent();
                    } else {
                        this.isPopSetAutoAnls = [];
                        //자동분석 설정 팝업
                        this.isPopSetAutoAnls = true;
                        this.selAnlstList = [];

                        //선택값 초기화
                        // this.$refs.AutoAnlsPop.selAnlstList = [];
                        //분석가 전체 선택
                        // for (var j in this.notiAnlstList) {
                        //     this.selAnlstList.push(this.notiAnlstList[j].id);
                        // }
                    }
                }
            },
            //분석가 선택
            clickAnlst(id){
                this.selAnlst = id;
            },
            //분석가목록 선택
            clickAnlstList(id){
                var idx = this.selAnlstList.findIndex((e)=> e == id);

                if (idx == -1) {
                    //못찾았으면
                    this.selAnlstList.push(id);
                } else {
                    this.selAnlstList.splice(idx,1);
                }
            },
            //공고번호 복사
            copyNotiNo(value) {
                this.$copyText(value);
                this.snackbar = true;
                // this.copyText = value;
                this.$stopEventBubble();

                // this.$toast('HelloWorld', { position: "bottom-right" });

                this.$toast.success("공고번호를 복사했습니다.");

                // alert("공고번호를 복사했습니다.");
            },
            //공고 상세페이지이동
            notiDetailPop(menuUrl, id) {
                if (this.$route.path != "/" + menuUrl) // 현재 route와 다른 경우만 페이지 이동
                    window.open('/' + menuUrl + '/' + id, '_blank', 'width=1200,height=1000');
            },
            //검색
            searchKeyword(){
                this.notiSuggestList=  [];
                this.notiSugguestListCnt = 0;
                this.page = 0;
                this.getNotiSuggestList();
            },
            clickAccordian(){
                this.accordionActive = !this.accordionActive;
                this.$cookies.set("accordionActive",this.accordionActive);
            },
            //분석가 목록
            getNotiAnlst() {
                this.notiAnlstList = [];
                // this.$refs.AutoAnlsPop.notiAnlstList = [];

                this.$http.get('/api/noti/anlstList')
                    .then(res => {
                        var notiAnlstList = res.data.notiAnlstList;
                       this.notiAnlstList = _.filter(notiAnlstList, function(o) {
                           return o.id == '1' || o.id == '2' || o.id == '3' || o.id == '4' || o.id == '5' || o.id == '6' ||
                                   o.id == '7' || o.id == '8' || o.id == '9';
                       });
                        // this.$refs.AutoAnlsPop.notiAnlstList = notiAnlstList;
                    })
                    .catch(error => console.log(error));
            },
            //신용평가 목록
            getCdltSdList() {
                this.cdltStList = [];

                this.$http.get('/api/comnCode/cdltSt')
                    .then(res => {
                        this.cdltStList = res.data.cdltStList;
                        this.cdltStList = this.cdltStList.filter((e) => e.cdltStCd == '0');

                    })
                    .catch(error => console.log(error));
            },
            //발급기관 목록
            getCdltStOrgnz() {
                this.cdltStOrgList = [];

                var param = {"comnCdGroupId": "CDLT_ST_ISS_ORGNZ"};

                this.$http.post('/api/comnCode/list',param)
                    .then(res => {
                        this.cdltStOrgList = res.data.comnCodeList;
                    })
                    .catch(error => console.log(error));
            },
            //내 정보 갱신
            getCustInfo3(callback){

                this.$get('/api/cust/custInfo/select')
                        .then(res => {
                            localStorage.setItem("custInfo",JSON.stringify(res.data));
                            this.loginInfo = JSON.parse(localStorage.getItem("custInfo"));

                            callback();
                        })
                        .catch(error => {console.log(error);}
                        );
            },
            //내 정보 갱신
            getCustInfo2(){
                this.$get('/api/cust/custInfo/select')
                    .then(res => {
                        localStorage.setItem("custInfo",JSON.stringify(res.data));
                        this.$router.go(0);
                    })
                    .catch(error => {console.log(error);}
                    );
            },
            //내 경영 정보
            getCustInfo(){
                // 회원정보 갱신
                var self = this;
                this.$parent.getCustInfoComn(function(res) {
                    self.loginInfo = self.$parent.loginInfo;

                    self.custInfo = res.data;
                    self.custBiz = self.custInfo.custBizList.find(item => item.id === self.custInfo.custBizList[0].id,);

                    // console.log(this.custBiz.custPrfshList);
                    // console.log(this.custBiz.cdltStId);
                    if(self.custBiz.custPrfshList.length > 0) {
                        self.custBiz.custPrfsh = self.custBiz.custPrfshList[0];
                        self.selGrd =  self.custBiz.cdltStId;
                        self.selOrgnz = self.custBiz.custPrfshList[0].prfshOrgnzNm;
                        self.cdltText = self.custBiz.custPrfshList[0].prfshEtcNm;
                        self.orgnzText = self.custBiz.custPrfshList[0].prfshOrgnzNm;
                        if(self.custBiz.custPrfshList[0].proofRnwlDe != null){
                            self.cdltStDe = self.$moment(self.custBiz.custPrfshList[0].proofRnwlDe).format('YYYY-MM-DD');
                        }

                        if(self.selGrd == null) {
                            self.selGrd = '';
                        }
                        if(self.selOrgnz == null) {
                            self.selOrgnz = '';
                        }

                        if(self.cdltText == null || self.cdltText == '') {
                            self.cdltText = '등급을 선택해 주세요.';
                        }
                        if(self.orgnzText == null || self.orgnzText == ''){
                            self.orgnzText = '발급기관을 선택해 주세요.';
                        }
                        if(self.cdltStDe == null){
                            self.cdltStDe = '';
                        }
                    }
                    // this.boardList = this.boardList.filter(item => item.noticeUseYn === 'Y');
                }, self.$route.path);
            },
            //내 면허 정보
            async getCustLicnsList(){

                this.isNoValueCustLicns = false;
                await this.$get('/api/custBiz/' + this.loginInfo.custBizList[0].id + '/custLicns')
                    .then(res => {
                        if(res.code == 'OK'){
                            if (res.data != "") {
                                this.custLicnsList = res.data;
                                this.modifyCustLicnsList = _.cloneDeep(res.data);
                                this.selectLicns = this.custLicnsList[0];
                                this.$moment(this.strtDate).format('YYYY-MM-DD');
                                this.modifySelectLicns = this.modifyCustLicnsList[0];

                                this.bsnRegstDe = this.custLicnsList[0].bsnRegstDe;
                                this.count = this.custLicnsList.length -1;
                                this.currentPosition = 40;
                                // let slideCont = document.querySelector('.tok-slide--list');
                                // console.log(slideCont);
                                // slideCont.style.transform = `translateX(${this.currentPosition}px)`;

                                for (var i in this.custLicnsList) {
                                    if(this.custLicnsList[i].updateYn == 'N') {
                                        this.isPopForceSetMyInfo = true;
                                        this.isPopForceSetMyInfoStep1 = true;
                                        this.isNoValueCustLicns = true;

                                        this.modifyCustLicnsList = _.cloneDeep(this.custLicnsList);
                                        this.modifySelectLicns = this.modifyCustLicnsList[0];
                                        this.debtRate = this.custBiz.debtRate;
                                        this.crasRate = this.custBiz.crasRate;
                                        this.y3AcrsltAmt = this.modifySelectLicns.y3AcrsltAmt;
                                        this.y5AcrsltAmt = this.modifySelectLicns.y5AcrsltAmt;
                                        this.cswkCapaEvltnAmt = this.modifySelectLicns.cswkCapaEvltnAmt;
                                        this.lcettSuplmtAcrsltAmt = this.modifySelectLicns.lcettSuplmtAcrsltAmt;
                                        return;
                                    }
                                }
                            } else {
                               //면허 없음
                                this.isPopNotExistCustLicns = true;
                                this.isNoValueCustLicns = true;
                            }
                        }

                    })
                    .catch(error => {console.log(error);}
                    );
            },
            //자동분석 정보
            getAutoAnlsConfigList(){
                var param = {
                    "custBizId" : this.loginInfo.custBizList[0].id,
                }
                this.$post('/api/autoAnlsConfig/list',param)
                    .then(res => {
                        this.autoAnlsConfigList = res.autoAnlsConfigList;

                        if(this.autoAnlsConfigList.length>0) {
                            this.isAutoAnls = true;
                            this.selfacilityYn =  this.autoAnlsConfigList[0].facilityYn == 'N' ? false : true;
                            this.selwnctyYn =  this.autoAnlsConfigList[0].wnctyYn == 'Y' ? true : false;
                            this.startDt = this.autoAnlsConfigList[0].anlsStartDt;
                            this.endDt = this.autoAnlsConfigList[0].anlsEndDt;
                            this.bddprStartDt = this.calcBddprDt(this.autoAnlsConfigList[0].anlsStartDt);
                            this.bddprEndDt = this.calcBddprDt(this.autoAnlsConfigList[0].anlsEndDt);
                            this.anlstStartDt = this.$moment(this.autoAnlsConfigList[0].anlsStartDt).format('MM.DD');
                            this.anlstEndDt = this.$moment(this.autoAnlsConfigList[0].anlsEndDt).format('MM.DD');
                            if(this.autoAnlsConfigList[0].anlsPeriod != '0'){
                                this.anlsPeriod = this.autoAnlsConfigList[0].anlsPeriod;
                            }

                            for (var i in this.autoAnlsConfigList) {
                                this.selAnlstList.push(this.autoAnlsConfigList[i].notiAnlstId);
                            }
                        } else {
                            this.calsNextDate(7);
                            this.calsBddprNextDate(7);
                        }
                    })
                    .catch(error => {console.log(error);}
                    );

            },
            calcBddprDt(dt){
                var targetDt = this.$moment(dt);
                var bddprDate = 3;

                while(bddprDate != 0){
                    targetDt = targetDt.add(1,'days');
                    if(this.isHolidayCheck(targetDt)){
                        continue;
                    }
                    bddprDate--;
                }
                return targetDt.format('YYYY-MM-DD');
            },
            //공휴일 정보
            getHolidayMngmtList(){

                var param = {
                    "startDt" : this.$moment().format('YYYY-MM-DD'),
                    "endDt" : this.$moment().add(90, 'days') .format('YYYY-MM-DD'),
                }
                this.$post('/api/holidayMngmt/list',param)
                    .then(res => {
                        this.holidayMngmtList = res.holidayMngmtList;
                    })
                    .catch(error => {console.log(error);}
                    );

            },
            //맞춤공고 정보
            getNotiSuggestList(){


                var param = {
                    "pageIndex": this.page,
                    "pageUnit": this.pageUnit,
                    "custBizId" : this.loginInfo.custBizList[0].id,
                    "strtRecordIntex": this.pageUnit * this.page,
                    "orderBySt": "bddprFinDt",
                    "importSuggestYn" : "N",
                    "searchDate":this.$moment().format('YYYY-MM-DD HH:mm'),
                    "facilityYn": this.facilityYn ? 'Y' : 'N',
                    "wnctyYn": this.wnctyYn ? 'Y' : 'N',
                    "findText": this.searchString,
                    "hideYn": this.isHideYn ? 'Y' : 'N',
                };

                this.$parent.isLoadingBar(true);
                this.isProcessing = true;
                this.$post('/api/noti/notiSuggestList',param)
                    .then(res => {
                        var tempNotiSuggestList = res.notiSuggestList;
                        this.notiSugguestListCnt =  res.notiSugguestListCnt;
                        const today = this.$moment();

                        for (var i in tempNotiSuggestList) {
                            var regstFinDt= this.$moment(tempNotiSuggestList[i].regstFinDt);
                            var bddprFinDt= this.$moment(tempNotiSuggestList[i].bddprFinDt);

                            tempNotiSuggestList[i].regstFinDtText = regstFinDt.format('MM/DD(ddd) HH:mm');
                            tempNotiSuggestList[i].bddprFinDtText = bddprFinDt.format('MM/DD(ddd) HH:mm');

                            //오늘 날짜 여부
                            if(regstFinDt.format('YYYY-MM-DD') == today.format('YYYY-MM-DD') && regstFinDt.isAfter(today) ) {
                                tempNotiSuggestList[i].isRegDt = true;
                            } else {
                                tempNotiSuggestList[i].isRegDt = false;
                            }
                            if(bddprFinDt.format('YYYY-MM-DD') == today.format('YYYY-MM-DD') && bddprFinDt.isAfter(today)) {
                                tempNotiSuggestList[i].isBddprDt = true;
                            } else {
                                tempNotiSuggestList[i].isBddprDt = false;
                            }

                            this.notiSuggestList.push(tempNotiSuggestList[i]);
                        }

                        this.refactSuggestNotiList();
                        this.$parent.isLoadingBar(false);
                        this.isProcessing = false;
                    })
                    .catch(error => {
                        console.log(error);
                        this.$parent.isLoadingBar(false);
                        this.isProcessing = false;
                    }
                    );

            },
            /** 추천공고 그룹화*/
            refactSuggestNotiList() {
                this.suggestNotiGroup = {};
                this.notiSuggestList.forEach(item => {
                    // if (item.notiNm.toString().indexOf(this.anlsFindText) != -1 || item.notiNo.toString().indexOf(this.anlsFindText) != -1) {
                        const category = this.$moment(item.bddprFinDt).format('YYYY-MM-DD');

                        if (!this.suggestNotiGroup[category]) {
                            this.suggestNotiGroup[category] = []; // Initialize an array for the category
                        }
                        this.suggestNotiGroup[category].push(item);
                    // }
                });

            },
            //날짜 계산
            calsNextDate(date){
                date --;
                var targetDt = this.$moment();
                this.startDt = this.$moment().format('YYYY-MM-DD');

                while(date != 0){
                    targetDt = targetDt.add(1,'days');
                    if(this.isHolidayCheck(targetDt)){
                        continue;
                    }
                    date--;
                }
                this.endDt = targetDt.format('YYYY-MM-DD');
                //holidayMngmtList
            },
            //날짜 계산
            calsBddprNextDate(date){
                date --;
                var targetDt = this.$moment();
                var bddprDate = 3;

                while(bddprDate != 0){
                    targetDt = targetDt.add(1,'days');
                    if(this.isHolidayCheck(targetDt)){
                        continue;
                    }
                    bddprDate--;
                }

                this.bddprStartDt = targetDt.format('YYYY-MM-DD');

                while(date != 0){
                    targetDt = targetDt.add(1,'days');
                    if(this.isHolidayCheck(targetDt)){
                        continue;
                    }
                    date--;
                }
                this.bddprEndDt = targetDt.format('YYYY-MM-DD');
                //holidayMngmtList
            },
            isHolidayCheck(targetDt){
                var index = this.holidayMngmtList.findIndex(item => item.holiday ===  targetDt.format('YYYY-MM-DD'));

                var dayOfWeek = targetDt.weekday();

                if(index != -1 || dayOfWeek == 0 || dayOfWeek == 6) {
                    return true;
                } else {
                    return false;
                }

            },
            //자동분석 저장
            insertAutoAnlsConfig(autoAnls) {
                if(autoAnls && this.selAnlstList.length == 0 ){
                    this.$toast.error('한 개 이상의 분석가 선택이 필요합니다.');
                    return;
                }
                if(this.isProcessing){
                    return;
                }

                this.isProcessing = true;


                var param = {
                    "custBizId": this.loginInfo.custBizList[0].id,
                    "anlsPeriod": this.anlsPeriod.toString(),
                    "facilityYn" : this.selfacilityYn ? 'Y' : 'N',
                    "wnctyYn" : this.selwnctyYn ? 'Y' : 'N',
                    "anlsStartDt": this.startDt,
                    "anlsEndDt": this.endDt,
                    "autoAnlsStatus" : autoAnls ? 'Y' : 'N',
                    "notiAnlstList": this.selAnlstList,
                };


                this.$post('/api/autoAnlsConfig/insert', param)
                    .then(res => {
                        this.isProcessing = false;
                        if(res.code == 'OK'){
                            if(autoAnls && !this.isAutoAnls){
                                //자동분석 수정
                                this.isAutoAnls = true;
                                this.$toast('자동분석이 켜졌습니다.\n분석실에서 분석제시가를 확인해 주세요.');
                            }else if (autoAnls && this.isAutoAnls){
                                this.isAutoAnls = true;
                                this.$toast('자동분석 설정이 변경되었습니다');
                            }else{
                                this.isAutoAnls = false
                                this.$toast('자동분석이 꺼졌습니다.\n지금부터 자동 분석 요청이 중단됩니다.');
                            }
                            //자동분석 설정 리셋
                            this.selAnlstList = [];
                            this.isPopSetAutoAnls = false;
                            //this.getAutoAnlsConfigList();
                            //this.reloadNotiSuggestList();

                            this.getCustInfo2();
                        } else {
                            this.$toast(res.msg);
                        }
                    })
                    .catch(error => {
                        console.log(error);
                        this.isProcessing = false;
                    }
                    );

            },
            //분석실로 이동
            insertCustNoti(notiId) {
                if(this.selAnlst == ''){
                    this.$toast.error('분석가를 선택하세요.');
                    return;
                }
                if(this.isProcessing){
                    return;
                }

                this.isProcessing = true;



                var param = {
                    "custNotiIdList": [notiId],
                    "custNotiInputCd": "0",
                    "notiAnlstId": this.selAnlst,
                    "custBizId" : this.loginInfo.custBizList[0].id,
                };
                this.$post('/api/noti/insertCustNoti',param)
                    .then(res => {
                        this.isProcessing = false;
                        if(res.code == 'OK'){
                            this.isPopSelectAnlst = false;
                            this.isPopSuccessCustNoti = true;

                            var idx =  this.notiSuggestList.findIndex((e) => e.id == notiId);
                            this.notiSuggestList.splice(idx,1);
                            this.notiSugguestListCnt--;
                            this.refactSuggestNotiList()
                            // this.reloadNotiSuggestList();
                        } else {
                            this.$toast.error(res.msg);
                        }
                    })
                    .catch(error => {
                        console.log(error);
                        this.isProcessing = false;
                    }
                    );

            },

            dateToString(value){
                // 들어오는 value 값이 공백이면 그냥 공백으로 돌려줌
                if(value == '') return '';
                if(value == 'NaN-NaN-NaN') return '';

                // 현재 Date 혹은 DateTime 데이터를 javaScript date 타입화
                var js_date = new Date(value);

                // 연도, 월, 일 추출
                var year = js_date.getFullYear();
                var month = js_date.getMonth() + 1;
                var day = js_date.getDate();

                // 월, 일의 경우 한자리 수 값이 있기 때문에 공백에 0 처리
                if(month < 10){
                    month = '0' + month;
                }

                if(day < 10){
                    day = '0' + day;
                }
                if(year == '1970' && month == '01' && day == '01'){
                    return '';
                }

                // 최종 포맷 (ex - '2021-10-08')
                return year + '-' + month + '-' + day;
            },

            //마지막 면허가 아니면 다음 면허로 이동
            checkLastLicns() {

                if(this.modifySelectLicns.id == this.modifyCustLicnsList[this.modifyCustLicnsList.length-1].id) {
                    this.insertFoceCustBiz();
                } else {
                    var index = this.modifyCustLicnsList.findIndex((element) => element.id == this.modifySelectLicns.id);

                    this.licnsChange(this.modifyCustLicnsList[index+1]);
                }
            },

            async insertFoceCustBiz() {
                if(this.validateCustBiz()){
                    await this.insertCustBiz();

                    this.isPopForceSetMyInfoStep3 = false;
                    this.isPopForceSetMyInfoStep4 = true;
                }
            },
            async modifyCustBiz() {

                if (this.validateCustBiz()) {
                    await this.insertCustBiz();
                    this.isPopSetMyInfo = false;
                }
                // this.insertCustBiz();
            },
            validateCustBiz(){
                if(this.selGrd != ''){
                    if(this.selOrgnz == '' || this.cdltStDe == '' || this.dateToString(this.cdltStDe) == ''){
                        this.$toast.error('신용평가 항목을 전부 선택해 주세요.');
                        return false;
                    }
                } else if(this.debtRate == null || this.debtRate.toString() == ''){
                    this.$toast.error('부채비율을 입력해 주세요.');
                    return false;
                } else if(this.crasRate == null ||this.crasRate.toString() == ''){
                    this.$toast.error('유동비율을 입력해 주세요.');
                    return false;
                }
                return true;
            },

            //사용자 정보 저장
            async insertCustBiz(){


                var param = {
                    "id": this.loginInfo.custBizList[0].id,
                    "custId": this.loginInfo.id,
                    "cmpNm": this.loginInfo.custBizList[0]['cmpNm'].toString(),
                    "cdltStCd": "0",
                    "cdltStId": this.selGrd,
                    "cdltStIssOrgnz": this.selOrgnz,
                    "cdltStDe": this.dateToString(this.cdltStDe) != '' ? this.dateToString(this.cdltStDe) : null,
                    "crasRate": this.crasRate,
                    "debtRate": this.debtRate,
                    "femaleCoYn": "N"
                };

                await this.$post('/api/cust/saveManagementStatus',param)
                    .then(async res => {
                        if (res.code == 'OK') {
                            await this.insertCustLicns();
                        }
                    })
                    .catch(error => {console.log(error);}
                    );

            },
            //
            reloadCustInfo(){
                this.getCustInfo();
                this.getCustLicnsList();
                this.reloadNotiSuggestList();
            },
            //면허 저장
            async insertCustLicns(){


                var bsnPerdCd = "";
                var bsnPerdDeText = "";

                var today = this.$moment();
                var bsnRegstDe = this.dateToString(this.bsnRegstDe) != '' ? this.dateToString(this.bsnRegstDe) : '';
                var selectDay = this.$moment(bsnRegstDe);
                var year = today.diff(selectDay,'years');

                if (year >= 5) {
                    bsnPerdCd = '3';
                    bsnPerdDeText = '5년이상';
                } else if (year >= 3) {
                    bsnPerdCd = '2';
                    bsnPerdDeText = '3년~5년';
                } else if (year >= 1) {
                    bsnPerdCd = '1';
                    bsnPerdDeText = '1년~3년';
                } else {
                    bsnPerdCd = '0';
                    bsnPerdDeText = '1년미만';
                }

                var index = this.modifyCustLicnsList.findIndex((element) => element == this.modifySelectLicns);

                this.modifyCustLicnsList[index].y3AcrsltAmt = this.y3AcrsltAmt;
                this.modifyCustLicnsList[index].y5AcrsltAmt = this.y5AcrsltAmt;
                this.modifyCustLicnsList[index].cswkCapaEvltnAmt =  this.cswkCapaEvltnAmt;
                this.modifyCustLicnsList[index].lcettSuplmtAcrsltAmt = this.lcettSuplmtAcrsltAmt;
                this.modifyCustLicnsList[index].bsnPerdCd = bsnPerdCd;
                this.modifyCustLicnsList[index].bsnRegstDe = bsnRegstDe;
                this.modifyCustLicnsList[index].bsnPerdDeText = bsnPerdDeText;


                var param = {
                    "custBizId": this.loginInfo.custBizList[0].id,
                    "list": this.modifyCustLicnsList
                };

                await this.$post('/api/cust/saveCustLicns',param)
                    .then(res => {
                        if(res.code == 'OK'){
                            this.$toast.success('내 경영/면허정보가 수정되었습니다. ');
                            //내 경영 정보
                            this.getCustInfo();
                            this.getCustLicnsList();
                            this.reloadNotiSuggestList();
                        }else{
                            this.$toast.error('내 경영/면허정보 업데이트에 실패했습니다.');
                        }

                    })
                    .catch(error => {console.log(error);}
                    );

            },
            initSlick() {

            },
            async loadData() {
                // 내 면허정보
                await this.getCustLicnsList();
                this.initSlick();
            },
            //공고 재로드
            reloadNotiSuggestList(){
                this.notiSuggestList=  [];
                this.notiSugguestListCnt = 0;
                this.page = 0;
                this.searchString = '';
                this.getNotiSuggestList();
            },
            //쿠키설정
            setIsPopSuggestNotiCookie(){
                const today = this.$moment().format('YYYY-MM-DD');

                this.$cookies.set("isPopSuggestNotiCookie",today);
                this.isPopSuggestNotiDesc = false;
            },
            //전국 제외 클릭
            clickFacilityYn() {
                this.facilityYn = !this.facilityYn;
                // this.$cookies.set("facilityYn",this.facilityYn);
                this.insertCustNotiFilter();
                this.searchKeyword();
            },
            //용역,물품 제외 여부 클릭
            clickWnctyYn() {
                this.wnctyYn = !this.wnctyYn;
                // this.$cookies.set("wnctyYn",this.wnctyYn);
                this.insertCustNotiFilter();
                this.searchKeyword();

            },
            //필터 저장
            insertCustNotiFilter(){
                var param = {
                    "custBizId" : this.loginInfo.custBizList[0].id,
                    "facilityYn": this.facilityYn ? 'Y' : 'N',
                    "wnctyYn": this.wnctyYn ? 'Y' : 'N',
                };

                this.$post('/api/custNotiFilter/insert',param)
                    .then(() => {
                    })
                    .catch(error => {
                            console.log(error);
                        }
                    );
            },
            //필터 저장정보 가져오기
            getCustNotiFilter(){
                var param = {
                    "custBizId" : this.loginInfo.custBizList[0].id,
                    "facilityYn": this.facilityYn ? 'Y' : 'N',
                    "wnctyYn": this.wnctyYn ? 'Y' : 'N',
                };

                this.$post('/api/custNotiFilter/select',param)
                    .then(res => {
                        if(res.code=="OK" && res.custNotiFilter != null){
                            this.facilityYn = res.custNotiFilter.facilityYn == 'Y' ? true: false;
                            this.wnctyYn = res.custNotiFilter.wnctyYn == 'Y' ? true: false;
                        }
                        //맞춤공고 정보
                        this.getNotiSuggestList();
                    })
                    .catch(error => {
                            console.log(error);
                        }
                    );
            }

        },
        created() {

        },

        mounted() {
            this.loginInfo = JSON.parse(localStorage.getItem("custInfo"));

            this.deviceType  = this.$getDeviceType();
            /* 맞춤공고 3일간 닫기 여부 확인 */
            // const isPopSuggestNotiCookie = this.$cookies.get('isPopSuggestNotiCookie');

            // if(isPopSuggestNotiCookie != null && this.$moment(isPopSuggestNotiCookie).diff(this.$moment(),"days") > -3) {
            //     this.isPopSuggestNotiDesc = false;
            // } else {
            //     this.isPopSuggestNotiDesc = true;
            // }

            //쿠키
            // var facilityYnCookie = this.$cookies.get("facilityYn");
            // var wnctyYnCookie = this.$cookies.get("wnctyYn");
            var accordionActiveCookie = this.$cookies.get("accordionActive");

            // if(facilityYnCookie != null){
            //     this.facilityYn = facilityYnCookie == 'true' ? true: false;
            // }
            // if(wnctyYnCookie != null){
            //     this.wnctyYn = wnctyYnCookie == 'true' ? true: false;
            // }
            if(accordionActiveCookie != null) {
                this.accordionActive = accordionActiveCookie == 'true' ? true: false;
            }

            //내 경영 정보
            this.getCustInfo();

            if(this.loginInfo != null) {
                //필터 정보 가져오기
                this.getCustNotiFilter();

                //자동분석 정보
                this.getAutoAnlsConfigList();

                //계약서 정보 로드
                this.getCustCntrct();

                //면허정보 로드
                this.getCustLicnsList();

                //불이행 정보
                this.getFulfilmentMngt();

                //수수료 정보
                this.getMyAnlsFee();
            }
            //툴팁 목록
            this.getTooltipList();

            //분석가 목록
            this.getNotiAnlst();

            //공휴일 정보
            this.getHolidayMngmtList();

            //신용평가 기준 목록
            this.getCdltSdList();

            //발급기관 목록
            this.getCdltStOrgnz();

            const btnCancel = document.querySelector('.btn__cancel');
            const searchInput = btnCancel.previousElementSibling; //input

            searchInput.addEventListener('focus', () => { // 검색어창 포커스 on
                searchInput.parentElement.classList.add('active');
            });

            searchInput.addEventListener('blur', () => { // 검색어창 포커스 off
                searchInput.parentElement.classList.remove('active');
            });
            searchInput.addEventListener('keyup', () => { // 검색어 입력시 취소버튼 나타나기
                const word = searchInput.value.length;
                if (word > 0) {
                    btnCancel.style.opacity = 1;
                } else {
                    btnCancel.style.opacity = 0;
                }
            });

            btnCancel.addEventListener('click', function () { //텍스트 초기화
                btnCancel.previousElementSibling.value = '';
                btnCancel.style.opacity = 0;
            });
        }
    }
</script>