<template>
<div>
  개인정보 이관 페이지
  안녕하세요, 주식회사(주)지니입니다.
  우선 저희 비드톡톡을 사랑해 주시는 고객님께 깊은 감사의 말씀을 드립니다.

  내용~~~~~~~~~~~~
</div>
</template>

<script>
  export default {
    name: 'TrfPerInfo',
    data() {
      return {
        message3: ''
      }
    },
    methods: {
    }
  };
</script>

<!--<style src="./TrfPerInfo.scss" scoped lang="scss"></style>-->
