<template>
    <div style="text-align: center">
        <a href="https://bidtok.co.kr" class="link__download main__visual--link pc" style="width: 30%; margin: 1.25rem auto;">비드톡톡 바로가기</a>
        <div class="">
            <img src="@/assets/survey/leaflet3.jpg" style="width: 80%; height: auto; margin: 0 auto 2rem;" alt="리플렛">
            <img src="@/assets/survey/leaflet4.jpg" style="width: 80%; height: auto;  margin: 0 auto 2rem;;" alt="리플렛">
        </div>
    </div>
</template>
<script>
    export default {
        name: "g2bNoti",
        metaInfo: {
            title: '[비드톡톡] 입찰정보 개찰정보 낙찰정보 공고 낙찰 - 나라장터(g2b) 조달청 전자입찰',
            link: [{ rel: "canonical", href: 'https://www.bidtok.co.kr/' }],
            meta: [
                { vmid: "description",name: 'description', content: '나라장터(g2b), 공고, 입찰공고, 입찰, 개찰, 낙찰, 입찰가격, 투찰가격, 입찰분석, 가격분석,  맞춤공고, 경영실적, 면허실적, 전자입찰, bid 모든 정보를 비드톡톡에서 쉽고 빠르게 확인하고 낙찰 받으세요.' },
                { vmid: "og:title",property: 'og:title', content: '[비드톡톡] 입찰정보 개찰정보 낙찰정보 공고 낙찰 - 나라장터(g2b) 조달청 전자입찰' },
                { vmid: "og:url",property: 'og:url', content: 'https://www.bidtok.co.kr/' },
                { vmid: "og:description",property: 'og:description', content: '나라장터(g2b), 공고, 입찰공고, 입찰, 개찰, 낙찰, 입찰가격, 투찰가격, 입찰분석, 가격분석,  맞춤공고, 경영실적, 면허실적, 전자입찰, bid 모든 정보를 비드톡톡에서 쉽고 빠르게 확인하고 낙찰 받으세요.' },
                { vmid: "og:image",property: "og:image" , content : "favicon.ico"},
            ]
        },
        data() {
            return {
            }
        },
        methods: {
        }
    };
</script>