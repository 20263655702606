export default {
    notiStatusList: [],
    rpsntOrgnzList: [],
    prvnclList: [],
    areaCodeList: [],
    selPrvnclList: [],

    licnsCodeList: [],
    selPrvncl: "",

    searchParam: {
        searchText: '',
        findNotiStatList: [],
        notiSearchAreaCdList: [],
        notiSearchLicnsCdList: [],
        findStrtAmt: null,
        findEndAmt: null,
        findRpsntOrgnzList: [],
        findStrtDt: '',
        findEndDt: '',
    },

    isSearching: false,
    notiSearchList: [],
    notiSearchListCnt : 0,
}
