export default{
    chart: {
        chartOptions: {
            chart: {
              id: "basic-bar"
            },
            xaxis: {
                type:'datetime',
                categories : []
            }
          },
          series: [{
            name: 'COUNT',
            data: []
          }]
    },
    distributedColumnChart: {
      series: [{
        name:'COUNT',
        data: []
      }],
      chartOptions: {
        chart: {
          type: 'bar'
        },
        //colors: colors,
        plotOptions: {
          bar: {
            columnWidth: '45%',
            distributed: true,
          }
        },
        dataLabels: {
          enabled: false
        },
        legend: {
          show: false
        },
        xaxis: {
          categories: [],
          labels: {
            style: {
              fontSize: '10px'
            }
          }
        }
      }
    },
    table: [
      {
        id: 9498145,
        name: 'exProdResltColctJob',
        start_time: '2022-03-08 06:59:50.000',
        status: 'FAILED',
      },
      {
        id: 9490303,
        name: 'g2bFacNotiColctJob',
        start_time: '2022-03-08 06:59:50.000',
        status: 'FAILED',
      },
      {
        id: 9489558,
        name: 'khnpV2FacResltColctJob',
        start_time: '2022-03-04 10:04:30.000',
        status: 'FAILED',
      },
      {
        id: 9453202,
        name: 'g2bSrvcNotiColctJob24',
        start_time: '2022-03-07 06:59:50.000',
        status: 'NULL',
      },
      {
        id: 9453202,
        name: 'd2bFacCmptNotiColctJob',
        start_time: '2022-03-04 13:49:40.000',
        status: 'NULL',
      },
    ]
}