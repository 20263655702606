<template>
    <div class="analysis__container help-home__container--result">
        <!-- 개찰결과 > 공고 카운트 및 필터 container -->
        <div class="anno-count__container">
            <div class="anno-count__item--num">
                <span class="total">총 </span><span class="empha">{{notiOpbdCnt}}</span><span class="txt"> 건</span>
            </div>
            <div class="result-filter__group help-resul--filter">
                <button class="result-filter--check help-resul--check" :class="lastSbidYn ? 'active' : ''" @click="lastSbidYn = !lastSbidYn; initOpbdNotiList()">낙찰 공고만 보기</button>
                <button class="result-filter--check help-resul--check" :class="bidTokNotiYn ? 'active' : ''" @click="bidTokNotiYn = !bidTokNotiYn; initOpbdNotiList()">비드톡톡 분석 요청 공고만 보기</button>
            </div>
        </div>
        <!-- 개찰결과 > 공고 카운트 및 필터 container -->

        <div class="layout__half announce" v-for="items in opbdNotiGroup" v-bind:key="items.id">
            <!-- 날짜, 건수 표기 -->
            <ul class="announce__date help-date">
                <li class="announce__date--mmdd help-date--mmdd">
                    <h3 class="txt__title">{{ $moment(items[0].bddprFinDt).format('MM월 DD일 (ddd)') }}</h3>
                </li>
                <li class="announce__date--count help-date--count">
                    <p>{{items.length}}</p><span>건</span>
                </li>
            </ul>
            <!-- /날짜, 건수 표기 -->

            <!-- 분석공고 있을 경우 -->
            <div v-for="(item) in items" v-bind:key="item.id" :class="(item.anlstNickNm == '' ? 'other-bid' : '') + (item.myCmpRank == 1 ? ' success' : '')">>
                <!-- 분석실 - 개찰결과 리스트 - 개인투찰 경우 (비톡 분석x) -->
                <div class="layout__item announce--item" :class="item.anlstNickNm == '' ? 'other-bid' : ''">
                    <div class="announce__group announce__analyst">
                        <ul class="analyst__box">
                            <li class="analyst__box--item">
                                <span class="txt--title">{{item.anlstNickNm}}</span>
                                <span class="dividing-line"></span>
                                <span class="txt--sub">{{item.anlstType == 'AI' ? 'AI' : '기업'}}</span>
                            </li>
                            <li class="analyst__box--item">
                                <span class="txt--sub strong">분석제시가</span>
                                <span class="txt--title strong" v-show="item.anlstNickNm == ''">미요청</span>
                                <span class="txt--title strong" v-show="item.anlstNickNm != '' && item.bddprAmtConfDt == null">미열람</span>
                                <span class="txt--title strong" v-show="item.anlstNickNm != '' && item.bddprAmtConfDt != null">{{item.anlsBddprAmt | currency}}</span>
                            </li>
                        </ul>
                        <div class="analyst__box company-logo">
                            <img :src="item.iconImg" alt="분석기업 로고 이미지">
                        </div>
                    </div>
                    <a class="announce__group announce--item--move-detail">
                        <p class="txt__title"><span
                                class="condition-badge__item application"><span></span></span>
                            {{item.notiNm}}</p>
                        <div class="viw-all">
                            <p class="txt__title">{{item.notiNm}} {{item.anlstNickNm}}</p>
                        </div>
                        <div class="announce__copy">
                            <div class="announce__copy--item">
                                <span class="txt__sub">{{item.notiNo}}{{item.notiSeq != null ? ('-' + item.notiSeq) : ''}}</span>
                                <button type="button" class="btn__copy" @click="copyNotiNo(item.notiNo)"></button>
                            </div>
                        </div>
                        <ul class="announce__info result-info" :class="item.myCmpRank == 1 ? 'success-info' : ''">
                            <li class="announce__info--list"><span class="txt__title">낙찰 하한가</span><span
                                    class="txt__sub">{{item.opbdPrargPrice | currency}}</span></li>
                            <li class="announce__info--list"><span class="txt__title">내 투찰 금액</span><span
                                    class="txt__sub">{{item.bddprAmt | currency}}</span></li>
                            <li class="announce__info--list result-info--rank" :class="item.myCmpRank == 1 ? 'success-info--rank win' : 'result-info'" v-if="item.opbdStatCd == '2' || item.opbdStatCd == '5'">
                                <p class="txt txt--title" :class="item.myCmpRank == null ? 'not-fulfil' : ''">{{item.myCmpRank == null ? '미투찰' : (item.myCmpRank == 1 ? '' : (item.myCmpRank +
                                    '위'))}}</p>
                                <p class="txt txt--sub">{{item.bddprPrtcCmpCnt }}개 업체중</p>
                            </li>
                            <li class="announce__info--list result-info--rank success-info--rank wait"  v-if="item.opbdStatCd != '2' && item.opbdStatCd != '5'">
                                <p class="txt txt--title"></p>
                                <p class="txt txt--sub">개찰 대기중</p>
                            </li>
                        </ul>
                    </a>
                </div>
                <!-- /분석실 - 개찰결과 리스트 - 개인투찰 경우 (비톡 분석x) -->
            </div>
            <!-- /분석공고 있을 경우 -->
        </div>
        <!-- 개찰결과 컨테이너 -->

    </div>
</template>
<script>

    export default {
        data() {
            return {
                /* 개찰결과 변수*/
                notiOpbdList: [], //개찰결과목록
                notiOpbdCnt: 0,
                opbdNotiGroup: [],  //개찰공고 그룹

                notiAnlstList: [],


                page: 0,    //현재 페이지
                pageUnit: 9999,   //페이지당 숫자
                strtDate: this.$moment().subtract(1, 'months').format('YYYY.MM.DD'),    //개찰결과 검색 시작일
                finDate: this.$moment().format('YYYY.MM.DD'),    //개찰결과 검색 종료일
                notiAnlstId: '',    //분석가ID

                licnsCd: '',
                areaCd: '',
                prvnclCd: '',
                lastSbidYn: false, //낙찰공고만보기 여부
                bidTokNotiYn: false, //비드톡톡 분석 공고만 보기
                anlsFindText: '',    //검색텍스트
                searchOpbdPeriod: 1, // 날짜 선택자

                isProcessing: false, // API 동작 여부

            }
        },
        methods: {
            copyNotiNo(value) {
                this.$copyText(value);
                this.snackbar = true;
                this.copyText = value;
                this.$stopEventBubble();

                // this.$toast('HelloWorld', { position: "bottom-right" });

                this.$toast.success("공고번호를 복사했습니다.", {
                    position: 'top-center',
                    timeout: 2000,
                    closeOnClick: true,
                    pauseOnHover: true,
                    transitionDuration: 750,
                    draggable: true,
                    draggablePercent: 0.6,
                    showCloseButtonOnHover: false,
                    hideProgressBar: true,
                    closeButton: "button",
                    icon: true
                });

                // alert("공고번호를 복사했습니다.");
            },
            /** 분석가 목록*/
            async getNotiAnlst() {
                this.notiAnlstList = [];

                await this.$http.get('/api/noti/anlstList')
                    .then(res => {
                        var notiAnlstList = res.data.notiAnlstList;
                        this.notiAnlstList = notiAnlstList;
                    })
                    .catch(error => console.log(error));
            },
            /** 개찰공고 검색*/
            searchOpbdNoti(value) {
                if (value != null) {
                    this.anlsFindText = value;
                }
                this.notiOpbdList = [];

                this.getNotiOpbdList();

            },
            /**  개찰공고 초기화*/
            initOpbdNotiList() {
                this.notiOpbdList = [];
                this.page = 0;

                this.getNotiOpbdList();
            },
            /** 개찰결과*/
            getNotiOpbdList() {
                if (this.isProcessing == true) {
                    return;
                }
                this.isProcessing = true;
                var param = {
                    "pageUnit": this.pageUnit,
                    "pageIndex": this.page,
                    "strtRecordIntex": this.page * this.pageUnit,
                    "searchDate": this.dateToString(this.strtDate),
                    "strtDate": this.dateToString(this.strtDate),
                    "finDate": this.dateToString(this.finDate),
                    "custBizId": this.loginInfo.custBizList[0].id,
                    "bizNo": this.loginInfo.custBizList[0].bizNo,
                    "orderBySt": "bddprFinDt",
                    "notiAnlstId": this.notiAnlstId,
                    "lastSbidYn": this.lastSbidYn ? 'Y' : 'N',
                    "bidTokNotiYn": this.bidTokNotiYn ? 'Y' : 'N',
                    "licnsCd": this.licnsCd,
                    "areaCd": this.areaCd,
                    "prvnclCd": this.prvnclCd,
                    // "notiSearchAreaCdList": this.notiSearchAreaCdList,
                    // "areaSearchType": "OR",
                    // "notiSearchLicnsCdList": this.notiSearchLicnsCdList,
                    "findText": this.anlsFindText,
                };

                this.$post('/api/noti/notiOpbdList', param)
                    .then(res => {
                        // this.notiOpbdList = res.notiOpbdList;
                        var notiOpbdList = res.notiOpbdList;
                        this.notiOpbdCnt = res.notiOpbdCnt;

                        /// 분석가 정리
                        for (var i in notiOpbdList) {
                            var index = this.notiAnlstList.findIndex((e) => e.id == notiOpbdList[i].notiAnlstId);

                            if (index != -1) {
                                notiOpbdList[i].iconImg = '/' + this.notiAnlstList[index].iconImg;
                                notiOpbdList[i].anlstNickNm = this.notiAnlstList[index].anlstNickNm;
                                notiOpbdList[i].anlstType = this.notiAnlstList[index].anlstType;
                            } else {
                                notiOpbdList[i].iconImg = '/assets/images/logo_bidtok_other-bid.png';
                                notiOpbdList[i].anlstNickNm = '';
                                notiOpbdList[i].anlstType = '';
                            }
                            this.notiOpbdList.push(notiOpbdList[i]);
                        }

                        this.refactOpbdNotiList();
                        this.isProcessing = false;
                    })
                    .catch(error => {
                        console.log(error);
                        this.isProcessing = false;
                    });
            },
            /** 개찰공고 그룹화*/
            refactOpbdNotiList() {
                this.opbdNotiGroup = {};
                this.notiOpbdList.forEach(item => {
                    if (item.notiNm.toString().indexOf(this.anlsFindText) != -1 || item.notiNo.toString().indexOf(this.anlsFindText) != -1) {
                        const category = this.$moment(item.opbdDt).format('YYYY-MM-DD');

                        if (!this.opbdNotiGroup[category]) {
                            this.opbdNotiGroup[category] = []; // Initialize an array for the category
                        }
                        this.opbdNotiGroup[category].push(item);
                    }
                });
            },
            dateToString(value) {
                // 들어오는 value 값이 공백이면 그냥 공백으로 돌려줌
                if (value == '') return '';

                // 현재 Date 혹은 DateTime 데이터를 javaScript date 타입화
                var js_date = new Date(value);

                // 연도, 월, 일 추출
                var year = js_date.getFullYear();
                var month = js_date.getMonth() + 1;
                var day = js_date.getDate();

                // 월, 일의 경우 한자리 수 값이 있기 때문에 공백에 0 처리
                if (month < 10) {
                    month = '0' + month;
                }

                if (day < 10) {
                    day = '0' + day;
                }
                if (year == '1970' && month == '01' && day == '01') {
                    return '';
                }

                // 최종 포맷 (ex - '20211008')
                return year + '' + month + '' + day;
            },
        },
        async mounted() {
            this.loginInfo = JSON.parse(localStorage.getItem("custInfo"));

            if (this.loginInfo != null) {
                //분석가 목록
                await this.getNotiAnlst();

                //개찰결과 목록
                this.initOpbdNotiList();
            }
        }
    }


</script>
<style src="../../../../assets/scss/common/bid-helper.scss" lang="scss" scoped/>