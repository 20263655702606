<template>
    <div class="wrap layout__half--wrap layout__veiw--wrap request">
        <div class="layout__half--container vertical--top layout__veiw--group title">
            <h2 class="menu__title">문의하기</h2>
        </div>
        <div class="layout__half--container vertical--top layout__veiw--group cont">
            <div class="request__group title">
                <p class="request--title">제목</p>
                <div class="request--cont select-box request--sel-box">
                    <button class="select-box__sel" id="custCenterSelectBox">면허변경</button>
                    <ul class="select-box__list" id="custCenterSelectBoxItem">
                        <li><button type="button">문의 유형을 선택하세요.</button></li>
                        <li  v-for="custCenterCd in custCenterCdList" v-bind:key="custCenterCd.id"  :value="custCenterCd.comnCd"><button type="button" :value="custCenterCd.comnCd">{{custCenterCd.comnCdNm}}</button></li>
                    </ul>
                </div>
            </div>
            <div class="request__group txtarea">
                <p class="request--title">내용</p>
                <textarea class="request--cont request--txtarea" v-model="content">
                </textarea>
            </div>
            <div class="request__group file-attach">
                <p class="txt">사진첨부 {{fileUpdate}}</p>
                <div class="file-attach__box" v-for="(file , index) in fileList"  v-bind:key="file.id" >
                    <input class="upload-name" v-model="file.name" placeholder="첨부파일" disabled>
                    <label :for="'file' + index">파일찾기</label>
                    <input type="file" :id="'file' + index" @change="getFileName($event.target.files , index)">
                    <button class="file-attach--del" @click="fileDelete(index)"><i class="ic_close"></i></button>
                </div>
                <button class="file-attach--add" @click="addFile()" v-show="fileList.length < 5"></button>
                <p class="txt--help">*면허의 추가/변경의 경우, 면허 등록증 또는 경영상태 등의 확인서 등 보유한 면허가 표기된 자료 사진을 첨부해 주세요.</p>
            </div>
            <div class="request__group email">
                <p class="request--title">이메일</p>
                <div class="input request--cont">
                    <input type="text" placeholder="이메일을 입력해 주세요." v-model="email">
                </div>
            </div>
            <div class="request__group phone">
                <p class="request--title">휴대폰 번호</p>
                <div class="input request--cont">
                    <input type="text" placeholder="휴대폰 번호를 입력해 주세요." v-mask="['###-###-####','###-####-####']" v-model="phoneNo">
                </div>
            </div>
            <div class="request__group agree">
                <div class="input--check request--check">
                    <input type="checkbox" id="checkCounsel" v-model="isTermsChecked">
                    <label for="checkCounsel"></label>
                </div>
                <span class="txt"><span class="empha">(필수) </span>개인정보 수집 동의</span>
                <p class="txt noti">- 수집하는 개인정보(이메일 주소, 휴대폰번호, 로그인계정, 문의 내용)은 문의 접수 및 고객 불만 해결을 위해 일정기간 보관됩니다. (상담이력 5년)</p>
            </div>
        </div>
        <div class="layout__veiw--list request--btn">
            <button type="button" class="btn-basic btn-line-cw" @click="$router.go(-1)"><span>취소</span></button>
            <button type="button" class="btn-basic btn-cta" @click="insertCenter()" :class="isProcessing ? 'loading-box' : ''" ><span> <LoadingSpinner v-show="isProcessing"></LoadingSpinner>{{isProcessing ? 'ㅤ' : '문의'}}</span></button>
        </div>
    </div>
</template>

<script>
    import mock from './mock';
    // import * as _ from 'lodash'
    import LoadingSpinner from '@/components/common/LoadingSpinner.vue';

    export default {
        name: "CounselBoard",
        metaInfo: {
            title: '[비드톡톡 공지사항] - 전자입찰 나라장터(g2b) 공고 투찰 낙찰',
            link: [{ rel: "canonical", href: 'https://www.bidtok.co.kr/' }],
            meta: [
                { vmid: "description", name: 'description', content: '비드톡톡에 관한 공지사항을 확인해 보세요! 비드톡톡의 업데이트 소식, 이벤트 소식, 새로운 기능의 소개, 나라장터 소식 등 비드톡톡의 중요한 공지사항을 확인하실 수 있습니다.' },
                { vmid: "og:title", property: 'og:title', content: '[비드톡톡 공지사항] - 전자입찰 나라장터(g2b) 공고 투찰 낙찰' },
                { vmid: "og:url", property: 'og:url', content: 'https://www.bidtok.co.kr/' },
                { vmid: "og:description", property: 'og:description', content: '비드톡톡에 관한 공지사항을 확인해 보세요! 비드톡톡의 업데이트 소식, 이벤트 소식, 새로운 기능의 소개, 나라장터 소식 등 비드톡톡의 중요한 공지사항을 확인하실 수 있습니다.' },
                { vmid: "og:image", property: "og:image" , content : "favicon.ico"},
            ]
        },
        data(){
            return{
                mock,
                loginInfo: {},
                tabNo: 0,
                custCenterCdList: [],   //1:1문의 타입코드

                custCenterCd: '',  //종류
                title: '',  //제목
                content: '',    //내용
                email: '',      //이메일
                phoneNo: '',    //핸드폰번호
                fileGrpUuid: '',    //파일그룹아이디
                fileList: [{}],   //파일 목록

                isTermsChecked: false, //개인정보 수집 동의

                isProcessing: false,    //저장중 체크
                fileUpdate: '', //파일 업데이트

            }
        },
        components: {
            LoadingSpinner
        },
        watch: {
        },
        methods: {
            fileDelete(index) {
                this.fileList.splice(index,1);
            },
            addFile() {
                this.fileList.push({});
            },
            getFileName(files, index) {
                this.fileList[index] = files[0];
                if(this.fileUpdate == '') {
                    this.fileUpdate = ' ';
                } else {
                    this.fileUpdate = '';
                }
                // this.base64( this.fileList[index]);
                // this.isTermsChecked = !this.isTermsChecked;
            },
            base64(file) {
                // 비동기적으로 동작하기 위하여 promise를 return 해준다.
                return new Promise(resolve => {
                    // 업로드된 파일을 읽기 위한 FileReader() 객체 생성
                    let a = new FileReader()
                    // 읽기 동작이 성공적으로 완료됐을 때 발생
                    a.onload = e => {
                        resolve(e.target.result)
                        // 썸네일을 보여주고자 하는 <img>에 id값을 가져와 src에 결과값을 넣어준다.
                        // const previewImage = document.getElementById('preview')
                        // previewImage.src = e.target.result
                    }
                    // file 데이터를 base64로 인코딩한 문자열. 이 문자열을 브라우저가 인식하여 원래 데이터로 만들어준다.
                    a.readAsDataURL(file)
                })
            },
            checkEmail() {
                // 비밀번호 양식 검사
                const validatePassword = /^[A-Za-z0-9_\\.\\-]+@[A-Za-z0-9\\-]+\.[A-Za-z0-9\\-]+/
                const matches = validatePassword.test(this.email);

                // this.phoneValidate = matches;
                return matches;
            },
            validateCustCenter(){
                if (this.title.length == 0) {
                    this.$toast.error('제목을 입력해 주세요.');
                    return false;
                }
                if (this.content.length == 0) {
                    this.$toast.error('내용을 입력해 주세요.');
                    return false;
                }
                if (this.email.length == 0 ) {
                    this.$toast.error('이메일을 입력해 주세요.');
                    return false;
                }
                if (this.email != 0 && !this.checkEmail()) {
                    this.$toast.error('이메일 양식이 일치하지 않습니다.');
                    return false;
                }

                if (this.phoneNo.length == 0) {
                    this.$toast.error('휴대폰 번호를 입력해 주세요.');
                    return false;
                }
                if (this.phoneNo.length != 0) {
                    var regExp = /^010-?([0-9]{4})-?([0-9]{4})$/;
                    var regExp2 = /^011-?([0-9]{3})-?([0-9]{4})$/;
                    var regExp3 = /^011-?([0-9]{4})-?([0-9]{4})$/;
                    if (!regExp.test(this.phoneNo) &&
                        !regExp2.test(this.phoneNo) &&
                        !regExp3.test(this.phoneNo)) {
                        this.$toast.error('휴대폰 번호 양식이 일치하지 않습니다.');
                        return false;
                    }
                }
                if (this.isTermsChecked == false) {
                    this.$toast.error('개인정보 수집에 동의해 주세요.');
                    return false;
                }
                return true;
            },
            insertCenter() {
                if(!this.validateCustCenter()){
                    return;
                }

                var formData = new FormData();
                formData.append("id",'');
                formData.append("custId",this.loginInfo.id);
                formData.append("custCenterCd",this.custCenterCd);
                formData.append("title",this.title);
                formData.append("content",this.content);
                formData.append("email",this.email);
                formData.append("fileGrpUuid", this.fileGrpUuid);
                formData.append("phoneNo",this.phoneNo,);
                formData.append("parentId",'0');
                for(let i=0; i<this.fileList.length; i++) {
                   if(this.fileList[i].name != null){
                       formData.append("fileList", this.fileList[i]);
                   }
                }

                this.isProcessing = true;

                const config = {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        'auth-token': localStorage.getItem("authToken"),
                        'refresh-token': localStorage.getItem("refreshToken"),
                    },
                };

                this.$http.post('/api/custCenter/insCustCenter',formData,config)
                    .then(res => {
                        if(res.data.code == 'OK'){
                            this.$toast.success('1:1문의가 등록되었습니다.');
                            this.$router.go(-1);
                        }
                        this.isProcessing = false;

                    })
                    .catch(error => {
                        this.isProcessing = false;
                            console.log(error);
                        }
                    );

            },
            getNotiStatCd() {
                this.custCenterCdList = [];

                var param = {"comnCdGroupId": "M_CUST_CENTER_CD"};

                this.$post('/api/comnCode/list',param)
                    .then(res => {
                        this.custCenterCdList = res.comnCodeList;

                        this.title = '면허 변경';
                        this.custCenterCd = '6';
                        this.content = "아래의 정보를 입력해 주세요.\n\n1) 면허 변경 종류(추가/삭제/변경): \n\n2) 추가/변경/삭제할 면허명:";

                        setTimeout(() => {
                            this.custCenterSelectBox();
                        }, 1000);
                    })
                    .catch(error => console.log(error));
            },
            custCenterSelectBox() {
                /* 셀렉트박스 */
                const selectBox = document.querySelector('#custCenterSelectBox');
                if (selectBox) {
                    selectBox.addEventListener('click', () => selectBox.classList.toggle('on'));

                    /* 셀렉트박스 활성화 중 다른 곳 클릭시 꺼짐 */
                    document.addEventListener('click', (event) => {
                        const target = event.target;
                        if (!selectBox.contains(target)) {
                            selectBox.classList.remove('on');
                        }
                    });
                    /* /셀렉트박스 활성화 중 다른 곳 클릭시 꺼짐 */
                }

                const selectBoxItem = document.querySelector('#custCenterSelectBoxItem');
                if (selectBoxItem) {
                    selectBoxItem.addEventListener('click', (event) => {
                        selectBox.innerText = event.target.innerText;
                        this.title = event.target.innerText;
                        this.custCenterCd = event.target.value;
                        console.log(this.custCenterCd);
                        selectBox.classList.remove('on');
                    });
                }
            },

        },
        created() {
            this.getNotiStatCd();
        },

        mounted() {
            this.loginInfo =JSON.parse(localStorage.getItem("custInfo"));
        }
    }


</script>



<!--<style src="./TokStory.scss" lang="scss" scoped />-->