<template>
  <div>
    <el-container style="height: 880px; border: 1px solid #eee">
      <el-aside width="200px" style="background-color: rgb(238, 241, 246)">
        <el-menu :default-openeds="['1']">
          <el-submenu index="1">
            <template slot="title"><i class="el-icon-lollipop"></i>투찰 통계</template>
            <el-menu-item-group>
              <template slot="title">투찰 현황</template>
              <el-menu-item index="1-1" @click="goMenu(1)">기초데이터</el-menu-item>
              <el-menu-item index="1-2" @click="goMenu(2)">실공고현황</el-menu-item>
              <el-menu-item index="1-2" @click="goMenu(3)">톡톡리포트</el-menu-item>
            </el-menu-item-group>
<!--            <el-menu-item-group title="Group 2">-->
<!--              <el-menu-item index="1-3">Option 3</el-menu-item>-->
<!--            </el-menu-item-group>-->
<!--            <el-submenu index="1-4">-->
<!--              <template slot="title">Option4</template>-->
<!--              <el-menu-item index="1-4-1">Option 4-1</el-menu-item>-->
<!--            </el-submenu>-->
          </el-submenu>
          <el-submenu index="2">
            <template slot="title"><i class="el-icon-cherry"></i>회원 통계</template>
            <el-menu-item-group>
              <template slot="title">모니터링</template>
              <el-menu-item index="2-1">가입 현황</el-menu-item>
              <el-menu-item index="2-2">매출 현황</el-menu-item>
            </el-menu-item-group>
<!--            <el-menu-item-group title="Group 2">-->
<!--              <el-menu-item index="2-3">Option 3</el-menu-item>-->
<!--            </el-menu-item-group>-->
<!--            <el-submenu index="2-4">-->
<!--              <template slot="title">Option 4</template>-->
<!--              <el-menu-item index="2-4-1">Option 4-1</el-menu-item>-->
<!--            </el-submenu>-->
          </el-submenu>
<!--          <el-submenu index="3">-->
<!--            <template slot="title"><i class="el-icon-setting"></i>3</template>-->
<!--            <el-menu-item-group>-->
<!--              <template slot="title">Group 1</template>-->
<!--              <el-menu-item index="3-1">Option 1</el-menu-item>-->
<!--              <el-menu-item index="3-2">Option 2</el-menu-item>-->
<!--            </el-menu-item-group>-->
<!--            <el-menu-item-group title="Group 2">-->
<!--              <el-menu-item index="3-3">Option 3</el-menu-item>-->
<!--            </el-menu-item-group>-->
<!--            <el-submenu index="3-4">-->
<!--              <template slot="title">Option 4</template>-->
<!--              <el-menu-item index="3-4-1">Option 4-1</el-menu-item>-->
<!--            </el-submenu>-->
<!--          </el-submenu>-->
        </el-menu>
      </el-aside>

      <el-container>
        <el-header style="text-align: right; font-size: 12px">
          <el-dropdown>
            <i class="el-icon-setting" style="margin-right: 15px"></i>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item>View</el-dropdown-item>
              <el-dropdown-item>Add</el-dropdown-item>
              <el-dropdown-item>Delete</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
          <span>관리자</span>
        </el-header>

        <DTR v-show="menu == 3">
        </DTR>

        <DRNS v-show="menu == 2">
        </DRNS>

        <el-main v-show="menu == 1">
          <el-row :gutter="20">
            <el-col :span="3"><el-input placeholder="사업자번호" v-model="bizNo"></el-input></el-col>
            <el-col :span="3.5">
              <el-date-picker
                      v-model="strtDate"
                      type="date"
                      placeholder="시작날짜"
                      format="yyyyMMdd"
              >
              </el-date-picker>
            </el-col>
            <el-col :span="3.5">
              <el-date-picker
                      v-model="finDate"
                      type="date"
                      placeholder="종료날짜"
                      format="yyyyMMdd"
              >
              </el-date-picker>
            </el-col>
            <el-col :span="3"><el-button type="primary" plain @click="doSearch">검색</el-button></el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col align="center" style="font-size:3.5vh" v-loading="loading" :offset=4.5>{{this.cmpNm}}</el-col>
<!--            <el-col :span="8" align="right">(지난 1년 데이터)</el-col>-->
          </el-row>
          <el-row :gutter="20">
            <el-col :span="20">
              <div style="margin-top: 1px" @change="clkLicns">
                <el-checkbox-group v-model="checkboxGroup1" size="medium">
                  <el-checkbox-button v-for="licns in licnsValList" :label="licns" :key="licns">{{licns}}</el-checkbox-button>
                </el-checkbox-group>
              </div>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="16">
                <el-tag size="large" style="font-size:1.5vh">총 건수: {{sum}}</el-tag>
            </el-col>
            <el-col :span="8">
              <el-tag size="large" style="font-size:1.5vh" type="success">평균 경쟁율: {{avgCptnRt}}</el-tag>&nbsp;
              <el-tag size="large" style="font-size:1.5vh" type="success">총 낙찰 건수: {{Number(cmpRankListCnt).toLocaleString()}}</el-tag>&nbsp;
              <el-tag size="large" style="font-size:1.5vh" type="success">낙찰한 공고 금액: {{Number(totalSBidAmt).toLocaleString()}}</el-tag>
            </el-col>
<!--            <el-col :span="1.5"><el-tag size="large" style="font-size:1.5vh">총 낙찰 건수: {{Number(cmpRankListCnt).toLocaleString()}}</el-tag></el-col>-->
<!--            <el-col :span="1.5"><el-tag size="large" style="font-size:1.5vh">낙찰한 공고 금액: {{Number(totalSBidAmt).toLocaleString()}}</el-tag></el-col>-->
          </el-row>
<!--          <el-row type="flex" class="row-bg" justify="center">-->
<!--            <el-col :span="20">-->
<!--              <el-table-->
<!--                      border-->
<!--                      v-loading="loading"-->
<!--                      :data="custRecordStatList3"-->
<!--                      :header-cell-style="{background: '#f5f5f5'}"-->
<!--              >-->
<!--                <el-table-column-->
<!--                        prop="낙찰구간"-->
<!--                        label="낙찰구간"-->
<!--                        header-align="center"-->
<!--                >-->
<!--                </el-table-column>-->
<!--                <el-table-column-->
<!--                        prop="1구간"-->
<!--                        label="1구간 (~98.9)"-->
<!--                        header-align="center"-->
<!--                        align="right"-->
<!--                >-->
<!--                </el-table-column>-->
<!--                <el-table-column-->
<!--                        prop="2구간"-->
<!--                        label="2구간 (99.0~99.4)"-->
<!--                        header-align="center"-->
<!--                        align="right"-->
<!--                >-->
<!--                </el-table-column>-->
<!--                <el-table-column-->
<!--                        prop="3구간"-->
<!--                        label="3구간 (99.5~99.9)"-->
<!--                        header-align="center"-->
<!--                        align="right"-->
<!--                >-->
<!--                </el-table-column>-->
<!--                <el-table-column-->
<!--                        prop="4구간"-->
<!--                        label="4구간 (100.0~100.4)"-->
<!--                        header-align="center"-->
<!--                        align="right"-->
<!--                >-->
<!--                </el-table-column>-->
<!--                <el-table-column-->
<!--                        prop="5구간"-->
<!--                        label="5구간 (100.5~100.9)"-->
<!--                        header-align="center"-->
<!--                        align="right"-->
<!--                >-->
<!--                </el-table-column>-->
<!--                <el-table-column-->
<!--                        prop="6구간"-->
<!--                        label="6구간 (101.0~)"-->
<!--                        header-align="center"-->
<!--                        align="right"-->
<!--                >-->
<!--                </el-table-column>-->
<!--                <el-table-column-->
<!--                        prop="합계"-->
<!--                        label="합계"-->
<!--                        header-align="center"-->
<!--                        align="right"-->
<!--                        style="background-color: blue"-->
<!--                        width="80px"-->
<!--                >-->
<!--                </el-table-column>-->
<!--              </el-table>-->
<!--            </el-col>-->
<!--          </el-row>-->
          <el-row type="flex" class="row-bg" justify="center">
            <el-col :span="15">
              <el-tag type="warning">±3</el-tag>
              <el-table
                      border
                      v-loading="loading"
                      :data="custRecordStatList"
                      :header-cell-style="{background: '#f5f5f5'}"
              >
                <el-table-column
                        prop="사정율(예가)"
                        label="사정율 (97~103)"
                        header-align="center"
                >
                </el-table-column>
                <el-table-column
                        prop="1구간"
                        label="1구간 (~98.9)"
                        header-align="center"
                        align="right"
                >
                </el-table-column>
                <el-table-column
                        prop="2구간"
                        label="2구간 (99.0~99.4)"
                        header-align="center"
                        align="right"
                >
                </el-table-column>
                <el-table-column
                        prop="3구간"
                        label="3구간 (99.5~99.9)"
                        header-align="center"
                        align="right"
                >
                </el-table-column>
                <el-table-column
                        prop="4구간"
                        label="4구간 (100.0~100.4)"
                        header-align="center"
                        align="right"
                >
                </el-table-column>
                <el-table-column
                        prop="5구간"
                        label="5구간 (100.5~100.9)"
                        header-align="center"
                        align="right"
                >
                </el-table-column>
                <el-table-column
                        prop="6구간"
                        label="6구간 (101.0~)"
                        header-align="center"
                        align="right"
                >
                </el-table-column>
                <el-table-column
                        prop="합계"
                        label="합계"
                        header-align="center"
                        align="right"
                        width="80px"
                >
                </el-table-column>
              </el-table>
              <el-row type="flex" class="row-bg" justify="center">
                <el-tag size="large" style="font-size:1.5vh" type="success">Top 투찰 구간: {{topGroup}}구간</el-tag>&nbsp;&nbsp;
                <el-tag size="large" style="font-size:1.5vh" type="success">Top 투찰 지점: {{topPoint}}</el-tag>&nbsp;&nbsp;
                <el-tag size="large" style="font-size:1.5vh" type="success">투찰 비율: {{custBidRt}}%</el-tag>&nbsp;&nbsp;
                <el-tag size="large" style="font-size:1.5vh" type="success">실제 예가 출현 비율: {{realOpbdPrargRt}}%</el-tag>&nbsp;&nbsp;
                <el-tag size="large" style="font-size:1.5vh" type="success">(예가-내지점)중 가장 큰 구간: {{topGroup2}}구간</el-tag>&nbsp;&nbsp;
              </el-row>
              <el-row type="flex" class="row-bg" justify="center" style="margin-top: -30px">
                <el-tag size="large" style="font-size:1.5vh" type="success">투찰 순위: {{groupBidRank}}</el-tag>&nbsp;
                <el-tag size="large" style="font-size:1.5vh" type="success">100기준 비율합계(출현): {{this.stdrd100yega}}</el-tag>&nbsp;
                <el-tag size="large" style="font-size:1.5vh" type="success">100기준 비율합계(투찰): {{this.stdrd100bid}}</el-tag>
              </el-row>
            </el-col>
            <el-col :span="1" align="center">
            </el-col>
            <el-col :span="7" align="center">
              <LineChartGenerator
                      v-loading="loading"
                      :chart-options="chartOptions"
                      :chart-data="chartData"
                      :chart-id="chartId"
                      :dataset-id-key="datasetIdKey"
                      :plugins="plugins"
                      :css-classes="cssClasses"
                      :styles="styles"
                      :width="width"
                      :height="height"
              />
            </el-col>
          </el-row>

          <el-row type="flex" class="row-bg" justify="center">
            <el-col :span="15">
              <el-tag type="warning">±2</el-tag>
              <el-table
                      border
                      v-loading="loading"
                      :data="custRecordStatList2"
                      :header-cell-style="{background: '#f5f5f5'}"
              >
                <el-table-column
                        prop="사정율(예가)"
                        label="사정율 (98~102)"
                        header-align="center"
                >
                </el-table-column>
                <el-table-column
                        prop="1구간"
                        label="1구간 (~98.9)"
                        header-align="center"
                        align="right"
                >
                </el-table-column>
                <el-table-column
                        prop="2구간"
                        label="2구간 (99.0~99.4)"
                        header-align="center"
                        align="right"
                >
                </el-table-column>
                <el-table-column
                        prop="3구간"
                        label="3구간 (99.5~99.9)"
                        header-align="center"
                        align="right"
                >
                </el-table-column>
                <el-table-column
                        prop="4구간"
                        label="4구간 (100.0~100.4)"
                        header-align="center"
                        align="right"
                >
                </el-table-column>
                <el-table-column
                        prop="5구간"
                        label="5구간 (100.5~100.9)"
                        header-align="center"
                        align="right"
                >
                </el-table-column>
                <el-table-column
                        prop="6구간"
                        label="6구간 (101.0~)"
                        header-align="center"
                        align="right"
                >
                </el-table-column>
                <el-table-column
                        prop="합계"
                        label="합계"
                        header-align="center"
                        align="right"
                        width="80px"
                >
                </el-table-column>
              </el-table>
              <el-row type="flex" class="row-bg" justify="center">
                <el-tag size="large" style="font-size:1.5vh" type="success">Top 투찰 구간: {{topGroup_98}}구간</el-tag>&nbsp;&nbsp;
                <el-tag size="large" style="font-size:1.5vh" type="success">Top 투찰 지점: {{topPoint_98}}</el-tag>&nbsp;&nbsp;
<!--                <el-tag size="large" style="font-size:1.5vh" type="success">투찰 비율: {{custBidRt}}%</el-tag>&nbsp;&nbsp;-->
<!--                <el-tag size="large" style="font-size:1.5vh" type="success">실제 예가 출현 비율: {{realOpbdPrargRt}}%</el-tag>&nbsp;&nbsp;-->
<!--                <el-tag size="large" style="font-size:1.5vh" type="success">(예가-내지점)중 가장 큰 구간: {{topGroup2}}구간</el-tag>&nbsp;&nbsp;-->
<!--                <el-tag size="large" style="font-size:1.5vh" type="success">투찰 순위: {{groupBidRank}}</el-tag>-->
              </el-row>
            </el-col>
            <el-col :span="1" align="center">
            </el-col>
            <el-col :span="7" align="center">
              <LineChartGenerator
                      v-loading="loading"
                      :chart-options="chartOptions"
                      :chart-data="chartData2"
                      :chart-id="chartId"
                      :dataset-id-key="datasetIdKey"
                      :plugins="plugins"
                      :css-classes="cssClasses"
                      :styles="styles"
                      :width="width"
                      :height="height"
              />
            </el-col>
          </el-row>
<!--          <el-row type="flex" class="row-bg" justify="center">-->
<!--              <el-col :span="10" align="center">-->
<!--                <Doughnut-->
<!--                        v-loading="loading"-->
<!--                        :chart-options="chartOptions"-->
<!--                        :chart-data="chartData3"-->
<!--                        :chart-id="chartId"-->
<!--                        :dataset-id-key="datasetIdKey"-->
<!--                        :plugins="plugins"-->
<!--                        :css-classes="cssClasses"-->
<!--                        :styles="styles"-->
<!--                        :width="width"-->
<!--                        :height="height"-->
<!--                />-->
<!--              </el-col>-->
<!--            <el-col :span="10" align="center">-->

<!--            </el-col>-->
<!--          </el-row>-->

        </el-main>

      </el-container>
    </el-container>
  </div>
</template>

<script>
  import * as _ from 'lodash'
  import mock from './mock';
  import DRNS from './DashRealNotiStat'
  import DTR from './DashTokReport'
  import { Line as LineChartGenerator } from 'vue-chartjs/legacy'
  import {
    Chart as ChartJS,
    Title,
    Tooltip,
    Legend,
    LineElement,
    LinearScale,
    CategoryScale,
    ArcElement,
    PointElement
  } from 'chart.js'

  ChartJS.register(
          Title,
          Tooltip,
          Legend,
          LineElement,
          LinearScale,
          CategoryScale,
          ArcElement,
          PointElement
  );

  export default {
          name: "Dashboard",
          components: {
              LineChartGenerator,
              //Doughnut,
              DRNS,
              DTR
          },
  props: {
    chartId: {
      type: String,
      default: 'line-chart'
    },
    chartId2: {
        type: String,
        default: 'doughnut-chart'
    },
    datasetIdKey: {
      type: String,
      default: 'label'
    },
    width: {
      type: Number,
      default: 400
    },
    height: {
      type: Number,
      default: 300
    },
    cssClasses: {
      default: '',
      type: String
    },
    styles: {
      type: Object,
      default: () => {}
    },
    plugins: {
      type: Array,
      default: () => []
    }
  },
  data() {
    const item = {
      date: '2016-05-02',
      name: 'Tom',
      address: 'No. 189, Grove St, Los Angeles',
    };
    return {
      menu: 1,
      tableData: Array(3).fill(item),
      value: new Date(),
      chartData: {
        labels: [
          '~98.9',
          '99.0~99.4',
          '99.599.9',
          '100.0~100.4',
          '100.5~100.9',
          '101.0~'
        ],
        datasets: [
          {
            label: '사정율',
            backgroundColor: 'blue',
            data: [0, 0, 0, 0, 0, 0, 0]
          },
          {
            label: '투찰율',
            backgroundColor: '#f87979',
            data: [0, 0, 0, 0, 0, 0, 0]
          }
        ],
      },
      chartData2: {
          labels: [
              '~98.9',
              '99.0~99.4',
              '99.599.9',
              '100.0~100.4',
              '100.5~100.9',
              '101.0~'
          ],
          datasets: [
              {
                  label: '사정율',
                  backgroundColor: 'blue',
                  data: [0, 0, 0, 0, 0, 0, 0]
              },
              {
                  label: '투찰율',
                  backgroundColor: '#f87979',
                  data: [0, 0, 0, 0, 0, 0, 0]
              }
          ],
      },
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false
      },
      // chartData3: {
      //     labels: ['AI수리'],
      //     datasets: [
      //         {
      //             backgroundColor: ['#41B883','#3ee345'],
      //             data: [77, 23]
      //         }
      //     ]
      // },
      bizNo: '2118668278',
      custRecordStatList: [],
      custRecordStatList2: [],
      custRecordStatList3: [{'낙찰구간':'낙찰건수','1구간':0,'2구간':0,'3구간':0,'4구간':0,'5구간':0,'6구간':0}], //구간별 낙찰 건수
      custRecordPercentList: [], //투찰율 구간별 백분율
      custRecordPercentList2: [], //투찰율 구간별 백분율 (98~102)
      loading: false,
      sum: 0,
      cmpNm: '',
      config: {
          headers: {
              'Content-Type': 'application/json',
              'auth-token': localStorage.getItem("authToken"), //localStorage.getItem("authToken"),
              'refresh-token': localStorage.getItem("refreshToken") // localStorage.getItem("refreshToken"),
          },
      },
      checkboxGroup1: [],
      licnsValList: [],
      licnsKeyList: [],
      licnsCntList: [],
      strtDate: new Date(new Date().getFullYear()-1, new Date().getMonth(), new Date().getDate()).toLocaleDateString(),
      finDate: new Date(),
      searchParam: {
        strtDate: new Date(new Date().getFullYear()-1, new Date().getMonth(), new Date().getDate()).toLocaleDateString(),
        finDate: new Date()
      },
      avgCptnRt: 0, // 평균경쟁율
      totalSBidAmt: 0, //낙찰한 공고 총금액
      cmpRankListCnt: 0, //총 낙찰 건수
      topGroup:'', // 가장 많이 투찰한 구간
      topGroup_98:'', // 가장 많이 투찰한 구간 (98~102)
      topPoint:'', // 가장 많이 투찰한 지점
      topPoint_98:'', // 가장 많이 투찰한 지점 (98~102)
      custBidRt:0, // 가장 많이 투찰한 포인트의 전체 투찰 비율
      realOpbdPrargRt:0, // 실제 예가 출현 비율
      topGroup2:0, // 예가 - 내지점 차이가 제일 큰 구간
      groupBidRank: [], // 투찰 순위
      stdrd100yega: [], // 100기준 상하 예가 출현비율
      stdrd100bid: [], // 100기준 상하 내 투찰비율
    }
  },
  options: {
    responsive: true
  },
  methods: {
    clkLicns() {
      if (this.loading == false) {
          this.searchParam.searchLicnsList = [];
          //this.licnsValList = ['2','3']

          for (var i=0 ; i<this.checkboxGroup1.length ; i++) {
              // console.log(this.checkboxGroup1[i]);
              // console.log(this.licnsValList[0]);
              // if (this.licnsValList[0] == this.checkboxGroup1[i]) {
              // console.log("같다");
              // }
              this.searchParam.searchLicnsList.push(this.licnsKeyList[_.indexOf(this.licnsValList, this.checkboxGroup1[i])]);
              //console.log(_.findIndex(this.licnsValList, '수질방지'));
          }

          // await _.map(this.checkboxGroup1, function(n) {
          //     _.findIndex(this.licnsKeyList, 'a');
          //     console.log(n);
          // });

          this.sum= 0;
          this.avgCptnRt= 0;
          this.totalSBidAmt= 0;
          var self = this;
          this.getCustRecordStat(97, async function(topGroup) {

              self.getCustStatNcstPrargList(topGroup);
              self.getCustStatTopPoint(topGroup, 97);
          });
          this.getCustRecordStat(98, async function(topGroup, topGroup_98) {

              self.getCustStatTopPoint(topGroup_98, 98);
          });

      }

    },
    doReset() {
        this.sum= 0;
        this.avgCptnRt=0;
        this.totalSBidAmt=0;
        this.checkboxGroup1= [];
        this.licnsValList= [],
        this.licnsKeyList= [],
        this.licnsCntList= [],
        this.searchParam.searchLicnsList = [];
        this.custRecordPercentList = [0,0,0,0,0,0];
        this.stdrd100yega = [];
        this.stdrd100bid = [];
    },
    doSearch() {
      this.doReset();
      var self = this;
      this.getCustRecordStat(97, async function(topGroup) {

          self.getCustStatNcstPrargList(topGroup);
          self.getCustStatTopPoint(topGroup, 97);
      });

      this.getCustRecordStat(98, async function(topGroup, topGroup_98) {

          self.getCustStatTopPoint(topGroup_98, 98);
      });
      this.getNcstCntList();
      //this.getCustStatNcstPrargList();
    },
    getRandomDataForTrends() {
      const arr = [];
      for (let i = 0; i < 12; i += 1) {
        arr.push(Math.random().toFixed(1) * 10);
      }
      return arr;
    },
    gneratePieSeries() {
      let series = [];

      for (let i = 0; i < 6; i++) {
        let y = Math.floor(Math.random() * (500 - 100 + 100)) + 100;
        series.push(y);
      }
      return series;
    },
    getRandomInt(min, max) {
      let rand = min - 0.5 + Math.random() * (max - min + 1);
      return Math.round(rand);
    },
    goMenu(idx) {
      this.menu = idx;

      switch (idx) {
        case 1:
          this.getCustRecordStat();
          break;
        case 2:
            //this.getCustRecordStat();
            break;
        default:
      }
    },
    goSite: function() {
      window.open(this.siteArr[this.menu], "_blank");
    },
    getCustRecordStat(minAsRt, callback) {
      // const config = {
      //   headers: {
      //     'Content-Type': 'application/json',
      //     'auth-token': 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJjbXBObSI6IuynhOu5hOuTnDEiLCJyb2xlIjoiUk9MRV9VU0VSIiwiaXNzIjoiamluYmlkIiwiaWQiOjEsImV4cCI6MTcxMDMxNDkzNSwiY3VzdElkTm0iOiIwMDAwMDAwMDAxIn0.5AnTZAttUtNk5hyFBitXukSUmIWIf9RWl8l_59KudKc', //localStorage.getItem("authToken"),
      //     'refresh-token': 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJjbXBObSI6IuynhOu5hOuTnDEiLCJyb2xlIjoiUk9MRV9VU0VSIiwiaXNzIjoiamluYmlkIiwiaWQiOjEsImV4cCI6MTcxMjg4NTMzNSwiY3VzdElkTm0iOiIwMDAwMDAwMDAxIn0.G8FlXptiTNfX0jcdMxFcfeLDKmWpWIsmIm3bCimAV5M' // localStorage.getItem("refreshToken"),
      //   },
      // };

      this.searchParam.strtDate = this.$moment(this.strtDate).format('YYYY-MM-DD');
      this.searchParam.finDate = this.$moment(this.finDate).format('YYYY-MM-DD');

      var postData = _.defaults({
        pageUnit: 9999,
        pageIndex: 0,
        strtRecordIntex: 0,
        //strtDate: this.$moment().format('YYYYMMDD HH:mm'),
        //finDate: this.$moment().format('YYYYMMDD HH:mm'),
        //custBizId: custInfo.custBizList[0].id,
        bizNo: this.bizNo,
        findText: this.findText,
        // orderBySt: 'bddprFinDt',
        // notiOpbdListYn: 'Y',
        minAsRt: minAsRt
      }, this.searchParam);

      //console.log(this.$moment(this.searchParam.strtDate).format('YYYYMMDD HH:mm'));

      mock.notiTable.noti = [];

      this.loading = true;
      this.$http.post('/api/custBiz/getCustRecordStat', postData, this.config).then(res => {
        const sum = res.data.custRecordStatList[0]['1구간']+res.data.custRecordStatList[0]['2구간']+res.data.custRecordStatList[0]['3구간']+res.data.custRecordStatList[0]['4구간']+res.data.custRecordStatList[0]['5구간']+res.data.custRecordStatList[0]['6구간'];
        this.sum = this.sum + sum;

        if (minAsRt == 97) {
          this.custRecordStatList = _.cloneDeep(res.data.custRecordStatList);

          // 예가 비율 - 내 지점 비율
          var tmpArray = [];

          for (var idx=0 ; idx<this.custRecordStatList.length ; idx++) {
              const v = this.custRecordStatList[idx];
              this.custRecordStatList[idx]['합계'] = v['1구간']+v['2구간']+v['3구간']+v['4구간']+v['5구간']+v['6구간'];

              if (idx < 2) {
                  //const a = v['1구간'];
                  this.chartData.datasets[idx].data = [_.round(v['1구간']/sum*100,2).toFixed(2),_.round(v['2구간']/sum*100,2).toFixed(2),_.round(v['3구간']/sum*100,2).toFixed(2),_.round(v['4구간']/sum*100,2).toFixed(2),_.round(v['5구간']/sum*100,2).toFixed(2),_.round(v['6구간']/sum*100,2).toFixed(2)];


                  if (idx == 0) {
                      // 백분율 차이 (예가 - 내지점)
                      tmpArray[0] = _.round(v['1구간']/sum*100,2).toFixed(2);
                      tmpArray[1] = _.round(v['2구간']/sum*100,2).toFixed(2);
                      tmpArray[2] = _.round(v['3구간']/sum*100,2).toFixed(2);
                      tmpArray[3] = _.round(v['4구간']/sum*100,2).toFixed(2);
                      tmpArray[4] = _.round(v['5구간']/sum*100,2).toFixed(2);
                      tmpArray[5] = _.round(v['6구간']/sum*100,2).toFixed(2);

                      this.stdrd100yega.push("+"+((Number(tmpArray[3]) + Number(tmpArray[4]) + Number(tmpArray[5]))).toFixed(2));
                      this.stdrd100yega.push("-"+((Number(tmpArray[0]) + Number(tmpArray[1]) + Number(tmpArray[2]))).toFixed(2));
                  }

                  if (idx == 1) { // 내 투찰율 저장
                      // this.custRecordPercentList = _.filter(_.map(_.keys(v), function(o) {
                      //     if (o != '합계' && o != '사정율(예가)') {
                      //         var obj = {};
                      //         obj[o] = _.round(v[o]/sum*100,2).toFixed(2);
                      //         return obj;
                      //     }
                      // }), function(o) {
                      //     if (o != undefined) {
                      //         return o;
                      //     }
                      // });

                      // 투찰율 백분율 목록
                      var groupList = _.keys(v);
                      for (var i=0 ; i<groupList.length ; i++) {
                          var o = groupList[i];

                          if (o != '합계' && o != '사정율(예가)') {
                              this.custRecordPercentList[o.substring(0,1)-1]=_.round(v[o]/sum*100,2).toFixed(2);
                              //this.custRecordPercentList[0] = _.round(v[o]/sum*100,2).toFixed(2);
                          }
                      }

                      this.stdrd100bid.push("+"+((Number(_.round(v['4구간']/sum*100,2).toFixed(2)) + Number(_.round(v['5구간']/sum*100,2).toFixed(2)) + Number(_.round(v['6구간']/sum*100,2).toFixed(2)))).toFixed(2));
                      this.stdrd100bid.push("-"+((Number(_.round(v['1구간']/sum*100,2).toFixed(2)) + Number(_.round(v['2구간']/sum*100,2).toFixed(2)) + Number(_.round(v['3구간']/sum*100,2).toFixed(2)))).toFixed(2));

                      // 백분율 차이 (예가 - 내지점)
                      tmpArray[0] =  tmpArray[0] - _.round(v['1구간']/sum*100,2).toFixed(2);
                      tmpArray[1] =  tmpArray[1] - _.round(v['2구간']/sum*100,2).toFixed(2);
                      tmpArray[2] =  tmpArray[2] - _.round(v['3구간']/sum*100,2).toFixed(2);
                      tmpArray[3] =  tmpArray[3] - _.round(v['4구간']/sum*100,2).toFixed(2);
                      tmpArray[4] =  tmpArray[4] - _.round(v['5구간']/sum*100,2).toFixed(2);
                      tmpArray[5] =  tmpArray[5] - _.round(v['6구간']/sum*100,2).toFixed(2);

                      //this.custRecordPercentList.push(obj);
                      // this.custRecordPercentList.push(_.round(v['2구간']/sum*100,2).toFixed(2));
                      // this.custRecordPercentList.push(_.round(v['3구간']/sum*100,2).toFixed(2));
                      // this.custRecordPercentList.push(_.round(v['4구간']/sum*100,2).toFixed(2));
                      // this.custRecordPercentList.push(_.round(v['5구간']/sum*100,2).toFixed(2));
                      // this.custRecordPercentList.push(_.round(v['6구간']/sum*100,2).toFixed(2));

                  }


                  v['1구간'] = v['1구간'] +' (' + _.round(v['1구간']/sum*100,2).toFixed(2) + ")";
                  v['2구간'] = v['2구간'] +' (' + _.round(v['2구간']/sum*100,2).toFixed(2) + ")";
                  v['3구간'] = v['3구간'] +' (' + _.round(v['3구간']/sum*100,2).toFixed(2) + ")";
                  v['4구간'] = v['4구간'] +' (' + _.round(v['4구간']/sum*100,2).toFixed(2) + ")";
                  v['5구간'] = v['5구간'] +' (' + _.round(v['5구간']/sum*100,2).toFixed(2) + ")";
                  v['6구간'] = v['6구간'] +' (' + _.round(v['6구간']/sum*100,2).toFixed(2) + ")";


                  //console.log('1'+_.round(v['1구간']/sum*100,2).toFixed(2));
                  //this.chartData.datasets[idx].data = [res.data.custRecordStatList[idx]['1구간'],res.data.custRecordStatList[idx]['2구간'],res.data.custRecordStatList[idx]['3구간'],res.data.custRecordStatList[idx]['4구간'],res.data.custRecordStatList[idx]['5구간'],res.data.custRecordStatList[idx]['6구간']];
              } else {
                  // this.custRecordStatList3[0]['1구간'] = this.custRecordStatList[idx]['1구간'];
                  // this.custRecordStatList3[0]['2구간'] = this.custRecordStatList[idx]['2구간'];
                  // this.custRecordStatList3[0]['3구간'] = this.custRecordStatList[idx]['3구간'];
                  // this.custRecordStatList3[0]['4구간'] = this.custRecordStatList[idx]['4구간'];
                  // this.custRecordStatList3[0]['5구간'] = this.custRecordStatList[idx]['5구간'];
                  // this.custRecordStatList3[0]['6구간'] = this.custRecordStatList[idx]['6구간'];
                  // this.custRecordStatList3[0]['합계'] = this.custRecordStatList[idx]['1구간']+this.custRecordStatList[idx]['2구간']+this.custRecordStatList[idx]['3구간']+this.custRecordStatList[idx]['4구간']+this.custRecordStatList[idx]['5구간']+this.custRecordStatList[idx]['6구간'];
              }
          }

          //  console.log(this.stdrd100yega);
          // 가장 많이 투찰한 순위
          var tempCustRecordPercentList = _.cloneDeep(this.custRecordPercentList);
          let num = this.custRecordPercentList.slice().sort((a,b) => b - a);
            this.groupBidRank = _.map(num, function(o) {
                return _.findIndex(tempCustRecordPercentList, function(oo) {
                    return oo == o;
                })+1;
            });
          //this.groupBidRank = this.custRecordPercentList.map(el => num.indexOf(el) + 1);


          var tmpRankArr = _.sortBy(this.custRecordPercentList, [function(o) { return Number(o);}]).reverse();
          var tmpRank = tmpRankArr[0];
          this.topGroup = _.findIndex(this.custRecordPercentList, function(o){
             return o == tmpRank;
          })+1;

          // 예가 - 내지점
          var tmpArray2 = _.sortBy(tmpArray).reverse();
          this.topGroup2 = _.findIndex(tmpArray, function(o) {
             return o == tmpArray2[0];
          })+1; // 배열에서 1증가


          //delete this.custRecordStatList[2];

          //delete this.custRecordStatList[2];
          // _.forEach(this.custRecordStatList, function(v, idx) {
          //     console.log(idx);
          //     console.log(v['1구간']);
          //     //const a = v['1구간'];
          //   v['1구간'] = v['1구간'] +' (' + _.round(v['1구간']/sum*100,2).toFixed(2) + ")";
          //   v['2구간'] = v['2구간'] +' (' + _.round(v['2구간']/sum*100,2).toFixed(2) + ")";
          //   v['3구간'] = v['3구간'] +' (' + _.round(v['3구간']/sum*100,2).toFixed(2) + ")";
          //   v['4구간'] = v['4구간'] +' (' + _.round(v['4구간']/sum*100,2).toFixed(2) + ")";
          //   v['5구간'] = v['5구간'] +' (' + _.round(v['5구간']/sum*100,2).toFixed(2) + ")";
          //   v['6구간'] = v['6구간'] +' (' + _.round(v['6구간']/sum*100,2).toFixed(2) + ")";
          //   //this.chartData.datasets[idx].data = [res.data.custRecordStatList[idx]['1구간'],res.data.custRecordStatList[idx]['2구간'],3,4,5,6];
          // });

          this.cmpNm = res.data.custBizInfo.corpNm;
        } else {
          this.custRecordStatList2 = _.cloneDeep(res.data.custRecordStatList);

            for (var j=0 ; j<this.custRecordStatList2.length ; j++) {
                const v2 = this.custRecordStatList2[j];
                this.custRecordStatList2[j]['합계'] = v2['1구간']+v2['2구간']+v2['3구간']+v2['4구간']+v2['5구간']+v2['6구간'];

                if (j < 2) {
                    //this.chartData2.datasets[j].data = [res.data.custRecordStatList[j]['1구간'],this.custRecordStatList2[j]['2구간'],res.data.custRecordStatList[j]['3구간'],res.data.custRecordStatList[j]['4구간'],res.data.custRecordStatList[j]['5구간'],res.data.custRecordStatList[j]['6구간']];
                    this.chartData2.datasets[j].data = [_.round(v2['1구간']/sum*100,2).toFixed(2),_.round(v2['2구간']/sum*100,2).toFixed(2),_.round(v2['3구간']/sum*100,2).toFixed(2),_.round(v2['4구간']/sum*100,2).toFixed(2),_.round(v2['5구간']/sum*100,2).toFixed(2),_.round(v2['6구간']/sum*100,2).toFixed(2)];

                    if (j == 1) { // 내 투찰율 저장
                        // 투찰율 백분율 목록
                        var groupList2 = _.keys(v2);
                        for (var k=0 ; k<groupList2.length ; k++) {
                            var o2 = groupList2[k];

                            if (o2 != '합계' && o2 != '사정율(예가)') {
                                this.custRecordPercentList2[o2.substring(0,1)-1]=_.round(v2[o2]/sum*100,2).toFixed(2);
                                //this.custRecordPercentList[0] = _.round(v[o]/sum*100,2).toFixed(2);
                            }
                        }
                    }

                    v2['1구간'] = v2['1구간'] +' (' + _.round(v2['1구간']/sum*100,2).toFixed(2) + ")";
                    v2['2구간'] = v2['2구간'] +' (' + _.round(v2['2구간']/sum*100,2).toFixed(2) + ")";
                    v2['3구간'] = v2['3구간'] +' (' + _.round(v2['3구간']/sum*100,2).toFixed(2) + ")";
                    v2['4구간'] = v2['4구간'] +' (' + _.round(v2['4구간']/sum*100,2).toFixed(2) + ")";
                    v2['5구간'] = v2['5구간'] +' (' + _.round(v2['5구간']/sum*100,2).toFixed(2) + ")";
                    v2['6구간'] = v2['6구간'] +' (' + _.round(v2['6구간']/sum*100,2).toFixed(2) + ")";
                }

                // 가장 많이 투찰한 구간 순위2
                var tempCustRecordPercentList2 = _.cloneDeep(this.custRecordPercentList2);

                let num = this.custRecordPercentList2.slice().sort((a,b) => b - a);
                this.groupBidRank_98 = _.map(num, function(o) {
                    return _.findIndex(tempCustRecordPercentList2, function(oo) {
                        return oo == o;
                    })+1;
                });
                //this.groupBidRank = this.custRecordPercentList.map(el => num.indexOf(el) + 1);
                //console.log(this.groupBidRank);

                var tmpRankArr2 = _.sortBy(this.custRecordPercentList2, [function(o) { return Number(o);}]).reverse();
                var tmpRank2 = tmpRankArr2[0];
                this.topGroup_98 = _.findIndex(this.custRecordPercentList2, function(o){
                    return o == tmpRank2;
                })+1;

                // this.custRecordStatList2[j]['합계'] = this.custRecordStatList2[j]['1구간']+
                //     this.custRecordStatList2[j]['2구간']+
                //     this.custRecordStatList2[j]['3구간']+
                //     this.custRecordStatList2[j]['4구간']+
                //     this.custRecordStatList2[j]['5구간']+
                //     this.custRecordStatList2[j]['6구간'];
            }

            //delete this.custRecordStatList2[2];
        }
        this.loading = false;

        callback(this.topGroup, this.topGroup_98);
      })
      .catch(error => console.log(error));
    },
    getCustLicnsList() {
    },
    getNcstCntList() {

      delete this.searchParam.searchLicnsList;

      this.searchParam.strtDate = this.$moment(this.strtDate).format('YYYY-MM-DD');
      this.searchParam.finDate = this.$moment(this.finDate).format('YYYY-MM-DD');

      var postData = _.defaults({
          custId: '',
          notiOpbdYn: 'Y',
          notiMyBddprInfoYn: 'Y',
          bizNo: this.bizNo,
          ncstBizNo: this.bizNo,
          //strtDate: this.$moment().subtract(1,'year').format('YYYYMMDD'),
          //finDate: this.$moment().format('YYYYMMDD'),
          custBizId: '',
          firstRank: 'N',
          custNcstYn: 'Y',
          licnsCd: '',
          prvnclCd: '',
          areaCd: '',
          kword: 'notiNm',
          findText: '',
          notiKindCd: '',
      }, this.searchParam);

      this.$http.post('/api/noti/notiNcstCntList', postData, this.config).then(res => {
          //console.log(res.data.notiLicnCntList);

          for (var j=0 ; j<res.data.notiLicnCntList.length; j++) {
            //console.log(res.data.notiLicnCntList[j].licnsCtgyNm);
            this.checkboxGroup1.push(res.data.notiLicnCntList[j].licnsCtgyNm);
            this.licnsValList.push(res.data.notiLicnCntList[j].licnsCtgyNm);
            this.licnsKeyList.push(res.data.notiLicnCntList[j].licnsCd);
            this.licnsCntList.push(res.data.notiLicnCntList[j].count);
          }
          //console.log(this.licnsValList);
          //this.loading = false;
      })
          .catch(error => console.log(error));
    },
    getCustStatTopPoint(topGroup, minAsRt) { // 가장 많이 투찰한 지점

        var postData = _.defaults({
            bizNo: this.bizNo,
            topGroup: topGroup.toString(),
            minAsRt: minAsRt
        }, this.searchParam);

        if (minAsRt == 98) {
            this.$http.post('/api/custBiz/getCustRecordTopPoint', postData, this.config).then(res => {
                this.topPoint_98 = res.data.custRecordTopPoint;
            })
                .catch(error => console.log(error));
        } else {
            this.$http.post('/api/custBiz/getCustRecordTopPoint', postData, this.config).then(res => {
                this.topPoint = res.data.custRecordTopPoint;
            })
                .catch(error => console.log(error));
        }

    },
    getCustStatNcstPrargList(topGroup) {

        var postData = _.defaults({
          bizNo: this.bizNo,
        }, this.searchParam);

      this.$http.post('/api/custBiz/getCustStatNcstPrargList', postData, this.config).then(res => {

          const total = res.data.custStatNcstPrargList.length;

          //console.log(res.data.custStatNcstPrargList);

          this.avgCptnRt = Math.round(_.sumBy(res.data.custStatNcstPrargList, function(o) {
             return o.bddprPrtcCmpCnt;
          }) / total);

          const cmpRankList = _.filter(res.data.custStatNcstPrargList, function(o) {
              return o.cmpRank == 1;
          });

          this.cmpRankListCnt = cmpRankList.length;

          this.totalSBidAmt = _.sumBy(cmpRankList, function(o) {
              return Number(o.bddprAmt);
          });

          //console.log((_.map(_.groupBy(res.data.custStatNcstPrargList, (item) => Math.floor(Number(item.calBddprRt)*10)/10), (group) => ({...group[0], count: group.length}))));

          // 가장 많이 투찰한 포인트 산출
          var tmpGroupList = _.map(res.data.custStatNcstPrargList, function(o) {
              //console.log(o.calBddprRt);
             //console.log( Number(o.calBddprRt).toFixed(1));
              //console.log( Math.floor(Number(o.calBddprRt)*10)/10);
              var obj = o;
              o.calBddprRt = Math.floor(Number(o.calBddprRt)*10)/10;

              return obj;
          });

          var tmpGroupList2 = _.cloneDeep(tmpGroupList);

          tmpGroupList = _.filter(tmpGroupList, function(o) {

              switch (topGroup) {
                  case 1:
                      return o.calBddprRt <= 99.9999;
                  case 2:
                      return o.calBddprRt >= 99.5 && o.calBddprRt <= 99.9999;
                  case 3:
                      return o.calBddprRt >= 99.5 && o.calBddprRt <= 99.9999;
                  case 4:
                      return o.calBddprRt >= 100.0 && o.calBddprRt <= 100.4999;
                  case 5:
                      return o.calBddprRt >= 100.5 && o.calBddprRt <= 100.9999;
                  case 6:
                      return o.calBddprRt >= 101.0;
                  default:
              }

              // if (topGroup == 3) {
              //     return o.calBddprRt >= 99.5 && o.calBddprRt <= 99.9999;
              // }
              //return o;
          });

          tmpGroupList = _.map(_.groupBy(tmpGroupList, 'calBddprRt'),function(o) {
              o.groupCnt = o.length;
              return o;
          });

          tmpGroupList = _.sortBy(tmpGroupList, 'groupCnt').reverse();

          //if (tmpGroupList.length > 0) {
              this.custBidRt = (tmpGroupList[0]['groupCnt'] / this.sum * 100).toFixed(2);

              // 실제 예가 출현 비율
              this.realOpbdPrargRt = (_.filter(tmpGroupList2, function(o) {
                  //console.log(Math.floor(Number(o.opbdPrargRt)*10)/10);
                  if (Math.floor(Number(o.opbdPrargRt)*10)/10 == tmpGroupList[0][0]['calBddprRt'] ) {
                      return o;
                  }
                  //console.log( Math.floor(Number(o.opbdPrargRt)*10)/10);
                  //console.log( tmpGroupList[0][0]['calBddprRt']);
                  //console.log( Math.floor(Number(o.opbdPrargRt)*10)/10 == tmpGroupList[0][0]['calBddprRt']);
              }).length / this.sum * 100).toFixed(2) ;
          //}

      })
          .catch(error => console.log(error));
    },
  },
  mounted() {
    setTimeout(() => {    
      this.apexLoading = true;
    });
    this.doSearch();
  },
};
</script>


<style src="./Dashboard.scss" lang="scss"/>
