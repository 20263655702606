<template>
    <div id="app">
        <Header ref="header"/>
<!--        <v-main class="content">-->
            <ComnCompnt ref="comncompnt"/>
            <transition name="slide-fade" mode="out-in">
                <router-view />
            </transition>
<!--        </v-main>-->
        <Footer />
    </div>
</template>

<script>
    import Header from '@/components/Header/Header';
    import Footer from "@/components/Footer/Footer";
    import ComnCompnt from "@/components/common/ComnCompnt";
    //import './Layout.scss';
    import * as _ from 'lodash';

    export default {
        name: 'Layout',
        components: {Header, Footer, ComnCompnt },
        data: () => ({
            loginInfo: {},
            custCntrctList: [],
            custCntrct: {},     //유효한 분석동의계약서
            custInfo : {},      //고객 정보
            custBiz: [],        //사업자 목록
            selectedPlanId: 0,
            isTestId: false,
            BASE_URL: "https://www.bidtok.co.kr",
        }),
        methods: {
            initComnCode() {
                // console.log('commit 시작');
                this.$http.get('/api/comnCode/initCode')
                    .then(res => {
                        this.$store.commit('setNotiStatList', res.data.notiStatList);
                        this.$store.commit('setAreaCodeList', res.data.areaCodeList);
                        this.$store.commit('setLicnsCodeList', res.data.licnsCodeList);
                        this.$store.commit('setRpsntOrgnzCdList', res.data.rpsntOrgnzCdList);
                        this.$store.commit('setNotiAnlstList', res.data.notiAnlstList);
                        this.$store.commit('setCdltStList', res.data.cdltStList);
                        // console.log('commit 완료');
                    })
                    .catch(error => {console.log(error);}
                    );
            },
            showGuidePopup(){
                if (screen.width > 1000) {
                    this.$refs.header.showGuidePopup();
                } else {
                    this.$refs.header.showGuidePopupMo();
                }
            },
            //계약서 팝업
            showCustCntrctPop(){
                this.$refs.header.isPopCustCntrct = true;
                this.$refs.header.isPopCustCntrctStep1 = true;
                this.$refs.header.activeScrollEvent();
            },
            isLoadingBar(value){
                this.$refs.header.isLoading = value;
            },
            isPopSetAutoAnls(value){
                this.$refs.header.isPopSetAutoAnls = value;
            },
            userAuth(){
                var loginInfo = JSON.parse(localStorage.getItem("custInfo"));
                var authToken =  localStorage.getItem('authToken'); // localStorage에서 authToken 가져오기
                // var refreshToken = localStorage.getItem('refreshToken'); // localStorage에서 refreshToken 가져오기

                if(loginInfo != null){
                    if (!authToken) {
                        // authToken이 없으면
                        localStorage.removeItem('authToken'); // authToken 제거
                        localStorage.removeItem('refreshToken'); // refreshToken 제거
                        localStorage.removeItem("custInfo");
                        this.$router.go();
                    }
                    // var isLoginInfo = loginInfo == null ? false : true;
                    // var loginId = !isLoginInfo ? '&' + loginInfo.custIdNm : '';
                    //
                    // var param = {
                    //
                    // };
                    // this.$post('/api/auth?isLoginInfo=' + isLoginInfo + loginId, param)
                    //     .then(res => {
                    //         if(res.loginInfo){
                    //             localStorage.setItem("custInfo",JSON.stringify(res.loginInfo));
                    //         }
                    //     })
                    //     .catch(error => {
                    //         this.$toast.error('로그인 풀림');
                    //         if(error.response.data.status == 403) {
                    //             localStorage.removeItem("authToken");
                    //             localStorage.removeItem("refreshToken");
                    //             localStorage.removeItem("custInfo");
                    //             this.$router.go();
                    //         }
                    //     });
                }
            },
            /** 계약정보 체크 */
            async chkCustCntrctComn() {
                if (this.mock.custCntrctList.length == undefined) {
                    // 계약서가 없으면 체크 약관 동의 팝업
                    self.$refs.comncompnt.isPopCustCntrct = true;
                    self.$refs.comncompnt.isPopCustCntrctStep1 = true;
                    self.$refs.comncompnt.activeScrollEvent();

                }
            },
            /** 로그인 팝업 */
            isPopLoginComn() {
                this.$refs.comncompnt.isPopLogin = true;
            },
            /** AI멤버십 신청 팝업 */
            async chkCustAISubComn(planId) {
                if (planId == 0 && this.loginInfo.subStatusCd == null) {
                    // 상품선택 안한 경우
                    this.$refs.comncompnt.isPopSelAI = true;
                    //this.$toast.error('AI멤버십 상품을 선택해주세요.');
                    return;
                }

                this.selectedPlanId = planId;
                var self = this;

                // 로그인 전
                if (Object.keys(self.loginInfo).length == 0) {
                    self.$refs.comncompnt.isPopLogin = true;
                } else {
                    this.getCustInfoComn(function() {
                        // 분석동의 전
                        if (Object.keys(self.custCntrct).length == 0) {
                            self.$refs.comncompnt.isPopGoMyPage = true;
                        } else { // 분석동의 후
                            // AI멤버십 신청 전
                            if (self.loginInfo.subStatusCd == null) {
                                self.$refs.comncompnt.isPopCustCntrct = true;
                                self.$refs.comncompnt.isPopCustCntrctStep2_1 = true;
                            } else if (self.loginInfo.subStatusCd == 0) { // 신청 후
                                if(self.loginInfo.vactYn == 'N'){
                                    self.comnPopup( '/pay/pcPay/' + self.loginInfo.rcptmnId, 875, 780 );
                                } else {
                                    self.comnPopup('/pay/payInfoPop',  820, 780 );
                                }
                                // if(self.loginInfo.custIdNm.indexOf('000000000') != -1){
                                //     //가상계좌 발급하지 않았을 때
                                //      if(self.loginInfo.vactYn == 'N'){
                                //          self.comnPopup( '/pay/pcPay/' + self.loginInfo.rcptmnId, 875, 780 );
                                //      } else {
                                //          self.comnPopup('/pay/payInfoPop',  820, 780 );
                                //      }
                                // } else {
                                //     self.$refs.comncompnt.isPopCustCntrct = true;
                                //     self.$refs.comncompnt.isPopCustCntrctStep3_1 = true;
                                // }
                                // self.$refs.comncompnt.isPopCustCntrct = true;
                                // self.$refs.comncompnt.isPopCustCntrctStep3_1 = true;
                                // self.comnPopup( "https://www.naver.com" , 900, 700 ); ///TODO: 가상계좌 팝업
                            }
                        }
                        self.$refs.comncompnt.activeScrollEvent();
                    });
                }
                //callback();
            },
            //계약서 정보 로드 (분석동의)
            // getCustCntrctComn(callback) {
            //     console.log("계약정보갱신");
            //     var param = {
            //         "custId": this.loginInfo.id,
            //     };
            //     this.$post('/api/cust/custCntrct',param)
            //             .then(res => {
            //
            //                 callback(res.custCntrctList);
            //             })
            //             .catch(error => console.log(error));
            //
            // },
            //계약서 정보 로드 (분석동의,AI멤버십 포함)
            getCustCntrctListComn(callback) {
                var param = {
                    "custId": this.loginInfo.id,
                };
                var self = this;
                this.$post('/api/cust/custCntrctList',param)
                        .then(res => {
                            this.custCntrctList = res.custCntrctList;
                            var custCntrctList = _.sortBy(this.custCntrctList, 'anlsAgreeDt').reverse();

                            if(custCntrctList.length > 0){
                                 var ret = _.filter(custCntrctList,function(o) {
                                    // console.log(self.$moment(o.anlsExpyDt).format('YYYY-MM-DD'));
                                    // console.log(self.$moment().format('YYYY-MM-DD'));
                                    // console.log(self.$moment(self.$moment().format('YYYY-MM-DD')).isAfter(self.$moment(o.anlsExpyDt).format('YYYY-MM-DD')));
                                    // 분석동의가 존재 && 현재날짜가 분석종료일을 안넘을때
                                    return o.cntrctType == 'fee' && o.newestYn == 'Y' && !self.$moment(self.$moment().format('YYYY-MM-DD')).isAfter(self.$moment(o.anlsExpyDt).format('YYYY-MM-DD'));
                                })[0];
                                this.custCntrct = ret == undefined ? {} : ret;
                            }

                            callback(this.custCntrctList);
                        })
                        .catch(error => {console.log(error);}
                        );
            },
            //내 정보 갱신
            getCustInfoComn(callback,menu){
                if (localStorage.getItem("custInfo") != null) {
                    this.$get('/api/cust/custInfo/select')
                        .then(res => {
                            localStorage.setItem("custInfo",JSON.stringify(res.data));
                            this.loginInfo = JSON.parse(localStorage.getItem("custInfo"));

                            this.custInfo = res.data;
                            this.custBiz = this.custInfo.custBizList.find(item => item.id === this.custInfo.custBizList[0].id,);

                            // if(this.loginInfo.custIdNm.indexOf('000000000') != -1){ // TODO 결제창 적용 시 삭제 (임시)
                            //     this.isTestId = true;
                            // }

                            // 헤더의 상태바 체크
                            this.$refs.header.chkAISubStatus();

                            callback(res);
                        })
                        .catch(error => {
                            if (error.response.status == 403) {
                                this.loginInfo = {};
                                //localStorage.setItem("custInfo", null);
                            }
                            callback(error);
                        });
                } else {
                    this.loginInfo = {};
                    if (menu == '/myPage') {
                        alert("잘못된 접근입니다.");
                        this.$router.go(-1);
                    }
                }
            },
            // 팝업창
            comnPopup( uri, width, height ) {
                // if( this.windowRef != null ){
                //     this.closeWinPop();
                // }

                let left = (screen.width) ? (screen.width - width) / 2 : 0;
                let top = (screen.height) ? (screen.height - height) / 2 : 0;

                let attr = 'top=' + top + ', left=' + left  + ', width=' + width + ', height=' + height + ', resizable=no,status=no';

                this.windowRef = window.open(uri, "", attr);
                if( this.windowRef != null ) {
                    this.windowRef.addEventListener('beforeunload', this.evtClose);
                } else {
                    alert("팝업실패");
                }

                window.addEventListener("message", this.recvEvtFromChild, false);
            },
            styleFuncComn(type) {
                if (type == 0) {
                    document.querySelector('body').style.overflow = "hidden";
                } else {
                    document.querySelector('body').style.overflow = "auto";
                }
            },
        },
        created() {
            if (process.env.NODE_ENV == "development") {
               // this.BASE_URL = "http://test.bidtok.co.kr:8085";
            } else if (process.env.NODE_ENV == "localhost") {
               // this.BASE_URL = "http://test.bidtok.co.kr:8085"; //"http://localhost:8085";
            }

            this.initComnCode();

            this.userAuth();
        },
        mounted() {
            //var self = this;
            this.getCustInfoComn(function(){
                //self.getCustCntrctComn(function() {});
            });

            //this.comnCompntControl();
            //this.myFunc.a();
        }

    };
</script>

<!--<style src="./Layout.scss" lang="scss" />-->
