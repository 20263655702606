<template>
    <div class="support wrap">
        <div class="filters">
            <a href="javascript:void(0);" class="filters__item" :class="tabNo == 0 ? 'active' : ''" @click="tabNo = 0">공지사항</a>
            <a href="javascript:void(0);" class="filters__item" :class="tabNo == 1 ? 'active' : ''" @click="tabNo = 1">자주하는 질문</a>
        </div>
        <div v-show="tabNo == 0">
            <keep-alive>
                <component v-bind:is="comp"></component>
            </keep-alive>
            <div class="notice" v-show="boardDetail.id == null">
                <div class="layout__half--wrap">
                    <div class="layout__half--group notice__side-space--wrap">
                        <div class="notice__side-space--group">
                            <h2 class="menu__title">비드톡톡 공지사항</h2>
                        </div>
                    </div>
                    <div class="layout__half--group notice__cont--wrap">
                        <ul class="table__subject notice__cont--subject">
                            <li class="table__row notice__cont--row num">번호</li>
                            <li class="table__row notice__cont--row title">제목</li>
                            <li class="table__row notice__cont--row date">작성일</li>
                        </ul>
                        <ul class="table__cont--wrap notice__cont--group" v-for="(item,index) in displayedBoardList" :key="item.value">
                            <!-- 상단 고정 > li에 important 클래스 추가  -->
                            <!-- 새글 뱃지 > li에 new 클래스 추가  -->
                            <li class="table__cont--item notice__cont--item important new"  v-if="item.topNoticeYn === 'Y'" :class="{'important': true, 'new': isToday(item.firstInputDt)}" @click="openBoard(item)">
                                <span class="table__row notice__cont--row num">[공지]</span>
                                <div class="table__row notice__cont--row title"><a class="txt__title">{{ item.boardTitle }}</a></div>
                                <span class="table__row notice__cont--row date">{{$moment(item.firstInputDt).format('YY-MM-DD') }}</span>
                            </li>
                            <li class="table__cont--item notice__cont--item" v-if="item.topNoticeYn !== 'Y'" :class="{'new': isToday(item.firstInputDt)}" @click="openBoard(item)">
                                <span class="table__row notice__cont--row num">{{boardList.length - (currentPage - 1) * itemsPerPage - index }}</span>
                                <div class="table__row notice__cont--row title"><a class="txt__title">{{ item.boardTitle }}</a></div>
                                <span class="table__row notice__cont--row date">{{$moment(item.firstInputDt).format('YY-MM-DD') }}</span>
                            </li>
                        </ul>
                        <div class="pagination notice__pagination">
                            <button type="button" class="prev" :disabled="!hasPreviousPage"  @click="currentPage--"><span></span></button>
                            <ul>
                                <li v-for="pageNumber in pageNumbers" :key="pageNumber" :class="{ active: pageNumber === currentPage }">
                                    <a href="#" onclick="return false" @click="currentPage = pageNumber">{{ pageNumber }}</a>
                                </li>
                            </ul>
                            <button type="button" class="next" :disabled="!hasNextPage" @click="currentPage++"><span></span></button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="layout__half--wrap wrap notice-view" v-show="boardDetail.id != null">
                <div class="layout__half--group notice__side-space--wrap notice-view__side">
                    <div class="notice__side-space--group notice-view__group">
                        <h2 class="menu__title">{{boardDetail.boardTitle}}</h2>
                        <p class="txt__sub date">{{$moment(boardDetail.firstInputDt).format('YY-MM-DD') }}</p>
                    </div>
                </div>
                <div class="layout__half--group notice__cont--wrap notice-view__cont">
                    <div class="layout__detaile-page--cont introduce-view__cont">
                        <p class="layout__detaile-page--txt" v-html="boardDetail.boardContent"></p>
                    </div>
                    <div class="layout__detaile-page--list" @click="boardDetail = {}">
                        <button type="button" class="btn-basic btn-line-cw">
                            <span>목록</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div v-show="tabNo == 1">
            <div class="faq">
                <div class="top">
                    <div class="filter faq__filter"  @click="select($event)">
                        <a href="javascript:void(0);" class="filter__item faq__filter--item" :class="item.comnCdNm == selectFilter ? 'active':''" v-for="item in custBoardCdList" v-bind:key="item.value" v-bind:data-filter="item.comnCd">{{item.comnCdNm}}</a>
                    </div>
                </div>
                <ul class="accordion">
                    <li class="accordion__item faq__group" data-filter="all one" v-for="item in boardFaq"  v-bind:key="item.value">
                        <div class="accordion__head faq__head" @click="toggleAccordion($event)">
                            <a href="javascript:void(0);" ><h2 class="txt__title">{{item.boardTitle}}</h2></a>
                        </div>
                        <div class="accordion__body faq__body">
                            <p class="txt__sub" v-html="item.boardContent">  </p>
                        </div>
                    </li>
                </ul>
                <div class="btn__moveTop">
                    <a href="#" onclick="return false" @click="moveTop"></a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Notice",
        metaInfo: {
            title: '[비드톡톡 공지사항] - 전자입찰 나라장터(g2b) 공고 투찰 낙찰',
            link: [{ rel: "canonical", href: 'https://www.bidtok.co.kr/' }],
            meta: [
                { vmid: "description", name: 'description', content: '비드톡톡에 관한 공지사항을 확인해 보세요! 비드톡톡의 업데이트 소식, 이벤트 소식, 새로운 기능의 소개, 나라장터 소식 등 비드톡톡의 중요한 공지사항을 확인하실 수 있습니다.' },
                { vmid: "og:title", property: 'og:title', content: '[비드톡톡 공지사항] - 전자입찰 나라장터(g2b) 공고 투찰 낙찰' },
                { vmid: "og:url", property: 'og:url', content: 'https://www.bidtok.co.kr/' },
                { vmid: "og:description", property: 'og:description', content: '비드톡톡에 관한 공지사항을 확인해 보세요! 비드톡톡의 업데이트 소식, 이벤트 소식, 새로운 기능의 소개, 나라장터 소식 등 비드톡톡의 중요한 공지사항을 확인하실 수 있습니다.' },
                { vmid: "og:image", property: "og:image" , content : "favicon.ico"},
            ]
        },
        data(){
            return{
                tabNo: 0,
                custBoardCdList: [],
                selectFilter : '전체',


                boardList : [],
                boardFaq: [],
                boardDetail : {},
                currentPage: 1,
                itemsPerPage: 10,

                comp: 'Board'
            }
        },

        watch: {
            boardDetail() {
                window.scrollTo({ top: 0, behavior: 'smooth' })
            },
            currentPage() {
                window.scrollTo({ top: 0, behavior: 'smooth' });
            }
        },

        computed: {

            pageNumbers() {
                const maxVisiblePages = window.innerWidth <= 800 ? 5 : 10;
                const firstVisiblePage = Math.max(1, this.currentPage - Math.floor(maxVisiblePages / 2));
                const lastVisiblePage = Math.min(this.totalPages, firstVisiblePage + maxVisiblePages - 1);
                return Array.from({ length: lastVisiblePage - firstVisiblePage + 1 }, (_, i) => firstVisiblePage + i);
            },

            //페이징 시작
            displayedBoardList() {


                //최상단고정 세팅
                // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                const sortedList = this.boardList.sort((a, b) => {
                    if (a.topNoticeYn === 'Y' && b.topNoticeYn !== 'Y') {
                        return -1;
                    } else if (a.topNoticeYn !== 'Y' && b.topNoticeYn === 'Y') {
                        return 1;
                    }
                    return 0;
                });

                const startIndex = (this.currentPage - 1) * this.itemsPerPage;
                const endIndex = startIndex + this.itemsPerPage;
                return sortedList.slice(startIndex, endIndex);

            },

            totalPages() {
                return Math.ceil(this.boardList.length / this.itemsPerPage);
            },

            hasPreviousPage() {
                return this.currentPage > 1;
            },

            hasNextPage() {
                return this.currentPage < this.totalPages;
            },
            //페이징 끝

        },

        methods: {
            openBoard(item){
                this.boardDetail = item;
            },
            moveTop() {
                //페이지 이동
                window.scroll({top: 0, left: 0,behavior: 'smooth'});
            },
            toggleAccordion(event) {
                const accordionHead = event.currentTarget;
                accordionHead.classList.toggle('active');
            },
            select(event) {
                const target = event.target;
                this.selectFilter = event.target.dataset.filter;

                if (target.classList.contains('filter__item')) {
                    const button = document.querySelectorAll('.filter__item');
                    const item = document.querySelectorAll('.accordion__item');
                    button.forEach(element => {
                        element.classList.remove('active');
                    });
                    target.classList.add('active');

                    const filter = target.dataset.filter;

                    for (let i = 0; i < this.boardFaq.length; i++) {
                        if (filter === '100') {
                            item[i].style.display = 'block';
                        } else {
                            if (this.boardFaq[i].boardCategoryCd === filter) {
                                item[i].style.display = 'block';
                            } else {
                                item[i].style.display = 'none';
                            }
                        }
                    }
                }
            },

            //자주하는질문 COMN_CODE 호출
            getNotiStatCd(){
                var params = {
                    "comnCdGroupId": "M_BOARD_CATEGORY_CD",
                };
                this.$http.post('/api/comnCode/list',params)
                // eslint-disable-next-line no-unused-vars
                    .then(res => {
                        var comnCodeList = res.data.comnCodeList;
                        for (var code in comnCodeList) {
                            if(parseInt(comnCodeList[code].comnCd) >= 100  && parseInt(comnCodeList[code].comnCd)  < 200){
                                // var obj = {
                                //     id: code[i].id,
                                //     notiNo: code[i].notiNo,
                                //     baseAmt: code[i].baseAmt,
                                //     notiNm: code[i].notiNm,
                                //     anlsBddprAmt: anlsBddprAmt,
                                // }
                                this.custBoardCdList.push(comnCodeList[code]);
                            }
                        }
                    })
                    .catch(error => {console.log(error);}
                    );

            },

            //자주하는 질문 호출
            fnSearchBoardFaq(){
                this.boardFaq = [];

                var _limit = 9999;
                var _page = 0;

                var params = {
                    "pageUnit": _limit,
                    //페이지당 보여줄 수
                    "pageIndex": _page,
                    //마지막 index
                    "strtRecordIntex": _limit * _page,
                    "boardCd": 1
                };

                this.$http.post('/api/board/boardlist',params)
                // eslint-disable-next-line no-unused-vars
                    .then(res => {
                        var data = res.data.boardList;

                        this.boardFaq = data;
                    })

                    .catch(error => {console.log(error);}
                    );

            },

            goMenu(menuUrl, id) {
                if (this.$route.path != "/"+menuUrl) // 현재 route와 다른 경우만 페이지 이동
                    this.$router.push('/'+menuUrl+'/'+id);
            },


            //오늘날짜 확인
            isToday(date) {
                const today = new Date();
                const inputDate = new Date(date);
                return (
                    inputDate.getFullYear() === today.getFullYear() &&
                    inputDate.getMonth() === today.getMonth() &&
                    inputDate.getDate() === today.getDate()
                );
            },

            fnSearchBoard(){

                var params = {
                    pageUnit: 9999,
                    pageIndex: this.currentPage,
                    strtRecordIntex: (this.currentPage - 1) * this.itemsPerPage,
                    boardCd: 0,
                };

                this.$http.post('/api/board/boardlist',params)
                // eslint-disable-next-line no-unused-vars
                    .then(res => {
                        this.boardList = res.data.boardList;
                        // this.boardList = this.boardList.filter(item => item.noticeUseYn === 'Y');

                    })
                    .catch(error => {console.log(error);}
                    );

            },

        },
        created() {

        },

        mounted() {
            this.getNotiStatCd();
            this.fnSearchBoardFaq();
            this.fnSearchBoard();

            // 페이지네이션 클릭 이벤트
            let pagenation = document.querySelectorAll('.pagination > ul > li');
            pagenation.forEach(function (element) {
                element.addEventListener('click', function(){
                    for (let i = 0; i < pagenation.length; i++) {
                        pagenation[i].classList.remove('active');
                    }
                    element.classList.add('active');
                    window.scroll({top: 0, left: 0,behavior: 'smooth'});
                });
            });
        }
    }


</script>



<!--<style src="./NotiSuggest.scss" lang="scss" scoped />-->