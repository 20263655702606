<template>
    <div class="layout__half--wrap wrap notice-view">
        <div class="layout__half--group notice__side-space--wrap notice-view__side">
            <div class="notice__side-space--group notice-view__group">
                <h2 class="menu__title">{{boardContentList.boardTitle}}</h2>
                <p class="txt__sub date">{{$moment(boardContentList.firstInputDt).format('YY-MM-DD') }}</p>
            </div>
        </div>
        <div class="layout__half--group notice__cont--wrap notice-view__cont">
            <div class="layout__detaile-page--cont introduce-view__cont">
                <p class="layout__detaile-page--txt" v-html="boardContentList.boardContent"></p>
            </div>
            <div class="layout__detaile-page--list" @click="goMenu('tokStory')">
                <button type="button" class="btn-basic btn-line-cw">
                    <span>목록</span>
                </button>
            </div>
        </div>
    </div>


<!--    <div class="notice_view">-->
<!--        <div class="inner">-->
<!--           <div class="layout__sideByside">-->
<!--               <div class="left">-->
<!--                   <p class="txt__title">{{boardContentList.boardTitle}}</p>-->
<!--                   <span class="txt__date">{{$moment(boardContentList.firstInputDt).format('YY-MM-DD') }}</span>-->
<!--               </div>-->
<!--               <div class="right">-->
<!--                   <p class="txt__cont" v-html="boardContentList.boardContent"></p>-->
<!--               </div>-->
<!--           </div>-->
<!--            <div class="bottom" @click="goMenu('notice')">-->
<!--                <button type="button" class="btn__init">-->
<!--                    <span>목록</span>-->
<!--                </button>-->
<!--            </div>-->
<!--        </div>-->
<!--    </div>-->
</template>


<script>
    export default {
        name: "NoticeView",

        data(){
            return{
                boardList : [],
                boardContentList : {}
            }
        },

        methods: {
            goMenu(menuUrl) {
                if (this.$route.path != "/"+menuUrl) // 현재 route와 다른 경우만 페이지 이동
                    this.$router.push('/'+menuUrl);
            },

            fnSearchBoard(){
                var params = {
                    "id": this.$route.params.id
                };

                this.$http.post('/api/board/boardDetaillist',params)
                // eslint-disable-next-line no-unused-vars
                    .then(res => {
                        this.boardContentList = res.data.boardDetailList;
                        // this.boardList = res.data.boardList;
                        // this.boardList.forEach(item => {
                        //    if(item.id == this.$route.params.id) {
                        //        this.boardContentList = item
                        //    }
                        // });
                        // console.log(this.boardContentList);
                    })

                    .catch(error => {console.log(error);}
                    );

            },
        },
        created() {

        },

        mounted() {
            this.fnSearchBoard();

            const headerNoticeElement = document.getElementById("tokStory");
            if (headerNoticeElement) {
                headerNoticeElement.classList.add("active");
            }
        }
    }


</script>



<!--<style src="./TokStoryView.scss" lang="scss" scoped />-->