<template>
    <div>
        <div class="howto-detail wrap analysis pc">
            <div class="howto-detail--filters filters analysis__filter">
                <a href="javascript:void(0);" class="filters__item" :class="tabNo == 0 ? 'active' : ''" @click="tabNo = 0">서비스 가이드</a>
                <a href="javascript:void(0);" class="filters__item" :class="tabNo == 1 ? 'active' : ''" @click="tabNo = 1">앱 사용가이드 영상</a>
            </div>

            <!-- 서비스 가이드 howto-pdf -->
            <div class="howto-pdf" v-show="tabNo==0">
                <div class="pdf-img"></div>
            </div>
            <!-- /서비스 가이드 howto-pdf -->

            <!--  앱 사용 가이드 howto-video -->
            <div class="howto-video" v-show="tabNo==1">
                <div class="howto-video__group">
                    <p class="howto-video__list--title"><i></i>재생목록</p>
                    <ul class="howto-video__list">
                        <li class="howto-video__list--item radio--btn">
                            <input type="radio" name="radio-style1" id="r2-1" @click="timeJump(0)" />
                            <label for="r2-1"><i>01</i>로그인방법</label>
                        </li>
                        <li class="howto-video__list--item radio--btn">
                            <input type="radio" name="radio-style1" id="r2-2" @click="timeJump(75)" />
                            <label for="r2-2"><i>02</i>홈메뉴</label>
                        </li>
                        <li class="howto-video__list--item radio--btn">
                            <input type="radio" name="radio-style1" id="r2-7" @click="timeJump(162.5)" />
                            <label for="r2-7"><i>03</i>맞춤공고</label>
                        </li>
                        <li class="howto-video__list--item radio--btn">
                            <input type="radio" name="radio-style1" id="r2-3"  @click="timeJump(246.5)" />
                            <label for="r2-3"><i>04</i>분석실</label>
                        </li>
                        <li class="howto-video__list--item radio--btn">
                            <input type="radio" name="radio-style1" id="r2-4"  @click="timeJump(298)" />
                            <label for="r2-4"><i>05</i>마이메뉴</label>
                        </li>
                        <li class="howto-video__list--item radio--btn">
                            <input type="radio" name="radio-style1" id="r2-5"  @click="timeJump(376)" />
                            <label for="r2-5"><i>06</i>분석요청</label>
                        </li>
                        <li class="howto-video__list--item radio--btn">
                            <input type="radio" name="radio-style1" id="r2-6"  @click="timeJump(515.5)" />
                            <label for="r2-6"><i>07</i>웹/앱 연동</label>
                        </li>
                    </ul>
                </div>
                <div class="video">
                    <youtube
                            id="youtubePlayer"
                            :video-id="videoId"
                            :player-vars="playerVars"
                            width="100%"
                            height="522px"
                            @autoplay="autoplay"
                            @ready="ready"
                            @playing="playing"
                            @ended="ended"
                            @error="error"
                            ref="youtube" />
                </div>
            </div>
            <!--  /앱 사용 가이드 howto-video -->

            <div class="btn__moveTop" @click="moveTop();">
                <a href="#" onclick="return false"></a>
            </div>
        </div>
        <div class="howto-detail wrap tablet ser-detail">
            <h3 class="h3">사용 가이드</h3>
            <a href="web/assets/file/brochure_hori_20240523.pdf" download="비드톡톡 가이드북.pdf" class="howto-detail--move-btn pdf">
                <p class="txt--title">서비스 가이드<i class="ic--view--accordion"></i></p>
                <p class="txt--sub">pdf로 제공되는 <span class="line-break">가이드북 입니다.</span></p>
                <i class="ic--pdf howto-detail--ic"></i>
            </a>
            <a href="https://www.youtube.com/watch?v=NOyU_8ZCKU4" class="howto-detail--move-btn video" target="_blank">
                <p class="txt--title">앱 사용가이드 영상<i class="ic--view--accordion"></i></p>
                <p class="txt--sub"> 유투브로 쉽게 배우는 <span class="line-break">영상 가이드</span></p>
                <i class="ic--video howto-detail--ic"></i>
            </a>
        </div>
    </div>
</template>

<script>
    import Vue from 'vue'
    import VueYoutube from "vue-youtube";

    Vue.use(VueYoutube);

    export default {
        name: "HowtoDetail",
        metaInfo: {
            title: '[비드톡톡 톡스토리] - 전자입찰 나라장터(g2b) 공고 투찰 낙찰',
            link: [{ rel: "canonical", href: 'https://www.bidtok.co.kr/' }],
            meta: [
                { vmid: "description", name: 'description', content: '비드톡톡에 관한 공지사항을 확인해 보세요! 비드톡톡의 업데이트 소식, 이벤트 소식, 새로운 기능의 소개, 나라장터 소식 등 비드톡톡의 중요한 공지사항을 확인하실 수 있습니다.' },
                { vmid: "og:title", property: 'og:title', content: '[비드톡톡 공지사항] - 전자입찰 나라장터(g2b) 공고 투찰 낙찰' },
                { vmid: "og:url", property: 'og:url', content: 'https://www.bidtok.co.kr/' },
                { vmid: "og:description", property: 'og:description', content: '비드톡톡에 관한 공지사항을 확인해 보세요! 비드톡톡의 업데이트 소식, 이벤트 소식, 새로운 기능의 소개, 나라장터 소식 등 비드톡톡의 중요한 공지사항을 확인하실 수 있습니다.' },
                { vmid: "og:image", property: "og:image" , content : "favicon.ico"},
            ]
        },
        data(){
            return{
                tabNo: 0,

                videoId: 'NOyU_8ZCKU4',
                autoplay: 0,
                playerVars: {
                    autoplay: 0,
                    rel:0,
                    mute: 1,
                    cc_lang_pref: "ko",
                    cc_load_policy: 1,
                },
                player : null,
            }
        },

        watch: {
            currentPage() {
                window.scrollTo({ top: 0, behavior: 'smooth' });
            }
        },


        methods: {
            ready(){},
            playing(){},
            ended(){},
            error(){},

            async timeJump(value) {

                var player = this.$refs.youtube.player;
                player.seekTo(value);
                await player.playVideo();
            },

            //페이지 최상단 이동
            moveTop() {
                //페이지 이동
                window.scroll({top: 0, left: 0,behavior: 'smooth'});
            }

        },
        created() {

        },

        mounted() {
            const target = window.location.hash;

            if(target != null){
                this.tabNo = target.substring(1);
            }


        }
    }


</script>



<!--<style src="./TokStory.scss" lang="scss" scoped />-->