<template>
  <div style="text-align: center">
    <a href="https://bidtok.co.kr" class="link__download main__visual--link pc" style="width: 30%; margin: 1.25rem auto;">비드톡톡 바로가기</a>
    <div class="">
      <img src="@/assets/survey/leaflet1.jpg" style="width: 80%; height: auto; margin: 0 auto 2rem;" alt="리플렛">
      <img src="@/assets/survey/leaflet2.jpg" style="width: 80%; height: auto;  margin: 0 auto 2rem;;" alt="리플렛">
    </div>
  </div>
</template>
<script>
export default {
  name: "bidding",
  metaInfo: {
    title: '[비드톡톡] 조달청 차세대 나라장터(g2b) 전자입찰 공고 낙찰',
    link: [{ rel: "canonical", href: 'https://www.bidtok.co.kr/' }],
    meta: [
      { vmid: "description",name: 'description', content: '차세대 나라장터, 조달청, 나라장터(g2b), 전차입찰, 전자조달, 공고검색, 입찰공고, 입찰정보, 개찰결과, 최종낙찰, bid 비드톡톡에서 알아보세요.' },
      { vmid: "og:title",property: 'og:title', content: '[비드톡톡] 조달청 나라장터(g2b) 전자입찰 공고 낙찰' },
      { vmid: "og:url",property: 'og:url', content: 'https://www.bidtok.co.kr/' },
      { vmid: "og:description",property: 'og:description', content: '조달청, 차세대 나라장터(g2b), 전차입찰, 전자조달, 공고검색, 입찰공고, 입찰정보, 개찰결과, 최종낙찰, bid 비드톡톡에서 알아보세요.' },
      { vmid: "og:image",property: "og:image" , content : "favicon.ico"},
    ]
  },
  data() {
    return {
    }
  },
  methods: {
  }
};
</script>