export default {
    notiTable: {
        //공고상세
        noti: [

        ],

        //첨부파일
        notiAtchmnflList:[

        ],

        //내 낙찰순위
        notiBddprPrtcCmp:[

        ],

        //복수예비가
        notiCompnoList:[

        ],
    },
}
