import { render, staticRenderFns } from "./Notifications.vue?vue&type=template&id=1158187c&xmlns%3Adiv=http%3A%2F%2Fwww.w3.org%2F1999%2Fhtml"
import script from "./Notifications.vue?vue&type=script&lang=js"
export * from "./Notifications.vue?vue&type=script&lang=js"
import style0 from "./Notifications.scss?vue&type=style&index=0&prod&lang=scss&external"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports