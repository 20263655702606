//import config from '@/config';

export default {
  notiTable: {
    selected: [],
    search: '',
    headers: [
      { text: '~98.9', value: '1' },
      { text: '99.0~99.4', value: '2' },
      { text: '99.5~99.9', value: '3' },
      { text: '100.0~100.4', value: '4'},
      { text: '100.5~100.9', value: '5'},
      { text: '101.0~', value: '6'}
    ],
    noti: [
    ],
  },
};
