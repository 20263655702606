<template>
    <div class="ser-detail wrap download">
        <h3 class="h3 f-i">언제 어디서나 비드톡톡 <span>앱 다운로드</span></h3>
        <h2 class="h2 f-i">언제 어디서든지 모바일로 공고를 <span>톡톡!</span></h2>
        <h4 class="h4 f-i">PC에서 하던 서비스 그대로 비드톡톡 모바일에서 간편하게 공고 확인, 분석 요청 서비스까지 한번에 사용할 수 있습니다. <span
                class="line-break--01">지금 바로 다운로드 후 모바일 비드톡톡 편리하게 사용하세요.</span></h4>
        <div class="ser-detail__container dowlod-guide__wrap f-i-d">
            <div class="popup__item--group dowlod-guide__group">
                <div class="popup__item--body dowlod-guide__group--body">
                    <div class="dowlod-guide__box">
                        <div class="dowlod-guide__item mail">
                            <div class="dowlod-guide--img--title mail"></div>
                            <p class="dowlod-guide--txt h3 dowlod-guide--img--num one">문자로 앱 다운로드 링크 받기</p>
                            <p class="dowlod-guide--txt h4">휴대폰번호를 입력해주시면 <br> 앱 다운로드 링크를 문자로 보내드려요.</p>
                            <div class="input dowlod-guide--input">
                                <input type="text" class="input--valid disable" placeholder="휴대폰번호를 입력해 주세요." v-model="phoneNum">
                            </div>
                            <div class="dowlod-guide__element"><div class="input--check dowlod-guide--check"><input type="checkbox" id="check11"  v-model="indvdlinfoAuthz"><label for="check11"></label></div><a href="#" onclick="return false" @click="indvdlinfoAuthz = !indvdlinfoAuthz"><span class="dowlod-guide--txt h4">(필수) 개인정보 수집 및 이용 동의</span></a><p class="dowlod-guide--txt h5">앱 다운로드 링크 전송을 위해 전화번호를 수집하며,<br>수집일로부터 3개월간 보관됩니다.</p></div>
                            <div class="dowlod-guide--btn">
                                <a href="#" onclick="return false" class="btn-basic btn-line-cb" @click="sendSms()">문자 발송</a>
                            </div>
                        </div>
                        <div class="dowlod-guide__item empty"></div>
                        <div class="dowlod-guide__item qr">
                            <div class="dowlod-guide--img--title lens"></div>
                            <p class="dowlod-guide--txt h3  dowlod-guide--img--num two">QR 코드 스캔하기</p>
                            <p class="dowlod-guide--txt h4">카메라로 QR코드를 스캔해 주세요.</p>
                            <div class="dowlod-guide--img qr"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "ServiceDetailDownload",
        data() {
            return {
                phoneNum : "",  //핸드폰번호
                indvdlinfoAuthz : false,    //개인정보 약관 동의 여부
            };
        },
        methods: {
            //문자전송
            async sendSms() {
                if(this.phoneNum == ""){
                    this.$toast.success("휴대폰번호를 입력해 주세요.");
                    return ;
                }
                if(this.indvdlinfoAuthz == false){
                    this.$toast.success("개인정보 수집에 동의해 주세요.");
                    return ;
                }

                var clintIP = await this.$getPublicIP();

                var params = {
                    "recptnTelno": this.phoneNum.toString(),
                    "clientIp": clintIP,
                    "indvdlinfoAuthzYn": this.indvdlinfoAuthz ? 'Y' : 'N'
                };

                this.$http.post('/api/launcher/extrnl/sendAppLink',params)
                // eslint-disable-next-line no-unused-vars
                    .then(res => {
                        this.$toast.success("문자 발송이 완료되었습니다.");
                        this.phoneNum = "";
                        this.indvdlinfoAuthz = false;
                    })

                    .catch(error => {
                            console.log(error);
                        }
                    );

            },
        },
        created() {

        },
        mounted() {
            const fiItem = document.querySelectorAll('.f-i');
            setTimeout(function () {
                let i = 0;
                function fiItemAddClass() {
                    if (i < fiItem.length) {
                        fiItem[i].classList.add('active');
                        i++;
                        setTimeout(fiItemAddClass, 200);
                    }
                }
                fiItemAddClass();
            }, 1200);

            setTimeout(function () {
                document.querySelector('.f-i-d').classList.add('active');
            }, 2500);
        }
    }


</script>



<!--<style src="@/IntroduceView.scss" lang="scss" scoped />-->