<template>
    <div class="pay-info-enter pay-info" :class="isMobile ? 'rsp': ''">
        <div class="pay-info-enter--container">
            <div class="pay-info-enter--group">
                <h3 class="pay-info-enter--title">결제 정보 확인</h3>
                <ul class="info-enter ful">
                    <li class="info-enter--item">
                        <span class="info-enter--tit">결제 수단</span>
                        <p class="info-enter--cont bt-c-main">무통장입금</p>
                    </li>
                    <li class="info-enter--item">
                        <span class="info-enter--tit">상품명</span>
                        <p class="info-enter--cont bt-c-main">{{payRcptmn.goodNm}}</p>
                    </li>
                    <li class="info-enter--item">
                        <span class="info-enter--tit">입금금액</span>
                        <p class="info-enter--cont bt-c-main">{{payRcptmn.price | currency}}원</p>
                    </li>
                    <li class="info-enter--item">
                        <span class="info-enter--tit vm">은행명/ <i class="line-break"></i>예금주명</span>
                        <p class="info-enter--cont bt-c-main vm">{{payRcptmn.vactBankName}} / {{payRcptmn.vactName}}</p>
                    </li>
                    <li class="info-enter--item">
                        <span class="info-enter--tit vm">무통장입금 <i class="line-break"></i>계좌번호</span>
                        <p class="info-enter--cont bt-c-main vm">{{payRcptmn.vactNum }}</p>
                    </li>
                    <li class="info-enter--item">
                        <span class="info-enter--tit">고객명</span>
                        <p class="info-enter--cont bt-c-main">{{payRcptmn.buyerNm}}</p>
                    </li>
                    <li class="info-enter--item">
                        <span class="info-enter--tit">사업자번호</span>
                        <p class="info-enter--cont bt-c-main">{{payRcptmn.buyerBizNo | bizNo}}</p>
                    </li>
                    <li class="info-enter--item">
                        <span class="info-enter--tit">휴대전화</span>
                        <p class="info-enter--cont bt-c-main">{{payRcptmn.buyerTel | phoneNumber}}</p>
                    </li>
                    <li class="info-enter--item">
                        <span class="info-enter--tit">이메일</span>
                        <p class="info-enter--cont bt-c-main">{{payRcptmn.buyerEmail}}</p>
                    </li>
                </ul>
                <p class="txt--noti bt-bold bt-f17">해당 계좌는 {{payRcptmn.vactDate}} 자정까지<i class="line-break"></i> 유효한 계좌로 미리 입금 부탁드립니다.<br>
                    위 유효 기간이 지난 경우에는<i class="line-break"></i> 다시 [결제하기] 부탁드립니다.</p>
            </div>
            <a href="#" onclick="return false" class="pay-info-enter--btn btn-basic btn-cta" @click="close()">닫기</a>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                payRcptmn: {},
                isMobile: false,
            }
        },
        mounted() {
            this.getPayRcptmnInfo();
            this.scrollScript();
            this.mobileScript();
        },
        methods: {
            mobileScript(){
                const userAgent = navigator.userAgent || navigator.vendor || window.opera;
                // 모바일 기기 User-Agent 문자열의 일부를 체크합니다.
                this.isMobile = /android|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(userAgent.toLowerCase());
                if(!this.isMobile){
                    document.querySelector('body').style.overflowY = 'hidden';
                }
            },
            scrollScript() {
                let fullHight = document.querySelectorAll('.ful');

                function updateVh() {
                    var vh = window.innerHeight * 0.01;
                    fullHight.forEach(function(element) {
                        element.style.setProperty('--vh', vh + 'px');
                    });
                }

                window.addEventListener('resize', updateVh);
                window.addEventListener('load', updateVh);
            },
            getPayRcptmnInfo() {
                this.$get('/api/bilcltMngt/payRcptmn')
                    .then(res => {
                        if(res.status == 'OK'){
                            this.payRcptmn = res.payRcptmn;
                            if(this.payRcptmn.vactDt != null){
                                this.payRcptmn.vactDate = this.$moment(this.payRcptmn.vactDt).format('MM월 DD일');
                            }
                        }
                    })
                    .catch(error => {
                            console.log(error);
                        }
                    );
            },
            close(){
                window.close();
            }
        }
    }
</script>

<style>

</style>