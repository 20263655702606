<template>
    <div>
        <div v-if="loading">
            <div class="loading loading-box">
            <LoadingSpinner></LoadingSpinner>ㅤ
            </div>
        </div>
        <div v-else-if="cntrctHtml" v-html="cntrctHtml"></div>
    </div>
</template>
<style>
    html, body, div, span, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, abbr, address, cite, code, del, dfn, em, img, ins, kbd, q, samp, small, strong, sub, sup, var, b, i, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, figcaption, figure, footer, header, hgroup, menu, nav, section, summary, time, mark, audio, video{margin:0;padding:0;border:0;font-size:100%;font:inherit;vertical-align:baseline;}
    body{line-height:1;background:#fff;font-family:Tahoma, sans-serif;font-size:9.5pt;}
    article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section{display:block;}
    ul, li, ol{list-style:none;}
    blockquote, q{quotes:none;}
    blockquote:before, blockquote:after, q:before, q:after{content:'';content:none;}
    em{font-style:normal;}
    a{margin:0;padding:0;background:transparent;color:#333;text-decoration:none;outline:none;vertical-align:baseline;-webkit-tap-highlight-color:transparent;}
    a:hover{text-decoration:underline;}
    img{vertical-align:top;border:none;}
    form{border:none;}
    table{border-collapse:collapse;border-spacing:0;}
    *, *:before, *:after{box-sizing:border-box;-webkit-box-sizing:border-box;}
    .sy-title{font-size:24pt;font-weight:bold;}
    .sy-title small{font-weight:normal;font-size:65%;}
    .sy-title .sy-icon{margin-right:3px;font-size:90%;}
    h2.sy-title{font-size:16pt;}
    h3.sy-title{font-size:10pt;}
    .sy-icon{font:normal normal normal 14px/1 'FontAwesome';text-rendering:auto;-webkit-font-smoothing:antialiased;transform:translate(0, 0);}
    .sy-grid-fixed{table-layout:fixed;}
    .sy-grid{width:100%;}
    .sy-grid > thead > tr > th, .sy-grid > tbody > tr > th{background-color:#ebf1f4;font-weight:bold;vertical-align:bottom;text-align:center;}
    .sy-grid > thead > tr > th, .sy-grid > tbody > tr > th, .sy-grid > tfoot > tr > th, .sy-grid > thead > tr > td, .sy-grid > tbody > tr > td, .sy-grid > tfoot > tr > td{padding:10px 6px;border-bottom:1px solid #bfbfbf;vertical-align:middle;}
    .sy-grid > tbody > tr:last-child > th, .sy-grid > tbody > tr:last-child > td{border-bottom:0;}
    .sy-grid-border{border:1px solid #bfbfbf;}
    .sy-grid-border > thead > tr > th + th, .sy-grid-border > tbody > tr > th + th, .sy-grid-border > tfoot > tr > th + th, .sy-grid-border > thead > tr > td + td, .sy-grid-border > tbody > tr > td + td, .sy-grid-border > tfoot > tr > td + td{border-left:1px solid #bfbfbf;}
    .sy-grid-border > thead > tr > th:first-child, .sy-grid-border > thead > tr > td:first-child, .sy-grid-border > tbody > tr > th:first-child, .sy-grid-border > tbody > tr > td:first-child{border-left:1px solid #bfbfbf;}
    .sy-grid-border > thead > tr > th:last-child, .sy-grid-border > thead > tr > td:last-child, .sy-grid-border > tbody > tr > th:last-child, .sy-grid-border > tbody > tr > td:last-child{border-right:1px solid #bfbfbf;}
    .sy-grid-border > thead > tr:first-child > th, .sy-grid-border > thead > tr:first-child > td, .sy-grid-border > tbody > tr:first-child > th, .sy-grid-border > tbody > tr:first-child > td{border-top:1px solid #bfbfbf;}
    .sy-grid-border > tbody > tr:last-child > th, .sy-grid-border > tbody > tr:last-child > td{border-bottom:1px solid #bfbfbf;}
    .sy-grid-strip > tbody > tr:nth-child(even) th, .sy-grid-strip > tbody > tr:nth-child(even) td{background-color:#f2f2f2;}
    .sy-grid-hover > tbody > tr:hover > td{background-color:#fffbe1;}
    .sy-grid-hover > tbody > tr.selected > td{background:#76a3e4;}
    .sy-grid-item > tbody > tr > th, .sy-grid-item > tbody > tr > td{padding:6px 2px;vertical-align:middle;}
    .sy-grid-sort > thead > tr > th{padding-left:3px;padding-right:10px;cursor:pointer;}
    .sy-grid-sort > thead > tr > th:after{content:"\f0dc";position:relative;left:7px;font-size:12px;font:normal normal normal 14px/1 FontAwesome;text-rendering:auto;-webkit-font-smoothing:antialiased;-moz-osx-font-smoothing:grayscale;transform:translate(0, 0);color:#1f8ccc;}
    .sy-grid-sort > thead > tr > th.up:after{content:"\f0de";}
    .sy-grid-sort > thead > tr > th.down:after{content:"\f0dd";}
    .sy-grid-expand > tbody > tr.odd{cursor:pointer;}
    .sy-grid-expand > tbody > tr.odd:hover td{background-color:#f9f9f9;}
    .sy-grid-expand > tbody > tr.odd.open td{background-color:#526b7b;color:#fff;}
    .sy-grid-expand > tbody > tr.even.open td{background-color:#f2f2f2;}
    .sy-grid-expand > tbody > tr.even td{display:none;}
    .sy-grid-expand > tbody > tr.even > td{padding:15px 10px 15px 20px;}
    .sy-grid-expand > tbody > tr.even.open td{display:table-cell;}
    .sy-grid-expand > tbody > tr > td > .icon-fold{margin-right:10px;font:normal normal normal 14px/1 FontAwesome;text-rendering:auto;-webkit-font-smoothing:antialiased;-moz-osx-font-smoothing:grayscale;transform:translate(0, 0);}
    .sy-grid-expand > tbody > tr > td >.icon-fold:before{content:"\f067";}
    .sy-grid-expand > tbody > tr.open > td >.icon-fold:before{content:"\f068";}
    .sy-checkbox{position:relative;display:inline-block;}
    .sy-checkbox input{position:absolute;overflow:hidden;clip:rect(0 0 0 0);height:1px;width:1px;margin:-1px;padding:0;border:0;}
    .sy-checkbox label{position:relative;display:inline-block;height:20px;line-height:20px;padding-left:21px;vertical-align:middle;cursor:pointer;}
    .sy-checkbox label:before{content:"\f096";color:#dcdcdc;position:absolute;left:1px;top:0;font:normal normal normal 20px/1 FontAwesome;text-rendering:auto;-webkit-font-smoothing:antialiased;-moz-osx-font-smoothing:grayscale;transform:translate(0, 0);}
    .sy-checkbox label:hover:before{color:#92c7ea;}
    .sy-checkbox input[disabled] + label:before{content:"\f096";color:#d8d8d8;}
    .sy-checkbox input[disabled] + label{color:#d8d8d8;cursor:no-drop;}
    .sy-checkbox input:checked + label:before{content:"\f14a";color:#8aa8bb;}
    .sy-checkbox input:checked:hover + label:before{color:#92c7ea;}
    .sy-checkbox input[disabled]:checked + label:before{content:"\f14a";color:#d8d8d8;}
    .sy-checkbox input[disabled]:checked + label{color:#d8d8d8;cursor:no-drop;}
    .sy-checkbox.sy-checkbox-lg label{height:24px;line-height:24px;padding-left:23px;}
    .sy-checkbox.sy-checkbox-lg label:before{font-size:24px;}
    .sy-checkbox.sy-checkbox-sm label{height:17px;line-height:17px;padding-left:16px;}
    .sy-checkbox.sy-checkbox-sm label:before{top:-1px;font-size:17px;}
    .sy-checkbox.sy-checkbox-xs label{height:12px;line-height:12px;padding-left:11px;}
    .sy-checkbox.sy-checkbox-xs label:before{top:-1px;font-size:13px;}
    .loading {
        z-index: 2;
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
    .clearfix{display:block;zoom:1;}
    .clearfix:before, .clearfix:after{display:block;content:"";}
    .clearfix:after{height:0;clear:both;visibility:hidden;}
    .blue-color{color:#137aff;}
    .white-bgcolor{background:#fff !important;}
    .darkgray-bgcolor2{background:#f5f5f5 !important;}
    .darkgray-bgcolor{background:#e5e5e5 !important;}
    .item-bold{font-weight: 600;}
    .item-undl{text-decoration: underline;}
    .tc{text-align:center !important;}
    .tr{text-align:right !important;}
    .tl{text-align:left !important;}
    .m0{margin:0 !important;}
    .m5{margin:5px !important;}
    .m10{margin:10px !important;}
    .m15{margin:15px !important;}
    .m20{margin:20px !important;}
    .mt0{margin-top:0 !important;}
    .mt5{margin-top:5px !important;}
    .mt10{margin-top:10px !important;}
    .mt15{margin-top:15px !important;}
    .mt20{margin-top:20px !important;}
    .mr0{margin-right:0 !important;}
    .mr5{margin-right:5px !important;}
    .mr10{margin-right:10px !important;}
    .mr15{margin-right:15px !important;}
    .mr20{margin-right:20px !important;}
    .mb0{margin-bottom:0 !important;}
    .mb5{margin-bottom:5px !important;}
    .mb10{margin-bottom:10px !important;}
    .mb15{margin-bottom:15px !important;}
    .mb20{margin-bottom:20px !important;}
    .ml0{margin-left:0 !important;}
    .ml5{margin-left:5px !important;}
    .ml10{margin-left:10px !important;}
    .ml15{margin-left:15px !important;}
    .ml20{margin-left:20px !important;}
    .p0{padding:0 !important;}
    .p5{padding:5px !important;}
    .p10{padding:10px !important;}
    .p15{padding:15px !important;}
    .p20{padding:20px !important;}
    .pt0{padding-top:0 !important;}
    .pt5{padding-top:5px !important;}
    .pt10{padding-top:10px !important;}
    .pt15{padding-top:15px !important;}
    .pt20{padding-top:20px !important;}
    .pr0{padding-right:0 !important;}
    .pr5{padding-right:5px !important;}
    .pr10{padding-right:10px !important;}
    .pr15{padding-right:15px !important;}
    .pr20{padding-right:20px !important;}
    .pb0{padding-bottom:0 !important;}
    .pb5{padding-bottom:5px !important;}
    .pb10{padding-bottom:10px !important;}
    .pb15{padding-bottom:15px !important;}
    .pb20{padding-bottom:20px !important;}
    .pl0{padding-left:0 !important;}
    .pl5{padding-left:5px !important;}
    .pl10{padding-left:10px !important;}
    .pl15{padding-left:15px !important;}
    .pl20{padding-left:20px !important;}
    .lh120p{line-height:120% !important;}
    .lh140p{line-height:140% !important;}
    .lh160p{line-height:160% !important;}
    .font18{font-size: 18px!important;}

    /* @page {size:210mm 297mm portrait;margin:22mm 22mm 42mm;} */
    .page{width: 210mm;min-height: 297mm;padding: 20mm;margin: 10mm auto;border: 1px #D3D3D3 solid;
        border-radius: 5px;background: #fff;box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);}
    @page {size:210mm 297mm; margin:0;}
    @media print{
        .page {width: initial;min-height: initial;margin: 0;border: initial;
            border-radius: initial;background: initial;box-shadow: initial;page-break-after: always;}
        *{-webkit-print-color-adjust:exact;}
    }

    .container{display: block;width:auto;margin: 0 auto}
    .docmngmt-wrap h1{margin:0 0 40px;letter-spacing:4px;}
    .docmngmt-wrap h1 sub{float: right;font-size: 10pt;font-weight: normal;}
    .docmngmt-wrap .sy-grid th, .docmngmt-wrap .sy-grid td, .docmngmt-wrap .sy-grid td>*{font-size: 9.5pt;}
    .docmngmt-wrap h2{margin-top:50px;font-size: 11pt;}
    .docmngmt-wrap h2 span{display: inline-block;}
    .docmngmt-wrap h2 span:first-child{float: left;}
    .docmngmt-wrap h2 span:last-child{float: right;}
    .docmngmt-wrap .docmngmt-wrap_date{margin: 60px 0;font-size: 11pt;}
    .docmngmt-wrap .page:last-child p{text-align: justify;}
</style>

<script>
    import LoadingSpinner from '@/components/common/AgreeLoadingSpinner.vue';

    export default {
        components: { LoadingSpinner },
        name: "CustCntrct",
        data() {
            return {
                custCntrct: {},
                loginInfo: {},
                loading: true,
                cntrctHtml: "",
            };
        },

        async created() {
            await this.getCustCntrct();
            this.loading = false;
        },

        methods: {

            setDynamicViewport() {
                const viewportMeta = document.getElementById('dynamic-viewport');
                if (viewportMeta) {
                    viewportMeta.setAttribute('content', '');
                }
            },

            async getCustCntrct() {
                try {
                    var param = {

                    };
                    const response = await this.$post('/api/cust/custCntrct/' + this.$route.params.id,param);
                    this.custCntrct = response.custCntrct;
                    console.log(response.custCntrct);

                    if(this.custCntrct != null){
                        this.cntrctHtml = this.custCntrct.cntrctHtml;
                    }

                } catch (error) {
                    console.log(error);
                }
            }
        }
    }
</script>
