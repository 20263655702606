<template>
    <div>
        <p>Kakao Login Access Pages</p>
    </div>
</template>

<script>
    import axios from 'axios';

    export default {
        async mounted() {
            const code = this.$route.query.code;
            const kakaoHeader = {
                'Authorization': process.env.VUE_APP_KAKAO_ADMIN,
                'Content-type': 'application/x-www-form-urlencoded;charset=utf-8',
            };
            try {
                const data = {
                    grant_type: 'authorization_code',
                    client_id: 'ed90614904999727c87e7d776fe0131e',
                    redirect_uri:  `${window.location.origin}/login/kakao`,
                    code: code,
                };
                const queryString = Object.keys(data)
                    .map(k => encodeURIComponent(k) + '=' + encodeURIComponent(data[k]))
                    .join('&');
                const result = await axios.post('https://kauth.kakao.com/oauth/token', queryString, { headers: kakaoHeader });

                var params = {
                    "accessToken": result.data['access_token'],
                    //"fbToken": params["fbToken"].toString() // firebase token
                };

                this.$http.post('/api/kakao/webLogin',params)
                    .then(res => {
                        localStorage.setItem("authToken",res.data.authToken);
                        localStorage.setItem("refreshToken",res.data.refreshToken);
                        localStorage.setItem("custInfo",JSON.stringify(res.data.cust));
                        //this.saveStateToStorage(res.data.authToken);
                        this.existToken = true;
                        this.custInfo = JSON.parse(localStorage.getItem("custInfo"));

                        this.$socket.emit('login',{
                            id: this.custInfo.id,
                            alias: this.custInfo.cmpNm

                        });

                        // document.querySelector('.login-bar--wrap').classList.toggle('on');
                        opener.location.replace("/home");
                        window.close();
                        // this.$router.replace('/analysis');
                    })
                    .catch(error => {
                            console.log(error);
                            // document.querySelector('.alert-text').innerHTML = "계정정보를 다시 확인해주세요";
                            // document.querySelector('.alert-text').classList.add('on');
                        }
                    );
                return result;
            } catch (e) {
                alert('실패');
                console.log(e);
                return e;
            }
        },

    };
</script>