<template>
    <div class="event wrap">
        <h2 class="menu__title">이벤트</h2>
        <div class="filter">
            <a href="#" class="filter__item active" data-filter="ing">진행중 이벤트</a>
            <a href="#" class="filter__item" data-filter="end">종료 이벤트</a>
        </div>

        <ul class="event__list--wrap" data-filter="ing">
            <li class="layout__item event__list--item">
                <div class="event__list--visual visual-img01"></div>
                <div class="event__info--wrap">
                    <p class="txt__title">비드톡톡 서비스 오픈!!</p>
                    <span class="txt__date">23-05-27<span>~</span>23-06-15</span>
                    <div class="event__info--view">
<!--                        <img src="./assets/images/ic_view.svg" alt="눈모양 아이콘">-->
                        <span class="txt__count">12,574</span>
                    </div>
                </div>
            </li>
            <li class="layout__item event__list--item">
                <div class="event__list--visual visual-img01"></div>
                <div class="event__info--wrap">
                    <p class="txt__title">비드톡톡 서비스 오픈!!</p>
                    <span class="txt__date">23-05-27<span>~</span>23-06-15</span>
                    <div class="event__info--view">
<!--                        <img src="./assets/images/ic_view.svg" alt="눈모양 아이콘">-->
                        <span class="txt__count">12,574</span>
                    </div>
                </div>
            </li>
            <li class="layout__item event__list--item">
                <div class="event__list--visual visual-img01"></div>
                <div class="event__info--wrap">
                    <p class="txt__title">비드톡톡 서비스 오픈!!</p>
                    <span class="txt__date">23-05-27<span>~</span>23-06-15</span>
                    <div class="event__info--view">
<!--                        <img src="./assets/images/ic_view.svg" alt="눈모양 아이콘">-->
                        <span class="txt__count">12,574</span>
                    </div>
                </div>
            </li>
        </ul>
        <ul class="event__list--wrap" data-filter="end">
            <li class="layout__item event__list--item">
                <div class="event__list--visual visual-img01"></div>
                <div class="event__info--wrap">
                    <p class="txt__title">비드톡톡 서비스 오픈!!</p>
                    <span class="txt__date">23-05-27<span>~</span>23-06-15</span>
                    <div class="event__info--view">
<!--                        <img src="./assets/images/ic_view.svg" alt="눈모양 아이콘">-->
                        <span class="txt__count">12,574</span>
                    </div>
                </div>
            </li>
            <li class="layout__item event__list--item">
                <div class="event__list--visual visual-img01"></div>
                <div class="event__info--wrap">
                    <p class="txt__title">비드톡톡 서비스 오픈!!</p>
                    <span class="txt__date">23-05-27<span>~</span>23-06-15</span>
                    <div class="event__info--view">
<!--                        <img src="./assets/images/ic_view.svg" alt="눈모양 아이콘">-->
                        <span class="txt__count">12,574</span>
                    </div>
                </div>
            </li>
            <li class="layout__item event__list--item">
                <div class="event__list--visual visual-img01"></div>
                <div class="event__info--wrap">
                    <p class="txt__title">비드톡톡 서비스 오픈!!</p>
                    <span class="txt__date">23-05-27<span>~</span>23-06-15</span>
                    <div class="event__info--view">
<!--                        <img src="./assets/images/ic_view.svg" alt="눈모양 아이콘">-->
                        <span class="txt__count">12,574</span>
                    </div>
                </div>
            </li>
        </ul>
        <a href="#" onclick="return false" class="btn__moveTop" @click="moveTop();">
        </a>
    </div>
</template>

<!--추후작업시 눈모양 필요 2023.08.07-->

<script>
    export default {
        name: "Event",
        methods: {
            //페이지 최상단 이동
            moveTop() {
                //페이지 이동
                window.scroll({top: 0, left: 0,behavior: 'smooth'});
            }
        },
        created() {

        },

        mounted() {
            //게시글 필터
            const button = document.querySelectorAll('.filter__item');
            const item = document.querySelectorAll('.event__list--wrap');
            button.forEach(element => element.addEventListener('click', (e) => {
                e.preventDefault();
                //버튼의 dataset값을 매개변수로 전달
                const filter = e.target.dataset.filter;
                arrayItem(filter);
                //스타일링
                for (let i = 0; i < button.length; i++) {
                    button[i]
                        .classList
                        .remove('active');
                }
                element.classList.toggle('active');
            }));
            function arrayItem(filter) {
                item.forEach(ele => {
                    const itemFilters = ele.getAttribute('data-filter')
                    console.log(itemFilters + '02');
                    if (itemFilters.includes(filter) || filter === 'all') {
                        ele.style.display = 'inline-block';
                    } else {
                        ele.style.display = 'none';
                    }
                });
            }
            // /게시글 필터
        }
    }


</script>



<!--<style src="./Event.scss" lang="scss" scoped />-->