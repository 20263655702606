import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({

  namespace: true,
  state: {
    drawer: false,
    notiStatList: [],
    areaCodeList: [],
    licnsCodeList: [],
    rpsntOrgnzCdList: [],
    notiAnlstList: [],
    cdltStList: [],
  },
  mutations: {
    toggleDrawer(state) {
      state.drawer = !state.drawer;
    },
    setNotiStatList(state, notiStatList) {
      state.notiStatList = notiStatList;
    },
    setAreaCodeList(state, areaCodeList) {
      state.areaCodeList = areaCodeList;
    },
    setLicnsCodeList(state, licnsCodeList) {
      state.licnsCodeList = licnsCodeList;
    },
    setRpsntOrgnzCdList(state, rpsntOrgnzCdList) {
      state.rpsntOrgnzCdList = rpsntOrgnzCdList;
    },
    setNotiAnlstList(state, notiAnlstList) {
      state.notiAnlstList = notiAnlstList;
    },
    setCdltStList(state, cdltStList) {
      state.cdltStList = cdltStList;
    },
  },
  actions: {
    TOGGLE_DRAWER({ context }) {
      context.commit('toggleDrawer');
    },
    SET_NOTI_ANLST({ context }) {
      context.commit('setNotiAnlstList');
    },
  },
  getters: {
    DRAWER_STATE(state) {
      return state.drawer;
    },
    getNotiStatList(state) {
      return state.notiStatList;
    },
    getAreaCodeList(state) {
      return state.areaCodeList;
    },
    getLicnsCodeList(state) {
      return state.licnsCodeList;
    },
    getRpsntOrgnzCdList(state) {
      return state.rpsntOrgnzCdList;
    },
    getNotiAnlstList(state) {
      return state.notiAnlstList;
    },
    getCdltStList(state) {
      return state.cdltStList;
    },
  },
});
