<template>
    <!-- 맞춤공고 자동분석 종료 알림 팝업  D-1 -->
    <div class="popup__item--container auto-fin__container">
        <div class="popup__item--group auto-fin__group">
            <div class="popup__item--head  auto-fin--head">
                <p class="txt__title">설정하신 맞춤공고 <br>자동분석이 <span class="empha">종료 1일 전</span> 입니다.</p>
            </div>
            <div class="popup__item--body auto-fin--body">
                <p class="txt__sub">{{endDt}}은 <span class="line-break"><span class="empha">자동분석 종료</span>일입니다.</span></p>
                <p class="txt__title">자동분석 일정을 연장해주세요.</p>
                <p class="txt__sub">맞춤공고 자동분석을 연장해야 <span class="line-break">공고 누락없이 분석 요청이 가능합니다.</span></p>
            </div>
        </div>
        <div class="popup__button--wrap pair auto-fin__button--wrap">
            <a href="#" onclick="return false" class="btn-basic btn-line-cb popup__close" @click="close()">닫기</a>
            <a href="#" onclick="return false" class="btn-basic btn-cta popup__close" @click="showPop()">자동분석 기간연장</a>
        </div>
    </div>
    <!-- /맞춤공고 자동분석 종료 알림 팝업  D-1 -->

</template>

<script>
    export default {
        name: 'AutoAnlsPrevDdayPop',

        data () {
            return {
                endDt : this.$moment().format('YYYY.MM.DD (ddd)'),
            }


        },
        methods: {
            showPop() {

                var tracking = {
                    "trackingCd": "4",
                    "trackingAction" : "CLICK",
                    "trackingNm": "자동분석 1일전 기간연장",
                    "trackingDesc" : "자동분석 1일전 기간연장",
                };

                this.$insertTracking(tracking);
                this.$parent.isPopAutoAnlsPrevDdayPop = false;
                this.$parent.isPopSetAutoAnls = true;
            },
            close() {
                var tracking = {
                    "trackingCd": "4",
                    "trackingAction" : "CLICK",
                    "trackingNm": "자동분석 1일전 닫기",
                    "trackingDesc" : "자동분석 1일전 닫기",
                };

                this.$insertTracking(tracking);
                this.$parent.isPopAutoAnlsPrevDdayPop = false;
            }
        },
        mounted() {
        }

    }

</script>