<template>
    <div class="edit__area contract-info">
        <p class="edit__area--title">계약서</p>
        <ul class="table__subject contract-info-board--subject">
            <li class="table__row">번호</li>
            <li class="table__row">구분</li>
            <li class="table__row">계약기간</li>
            <li class="table__row">보기</li>
        </ul>
        <ul class="table__cont--wrap contract-info-board">
            <li class="table__cont--item contract-info-board--item" v-for="(custCntrct, index) in mock.custCntrctList" v-bind:key="index">
                <span class="table__row">{{mock.custCntrctList.length - index}}</span>
                <span class="table__row" :class="custCntrct.cntrctType == 'fee' ? '' : 'ai'">{{custCntrct.cntrctType == 'fee' ? '분석서비스 이용 약관' : '멤버십 이용'}} {{custCntrct.planNm != "" ? '('+custCntrct.planNm+')' : ''}}</span>
                <div class="table__row">
                    <span class="txt__title cur-ptr" v-if="custCntrct.cntrctType == '9dok'">
                        <span v-if="custCntrct.subStatusCd == '0'" @click="$parent.$parent.chkCustAISubComn($parent.loginInfo.planId)">입금 대기중</span>
                        <span v-if="custCntrct.subStatusCd == '1' || custCntrct.subStatusCd == '2' || custCntrct.subStatusCd == '3'">
                            {{$moment(custCntrct.anlsAgreeDt).format('YYYY.MM.DD')}} ~ {{$moment(custCntrct.anlsExpyDt).format('YYYY.MM.DD')}} ({{custCntrct.statusNm}})
<!--                            {{custCntrct.subStatusCd == '1' ? '(이용 중)' : ''}} {{custCntrct.subStatusCd == '2' ? '(종료)' : ''}}-->
                        </span>
                    </span>
                    <span class="txt__title" v-if="custCntrct.cntrctType == 'fee'">
                        {{$moment(custCntrct.anlsAgreeDt).format('YYYY.MM.DD')}} ~ {{$moment(custCntrct.anlsExpyDt).format('YYYY.MM.DD')}}
                    </span>
                </div>
                <span class="table__row" @click="showCustCntrct(custCntrct);">
                    <i class="ic_magnifier" v-if="custCntrct.cntrctType == 'fee'"></i>
                    <i class="ic_magnifier" v-if="custCntrct.cntrctType == '9dok' && custCntrct.subStatusCd != '0'"></i>
                </span>
            </li>
        </ul>
    </div>
</template>
<script>
    import mock from '../mock';
    export default {
        data() {
            return {
                mock,

            }
        },
        methods: {
            showCustCntrct(custCntrct) {
                if (custCntrct.cntrctType == 'fee') {
                    window.open('custCntrct/'+ custCntrct.id , "popup", "width=900, height=1000, left=30, top=30, scrollbars=no,titlebar=no,status=no,resizable=no,fullscreen=no");
                } else {
                    window.open('custSubscription/'+ custCntrct.id , "popup", "width=900, height=1000, left=30, top=30, scrollbars=no,titlebar=no,status=no,resizable=no,fullscreen=no");
                }

            }
        }
    }


</script>