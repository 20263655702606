<template>
    <div>
    </div>
</template>

<script>

    export default {
        name: 'Redirect',
        created() {
            this.trackUser();
        },
        methods: {
            async trackUser() {
                const urlParams = new URLSearchParams(window.location.search);
                const url = urlParams.get('url');
                const bizNo = urlParams.get('bizNo');

                // 사용자 정보 수집
                var tracking = {
                    "trackingCd": "5",
                    "trackingNm": "QR 코드 리다이렉트",
                    "trackingAction" : "ACCESS",
                    "trackingDesc" : bizNo
                };

                await this.$insertTracking(tracking);

                window.location.href = url;









            }
        }
    };
</script>
