<template>
    <!-- 개찰결과 -->
    <div class="analysis__container">
        <!-- 로그인 독려 배너 -->
        <div class="nonlog-banner"  v-if="Object.keys($parent.loginInfo).length == 0">
            <div class="nonlog-banner__txt--group">
                <p class="sub">비드톡톡에서는</p>
                <p class="title">내가 참여했던 공고의 결과를<br><span class="empha">한 번에 확인</span>할 수 있습니다.</p>
                <div class="nonlog-banner--bg result"></div>
            </div>
        </div>
        <!-- /로그인 독려 배너 -->
        <!-- 검색 필터 -->
        <div class="result-bid__area result-filter" v-if="Object.keys($parent.loginInfo).length != 0">
            <div class="result-filter__group">
                <p class="txt--title">날짜</p>
                <div class="result-filter__unit result-filter--input">
                    <!--                        <div class="input">-->
                    <!--                            <input type="text" class="" placeholder="2024.02.01">-->
                    <v-date-picker v-model="strtDate" >
                        <template v-slot="{ inputValue, togglePopover }">
                            <div class="input" v-show="searchOpbdPeriod == 0"
                                 @click="togglePopover">
                                <input
                                        class=""
                                        placeholder=""
                                        :value="inputValue"
                                />
                            </div>
                            <div class="input" v-show="searchOpbdPeriod != 0">
                                <input type="text" class="disable" :placeholder="strtDate">
                            </div>
                        </template>
                    </v-date-picker>
                    <!--                        </div>-->
                    <span class="result-filter--input--dash">~</span>
                    <v-date-picker v-model="finDate" >
                        <template v-slot="{ inputValue, togglePopover }">
                            <div class="input" v-show="searchOpbdPeriod == 0"
                                 @click="togglePopover">
                                <input
                                        class=""
                                        placeholder="1"
                                        :value="inputValue"
                                />
                            </div>
                            <div class="input" v-show="searchOpbdPeriod != 0">
                                <input type="text" class="disable" :placeholder="finDate">
                            </div>
                        </template>
                    </v-date-picker>
                </div>
                <ul class="result-filter__unit date-btn">
                    <li class="result-filter--item date-btn--item" @click="clickSearchOpbdPeriod(1)">
                        <input type="radio" name="date-btn" id="date-btn01"  :checked="searchOpbdPeriod == 1 ? true : false">
                        <label for="date-btn01" class="date-btn--item">1개월</label>
                    </li>
                    <li class="result-filter--item date-btn--item" @click="clickSearchOpbdPeriod(3)">
                        <input type="radio" name="date-btn" id="date-btn02" :checked="searchOpbdPeriod == 3  ? true : false">
                        <label for="date-btn02" class="date-btn--item">3개월</label>
                    </li>
                    <li class="result-filter--item date-btn--item" @click="clickSearchOpbdPeriod(6)">
                        <input type="radio" name="date-btn" id="date-btn03" :checked="searchOpbdPeriod == 6 ? true : false">
                        <label for="date-btn03" class="date-btn--item">6개월</label>
                    </li>
                    <li class="result-filter--item date-btn--item" @click="clickSearchOpbdPeriod(0)">
                        <input type="radio" name="date-btn" id="date-btn04" :checked="searchOpbdPeriod == 0? true : false">
                        <label for="date-btn04" class="date-btn--item">직접입력</label>
                    </li>
                </ul>
            </div>
            <div class="result-filter__group result-filter--select">
                <div class="select-box result-filter--select--item analyst">
                    <button class="select-box__sel">전체</button>
                    <ul class="select-box__list">
                        <li><button type="button" @click="notiAnlstId = ''">전체</button></li>
                        <li v-for="items in mock.notiTable.notiAnlstList" v-bind:key="items.id" ><button type="button" :value="items.id" @click="notiAnlstId = items.id">{{items.anlstNickNm}}</button></li>
                    </ul>
                </div>
                <div class="select-box result-filter--select--item license">
                    <button class="select-box__sel">전체</button>
                    <ul class="select-box__list">
                        <li><button type="button" @click="licnsCd = ''">전체</button></li>
                        <li  v-for="items in notiOpbdLicnsCnt" v-bind:key="items.id" ><button type="button" :value="items.licnsCd" @click="licnsCd = items.licnsCd">{{items.licnsCtgyNm}}</button></li>
                    </ul>
                </div>
                <div class="select-box result-filter--select--item city">
                    <button class="select-box__sel">전체</button>
                    <ul class="select-box__list">
                        <li><button type="button" @click="prvnclCd = ''">전체</button></li>
                        <li v-for="items in notiPrvnclList" v-bind:key="items.id"><button type="button" :value="items.prvnclCd" @click="clickPrvnclItem(items.prvnclCd); prvnclCd=items.prvnclCd;">{{items.prvnclNmAndCount}}</button></li>
                    </ul>
                </div>
                <div class="select-box result-filter--select--item local">
                    <button class="select-box__sel">전체</button>
                    <ul class="select-box__list">
                        <li><button type="button" @click="areaCd = ''">전체</button></li>
                        <li v-for="items in notiArealList" v-bind:key="items.id" ><button type="button" :value="items.areaCd" @click="areaCd = items.areaCd">{{items.areaNmAndCount}}</button></li>
                    </ul>
                </div>
            </div>
            <div class="result-filter__group">
                <button class="result-filter--check" :class="lastSbidYn ? 'active' : ''" @click="lastSbidYn = !lastSbidYn; initOpbdNotiList()">낙찰 공고만 보기</button>
                <button class="result-filter--check" :class="bidTokNotiYn ? 'active' : ''" @click="bidTokNotiYn = !bidTokNotiYn; initOpbdNotiList()">비드톡톡 분석 요청 공고만 보기</button>
            </div>
        </div>
        <!-- /검색 필터 -->

        <!-- 검색창, 카운트 -->
        <div class="search-banner">
            <div class="search-banner__box">
                <p class="inquiry__total txt__title analysis__total">총<span>{{notiOpbdCnt}}</span>건</p>
            </div>
            <div class="bt-srch--rev search-banner__box">
                <div class="bt-srch--btn excel--btn" @click="fnExcelDown()" v-if="Object.keys($parent.loginInfo).length != 0"><span class="tit">엑셀</span></div>
                <div class="search">
                    <input type="text" placeholder="공고명, 공고번호를 검색해 보세요." v-model="anlsFindText" @keyup.enter="searchOpbdNoti($event.target.value)">
                    <button type="reset" class="btn__cancel" @click="anlsFindText=''; searchOpbdNoti()">취소</button>
                </div>
            </div>
        </div>
        <!-- /검색창, 카운트 -->

        <!-- 분석공고 있을 경우 -->
        <div class="layout__half announce">
            <!-- 비로그인 - 공고 리스트 -->
            <div class="announce__board--state non-log" v-if="Object.keys($parent.loginInfo).length == 0">
                <div class="announce__board--img"></div>
                <div class="announce__board--txt">
                    <p class="title">로그인 후, 이용 가능한 기능입니다.</p>
                    <p class="sub">회원이 아닌 경우, 회원가입 후 로그인해 주세요.</p>
                </div>
                <div class="announce__board--button">
                    <a href="#" onclick="return false" class="btn-basic btn-line-cb" @click="$goMenu('joinForm')">회원가입</a>
                    <a href="#" onclick="return false" class="btn-basic btn-cta" @click="$goMenu('login')">로그인</a>
                </div>
            </div>
            <!-- /비로그인 - 공고 리스트 -->

            <!-- 분석요청한 공고가 없을 경우 -->
            <div class="entire-search entire-search--no-res no-res" v-if="Object.keys($parent.loginInfo).length != 0 && notiOpbdList.length == 0 && Object.keys(opbdNotiGroup).length == 0">
                <div class="no-res__group">
                    <div class="no-res--img"></div>
                    <p class="title">투찰한 공고가 없습니다.</p>
                    <p class="sub">기간 재설정 후 다시 검색해 보세요.</p>
                </div>
<!--                <a href="#" onclick="return false" class="btn-basic btn-line-cb" @click="$goMenu('notiSuggest')">나의 맞춤공고 보기</a>-->
            </div>
            <!-- 분석요청한 공고가 없을 경우 -->

            <!-- 검색 결과가 없을 경우 -->
            <div class="announce__board--state non-result" v-if="Object.keys($parent.loginInfo).length != 0 && notiOpbdCnt == 0 && notiOpbdList.length != 0">
                <div class="announce__board--img"></div>
                <div class="announce__board--txt">
                    <p class="title">검색어와 일치하는<span class="mobile"> 분석 요청 공고가 없습니다.</span></p>
                </div>
            </div>

            <!-- /검색 결과가 없을 경우 -->

            <span v-for="items in opbdNotiGroup" v-bind:key="items.id">
                <!-- 날짜, 건수 표기 -->
                    <ul class="announce__date">
                        <li class="announce__date--mmdd">
                            <h3 class="txt__title">{{ $moment(items[0].bddprFinDt).format('MM월 DD일 (ddd)') }}</h3>
                        </li>
                        <li class="announce__date--count">
                            <p>{{items.length}}</p><span>건</span>
                        </li>
                    </ul>
                <!-- /날짜, 건수 표기 -->

                    <div>
                        <!-- 분석실 - 개찰결과 리스트 - 개인투찰 경우 (비톡 분석x) -->
                        <div class="layout__item announce--item" v-for="(item) in items" v-bind:key="item.id" :class="(item.anlstNickNm == '' ? 'other-bid' : '') + (item.myCmpRank == 1 ? ' success' : item.lastSbidYn == 'Y' ? 'success fin-win' : '')">
                            <div class="announce__group announce__analyst" >
                                <ul class="analyst__box">
                                    <li class="analyst__box--item">
                                        <span class="txt--title">{{item.anlstNickNm}}</span>
                                        <span class="dividing-line"></span>
                                        <span class="txt--sub">{{item.anlstType == 'AI' ? 'AI' : '기업'}}</span>
                                    </li>
                                    <li class="analyst__box--item">
                                        <span class="txt--sub strong">분석제시가</span>
                                        <span class="txt--title strong" v-show="item.anlstNickNm == ''">미요청</span>
                                         <span class="txt--title strong" v-show="item.anlstNickNm != '' && item.bddprAmtConfDt == null">미열람</span>
                                         <span class="txt--title strong" v-show="item.anlstNickNm != '' && item.bddprAmtConfDt != null">{{item.anlsBddprAmt | currency}}</span>
                                    </li>
                                </ul>
                                <div class="analyst__box company-logo">
                                    <img :src="item.iconImg" alt="분석기업 로고 이미지">
                                </div>
                            </div>
                            <a class="announce__group announce--item--move-detail tit" @click="goMenu('notiDetail',item.notiId)">
                                <p class="txt__title"><span
                                        class="condition-badge__item application" v-show="false"><span></span></span>{{item.notiNm}}</p>
                                <div class="viw-all" v-show="deviceType == 'Desktop'">
                                    <p class="txt__title">{{item.notiNm}}</p>
                                </div>
                                <div class="announce__copy">
                                    <div class="announce__copy--item">
                                        <span class="txt__sub">{{item.notiNo}}{{item.notiSeq != null ? ('-' + item.notiSeq) : ''}}</span>
                                        <button type="button" class="btn__copy" @click="copyNotiNo(item.notiNo)"></button>
                                    </div>
                                </div>
                            </a>
                            <a class="announce__group announce--item--move-detail amount">
                                <ul class="announce__info result-info" :class="item.myCmpRank == 1 || item.lastSbidYn == 'Y' ? 'success-info' : ''">
                                    <li class="announce__info--list"><span class="txt__title">낙찰 하한가</span><span
                                            class="txt__sub">{{item.opbdDwnlmtAmt | currency}}</span></li>
                                    <li class="announce__info--list"><span class="txt__title">내 투찰 금액</span><span
                                            class="txt__sub">{{item.bddprAmt | currency}}</span></li>
                                    <li class="announce__info--list result-info--rank" :class="item.myCmpRank == 1 ? 'win' : 'result-info'" v-if="(item.opbdStatCd == '2' || item.opbdStatCd == '5') && (item.lastSbidYn == null || item.lastSbidYn == 'N')">

                                        <p class="txt txt--title" :class="item.myCmpRank == null ? 'not-fulfil' : ''"><span>{{item.myCmpRank == null ? '미투찰' : (item.myCmpRank == 1 ? '' : (item.myCmpRank + '위'))}}</span></p>
                                        <p class="txt txt--sub">{{item.bddprPrtcCmpCnt }}개 업체중</p>
                                    </li>
                                    <li class="announce__info--list result-info--rank fin-win" v-if="(item.opbdStatCd == '2' || item.opbdStatCd == '5') && item.lastSbidYn == 'Y'">
                                        <p class="txt txt--title"><span>{{item.myCmpRank + '위'}}</span></p>
                                        <p class="txt txt--sub">{{item.bddprPrtcCmpCnt }}개 업체중</p>
                                    </li>
                                     <li class="announce__info--list result-info--rank success-info--rank wait"  v-if="item.opbdStatCd != '2' && item.opbdStatCd != '5'">
                                        <p class="txt txt--title"></p>
                                        <p class="txt txt--sub">개찰 대기중</p>
                                    </li>
                                </ul>
                            </a>
                        </div>
                        <!-- /분석실 - 개찰결과 리스트 - 개인투찰 경우 (비톡 분석x) -->
                    </div>
                </span>
        </div>
        <!-- /분석공고 있을 경우 -->
        <div  class="btn__moveTop">
            <button class="btn-basic btn-line-cb analist--btn--more" v-show="notiOpbdList.length  < notiOpbdCnt" @click="page++; getNotiOpbdList()"><span>{{notiOpbdCnt - notiOpbdList.length > pageUnit ? pageUnit : notiOpbdCnt  - notiOpbdList.length}}개 더 보기&#8595;</span></button>
        </div>
    </div>
    <!--/ 개찰결과-->
</template>
<script>
    import mock from './mock';

    export default {
        data() {
            return {
                mock,
                deviceType: 'Desktop',

                loginInfo: {},
                /* 개찰결과 변수*/
                notiOpbdList: [], //개찰결과목록
                notiOpbdCnt: 0,
                notiOpbdLicnsCnt: [], //검색리스트에 있는 지역 목록
                notiOpbdAreaCnt: [], //검색리스트에 있는 면허 목록
                notiPrvnclList: [],
                notiArealList: [],

                page: 0,    //현재 페이지
                pageUnit: 30,   //페이지당 숫자
                strtDate: this.$moment().subtract(1,'months').format('YYYY.MM.DD'),    //개찰결과 검색 시작일
                finDate: this.$moment().format('YYYY.MM.DD'),    //개찰결과 검색 종료일
                notiAnlstId: '',    //분석가ID
                // notiSearchLicnsCdList: [],  //면허검색목록
                // notiSearchAreaCdList: [],   //지역검색목록
                notiId: '',
                licnsCd: '',
                areaCd: '',
                prvnclCd: '',
                lastSbidYn: false, //낙찰공고만보기 여부
                bidTokNotiYn: false, //비드톡톡 분석 공고만 보기
                anlsFindText: '',    //검색텍스트
                searchOpbdPeriod: 1, // 날짜 선택자

                areaCodeList: [],//지역목록
                prvnclList: [], //관내

                // notiAnlstList: [],  //분석가목록
                opbdNotiGroup: [],  //개찰공고 그룹

                isProcessing: false, // API 동작 여부

            }
        },
        watch: {
            isPopAnlsRestrain(newValue) {
                if (newValue == true) {
                    this.$scrollNon();
                } else {
                    this.$scrollActive();
                }
            },
            isPopRegstConfirm(newValue) {
                if (newValue == true) {
                    this.$scrollNon();
                } else {
                    this.$scrollActive();
                }
            },
            strtDate() {
                this.initOpbdNotiList();
                this.getNotiOpbdCntList();
            },
            finDate() {
                this.initOpbdNotiList();
                this.getNotiOpbdCntList();
            },
            notiAnlstId() {
                this.initOpbdNotiList();
            },
            licnsCd() {
                this.initOpbdNotiList();
            },
            areaCd() {
                this.initOpbdNotiList();
            },
            prvnclCd() {
                this.initOpbdNotiList();
            },
        },


        async mounted() {
            this.loginInfo = JSON.parse(localStorage.getItem("custInfo"));
            this.deviceType  = this.$getDeviceType();

            const btnCancel = document.querySelector('.btn__cancel');
            const searchInput = btnCancel.previousElementSibling; //input

            searchInput.addEventListener('focus', () => { // 검색어창 포커스 on
                searchInput.parentElement.classList.add('active');
            });

            searchInput.addEventListener('blur', () => { // 검색어창 포커스 off
                searchInput.parentElement.classList.remove('active');
            });
            searchInput.addEventListener('keyup', () => { // 검색어 입력시 취소버튼 나타나기
                const word = searchInput.value.length;
                if (word > 0) {
                    btnCancel.style.opacity = 1;
                } else {
                    btnCancel.style.opacity = 0;
                }
            });

            btnCancel.addEventListener('click', function () { //텍스트 초기화
                btnCancel.previousElementSibling.value = '';
                btnCancel.style.opacity = 0;
            });


            const selectBoxes = document.querySelectorAll('.select-box__sel');

            selectBoxes.forEach(selectBox => {
                const selBoxParent = selectBox.parentElement;
                const selBoxDisable = selBoxParent.classList.contains('disable');

                if (!selBoxDisable) {
                    /* 선택창 켜짐 */
                    selectBox.addEventListener('click', () => {
                        selectBox.classList.toggle('on');
                    });
                    /* /선택창 켜짐 */

                    /* 셀렉트박스 활성화 중 다른 곳 클릭시 꺼짐 */
                    document.addEventListener('click', (event) => {
                        const target = event.target;
                        if (!selectBox.contains(target)) {
                            selectBox.classList.remove('on');
                        }
                    });
                    /* /셀렉트박스 활성화 중 다른 곳 클릭시 꺼짐 */

                    const selectBoxItems = selectBox.closest('.select-box').querySelectorAll('.select-box__list');

                    if (selectBoxItems.length > 0) {
                        selectBoxItems.forEach(selectBoxItem => {
                            selectBoxItem.addEventListener('click', (event) => {
                                const selectedText = event.target.innerText;
                                const selectedValue = event.target.value;
                                selectBox.innerText = selectedText;
                                selectBox.value = selectedValue;
                            });
                        });
                    }
                }
            });


            /* /셀렉트박스 */

            /* 태그 select disable시 border 제거 */

            let selDisItems = document.querySelectorAll('select');

// 클래스 변경을 감지하는 MutationObserver 생성
            const observer02 = new MutationObserver((mutationsList) => {
                for(let mutation of mutationsList) {
                    // 'disabled' 속성 변경이 발생한 경우만 처리
                    if (mutation.type === 'attributes' && mutation.attributeName === 'disabled') {
                        if (mutation.target.tagName.toLowerCase() === 'select') {
                            let parent = mutation.target.parentElement;

                            if (mutation.target.disabled) {
                                parent.classList.add('disable');
                            } else {
                                parent.classList.remove('disable');
                            }
                        }
                    }
                }
            });


// 각 select 요소에 대해 observer를 연결
            selDisItems.forEach(function(select) {
                observer02.observe(select, { attributes: true });
            });

            if ( this.loginInfo != null) {
                //개찰결과 목록
                this.initOpbdNotiList();

                //조건에 존재하는 면허, 지역검색
                this.getNotiOpbdCntList();

                ///마지막 접속 날짜 저장
                this.lastLoginDateUpate();
            }
        },
        methods: {
            /** 엑셀 다운로드 */
            fnExcelDown() {
                var fileName = JSON.parse(localStorage.getItem("custInfo")).cmpNm.toUpperCase() + '_' + this.$moment().format('YYYYMMDDHHmmss') + '.xls';
                var excel = [];
                // var data = [
                //     { notiNo: '', notiNm: '' },
                // ];

                for (const notiList of this.notiOpbdList) {
                    const excelHeaders = { 공고번호:'', 공고명:'', 발주처:'', 지역:'', 면허:'', 등록마감:'', 투찰마감:'', 개찰일시:'', 기초금액:'', 제시금액:'', 제시지점:''};
                    excelHeaders.공고번호 = notiList.notiNo;
                    excelHeaders.공고명 = notiList.notiNm;
                    excelHeaders.발주처 = notiList.orderOffiNm;
                    excelHeaders.지역 = notiList.areaNmConcat;
                    excelHeaders.면허 = notiList.licnsNmConcat;
                    excelHeaders.등록마감 = notiList.regstFinDt;
                    excelHeaders.투찰마감 = this.$moment(notiList.bddprFinDt).format('YYYY-MM-DD HH:mm');
                    excelHeaders.개찰일시 = notiList.opbdDt;
                    excelHeaders.기초금액 = Number(notiList.baseAmt).toLocaleString();
                    if (notiList.anlstChoiceFlag == 'Y') {
                        excelHeaders.제시금액 = Number(notiList.anlsBddprAmt).toLocaleString();
                        excelHeaders.제시지점 = notiList.anlsBddprRt;
                    }
                    excel.push(excelHeaders);
                }

                if (this.notiOpbdList.length > 0)
                this.$excelDown(excel, 'sheet1', fileName);
            },
            /** 날짝 기간 선택*/
            clickSearchOpbdPeriod(value){
                this.searchOpbdPeriod = value;
                if(value != 0){
                    this.strtDate = this.$moment().subtract(value,'months').format('YYYY.MM.DD');    //개찰결과 검색 시작일\
                    this.finDate= this.$moment().format('YYYY.MM.DD');    //개찰결과 검색 종료일
                }
            },
            /** 도내 클릭*/
            clickPrvnclItem(value) {
                this.notiArealList = this.notiOpbdAreaCnt.filter((element) => element.prvnclCd == value && element.areaNm != '');

                for(var j in this.notiArealList) {
                    this.notiArealList[j].areaNmAndCount = this.notiArealList[j].areaNm;
                }
            },
            dateToString(value){
                // 들어오는 value 값이 공백이면 그냥 공백으로 돌려줌
                if(value == '') return '';

                // 현재 Date 혹은 DateTime 데이터를 javaScript date 타입화
                var js_date = new Date(value);

                // 연도, 월, 일 추출
                var year = js_date.getFullYear();
                var month = js_date.getMonth() + 1;
                var day = js_date.getDate();

                // 월, 일의 경우 한자리 수 값이 있기 때문에 공백에 0 처리
                if(month < 10){
                    month = '0' + month;
                }

                if(day < 10){
                    day = '0' + day;
                }
                if(year == '1970' && month == '01' && day == '01'){
                    return '';
                }

                // 최종 포맷 (ex - '20211008')
                return year + '' + month + '' + day;
            },
            /** 개찰결과*/
            getNotiOpbdList() {
                if(this.isProcessing == true){
                    return;
                }
                this.isProcessing = true;
                var param =  {
                    "pageUnit": this.pageUnit,
                    "pageIndex": this.page,
                    "strtRecordIntex": this.page * this.pageUnit,
                    "searchDate": this.dateToString(this.strtDate),
                    "strtDate": this.dateToString(this.strtDate),
                    "finDate": this.dateToString(this.finDate),
                    "custBizId": this.loginInfo.custBizList[0].id,
                    "bizNo": this.loginInfo.custBizList[0].bizNo,
                    "orderBySt": "bddprFinDt",
                    "notiAnlstId": this.notiAnlstId,
                    "lastSbidYn" : this.lastSbidYn ? 'Y':'N',
                    "bidTokNotiYn" : this.bidTokNotiYn ? 'Y':'N',
                    "licnsCd": this.licnsCd,
                    "areaCd": this.areaCd,
                    "prvnclCd": this.prvnclCd,
                    // "notiSearchAreaCdList": this.notiSearchAreaCdList,
                    // "areaSearchType": "OR",
                    // "notiSearchLicnsCdList": this.notiSearchLicnsCdList,
                    "findText": this.anlsFindText,
                };

                this.$parent.$parent.isLoadingBar(true);
                this.$post('/api/noti/notiOpbdList',param)
                    .then(res => {
                        // this.notiOpbdList = res.notiOpbdList;
                        var notiOpbdList = res.notiOpbdList;
                        this.notiOpbdCnt = res.notiOpbdCnt;

                        /// 분석가 정리
                        for(var i in notiOpbdList){
                            var index = mock.notiTable.notiAnlstList.findIndex((e) => e.id == notiOpbdList[i].notiAnlstId);

                            if (index != -1) {
                                notiOpbdList[i].iconImg = mock.notiTable.notiAnlstList[index].iconImg;
                                notiOpbdList[i].anlstNickNm = mock.notiTable.notiAnlstList[index].anlstNickNm;
                                notiOpbdList[i].anlstType = mock.notiTable.notiAnlstList[index].anlstType;
                            } else {
                                notiOpbdList[i].iconImg = '/assets/images/logo_bidtok_other-bid.png';
                                notiOpbdList[i].anlstNickNm = '';
                                notiOpbdList[i].anlstType = '';
                            }
                            this.notiOpbdList.push(notiOpbdList[i]);
                        }

                        this.refactOpbdNotiList();
                        this.isProcessing = false;
                        this.$parent.$parent.isLoadingBar(false);
                    })
                    .catch(error => {
                        console.log(error);
                        this.isProcessing = false;
                        this.$parent.$parent.isLoadingBar(false);
                    });
            },

            /** 조건에 해당하는 면허 지역 리스트*/
            getNotiOpbdCntList() {

                var param =  {
                    "pageUnit": this.pageUnit,
                    "pageIndex": this.page,
                    "strtRecordIntex": this.page * this.pageUnit,
                    "searchDate": this.dateToString(this.strtDate),
                    "strtDate": this.dateToString(this.strtDate),
                    "finDate": this.dateToString(this.finDate),
                    "custBizId": this.loginInfo.custBizList[0].id,
                    "bizNo": this.loginInfo.custBizList[0].bizNo,
                    "orderBySt": "bddprFinDt",
                    "notiAnlstId": '',
                    "lastSbidYn" : 'N',
                    "bidTokNotiYn" : 'N',
                    "licnsCd": '',
                    "areaCd": '',
                    "prvnclCd": '',
                    // "notiSearchAreaCdList": this.notiSearchAreaCdList,
                    // "areaSearchType": "OR",
                    // "notiSearchLicnsCdList": this.notiSearchLicnsCdList,
                    "findText": '',
                };

                this.$post('/api/noti/notiOpbdCntList',param)
                    .then(res => {
                        this.notiOpbdLicnsCnt = res.notiOpbdLicnsCnt;
                        this.notiOpbdAreaCnt = res.notiOpbdAreaCnt;

                        this.notiPrvnclList = this.notiOpbdAreaCnt.filter((element) => element.areaNm == '')

                        for(var j in this.notiPrvnclList) {
                            this.notiPrvnclList[j].prvnclNmAndCount = this.notiPrvnclList[j].prvnclNm;
                        }
                    })
                    .catch(error => console.log(error));
            },

            //문자전송팝업 닫기
            closePop() {
                var popupWrap = document.getElementById("popupWrap");
                popupWrap.style.display = "none";
                this.phoneNum = "";
                this.indvdlinfoAuthz = false;
            },

            //분석제시가 확인제한 팝업 닫기
            closeAnlsPop() {
                this.isPopAnlsRestrain = false;
            },
            /** 개인정보 동의*/
            indvInAuYn() {
                if (this.indvdlinfoAuthz == false) {
                    this.indvdlinfoAuthz = true;
                } else if (this.indvdlinfoAuthz == true) {
                    this.indvdlinfoAuthz = false;
                }
            },

            //문자전송
            async sendSms() {
                if (this.phoneNum == "") {
                    this.$toast.success("휴대폰 번호를 입력해 주세요.", {
                        position: 'top-center',
                        timeout: 1000,
                        closeOnClick: true,
                        pauseOnHover: true,
                        transitionDuration: 750,
                        draggable: true,
                        draggablePercent: 0.6,
                        showCloseButtonOnHover: false,
                        hideProgressBar: true,
                        closeButton: "button",
                        icon: true
                    });
                    return;
                }
                if (this.indvdlinfoAuthz == false) {
                    this.$toast.success("개인정보 수집에 동의해 주세요.", {
                        position: 'top-center',
                        timeout: 1000,
                        closeOnClick: true,
                        pauseOnHover: true,
                        transitionDuration: 750,
                        draggable: true,
                        draggablePercent: 0.6,
                        showCloseButtonOnHover: false,
                        hideProgressBar: true,
                        closeButton: "button",
                        icon: true
                    });
                    return;
                }

                var popupWrap = document.getElementById("popupWrap");

                if (this.indvdlinfoAuthz == true) {
                    this.indvdlinfoAuthzYn = 'Y';
                }

                var clintIP = await this.$getPublicIP();

                var params = {
                    "recptnTelno": this.phoneNum.toString(),
                    "clientIp": clintIP,
                    "indvdlinfoAuthzYn": this.indvdlinfoAuthzYn
                };

                this.$http.post('/api/launcher/extrnl/sendAppLink', params)
                // eslint-disable-next-line no-unused-vars
                    .then(res => {
                        this.$toast.success("문자 발송이 완료되었습니다.", {
                            position: 'top-center',
                            timeout: 2000,
                            closeOnClick: true,
                            pauseOnHover: true,
                            transitionDuration: 750,
                            draggable: true,
                            draggablePercent: 0.6,
                            showCloseButtonOnHover: false,
                            hideProgressBar: true,
                            closeButton: "button",
                            icon: true
                        });
                        popupWrap.style.display = "none";
                        this.phoneNum = "";
                        this.indvdlinfoAuthz = false;
                    })

                    .catch(error => {
                            console.log(error);
                        }
                    );

            },

            //공고 상세페이지이동
            goMenu(menuUrl, id) {
                if (this.$route.path != "/" + menuUrl) // 현재 route와 다른 경우만 페이지 이동
                    window.open('/' + menuUrl + '/' + id, '_blank', 'width=1200,height=1000');
            },

            //문자전송팝업 오픈
            async openPop() {
                var popupWrap = document.getElementById("popupWrap");
                popupWrap.style.display = "block";

                var tracking = {
                    "trackingCd": "1",
                    "trackingAction" : "CLICK",
                    "trackingNm": "앱링크 다운로드",
                    "trackingDesc" : "",
                };

                await this.$insertTracking(tracking);
            },
            handleScroll() {
                const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
                const moveTopButton = document.querySelector('.btn__moveTop');

                if (scrollTop > 0) {
                    moveTopButton.style.display = 'block';
                } else {
                    moveTopButton.style.display = 'none';
                }
            },
            /** 가장 위로 이동*/
            moveTop() {
                //페이지 이동
                window.scroll({top: 0, left: 0, behavior: 'smooth'});
            },
            goSite(notiNo, rpsntOrgnzCd) {
                var url = '';
                if (rpsntOrgnzCd == 1) { // 나라장터
                    url = 'https://www.g2b.go.kr/pt/menu/selectSubFrame.do?framesrc=/pt/menu/frameTgong.do?url=https://www.g2b.go.kr:8101/ep/tbid/tbidList.do?bidSearchType=1&bidno=' + notiNo + '&searchType=2';
                } else if (rpsntOrgnzCd == 2) { // 한전
                    this.copyNotiNo(notiNo);
                    url = 'https://srm.kepco.net/index.do';
                } else if (rpsntOrgnzCd == 3) { // 국방부
                    this.copyNotiNo(notiNo);
                    url = 'https://www.d2b.go.kr/peb/bid/announceList.do?key=41';
                }

                window.open(url, "popup", "width=1024, height=600, left=30, top=30, scrollbars=no,titlebar=no,status=no,resizable=no,fullscreen=no");
            },
            /** 개찰공고 검색*/
            searchOpbdNoti(value) {
                if (value != null) {
                    this.anlsFindText = value;
                }
                this.notiOpbdList= [];

                this.getNotiOpbdList();

            },
            /**  개찰공고 초기화*/
            initOpbdNotiList() {
                this.notiOpbdList = [];
                this.page = 0;

                this.getNotiOpbdList();
            },
            /** 개찰공고 그룹화*/
            refactOpbdNotiList() {
                this.opbdNotiGroup = {};
                this.notiOpbdList.forEach(item => {
                    if (item.notiNm.toString().indexOf(this.anlsFindText) != -1 || item.notiNo.toString().indexOf(this.anlsFindText) != -1) {
                        const category = this.$moment(item.opbdDt).format('YYYY-MM-DD');

                        if (!this.opbdNotiGroup[category]) {
                            this.opbdNotiGroup[category] = []; // Initialize an array for the category
                        }
                        this.opbdNotiGroup[category].push(item);
                    }
                });
            },
            copyNotiNo(value) {
                this.$copyText(value);
                this.snackbar = true;
                this.copyText = value;
                this.$stopEventBubble();

                // this.$toast('HelloWorld', { position: "bottom-right" });

                this.$toast.success("공고번호를 복사했습니다.", {
                    position: 'top-center',
                    timeout: 2000,
                    closeOnClick: true,
                    pauseOnHover: true,
                    transitionDuration: 750,
                    draggable: true,
                    draggablePercent: 0.6,
                    showCloseButtonOnHover: false,
                    hideProgressBar: true,
                    closeButton: "button",
                    icon: true
                });

                // alert("공고번호를 복사했습니다.");
            },
            copyAnlsBddprAmt(value) {
                this.$copyText(value);
                this.snackbar = true;
                this.copyText = value;
                this.$stopEventBubble();

                this.$toast.success("분석제시가를 복사했습니다.",);

                // alert("분석투찰금액을 복사했습니다.");
            },
            click($event) {
                //상세 검색 아이콘 클릭 > 필터 on
                if ($event.target.closest('.analysis__search--detaile') !== null) {
                    document.querySelector('.filter').classList.toggle('on');
                } else if ($event.target.closest('.analysis__search--detaile') === null) { // 필터 영역 제외한 부분 클릭시 on 클래스 제거
                    if ($event.target.closest('.filter__wrap') === null) {
                        document.querySelector('.filter').classList.remove('on');
                    }
                }
                //필터 영역의 끄기 버튼
                if ($event.target === document.querySelector('.btn-close')) {
                    document.querySelector('.filter').classList.remove('on');
                }
                if ($event.target === document.querySelector('.btn-close')) {
                    document.querySelector('.filter').classList.remove('on');
                }
                //셀렉트 박스 dud
                if (document.querySelector('.select-box__sel').classList.contains('on') && $event.target !== document.querySelector('.select-box__sel')) {
                    document.querySelector('.select-box__sel').classList.remove('on');
                }
                //로그인 바 제외한 영역 클릭시 on 클래스 제거
                // if (document.querySelector('.login-bar--wrap').classList.contains('on')) {
                //     document.querySelector('.login-bar--wrap').classList.remove('on');
                // }
            },
            async lastLoginDateUpate(){
                let publicIP;
                publicIP = await this.$getPublicIP();

                let param =  {
                    "id": this.loginInfo.custBizList[0].custId.toString(),
                    "clientIp" : publicIP
                };

                this.$post('/api/updateLastLogin',param)
                    .then(res => {
                        return res;
                    })
                    .catch(error => console.log(error));

            },
        }

    }


</script>
<!--<style src="./Analysis.scss" lang="scss" scoped />-->