<template>
    <!-- 헤더 -->
    <div class="header">
        <div class="wrap header__wrap" :class="loginInfo == null ? 'logout-active':'login-active'">
            <div class="loading-bar" v-show="isLoading">
                <div class="loading-bar--element"></div>
            </div>
            <div class="header__area header__info--wrap header__button">
                <div class="header__info--wrap header__button">
                    <div class="header__info--user" v-if="loginInfo != null">
                        <span class="txt__title">{{loginInfo.cmpNm}}</span>
                        <span class="txt__sub">님</span>
                        <div class="header__info--service">
                            <div href="#" onclick="return false" class="logout-active ai" v-if="loginInfo.subStatusCd == 1">
                                <span class="ai--title">AI멤버십 </span>
                                <span class="ai--txt on bt-c-main">{{AIStatusText}}</span>
                            </div>
                            <a href="#" onclick="return false" class="logout-active auto-analy" @click="clickAutoAnls">
                                <span class="auto-analy--title">자동분석</span>
                                <span class="auto-analy--txt" v-if="isAutoAnls == false">꺼짐</span>
                                <span class="auto-analy--txt" :class="dday < 3 ? 'on' : ''" v-show="isAutoAnls == true">D-{{dday}}</span>
                                <span class="auto-analy--txt off" v-if="isAutoAnls == true">켜짐</span>
                            </a>
                        </div>
                        <a href="#" onclick="return false" class="logout-active info ml30" @click="$goMenu('myPage')" aria-label="내정보"><i class="ic_cus-info_label"></i></a>
                    </div>
                    <div class="header__info--btn">
                        <a href="#" onclick="return false" class="login-active join" @click="$goMenu('joinForm')">회원가입</a>
                        <a href="#" onclick="return false" class="login-active" @click="$goMenu('login')">로그인</a>
                        <a href="#" onclick="return false" class="logout-active" @click="doLogout">로그아웃</a>
                    </div>
                </div>
            </div>
            <div class="header__area menu">
                <h1 class="header__logo"><a href="/home" aria-label="전문 AI 전자입찰 서비스 비드톡톡"></a></h1>
                <div class="header__gnb--wrap">
                    <ul class="header__gnb--pc">
                        <li class="depth1" id="notiSearch"><a href="#" onclick="return false" @click="$goMenu('notiSearch')">통합검색</a></li>
                        <li class="depth1" id="notiSuggest"><a href="#" onclick="return false" @click="$goMenu('notiSuggest')">맞춤공고</a></li>
                        <li class="depth1" id="analysis"><a href="#" onclick="return false" @click="$goMenu('analysis')">분석실</a></li>
                        <li class="depth1" id="tokReport"><a href="#" onclick="return false" @click="$goMenu('tokReportMain')">톡톡리포트</a></li>
                        <li class="depth1" id="notice"><a href="#" onclick="return false" @click="$goMenu('notice')">고객지원</a></li>
    <!--                    <li class="depth1" id="notice"><a href="javascript:void(0);" @click="$goMenu('notice')">공지사항</a></li>-->
    <!--                    <li class="depth1" id="faq"><a href="javascript:void(0);" @click="$goMenu('faq')">자주하는 질문</a></li>-->
    <!--                    <li class="depth1"><a href="#" onclick="return false" @click="showGuidePopup">사용가이드</a></li>-->
                        <li class="depth1" id="tokStory"><a href="#" onclick="return false" @click="$goMenu('tokStory')">톡스토리</a></li>
                        <li class="depth1 report" id="membership"><a href="#" onclick="return false" @click="$goMenu('membership')">요금안내</a></li>
                    </ul>
                    <div class="header__gnb--mobile">
                        <a href="#" onclick="return false" class="logout-active auto-analy" @click="clickAutoAnls">
                            <span class="auto-analy--title">자동분석</span>
                            <span class="auto-analy--txt" v-if="isAutoAnls == false">꺼짐</span>
                            <span class="auto-analy--txt" :class="dday < 3 ? 'on' : ''" v-show="isAutoAnls == true">D-{{dday}}</span>
                            <span class="auto-analy--txt off" v-if="isAutoAnls == true">켜짐</span>
                            <!-- <span class="auto-analy--txt off">켜짐</span> -->
                        </a>
                        <a href="#" onclick="return false" class="login-active join" @click="$goMenu('joinForm')">회원가입</a>
                        <button type="button" class="btn__ham">
                            <span class="btn__ham--global btn__ham--top"></span>
                            <span class="btn__ham--global btn__ham--middle"></span>
                            <span class="btn__ham--global btn__ham--bottom"></span>
                        </button>
                    </div>
                </div>
            </div>
<!--            <h1 class="header__logo"><a href="/home"></a></h1>-->
<!--            <div class="header__gnb&#45;&#45;wrap">-->
<!--                <ul class="header__gnb&#45;&#45;pc">-->
<!--                    <li class="depth1" id="notiSuggest"><a href="#" onclick="return false" @click="$goMenu('notiSuggest')">맞춤공고</a></li>-->
<!--                    <li class="depth1" id="analysis"><a href="javascript:void(0);" @click="$goMenu('analysis')">분석실</a></li>-->
<!--                    <li class="depth1 report"><a href="#" onclick="return false" @click="$goMenu('tokReportMain')">톡톡리포트</a></li>-->
<!--                    <li class="depth1" id="notice"><a href="javascript:void(0);" @click="$goMenu('notice')">고객지원</a></li>-->
<!--&lt;!&ndash;                    <li class="depth1" id="notice"><a href="javascript:void(0);" @click="$goMenu('notice')">공지사항</a></li>&ndash;&gt;-->
<!--&lt;!&ndash;                    <li class="depth1" id="faq"><a href="javascript:void(0);" @click="$goMenu('faq')">자주하는 질문</a></li>&ndash;&gt;-->
<!--&lt;!&ndash;                    <li class="depth1"><a href="#" onclick="return false" @click="showGuidePopup">사용가이드</a></li>&ndash;&gt;-->
<!--                    <li class="depth1" id="tokStory"><a href="#" onclick="return false" @click="$goMenu('tokStory')">톡스토리</a></li>-->
<!--                </ul>-->
<!--                <div class="header__gnb&#45;&#45;mobile">-->
<!--                    <a href="#" onclick="return false" class="login-active join"  @click="$goMenu('joinForm')">회원가입</a>-->
<!--                    <button type="button" class="btn__ham">-->
<!--                        <span class="btn__ham&#45;&#45;global btn__ham&#45;&#45;top"></span>-->
<!--                        <span class="btn__ham&#45;&#45;global btn__ham&#45;&#45;middle"></span>-->
<!--                        <span class="btn__ham&#45;&#45;global btn__ham&#45;&#45;bottom"></span>-->
<!--                    </button>-->
<!--                </div>-->
<!--            </div>-->
            <!--  로그인 전) .header__info--wrap 뒤에 login-active 클래스를 추가-->
            <!--  로그인 후) .header__info--wrap 뒤에 logout-active 클래스를 추가-->
<!--            <div class="header__info&#45;&#45;wrap header__button">-->
<!--                <div class="header__info&#45;&#45;btn">-->
<!--                    <a href="#" onclick="return false" class="login-active join" @click="$goMenu('joinForm')">회원가입</a>-->
<!--                    <a href="#" onclick="return false" class="login-active" @click="$goMenu('login')">로그인</a>-->
<!--                    <a href="#" onclick="return false" class="logout-active info" @click="$goMenu('myPage')"><i class="ic_cus-info"></i>내정보</a>-->
<!--                    <a href="#" onclick="return false" class="logout-active" @click="doLogout">로그아웃</a>-->
<!--                </div>-->
<!--                <div class="header__info&#45;&#45;user" v-if="loginInfo != null">-->
<!--                    <span class="txt__title">{{loginInfo.cmpNm}}</span>-->
<!--                    <span class="txt__sub">님</span>-->
<!--                    <span class="txt__sub"><strong>안녕하세요!</strong></span>-->
<!--                </div>-->
<!--            </div>-->
        </div>
        <div class="header__ham--wrap">
            <!--  로그인 전) .header__ham--info 뒤에 login-active 클래스를 추가-->
            <!--  로그인 후) .header__ham--info 뒤에 logout-active 클래스를 추가-->
            <div class="header__ham--group info" :class="loginInfo == null ? 'logout-active':'login-active'">
                <div class="header__ham--user" v-if="loginInfo != null">
                    <p class="txt__title">{{loginInfo.cmpNm}}</p>
                    <span class="txt__sub tail">님</span>
                    <p class="txt__sub greet">안녕하세요!
                        <a href="#" onclick="return false" class="info" @click="$goMenu('myPage')">내정보
                            <i></i>
                        </a>
                    </p>
                </div>
                <a href="#" onclick="return false" class="txt__title encouragement" @click="$goMenu('login')"><span>로그인</span>을 해 주세요.</a>
                <div class="header__info--btn">
                </div>
            </div>
            <ul class="header__ham--group menu">
                <li>
                    <div class="depth1" id="m_notiSearch"><a href="#" onclick="return false" @click="$goMenu('notiSearch')">통합검색</a></div>
                    <div class="depth1" id="m_notiSuggest"><a href="#" onclick="return false" @click="$goMenu('notiSuggest')">맞춤공고</a></div>
                    <div class="depth1" id="m_analysis"><a href="#" onclick="return false" @click="$goMenu('analysis')">분석실</a></div>
                    <div class="depth1" id="m_tokReport"><a href="#" onclick="return false" @click="$goMenu('tokReportMain')">톡톡리포트</a></div>
                    <div class="depth1" id="m_notice"><a href="#" onclick="return false" @click="$goMenu('notice')">고객지원</a></div>
<!--                    <div class="depth1" id="m_notice"><a href="#" onclick="return false" @click="$goMenu('notice')">공지사항</a></div>-->
<!--                    <div class="depth1" id="m_faq"><a href="#" onclick="return false" @click="$goMenu('faq')">자주하는 질문</a></div>-->
<!--                    <div class="depth1"><a href="#" onclick="return false" @click="showGuidePopupMo">사용가이드</a></div>-->
                    <div class="depth1" id="m_tokStory"><a href="#" onclick="return false" @click="$goMenu('tokStory')">톡스토리</a></div>
                    <div class="depth1 report" id="m_membership"><a href="#" onclick="return false" @click="$goMenu('membership')">요금안내</a></div>
                </li>
            </ul>
            <div class="header__ham--group lnb">
                <a href="https://pf.kakao.com/_QmQPxj?from=qr" class="kakao-channel"><span>카카오톡 채널</span></a>
                <a href="#" onclick="return false" class="login-active" @click="$goMenu('login')"><span>로그인</span></a>
                <a href="#" onclick="return false" class="logout-active" @click="doLogout"><span>로그아웃</span></a>
            </div>
        </div>
        <!-- 팝업 활성화 popup__wrap 뒤에 active 추가 -->
        <div class="popup__wrap analy-noti" :class="notificationsBadge ? 'active' : ''">
            <div class="popup__item--container">
                <div class="popup__item--group">
                    <div class="popup__item--head">
                        <!-- 체크 이미지 있을 경우 -->
                        <div class="popup__img"></div>
                        <!-- 타이틀이 있을경우 -->
                        <!-- <h3 class="txt__title">타이틀</h3> -->
                    </div>
                    <div id="socketDiv" class="popup__item--body">
                    </div>
                </div>
                <!-- 버튼이 하나일 때 popup__button--wrap 클래스 뒤에 single 추가,
                     버튼이 두개일 때 popup__button--wrap 클래스 뒤에 pair 추가-->
                <!--                <div class="popup__button&#45;&#45;wrap single">-->
                <!--                    <a href="#" onclick="return false" class="btn-basic btn-cta"><span>분석실로 이동</span></a>-->
                <!--                </div>-->
                <div class="popup__button--wrap pair">
                    <a href="#" onclick="return false" class="btn-basic btn-line-cb" @click="goAnalysis(false)">닫기</a>
                    <a href="#" onclick="return false" class="btn-basic btn-cta" @click="goAnalysis(true)">분석실로 이동</a>
                </div>
            </div>
            <!--     // popup-->
        </div>

        <!-- 설날팝업 -->
        <div class="popup__wrap advertise lunar"  v-show="closePop == 'N' && earlyBirdYn != 'Y' && dateCheck()">
            <div class="popup__item--container">
                <a class="popup__item--group" href="#" onclick="return false"></a>
                <div class="popup__button--wrap pair">
                     <a href="#" onclick="return false" class="btn-basic btn-line-cb" @click="earlyBirdCookie()">오늘 하루 보지 않기</a>
                    <a href="#" onclick="return false" class="btn-basic btn-cta popup__close" @click="_closeFnc">닫기</a>
                </div>
            </div>
        </div>

        <!-- 신년 팝업 -->
        <div class="popup__wrap advertise new-year" :class="isNewYear ? 'active':''">
            <div class="popup__item--container">
                <a class="popup__item--group" href="#" onclick="return false" style="cursor:default"></a>
                <div class="popup__button--wrap pair">
                    <a href="#" onclick="return false" class="btn-basic btn-line-cb popup__close" @click="isNewYear = false;">닫기</a>
                    <a href="#" onclick="return false" class="btn-basic btn-cta" @click="newYearCookie();">3일 동안 보지 않기</a>
                </div>
            </div>
        </div>
        <!-- /신년 팝업 -->

        <!-- 톡톡 리포트 홍보 팝업 -->
<!--        <div class="popup__wrap advertise report-pop" :class="isTokReport ? 'active':''">-->
<!--            <a class="new-year__container" href="#" onclick="return false" @click="$goMenu('tokReportMain'); isTokReport = false;"></a>-->
<!--            <div class="popup__item&#45;&#45;container">-->
<!--                <a class="popup__item&#45;&#45;group" href="#" onclick="return false" @click="$goMenu('tokReportMain'); isTokReport = false;"></a>-->
<!--                <div class="popup__button&#45;&#45;wrap pair">-->
<!--                    <a href="#" onclick="return false" class="btn-basic btn-line-cb popup__close" @click="isTokReport = false;">닫기</a>-->
<!--                    <a href="#" onclick="return false" class="btn-basic btn-cta" @click="tokReportCookie(); $goMenu('tokReportMain')">3일 동안 보지 않기</a>-->
<!--                </div>-->
<!--            </div>-->
<!--        </div>-->
        <!-- /톡톡 리포트 홍보 팝업 -->

        <!-- 231213 추가사항 사용가이드 팝업 (PC 버전) -->
<!--        <div class="popup__wrap guide guide&#45;&#45;pc" :style="{ display: showUseGuide ? 'block' : 'none' }">-->
<!--            <div class="popup__item&#45;&#45;container">-->
<!--                <div class="popup__item&#45;&#45;group">-->
<!--                    <div class="popup__item&#45;&#45;head tab__box">-->
<!--                        <button class="btn-basic tab&#45;&#45;btn btn-line-cw btn-cta" @click="openTab($event, 'tab1')">앱 사용가이드 영상</button>-->
<!--                        <button class="btn-basic tab&#45;&#45;btn btn-line-cw" @click="openTab($event, 'tab2')">서비스 소개서</button>-->
<!--                    </div>-->
<!--                    <div class="popup__item&#45;&#45;body">-->
<!--                        <div id="tab1" class="tab__box&#45;&#45;cnts active">-->
<!--                            <div class="">-->
<!--                                <div>-->
<!--                                    <youtube-->
<!--                                            id="youtubePlayer"-->
<!--                                            :video-id="videoId"-->
<!--                                            :player-vars="playerVars"-->
<!--                                            width="100%"-->
<!--                                            height="522px"-->
<!--                                            @autoplay="autoplay"-->
<!--                                            @ready="ready"-->
<!--                                            @playing="playing"-->
<!--                                            @ended="ended"-->
<!--                                            @error="error"-->
<!--                                            ref="youtube" />-->
<!--                                    <p class="txt__sub">화면이 흐린 경우,<span> 화면 하단의 설정(<i></i>) 화질을 클릭한 후 높은 숫자로 변경하시면</span> 더욱더 선명한 화면으로 보실 수 있습니다.</p>-->

<!--                                </div>-->
<!--                            </div>-->
<!--                            <div>-->
<!--                                <p>재생 목차</p>-->
<!--                                <div class="radio&#45;&#45;btn">-->
<!--                                    <input type="radio" name="radio-style1" id="r1-1" @click="timeJump(0)" />-->
<!--                                    <label for="r1-1"><i class="fas fa-lock"></i>로그인방법</label>-->
<!--                                </div>-->
<!--                                <div class="radio&#45;&#45;btn">-->
<!--                                    <input type="radio" name="radio-style1" id="r1-2" @click="timeJump(75)" />-->
<!--                                    <label for="r1-2"><i class="fas fa-home"></i>홈메뉴</label>-->
<!--                                </div>-->
<!--                                <div class="radio&#45;&#45;btn">-->
<!--                                    <input type="radio" name="radio-style1" id="r1-7"  @click="timeJump(162.5)" />-->
<!--                                    <label for="r1-7"><i class="fas fa-puzzle-piece"></i>맞춤공고</label>-->
<!--                                </div>-->
<!--                                <div class="radio&#45;&#45;btn">-->
<!--                                    <input type="radio" name="radio-style1" id="r1-3"  @click="timeJump(246.5)" />-->
<!--                                    <label for="r1-3"><i class="fas fa-chart-area"></i>분석실</label>-->
<!--                                </div>-->
<!--                                <div class="radio&#45;&#45;btn">-->
<!--                                    <input type="radio" name="radio-style1" id="r1-4"  @click="timeJump(298)" />-->
<!--                                    <label for="r1-4"><i class="fas fa-user"></i>마이메뉴</label>-->
<!--                                </div>-->
<!--                                <div class="radio&#45;&#45;btn">-->
<!--                                    <input type="radio" name="radio-style1" id="r1-5"  @click="timeJump(376)" />-->
<!--                                    <label for="r1-5"><i class="fas fa-brain"></i>분석요청</label>-->
<!--                                </div>-->
<!--                                <div class="radio&#45;&#45;btn">-->
<!--                                    <input type="radio" name="radio-style1" id="r1-6"  @click="timeJump(515.5)" />-->
<!--                                    <label for="r1-6"><i class="fas fa-sync-alt"></i>웹/앱 연동</label>-->
<!--                                </div>-->
<!--                            </div>-->
<!--                        </div>-->

<!--                        <div id="tab2" class="tab__box&#45;&#45;cnts">-->
<!--                            <div class="">-->
<!--&lt;!&ndash;                                <embed src="https://drive.google.com/viewerng/viewer?embedded=true&url=https://bidtok.co.kr/web/assets/file/%EB%B9%84%EB%93%9C%ED%86%A1%ED%86%A1%20%EC%84%9C%EB%B9%84%EC%8A%A4%20%EC%86%8C%EA%B0%9C%EC%84%9C.pdf" width="100%" height="522px">&ndash;&gt;-->
<!--                                <embed src="https://drive.google.com/viewerng/viewer?embedded=true&url=https://www.bidtok.co.kr/web/assets/file/%EB%B9%84%EB%93%9C%ED%86%A1%ED%86%A1_%EB%B8%8C%EB%A1%9C%EC%8A%88%EC%96%B4.pdf" width="100%" height="522px">-->
<!--&lt;!&ndash;                                <embed src="web/assets/file/비드톡톡 서비스 소개서.pdf#toolbar=0&navpanes=0&scrollbar=0" type="application/pdf" width="100%" height="522px" frameborder="0"/>&ndash;&gt;-->
<!--                            </div>-->
<!--                            <div class="">-->
<!--                                <p>파일 목록</p>-->
<!--                                <a href="web/assets/file/비드톡톡_브로슈어.pdf" class="btn-basic btn-cta" download="비드톡톡 브로슈어.pdf"><i class="far fa-file-pdf"></i>다운로드</a>-->
<!--                            </div>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                </div>-->
<!--                <div class="popup__button&#45;&#45;wrap single">-->
<!--                    <a href="#" onclick="return false" class="btn-basic btn-cta popup__close" @click="hideGuidePopup">닫기</a>-->
<!--                </div>-->
<!--            </div>-->
<!--        </div>-->
        <!-- //231213 추가사항 사용가이드 팝업 (PC 버전) -->

        <!-- 231213 추가사항 사용가이드 팝업 (MO 버전) -->
        <div class="popup__wrap guide guide--mo"  :style="{ display: showUseGuideMo ? 'block' : 'none' }">
            <div class="popup__item--container">
                <div class="popup__item--group">
                    <div class="popup__item--head">
                        <p class="txt__title">사용가이드 안내</p>
                    </div>
                    <div class="popup__item--body">
                        <a href="https://www.youtube.com/watch?v=NOyU_8ZCKU4" class="btn-basic btn-cta"><i class="fab fa-youtube"></i>사용가이드<span class="mo-item"></span>영상 보기</a>
                        <a href="web/assets/file/비드톡톡 서비스 소개서.pdf" class="btn-basic btn-cta"><i class="far fa-file-pdf"></i>서비스 소개서<span class="mo-item"></span>PDF 다운로드</a>
                    </div>
                </div>
                <div class="popup__button--wrap single">
                    <a href="#" onclick="return false" class="btn-basic btn-cta popup__close" @click="hideGuidePopupMo">닫기</a>
                </div>
            </div>
        </div>
        <!-- //231213 추가사항 사용가이드 팝업 (MO 버전) -->

        <!-- 공사 실적 정보 변경 필요 팝업-->
        <div class="popup__wrap confim cfmt"  :class="isPopAcrslt ? 'active':''">
            <AcrsltPop></AcrsltPop>
        </div>
        <!--  /공사 실적 정보 변경 필요 팝업-->
        <!--  경영 상태 정보 변경 필요 팝업-->
        <div class="popup__wrap confim cfmt"  :class="isPopManageStat ? 'active':''">
            <ManageStatPop></ManageStatPop>
        </div>
        <!--  /경영 상태 정보 변경 필요 팝업-->

        <!--  시공능력평가액 정보 변경 필요 팝업-->
        <div class="popup__wrap confim cfmt"  :class="isPopCswkCapaEvltn ? 'active':''">
            <CswkCapaEvltnPop></CswkCapaEvltnPop>
        </div>
        <!--  /시공능력평가액 정보 변경 필요 팝업-->

        <!--  내 경영 정보 변경 필요 팝업-->
        <div class="popup__wrap confim cfmt"  :class="isPopManage ? 'active':''">
            <ManagePop></ManagePop>
        </div>
        <!--  /내 경영 정보 변경 필요 팝업-->

        <!-- 맞춤공고 자동분석 종료 알림 팝업  D-1 -->
        <div class="popup__wrap auto-fin__wrap d-1" :class="isPopAutoAnlsPrevDdayPop ? 'active':''">
            <AutoAnlsPrevDdayPop ref="AutoAnlsPrevDdayPop" ></AutoAnlsPrevDdayPop>
        </div>
        <!-- /맞춤공고 자동분석 종료 알림 팝업  D-1 -->

        <!-- 맞춤공고 자동분석 종료 알림 팝업  D-day && D+1 -->
        <div class="popup__wrap auto-fin__wrap d-day"  :class="isPopAutoAnlsDdayPop || isPopAutoAnlsEnd ? 'active':''">
            <AutoAnlsDdayPop ref="AutoAnlsDdayPop"></AutoAnlsDdayPop>
        </div>
        <!-- 맞춤공고 자동분석 종료 알림 팝업  D-day && D+1 -->


        <!-- 자동 분석 설정 변경 -->
        <div class="popup__wrap  recom-announce__pop--wrap set-pop__wrap recom-announce" :class="isPopSetAutoAnls ? 'active' : ''" >
            <AutoAnlsPop ref="AutoAnlsPop" v-if="isPopSetAutoAnls"></AutoAnlsPop>
        </div>
        <!-- /자동 분석 설정 변경 -->

<!--        <div class="popup__wrap recom-announce__pop&#45;&#45;wrap consent-use__pop&#45;&#45;wrap" :class="isPopCustCntrct ? 'active' : ''">-->
<!--            <CustCntrctPop v-if="isPopCustCntrct"></CustCntrctPop>-->
<!--        </div>-->

        <!-- 분석서비스 이용 동의 팝업 -->
        <div class="popup__wrap recom-announce__pop--wrap consent-use__pop--wrap" :class="isPopCustCntrct ? 'active' : ''">
            <!-- 시작 -->
            <div class="popup__item--container recom-announce__pop--container consent-use__pop--container cover" :class="isPopCustCntrctStep1 ? 'active' : ''">
                <div class="popup__item--group recom-announce__pop--group consent-use__pop--group">
                    <div class="popup__item--head">
                        <p class="txt__title">분석서비스 이용 동의</p>
                        <a href="#" onclick="return false" class="popup__close" @click="closeCustCntrctPop()">닫기버튼</a>
                    </div>
                    <div class="popup__item--body recom-announce__pop--body consent-use__pop--body">
                        <p class="txt--sub">분석 서비스를 이용을 위해<br> 이용약관 및 계약서 동의가 필요합니다.</p>
                        <p class="txt--title">지금 바로 동의하고 분석 서비스를 이용해 보세요!</p>
                        <a href="#" onclick="return false" class="btn-basic btn-cta popup__next consent-use__pop--btn" @click=" isPopCustCntrctStep1 = false; isPopCustCntrctStep2 = true;">네.<br>동의하고 바로 이용할게요.</a>
                        <a href="#" onclick="return false" class="btn-basic btn-line-cb consent-use__pop--btn consent-use__pop--btn--close" @click="isPopCustCntrct = false">아니오.<br>그냥 둘러만 볼래요.</a>
                    </div>
                </div>
            </div>
            <!-- /시작 -->

            <!-- 내용 -->
            <div class="popup__item--container recom-announce__pop--container consent-use__pop--container step-01" :class="isPopCustCntrctStep2 ? 'active' : ''">
                <div class="popup__item--group recom-announce__pop--group consent-use__pop--group">
                    <div class="popup__item--head">
                        <p class="txt__title">분석서비스 이용 동의</p>
                        <a href="#" onclick="return false" class="popup__close" @click="closeCustCntrctPop()">닫기버튼</a>
                    </div>
                    <div class="popup__item--body recom-announce__pop--body consent-use__pop--body">
                        <p class="txt__title border-bottom">① 분석서비스 이용 동의</p>
                        <div class="join-form__box">
                            <p class="join-form__title consent-title">⑤ 약관동의</p>
                            <ul class="input join-form__wrap--link consent-use__pop--link">
                                <li class="join-form__wrap--move use-consent">
                                    <a href="#" onclick="return false" class="btn-basic btn-line-cw link__consent" @click="isPopUseAgree = true;">(필수)분석서비스 이용 약관 동의
                                        <span class="link__consent--check">
                                            <i class="check-none" title="체크 비활성화" :style="{ display: !anlsAgreeYn ? 'inline-block' : 'none' }"></i>
                                            <i class="check-active" title="체크 활성화" :style="{ display: anlsAgreeYn ? 'inline-block' : 'none' }"></i>
                                            동의
                                        </span>
                                    </a>
                                </li>
                                <li class="join-form__wrap--move info-consent">
                                    <a href="#" onclick="return false" class="btn-basic btn-line-cw link__consent" @click="isPopCntrct = true;">(필수)분석서비스 제공계약서
                                        <span class="link__consent--check">
                                    <i class="check-none" title="체크 비활성화" :style="{ display: !cntrctAgreeYn ? 'inline-block' : 'none' }"></i>
                                    <i class="check-active" title="체크 활성화" :style="{ display: cntrctAgreeYn ? 'inline-block' : 'none' }"></i>
                                    동의
                                     </span>
                                    </a>
                                </li>
                            </ul>

                        </div>
                        <p class="txt__title">② 세금계산서 수신 이메일(필수)</p>
                        <div class="input step-01--input">
                            <input type="text" placeholder="이메일을 입력해주세요." v-model="email">
                        </div>
                        <!--                        <p class="txt__sub">* 성공보수료의 세금계산서를 수신할 이메일을 입력해 주세요.</p>-->
                        <!--                        <div class="input&#45;&#45;check">-->
                        <!--                            <input type="checkbox" id="email" v-model="checkEmail">-->
                        <!--                            <label for="email"></label>-->
                        <!--                        </div>-->
                        <!--                        <label for="email">이메일로 분석서비스 제공계약서 받기</label>-->
                    </div>
                </div>
                <div class="popup__button--wrap single">
                    <a href="#" onclick="return false" class="btn-basic btn-cta popup__next"
                       :class="anlsAgreeYn && cntrctAgreeYn && email != ''? 'btn__active':'btn__non-active'"
                       @click="insertAnlsAgree()">
                        완료
                    </a>
                </div>
            </div>
            <!-- / 내용 -->

            <!-- 마지막 -->
            <div class="popup__item--container recom-announce__pop--container consent-use__pop--container step-last" :class="isPopCustCntrctStep3 ? 'active' : ''">
                <div class="popup__item--group recom-announce__pop--group consent-use__pop--group">
                    <div class="popup__item--head">
                        <p class="txt__title">분석서비스 이용 동의</p>
                        <a href="#" onclick="return false" class="popup__close" @click="closeCustCntrctPop(); $router.go(0);">닫기버튼</a>
                    </div>
                    <div class="popup__item--body recom-announce__pop--body consent-use__pop--body">
                        <p class="txt__title">분석서비스 이용 동의가<br>완료되었습니다.</p>
                        <div class="grid__wrap">
                            <div class="grid__container consent-use__pop--grid">
                                <ul class="grid__group head">
                                    <li class="grid__cell consent-use__pop--grid--head">동의날짜</li>
                                    <li class="grid__cell consent-use__pop--grid--body">{{agreeDt}}</li>

                                </ul>
                                <ul class="grid__group head">
                                    <li class="grid__cell consent-use__pop--grid--head">기간</li>
                                    <li class="grid__cell consent-use__pop--grid--body">{{agreeDate}}(1년)</li>
                                </ul>
                            </div>
                        </div>
                        <!--                        <p class="txt__sub" v-show="email != '' && checkEmail">분석서비스 제공계약서는 이메일로 발송되었습니다.</p>-->
                        <p class="txt__sub">멤버십 혜택 받아가세요!</p>
                        <a href="#" onclick="return false" class="ai-mbr--promo" @click="$goMenu('membership')"></a>
                    </div>
                </div>
                <div class="popup__button--wrap single">
                    <a href="#" onclick="return false" class="btn-basic btn-cta popup__close" @click="closeCustCntrctPop(); $router.go(0);">확인</a>
                </div>
            </div>
            <!-- /마지막 -->
        </div>

        <!-- 분석서비스 이용 약관 동의 팝업-->
        <div class="popup__wrap use-consent__wrap use" :class="isPopUseAgree ? 'active' : ''">
            <div class="popup__item--container use-consent__container">
                <div class="popup__item--group use-consent__wrap--group">
                    <div class="popup__item--head">
                        <h3 class="txt__title">분석서비스 이용 약관 동의</h3>
                        <button type="button" class="popup__close" @click="isPopUseAgree = false;"></button>
                    </div>
                    <div id="headerUseAgreeScroll" class="popup__item--body use-consent__wrap--body">
                        <EtcUseAgree></EtcUseAgree>
                    </div>
                </div>
                <div class="input popup__button--wrap use-consent__wrap--button single">
                    <button id="headerAnlsAgreeBtn" type="submit" class="btn-basic btn__non-active" @click="closeAnlsAgreePop(true)"><p class="txt__empha">동의</p>*약관을 끝까지 보신 후, 동의 가능합니다.</button>
                </div>
            </div>
        </div>
        <!-- /분석서비스 이용 약관 동의 -->

        <!-- 분석 서비스 제공 계약서 팝업-->
        <div class="popup__wrap use-consent__wrap Provis" :class="isPopCntrct ? 'active' : ''">
            <div class="popup__item--container use-consent__container">
                <div class="popup__item--group use-consent__wrap--group">
                    <div class="popup__item--head">
                        <h3 class="txt__title">분석 서비스 제공 계약서</h3>
                        <button type="button" class="popup__close" @click="isPopCntrct = false"></button>
                    </div>
                    <div id="headerEtcAgreeScroll" class="popup__item--body use-consent__wrap--body">
                        <div class="clause wrap agree">
                            <div class="agree__table--wrap">
                                <ul class="agree__table--group">
                                    <li class="agree__table--item subject"><span class="agree__txt">계약자</span></li>
                                    <li class="agree__table--item content">
                                        <div class="agree__table--box">
                                            <div class="agree__table--element head couple"><span class="agree__txt agree__txt--head">정보 제공자
                                                (“동”) </span></div>
                                            <div class="agree__table--element body">
                                                <p class="agree__txt agree__table--sub"><span>회 사 명</span><span class="agree__table--title">
                                                    주식회사 비드톡톡</span></p>
                                                <p class="agree__txt agree__table--sub"><span>대 표 이 사</span><span class="agree__table--title">
                                                    최경호</span></p>
                                            </div>
                                        </div>
                                        <div class="agree__table--box">
                                            <div class="agree__table--element head couple"><span class="agree__txt agree__txt--head">정보 이용자
                                                (“행”) </span></div>
                                            <div class="agree__table--element body">
                                                <p class="agree__txt agree__table--sub"><span>회 사 명</span><span id="cmpNm"
                                                                                                                class="agree__table--title"><strong>{{custBiz.cmpNm}}</strong></span></p>
                                                <p class="agree__txt agree__table--sub"><span>대 표 이 사</span><span id="userNm"
                                                                                                                  class="agree__table--title"><strong>{{custBiz.rpsntvNm}}</strong></span></p>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                                <ul class="agree__table--group">
                                    <li class="agree__table--item subject"><span class="agree__txt">계약내용</span></li>
                                    <li class="agree__table--item content">
                                        <div class="agree__table--box couple">
                                            <div class="agree__table--element head"><span class="agree__txt agree__txt--head">계약명</span></div>
                                            <div class="agree__table--element body">
                                                <p class="agree__txt agree__table--sub"><span>전자입찰 분석정보 제공계약서 </span></p>
                                            </div>
                                        </div>
                                        <div class="agree__table--box couple">
                                            <div class="agree__table--element head"><span class="agree__txt agree__txt--head">계약 금액</span></div>
                                            <div class="agree__table--element body">
                                                <p class="agree__txt agree__table--sub">금<span
                                                        class="agree__table--title"><strong>0</strong></span>원 (VAT포함) </p>
                                            </div>
                                        </div>
                                        <div class="agree__table--box couple">
                                            <div class="agree__table--element head"><span class="agree__txt agree__txt--head">성공 보수료</span>
                                            </div>
                                            <div class="agree__table--element body">
                                                <p class="agree__txt agree__table--sub agree__table--title">※아래 <span>&lt;표1&gt;</span>에서 제시하는
                                                    바에 따름 (분석가 선택 시 수수료율 적용)</p>
                                            </div>
                                        </div>
                                        <div class="agree__table--box">
                                            <div class="agree__table--element head couple"><span class="agree__txt agree__txt--head">성공 보수료 지급
                                                조건</span></div>
                                            <div class="agree__table--element body">
                                                <p class="agree__txt agree__table--sub agree__table--title">※낙찰일로 부터 14일 이내 (별첨 계약내용 참조)</p>
                                            </div>
                                        </div>
                                        <div class="agree__table--box">
                                            <div class="agree__table--element head couple"><span class="agree__txt agree__txt--head">계약
                                                기간</span></div>
                                            <div class="agree__table--element body">
                                                <p class="agree__txt agree__table--sub agree__table--title">{{currentDate}} ~ {{nextYearDate}}</p>
                                            </div>
                                        </div>
                                        <div class="agree__table--box">
                                            <div class="agree__table--element head couple"><span class="agree__txt agree__txt--head">기타</span>
                                            </div>
                                            <div class="agree__table--element body">
                                                <p class="agree__txt agree__table--sub agree__table--title">※ 별첨 계약 내용에 정한 바에 따름 *계약 기간 경과 후 상호
                                                    이의가 없을 경우 자동 연장 되며 계약금은 발생하지 않습니다.</p>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                            <div class="clause__group">
                                <p class="clause__group--info agree__table--notice"><span> 주식회사 비드톡톡과 정보이용자(“행”)는 표지 및 별첨계약내용에 따라 본 “분석서비스 제공
                                    계약”을 체결하고 신의에 따라 성실하게 계약상 의무를 이행할 것을 확약하였으며 이 계약의 증거로서 내용 확인 후 동의함으로서 계약 성립은 유효하다.</span></p>
                            </div>
                            <AnlsFee></AnlsFee>
                            <div class="clause__group agree__company">
                                <h2 class="clause__group--tit align-c auto-input">{{currentDate}}</h2>
                                <p class="clause__group--info agree__company--announce"><span>※ 주식회사 비드톡톡 임직원들은 전자입찰 분석정보 제공계약서에 명시된 계약금 및 성공보수료
                                    이외에 어떠한 금품, 향응, 선물 그리고 편의를 수수하거나 제공을 요청하지 않으며 이를 위배한 임직원은 사규에 따라 징계합니다.</span></p>
                                <div class="comppany-info__group agree__company--info">
                                    <p class="clause__group--info align-c"><span>대전광역시 서구 계룡로553번길 18, 조이빌딩 9층</span></p>
                                    <p class="clause__group--info align-c"><span>주식회사 비드톡톡 </span></p>
                                    <p class="clause__group--info align-c"><span>TEL 1670-0508</span><span>FAX 042-367-3313</span></p>
                                </div>
                            </div>
                            <h2 class="menu__title clause__txt--title add-message">계 약 내 용</h2>
                            <div class="clause__group">
                                <h2 class="clause__group--tit">제 1조 (계약의 목적)</h2>
                                <p class="clause__group--info"><span>본 계약은 “동”이 “행”에게 전자 입찰 분석 정보를 제공하고 “행”은 “동”이 제공하는 전자 입찰 분석 정보를 이용하며 그 대가를
                                    “동”에게 지급하는 것과 관련하여 필요한 사항을 정함을 목적으로 한다.</span></p>
                            </div>
                            <div class="clause__group">
                                <h2 class="clause__group--tit">제2조 (“동”의 의무)</h2>
                                <p class="clause__group--info"><span>본 계약에 따라 “동”은 “동”이 운영하는 인터넷 웹페이지(www.bidtok.co.kr)와 어플리케이션(비드톡톡)을 통하여 국가나
                                    지방자치단체가 공고한 각종 입찰 관련 정보를 “행”에게 제공하기로 한다. 또한 “행”이 “동”에게 요청하는 입찰건에 대해서도 동일하게 정보를 제공하기로 한다. “동”이 “행”에게 제공하는
                                    정보의 종류와 범위는 “동”의 웹페이지와 어플리케이션을 통해 제공되고 있는 내용을 기본으로 하며 유선, 문자, SNS, 이메일, 팩스 등도 포함된다.</span></p>
                            </div>
                            <div class="clause__group">
                                <h2 class="clause__group--tit">제3조 (“행”의 의무) </h2>
                                <p class="clause__group--info"><span class="clause__group--info indent"><span>① “행”은 “동”으로부터 제2조의 정보를 제공 받는데에 대한
                                        대가로 제4조 ①항에 정한 액수에 따라 “동”에게 이용대금을 지급하여야 한다. 계약 금액이 지급된 후부터 분석실을 통한 가격 제시가 시작되는 것으로 한다.</span><span>②
                                        “행”은 본 계약 체결일로부터 분석요청한 입찰 공고에 대하여 분석실을 통하여 제시 가격을 확인하며 확인한 가격에 대하여 가격 이행을 하여야 한다. 단, 가격 불이행을 했을 경우
                                        횟수에 따라 “동”은 “행”에 대해 등급을 변동 할 수 있다. (단, 가격 불이행 가격이 낙찰가가 된 경우에는 횟수에 관계없이 즉시 등급을 변동할 수 있다) (비드톡톡 이용약관
                                        제10조 참조)</span></span></p>
                            </div>
                            <div class="clause__group">
                                <h2 class="clause__group--tit">제4조 (이용 대금) </h2>
                                <p class="clause__group--info"><span class="clause__group--info indent"><span>① “행”은 본 계약 체결과 동시에 “동”에게 본 계약서
                                        표지에 정한 “계약 금액”을 지급한다. “동”의 귀책사유로 “행”에 대한 정보 제공이 불가능해진 경우를 제외하고 “행”은 “동”에게 기본 계약 금액의 반환을 청구할 수
                                        없다.</span><span>② “행”이 “동”으로부터 제시 받은 가격으로 입찰에 참가하여 최종 낙찰자로 선정 된 경우(“행”이 동가의 1순위가 된 후 추첨을 통하여 최종 낙찰이
                                        확정된 경우, “행”보다 선 순위의 입찰 참가자가 있음에도 불구하고 “행”이 최종 낙찰이 된 경우 등을 포함) “행”은 “동”에게 본 계약서 표지에 정한 “성공 보수료”를
                                        지급한다.단, 단가 계약(공사, 납품, 구매 설치, 용역 수행 등)의 경우 총 공사 예정 금액 또는 총 배정 예산으로 성공 보수료를 지급한다.</span><span>③ 공동 도급을
                                        진행하여(구성 업체,분담 업체) 최종 낙찰자가될 경우 최종 공사 비율에 따라 본 계약서 표지에 정한 성공 보수료를 지급한다. </span><span>④ “행”은 “동”의 웹페이지
                                        및 어플리케이션 상의 “분석실”을 통해 “분석제시가 확인” 버튼을 클릭한 공고 건에 관여하는 “행”이 해당 공고건의 입찰에 참여한 경우 “동”으로부터 제시 받은 가격으로 입찰에
                                        참여한 것으로 보아 ②항을 적용하기로 한다. </span><span>⑤ 가격이행 여부의 표준 오차는 ±0.1% 범위로 인정하거나 또는 비드톡톡 가격 제시 기준 절상,절하 (ex
                                        만원단위, 십만단위, 백만단위 등) 일때 혹은 임의로 수정할 경우 또한 이행 여부 범위로 인정하여 성공 보수료를 지급한다. (분석서비스 이용약관 제13조 참조)
                                    </span><span>⑥ “행”은 본 계약서 표지에 정한 “성공 보수료”를 “동”의 성공 낙찰 일로부터 14 일 이내에 지급하여야 하며 지체하는 경우 “동”은 “행”에 대한 정보 제공을
                                        중단할 수 있다. 다만 성공 보수 금액이 1,000만원 이상일 경우 “행”은 성공 보수 금액 중 50%의 지급 유예를 신청할 수 있으며, “동”은 “행”의 지불 능력 기타 제반
                                        사정을 참작하여 지급 유예를 승인할 수 있다.(단,지급 유예 기간은 최장 60일 이내로 한다.)</span></span></p>
                            </div>
                            <div class="clause__group">
                                <h2 class="clause__group--tit">제5조(비밀 보장) </h2>
                                <p class="clause__group--info"><span class="clause__group--info indent"><span>① “행”은 본 계약에 따라 “동”으로부터 제시 받은 가격을
                                        제3자에게 공개하거나 양도 할 수 없으며 이를 위반함으로 인해 “동” 이 입은 손해를 배상해야 한다. 또한 “행”은 본 계약에 의하여 지득한 “동”의 자료 및 정보 등의 비밀
                                        (이미 일반에 알려진 정보는 제외)을 타인에게 누설 또는 공개 할 수 없으며 이를 위반함으로 인해 “동” 이 입은 손해를 배상해야 한다.</span></span></p>
                            </div>
                            <div class="clause__group">
                                <h2 class="clause__group--tit">제6조(분쟁의 해결) </h2>
                                <p class="clause__group--info"><span>본 계약과 관련하여 발생하는 분쟁은 수원지방법원 성남지원(택일 결정 필요)을 제1심 관할법원으로 하여 해결한다.</span></p>
                            </div>
                            <div class="agree__check--area">
                                <div class="agree__check--group pc">
                                    <div class="agree__check--box content clause__group--info"><span>“행”은 본 계약서 내용을 반드시 숙지하고 동의하며 원만히 계약이 성사되었음을
                                        인정합니다. 위 계약 내용에 대해 동의 하십니까?</span></div>
                                    <div class="agree__check--box company clause__group--info"><span>회사명:&nbsp;{{custBiz.cmpNm}}</span><span></span></div>
                                    <div class="agree__check--box agree clause__group--info"><span>동의</span><input type="checkbox"
                                                                                                                   id="check1" :checked="anlsAgreeFlag" @click="anlsAgreeFlag = !anlsAgreeFlag"><label for="check1"></label></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="input popup__button--wrap use-consent__wrap--button single">
                    <button id="headerCntrctAgreeBtn" type="submit" class="btn-basic btn__non-active"><p class="txt__empha" @click="closeCntrctAgreePop(true)">동의</p>*약관을 끝까지 보신 후, 동의 가능합니다.</button>
                </div>
            </div>
        </div>
        <!-- /분석 서비스 제공 계약서 -->

        <!-- 분석 요청 제한 안내 팝업 -->
        <div class="popup__wrap analy-rstrt" :class="isPopNonFulfliment ? 'active' : ''">
            <div class="popup__item--container analy-rstrt--container">
                <div class="popup__item--group">
                    <div class="popup__item--head analy-rstrt--head">
                        <h3 class="txt__title">분석 요청 제한 안내</h3>
                    </div>
                    <div class="popup__item--body analy-rstrt--body">
                        <p class="txt__title">비드톡톡 분석 가격 이행률이 낮아</p>
                        <p class="txt__title strong">분석 요청 서비스 이용이<br>
                            <span class="limit-count">{{custFulfilmentMngt != null ? custFulfilmentMngt.lockCnt : ''}}회</span> 제한되었습니다.
                        </p>
                        <p class="txt__sub mtb24">
                            <span class="date"> {{custFulfilmentMngt != null  ? $moment(custFulfilmentMngt.lockEndDt).add(1,'days').format('MM.DD (ddd)'): ''}}</span>부터 분석 요청 서비스를<br>이용하실 수 있습니다.
                        </p>
                        <p class="txt__sub rstrt-durin">분석 요청 제한 기간</p>
                        <p class="txt__sub date">
                            {{custFulfilmentMngt != null  ? $moment(custFulfilmentMngt.lockStartDt).format('YYYY.MM.DD (ddd)') : ''}} ~
                            {{custFulfilmentMngt != null  ? $moment(custFulfilmentMngt.lockEndDt).format('YYYY.MM.DD (ddd)'): ''}}</p>
                    </div>
                </div>
                <div class="popup__button--wrap single">
                    <a href="#" onclick="return false" class="btn-basic btn-cta popup__close" @click="updateLockConfirm">확인</a>
                </div>
            </div>
        </div>
        <!-- /분석 요청 제한 안내 팝업 -->

        <!-- 경영면허 입력 강제 팝업 -->
        <div class="popup__wrap  recom-announce__pop--wrap enter-pop__wrap recom-announce" :class="isPopForceSetMyInfo ? 'active' : ''" >
            <!-- 경영 면허 정보 입력 - 시작 -->
            <div class="popup__item--container recom-announce__pop--container enter-pop__container enter-pop--cover step-start" :class="isPopForceSetMyInfoStep1 ? 'active' : ''" >
                <div class="popup__item--group recom-announce__pop--group enter-pop__group step-start__group">
                    <div class="popup__item--head">
                        <p class="txt__title">경영/면허 정보를<br>입력해 주세요.</p>
                    </div>
                    <div class="popup__item--body recom-announce__pop--body enter-pop--body">
                        <div class="enter-pop--cover--img"></div>
                        <p class="txt--sub">맞춤공고는<br>입력한 경영/면허 정보를 계산하여<br>입찰에 참여 가능한 공고를 추천하므로</p>
                        <p class="txt--title">경영/면허 정보 입력이 필요합니다.</p>
                    </div>
                </div>
                <div class="popup__button--wrap pair">
                    <a href="#" onclick="return false" class="btn-basic btn-line-cb  popup__close" @click="isPopForceSetMyInfo = false;">취소</a>
                    <a href="#" onclick="return false" class="btn-basic btn-cta popup__next" @click="isPopForceSetMyInfoStep1 = false; isPopForceSetMyInfoStep2 = true;">정보입력</a>
                </div>
            </div>
            <!-- /경영 면허 정보 입력 - 시작 -->

            <!-- 경영 면허 정보 입력 - 경영 정보 -->
            <div class="popup__item--container recom-announce__pop--container enter-pop__container step-01 recom-announce" :class="isPopForceSetMyInfoStep2 ? 'active' : ''" >
                <div class="popup__item--group recom-announce__pop--group enter-pop__group">
                    <div class="popup__item--head">
                        <p class="txt__title">경영 정보</p>
                    </div>
                    <div class="popup__item--body recom-announce__pop--body edit-pop__wrap">
                        <div class="manage tooltip">
                            <div class="tooltip--manage">
                                <h3 class="h3">① 경영 정보상태</h3>
                                <div class="tooltip__group">
                                    <span class="tooltip__box">?</span>
                                    <div class="tooltip--txt">22년 경영상태동의확인서 적용입니다.</div>
                                </div>
                            </div>
                            <h4 class="h4">부채비율</h4>
                            <div class="input edit-pop--input manage--input">
                                <input id="forceDebtRate" type="text" placeholder="부채비율을 입력해 주세요." v-model="debtRateComputed">
                            </div>
                            <h4 class="h4">유동비율</h4>
                            <div class="input edit-pop--input manage--input currnt-ratio">
                                <input id="forceCrasRate" type="text" placeholder="유동비율을 입력해 주세요." v-model="crasRateComputed">
                            </div>
                            <div class="tooltip--credit">
                                <h4 class="h4">신용 평가</h4>
                                <div class="tooltip__group">
                                    <span class="tooltip__box">?</span>
                                    <div class="tooltip--txt">공공기관 입찰용으로 발급받은 신용평가등급확인서로만 사용할 수 있습니다.</div>
                                </div>
                            </div>
                            <div class="sel-box edit-pop--select">
                                <select name="grade" v-model="selGrd">
                                    <option value="none" disabled>등급을 선택해 주세요.</option>
                                    <option value="">해당사항 없음</option>
                                    <option v-for="cdlt in cdltStList" v-bind:key="cdlt.id" :value="cdlt.id">{{cdlt.cdltGrd}}</option>
                                </select>
                            </div>
                            <div class="sel-box edit-pop--select" :class="selGrd == '' ? 'disable' : ''">
                                <select name="agency" v-model="selOrgnz" :disabled="selGrd == '' ? true : false">
                                    <option value="">발급기관을 선택해 주세요.</option>
                                    <option v-for="orgnz in cdltStOrgList" v-bind:key="orgnz.id"  :value="orgnz.comnCdNm">{{orgnz.comnCdNm}}</option>
                                </select>
                            </div>
                            <v-date-picker v-model="cdltStDe" >
                                <template v-slot="{ inputValue, togglePopover }">
                                    <div class="input edit-pop--input manage--input calendar-btn expi-date disable" v-show="!selGrd == ''"
                                         @click="togglePopover">
                                        <input
                                                class="input edit-pop--input manage--input"
                                                placeholder="만료일을 선택해 주세요."
                                                :value="inputValue"
                                        />
                                    </div>
                                    <div class="input edit-pop--input manage--input calendar-btn expi-date disable" v-show="selGrd == ''">
                                        <input type="text" class="disable" placeholder="만료일을 선택해주세요.">
                                    </div>
                                </template>
                            </v-date-picker>
                        </div>
                    </div>
                </div>
                <div class="popup__button--wrap pair">
                    <a href="#" onclick="return false" class="btn-basic btn-line-cb popup__before" @click="isPopForceSetMyInfoStep1 = true; isPopForceSetMyInfoStep2 = false;">이전</a>
                    <a href="#" onclick="return false" class="btn-basic btn-cta popup__next" @click="clickForceSetMyInfoStep()">다음</a>
                </div>
            </div>
            <!-- /경영 면허 정보 입력 - 경영 정보 -->

            <!-- 경영 면허 정보 입력 - 면허 정보 -->
            <div class="popup__item--container recom-announce__pop--container enter-pop__container step-02 recom-announce" :class="isPopForceSetMyInfoStep3 ? 'active' : ''">
                <div class="popup__item--group recom-announce__pop--group enter-pop__group">
                    <div class="popup__item--head">
                        <p class="txt__title">면허 정보</p>
                    </div>
                    <div class="popup__item--body recom-announce__pop--body edit-pop__wrap">
                        <div class="license">
                            <div class="step-02--title">
                                <h3 class="h3">② 면허 정보</h3>
                                <p class="txt--title">총 <span class="count">{{custLicnsList.length}}</span> 개</p>
                                <span class="txt--sub">*면허별로 정보를 입력해주세요.</span>
                            </div>
                            <div class="tok-slide__wrap user-info__slide">
                                <div class="tok-slide__container">
                                    <ul class="tok-slide--list user-info__slide--list">
                                        <li class="tok-slide__item user-info__slide--item" :class="modifySelectLicns == licns ? 'active' : '' " v-for="licns in modifyCustLicnsList" v-bind:key="licns.id">
                                            <a href="#" onclick="return false" @click="forceClickLicns(licns)">{{licns.licnsNm}}</a></li>
                                    </ul>
                                </div>
                                <ul class="tok-slide__arrow">
                                    <li class="tok-slide__arrow--item pre user-info__slide--arrow--item edit-pop__slide--arrow--item" @click="previos">previos </li>
                                    <li class="tok-slide__arrow--item next user-info__slide--arrow--item edit-pop__slide--arrow--item" @click="next">next</li>
                                </ul>
                            </div>
                            <div class="txt--title txt--sel-license">
                                <div class="bdg-lic" v-show="modifySelectLicns.mainLicnsCd == null && modifySelectLicns.licnsNm != null && modifySelectLicns.licnsNm.indexOf('(대)') != -1">
                                    <!-- 주력 main / 대업종 big-->
                                    <i class="bdg-lic--ic big">대업종</i>
                                </div>
                                <div class="bdg-lic" v-show="modifySelectLicns.mainLicnsCd != null">
                                    <!-- 주력 main / 대업종 big-->
                                    <i class="bdg-lic--ic main" >주력</i>
                                </div>
                                <span>{{modifySelectLicns.licnsNm}}</span>
                            </div>
                            <div class="tooltip__group tooltip--3year">
                                <h4 class="h4">3년 실적</h4>
                            </div>
                            <div class="input edit-pop--input license--input">
                                <input id="forceY3AcrsltAmt" type="text" placeholder="3년 실적을 입력해 주세요." v-model="y3AcrsltAmtComputed">
                            </div>
                            <div class="tooltip__group tooltip--5year">
                                <h4 class="h4">5년 실적</h4>
                            </div>
                            <div class="input edit-pop--input license--input">
                                <input id="forceY5AcrsltAmt" type="text" placeholder="5년 실적을 입력해 주세요." v-model="y5AcrsltAmtComputed">
                            </div>
                            <div class="tooltip__group tooltip--perform">
                                <h4 class="h4">추가 실적</h4>
                            </div>
                            <div class="input edit-pop--input license--input">
                                <input id="forceLcettSuplmtAcrsltAmt" type="text" placeholder="추가 실적을 입력해 주세요." v-model="lcettSuplmtAcrsltAmtComputed">
                            </div>
                            <p class="comment-btn step-02--comment"><span>추가 실적: 협회에는 등록되지 않았지만 최종 준공이 완료된 공사 실적 합계액</span></p>
                            <div class="tooltip__group tooltip--capa">
                                <h4 class="h4">시공능력평가액</h4>
                            </div>
                            <div class="input edit-pop--input license--input">
                                <input id="forceCswkCapaEvltnAmt" type="text" placeholder="시공능력평가액을 입력해 주세요." v-model="cswkCapaEvltnAmtComputed">
                            </div>
                            <div class="tooltip__group tooltip--dura">
                                <h4 class="h4">영업기간</h4>
                            </div>
                            <v-date-picker v-model="bsnRegstDe" >
                                <template v-slot="{ inputValue, togglePopover }">
                                    <div class="input edit-pop--input manage--input calendar-btn expi-date"
                                         @click="togglePopover">
                                        <input
                                                class="input edit-pop--input manage--input"
                                                placeholder="영업기간을 선택해 주세요."
                                                :value="inputValue"
                                        />
                                    </div>
                                </template>
                            </v-date-picker>
                        </div>
                    </div>
                </div>
                <div class="popup__button--wrap pair">
                    <a href="#" onclick="return false" class="btn-basic btn-line-cb  popup__before" @click="isPopForceSetMyInfoStep3 = false; isPopForceSetMyInfoStep2 = true;">이전</a>
                    <a href="#" onclick="return false" class="btn-basic btn-cta popup__next" @click="checkLastLicns(); ">{{this.modifySelectLicns == this.modifyCustLicnsList[this.modifyCustLicnsList.length-1] ? '완료': '다음'}}</a>
                </div>
            </div>
            <!-- /경영 면허 정보 입력 - 면허 정보 -->

            <!-- 경영 면허 정보 입력 - 마지막 -->
            <div class="popup__item--container recom-announce__pop--container enter-pop__container enter-pop--cover step-last recom-announce" :class="isPopForceSetMyInfoStep4 ? 'active' : ''">
                <div class="popup__item--group recom-announce__pop--group enter-pop__group step-last__group">
                    <div class="popup__item--head">
                        <p class="txt__title">경영/면허 정보 입력이<br>완료되었어요.</p>
                    </div>
                    <div class="popup__item--body recom-announce__pop--body enter-pop--body">
                        <div class="enter-pop--cover--img"></div>
                        <p class="txt--sub">입력한 경영/면허 정보는<br>맞춤공고 메뉴의 내 경영/면허 정보에서<br>수정 가능합니다.</p>
                    </div>
                </div>
                <div class="popup__button--wrap single">
                    <a href="#" onclick="return false" class="btn-basic btn-cta popup__close" @click="refreshPage">맞춤공고 보기</a>
                </div>
            </div>
            <!-- /경영 면허 정보 입력 - 마지막 -->


            <!-- 경영 면허 정보 입력 진행률
                item 뒤에 active를 붙이면 진행됩니다-->
            <ul class="enter-pop--step recom-announce">
                <li class="enter-pop--step--item item01"></li>
                <li class="enter-pop--step--item item-dash"></li>
                <li class="enter-pop--step--item item02"></li>
            </ul>
            <!-- /경영 면허 정보 입력 진행률  -->
        </div>
        <!-- /경영면허 입력 강제 팝업 -->
        <ExtendCntrctPop ref="ExtendCntrctPop"></ExtendCntrctPop>

    </div>
    <!-- //헤더 -->
</template>

<script>
    import {mapActions, mapState} from 'vuex'
    import config from '../../config';
    import {loadScript} from "vue-plugin-load-script";
    import $ from 'jquery';
    import AcrsltPop from './container/AcrsltPop'
    import ManageStatPop from './container/ManageStatPop'
    import CswkCapaEvltnPop from './container/CswkCapaEvltnPop'
    import ManagePop from './container/ManagePop'
    import AutoAnlsPrevDdayPop from './container/AutoAnlsPrevDdayPop'
    import AutoAnlsDdayPop from './container/AutoAnlsDdayPop'
    import AutoAnlsPop from '@/pages/V2/Popup/AutoAnlsPop'
    import EtcUseAgree from '@/pages/Comn/EtcUseAgree'
    import ExtendCntrctPop from './container/ExtendCntrctPop'
    // import CustCntrctPop from '@/pages/V2/Popup/CustCntrctPop'
    import * as _ from 'lodash'
    import 'slick-carousel/slick/slick.css';
    import 'slick-carousel/slick/slick.min.js';
    import AnlsFee from '@/pages/Comn/AnlsFee'

    // import Vue from 'vue'
    // import VueYoutube from "vue-youtube";

    // Vue.use(VueYoutube);

    window.channelPluginSettings = {
        pluginKey: '7ede9fbd-cfd2-4584-acaf-4c49eab81a12',
        hideDefaultLauncher: true,
    }


    export default {
        name: 'Header',

        created() {
            this.browser();
            // this.$socket.on('chat', (data)=> {
            //     this.textarea += data.message + "\n"
            // })
            // this.$socket.on('connect', ()=> {
            //     console.log('웹 소켓이 서버에 연결되었습니다.');
            //     this.$socket.on('message', (message)=> {
            //         // this.textarea += message.sender + ': '+ message.data.toString()  + "\n"
            //         console.log("메세지를 받았습니다: " + message.sender + ': '+ message.data.toString());
            //         this.message = message.data.toString();
            //         console.log(this.message);
            //         $('#socketDiv').children().remove();
            //         $('#socketDiv').append(message.data.toString());
            //         // document.querySelector('.popup__item--body').append(`<p class='txt__sub'>소켓통신 테스트</p>`);
            //         this.notificationsBadge = true;
            //     })
            //
            //     this.$socket.on('response', (response)=> {
            //         console.log("응답 메세지를 받았습니다: " + response.command + ", "
            //             + response.code + ", " + response.message);
            //     })
            // })
        },
        components: {
            AcrsltPop,
            ManageStatPop,
            CswkCapaEvltnPop,
            ManagePop,
            AutoAnlsPrevDdayPop,
            AutoAnlsDdayPop,
            AutoAnlsPop,
            EtcUseAgree,
            AnlsFee,
            ExtendCntrctPop,
            // CustCntrctPop

        },
        data: () => ({
            config,
            searchCollapse: true,
            isScrollActive : false,
            notifications: [
                { text: 'Check out this awesome ticket', icon: 'mdi-tag', color: 'warning' },
                { text: 'What is the best way to get ...', icon: 'mdi-thumb-up', color: 'success'  },
                { text: 'This is just a simple notification', icon: 'mdi-flag', color: 'error'  },
                { text: '12 new orders has arrived today', icon: 'mdi-cart', color: 'primary'  },
            ],
            messages: [
                // { text: 'JH', name: 'Jane Hew', message: 'Hey! How is it going?', time: '09:32', color: 'warning' },
                // { text: 'LB', name: 'Lloyd Brown', message: 'Check out my new Dashboard', time: '10:02', color: 'success'  },
                // { text: 'MW', name: 'Mark Winstein', message: 'I want rearrange the appointment', time: '12:16', color: 'error'  },
                // { text: 'LD', name: 'Liana Dutti', message: 'Good news from sale department', time: '14:56', color: 'primary'  },
            ],
            account: [
                { text: '정보수정', icon: 'mdi-account', color: 'textColor' },
                { text: '설정', icon: 'mdi-thumb-up', color: 'textColor'  },
                { text: '도움말', icon: 'mdi-flag', color: 'textColor'  }
            ],
            videoId: 'NOyU_8ZCKU4',
            autoplay: 0,
            playerVars: {
                autoplay: 0,
                rel:0,
                mute: 1,
                cc_lang_pref: "ko",
                cc_load_policy: 1,
            },
            player : null,
            notificationsBadge: false,
            messageBadge: true,
            myPhoto: require('../../assets/jhun.jpg'),
            loginInfo: null,
            showUseGuide: false,
            showUseGuideMo : false,
            //isShowHotIssue : false,
            message: "",
            popHtml: "",
            closePop: 'N',
            earlyBirdYn: 'N',
            hotIssuePop: false,
            hotIssueCookie: false,
            isPopAutoAnlsEnd: false,    //자동분석 종료 알림
            isPopAcrslt: false,   //공사 실적 정보 변경 필요 팝업
            isPopManageStat: false,   //경영 상태 정보 변경 필요 팝업
            isPopCswkCapaEvltn: false,   //시공능력평가액 정보 변경 필요 팝업
            isPopManage: false,   //내 경영 정보 변경 필요 팝업
            isTokReport: false,     //톡톡리포트 팝업
            isNewYear: false,    //신년팝업
            isPopAutoAnlsPrevDdayPop : false,
            isPopAutoAnlsDdayPop : false,
            isPopAutoAnlsPop : false,
            isPopSetAutoAnls : false,
            autoAnlsConfig : null,
            autoAnlsEndDt : '',

            tokReportYn: 'N',   //톡톡리포트 팝업 여부
            isLoading: false,
            holidayMngmtList: [],   //공휴일 목록
            dday : '',

            selAnlstList: [], //분석가리스트 선택

            isAutoAnls: false,  //자동분석 여부

            debtRate: '',   //부채비율
            crasRate: '',   //유동비율
            y3AcrsltAmt: '',    //3년실적
            y5AcrsltAmt: '',    //5년 실적
            lcettSuplmtAcrsltAmt: '',   //추가실적
            cswkCapaEvltnAmt: '',   //시공능력평가금액
            modifySelectLicns: {},  //선택 라이센스
            custLicnsList : [],
            modifyCustLicnsList : [],     //수정 고객 면허 목록


            isPopNonFulfliment: false, //불이행 팝업

            isPopCustCntrct: false, //약관 동의 팝업
            isPopCustCntrctStep1: false,
            isPopCustCntrctStep2: false,
            isPopCustCntrctStep3: false,

            custCntrctList: [], //계약서 정보


            ///분석동의서

            email: '', //이메일
            checkEmail: false, //이메일 동의 여부

            anlsAgreeYn: false, //분석서비스 이용 약관 동의 여부
            cntrctAgreeYn: false, // /분석서비스 제공 계약서 동의 여부
            anlsAgreeFlag: false,

            isPopUseAgree: false,   //분석서비스 약관동의팝업
            isPopCntrct: false,     //분석서비스 제공 계약서

            agreeDt: '',    //계약서 동의 일
            agreeDate: '',  //계약서 유효 기간
            custBiz: [],    //사업자 목록

            ///분석동의서

            //분석 제한
            custFulfilmentMngt: [], // 불이행 정보

            //경영면허정보
            isPopForceSetMyInfo: false,    //경영면허 입력 강제 팝업
            isPopForceSetMyInfoStep1: false,    //경영면허 입력 강제 팝업
            isPopForceSetMyInfoStep2: false,    //경영면허 입력 강제 팝업
            isPopForceSetMyInfoStep3: false,    //경영면허 입력 강제 팝업
            isPopForceSetMyInfoStep4: false,    //경영면허 입력 강제 팝업

            selGrd: '', //선택등급
            selOrgnz: '', // 선택 발급 기관

            orgnzText: '발급기관을 선택해 주세요.', // 선택 발급 기관
            cdltText: '등급을 선택해 주세요.',   //선택 등급
            cdltStList: [], //신용평가 목록
            cdltStOrgList: [], //발급기관 목록
            cdltStDe: '',   //신용평가 만료일
            bsnRegstDe: '',     //영업기간

            tooltipList: [], //툴팁리스트
            tooltipTxt1: '',    //경영상태 툴팁
            tooltipTxt2: '',    //3년실적 툴팁
            tooltipTxt3: '',    //5년실적 툴팁
            tooltipTxt4: '',    //시공평가액 툴팁
            tooltipTxt5: '',    //영업기간 툴팁
            tooltipActive : false,
            custInfo : {},      //고객 정보

            AIStatusText: '', // AI멤버십 상태 텍스트
            subDday: '', // 구독 D-day
            unreadCount: 0
        }),
        computed: {
            ...mapState(['drawer']),
            DRAWER_STATE :{
                get() {
                    return this.drawer
                },
            },
            currentDate() {
                const today = new Date();
                const year = today.getFullYear();
                const month = today.getMonth() + 1;
                const day = today.getDate();

                const formattedDate = `${year}년 ${month}월 ${day}일`;

                return formattedDate;
            },
            nextYearDate(){
                const today = new Date();
                const year = today.getFullYear() + 1;
                const month = today.getMonth() + 1;
                const day = today.getDate() - 1;

                const formattedDate = `${year}년 ${month}월 ${day}일`;

                return formattedDate;
            },
            debtRateComputed: {
                get() {
                    try{
                        return this.debtRate.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                    }catch{

                        return '';
                    }
                },
                set(value) {
                    if(value == ''){
                        this.debtRate = '';
                    } else {
                        this.debtRate = parseFloat(value.replace(/,/g, ''));
                    }
                }
            },
            crasRateComputed: {
                get() {
                    try{
                        return this.crasRate.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                    }catch{
                        return '';
                    }
                },
                set(value) {
                    if(value == ''){
                        this.crasRate = '';
                    } else {
                        this.crasRate = parseFloat(value.replace(/,/g, ''));
                    }
                }
            },
            y3AcrsltAmtComputed: {
                get() {

                    return this.y3AcrsltAmt.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                },
                set(value) {
                    if(value == ''){
                        this.y3AcrsltAmt = 0;
                    } else {
                        this.y3AcrsltAmt = parseFloat(value.replace(/,/g, ''));
                    }
                }
            },
            y5AcrsltAmtComputed: {
                get() {
                    return this.y5AcrsltAmt.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                },
                set(value) {
                    if(value == ''){
                        this.y5AcrsltAmt = 0;
                    } else {
                        this.y5AcrsltAmt = parseFloat(value.replace(/,/g, ''));
                    }
                }
            },
            cswkCapaEvltnAmtComputed: {
                get() {
                    return this.cswkCapaEvltnAmt.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                },
                set(value) {
                    if(value == ''){
                        this.cswkCapaEvltnAmt = 0;
                    } else {
                        this.cswkCapaEvltnAmt = parseFloat(value.replace(/,/g, ''));
                    }
                }
            },
            lcettSuplmtAcrsltAmtComputed: {
                get() {
                    return this.lcettSuplmtAcrsltAmt.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                },
                set(value) {
                    if(value == ''){
                        this.lcettSuplmtAcrsltAmt = 0;
                    } else {
                        this.lcettSuplmtAcrsltAmt = parseFloat(value.replace(/,/g, ''));
                    }
                }
            },
        },
        async mounted() {
            document.querySelector('body').style.backgroundColor = '#FFFFFF';
            this.earlyBirdYn = this.$cookies.get('earlyBirdCookie');


            /* 핫이슈 3일간 닫기 여부 확인 */
            //const hotIssueCookie = this.$cookies.get('hotIssueCookie');

            // if(hotIssueCookie != null && this.$moment(hotIssueCookie).diff(this.$moment(),"days") > -3) {
            //     this.isShowHotIssue = false;
            // }


            this.menuActive(this.$route.path);
            loadScript(
                'https://cdn.channel.io/plugin/ch-plugin-web.js',
                'ChannelIO'
            ).then(value => {
                var self = this;
                if (value) {
                    var ch = function() {
                        ch.c(arguments)
                    }
                    ch.q = []
                    ch.c = function(args) {
                        ch.q.push(args)
                    }
                    window.ChannelIO = ch;
                    window.ChannelIO('boot', {
                        pluginKey: window.channelPluginSettings.pluginKey
                    }, function onBoot(error) {
                        if (error) {
                            console.error(error);
                        } else {
                            self.active = true;
                        }
                    });

                    // window.ChannelIO('boot', {
                    //     pluginKey: window.channelPluginSettings.pluginKey
                    // }, function onBoot(error, user) {
                    //     if (error) {
                    //         console.error(error);
                    //     } else {
                    //         console.log('boot success', user);
                    //     }
                    // });
                    // window.ChannelIO('onBoot', function() {
                    //     this.active = true;
                    // });
                    window.ChannelIO('onBadgeChanged', function(unreadCount) {
                        self.unreadCount = unreadCount;
                    })
                }
            })
            this.loginInfo =JSON.parse(localStorage.getItem("custInfo"));

            this.chkAISubStatus();
            // if(this.loginInfo != null){
            //
            //     var params = {
            //         "webVersionName" :'1.0.1',
            //         "loginDstnctCd": "0",
            //         //"fbToken": params["fbToken"].toString() // firebase token
            //     };
            //
            //     this.$post('/api/loginHist', params);
            //
            //
            //     this.getAutoAnlsConfigList();
            //
            // }
            // if(this.loginInfo!= null){
            //     this.$socket.emit('login',{
            //         id: this.loginInfo.id,
            //         alias: this.loginInfo.cmpNm
            //
            //     });
            // }
            function screenWidth() {
                // 해상도 1000 이상일 때, 햄메뉴 닫힘
                if (screen.width > 1000) {
                    if (document.querySelector('.btn__ham') != null && document.querySelector('.btn__ham').classList.contains('on')) {
                        (document.querySelector('.btn__ham').classList.remove('on'));
                        document.querySelector('.btn__ham--top').classList.toggle('btn__ham--top-click');
                        document.querySelector('.btn__ham--middle').classList.toggle('btn__ham--middle-click');
                        document.querySelector('.btn__ham--bottom').classList.toggle('btn__ham--bottom-click');
                        document.body.style.overflowY = 'auto';
                        document.body.style.height = 'auto';
                    }
                }
            }
            window.addEventListener("resize", function(){
                setTimeout(screenWidth, 550);
            });
            window.addEventListener("load", function(){
                setTimeout(screenWidth, 550);
            });
            // pc 메뉴 클릭 이벤트
            // var menu = document.querySelectorAll('.depth1');
            // menu.forEach(function(element){
            //   element.addEventListener('click', function(){
            //     for (let i = 0; i < menu.length; i++) {
            //       menu[i].classList.remove('active');
            //     }
            //     element.className += ' active';
            //   });
            // })

            // 모바일 햄메뉴 이벤트
            const ham = document.querySelector('.btn__ham');
            const hamWrap = document.querySelector('.header__ham--wrap');
            const hamBtnTop = document.querySelector('.btn__ham--top');
            const hamBtnMiddle = document.querySelector('.btn__ham--middle');
            const hamBtnBottom = document.querySelector('.btn__ham--bottom');


            // Add event listeners
            ham.addEventListener('click', toggleHam);
            ham.addEventListener('click', _adHide);

            // Event handler for hamburger button
            function toggleHam() {
                hamWrap.classList.toggle('on');
                document.body.classList.toggle('expand');
                hamBtnTop.classList.toggle('btn__ham--top-click');
                hamBtnMiddle.classList.toggle('btn__ham--middle-click');
                hamBtnBottom.classList.toggle('btn__ham--bottom-click');
            }

            // //모바일 얼리버드 이벤트
            // this.$nextTick(() => {
            //     let target = document.querySelector('.early-bird');
            //
            //     if (window.getComputedStyle(target).getPropertyValue('display') === 'block') {
            //         document.querySelector('body').classList.add('expand');
            //     }
            // });
            //
            // //모바일 새해 이벤트
            // this.$nextTick(() => {
            //     let target = document.querySelector('.new-year');
            //
            //     if (window.getComputedStyle(target).getPropertyValue('display') === 'block') {
            //         document.querySelector('body').classList.add('expand');
            //     }
            // });

            //로그인 안했을때만 작동하게
            if(this.loginInfo == null){

                ham.addEventListener('click', _adHide);
                this.getTooltipList();


                //var tokReportCookie = this.$cookies.get('tokReportCookie');

                // if(tokReportCookie == null || this.$moment(tokReportCookie).diff(this.$moment(),"days") < -3) {
                //     this.isTokReport = true;
                // }

                // 2025.01.01 ~ 01.05 에 신년 팝업 띄움
                if (this.$moment().isBetween(this.$moment('2025-01-01'), this.$moment('2025-01-05'), 'day', '[]')) {
                    var newYearCookie = this.$cookies.get('newYearCookie');
                    if(newYearCookie == null || this.$moment(newYearCookie).diff(this.$moment(),"days") <= -3) {
                        this.isNewYear = true;
                    }
                }
            } else {
                var params = {
                    "webVersionName" :'1.0.2',
                    "loginDstnctCd": "0",
                    //"fbToken": params["fbToken"].toString() // firebase token
                };

                this.$post('/api/loginHist', params);

                //신용평가 기준 목록
                this.getCdltSdList();
                //발급기관 목록
                this.getCdltStOrgnz();

                //사용자 정보 최신화
                await this.getCustInfo();
                this.getAutoAnlsConfigList();
                // this.setAutoAnlsEndPopup();
                //
                // this.getAutoAnlsEndInfo();

                this.getHomePopupList();
            }

            function _adHide () {
                let item = document.querySelector('.ad');

                if(this.loginInfo == null && window.innerWidth >800 ){
                    item.classList.toggle('active');
                }
            }

            this.insTracking('/home');

        },
        watch: {
            $route(to, form) {
                if (to.path !== form.path) {
                    // var menu = document.querySelectorAll('.depth1');

                    // menu.forEach(function(element){
                    //     for (let i = 0; i < menu.length; i++) {
                    //       menu[i].classList.remove('active');
                    //     }
                    //     element.className += ' active';
                    // });
                    this.menuActive(to.path);
                    this.insTracking(to.path);
                    //사용자 정보 최신화
                    if(this.loginInfo != null) {
                        this.getCustInfo();
                        this.getAutoAnlsConfigList();
                    }
                    // document.querySelector('#'+to.path.toString().substring(1,to.path.length)).classList.add('active');
                }
            },
            showUseGuide(newValue) {
                if (newValue == true) {
                    this.$scrollNon();
                } else {
                    this.$scrollActive();
                }
            },
            showUseGuideMo(newValue) {
                if (newValue == true) {
                    this.$scrollNon();
                } else {
                    this.$scrollActive();
                }
            },
            isPopAcrslt(newValue) {
                if (newValue == true) {
                    this.$scrollNon();
                } else {
                    this.$scrollActive();
                }
            },
            isPopManageStat(newValue) {
                if (newValue == true) {
                    this.$scrollNon();
                } else {
                    this.$scrollActive();
                }
            },
            isPopCswkCapaEvltn(newValue) {
                if (newValue == true) {
                    this.$scrollNon();
                } else {
                    this.$scrollActive();
                }
            },
            isPopManage(newValue) {
                if (newValue == true) {
                    this.$scrollNon();
                } else {
                    this.$scrollActive();
                }
            },
            isTokReport(newValue) {
                if (newValue == true) {
                    this.$scrollNon();
                } else {
                    this.$scrollActive();
                }
            },
            isPopAutoAnlsPrevDdayPop(newValue) {
                if (newValue == true) {
                    this.$scrollNon();
                } else {
                    this.$scrollActive();
                }
            },
            isPopAutoAnlsDdayPop(newValue) {
                if (newValue == true) {
                    this.$scrollNon();
                } else {
                    this.$scrollActive();
                }
            },
            isPopAutoAnlsPop(newValue) {
                if (newValue == true) {
                    this.$scrollNon();
                } else {
                    this.$scrollActive();
                }
            },
            isPopSetAutoAnls(newValue) {
                if (newValue == true) {
                    this.$scrollNon();
                } else {
                    this.$scrollActive();
                }
            },
            isPopCustCntrct(newValue){
                if(newValue == true){
                    this.$scrollNon();
                }else{
                    this.$scrollActive();
                }
            },
        },
        methods: {
            ready(){},
            playing(){},
            ended(){},
            error(){},

            //AI멤버십 상태체크
            chkAISubStatus() {
                if (this.$parent.loginInfo != null) {
                    if (this.$parent.loginInfo.subStatusCd == '1') {

                        if (this.$parent.loginInfo.subDday == 0) {
                            this.AIStatusText = "D-day";
                        } else if (this.$parent.loginInfo.subDday > 0) {
                            this.AIStatusText = "D-"+this.$parent.loginInfo.subDday;
                        } else {
                            this.AIStatusText = "";
                        }

                        // if (self.$moment().diff(self.$moment(self.loginInfo.subEndDt).format('YYYY.MM.DD') , 'days') == 0) {
                        //     console.log(1);
                        //     self.subDday = "D-day";
                        //     self.AIStatusText = self.loginInfo.subStatusCd == 1 ? self.subDday : '';
                        // } else if (self.$moment().diff(self.$moment(self.loginInfo.subEndDt).format('YYYY.MM.DD') , 'days') > 0) {
                        //     console.log(2);
                        //     self.subDday = "종료";
                        // } else {
                        //     console.log(3);
                        //     self.subDday = "D"+self.$moment().diff(self.$moment(self.loginInfo.subEndDt).format('YYYY.MM.DD') , 'days');
                        //     self.AIStatusText = self.loginInfo.subStatusCd == 1 ? self.subDday : '';
                        // }
                    }
                }
            },
            //분석동의창 닫기
            closeCustCntrctPop(){
                this.isPopCustCntrct = false;
                this.isPopCustCntrctStep1 = false;
                this.isPopCustCntrctStep2 = false;
                this.isPopCustCntrctStep3 = false;
                this.anlsAgreeYn = false;
                this.cntrctAgreeYn = false;
            },

            /** 내 정보*/
            async getCustInfo(){
                await this.$get('/api/cust/custInfo/select')
                    .then(res => {
                        localStorage.setItem("custInfo",JSON.stringify(res.data));
                        this.loginInfo = res.data;
                        this.custInfo = res.data;
                        this.custBiz = this.custInfo.custBizList.find(item => item.id === this.custInfo.custBizList[0].id,);

                        this.getAutoAnlsEndInfo();
                        this.setAutoAnlsEndPopup();

                    })
                    .catch(error => {
                        console.log(error);
                        this.loginInfo = null;
                    }
                    );

            },
            //내 정보 갱신
            getCustInfo3(callback){

                this.$get('/api/cust/custInfo/select')
                        .then(res => {
                            localStorage.setItem("custInfo",JSON.stringify(res.data));
                            this.loginInfo = JSON.parse(localStorage.getItem("custInfo"));

                            callback();
                        })
                        .catch(error => {console.log(error);}
                        );
            },
            /** 팝업 정보 가져오기*/
            getHomePopupList() {
                this.$get('/api/home/homePopupList')
                    .then(res => {
                        if(res.code == 'OK'){
                            var acrsltList = res.acrsltList;
                            var manageStatList = res.manageStatList;
                            var cswkCapaEvltnList = res.cswkCapaEvltnList;
                            var manageList = res.manageList;
                            var joinYear = res.joinYear;
                            var basic1User = res.basic1User;
                            var cntrctDday = res.cntrctDday;
                            var eventUser = res.eventUser;

                            if(acrsltList != null){
                                var tracking1 = {
                                    "trackingCd": "4",
                                    "trackingAction" : "VIEW",
                                    "trackingNm": "경상정보 실적변경 팝업",
                                    "trackingDesc" : "공사 실적 정보 변경 필요 팝업",
                                };

                                this.$insertTracking(tracking1);
                                this.isPopAcrslt = true;
                            } else if(manageStatList != null){
                                var tracking2 = {
                                    "trackingCd": "4",
                                    "trackingAction" : "VIEW",
                                    "trackingNm": "경상정보 실적변경 팝업",
                                    "trackingDesc" : "경영 상태 정보 변경 필요 팝업",
                                };

                                this.$insertTracking(tracking2);
                                this.isPopManageStat = true;

                            } else if(cswkCapaEvltnList != null){
                                var tracking3 = {
                                    "trackingCd": "4",
                                    "trackingAction" : "VIEW",
                                    "trackingNm": "경상정보 실적변경 팝업",
                                    "trackingDesc" : "시공능력평가액 정보 변경 필요 팝업",
                                };

                                this.$insertTracking(tracking3);
                                this.isPopCswkCapaEvltn = true;

                            } else if(manageList != null){
                                var tracking4 = {
                                    "trackingCd": "4",
                                    "trackingAction" : "VIEW",
                                    "trackingNm": "경상정보 실적변경 팝업",
                                    "trackingDesc" : "내 경영 정보 변경 필요 팝업",
                                };

                                this.$insertTracking(tracking4);
                                this.isPopManage = true;
                            } else if(joinYear == 'Y'){
                                this.$refs.ExtendCntrctPop.isPopJoinYear = true;
                                var tracking5 = {
                                    "trackingCd": "8",
                                    "trackingAction": "VIEW",
                                    "trackingNm": "분석동의 유도 팝업",
                                    "trackingDesc": "분석동의 유도 팝업",
                                };

                                this.$insertTracking(tracking5);

                            } else if(cntrctDday == 'Y'){
                                this.$refs.ExtendCntrctPop.isPopCntrctDday = true;

                                var tracking6 = {
                                    "trackingCd": "8",
                                    "trackingAction": "VIEW",
                                    "trackingNm": "분석동의서 연장 팝업",
                                    "trackingDesc": "분석동의서 연장 팝업",
                                };

                                this.$insertTracking(tracking6);

                                if(basic1User == 'Y'){
                                    this.$refs.ExtendCntrctPop.isPopBasic1User = true;
                                }

                                if(eventUser == 'Y'){
                                    this.$refs.ExtendCntrctPop.isPopEventUser = true;
                                }
                            } else {
                                // var tokReportCookie = this.$cookies.get('tokReportCookie');

                                // if(tokReportCookie == null || this.$moment(tokReportCookie).diff(this.$moment(),"days") < -3) {
                                //     this.isTokReport = true;
                                // }

                                // 2025.01.01 ~ 01.05 에 신년 팝업 띄움
                                if (this.$moment().isBetween(this.$moment('2025-01-01'), this.$moment('2025-01-05'), 'day', '[]')) {
                                    var newYearCookie = this.$cookies.get('newYearCookie');
                                    if(newYearCookie == null || this.$moment(newYearCookie).diff(this.$moment(),"days") <= -3) {
                                        this.isNewYear = true;
                                    }
                                }
                            }

                        }
                    })
                    .catch(error => {
                            console.log(error);
                        }
                    );
            },

            /** 자동분석 종료 팝업 정보*/
            getAutoAnlsEndInfo () {
                var param = {
                    "custBizId": this.loginInfo.custBizList[0].id,
                };
                this.$post('/api/autoAnlsConfig/autoAnlsEndInfo', param)
                    .then(res => {
                        if(res.code == 'OK'){
                            this.autoAnlsConfig = res.autoAnlsConfig;

                            if(this.autoAnlsConfig != null){

                                this.isPopAutoAnlsEnd = true;
                                this.autoAnlsEndDt = this.$moment(this.autoAnlsConfig.anlsEndDt).format('YYYY.MM.DD (ddd)')
                            }
                        }
                    })
                    .catch(error => {
                            console.log(error);
                        }
                    );
            },



            async timeJump(value) {

                var player = this.$refs.youtube.player;
                player.seekTo(value);
                await player.playVideo();
            },

            ...mapActions([ 'TOGGLE_DRAWER' ]),
            menuActive(location){
                var menu = document.querySelectorAll('.depth1');

                for (let i = 0; i < menu.length; i++) {
                    menu[i].classList.remove('active');
                }

                //faq 처리
                if(location == '/faq'){
                    location = '/notice';
                }
                //서비스가이드 처리
                if(location.includes("howtoDetail")){
                    location = '/tokStory';
                }
                //톡리포트 처리
                if(location.includes("tokReport")){
                    location= '/tokReport';
                }

                let obj = document.querySelector('#' + location.substring(1).replace('/', '_'));
                if (obj != null) {
                    obj.classList.add('active');
                }

                let m_obj = document.querySelector('#m_' + location.substring(1).replace('/', '_'));
                if (m_obj != null) {
                    m_obj.classList.add('active');
                }
            },
            // goMenu(menuUrl) {
            //     if (this.$route.path != "/"+menuUrl) // 현재 route와 다른 경우만 페이지 이동
            //       console.log('/'+menuUrl)
            //       this.$router.push('/'+menuUrl);
            //     if (menuUrl == 'home') { // home 클릭 시 active 삭제
            //       // console.log('삭제');
            //       // var menu = document.querySelectorAll('.depth1');
            //       // for (let i = 0; i < menu.length; i++) {
            //       //   menu[i].classList.remove('active');
            //       // }
            //     }
            //   //   if (document.querySelector('#loginLayer').className == 'login-bar--wrap on') {
            //   //     document.querySelector('.login-bar--wrap').classList.toggle('on');
            //   //   }
            //   //
            //   //   if (this.$route.path != "/"+menuUrl) // 현재 route와 다른 경우만 페이지 이동
            //   //     this.$router.push('/'+menuUrl);
            //   // } else {
            //   //   this.snackbar = true;
            // },

            //설날 이벤트 체크
            dateCheck() {
                const currentDate = new Date();
                const startDate = new Date('2024-02-08 00:00:00');
                const endDate = new Date('2024-02-13 00:00:00');

                return currentDate >= startDate && currentDate < endDate;
            },

            //사용자가이드
            openTab(evt, tabName) {
                var i, tabcnts, tabbtns;
                tabcnts = document.getElementsByClassName("tab__box--cnts");
                for (i = 0; i < tabcnts.length; i++) {
                    tabcnts[i].style.display = "none";
                }
                tabbtns = document.getElementsByClassName("tab--btn");
                for (i = 0; i < tabbtns.length; i++) {
                    tabbtns[i].className = tabbtns[i].className.replace(" btn-cta", "");
                }
                document.getElementById(tabName).style.display = "block";
                evt.currentTarget.className += " btn-cta";
            },

            showGuidePopup() {
                this.showUseGuide = true;
                // document.querySelector('body').classList.add('expand');
            },

            hideGuidePopup() {
                this.showUseGuide = false;
                // document.querySelector('body').classList.remove('expand');
            },

            showGuidePopupMo() {
                this.showUseGuideMo = true;
            },

            hideGuidePopupMo() {
                this.showUseGuideMo = false;
            },

            /* 핫이슈창 닫기*/
            // closeHotIssue() {
            //     this.isShowHotIssue = false;
            // },
            /* 핫이슈 쿠키 저장*/
            setHotIssueCookie(){
                const today = this.$moment().format('YYYY-MM-DD');

                this.$cookies.set("hotIssueCookie",today);
                this.closeHotIssue();
            },

            async insTracking(path) {

                var tracking = {
                    "trackingCd": "3",
                    "trackingAction" : "ACCESS",
                    "trackingNm": "페이지 이동",
                    "trackingDesc" : path,
                };

                this.$insertTracking(tracking);


            },
            browser() {
                function ieClose() {
                    top.window.open('about:blank', '_self').close();
                    top.window.opener = self;
                    top.self.close();
                }

                if (navigator.userAgent.indexOf('Trident') > 0) {
                    $("body").addClass("shield");

                    alert(
                        "비드톡톡은 Microsoft Edge, Chrome 브라우저를 권장합니다.\n확인버튼을 누르면 Edge브라우저로 자동으로 이동됩니다."
                    );

                    setTimeout(ieClose, 1000);

                    window.location = 'microsoft-edge:' + 'https://www.bidtok.co.kr/';
                } else if (/MSIE \d |Trident.*rv:/.test(navigator.userAgent)) {
                    $("body").addClass("shield");

                    setTimeout(ieClose, 1000);

                    alert(
                        "비드톡톡은 Microsoft Edge, Chrome 브라우저를 권장합니다.\n확인버튼을 누르면 Edge브라우저로 자동으로 이동됩니다."
                    );

                    window.location = 'microsoft-edge:http:' + 'http://www.newjini.co.kr/';
                }
            },

            goAnalysis(key) {
                this.notificationsBadge = false;
                if (key) {
                    if(window.location.pathname != '/analysis'){
                        this.$goMenu('analysis');
                    } else {
                        window.location.reload(true);
                    }
                }
            },
            doLogout: function () {
                window.localStorage.setItem('authenticated', false);
                // this.$router.push('/home');
                localStorage.removeItem("authToken");
                localStorage.removeItem("refreshToken");
                localStorage.removeItem("custInfo");
                this.existToken = false;
                this.bizNo = "";
                this.pwNo = "";
                this.loginInfo = null;
                // this.$router.push('/home');
                // console.log('logout');
                // window.location.reload(true);
                window.location = '/home';
                // document.querySelector('.login-bar--wrap').classList.toggle('on');
            },
            notifiactionClick(){
                this.notificationsBadge = false;
            },
            loginKakao: function() {
                // window.Kakao.Auth.login({
                //     success: function(authObj) {
                //         alert(JSON.stringify(authObj))
                //     },
                //     fail: function(err) {
                //         alert(JSON.stringify(err))
                //     },
                // })

                window.Kakao.Auth.login({
                    success: function(authObj) {
                        console.log(JSON.stringify(authObj));
                        //document.getElementById("Response").innerText = JSON.stringify(authObj);
                        window.Kakao.Auth.setAccessToken(authObj.access_token);
                    },
                    fail: function(err) {
                        console.log(JSON.stringify(err));
                        //document.getElementById("Response").innerText = JSON.stringify(err);
                    },
                })
            },
            sendMsg: function() {
                window.Kakao.Auth.login({
                    scope: 'TALK_MESSAGE',
                    success: function() {
                        window.Kakao.API.request({
                            url: '/v2/api/talk/memo/default/send',
                            data: {
                                template_object: {
                                    object_type: 'list',
                                    header_title: '맞춤공고가 도착했어요~!',
                                    header_link: {
                                        mobile_web_url: 'https://developers.kakao.com',
                                        web_url: 'https://developers.kakao.com',
                                    },
                                    contents: [
                                        {
                                            title: '22년 즉각취식형 전투식량 구매 납품',
                                            description: '전기',
                                            image_url:
                                                'http://k.kakaocdn.net/dn/bDPMIb/btqgeoTRQvd/49BuF1gNo6UXkdbKecx600/kakaolink40_original.png',
                                            link: {
                                                mobile_web_url: 'https://developers.kakao.com',
                                                web_url: 'https://developers.kakao.com',
                                            },
                                        },
                                        {
                                            title: '심포뷰티스마켓 진입로 가로경관 조성사업',
                                            description: '설계|측량',
                                            image_url:
                                                'http://k.kakaocdn.net/dn/QPeNt/btqgeSfSsCR/0QJIRuWTtkg4cYc57n8H80/kakaolink40_original.png',
                                            link: {
                                                mobile_web_url: 'https://developers.kakao.com',
                                                web_url: 'https://developers.kakao.com',
                                            },
                                        },
                                        {
                                            title: '갑룡길 그린뉴딜 지중화공사 기초자료 조사용역',
                                            description: '엔지니어링',
                                            image_url:
                                                'http://k.kakaocdn.net/dn/c7MBX4/btqgeRgWhBy/ZMLnndJFAqyUAnqu4sQHS0/kakaolink40_original.png',
                                            link: {
                                                mobile_web_url: 'https://developers.kakao.com',
                                                web_url: 'https://developers.kakao.com',
                                            },
                                        },
                                    ],
                                    buttons: [
                                        {
                                            title: '웹으로 보기',
                                            link: {
                                                mobile_web_url: 'https://developers.kakao.com',
                                                web_url: 'https://developers.kakao.com',
                                            },
                                        },
                                        {
                                            title: '앱으로 보기',
                                            link: {
                                                mobile_web_url: 'https://developers.kakao.com',
                                                web_url: 'https://developers.kakao.com',
                                            },
                                        },
                                    ],
                                },
                            },
                            success: function(res) {
                                alert('success: ' + JSON.stringify(res))
                            },
                            fail: function(err) {
                                alert('error: ' + JSON.stringify(err))
                            },
                        })
                    },
                    fail: function(err) {
                        alert('failed to login: ' + JSON.stringify(err))
                    },
                })
            },
            _closeFnc() {
                this.closePop = 'Y';
                document.querySelector('body').classList.remove('expand');
                // let closeBtns = Array.from(document.querySelectorAll('.close-btn'));
                //
                // let _addEventHandlers = function () {
                //     closeBtns.forEach(function(closeBtn) {
                //         closeBtn.addEventListener("click", _closeAction);
                //     });
                // }
                //
                // let _closeAction = function () {
                //     let temp = this.closest('.popup__wrap');
                //     temp.style.display = 'none';
                // }
                // _addEventHandlers();
            },

            earlyBirdCookie(){
                document.querySelector('body').classList.remove('expand');
                this.$cookies.set("earlyBirdCookie",'Y');
                this.isTokReport = false;
            },
            newYearCookie(){
                const today = this.$moment().format('YYYY-MM-DD');

                this.$cookies.set("newYearCookie",today);
                this.isNewYear = false;
            },
            tokReportCookie(){
                const today = this.$moment().format('YYYY-MM-DD');

                this.$cookies.set("tokReportCookie",today);

                this.isTokReport = false;
            },
            sendMsgOther: function() {
                // window.Kakao.Auth.login({
                //     scope: 'friends,talk_message',
                //     success: function () {
                //         window.Kakao.API.request({
                //             url: '/v1/api/talk/friends',
                //             success: function (res) {
                //                 console.log(res);
                //                 if (res.elements.length > 0) {
                //                     for (let i = 0, item; (item = res.elements[i]); i++) {
                //                         console.log(item);
                //                         // const friendElement = document.createElement('li')
                //                         // const profileImg = item.profile_thumbnail_image
                //                         //     ? item.profile_thumbnail_image
                //                         //     : 'http://k.kakaocdn.net/dn/1xGCm/btqwLTjcNzV/orvxGiWth8JR20SgUcDNKK/kakaolink40_original.jpg'
                //                         //
                //                         // friendElement.innerHTML = '<div class="friend">'
                //                         //     + '<img src="' + profileImg + '" alt="프로필 이미지">'
                //                         //     + '<strong>' + item.profile_nickname + '</strong>'
                //                         //     + '</div>'
                //                         //     + '<button onclick="sendMessage(\'' + item.uuid + '\')">전송</button>'
                //                         // document.getElementById('friend-list').appendChild(friendElement)
                //                     }
                //                 } else {
                //                     console.log("앱에 연결된 친구가 없습니다. 앱에 연결된 친구만 조회할 수 있습니다.");
                //                 }
                //             },
                //             fail: function (err) {
                //                 console.log(JSON.stringify(err));
                //             },
                //         })
                //     },
                // })
                window.Kakao.Link.sendDefault({
                    objectType: 'list',
                    headerTitle: '맞춤공고가 도착했어요~!',
                    headerLink: {
                        mobileWebUrl: 'https://developers.kakao.com',
                        webUrl: 'https://developers.kakao.com',
                    },
                    contents: [
                        {
                            title: '22년 즉각취식형 전투식량 구매 납품',
                            description: '전기',
                            imageUrl:
                                'http://k.kakaocdn.net/dn/bDPMIb/btqgeoTRQvd/49BuF1gNo6UXkdbKecx600/kakaolink40_original.png',
                            link: {
                                mobileWebUrl: 'https://developers.kakao.com',
                                webUrl: 'https://developers.kakao.com',
                            },
                        },
                        {
                            title: '심포뷰티스마켓 진입로 가로경관 조성사업',
                            description: '설계|측량',
                            imageUrl:
                                'http://k.kakaocdn.net/dn/QPeNt/btqgeSfSsCR/0QJIRuWTtkg4cYc57n8H80/kakaolink40_original.png',
                            link: {
                                mobileWebUrl: 'https://developers.kakao.com',
                                webUrl: 'https://developers.kakao.com',
                            },
                        },
                        {
                            title: '갑룡길 그린뉴딜 지중화공사 기초자료 조사용역',
                            description: '엔지니어링',
                            imageUrl:
                                'http://k.kakaocdn.net/dn/c7MBX4/btqgeRgWhBy/ZMLnndJFAqyUAnqu4sQHS0/kakaolink40_original.png',
                            link: {
                                mobileWebUrl: 'https://developers.kakao.com',
                                webUrl: 'https://developers.kakao.com',
                            },
                        },
                    ],
                    // content: {
                    //     title: '딸기 치즈 케익',
                    //     description: '#케익 #딸기 #삼평동 #카페 #분위기 #소개팅',
                    //     imageUrl:
                    //         'http://k.kakaocdn.net/dn/Q2iNx/btqgeRgV54P/VLdBs9cvyn8BJXB3o7N8UK/kakaolink40_original.png',
                    //     link: {
                    //         mobileWebUrl: 'https://developers.kakao.com',
                    //         webUrl: 'https://developers.kakao.com',
                    //     },
                    // },
                    // social: {
                    //     likeCount: 286,
                    //     commentCount: 45,
                    //     sharedCount: 845,
                    // },
                    buttons: [
                        {
                            title: '웹으로 보기',
                            link: {
                                mobileWebUrl: 'https://developers.kakao.com',
                                webUrl: 'https://developers.kakao.com',
                            },
                        },
                        {
                            title: '앱으로 보기',
                            link: {
                                mobileWebUrl: 'https://developers.kakao.com',
                                webUrl: 'https://developers.kakao.com',
                            },
                        },
                    ],
                })
            },
            setAutoAnlsEndPopup(){
                if(this.loginInfo.anlsEndDt != null){
                    var anlsEndDt = this.$moment(this.loginInfo.anlsEndDt);
                    var today = this.$moment(this.$moment().format('YYYY-MM-DD'));
                    // console.log(today);
                    var diffDays = anlsEndDt.diff(today, 'days');
                    this.dday = diffDays;

                    var lastDt;
                    ///종료일 하루 이전
                    if(diffDays == 1){
                        lastDt = localStorage.getItem("AutoAnlsPrevDdayPopDt");
                        if(today.diff(lastDt, 'days') == 0){
                            console.log(lastDt);
                            return;
                        }
                        localStorage.setItem("AutoAnlsPrevDdayPopDt",today);
                        this.$refs.AutoAnlsPrevDdayPop.endDt = anlsEndDt.format(('YYYY.MM.DD (ddd)' ));
                        this.isPopAutoAnlsPrevDdayPop = true;

                    }
                    ///종료일 하루 이후
                    else if(diffDays == -1){
                        lastDt = localStorage.getItem("AutoAnlsDdayPopDt");
                        if(today.diff(lastDt, 'days') == 0){
                            console.log(lastDt);
                            return;
                        }

                        localStorage.setItem("AutoAnlsDdayPopDt",today);
                        this.$refs.AutoAnlsDdayPop.endDt = anlsEndDt.format(('YYYY.MM.DD (ddd)' ));
                        this.isPopAutoAnlsDdayPop = true;
                    }
                }
            },
            getAutoAnlsConfigList(){
                var param = {
                    "custBizId" : this.loginInfo.custBizList[0].id,
                }
                this.$post('/api/autoAnlsConfig/list',param)
                    .then(res => {
                        this.autoAnlsConfigList = res.autoAnlsConfigList;

                        if(this.autoAnlsConfigList.length>0) {
                            this.isAutoAnls = true;
                        } else {
                            this.isAutoAnls = false;
                        }
                    })
                    .catch(error => {
                        console.log(error);
                    }
                    );

            },
            //자동분석 토글 선택
            async clickAutoAnls() {
                if (await this.checkNonFulfilmentMngt()) {
                    this.isPopNonFulfliment = true;
                } else if (this.isAutoAnls) {
                    this.clickModifyAutoAnls()
                } else {
                    await this.getCustLicnsList();
                    //경영면허 정보 입력 팝업
                    for (var i in this.custLicnsList) {
                        if (this.custLicnsList[i].updateYn == 'N') {
                            this.isPopForceSetMyInfo = true;
                            this.isPopForceSetMyInfoStep1 = true;

                            this.modifyCustLicnsList = _.cloneDeep(this.custLicnsList);
                            this.modifySelectLicns = this.modifyCustLicnsList[0];
                            this.debtRate = this.custBiz.debtRate;
                            this.crasRate = this.custBiz.crasRate;
                            this.y3AcrsltAmt = this.modifySelectLicns.y3AcrsltAmt;
                            this.y5AcrsltAmt = this.modifySelectLicns.y5AcrsltAmt;
                            this.cswkCapaEvltnAmt = this.modifySelectLicns.cswkCapaEvltnAmt;
                            this.lcettSuplmtAcrsltAmt = this.modifySelectLicns.lcettSuplmtAcrsltAmt;
                            return;
                        }
                    }
                    await this.getCustCntrct();

                    if (this.custCntrctList.length == 0) {
                        // 계약서가 없으면 체크 약관 동의 팝업
                        this.isPopCustCntrct = true;
                        this.isPopCustCntrctStep1 = true;
                        this.activeScrollEvent();
                    } else {
                        this.isPopSetAutoAnls = [];
                        //자동분석 설정 팝업
                        this.isPopSetAutoAnls = true;
                        this.selAnlstList = [];

                        //선택값 초기화
                        // this.$refs.AutoAnlsPop.selAnlstList = [];
                        //분석가 전체 선택
                        // for (var j in this.notiAnlstList) {
                        //     this.selAnlstList.push(this.notiAnlstList[j].id);
                        // }
                    }
                }
            },
            //분석요청 제한 기간 체크
            async checkNonFulfilmentMngt(){

                await this.$get('/api/cust/fulfilmentMngt/info')
                    .then(res => {
                        this.custFulfilmentMngt = res.data;
                    })
                    .catch(error => console.log(error));



                if(this.custFulfilmentMngt == null){
                    return false;
                }

                const today = this.$moment();

                var lockStartDt= this.$moment(this.custFulfilmentMngt.lockStartDt);
                var lockEndDt= this.$moment(this.custFulfilmentMngt.lockEndDt).add(1,'days');

                //오늘날짜가 분석요청제한기간일 경우
                if( today.isAfter(lockStartDt) && today.isBefore(lockEndDt) && this.custFulfilmentMngt.lockStatus == 'Y') {
                    return true;
                } else {
                    return false;
                }
            },
            //자동분석 변경 클릭
            clickModifyAutoAnls() {
                this.isPopSetAutoAnls = true;
                this.selAnlstList = [];
                // this.$refs.AutoAnlsPop.selAnlstList = [];
                for (var i in this.autoAnlsConfigList) {
                    this.selAnlstList.push(this.autoAnlsConfigList[i].notiAnlstId);
                    // this.$refs.AutoAnlsPop.selAnlstList.push(this.autoAnlsConfigList[i].notiAnlstId);
                }
            },
            //계약서 정보 로드
            async getCustCntrct() {
                var param = {
                    "custId": this.loginInfo.id,
                };
                await this.$post('/api/cust/custCntrct',param)
                    .then(res => {
                        this.custCntrctList = res.custCntrctList;
                    })
                    .catch(error => console.log(error));

            },
            closeAnlsAgreePop(value){

                if(value){
                    const anlsAgreeBtn = document.getElementById('headerAnlsAgreeBtn');

                    if (anlsAgreeBtn.classList.contains('btn__active')) {
                        this.anlsAgreeYn = true;
                        this.isPopUseAgree = false;
                    } else {
                        this.$toast.error("약관을 끝까지 보신 후, 동의 가능합니다.");
                    }
                } else {
                    this.isPopUseAgree = false;
                }
                // this.$refs.agree.focus();
            },
            closeCntrctAgreePop(value){
                if(value){
                    const cntrctAgreeBtn = document.getElementById('headerCntrctAgreeBtn');

                    if (cntrctAgreeBtn.classList.contains('btn__active')) {
                        this.cntrctAgreeYn = true;
                        this.anlsAgreeFlag = true;
                        this.isPopCntrct = false;
                    } else {
                        this.$toast.error("약관을 끝까지 보신 후, 동의 가능합니다.");
                    }
                    // if (this.anlsAgreeFlag) {
                    //     this.cntrctAgreeYn = true;
                    //     this.isPopCntrct = false;
                    // } else {
                    //     this.$toast.error("약관을 끝까지 보신 후, 동의 가능합니다.");
                    // }
                } else {
                    this.isPopCntrct = false;
                }
                // this.$refs.agree.focus();

            },
            activeScrollEvent(){
                if(!this.isScrollActive){

                    this.isScrollActive = true;
                    const headerUseAgreeScroll = document.getElementById('headerUseAgreeScroll');

                    headerUseAgreeScroll.addEventListener('scroll', function(){
                        let popScrollHeight = headerUseAgreeScroll.scrollHeight; // 스크롤 전체 높이
                        let popScrollTop = headerUseAgreeScroll.scrollTop; // 스크롤 현재 높이
                        if (headerUseAgreeScroll.getBoundingClientRect().height + popScrollTop > popScrollHeight - 3) {
                            const anlsAgreeBtn = document.getElementById('headerAnlsAgreeBtn');
                            anlsAgreeBtn.classList.remove('btn__non-active');
                            anlsAgreeBtn.classList.add('btn__active');
                        }
                    });

                    // /* 개인정보 스크롤 이벤트*/
                    const headerEtcAgreeScroll = document.getElementById('headerEtcAgreeScroll');

                    headerEtcAgreeScroll.addEventListener('scroll', function(){
                        let popScrollHeight = headerEtcAgreeScroll.scrollHeight; // 스크롤 전체 높이
                        let popScrollTop = headerEtcAgreeScroll.scrollTop; // 스크롤 현재 높이
                        if (headerEtcAgreeScroll.getBoundingClientRect().height + popScrollTop > popScrollHeight - 3) {
                            const cntrctAgreeBtn = document.getElementById('headerCntrctAgreeBtn');
                            // cntrctAgreeBtn.disabled = false;
                            cntrctAgreeBtn.classList.remove('btn__non-active');
                            cntrctAgreeBtn.classList.add('btn__active');
                            //요기!!!!!!
                        }
                    });
                }
            },
            //분석이용 약관 동의
            insertAnlsAgree(){
                if(!this.anlsAgreeYn || !this.cntrctAgreeYn) {
                    this.$toast.error("약관에 동의해 주세요.");
                    return;
                }
                if(!this.email) {
                    this.$toast.error("이메일을 입력해 주세요.");
                    return;
                }
                // if(this.email != '' && this.checkEmail) {
                if(this.email != '') {
                    this.insertEmailInfo();
                }

                this.getCntrctTmple();

            },
            //템플릿 가져오기
            getCntrctTmple() {
                var param = {};

                this.$post('/api/cust/custCntrctTmpl',param)
                    .then(res => {
                        if(res.status == 'OK'){
                            var custCntrctTmpl= res.custCntrctTmplList[0];
                            var custtmplHtml = custCntrctTmpl.cntrctTmplHtml;
                            var today = this.$moment();

                            custtmplHtml = custtmplHtml.replaceAll('#{CMP_NM}', this.loginInfo.cmpNm);
                            custtmplHtml = custtmplHtml.replaceAll('#{USER_NM}', this.loginInfo.userNm);
                            custtmplHtml = custtmplHtml.replaceAll('#{currentDate}',  today.format('YYYY년 MM월 DD일'));
                            custtmplHtml = custtmplHtml.replaceAll('#{nextYearDate}', today.add(1, 'year').subtract(1,'days').format('YYYY년 MM월 DD일'));
                            custtmplHtml = custtmplHtml.replaceAll('#{todayTime}', today.format('HH:mm'));

                            this.insertCntrct(custCntrctTmpl,custtmplHtml);

                        } else {
                            this.$toast.error('계약서 등록 실패');
                        }
                    })
                    .catch(error => console.log(error));
            },
            //템플릿 저장
            insertCntrct(custCntrctTmpl,custtmplHtml){
                var param = {
                    "custId": this.loginInfo.id,
                    "anlsAgreeYn": this.anlsAgreeYn ? 'Y' : 'N',
                    "cntrctAgreeYn": this.cntrctAgreeYn ? 'Y': 'N',
                    "cntrctTmplId": custCntrctTmpl.id,
                    "cntrctHtml": custtmplHtml
                };

                this.$post('/api/cust/insertCntrct',param)
                    .then(res => {
                        if(res.status == 'OK'){
                            this.isPopCustCntrctStep1= false;
                            this.isPopCustCntrctStep2= false;
                            this.isPopCustCntrctStep3 = true;
                            this.agreeDt = this.$moment().format('YYYY.MM.DD');
                            this.agreeDate = this.$moment().format('YYYY.MM.DD') + ' ~ ' + this.$moment().add(1,"years").subtract(1,'days').format('YYYY.MM.DD');
                            this.getCustCntrct();
                            // this.getCustInfo3(function(){});
                        } else {
                            this.$toast.error('계약서 등록 실패');
                        }
                    })
                    .catch(error => console.log(error));
            },
            //불이행 해제 팝업 확인
            updateLockConfirm(){
                var param = {
                    "id":  this.custFulfilmentMngt.id
                };

                this.$post('/api/cust/fulfilmentMngt/lockConfirm',param)
                    .then(res => {
                        if(res.code == "OK"){
                            this.isPopNonFulfliment = false;
                        } else {
                            this.isPopNonFulfliment = false;
                        }
                    })
                    .catch(error => console.log(error));
            },
            checkLastLicns() {

                if(this.modifySelectLicns.id == this.modifyCustLicnsList[this.modifyCustLicnsList.length-1].id) {
                    this.insertFoceCustBiz();
                } else {
                    var index = this.modifyCustLicnsList.findIndex((element) => element.id == this.modifySelectLicns.id);

                    this.licnsChange(this.modifyCustLicnsList[index+1]);
                }
            },

            async insertFoceCustBiz() {
                if(this.validateCustBiz()){
                    await this.insertCustBiz();

                    this.isPopForceSetMyInfoStep3 = false;
                    this.isPopForceSetMyInfoStep4 = true;
                }
            },
            //신용평가 목록
            getCdltSdList() {
                this.cdltStList = [];

                this.$http.get('/api/comnCode/cdltSt')
                    .then(res => {
                        this.cdltStList = res.data.cdltStList;
                        this.cdltStList = this.cdltStList.filter((e) => e.cdltStCd == '0');

                    })
                    .catch(error => console.log(error));
            },
            //발급기관 목록
            getCdltStOrgnz() {
                this.cdltStOrgList = [];

                var param = {"comnCdGroupId": "CDLT_ST_ISS_ORGNZ"};

                this.$http.post('/api/comnCode/list',param)
                    .then(res => {
                        this.cdltStOrgList = res.data.comnCodeList;
                    })
                    .catch(error => console.log(error));
            },
            //내 면허 정보
            async getCustLicnsList(){


                await this.$get('/api/custBiz/' + this.loginInfo.custBizList[0].id + '/custLicns')
                    .then(res => {
                        if(res.code == 'OK'){
                            this.custLicnsList = res.data;
                            this.modifyCustLicnsList = _.cloneDeep(res.data);
                            this.selectLicns = this.custLicnsList[0];
                            this.$moment(this.strtDate).format('YYYY-MM-DD');
                            this.modifySelectLicns = this.modifyCustLicnsList[0];

                            this.bsnRegstDe = this.custLicnsList[0].bsnRegstDe;
                            this.count = this.custLicnsList.length -1;
                            this.currentPosition = 40;
                            // let slideCont = document.querySelector('.tok-slide--list');
                            // console.log(slideCont);
                            // slideCont.style.transform = `translateX(${this.currentPosition}px)`;

                            for (var i in this.custLicnsList) {
                                if(this.custLicnsList[i].updateYn == 'N') {
                                    this.isPopForceSetMyInfo = true;
                                    this.isPopForceSetMyInfoStep1 = true;

                                    this.modifyCustLicnsList = _.cloneDeep(this.custLicnsList);
                                    this.modifySelectLicns = this.modifyCustLicnsList[0];
                                    this.debtRate = this.custBiz.debtRate;
                                    this.crasRate = this.custBiz.crasRate;
                                    this.y3AcrsltAmt = this.modifySelectLicns.y3AcrsltAmt;
                                    this.y5AcrsltAmt = this.modifySelectLicns.y5AcrsltAmt;
                                    this.cswkCapaEvltnAmt = this.modifySelectLicns.cswkCapaEvltnAmt;
                                    this.lcettSuplmtAcrsltAmt = this.modifySelectLicns.lcettSuplmtAcrsltAmt;
                                    return;
                                }
                            }
                        }

                    })
                    .catch(error => {console.log(error);}
                    );
            },
            //강제입력팝업 다음 클릭
            clickForceSetMyInfoStep() {
                if(this.validateCustBiz()){
                    this.isPopForceSetMyInfoStep3 = true;
                    this.isPopForceSetMyInfoStep2 = false;
                }
            },
            validateCustBiz(){
                if(this.selGrd != ''){
                    if(this.selOrgnz == '' || this.cdltStDe == '' || this.dateToString(this.cdltStDe) == ''){
                        this.$toast.error('신용평가 항목을 전부 선택해 주세요.');
                        return false;
                    }
                } else if(this.debtRate == null || this.debtRate.toString() == ''){
                    this.$toast.error('부채비율을 입력해 주세요.');
                    return false;
                } else if(this.crasRate == null ||this.crasRate.toString() == ''){
                    this.$toast.error('유동비율을 입력해 주세요.');
                    return false;
                }
                return true;
            },
            previos(){
                let slideCont = event.target.parentElement.parentElement.querySelector('.tok-slide--list');

                if (this.currentPosition < 40) {
                    this.currentPosition += 160;
                    slideCont.style.transform = `translateX(${this.currentPosition}px)`;
                    this.count += 1;
                }
            },
            next(){
                let slideCont = event.target.parentElement.parentElement.querySelector('.tok-slide--list');

                if (this.count > 0 ) {
                    this.currentPosition -= 160;
                    slideCont.style.transform = `translateX(${this.currentPosition}px)`;
                    this.count -= 1;
                } else {
                    return;
                }

            },
            forceClickLicns(licns) {

                this.licnsChange(licns);
            },
            clickLicns(licns) {

                this.licnsChange(licns);
            },
            licnsChange(licns){
                var index = this.modifyCustLicnsList.findIndex((element) => element == this.modifySelectLicns);

                var bsnPerdCd = "";
                var bsnPerdDeText = "";

                var today = this.$moment();
                var bsnRegstDe =  this.dateToString(this.bsnRegstDe);
                var selectDay = this.$moment(bsnRegstDe);
                var year = today.diff(selectDay,'years');

                if (year >= 5) {
                    bsnPerdCd = '3';
                    bsnPerdDeText = '5년이상';
                } else if (year >= 3) {
                    bsnPerdCd = '2';
                    bsnPerdDeText = '3년~5년';
                } else if (year >= 1) {
                    bsnPerdCd = '1';
                    bsnPerdDeText = '1년~3년';
                } else {
                    bsnPerdCd = '0';
                    bsnPerdDeText = '1년미만';
                }

                this.modifyCustLicnsList[index].y3AcrsltAmt = this.y3AcrsltAmt;
                this.modifyCustLicnsList[index].y5AcrsltAmt = this.y5AcrsltAmt;
                this.modifyCustLicnsList[index].cswkCapaEvltnAmt =  this.cswkCapaEvltnAmt;
                this.modifyCustLicnsList[index].lcettSuplmtAcrsltAmt = this.lcettSuplmtAcrsltAmt;
                this.modifyCustLicnsList[index].bsnPerdCd = bsnPerdCd;
                this.modifyCustLicnsList[index].bsnRegstDe = bsnRegstDe;
                this.modifyCustLicnsList[index].bsnPerdDeText = bsnPerdDeText;

                this.modifySelectLicns = licns;
                this.y3AcrsltAmt = this.modifySelectLicns.y3AcrsltAmt;
                this.y5AcrsltAmt = this.modifySelectLicns.y5AcrsltAmt;
                this.cswkCapaEvltnAmt = this.modifySelectLicns.cswkCapaEvltnAmt;
                this.lcettSuplmtAcrsltAmt = this.modifySelectLicns.lcettSuplmtAcrsltAmt;
                this.bsnRegstDe = this.modifySelectLicns.bsnRegstDe;
                this.changeTooltipText() ;
            },
            dateToString(value){
                // 들어오는 value 값이 공백이면 그냥 공백으로 돌려줌
                if(value == '') return '';
                if(value == 'NaN-NaN-NaN') return '';

                // 현재 Date 혹은 DateTime 데이터를 javaScript date 타입화
                var js_date = new Date(value);

                // 연도, 월, 일 추출
                var year = js_date.getFullYear();
                var month = js_date.getMonth() + 1;
                var day = js_date.getDate();

                // 월, 일의 경우 한자리 수 값이 있기 때문에 공백에 0 처리
                if(month < 10){
                    month = '0' + month;
                }

                if(day < 10){
                    day = '0' + day;
                }
                if(year == '1970' && month == '01' && day == '01'){
                    return '';
                }

                // 최종 포맷 (ex - '2021-10-08')
                return year + '-' + month + '-' + day;
            },
            /** 툴팁 텍스트 변경*/
            changeTooltipText() {
                var index1 = this.tooltipList.findIndex((e) => e.licnsCd == this.modifySelectLicns.licnsCd && e.tooltipCd == '1');
                var index2 = this.tooltipList.findIndex((e) => e.licnsCd == this.modifySelectLicns.licnsCd && e.tooltipCd == '2');
                var index3 = this.tooltipList.findIndex((e) => e.licnsCd == this.modifySelectLicns.licnsCd && e.tooltipCd == '3');
                var index4 = this.tooltipList.findIndex((e) => e.licnsCd == this.modifySelectLicns.licnsCd && e.tooltipCd == '4');
                var index5 = this.tooltipList.findIndex((e) => e.licnsCd == this.modifySelectLicns.licnsCd && e.tooltipCd == '5');

                if (index1 !=  -1) {
                    this.tooltipTxt1 = this.tooltipList[index1].contents
                } else {
                    this.tooltipTxt1 = '22년 경영상태동의확인서 적용입니다.';
                }
                if (index2 !=  -1) {
                    this.tooltipTxt2 = this.tooltipList[index2].contents
                } else {
                    this.tooltipTxt2 = '최근 22년, 21년, 20년도의 최종 준공 완료된 공사 실적 합계액입니다.';
                }
                if (index3 !=  -1) {
                    this.tooltipTxt3 = this.tooltipList[index3].contents
                } else {
                    this.tooltipTxt3 = '최근 22년, 21년, 20년, 19년, 18년도의 최종 준공 완료된 공사 실적 합계액입니다.';
                }
                if (index4 !=  -1) {
                    this.tooltipTxt4= this.tooltipList[index4].contents
                } else {
                    this.tooltipTxt4 = '업체가 공사를 수행할 수 있는 능력을 금액으로 환산한 것으로 현재 23년 시공능력평가액 적용됩니다.';
                }
                if (index5 !=  -1) {
                    this.tooltipTxt5 = this.tooltipList[index5].contents
                } else {
                    this.tooltipTxt5 = '해당 면허의 영업시작일로 공사업 등록증 또는 경영상태등의확인서에서 확인할 수있습니다.';
                }

                if(!this.tooltipActive) {
                    this.tooltipScript();
                    this.tooltipActive = true;
                }
            },
            /** 툴팁 스크립트*/
            tooltipScript() {
                //툴팁 수정 (before after 선택자에 내용 x)
                let toolTip01 = document.getElementById('prtcAmtToolTip');

                if(toolTip01 != null){
                    toolTip01.addEventListener('click', function(event){
                        toolTipClass01(event.target);
                        event.stopPropagation();
                    });
                }

                function toolTipClass01(target) {
                    let parent = target.parentElement;
                    while (parent) {
                        if (parent && parent.classList.contains('tooltip')) {
                            parent.classList.toggle('active');
                            break;
                        }
                        parent = parent.parentElement; // 부모 요소 업데이트
                    }
                }

                document.addEventListener('click', function (event) {
                    const clickedElement = event.target;
                    if (!clickedElement.classList.contains('tooltip__box') && toolTip01 != null) {
                        // toolTip01.forEach(element => {
                        toolTip01.classList.remove('active');
                        // });
                    }
                });


//툴팁 (before after 선택자에 내용)
                let toolTip = document.querySelectorAll('.tooltip__group');
                if (toolTip.length > 0) {
                    toolTip.forEach(element => {
                        element.addEventListener('click', function (event) {
                            toolTipClass(event.target);
                            event.stopPropagation(); // 이벤트 전파 중단
                        });
                    });
                }

                function toolTipClass(target) {
                    let parent = target.parentElement;
                    while (parent) {
                        if (parent && parent.classList.contains('tooltip__group')) {
                            parent.classList.toggle('active');
                            break;
                        }
                        parent = parent.parentElement; // 부모 요소 업데이트
                    }
                }

                document.addEventListener('click', function (event) {
                    const clickedElement = event.target;
                    if (!clickedElement.classList.contains('tooltip__box')) {
                        toolTip.forEach(element => {
                            element.classList.remove('active');
                        });
                    }
                });
            },
            //사용자 정보 저장
            async insertCustBiz(){

                var param = {
                    "id": this.loginInfo.custBizList[0].id,
                    "custId": this.loginInfo.id,
                    "cmpNm": this.loginInfo.custBizList[0]['cmpNm'].toString(),
                    "cdltStCd": "0",
                    "cdltStId": this.selGrd,
                    "cdltStIssOrgnz": this.selOrgnz,
                    "cdltStDe": this.dateToString(this.cdltStDe) != '' ? this.dateToString(this.cdltStDe) : null,
                    "crasRate": this.crasRate,
                    "debtRate": this.debtRate,
                    "femaleCoYn": "N"
                };

                await this.$post('/api/cust/saveManagementStatus',param)
                    .then(async res => {
                        if (res.code == 'OK') {
                            await this.insertCustLicns();
                        }
                    })
                    .catch(error => {console.log(error);}
                    );

            },
            /** 툴팁 리스트 가져오기*/
            getTooltipList() {
                var stringList = [];
                for (var i in this.custLicnsList) {
                    stringList.push(this.custLicnsList[i].licnsCd)
                }

                var param = {
                    licnsCdList: stringList,
                    tooltipCdList: [1, 2, 3, 4, 5]
                };

                this.$post('/api/comn/tooltipList', param)
                    .then(res => {
                        this.tooltipList = res.tooltipList;
                        this.changeTooltipText();
                    })
                    .catch(error => console.log(error));
            },
            refreshPage() {
                window.location.reload();
            },
            async insertCustLicns(){


                var bsnPerdCd = "";
                var bsnPerdDeText = "";

                var today = this.$moment();
                var bsnRegstDe = this.dateToString(this.bsnRegstDe) != '' ? this.dateToString(this.bsnRegstDe) : '';
                var selectDay = this.$moment(bsnRegstDe);
                var year = today.diff(selectDay,'years');

                if (year >= 5) {
                    bsnPerdCd = '3';
                    bsnPerdDeText = '5년이상';
                } else if (year >= 3) {
                    bsnPerdCd = '2';
                    bsnPerdDeText = '3년~5년';
                } else if (year >= 1) {
                    bsnPerdCd = '1';
                    bsnPerdDeText = '1년~3년';
                } else {
                    bsnPerdCd = '0';
                    bsnPerdDeText = '1년미만';
                }

                var index = this.modifyCustLicnsList.findIndex((element) => element == this.modifySelectLicns);

                this.modifyCustLicnsList[index].y3AcrsltAmt = this.y3AcrsltAmt;
                this.modifyCustLicnsList[index].y5AcrsltAmt = this.y5AcrsltAmt;
                this.modifyCustLicnsList[index].cswkCapaEvltnAmt =  this.cswkCapaEvltnAmt;
                this.modifyCustLicnsList[index].lcettSuplmtAcrsltAmt = this.lcettSuplmtAcrsltAmt;
                this.modifyCustLicnsList[index].bsnPerdCd = bsnPerdCd;
                this.modifyCustLicnsList[index].bsnRegstDe = bsnRegstDe;
                this.modifyCustLicnsList[index].bsnPerdDeText = bsnPerdDeText;


                var param = {
                    "custBizId": this.loginInfo.custBizList[0].id,
                    "list": this.modifyCustLicnsList
                };

                await this.$post('/api/cust/saveCustLicns',param)
                    .then(res => {
                        if(res.code == 'OK'){
                            this.$toast.success('내 경영/면허정보가 수정되었습니다. ');
                            //내 경영 정보
                            this.getCustInfo();
                            this.getCustLicnsList();
                            this.reloadNotiSuggestList();
                        }else{
                            this.$toast.error('내 경영/면허정보 업데이트에 실패했습니다.');
                        }

                    })
                    .catch(error => {console.log(error);}
                    );

            },
            insertEmailInfo() {
                var param = {
                    "custEmail": this.email,
                };

                this.$post('/api/cust/updateEmail',param)
                    .then(res => {
                        console.log(res);
                    })
                    .catch(error => console.log(error));
            },


            // setAutoAnlsEndDday() {
            //   console.log(this.loginInfo);
            //     if(this.loginInfo.anlsEndDt != ''){
            //         var anlsEndDt = this.$moment(this.loginInfo.anlsEndDt);
            //         var today = this.$moment(this.$moment().format('YYYY-MM-DD'));
            //         // console.log(today);
            //         console.log(anlsEndDt);
            //         this.dday = anlsEndDt.diff(today, 'days');
            //
            //         console.log(this.dday);
            //     }
            // }
        }
    };
</script>

<!--<style src="./Header.scss" lang="scss"></style>-->
