export default {
    notiTable: {
        selected: [],
        search: '',
        headers: [
            // {
            //     text: 'Name',
            //     align: 'start',
            //     sortable: true,
            //     value: 'name',
            // },
            // { text: 'Company', value: 'company' },
            // { text: 'City', value: 'city' },
            // { text: 'State', value: 'state' },
            // {
            //     text: 'ID',
            //     align: 'start',
            //     sortable: true,
            //     value: 'id',
            // },
            { text: '공고번호', value: 'notiNo' },
            { text: '공고명', value: 'notiNm' },
            { text: '마감일', value: 'bddprFinDt' },
            { text: '투찰하기', value: 'rpsntv_nm'}
        ],
        noti: [
        ],
    },
    materialUITable: [
        {
            id: 0,
            name: 'Mark Otto',
            email: 'ottoto@wxample.com',
            product: 'ON the Road',
            price: '$25 224.2',
            date: '11 May 2017',
            city: 'Otsego',
            status: 'Sent',
        },
        {
            id: 1,
            name: 'Jacob Thornton',
            email: 'thornton@wxample.com',
            product: 'HP Core i7',
            price: '$1 254.2',
            date: '4 Jun 2017',
            city: 'Fivepointville',
            status: 'Sent',
        },
        {
            id: 2,
            name: 'Larry the Bird',
            email: 'bird@wxample.com',
            product: 'Air Pro',
            price: '$1 570.0',
            date: '27 Aug 2017',
            city: 'Leadville North',
            status: 'Pending',
        },
        {
            id: 3,
            name: 'Joseph May',
            email: 'josephmay@wxample.com',
            product: 'Version Control',
            price: '$5 224.5',
            date: '19 Feb 2018',
            city: 'Seaforth',
            status: 'Declined',
        },
        {
            id: 4,
            name: 'Peter Horadnia',
            email: 'horadnia@wxample.com',
            product: 'Let\'s Dance',
            price: '$43 594.7',
            date: '1 Mar 2018',
            city: 'Hanoverton',
            status: 'Sent',
        },
    ],
    menu: [
        'Edit',
        'Copy',
        'Delete',
        'Print'
    ]
}
