<template>
    <div>
        <p>Kakao Login Pages</p>
    </div>
</template>

<script>
    export default {
        mounted() {
            window.Kakao.Auth.authorize({
                redirectUri: `${window.location.origin}/login/kakao`,
                scope: "profile, account_email",
            });
        },

    };
</script>