<template>
  <img src="@/assets/survey/poster.jpg" style="height:100vh" alt="포스터">
</template>

<script>
export default {
  name: 'Poster',
  data() {
    return {
      message3: ''
    }
  },
  methods: {
  }
};
</script>

<style src="./Poster.scss" scoped lang="scss"></style>
