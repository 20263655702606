import { Doughnut } from "vue-chartjs";

import {
    Chart as ChartJS,
    registerables
} from 'chart.js'

ChartJS.register(
    ...registerables
);


export default {
    extends: Doughnut,
    props: ["chartData", "chartOptions"],
    mounted() {
    }
};