<template>

  <div id="box" class="report report-pdf" ref="pdfarea">
    <div class="page">
      <!-- 고객 정보 cus-info -->
      <div class="report__container cus-info">
        <p class="cus-info--title">{{cmpNm}}</p>
        <p class="report--bg-style lin-higt cus-info--txt" v-if="bidTotal != 0" v-html="viewStepText1[0]+viewStepText1[1]+viewStepText1[2]+viewStepText1[3]">
        </p>
        <p class="report--bg-style lin-higt cus-info--txt" v-if="bidTotal == 0"><span v-html="viewStepTextEmpty"></span></p>
      </div>
      <!-- /고객 정보 cus-info -->

      <!-- 내 투찰 정보 요약 정리 info-summary-->
      <div class="info-summary">
        <div class="info-summary--head">
          <span class="info-summary--title--img"></span>
          <p class="info-summary--title">
            투찰 현황<span class="info-summary--sub">({{today}} 기준)</span>
          </p>
        </div>

        <!-- 투찰정보 요약 sumry-list-->
        <div class="sumry-list">
          <p class="sumry-list--title">내 투찰 정보 요약 정리</p>
          <ul class="sumry-list__group">
            <li class="sumry-list__item">
              <p class="sumry-list__item--txt">
                {{Number(bidTotal).toLocaleString()}}<span class="txt--explan">건</span>
              </p>
              <p class="sumry-list__item--sub">최근 1년 동안 참여 공고 수</p>
            </li>
            <li class="sumry-list__item">
              <p class="sumry-list__item--txt">
                {{cmpRankListCnt}}<span class="txt--explan">건</span>
              </p>
              <p class="sumry-list__item--sub">낙찰</p>
            </li>
            <li class="sumry-list__item">
              <p class="sumry-list__item--txt">
                <span class="txt--explan">약</span>
                {{notiSbidRt}}
                <span class="txt--explan">%</span>
              </p>
              <p class="sumry-list__item--sub">낙찰률</p>
            </li>
            <li class="sumry-list__item cost">
              <p class="sumry-list__item--txt">
                {{Number(totalSBidAmt).toLocaleString()}}<span class="txt--explan">원</span>
              </p>
              <p class="sumry-list__item--sub">낙찰 수주액</p>
            </li>
          </ul>
        </div>
        <!-- /투찰정보 요약 sumry-list-->

        <!-- 사정율 차트  evalu-chart-->
        <div class="evalu-chart" v-show="bidTotal != 0">
          <div class="evalu-chart__group">
            <Bar
                    v-loading="loading"
                    :chart-options="chartOptions"
                    :chart-data="chartData"
                    :chart-id="chartIdLeft"
                    :dataset-id-key="datasetIdKey"
                    :plugins="plugins"
                    :css-classes="cssClasses"
                    :styles="styles"
                    :width="width"
                    :height="height"
            />
            <p class="txt--title">사정율 97% ~ 103% 자료</p>
          </div>
          <div class="evalu-chart__group">
            <Bar
                    v-loading="loading"
                    :chart-options="chartOptions"
                    :chart-data="chartData2"
                    :chart-id="chartIdRight"
                    :dataset-id-key="datasetIdKey"
                    :plugins="plugins"
                    :css-classes="cssClasses"
                    :styles="styles"
                    :width="width"
                    :height="height"
            />
            <p class="txt--title">사정율 98% ~ 102% 자료</p>
          </div>
        </div>
        <!-- /사정율 차트 evalu-chart -->

        <!-- tok say (설명) evalu-chart--say-->
        <div class="tok-say evalu-chart--say" style="text-aling:left" v-show="bidTotal != 0">
          <span class="tok-say--img"></span>
          <p class="tok-say--explain lin-higt txt-left">
            위 그래프 자료는 사정율 100을 기점으로 표현하였으며 97% ~ 103%, 98% ~ 102% 그래프로,
            고객님이 지난 1년 동안 참여했던 공고의 출현 예가 비율과 투찰하셨던 포인트 비율을 표시하였습니다.
            투찰하셨던 내용을 각 사정률마다 가장 많이 투찰한 포인트를 확인해 본다면 아래와 같습니다.<br>
            사정율 97% ~ 103% 자료 기준으로 확인했을 때 <span class="empha">고객님의 경우 {{this.sectionArr[this.topGroup-1]}} 부분에 {{this.topPercent}}%로 전체 중 가장 많은 투찰 포인트를
            산정했음을 확인</span>할 수 있습니다. 그 중에서도 <span class="empha">{{this.topPoint}}포인트를 전체 공고 중 약 {{custBidRt}}%로 투찰 포인트를 산정</span>하였고,
            가장 많이 투찰 포인트로 산정했던 {{this.topPoint}}포인트의 실제 출현했던 예가 비율은 {{this.realOpbdPrargRt}}%입니다.<br>
            사정율 98% ~ 102% 자료를 확인했을 때 <span class="empha">{{this.sectionArr[this.topGroup_98-1]}} 부분에 {{this.topPercent_98}}%로 전체 중 가장 많은 투찰 포인트를 산정</span>하였으며,
            그중에서도 {{this.topPoint_98}} 포인트를 많이 산정하였습니다.
          </p>
        </div>
        <!-- /tok say (설명) evalu-chart--say-->
      </div>
      <!-- /내 투찰 정보 요약 정리 info-summary-->
    </div>

    <div class="page" v-show="bidTotal != 0">
      <!-- 예가 출연 차트 setted-chart -->
      <div class="setted-chart">
        <div class="setted-chart__area graph">
          <!-- 그래프 라인 -->
          <ul class="chart-line">
            <li class="chart-line--item"></li>
            <li class="chart-line--item"></li>
            <li class="chart-line--item"></li>
            <li class="chart-line--item empha"></li>
            <li class="chart-line--item"></li>
            <li class="chart-line--item"></li>
            <li class="chart-line--item"></li>
          </ul>
          <!-- /그래프 라인 -->

          <div class="graph-content">
            <!-- 그래프 그림 -->
            <div class="graph-img__group graph-img">
              <div class="graph-img__unit">
                <div class="graph-img__box graph-img--top graph-img--left" :style="stdrd100yegaStyle[0]">
                  <span class="txt stdrd100yegaUp">{{this.stdrd100yega[0]}}</span>
                </div>
                <div class="graph-img__box graph-img--top graph-img--right" :style="stdrd100bidStyle[0]">
                  <span class="txt stdrd100bidUp">{{this.stdrd100bid[0]}}</span>
                </div>
              </div>
              <div class="graph-img__unit">
                <div class="graph-img__box graph-img--bottom graph-img--left" :style="stdrd100yegaStyle[1]">
                  <span class="txt stdrd100yegaDown">{{this.stdrd100yega[1]}}</span>
                </div>
                <div class="graph-img__box graph-img--bottom graph-img--right" :style="stdrd100bidStyle[1]">
                  <span class="txt stdrd100bidDown">{{this.stdrd100bid[1]}}</span>
                </div>
              </div>
            </div>
            <!-- /그래프 그림 -->

            <!-- 그래프 이름 -->
            <ul class="graph-img__group name">
              <li class="name__item name--left">예가 출현 비율(%)</li>
              <li class="name__item name--right">내 투찰 비율(%)</li>
            </ul>
            <!-- /그래프 이름 -->
          </div>
        </div>
        <div class="setted-chart__area explain lin-higt">
          왼쪽의 표는 사정율 97% ~ 103% 기준으로<br>참여했던 공고의 예가 출현 비율 및<br>내 투찰 비율을 100 기준으로 마이너스 부분과<br>플러스 부분의 합을 표현한 그래프입니다.
        </div>
      </div>
      <!-- /예가 출연 차트 setted-chart -->

      <!-- tok say (설명) setted-chart--say-->
      <div class="tok-say setted-chart--say">
        <span class="tok-say--img"></span>
        <p class="tok-say--explain lin-higt" v-html="viewStepText0[0]+viewStepText0[1]+viewStepText0[2]">
        </p>
      </div>
      <div class="setted-chart--say--arrow"></div>
      <!-- /tok say (설명) setted-chart--say-->

      <!-- 결과 result -->
      <div class="result">
        <p class="result--sub">나의 분석 투찰 성향은?</p>
        <p class="result--title">{{tendencyCdArr[tendencyCd]}}</p>
        <ul class="bid-type__list">
          <li class="bid-type__list--item" :class="[tendencyCd == 0 ? 'active' : '']">적극형</li>
          <li class="bid-type__list--item" :class="[tendencyCd == 1 ? 'active' : '']">부분적극형</li>
          <li class="bid-type__list--item" :class="[tendencyCd == 2 ? 'active' : '']">부분중립형</li>
          <li class="bid-type__list--item" :class="[tendencyCd == 3 ? 'active' : '']">중립형</li>
          <li class="bid-type__list--item" :class="[tendencyCd == 4 ? 'active' : '']">부분안정형</li>
          <li class="bid-type__list--item" :class="[tendencyCd == 5 ? 'active' : '']">안정형</li>
        </ul>
        <div class="tok-say result--say">
          <span class="tok-say--img"></span>
          <p class="tok-say--explain lin-higt txt-left" v-html="this.viewStepText2[0]+this.viewStepText2[1]+this.viewStepText2[2]+this.viewStepText2[3]+this.viewStepText2[4]+this.viewStepText2[5]">
            <!--              대표님의 지난 투찰 기록을 바탕으로 성향 분석 결과, (주)다임에스디 대표님의 투찰 성향은 [안정형]으로 분류되었습니다. 이는 고객님께서 예상 예가의 출현을 중점적으로 파악하며, <span class="empha">산출 예가의 비중이 높은 부분에 주로 투찰</span>하시며, 동시에 예가와 근접하지 못하는 위험을 최소화하려고 노력하신다는 것을 의미합니다. 이와 같은 접근 방식은 전체적으로 신중하게 정보를 파악하고 분석하며, <span class="empha">예정 가격 예상 출현에 집중하여 예가의 흐름을 앞서가려합니다.</span> 하지만 예가의 흐름을 앞서려다 보니 근소한 차이에서만 낙찰이 이루어지기 때문에 때로는 입찰 참여함에 있어 지침을 토로할 수 있습니다. <br>이에 따라, 고객님의 투찰 전략을 더욱 세밀하게 조율하기 위해 아래와 같은 제안을 드립니다.-->
          </p>
        </div>
        <ul class="result-tip">
          <li class="result-tip__group">
            <p class="result-tip--title no-empha">{{viewStepText3[0]}}</p>
            <p class="result-tip--explain lin-higt" v-html="viewStepText4[0]">
            </p>
          </li>
        </ul>
      </div>
      <!-- 결과 result -->
    </div>
    <div class="page" v-show="bidTotal != 0">
      <!-- 결과 하단 부분 -->
      <div class="result">
        <ul class="result-tip">
          <li class="result-tip__group">
            <p class="result-tip--title no-empha">{{viewStepText5[0]}}</p>
            <p class="result-tip--explain lin-higt" v-html="viewStepText6[0]">
            </p>
          </li>
        </ul>
      </div>
      <!-- /결과 하단 부분 -->
      <!-- 추천 recomd -->
      <div class="report__container recomd">
        <div class="recomd__area suit">
          <div class="recomd--head">
            <span class="recomd--title--img"></span>
            <div>
              <p class="recomd--title">내 성향과 맞는</p>
              <p class="recomd--sub">비드톡톡 추천 AI분석가</p>
            </div>
          </div>

          <!-- 추천 ai 카드 recomd-suit
              분석가에 따라 color-dog / color-peng / color-suri 붙여주세요.  -->
          <ul class="recomd-suit" :class="[recomdArr == '수리' ? 'color-suri' : recomdArr == '도그' ? 'color-dog' : recomdArr == '펭' ? 'color-peng' : '']"  v-for="(recomdArr, idx) in this.tendencyCdArr2[this.tendencyCd]" v-bind:key="recomdArr">
            <li class="recomd-suit--card">
              <div class="recomd-suit__group analist">
                <img src="@/assets/images/analyist/img_analyist_dog_lg.png" alt="분석가 이미지" class="recomd-suit__unit analist--img" v-if="recomdArr == '도그'">
                <img src="@/assets/images/analyist/img_analyist_peng_lg.png" alt="분석가 이미지" class="recomd-suit__unit analist--img" v-if="recomdArr == '펭'">
                <img src="@/assets/images/analyist/img_analyist_suri_lg.png" alt="분석가 이미지" class="recomd-suit__unit analist--img" v-if="recomdArr == '수리'">
                <div class="recomd-suit__unit">
                  <p class="analist--sub">{{recomdArr == "도그" ? '중심분석' : recomdArr == "수리" ? '자율분석' : recomdArr == "펭" ? '외곽분석' : ''}}</p>
                  <p class="analist--title col-change">{{recomdArr}}</p>
                </div>
              </div>
              <div class="recomd-suit__group report--bg-style content">
                <div class="explain">
                  <p class="txt lin-higt" v-html="viewStepText7[idx]">
                  </p>
                  <p class="txt col-change recom">{{viewStepText8[idx]}}</p>
                  <div class="explain__group">
                    <p class="content--graph--title">나와의 적합도</p>
                    <div class="content--graph">
                      <el-progress :text-inside="false" :stroke-width="15" :percentage="viewStepText8_1[idx]" style="width:250px" v-bind:id="'suggestGraph'+recomdArr"></el-progress>
                    </div>
                  </div>
                </div>
                <div class="bid-rate">
                  <p class="bid-rate--title col-change">{{viewStepText8_2[idx]}}</p>
                  <p class="bid-rate--sub">AI {{recomdArr}} 평균 낙찰률</p>
                </div>
              </div>
            </li>
          </ul>
          <!-- /추천 ai 카드 recomd-suit-->
        </div>
        <div :style="spacingDiv">
        </div>
        <!-- 성향 변화 추천  change -->
        <div class="recomd__area change">
          <div class="recomd--head">
            <span class="recomd--title--img"></span>
            <div>
              <p class="recomd--title">내 성향 변화 위한</p>
              <p class="recomd--sub">비드톡톡 추천 AI분석가</p>
            </div>
          </div>

          <!-- 분석가에 따라 color-dog / color-peng / color-suri 붙여주세요. -->
          <div class="recom-change" :class="[tendencyCdReverseArr[tendencyCd] == '수리' ? 'color-suri' : tendencyCdReverseArr[tendencyCd] == '도그' ? 'color-dog' : tendencyCdReverseArr[tendencyCd] == '펭' ? 'color-peng' :  tendencyCdReverseArr[tendencyCd] == '' ? 'color-all' : '']">
            <div class="recom-change__group analist">
              <div class="analist--title">
                <p class="sub">{{tendencyCdReverseArr[tendencyCd] == '수리' ? '자율 분석' : tendencyCdReverseArr[tendencyCd] == '도그' ? '중심 분석' : tendencyCdReverseArr[tendencyCd] == '펭' ? '외곽 분석' : ''}}</p>
                <p class="title col-change">{{tendencyCdReverseArr[tendencyCd]}}</p>
              </div>
              <span class="analist--img"></span>
            </div>
            <div class="report--bg-style recom-change__group content">
              <p class="lin-higt" v-html="viewStepText9[0]">
              </p>
            </div>
          </div>
        </div>
        <!-- /성향 변화 추천  change -->

        <!-- 마무리 멘트 final--say -->
        <div class="tok-say final--say">
          <span class="tok-say--img"></span>
          <p class="tok-say--explain lin-higt" v-html="viewStepText10[0]">
          </p>
        </div>
        <!-- 마무리 멘트 -->
      </div>
      <!-- /추천 recomd -->
    </div>

  </div>


</template>

<script>
    import * as _ from 'lodash'
    import $ from 'jquery';
    import { Bar } from 'vue-chartjs/legacy'
    import {
        Chart as ChartJS,
        Title,
        Tooltip,
        Legend,
        BarElement,
        CategoryScale,
        LinearScale
    } from 'chart.js'
    import html2pdf from 'html2pdf.js';
    //import ChartJSPluginDatalabels from 'chartjs-plugin-datalabels';

    ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale); //, ChartJSPluginDatalabels);

    // let chart;
    //
    // chart = new ChartJS(this.$refs.barChart, {
    //     type: 'bar',
    //     data: this.chartData,
    //     options:this.options
    // })

    export default {
        name: "Dashboard",
        components: {
            Bar,
        },
        props: {
            chartIdLeft: {
                type: String,
                default: 'bar-chart-left'
            },
            chartIdRight: {
                type: String,
                default: 'bar-chart-right'
            },
            datasetIdKey: {
                type: String,
                default: 'label'
            },
            width: {
                type: Number,
                default: 360
            },
            height: {
                type: Number,
                default: 320
            },
            cssClasses: {
                default: '',
                type: String
            },
            styles: {
                type: Object,
                default: () => {}
            },
            plugins: {
                type: Array,
                default: () => []
            }
        },
        data() {
            return {
                //imsi: false,
                //bizNo: '2068175957' //나오스디자인
                //bizNo: '4168160385',
                //bizNo: '2118668278',
                //bizNo: '3838101131', //인우이엔지
                //bizNo: '2868100414', //대상종합건설
                bizNo: this.$route.params.bizNo, //드림로드
                sectionArr: ["~98.9","99.0~99.4","99.5~99.9","100.0~100.4","100.5~100.9","101.0~"], // 구간
                custRecordStatList: [],
                custRecordStatList2: [],
                custRecordStatList3: [{'낙찰구간':'낙찰건수','1구간':0,'2구간':0,'3구간':0,'4구간':0,'5구간':0,'6구간':0}], //구간별 낙찰 건수
                custRecordPercentList: [], //투찰율 구간별 백분율
                custRecordPercentList2: [], //투찰율 구간별 백분율 (98~102)
                strtDate: new Date(new Date().getFullYear()-1, new Date().getMonth(), new Date().getDate()).toLocaleDateString(),
                finDate: new Date(),
                config: {
                    headers: {
                        'Content-Type': 'application/json',
                        'auth-token': localStorage.getItem("authToken"), //localStorage.getItem("authToken"),
                        'refresh-token': localStorage.getItem("refreshToken") // localStorage.getItem("refreshToken"),
                    },
                },
                searchParam: {
                    strtDate: new Date(new Date().getFullYear()-1, new Date().getMonth(), new Date().getDate()).toLocaleDateString(),
                    finDate: new Date()
                },
                realNoticeList: [{'1구간':0,'2구간':0,'3구간':0,'4구간':0,'5구간':0,'6구간':0}],
                licnsCodeList: [],
                licnsCodeGroupList: [],
                areaCodeList: [],
                loading: false,
                checkboxGroup1: [],
                licnsValList: [],
                licnsKeyList: [],
                licnsCntList: [],
                checkboxGroup2: [],
                prvnclList: [],
                prvnclValList: [],
                prvnclKeyList: [],
                areaValList: [],
                areaKeyList: [],
                areaCntList: [],
                toggle1: false,
                toggle2: false,
                cmpNm: '',
                //maxNum1 : 256,
                maxNum2 : '549876',
                maxNum3 : 759214,
                cmpRankLicns: '상하수도 1건 / 지반조성포장 5건 / 철콘 2건',
                bidTotal:0, // 참여한 총 공고수
                cmpRankListCnt: 0, //총 낙찰 건수
                totalSBidAmt: 0, //낙찰한 공고 총금액
                notiSbidRt: 0, //낙찰률
                today: this.$moment().format('LL'), //오늘날짜
                topGroup:'', // 가장 많이 투찰한 구간
                topGroup_98:'', // 가장 많이 투찰한 구간 (98~102)
                topPoint:'', // 가장 많이 투찰한 지점
                topPoint_98:'', // 가장 많이 투찰한 지점 (98~102)
                custBidRt:0, // 가장 많이 투찰한 포인트의 전체 투찰 비율
                realOpbdPrargRt:0, // 실제 예가 출현 비율
                topPercent:'', // 가장 많이 투찰한 구간의 비율
                topPercent_98:'', // 가장 많이 투찰한 구간의 비율 (98~102)
                groupBidRank: [], // 구간별 투찰 순위
                groupBidRank_98: [], // 구간별 투찰 순위 (98~102)
                chartData: {
                    labels: [
                        '~98.9',
                        '99.0~99.4',
                        '99.5~99.9',
                        '100.0~100.4',
                        '100.5~100.9',
                        '101.0~'
                    ],
                    datasets: [
                        {
                            label: '예가 출현 비율(%)',
                            backgroundColor: '#cce2ff',
                            data: [0, 0, 0, 0, 0, 0, 0]
                        },
                        {
                            label: '내 투찰 비율(%)',
                            backgroundColor: '#137aff',
                            data: [0, 0, 0, 0, 0, 0, 0]
                        }
                    ],
                },
                chartData2: {
                    labels: [
                        '~98.9',
                        '99.0~99.4',
                        '99.5~99.9',
                        '100.0~100.4',
                        '100.5~100.9',
                        '101.0~'
                    ],
                    datasets: [
                        {
                            label: '예가 출현 비율(%)',
                            backgroundColor: '#cce2ff',
                            data: [0, 0, 0, 0, 0, 0, 0]
                        },
                        {
                            label: '내 투찰 비율(%)',
                            backgroundColor: '#137aff',
                            data: [0, 0, 0, 0, 0, 0, 0]
                        }
                    ],
                },
                chartOptions: {
                    responsive: false,
                    maintainAspectRatio: false,
                    // tooltip: { // 기존 툴팁 사용 안 함
                    //     enabled: false
                    // },
                    scales: {
                        yAxis: {
                            grid: {
                                display: true
                            },
                            // ticks: {
                            //     maxTicksLimit: 15
                            // },
                            beginAtZero: true
                            ,suggestedMax: 55
                        }
                    },
                    plugins: {
                        datalabels: {
                            clamp: true,
                            anchor: 'end',
                            align: 'top',
                            offset: 1,
                            formatter: (value) => {
                                let percent = value;
                                return percent;
                            }
                            // font: {
                            //     weight: 'bold'
                            // }
                        },
                        legend: { // 범례 사용 안 함
                            display: true,
                            position: 'top',
                            labels: {
                                padding: 20,
                            }
                        },
                    }
                },
                isComplete: false, // 조회완료
                tendencyCd: 0, // 투찰 성향
                tendencyCdArr: [], // 투찰 성향 배열
                tendencyCdArr2: [['펭'],['펭','수리'],['펭','수리','도그'],['수리'],['수리','도그'],['도그']], // 투찰 성향별 추천AI
                tendencyCdReverseArr: ['도그','도그','','펭','펭','수리'], // 투찰 반대 성향 배열
                tendencyCdReverseArr2: ['부분중립형/안정형/부분안정형','부분중립형/안정형/부분안정형','','적극형/부분적극형/부분중립형','적극형/부분적극형/부분중립형','부분적극형/중립형/부분중립형/부분안정형'], // 투찰 반대 성향 배열
                tendencyDetail: [], // 투찰 성향 상세 type1 멘트
                tendencyDetail2: [], // 투찰 성향 상세 type2 멘트
                tendencyDetail2_1: [], // 투찰 성향 상세 type2 멘트
                tendencyDetail2_2: [], // 투찰 성향 상세 type2 멘트
                tendencyDetail3: [], // 투찰 성향 상세 type3 멘트
                aiSuggest: [], // 추천 AI
                setTyping: null,
                scrollTime: 0,
                tendencyDetail2_0_Percent: [],
                tendencyDetail2_1_Percent: [],
                tendencyDetail2_2_Percent: [],
                isLine2: false,
                isLine3: false,
                isTyper1: false,
                isTyper2: false,
                isTyper3: false,
                stdrd100yega: [], // 100기준 상하 예가 출현비율
                stdrd100yegaStyle: [],
                stdrd100bid: [], // 100기준 상하 내 투찰비율
                stdrd100bidStyle: [],
                scrollVersion: false,
                viewStep: [false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false], // 화면에 보여지는 단계
                viewStepTextEmpty: '',
                viewStepText0: [],
                viewStepText1: [this.cmpNm+" 대표님의 ","<span class=\"cus-info--empha textUnderline\">최근 1년 동안의 투찰 활동에 대한 리포트</span>","를 제공해드립니다.<br>고객님의 투찰 성향을 파악하였으며,<br>","아래 자료를 참고하여 앞으로의 분석 방향에 참고하시길 바랍니다."],
                viewStepText2: [],
                viewStepText3: [],
                viewStepText4: [],
                viewStepText5: [],
                viewStepText6: [],
                viewStepText7: [],
                viewStepText8: [],
                viewStepText8_1: [],
                viewStepText8_2: [],
                viewStepText9: [],
                viewStepText10: [],
                div: null,
                initBody: null,
                spacingDiv: {},
                rtMaxArr: [], // 사정율 구간 각각의 최대값
                rtMax: 0, // 사정율 구간 각각의 최대값 중 최대값
            }
        },
        async mounted() {
            var ip = await this.$getPublicIP();

            if(ip == '39.125.87.9') {
                this.doSearch();

            } else {
                this.$toast.error('접근권한이 없습니다.');
                this.$router.push('/home');
            }

            /* 사정율 그래프 높이 제어 */
            if (this.$route.params.height != undefined) {
                // if (this.$route.params.height.indexOf(",") > 0) {
                //     this.leftHeight = this.$route.params.height.split(",")[0];
                // }
                this.chartOptions.scales.yAxis.suggestedMax = this.$route.params.height;
            }
        },
        created() {

        },
        methods: {
            async typingFn () {

                const $text1 = document.querySelector(".typingStep1_1"); $text1.innerHTML = "";
                const $text2 = document.querySelector(".typingStep1_2 .cus-info--empha"); $text2.innerHTML = "";
                const $text3 = document.querySelector(".typingStep1_3"); $text3.innerHTML = "";
                const $text4 = document.querySelector(".typingStep1_4"); $text4.innerHTML = "";

                const speed = 35;
                //let i = 0;

                const letter1 = this.viewStepText1[0].split("");
                while (letter1.length) {
                    await this.wait(speed);
                    $text1.innerHTML += letter1.shift();
                }

                const letter2 = this.viewStepText1[1].split("");
                while (letter2.length) {
                    await this.wait(speed);
                    $text2.innerHTML += letter2.shift();
                }

                const letter3 = this.viewStepText1[2].split("");
                while (letter3.length) {
                    await this.wait(speed);
                    $text3.innerHTML += letter3.shift();
                }

                const letter4 = this.viewStepText1[3].split("");
                while (letter4.length) {
                    await this.wait(speed);
                    $text4.innerHTML += letter4.shift();
                }

                // 잠시 대기
                await this.wait(800);

                // // 지우는 효과
                // remove();

                this.newScroll();
            },
            wait(ms) {
                return new Promise(res => setTimeout(res, ms))
            },
            newScroll() {
                $('.window').animate( { scrollTop : $("#barChart").offset().top }, 500 );
                // //if (this.scrollVersion) {
                //     let reportMain = document.getElementById('reportMain');
                //     console.log(reportMain);
                //
                //     reportMain.scrollTop = reportMain.scrollHeight;
                //
                //     //$(elMain).scrollTop($(elMain).prop('scrollHeight'));
                //
                //     //var preScrollHeight = 0;
                //     setTimeout(() => {
                //         this.scrollTime = setInterval(() => {
                //             console.log($(document).height());
                //             console.log(reportMain.scrollTop);
                //             //preScrollHeight = $(elMain).prop('scrollHeight')+300;
                //             //$(elMain).scrollTop($(elMain).prop('scrollHeight')+300);
                //
                //             $(reportMain).animate({
                //                 scrollTop: 100
                //             }, 1500)
                //
                //             //console.log(preScrollHeight);
                //             // if (preScrollHeight == $(elMain).prop('scrollHeight')+300) {
                //             //     clearInterval(scrollTime);
                //             // }
                //
                //             //console.log(this.isTyper);
                //         }, 2550);
                //         //console.log(this.scrollTime);
                //
                //     }, 2500);
                // //}
                // /* 스크롤 e*/
            },
            showPrint ()  {
                //인쇄 할 영역 작성 : 나는 id가 print-content인 엘리먼트의 내부에 있는 HTML영역을 잡음
                const print_content = document.querySelector('#box').innerHTML;
                const origin_content = document.querySelector('#modalSheet')

                /**
                 * window.onbeforeprint : print 되기전에 수행되는 함수
                 * print 되기전에 인쇄하고자 하는 영역(엘리먼트)의 값을
                 * document.body에 담아준다
                 */
                window.onbeforeprint = function () {
                    document.body = print_content;
                };

                //print 수행
                window.print();

                /**
                 * window.onafterprint : print 되고난 후에 수행되는 함수
                 * print 되고난 후 기존 영역을 document.body에 담아준다
                 */
                window.onafterprint = function () {
                    document.body = origin_content;
                };

                return true;
            }
            ,onPrint() {
                const html = document.querySelector('html');
                const printContents = document.querySelector('.modal-body').innerHTML;
                const printDiv = document.createElement("DIV");
                printDiv.className = "print-div";

                html.appendChild(printDiv);
                printDiv.innerHTML = printContents;
                document.body.style.display = 'none';
                window.print();
                document.body.style.display = 'block';
                printDiv.style.display = 'none';
            },
            exportToPDF () {
                //window.scrollTo(0, 0);
                html2pdf(this.$refs.pdfarea, {
                    margin: [10, 10, 10, 10],
                    filename: 'document.pdf',
                    image: {type: "jpg", quality: 0.95},
                    //	allowTaint 옵션추가
                    html2canvas: {scrollY: 0, scale: 1, dpi: 300, letterRendering: true, allowTaint: true},
                    jsPDF: {orientation: 'portrait', unit: 'mm', format: 'a4', compressPDF: true}
                })
            },
            line1Complete: function() {
                setTimeout(() => {
                    this.isLine2 = true;
                }, 500);
                setTimeout(() => {
                    this.isLine3 = true;
                }, 5000);

                if (!this.scrollVersion) {
                    this.isTyper2 = true;
                    setTimeout(() => {
                        this.isTyper3 = true;
                    }, 500);
                    setTimeout(() => {
                        clearInterval(this.scrollTime);
                    }, 10000);
                }
            },
            stepComplete: function(step) {
                switch (step) {
                    case 0:

                        break;
                    case 1:

                        break;
                    default:
                }
            },
            typerComplete: function() {
                this.isTyper2 = true;

                setTimeout(() => {
                    this.isTyper3 = true;
                }, 500);
            },
            typerComplete2: function() {
                clearInterval(this.scrollTime);
            },
            setTendency(imsi) { //

                var step1 = this.groupBidRank[0];
                var step2 = this.groupBidRank[1];

                if (this.bizNo == '8893701368') {
                    step1 = 2;
                    step2 = 5;
                }

                this.tendencyCdArr = ['적극형','부분적극형','부분중립형','중립형','부분안정형','안정형']; // 투찰 성향 배열

                this.viewStepText10 = ["비드톡톡은 언제나 고객님의 <span class=\"textUnderline\">더 많은 낙찰이 되기를 기원</span>하며 \n" +
                "고객님의 투찰 전략과 가장 <span class=\"textUnderline\">잘 맞는 비드톡톡의 AI 분석가를 선택</span>하여 보다 체계적이고 전략적인 투찰 접근을 시도해 보시는 것을 권장드립니다."];

                if (this.stdrd100yega[1] >= 50) {
                    this.viewStepText0.push("예가 출현 비율을 확인했을 때 특별한 특징은 눈에 띄지 않으나, 우리가 알고 있는 이론대로 <span class=\"textUnderline\">사정율 내 100.0 이하에서 예가 출현 성향이 강합니다.</span> ");

                    if (this.stdrd100bid[1] >= 50) {
                        this.viewStepText0.push("고객님의 투찰 포인트 산정 비율은 <span class=\"textUnderline\">100.0 이하 구간에서 더 많이 형성되어 있음을</span> 알 수 있습니다. ");
                        this.viewStepText0.push("예가의 흐름을 놓치지는 않도록 강세 구간 안에서도 균형적인 투찰 포인트 산정을 점검하며, 지속적으로 강세인 예가 흐름 및 경쟁률을 꾸준히 파악하여 최적의 투찰 전략, 포인트를 산정하셔야합니다.");
                    } else {
                        this.viewStepText0.push("고객님의 투찰 포인트 산정 비율은 <span class=\"textUnderline\">100.0 이상 구간에서 더 많이 형성되어 있음을</span> 알 수 있습니다. ");
                        this.viewStepText0.push("예가의 흐름을 놓치지는 않는지 투찰 포인트를 점검 및 조정해야할 것으로 보이며, 강세인 예가 흐름과 경쟁율을 꾸준히 파악해 최적의 투찰 전략 및 포인트를 산정하셔야합니다");
                    }
                } else {
                    this.viewStepText0.push("예가 출현 비율을 확인했을 때 우리가 알고 있는 이론보다 사정율 내 100.0 이하 예가 출현 성향이 약함을 확인할 수 있습니다.");

                    if (this.stdrd100bid[1] >= 50) {
                        this.viewStepText0.push("고객님의 투찰 포인트 산정 비율은 <span class=\"textUnderline\">100.0 이하 구간에서 더 많이 형성되어 있음을</span> 알 수 있습니다. ");
                        this.viewStepText0.push("예가의 흐름을 놓치지는 않는지 투찰 포인트를 점검 및 조정해야할 것으로 보이며, 강세인 예가 흐름과 경쟁율을 꾸준히 파악해 최적의 투찰 전략 및 포인트를 산정하셔야합니다");
                    } else {
                        this.viewStepText0.push("고객님의 투찰 포인트 산정 비율은 <span class=\"textUnderline\">100.0 이상 구간에서 더 많이 형성되어 있음을</span> 알 수 있습니다. ");
                        this.viewStepText0.push("예가의 흐름을 놓치지는 않도록 강세 구간 안에서도 균형적인 투찰 포인트 산정을 점검하며, 지속적으로 강세인 예가 흐름 및 경쟁률을 꾸준히 파악하여 최적의 투찰 전략, 포인트를 산정하셔야합니다.");
                    }
                }

                /* 예가 그래프 수치 조정 */
                if (this.stdrd100bid[0] < 30) {
                    //style="top:-10px;color:black"
                    $(document.getElementsByClassName('stdrd100bidUp')).css("top","-11px");
                    $(document.getElementsByClassName('stdrd100bidUp')).css("color","#036BF1");
                }
                if (this.stdrd100bid[1] < 30) {
                    //style="top:-10px;color:black"
                    $(document.getElementsByClassName('stdrd100bidDown')).css("top",this.stdrd100bid[1]*0.8+10 + "px");
                    $(document.getElementsByClassName('stdrd100bidDown')).css("color","#036BF1");
                }
                if (this.stdrd100yega[0] < 30) {
                    //style="top:-10px;color:black"
                    $(document.getElementsByClassName('stdrd100yegaUp')).css("top","-11px");
                    $(document.getElementsByClassName('stdrd100yegaUp')).css("color","#036BF1");
                }
                if (this.stdrd100yega[1] < 30) {
                    //style="top:-10px;color:black"
                    $(document.getElementsByClassName('stdrd100yegaDown')).css("top",this.stdrd100yega[1]*0.8+10 +"px");
                    $(document.getElementsByClassName('stdrd100yegaDown')).css("color","#036BF1");
                }

                switch (step1) {
                    case 1:
                        console.log("1구간");
                        if (step2 == 6) {
                            this.tendencyCd = 0;
                            this.tendencyDetail2_0_Percent = [93,3.21];

                            this.viewStepText2 = ["대표님의 지난 투찰 기록을 바탕으로 성향 분석 결과, " + this.cmpNm + " 대표님의 <span class=\"textUnderline\">투찰 성향은 [적극형]입니다.</span>",
                                "이는 고객님께서 낙찰 확률의 잠재력이 높은 곳으로 투찰하며 <span class=\"textUnderline\">새로운 예가 출현의 기회를 잡으려 하고,</span> ",
                                "흙 속의 진주를 발견하려는 성향을 가지고 있다는 것을 의미합니다.<br>",
                                "[적극형]의 특징은 경쟁 업체의 진입이 적으면서도 예상 출현 예가의 흐름을 확인하고 파악하며, 분산 투자보다는 <span class=\"textUnderline\">집중 투찰 포인트를 도전적으로 찾아내는 전략</span>입니다. ",
                                "하지만 오히려 도전적인 부분과 잠재력이 높은 포인트를 찾다보니 예가의 흐름을 따라가지 못한다는 말을 듣기도 합니다. ",
                                "이에 따라, 고객님의 투찰 전략을 더욱 세밀하게 조율하기 위해 아래와 같은 제안을 드립니다"];

                            this.viewStepText3 = ["경쟁 업체 및 예가 출현의 기회 분석"];

                            this.viewStepText4 = ["고객님의 경우, 경쟁 업체의 진입이 적고 잠재력 높은 출현 예가의 흐름을 면밀히 분석하여, <span class=\"textUnderline\">새롭고 도전적인 투찰 포인트를 발견하는 것이 중요</span>합니다. 이를 통해, 고객님께서는 시장에서 유리한 위치를 확보하며 낙찰 확률을 높일 수 있습니다."];

                            this.viewStepText5 = ["집중 투찰 포인트의 선정 및 끈기 있는 투찰"];

                            this.viewStepText6 = ["고객님은 분산 투자보다는 <span class=\"textUnderline\">하나 혹은 소수의 집중된 투찰 포인트에 끈기 있게 투찰함으로써 낙찰 확률을 높이려는 전략을 선호</span>합니다. 이러한 접근은 고객님께서 시장 변화에 민첩하게 대응하고, 장기적으로 안정적인 수익을 창출할 수 있는 기반을 마련해 줄 것입니다."];

                            this.viewStepText7 = ["최신 예가 자료 및 경쟁 업체 참여 자료를 바탕으로, 출현 예가와 <span class=\"textUnderline\">경쟁사와 우위한 구간에서 결정적인 투찰 포인트를 산출</span>합니다. 이런 접근 방식의 분석 능력은 공고 내에서 유리한 포인트를 확보하며, 상황에 따라 경쟁사와 예가의 <span class=\"textUnderline\">전략적 위치를 선점하길 원하는 고객님에게 적극 추천</span>됩니다."];

                            this.viewStepText8 = ["적극형/부분적극형/부분중립형 성향에 추천드립니다."];

                            this.viewStepText8_1 = [93];

                            this.viewStepText8_2 = [3.21];

                            this.viewStepText9 = ["기본적으로 <span class=\"empha textUnderline\">"+ this.tendencyCdReverseArr2[this.tendencyCd] + " 성향에 추천</span>드리는 AI분석가입니다.\n" +
                            "하지만 현재 결과가 나오지 않아 내 투찰 성향의 개선을 위해 적극적인 변화를 원하신다면\n" +
                            "<span class=\"empha textUnderline\">AI분석가 "+ this.tendencyCdReverseArr[this.tendencyCd] + " 를 추천</span>드리겠습니다."];

                            this.tendencyDetail = ["대표님의 지난 투찰 기록을 바탕으로 성향 분석 결과, " + this.cmpNm + " 대표님의 투찰 성향은 [적극형]으로 분류되었습니다. \n" +
                            "이는 고객님께서 낙찰 확률의 잠재력이 높은 곳으로 투찰하며 새로운 예가 출현의 기회를 잡으려 하고,\n" +
                            "흙 속의 진주를 발견하려는 성향을 가지고 있다는 것을 의미합니다.\n" +
                            "[적극형]의 특징은 경쟁 업체의 진입이 적으면서도 예상 출현 예가의 흐름을 확인하고 파악하며,\n" +
                            "분산 투자보다는 집중 투찰 포인트를 도전적으로 찾아내는 전략입니다.\n" +
                            "하지만 오히려 도전적인 부분과 잠재력이 높은 포인트를 찾다보니 예가의 흐름을 따라가지 못한다는 말을 듣기도 합니다.\n" +
                            "특징은 도전적이며 잠재력 높은 투찰 포인트를 찾아내려는 전략적 접근입니다.\n" +
                            "\n" +
                            "이에 따라, 고객님의 투찰 전략을 더욱 세밀하게 조율하기 위해 아래와 같은 제안을 드립니다:\n" +
                            "\n" +
                            "▶ 경쟁 업체 및 예가 출현의 기회 분석\n" +
                            "고객님의 경우, 경쟁 업체의 진입이 적고 잠재력 높은 출현 예가의 흐름을 면밀히 분석하여, 새롭고 도전적인 투찰 포인트를 발견하는 것이 중요합니다.\n" +
                            "이를 통해, 고객님께서는 시장에서 유리한 위치를 확보하며 낙찰 확률을 높일 수 있습니다.\n" +
                            "\n" +
                            "▶ 집중 투찰 포인트의 선정 및 끈기 있는 투찰\n" +
                            "고객님은 분산 투자보다는 하나 혹은 소수의 집중된 투찰 포인트에 끈기 있게 투찰함으로써 낙찰 확률을 높이려는 전략을 선호합니다.\n" +
                            "이러한 접근은 고객님께서 시장 변화에 민첩하게 대응하고, 장기적으로 안정적인 수익을 창출할 수 있는 기반을 마련해 줄 것입니다.\n"];

                            this.tendencyDetail2 = [
                                "내 성향과 맞는 비드톡톡 추천 AI분석가: " + this.tendencyCdArr2[this.tendencyCd][0] + "\n" +
                                "\n" +
                                "AI분석가 펭: \n" +
                                "최신 예가 자료 및 경쟁 업체 참여 자료를 바탕으로, 출현 예가와 경쟁사와 우위한 구간에서 결정적인 투찰 포인트를 산출합니다.\n" +
                                "이런 접근 방식의 분석 능력은 공고 내에서 유리한 포인트를 확보하며, 상황에 따라 경쟁사와 예가의 전략적 위치를 선점하길 원하는 고객님에게 적극 추천됩니다.\n" +
                                "★적극형/부분적극형/부분중립형 성향에 추천드립니다.\n"+
                                "< 나와의 예상 적합도 >" + "                      < 현재 펭 평균 낙찰률 > 3.14%"
                            ];

                            this.tendencyDetail3 = ["내 성향 변화를 위한 비드톡톡 추천 AI분석가\n" +
                            "\n" +
                            "[[AI분석가 "+ this.tendencyCdReverseArr[this.tendencyCd] + "\n" +
                            "기본적으로 "+ this.tendencyCdReverseArr2[this.tendencyCd] + " 성향에 추천드리는 AI분석가입니다.\n" +
                            "하지만 현재 결과가 나오지 않아 내 투찰 성향의 개선을 위해 적극적인 변화를 원하신다면\n" +
                            "AI분석가 "+ this.tendencyCdReverseArr[this.tendencyCd] + " 을 추천드리겠습니다.]]\n" +
                            "\n" +
                            "고객님의 투찰 전략과 가장 잘 맞는 분석가를 선택하여, 보다 체계적이고 전략적인 투찰 접근을 시도해 보시는 것을 권장드립니다.\n" +
                            "\n" +
                            "비드톡톡은 언제나 고객님의 더 많은 낙찰이 되기를 기원하며 \n" +
                            "고객님의 투찰 전략과 가장 잘 맞는 비드톡톡의 AI 분석가를 선택하여 보다 체계적이고 전략적인 투찰 접근을 시도해 보시는 것을 권장드립니다. \n"
                            ];
                        } else {
                            this.tendencyCd = 1;
                            this.aiSuggest = ["펭","수리"];
                            this.tendencyDetail2_0_Percent = [87,3.21];
                            this.tendencyDetail2_1_Percent = [88,2.97];

                            this.viewStepText2 = ["대표님의 지난 투찰 기록을 바탕으로 성향 분석 결과, " + this.cmpNm + " 대표님의 <span class=\"textUnderline\">투찰 성향은 [부분적극형]입니다.</span><br>",
                                "이는 고객님께서 예상 출현 예가의 흐름을 파악하여 안정적인 투찰 포인트를 잡으려고 하지만 ",
                                "경쟁율이 낮거나 또는 특정 공고에서는 공격적인 포인트를 찾아 위험을 감수하는 전략을 선호한다는 것을 의미합니다.<br>",
                                "안정적인 예가 출현을 체크하면서도 ",
                                "단편적으로는 <span class=\"textUnderline\">위험도 감수하며 경쟁 업체 빈 구간을 확보</span>하여 낙찰률을 높이고자 합니다.<br>",
                                "이에 따라, 고객님의 투찰 전략을 더욱 세밀하게 조율하기 위해 아래와 같은 제안을 드립니다"];

                            this.viewStepText3 = ["위험 감수와 안정적인 예가 흐름 파악 사이의 균형"];

                            this.viewStepText4 = ["고객님께서는 안정적인 예가 흐름을 파악하면서도 부분적으로는 더 높은 수익을 위해 위험을 감수하는 전략을 선호합니다. 이를 위해, 시장 변화와 경쟁 업체의 움직임을 면밀히 분석하여, <span class=\"textUnderline\">경쟁율이 낮은 투찰 포인트를 선별하는 동시에 안정적인 수익을 보장할 수 있는 포인트도 확인</span>하는 것이 중요합니다."];

                            this.viewStepText5 = ["다양한 시장 상황에 대한 적응력 강화"];

                            this.viewStepText6 = ["고객님의 경우, <span class=\"textUnderline\">다양한 시장 상황에서의 적응력을 강화하여 더 많은 투찰 기회를 포착</span>하는 것이 유리합니다. 이를 위해 시장 변화에 대한 심층적인 이해와 함께, 다양한 예가 흐름과 경쟁 업체의 전략을 예측하는 능력을 키우는 것이 추천됩니다."];

                            this.viewStepText7 = ["최신 예가 자료 및 경쟁 업체 참여 자료를 바탕으로, 출현 예가와 <span class=\"textUnderline\">경쟁사와 우위한 구간에서 결정적인 투찰 포인트를 산출</span>합니다. 이런 접근 방식의 분석 능력은 공고 내에서 유리한 포인트를 확보하며, 상황에 따라 경쟁사와 예가의 <span class=\"textUnderline\">전략적 위치를 선점하길 원하는 고객님에게 적극 추천</span>됩니다.",
                                "최신 예가 자료 및 경쟁 업체 참여 자료를 바탕으로 신중한 출현 예가의 흐름을 파악하여 <span class=\"textUnderline\">전체적인 사정율 내에 고객님에게 맞는 최적의 투찰 포인트를 제시</span>합니다. <span class=\"textUnderline\">신중한 예가 정보 분석 및 전략을 선호</span>하는 고객님께 적합합니다."];

                            this.viewStepText8 = ["적극형/부분적극형/부분중립형 성향에 추천드립니다.",
                                "부분적극형/중립형/부분중립형/부분안정형 성향에 추천드립니다."];

                            this.viewStepText8_1 = [87,88];

                            this.viewStepText8_2 = [3.21,2.97];

                            this.viewStepText9 = ["기본적으로 <span class=\"textUnderline\">"+ this.tendencyCdReverseArr2[this.tendencyCd] + " 성향에 추천</span>드리는 AI분석가입니다.\n" +
                            "하지만 현재 결과가 나오지 않아 내 투찰 성향의 개선을 위해 적극적인 변화를 원하신다면\n" +
                            "<span class=\"textUnderline\">AI분석가 "+ this.tendencyCdReverseArr[this.tendencyCd] + " 를 추천드리겠습니다.</span>"];


                            this.tendencyDetail = ["대표님의 지난 투찰 기록을 바탕으로 성향 분석 결과, " + this.cmpNm + " 대표님의 투찰 성향은 [부분적극형]으로 분류되었습니다. " +
                            "이는 고객님께서 예상 출현 예가의 흐름을 파악하여 안정적인 투찰 포인트를 잡으려고 하면서도 일정 공고에서는\n" +
                            "경쟁율이 적은 포인트도 확인해 예가가 출현하지 않을 것이라는 위험을 감수하는 전략을 선호한다는 것을 의미합니다.\n" +
                            "안정적인 예가 출현을 체크하면서도 부분적으로는 위험도 감수하며 경쟁 업체 빈 구간을 확보하여 낙찰률을 높이고자 합니다. \n" +
                            "\n" +
                            "이에 따라, 고객님의 투찰 전략을 더욱 세밀하게 조율하기 위해 아래와 같은 제안을 드립니다:\n" +
                            "\n" +
                            "▶ 위험 감수와 안정적인 예가 흐름 파악 사이의 균형\n" +
                            "고객님께서는 안정적인 예가 흐름을 파악하면서도 부분적으로는 더 높은 수익을 위해 위험을 감수하는 전략을 선호합니다.\n" +
                            "이를 위해, 시장 변화와 경쟁 업체의 움직임을 면밀히 분석하여, 경쟁율이 낮은 투찰 포인트를 선별하는 동시에\n" +
                            "안정적인 수익을 보장할 수 있는 포인트도 확인하는 것이 중요합니다.\n" +
                            "\n" +
                            "▶ 다양한 시장 상황에 대한 적응력 강화\n" +
                            "고객님의 경우, 다양한 시장 상황에서의 적응력을 강화하여 더 많은 투찰 기회를 포착하는 것이 유리합니다.\n" +
                            "이를 위해 시장 변화에 대한 심층적인 이해와 함께, 다양한 예가 흐름과 경쟁 업체의 전략을 예측하는 능력을 키우는 것이 추천됩니다.\n" +
                            "\n" +
                            "비드톡톡 AI 분석가 중 고객님께 특히 추천드리는 분들은 [펭 / 수리]입니다:\n"];
                            this.tendencyDetail2 = [
                                "AI분석가 펭: \n" +
                                "최신 예가 자료 및 경쟁 업체 참여 자료를 바탕으로, 출현 예가와 경쟁사와 우위한 구간에서 결정적인 투찰 포인트를 산출합니다.\n" +
                                "이런 접근 방식의 분석 능력은 공고 내에서 유리한 포인트를 확보하며, 상황에 따라 경쟁사와 예가의 전략적 위치를 선점하길 원하는 고객님에게 적극 추천됩니다.\n" +
                                "★적극형/부분적극형/부분중립형 성향에 추천드립니다.\n" +
                                "< 나와의 예상 적합도 >" + "                      < 현재 펭 평균 낙찰률 > 3.14%"];
                            this.tendencyDetail2_1 = [
                                "AI분석가 수리: \n" +
                                "최신 예가 자료 및 경쟁 업체 참여 자료를 바탕으로 신중한 출현 예가의 흐름을 파악하여 전체적인 사정율 내에\n" +
                                "고객님에게 맞는 최적의 투찰 포인트를 제시합니다. 신중한 예가 정보 분석 및 전략을 선호하는 고객님께 적합합니다.\n" +
                                "★부분적극형/중립형/부분중립형/부분안정형 성향에 추천드립니다.\n" +
                                "< 나와의 예상 적합도 >" + "                      < 현재 수리 평균 낙찰률> 2.95%"];
                            this.tendencyDetail3 = [
                                "내 성향 변화를 위한 비드톡톡 추천 AI분석가\n" +
                                "\n" +
                                "[[AI분석가 "+ this.tendencyCdReverseArr[this.tendencyCd] + "\n" +
                                "기본적으로 "+ this.tendencyCdReverseArr2[this.tendencyCd] + " 성향에 추천드리는 AI분석가입니다.\n" +
                                "하지만 현재 결과가 나오지 않아 내 투찰 성향의 개선을 위해 적극적인 변화를 원하신다면\n" +
                                "AI분석가 "+ this.tendencyCdReverseArr[this.tendencyCd] + " 을 추천드리겠습니다.]]\n" +
                                "\n" +
                                "고객님의 투찰 전략과 가장 잘 맞는 분석가를 선택하여, 보다 체계적이고 전략적인 투찰 접근을 시도해 보시는 것을 권장드립니다.\n" +
                                "\n" +
                                "비드톡톡은 언제나 고객님의 더 많은 낙찰이 되기를 기원하며 \n" +
                                "고객님의 투찰 전략과 가장 잘 맞는 비드톡톡의 AI 분석가를 선택하여 보다 체계적이고 전략적인 투찰 접근을 시도해 보시는 것을 권장드립니다. \n"
                            ];
                        }
                        break;
                    case 2:
                        console.log("2구간");
                        if (step2 == 5) {
                            this.tendencyCd = 3;
                            this.aiSuggest = ["펭"];
                            this.tendencyDetail2_0_Percent = [95,2.97];

                            this.viewStepText2 = ["대표님의 지난 투찰 기록을 바탕으로 성향 분석 결과, " + this.cmpNm + " 대표님의 <span class=\"textUnderline\">투찰 성향은 [중립형]입니다</span>.",
                                "이는 고객님께서 예상 출현 예가의 흐름을 파악하여 안정적인 투찰 포인트를 잡으려는 경향이 있음을 의미합니다.<br>",
                                "[중립형]의 특징은 예가 흐름과 경쟁율 사이에서 균형적이면서 전략적으로 투찰 포인트를 잡는 것입니다. ",
                                "단발적인 예가 변동에 흔들리지 않으며, <span class=\"textUnderline\">장기적으로 예상 출현 예가를 확인하며 신중하게 위험 관리를 하는 접근 방식을</span>",
                                "가지고 있으나 오히려 신중하게 예측한 포인트가 <span class=\"textUnderline\">타 경쟁 업체에게 밟힐 확률이 높을 수도 있기 때문에</span> 주의해야합니다.<br>",
                                "이에 따라, 고객님의 투찰 전략을 더욱 세밀하게 조율하기 위해 아래와 같은 제안을 드립니다"];

                            this.viewStepText3 = ["예가 흐름과 경쟁율 사이에서의 균형적인 접근"];

                            this.viewStepText4 = ["고객님의 경우, 예가 흐름을 면밀히 분석하고 경쟁율을 고려하여 <span class=\"textUnderline\">안정적이면서도 전략적인 투찰 포인트를 잡는 것이 중요</span>합니다. 이러한 접근을 통해 변동성이 높은 시장 상황에서도 안정적인 수익을 창출할 수 있습니다."];

                            this.viewStepText5 = ["장기적인 예가 흐름 분석을 통한 위험 관리"];

                            this.viewStepText6 = ["<span class=\"textUnderline\">장기적인 예가 흐름을 분석하고 신중하게 위험을 관리</span>함으로써, 시장의 변화에 효과적으로 대응할 수 있는 기반을 마련하는 것이 바람직합니다. 이는 고객님께서 장기적으로 안정적인 수익을 확보할 수 있도록 도와줄 것입니다."];

                            this.viewStepText7 = ["최신 예가 자료 및 경쟁 업체 참여 자료를 바탕으로 신중한 출현 예가의 흐름을 파악하여 <span class=\"textUnderline\">전체적인 사정율 내에 고객님에게 맞는 최적의 투찰 포인트를 제시합니다. 신중한 예가 정보 분석 및 전략을 선호</span>하는 고객님께 적합합니다."],

                                this.viewStepText8 = ["부분적극형/중립형/부분중립형/부분안정형 성향에 추천드립니다."];

                            this.viewStepText8_1 = [95];

                            this.viewStepText8_2 = [2.97];

                            this.viewStepText9 = ["기본적으로 <span class=\"textUnderline\">"+ this.tendencyCdReverseArr2[this.tendencyCd] + " 성향에 추천</span>드리는 AI분석가입니다.\n" +
                            "하지만 현재 결과가 나오지 않아 내 투찰 성향의 개선을 위해 적극적인 변화를 원하신다면\n" +
                            "<span class=\"textUnderline\">AI분석가 "+ this.tendencyCdReverseArr[this.tendencyCd] + " 을 추천드리겠습니다.</span>"];

                            this.tendencyDetail = ["대표님의 지난 투찰 기록을 바탕으로 성향 분석 결과, " + this.cmpNm + " 대표님의 투찰 성향은 [중립형]으로 분류되었습니다. \n" +
                            "이는 고객님께서 예상 출현 예가의 흐름을 파악하여 안정적인 투찰 포인트를 잡으려는 경향이 있음을 의미합니다.\n" +
                            "[중립형]의 특징은 예가 흐름과 경쟁율 사이에서 균형적이면서 전략적으로 투찰 포인트를 잡는 것입니다. 단발적인 예가 변동에 흔들리지 않으며,\n" +
                            "장기적으로 예상 출현 예가를 확인하며 신중하게 위험 관리를 하는 접근 방식을 가지고 있으나\n" +
                            "오히려 신중하게 예측한 포인트가 타 경쟁 업체에게 밟힐 확률이 높을 수도 있기 때문에 주의해야합니다.\n" +
                            "\n" +
                            "이에 따라, 고객님의 투찰 전략을 더욱 세밀하게 조율하기 위해 아래와 같은 제안을 드립니다:\n" +
                            "\n" +
                            "▶ 예가 흐름과 경쟁율 사이에서의 균형적인 접근\n" +
                            "고객님의 경우, 예가 흐름을 면밀히 분석하고 경쟁율을 고려하여 안정적이면서도 전략적인 투찰 포인트를 잡는 것이 중요합니다.\n" +
                            "이러한 접근을 통해 변동성이 높은 시장 상황에서도 안정적인 수익을 창출할 수 있습니다.\n" +
                            "\n" +
                            "▶ 장기적인 예가 흐름 분석을 통한 위험 관리\n" +
                            "장기적인 예가 흐름을 분석하고 신중하게 위험을 관리함으로써, 시장의 변화에 효과적으로 대응할 수 있는 기반을 마련하는 것이 바람직합니다.\n" +
                            "이는 고객님께서 장기적으로 안정적인 수익을 확보할 수 있도록 도와줄 것입니다.\n" +
                            "\n" +
                            "내 성향과 맞는 비드톡톡 추천 AI분석가: " + this.tendencyCdArr2[this.tendencyCd][0] + "\n" +
                            "\n"];

                            this.tendencyDetail2 = [
                                "AI분석가 수리: \n" +
                                "최신 예가 자료를 바탕으로 고객님에게 맞는 최적의 투찰 포인트를 제시합니다. 신중한 예가 정보 분석 및 전략을 선호하는 고객님께 적합합니다.\n" +
                                "고객님의 투찰 전략과 가장 잘 맞는 분석가를 선택하여, 보다 체계적이고 전략적인 투찰 접근을 시도해 보시는 것을 권장드립니다\n" +
                                "★부분적극형/중립형/부분중립형/부분안정형 성향에 추천드립니다."+
                                "< 나와의 예상 적합도 >" + "                      < 현재 수리 평균 낙찰률 > 2.95%"];

                            this.tendencyDetail3 = [
                                "내 성향 변화를 위한 비드톡톡 추천 AI분석가\n" +
                                "\n" +
                                "[[AI분석가 "+ this.tendencyCdReverseArr[this.tendencyCd] + "\n" +
                                "기본적으로 "+ this.tendencyCdReverseArr2[this.tendencyCd] + " 성향에 추천드리는 AI분석가입니다.\n" +
                                "하지만 현재 결과가 나오지 않아 내 투찰 성향의 개선을 위해 적극적인 변화를 원하신다면\n" +
                                "AI분석가 "+ this.tendencyCdReverseArr[this.tendencyCd] + " 을 추천드리겠습니다.]]\n" +
                                "\n" +
                                "고객님의 투찰 전략과 가장 잘 맞는 분석가를 선택하여, 보다 체계적이고 전략적인 투찰 접근을 시도해 보시는 것을 권장드립니다.\n" +
                                "\n" +
                                "비드톡톡은 언제나 고객님의 더 많은 낙찰이 되기를 기원하며 \n" +
                                "고객님의 투찰 전략과 가장 잘 맞는 비드톡톡의 AI 분석가를 선택하여 보다 체계적이고 전략적인 투찰 접근을 시도해 보시는 것을 권장드립니다. \n"];
                        } else {
                            this.tendencyCd = 2;
                            this.aiSuggest = ["펭","수리","도그"];
                            this.tendencyDetail2_0_Percent = [89,3.21];
                            this.tendencyDetail2_1_Percent = [88,2.97];
                            this.tendencyDetail2_2_Percent = [89,2.65];

                            this.viewStepText2 = ["대표님의 지난 투찰 기록을 바탕으로 성향 분석 결과, " + this.cmpNm + " 대표님의 <span class=\"textUnderline\">투찰 성향은 [부분중립형]입니다.</span> 이는 고객님께서 중립적인 투찰 접근 방식을 선호하면서도 ",
                                "특정 상황에서는 경쟁율이 낮은 포인트를 확인하여 ",
                                "<span class=\"textUnderline\">일정 부분 위험을 감수하는 전략을 선호한다는 것</span>을 의미합니다. ",
                                "[부분중립형]의 특징은 균형 잡힌 분석을 통해 안정적인 예가 출현을 체크하면서도, <span class=\"textUnderline\">상황에 따라 소극적, 적극적인 태도로 위험도를 감수하며 낙찰률을 높이려는 접근</span> 방식입니다. ",
                                "하지만 다른 성향인 [부분적극형], [부분안정형]과 마찬가지로 일관성을 잃은 투찰 포인트를 산정하여 분배한다면 낙찰 확률을 감소시킬수도 있습니다. ",
                                "이에 따라, 고객님의 투찰 전략을 더욱 세밀하게 조율하기 위해 아래와 같은 제안을 드립니다"];

                            this.viewStepText3 = ["균형 잡힌 투찰 포인트 분석과 위험 관리"];

                            this.viewStepText4 = ["고객님의 경우, 출현 했던 예가와 출현 예상 예가, 그리고 경쟁사 확인을 통한 <span class=\"textUnderline\">균형적인 분석 포인트 산출이 중요</span>합니다. 이를 통해, 안정적인 예가 흐름을 파악하고, 동시에 부분적으로 위험을 감수하여 더 높은 수익을 창출할 수 있는 기회를 포착할 수 있습니다."];

                            this.viewStepText5 = ["적극적인 투찰 포인트 산정과 분산 투찰 전략"];

                            this.viewStepText6 = ["다양한 투찰 지점 포인트에서의 경험을 통해 시장의 변화에 대한 이해도를 높이고, 유연한 대응 전략을 수립하는 것이 바람직합니다. 또한 <span class=\"textUnderline\">적극적인 투찰 포인트 산정을 통해 좀 더 다양한 예가 출현에 대해 전략적으로 대응</span>할 수 있도록 도울 것 입니다."];

                            this.viewStepText7 = ["최신 예가 자료 및 경쟁 업체 참여 자료를 바탕으로, 출현 예가와 <span class=\"textUnderline\">경쟁사와 우위한 구간에서 결정적인 투찰 포인트를 산출</span>합니다. 이런 접근 방식의 분석 능력은 공고 내에서 유리한 포인트를 확보하며, 상황에 따라 <span class=\"textUnderline\">경쟁사와 예가의 전략적 위치를 선점</span>하길 원하는 고객님에게 적극 추천됩니다.",
                                "최신 예가 자료 및 경쟁 업체 참여 자료를 바탕으로 신중한 출현 예가의 흐름을 파악하여 <span class=\"textUnderline\">전체적인 사정율 내에 고객님에게 맞는 최적의 투찰 포인트를 제시합니다</span>. <span class=\"textUnderline\">신중한 예가 정보 분석 및 전략을 선호</span>하는 고객님께 적합합니다.",
                                "최신 예가 자료를 바탕으로 출현 예가 흐름을 파악하여 <span class=\"textUnderline\">다음 예상 출현 구간을 체크해 사정율 내 최적의 투찰 포인트를 산출합니다</span>. 핵심 예가를 파악하는 전문성을 발휘하여 예가 중심적으로 유리한 위치를 확보할 수 있으며 <span class=\"textUnderline\">균형적 투찰을 원하는</span> 고객님께 적합합니다."];

                            this.viewStepText8 = ["적극형/부분적극형/부분중립형 성향에 추천드립니다.",
                                "부분적극형/중립형/부분중립형/부분안정형 성향에 추천드립니다.",
                                "부분중립형/안정형/부분안정형 성향에 추천드립니다."];

                            this.viewStepText8_1 = [89,88,89];

                            this.viewStepText8_2 = [3.21,2.97,2.65];

                            this.viewStepText9 = ["고객님의 경우 AI 펭 / AI 수리 / AI 도그 를 추천드렸습니다. 그렇기 때문에 모든 분석가가 내 투찰 성향과 유연하게 맞으며 변화를 줄 수 있습니다. <span class=\"textUnderline\">앞서 추천드렸던 AI 분석가를 골고루 이용해 보시길 적극 추천드립니다</span>."];


                            this.tendencyDetail = ["대표님의 지난 투찰 기록을 바탕으로 성향 분석 결과, " + this.cmpNm + " 대표님의 투찰 성향은 [부분중립형]으로 분류되었습니다. \n" +
                            "이는 고객님께서 중립적인 투찰 접근 방식을 선호하면서도 특정 상황에서는 경쟁율이 낮은 포인트를 확인하여\n" +
                            "일정 부분 위험을 감수하는 전략을 선호한다는 것을 의미합니다.\n" +
                            "[부분 중립형]의 특징은 균형 잡힌 분석을 통해 안정적인 예가 출현을 체크하면서도,\n" +
                            "상황에 따라 소극적, 적극적인 태도로 위험도를 감수하며 낙찰률을 높이려는 접근 방식입니다.\n" +
                            "하지만 다른 부분 적극형, 부분 안정형과 마찬가지로 일관성을 잃은 투찰 포인트를 산정하여 분배한다면 낙찰 확률을 감소시킬수도 있습니다.\n" +
                            "\n" +
                            "이에 따라, 고객님의 투찰 전략을 더욱 세밀하게 조율하기 위해 아래와 같은 제안을 드립니다:\n" +
                            "\n" +
                            "▶ 균형 잡힌 투찰 포인트 분석과 위험 관리\n" +
                            "고객님의 경우, 출현 했던 예가와 출현 예상 예가, 그리고 경쟁사 확인을 통한 균형적인 분석 포인트 산출이 중요합니다.\n" +
                            "이를 통해, 안정적인 예가 흐름을 파악하고, 동시에 부분적으로 위험을 감수하여 더 높은 수익을 창출할 수 있는 기회를 포착할 수 있습니다.\n" +
                            "\n" +
                            "▶ 적극적인 투찰 포인트 산정과 분산 투찰 전략\n" +
                            "다양한 투찰 지점 포인트에서의 경험을 통해 시장의 변화에 대한 이해도를 높이고, 유연한 대응 전략을 수립하는 것이 바람직합니다.\n" +
                            "또한 적극적인 투찰 포인트 산정을 통해 좀 더 다양한 예가 출현에 대해 전략적으로 대응할 수 있도록 도울 것 입니다.\n" +
                            "\n" +
                            "비드톡톡 분석가 중 고객님께 특히 추천드리는 AI분석가는 [" + this.aiSuggest.join("/") + "] 입니다:\n" +
                            "\n"];
                            this.tendencyDetail2 = ["AI분석가 펭: \n" +
                            "최신 예가 자료 및 경쟁 업체 참여 자료를 바탕으로, 출현 예가와 경쟁사와 우위한 구간에서 결정적인 투찰 포인트를 산출합니다.\n" +
                            "이런 접근 방식의 분석 능력은 공고 내에서 유리한 포인트를 확보하며, 상황에 따라 경쟁사와 예가의 전략적 위치를 선점하길 원하는 고객님에게 적극 추천됩니다.\n" +
                            "★적극형/부분적극형/부분중립형 성향에 추천드립니다.\n" +
                            "< 나와의 예상 적합도 >" + "                      < 현재 펭 평균 낙찰률 > 3.14%"];

                            this.tendencyDetail2_1 = [
                                "AI분석가 수리: \n" +
                                "최신 예가 자료 및 경쟁 업체 참여 자료를 바탕으로 신 중한 출현 예가의 흐름을 파악하여 전체적인 사정율 내에 고객님에게 맞는\n" +
                                "최적의 투찰 포인트를 제시합니다. 신중한 예가 정보 분석 및 전략을 선호하는 고객님께 적합합니다.\n" +
                                "★부분적극형/중립형/부분중립형/부분안정형 성향에 추천드립니다.  \n" +
                                "< 나와의 예상 적합도 >" + "                      < 현재 수리 평균 낙찰률 > 2.95%"];

                            this.tendencyDetail2_2 = [
                                "AI분석가 도그: \n" +
                                "최신 예가 자료를 바탕으로 출현 예가 흐름을 파악하여 다음 예상 출현 구간을 체크해 사정율 내 최적의 투찰 포인트를 산출합니다.\n" +
                                "핵심 예가를 파악하는 전문성을 발휘하여 예가 중심적으로 유리한 위치를 확보할 수 있으며 균형적 투찰을 원하는 고객님께 적합합니다.\n" +
                                "★부분중립형/안정형/부분안정형 성향에 추천드립니다. \n" +
                                "< 나와의 예상 적합도 >" + "                      < 현재 도그 평균 낙찰률 > 2.77%"];

                            this.tendencyDetail3 = [
                                "내 성향 변화를 위한 비드톡톡 추천 AI분석가\n" +
                                "\n" +
                                "[[AI분석가 "+ this.tendencyCdReverseArr[this.tendencyCd] + "\n" +
                                "기본적으로 "+ this.tendencyCdReverseArr2[this.tendencyCd] + " 성향에 추천드리는 AI분석가입니다.\n" +
                                "하지만 현재 결과가 나오지 않아 내 투찰 성향의 개선을 위해 적극적인 변화를 원하신다면\n" +
                                "AI분석가 "+ this.tendencyCdReverseArr[this.tendencyCd] + " 을 추천드리겠습니다.]]\n" +
                                "\n" +
                                "고객님의 투찰 전략과 가장 잘 맞는 분석가를 선택하여, 보다 체계적이고 전략적인 투찰 접근을 시도해 보시는 것을 권장드립니다.\n" +
                                "\n" +
                                "비드톡톡은 언제나 고객님의 더 많은 낙찰이 되기를 기원하며 \n" +
                                "고객님의 투찰 전략과 가장 잘 맞는 비드톡톡의 AI 분석가를 선택하여 보다 체계적이고 전략적인 투찰 접근을 시도해 보시는 것을 권장드립니다. \n"
                            ];
                        }
                        break;
                    case 3:
                        console.log("3구간");
                        if (step2 == 4) {
                            this.tendencyCd = 5;
                            this.tendencyDetail2_0_Percent = [93,2.65];

                            this.viewStepText2 = ["대표님의 지난 투찰 기록을 바탕으로 성향 분석 결과, " + this.cmpNm + " 대표님의 <span class=\"textUnderline\">투찰 성향은 [안정형]입니다.</span><br>",
                                "이는 고객님께서 예상 예가의 출현을 중점적으로 파악하며,",
                                "산출 예가의 비중이 높은 부분에 주로 투찰",
                                "하시며,\n 동시에 예가와 근접하지 못하는 <span class=\"textUnderline\">위험을 최소화하려고 노력하신다는 것을 의미</span>합니다.\n [안정형]의 특징은 전체적으로 신중하게 정보를 파악하고 분석하며,",
                                "예정 가격 예상 출현에 집중하여 예가의 흐름을 앞서가려합니다. ",
                                "하지만 예가의 흐름을 앞서려다 보니 근소한 차이에서만 낙찰이 이루어지기 때문에 <span class=\"textUnderline\">때로는 입찰 참여함에 있어 지침을 토로</span>할 수 있습니다.<br>",
                                "이에 따라, 고객님의 투찰 전략을 더욱 세밀하게 조율하기 위해 아래와 같은 제안을 드립니다"];

                            this.viewStepText3 = ["더 높은 낙찰 확률을 위한 신중한 투찰 포인트 산정"];

                            this.viewStepText4 = ["고객님께서 예가와 근접한 부분에 집중하시되, 낙찰 확률을 높일 수 있는 <span class=\"textUnderline\">신중한 투찰 포인트를 선정하며, 좀 더 적극적인 태도의 접근이 필요</span>할 것으로 보입니다."];

                            this.viewStepText5 = ["전체적인 예가 흐름 파악을 위한 투찰 포인트 분산"];

                            this.viewStepText6 = ["동시에, <span class=\"textUnderline\">투찰 포인트를 분산시켜 전체적으로 안정적인 예가 흐름을 파악</span>하고 이에 기반한 투찰을 진행하는 전략도 고려할 수 있습니다. 이는 예상치 못한 시장 변화에도 유연하게 대응할 수 있는 기반을 마련해줄 것입니다."];

                            this.viewStepText7 = ["최신 예가 자료를 바탕으로 출현 예가 흐름을 파악하여 <span class=\"textUnderline\">다음 예상 출현 구간을 체크해 사정율 내 최적의 투찰 포인트를 산출합니다.</span> 핵심 예가를 파악하는 전문성을 발휘하여 예가 중심적으로 유리한 위치를 확보할 수 있으며 <span class=\"textUnderline\">균형적 투찰을 원하는</span> 고객님께 적합합니다."];

                            this.viewStepText8 = ["부분중립형/안정형/부분안정형 성향에 추천드립니다."];

                            this.viewStepText8_1 = [93];

                            this.viewStepText8_2 = [2.65];

                            this.viewStepText9 = ["기본적으로 "+ this.tendencyCdReverseArr2[this.tendencyCd] + " 성향에 추천드리는 AI분석가입니다. 하지만 현재 결과가 나오지 않아 내 투찰 성향의 개선을 위해 적극적인 변화를 원하신다면\n" +
                            "<span class=\"textUnderline\">AI분석가 "+ this.tendencyCdReverseArr[this.tendencyCd] + " 를 추천드리겠습니다.</span>"];

                            this.tendencyDetail = ["대표님의 지난 투찰 기록을 바탕으로 성향 분석 결과, " + this.cmpNm + " 대표님의 투찰 성향은 [안정형]으로 분류되었습니다. \n" +
                            "이는 고객님께서 예상 예가의 출현을 중점적으로 파악하며, 산출 예가의 비중이 높은 부분에 주로 투찰하시며,\n" +
                            "동시에 예가와 근접하지 못하는 위험을 최소화하려고 노력하신다는 것을 의미합니다.\n" +
                            "이와 같은 접근 방식은 전체적으로 신중하게 정보를 파악하고 분석하며, 예정 가격 예상 출현에 집중하여 예가의 흐름을 앞서가려합니다.\n" +
                            "하지만 예가의 흐름을 앞서려다 보니 근소한 차이에서만 낙찰이 이루어지기 때문에 때로는 입찰 참여함에 있어 지침을 토로할 수 있습니다. \n" +
                            "\n" +
                            "이에 따라, 고객님의 투찰 전략을 더욱 세밀하게 조율하기 위해 아래와 같은 제안을 드립니다\n" +
                            "\n" +
                            "▶ 더 높은 낙찰 확률을 위한 신중한 투찰 포인트 산정\n" +
                            "고객님께서 예가와 근접한 부분에 집중하시되, 낙찰 확률을 높일 수 있는 신중한 투찰 포인트를 선정하며, 좀 더 적극적인 태도의 접근이 필요할 것으로 보입니다.\n" +
                            "\n" +
                            "▶ 전체적인 예가 흐름 파악을 위한 투찰 포인트 분산\n" +
                            "동시에, 투찰 포인트를 분산시켜 전체적으로 안정적인 예가 흐름을 파악하고 이에 기반한 투찰을 진행하는 전략도 고려할 수 있습니다.\n" +
                            "이는 예상치 못한 시장 변화에도 유연하게 대응할 수 있는 기반을 마련해줄 것입니다."];
                            this.tendencyDetail2 = [
                                "내 성향과 맞는 비드톡톡 추천 AI분석가: " + this.tendencyCdArr2[this.tendencyCd][0] + "\n" +
                                "\n" +
                                "AI분석가 도그: \n" +
                                "최신 예가 자료를 바탕으로 출현 예가 흐름을 파악하여 다음 예상 출현 구간을 체크해 사정율 내 최적의 투찰 포인트를 산출합니다.\n" +
                                "핵심 예가를 파악하는 전문성을 발휘하여 예가 중심적으로 유리한 위치를 확보할 수 있으며 균형적 투찰을 원하는 고객님께 적합합니다.\n" +
                                "★부분중립형/안정형/부분안정형 성향에 추천드립니다.\n" +
                                "< 나와의 예상 적합도 >                        < 현재 도그 평균 낙찰률 > 2.77%"
                            ];
                            this.tendencyDetail3 =
                                ["내 성향 변화를 위한 비드톡톡 추천 AI분석가\n" +
                                "\n" +
                                "[[AI분석가 "+ this.tendencyCdReverseArr[this.tendencyCd] + "\n" +
                                "기본적으로 "+ this.tendencyCdReverseArr2[this.tendencyCd] + " 성향에 추천드리는 AI분석가입니다.\n" +
                                "하지만 현재 결과가 나오지 않아 내 투찰 성향의 개선을 위해 적극적인 변화를 원하신다면\n" +
                                "AI분석가 "+ this.tendencyCdReverseArr[this.tendencyCd] + " 을 추천드리겠습니다.]]\n" +
                                "\n" +
                                "고객님의 투찰 전략과 가장 잘 맞는 분석가를 선택하여, 보다 체계적이고 전략적인 투찰 접근을 시도해 보시는 것을 권장드립니다.\n" +
                                "\n" +
                                "비드톡톡은 언제나 고객님의 더 많은 낙찰이 되기를 기원하며 \n" +
                                "고객님의 투찰 전략과 가장 잘 맞는 비드톡톡의 AI 분석가를 선택하여 보다 체계적이고 전략적인 투찰 접근을 시도해 보시는 것을 권장드립니다. \n"];
                        } else {
                            this.tendencyCd = 4;
                            this.aiSuggest = ["수리","도그"];
                            this.tendencyDetail2_0_Percent = [87,2.97];
                            this.tendencyDetail2_1_Percent = [89,2.65];

                            this.viewStepText2 = ["대표님의 지난 투찰 기록을 바탕으로 성향 분석 결과, " + this.cmpNm + " 대표님의 <span class=\"textUnderline\">투찰 성향은 [부분안정형]입니다.</span><br>",
                                "이는 고객님께서 안정적인 수익 창출을 중시하면서도 ",
                                "일정 부분 위험을 감수하여 <span class=\"textUnderline\">다양한 투찰 기회를 탐색하는 전략을 선호</span>한다는 것을 의미합니다.<br>",
                                "[부분안정형]의 특징은 안정성과 유연성 사이에서 균형을 이루려는 접근 방식을 가지고 있지만 ",
                                "오히려 <span class=\"textUnderline\">다양한 범위 내의 균형을 추구하거나 전략적으로 투찰</span>하려 했지만 각 구간의 산출 예가 흐름의 장점의 기회를 놓칠 수가 있습니다. ",
                                "이에 따라, 고객님의 투찰 전략을 더욱 세밀하게 조율하기 위해 아래와 같은 제안을 드립니다."];

                            this.viewStepText3 = ["신중한 정보 분석을 통한 전략적 투찰 포인트 설정"];

                            this.viewStepText4 = ["고객님의 경우, 시장의 변화와 출현 예가의 흐름을 면밀히 분석하여, 안정적이면서도 잠재적인 <span class=\"textUnderline\">수익성이 높은 투찰 포인트를 선별하는 것이 중요</span>합니다. 이를 통해, 변동성이 높은 시장 상황에서도 유리한 위치를 확보할 수 있습니다."];

                            this.viewStepText5 = ["다양한 투찰 지점 포인트의 경험"];

                            this.viewStepText6 = ["안정적인 수익 창출과 함께, 다양한 투찰 지점 포인트에서의 경험을 통해 시장의 변화에 대한 이해도를 높이고,<br><span class=\"textUnderline\">유연한 대응 전략을 수립하는 것이 바람직</span>합니다.<br>이는 고객님께서 보다 넓은 범위에서 기회를 포착하고, 시장 변화에 효과적으로 대응할 수 있는 기반을 마련해 줄 것입니다."];

                            this.viewStepText7 = ["최신 예가 자료 및 경쟁 업체 참여 자료를 바탕으로 신중한 출현 예가의 흐름을 파악하여 <span class=\"textUnderline\">전체적인 사정율 내에 고객님에게 맞는 최적의 투찰 포인트를 제시</span>합니다. <span class=\"textUnderline\">신중한 예가 정보 분석 및 전략을 선호</span>하는 고객님께 적합합니다.",
                                "최신 예가 자료를 바탕으로 출현 예가 흐름을 파악하여 <span class=\"textUnderline\">다음 예상 출현 구간을 체크해 사정율 내 최적의 투찰 포인트를 산출합니다.</span> 핵심 예가를 파악하는 전문성을 발휘하여 예가 중심적으로 유리한 위치를 확보할 수 있으며 <span class=\"textUnderline\">균형적 투찰을 원하는</span> 고객님께 적합합니다."];

                            this.viewStepText8 = ["부분적극형/중립형/부분중립형/부분안정형 성향에 추천드립니다.",
                                "부분중립형/안정형/부분안정형 성향에 추천드립니다."];

                            this.viewStepText8_1 = [87,89];

                            this.viewStepText8_2 = [2.97,2.65];

                            this.viewStepText9 = ["기본적으로 <span class=\"textUnderline\">"+ this.tendencyCdReverseArr2[this.tendencyCd] + " 성향에 추천</span>드리는 AI분석가입니다.\n" +
                            "하지만 현재 결과가 나오지 않아 내 투찰 성향의 개선을 위해 적극적인 변화를 원하신다면\n" +
                            "<span class=\"textUnderline\">AI분석가 "+ this.tendencyCdReverseArr[this.tendencyCd] + " 을 추천드리겠습니다.</span>"];


                            this.tendencyDetail = ["대표님의 지난 투찰 기록을 바탕으로 성향 분석 결과, " + this.cmpNm + " 대표님의 투찰 성향은 [부분안정형]입니다\n" +
                            "이는 고객님께서 안정적인 수익 창출을 중시하면서도 일정 부분 위험을 감수하여 다양한 투찰 기회를 탐색하는 전략을 선호한다는 것을 의미합니다. \n" +
                            "[부분안정형]의 특징은 안정성과 유연성 사이에서 균형을 이루려는 접근 방식을 가지고 있지만 오히려 다양한 범위 내의\n" +
                            "균형을 추구하거나 전략적으로 투찰하려 했지만 각 구간의 산출 예가 흐름의 장점의 기회를 놓칠 수가 있습니다. \n" +
                            "\n" +
                            "이에 따라, 고객님의 투찰 전략을 더욱 세밀하게 조율하기 위해 아래와 같은 제안을 드립니다\n" +
                            "\n" +
                            "▶ 신중한 정보 분석을 통한 전략적 투찰 포인트 설정\n" +
                            "고객님의 경우, 시장의 변화와 출현 예가의 흐름을 면밀히 분석하여, 안정적이면서도 잠재적인 수익성이 높은 투찰 포인트를 선별하는 것이 중요합니다. \n" +
                            "이를 통해, 변동성이 높은 시장 상황에서도 유리한 위치를 확보할 수 있습니다.\n" +
                            "\n" +
                            "▶ 다양한 투찰 지점 포인트의 경험\n" +
                            "안정적인 수익 창출과 함께, 다양한 투찰 지점 포인트에서의 경험을 통해 시장의 변화에 대한 이해도를 높이고, 유연한 대응 전략을 수립하는 것이 바람직합니다. \n" +
                            "이는 고객님께서 보다 넓은 범위에서 기회를 포착하고, 시장 변화에 효과적으로 대응할 수 있는 기반을 마련해 줄 것입니다.\n"+
                            "비드톡톡 분석가 중 고객님께 특히 추천드리는 AI분석가는 [" + this.aiSuggest.join("/") + "] 입니다"];
                            this.tendencyDetail2 = [
                                "AI분석가 수리:\n" +
                                " 최신 예가 자료를 바탕으로 고객님에게 맞는 최적의 투찰 포인트를 제시합니다. 신중한 예가 정보 분석 및 전략을 선호하는 고객님께 적합합니다.\n" +
                                "★부분적극형/중립형/부분중립형/부분안정형 성향에 추천드립니다.\n\n" +
                                "< 나와의 예상 적합도 >" + "                      < 현재 수리 평균 낙찰률 > 2.95%"];
                            this.tendencyDetail2_1 = [
                                "AI분석가 도그:\n" +
                                "예상 출현 예가의 다양한 구간을 체크하여 최고의 투찰 포인트를 산출합니다.\n" +
                                "핵심 예가를 파악하는 전문성을 발휘하여 경쟁율이 높은 상황에서도 유리한 위치를 확보할 수 있습니다.\n" +
                                "★부분중립형/안정형/부분안정형 성향에 추천드립니다.\n\n"+
                                "\n" +
                                "< 나와의 예상 적합도 >" + "                      < 현재 도그 평균 낙찰률 > 2.77%"];
                            this.tendencyDetail3 = [
                                "내 성향 변화를 위한 비드톡톡 추천 AI분석가\n" +
                                "\n" +
                                "[[AI분석가 "+ this.tendencyCdReverseArr[this.tendencyCd] + "\n" +
                                "기본적으로 "+ this.tendencyCdReverseArr2[this.tendencyCd] + " 성향에 추천드리는 AI분석가입니다.\n" +
                                "하지만 현재 결과가 나오지 않아 내 투찰 성향의 개선을 위해 적극적인 변화를 원하신다면\n" +
                                "AI분석가 "+ this.tendencyCdReverseArr[this.tendencyCd] + " 을 추천드리겠습니다.]]\n\n" +
                                "고객님의 투찰 전략과 가장 잘 맞는 분석가를 선택하여, 보다 체계적이고 전략적인 투찰 접근을 시도해 보시는 것을 권장드립니다.\n" +
                                "\n" +
                                "비드톡톡은 언제나 고객님의 더 많은 낙찰이 되기를 기원하며 \n" +
                                "고객님의 투찰 전략과 가장 잘 맞는 비드톡톡의 AI 분석가를 선택하여 보다 체계적이고 전략적인 투찰 접근을 시도해 보시는 것을 권장드립니다. \n"];
                        }
                        break;
                    case 4:
                        console.log("4구간");
                        if (step2 == 3) {
                            this.tendencyCd = 5;
                            this.tendencyDetail2_0_Percent = [93,2.65];

                            this.viewStepText2 = ["대표님의 지난 투찰 기록을 바탕으로 성향 분석 결과, " + this.cmpNm + " 대표님의 <span class=\"textUnderline\">투찰 성향은 [안정형]입니다.</span><br>",
                                "이는 고객님께서 예상 예가의 출현을 중점적으로 파악하며,",
                                "산출 예가의 비중이 높은 부분에 주로 투찰",
                                "하시며,\n 동시에 예가와 근접하지 못하는 <span class=\"textUnderline\">위험을 최소화하려고 노력하신다는 것을 의미</span>합니다.\n [안정형]의 특징은 전체적으로 신중하게 정보를 파악하고 분석하며,",
                                "예정 가격 예상 출현에 집중하여 예가의 흐름을 앞서가려합니다. ",
                                "하지만 예가의 흐름을 앞서려다 보니 근소한 차이에서만 낙찰이 이루어지기 때문에 <span class=\"textUnderline\">때로는 입찰 참여함에 있어 지침을 토로</span>할 수 있습니다.<br>",
                                "이에 따라, 고객님의 투찰 전략을 더욱 세밀하게 조율하기 위해 아래와 같은 제안을 드립니다"];

                            this.viewStepText3 = ["더 높은 낙찰 확률을 위한 신중한 투찰 포인트 산정"];

                            this.viewStepText4 = ["고객님께서 예가와 근접한 부분에 집중하시되, 낙찰 확률을 높일 수 있는 <span class=\"textUnderline\">신중한 투찰 포인트를 선정하며, 좀 더 적극적인 태도의 접근이 필요</span>할 것으로 보입니다."];

                            this.viewStepText5 = ["전체적인 예가 흐름 파악을 위한 투찰 포인트 분산"];

                            this.viewStepText6 = ["동시에, <span class=\"textUnderline\">투찰 포인트를 분산시켜 전체적으로 안정적인 예가 흐름을 파악</span>하고 이에 기반한 투찰을 진행하는 전략도 고려할 수 있습니다. 이는 예상치 못한 시장 변화에도 유연하게 대응할 수 있는 기반을 마련해줄 것입니다."];

                            this.viewStepText7 = ["최신 예가 자료를 바탕으로 출현 예가 흐름을 파악하여 <span class=\"textUnderline\">다음 예상 출현 구간을 체크해 사정율 내 최적의 투찰 포인트를 산출합니다.</span> 핵심 예가를 파악하는 전문성을 발휘하여 예가 중심적으로 유리한 위치를 확보할 수 있으며 <span class=\"textUnderline\">균형적 투찰을 원하는</span> 고객님께 적합합니다."];

                            this.viewStepText8 = ["부분중립형/안정형/부분안정형 성향에 추천드립니다."];

                            this.viewStepText8_1 = [93];

                            this.viewStepText8_2 = [2.65];

                            this.viewStepText9 = ["기본적으로 "+ this.tendencyCdReverseArr2[this.tendencyCd] + " 성향에 추천드리는 AI분석가입니다. 하지만 현재 결과가 나오지 않아 내 투찰 성향의 개선을 위해 적극적인 변화를 원하신다면\n" +
                            "<span class=\"textUnderline\">AI분석가 "+ this.tendencyCdReverseArr[this.tendencyCd] + " 를 추천드리겠습니다.</span>"];

                            this.tendencyDetail = ["대표님의 지난 투찰 기록을 바탕으로 성향 분석 결과, " + this.cmpNm + " 대표님의 투찰 성향은 [안정형]입니다.\n" +
                            "이는 고객님께서 예상 예가의 출현을 중점적으로 파악하며, 산출 예가의 비중이 높은 부분에 주로 투찰하시며,\n" +
                            "동시에 예가와 근접하지 못하는 위험을 최소화하려고 노력하신다는 것을 의미합니다.\n" +
                            "이와 같은 접근 방식은 전체적으로 신중하게 정보를 파악하고 분석하며, 예정 가격 예상 출현에 집중하여 예가의 흐름을 앞서가려합니다.\n" +
                            "하지만 예가의 흐름을 앞서려다 보니 근소한 차이에서만 낙찰이 이루어지기 때문에 때로는 입찰 참여함에 있어 지침을 토로할 수 있습니다. \n" +
                            "\n" +
                            "이에 따라, 고객님의 투찰 전략을 더욱 세밀하게 조율하기 위해 아래와 같은 제안을 드립니다\n" +
                            "\n" +
                            "▶ 더 높은 낙찰 확률을 위한 신중한 투찰 포인트 산정\n" +
                            "고객님께서 예가와 근접한 부분에 집중하시되, 낙찰 확률을 높일 수 있는 신중한 투찰 포인트를 선정하며, 좀 더 적극적인 태도의 접근이 필요할 것으로 보입니다.\n" +
                            "\n" +
                            "▶ 전체적인 예가 흐름 파악을 위한 투찰 포인트 분산\n" +
                            "동시에, 투찰 포인트를 분산시켜 전체적으로 안정적인 예가 흐름을 파악하고 이에 기반한 투찰을 진행하는 전략도 고려할 수 있습니다.\n" +
                            "이는 예상치 못한 시장 변화에도 유연하게 대응할 수 있는 기반을 마련해줄 것입니다."];
                            this.tendencyDetail2 = [
                                "내 성향과 맞는 비드톡톡 추천 AI분석가: " + this.tendencyCdArr2[this.tendencyCd][0] + "\n" +
                                "\n" +
                                "AI분석가 도그: \n" +
                                "최신 예가 자료를 바탕으로 출현 예가 흐름을 파악하여 다음 예상 출현 구간을 체크해 사정율 내 최적의 투찰 포인트를 산출합니다.\n" +
                                "핵심 예가를 파악하는 전문성을 발휘하여 예가 중심적으로 유리한 위치를 확보할 수 있으며 균형적 투찰을 원하는 고객님께 적합합니다.\n" +
                                "★부분중립형/안정형/부분안정형 성향에 추천드립니다.\n" +
                                "< 나와의 예상 적합도 >                        < 현재 도그 평균 낙찰률 > 2.77%"
                            ];
                            this.tendencyDetail3 =
                                ["내 성향 변화를 위한 비드톡톡 추천 AI분석가\n" +
                                "\n" +
                                "[[AI분석가 "+ this.tendencyCdReverseArr[this.tendencyCd] + "\n" +
                                "기본적으로 "+ this.tendencyCdReverseArr2[this.tendencyCd] + " 성향에 추천드리는 AI분석가입니다.\n" +
                                "하지만 현재 결과가 나오지 않아 내 투찰 성향의 개선을 위해 적극적인 변화를 원하신다면\n" +
                                "AI분석가 "+ this.tendencyCdReverseArr[this.tendencyCd] + " 을 추천드리겠습니다.]]\n" +
                                "\n" +
                                "고객님의 투찰 전략과 가장 잘 맞는 분석가를 선택하여, 보다 체계적이고 전략적인 투찰 접근을 시도해 보시는 것을 권장드립니다.\n" +
                                "\n" +
                                "비드톡톡은 언제나 고객님의 더 많은 낙찰이 되기를 기원하며 \n" +
                                "고객님의 투찰 전략과 가장 잘 맞는 비드톡톡의 AI 분석가를 선택하여 보다 체계적이고 전략적인 투찰 접근을 시도해 보시는 것을 권장드립니다. \n"];
                        } else {
                            this.tendencyCd = 4;
                            this.aiSuggest = ["수리","도그"];
                            this.tendencyDetail2_0_Percent = [87,2.97];
                            this.tendencyDetail2_1_Percent = [89,2.65];

                            this.viewStepText2 = ["대표님의 지난 투찰 기록을 바탕으로 성향 분석 결과, " + this.cmpNm + " 대표님의 <span class=\"textUnderline\">투찰 성향은 [부분안정형]입니다.</span><br>",
                                "이는 고객님께서 안정적인 수익 창출을 중시하면서도 ",
                                "일정 부분 위험을 감수하여 <span class=\"textUnderline\">다양한 투찰 기회를 탐색하는 전략을 선호</span>한다는 것을 의미합니다.<br>",
                                "[부분안정형]의 특징은 안정성과 유연성 사이에서 균형을 이루려는 접근 방식을 가지고 있지만 ",
                                "오히려 <span class=\"textUnderline\">다양한 범위 내의 균형을 추구하거나 전략적으로 투찰</span>하려 했지만 각 구간의 산출 예가 흐름의 장점의 기회를 놓칠 수가 있습니다. ",
                                "이에 따라, 고객님의 투찰 전략을 더욱 세밀하게 조율하기 위해 아래와 같은 제안을 드립니다."];

                            this.viewStepText3 = ["신중한 정보 분석을 통한 전략적 투찰 포인트 설정"];

                            this.viewStepText4 = ["고객님의 경우, 시장의 변화와 출현 예가의 흐름을 면밀히 분석하여, 안정적이면서도 잠재적인 <span class=\"textUnderline\">수익성이 높은 투찰 포인트를 선별하는 것이 중요</span>합니다. 이를 통해, 변동성이 높은 시장 상황에서도 유리한 위치를 확보할 수 있습니다."];

                            this.viewStepText5 = ["다양한 투찰 지점 포인트의 경험"];

                            this.viewStepText6 = ["안정적인 수익 창출과 함께, 다양한 투찰 지점 포인트에서의 경험을 통해 시장의 변화에 대한 이해도를 높이고,<br><span class=\"textUnderline\">유연한 대응 전략을 수립하는 것이 바람직</span>합니다.<br>이는 고객님께서 보다 넓은 범위에서 기회를 포착하고, 시장 변화에 효과적으로 대응할 수 있는 기반을 마련해 줄 것입니다."];

                            this.viewStepText7 = ["최신 예가 자료 및 경쟁 업체 참여 자료를 바탕으로 신중한 출현 예가의 흐름을 파악하여 <span class=\"textUnderline\">전체적인 사정율 내에 고객님에게 맞는 최적의 투찰 포인트를 제시</span>합니다. <span class=\"textUnderline\">신중한 예가 정보 분석 및 전략을 선호</span>하는 고객님께 적합합니다.",
                                "최신 예가 자료를 바탕으로 출현 예가 흐름을 파악하여 <span class=\"textUnderline\">다음 예상 출현 구간을 체크해 사정율 내 최적의 투찰 포인트를 산출합니다.</span> 핵심 예가를 파악하는 전문성을 발휘하여 예가 중심적으로 유리한 위치를 확보할 수 있으며 <span class=\"textUnderline\">균형적 투찰을 원하는</span> 고객님께 적합합니다."];

                            this.viewStepText8 = ["부분적극형/중립형/부분중립형/부분안정형 성향에 추천드립니다.",
                                "부분중립형/안정형/부분안정형 성향에 추천드립니다."];

                            this.viewStepText8_1 = [87,89];

                            this.viewStepText8_2 = [2.97,2.65];

                            this.viewStepText9 = ["기본적으로 <span class=\"textUnderline\">"+ this.tendencyCdReverseArr2[this.tendencyCd] + " 성향에 추천</span>드리는 AI분석가입니다.\n" +
                            "하지만 현재 결과가 나오지 않아 내 투찰 성향의 개선을 위해 적극적인 변화를 원하신다면\n" +
                            "<span class=\"textUnderline\">AI분석가 "+ this.tendencyCdReverseArr[this.tendencyCd] + " 을 추천드리겠습니다.</span>"];


                            this.tendencyDetail = ["대표님의 지난 투찰 기록을 바탕으로 성향 분석 결과, " + this.cmpNm + " 대표님의 투찰 성향은 [부분안정형]으로 분류되었습니다. \n" +
                            "이는 고객님께서 안정적인 수익 창출을 중시하면서도 일정 부분 위험을 감수하여 다양한 투찰 기회를 탐색하는 전략을 선호한다는 것을 의미합니다. \n" +
                            "[부분안정형]의 특징은 안정성과 유연성 사이에서 균형을 이루려는 접근 방식을 가지고 있지만 오히려 다양한 범위 내의\n" +
                            "균형을 추구하거나 전략적으로 투찰하려 했지만 각 구간의 산출 예가 흐름의 장점의 기회를 놓칠 수가 있습니다. \n" +
                            "\n" +
                            "이에 따라, 고객님의 투찰 전략을 더욱 세밀하게 조율하기 위해 아래와 같은 제안을 드립니다\n" +
                            "\n" +
                            "▶ 신중한 정보 분석을 통한 전략적 투찰 포인트 설정\n" +
                            "고객님의 경우, 시장의 변화와 출현 예가의 흐름을 면밀히 분석하여, 안정적이면서도 잠재적인 수익성이 높은 투찰 포인트를 선별하는 것이 중요합니다. \n" +
                            "이를 통해, 변동성이 높은 시장 상황에서도 유리한 위치를 확보할 수 있습니다.\n" +
                            "\n" +
                            "▶ 다양한 투찰 지점 포인트의 경험\n" +
                            "안정적인 수익 창출과 함께, 다양한 투찰 지점 포인트에서의 경험을 통해 시장의 변화에 대한 이해도를 높이고, 유연한 대응 전략을 수립하는 것이 바람직합니다. \n" +
                            "이는 고객님께서 보다 넓은 범위에서 기회를 포착하고, 시장 변화에 효과적으로 대응할 수 있는 기반을 마련해 줄 것입니다.\n"+
                            "비드톡톡 분석가 중 고객님께 특히 추천드리는 AI분석가는 [" + this.aiSuggest.join("/") + "] 입니다"];
                            this.tendencyDetail2 = [
                                "AI분석가 수리:\n" +
                                " 최신 예가 자료를 바탕으로 고객님에게 맞는 최적의 투찰 포인트를 제시합니다. 신중한 예가 정보 분석 및 전략을 선호하는 고객님께 적합합니다.\n" +
                                "★부분적극형/중립형/부분중립형/부분안정형 성향에 추천드립니다.\n\n" +
                                "< 나와의 예상 적합도 >" + "                      < 현재 수리 평균 낙찰률 > 2.95%\n"];
                            this.tendencyDetail2_1 = [
                                "AI분석가 도그:\n" +
                                "예상 출현 예가의 다양한 구간을 체크하여 최고의 투찰 포인트를 산출합니다.\n" +
                                "핵심 예가를 파악하는 전문성을 발휘하여 경쟁율이 높은 상황에서도 유리한 위치를 확보할 수 있습니다.\n" +
                                "★부분중립형/안정형/부분안정형 성향에 추천드립니다.\n\n"+
                                "\n" +
                                "< 나와의 예상 적합도 >" + "                      < 현재 도그 평균 낙찰률 > 2.77%\n"];
                            this.tendencyDetail3 = [
                                "내 성향 변화를 위한 비드톡톡 추천 AI분석가\n" +
                                "\n" +
                                "[[AI분석가 "+ this.tendencyCdReverseArr[this.tendencyCd] + "\n" +
                                "기본적으로 "+ this.tendencyCdReverseArr2[this.tendencyCd] + " 성향에 추천드리는 AI분석가입니다.\n" +
                                "하지만 현재 결과가 나오지 않아 내 투찰 성향의 개선을 위해 적극적인 변화를 원하신다면\n" +
                                "AI분석가 "+ this.tendencyCdReverseArr[this.tendencyCd] + " 을 추천드리겠습니다.]]\n" +
                                "고객님의 투찰 전략과 가장 잘 맞는 분석가를 선택하여, 보다 체계적이고 전략적인 투찰 접근을 시도해 보시는 것을 권장드립니다.\n" +
                                "\n" +
                                "비드톡톡은 언제나 고객님의 더 많은 낙찰이 되기를 기원하며 \n" +
                                "고객님의 투찰 전략과 가장 잘 맞는 비드톡톡의 AI 분석가를 선택하여 보다 체계적이고 전략적인 투찰 접근을 시도해 보시는 것을 권장드립니다. \n"];
                        }
                        break;
                    case 5:
                        console.log("5구간");
                        if (step2 == 2) {
                            this.tendencyCd = 3;
                            this.aiSuggest = ["펭"];
                            this.tendencyDetail2_0_Percent = [95,2.97];

                            this.viewStepText2 = ["대표님의 지난 투찰 기록을 바탕으로 성향 분석 결과, " + this.cmpNm + " 대표님의 <span class=\"textUnderline\">투찰 성향은 [중립형]입니다</span>.",
                                "이는 고객님께서 예상 출현 예가의 흐름을 파악하여 안정적인 투찰 포인트를 잡으려는 경향이 있음을 의미합니다.<br>",
                                "[중립형]의 특징은 예가 흐름과 경쟁율 사이에서 균형적이면서 전략적으로 투찰 포인트를 잡는 것입니다. ",
                                "단발적인 예가 변동에 흔들리지 않으며, <span class=\"textUnderline\">장기적으로 예상 출현 예가를 확인하며 신중하게 위험 관리를 하는 접근 방식을</span>",
                                "가지고 있으나 오히려 신중하게 예측한 포인트가 <span class=\"textUnderline\">타 경쟁 업체에게 밟힐 확률이 높을 수도 있기 때문에</span> 주의해야합니다.<br>",
                                "이에 따라, 고객님의 투찰 전략을 더욱 세밀하게 조율하기 위해 아래와 같은 제안을 드립니다"];

                            this.viewStepText3 = ["예가 흐름과 경쟁율 사이에서의 균형적인 접근"];

                            this.viewStepText4 = ["고객님의 경우, 예가 흐름을 면밀히 분석하고 경쟁율을 고려하여 <span class=\"textUnderline\">안정적이면서도 전략적인 투찰 포인트를 잡는 것이 중요</span>합니다. 이러한 접근을 통해 변동성이 높은 시장 상황에서도 안정적인 수익을 창출할 수 있습니다."];

                            this.viewStepText5 = ["장기적인 예가 흐름 분석을 통한 위험 관리"];

                            this.viewStepText6 = ["<span class=\"textUnderline\">장기적인 예가 흐름을 분석하고 신중하게 위험을 관리</span>함으로써, 시장의 변화에 효과적으로 대응할 수 있는 기반을 마련하는 것이 바람직합니다. 이는 고객님께서 장기적으로 안정적인 수익을 확보할 수 있도록 도와줄 것입니다."];

                            this.viewStepText7 = ["최신 예가 자료 및 경쟁 업체 참여 자료를 바탕으로 신중한 출현 예가의 흐름을 파악하여 전체적인 사정율 내에 고객님에게 맞는 최적의 투찰 포인트를 제시합니다. 신중한 예가 정보 분석 및 전략을 선호하는 고객님께 적합합니다."],

                                this.viewStepText8 = ["부분적극형/중립형/부분중립형/부분안정형 성향에 추천드립니다."];

                            this.viewStepText8_1 = [95];

                            this.viewStepText8_2 = [2.97];

                            this.viewStepText9 = ["기본적으로 <span class=\"textUnderline\">"+ this.tendencyCdReverseArr2[this.tendencyCd] + " 성향에 추천</span>드리는 AI분석가입니다.\n" +
                            "하지만 현재 결과가 나오지 않아 내 투찰 성향의 개선을 위해 적극적인 변화를 원하신다면\n" +
                            "<span class=\"textUnderline\">AI분석가 "+ this.tendencyCdReverseArr[this.tendencyCd] + " 을 추천드리겠습니다.</span>"];

                            this.tendencyDetail = ["대표님의 지난 투찰 기록을 바탕으로 성향 분석 결과, " + this.cmpNm + " 대표님의 투찰 성향은 [중립형]으로 분류되었습니다. \n" +
                            "이는 고객님께서 예상 출현 예가의 흐름을 파악하여 안정적인 투찰 포인트를 잡으려는 경향이 있음을 의미합니다.\n" +
                            "[중립형]의 특징은 예가 흐름과 경쟁율 사이에서 균형적이면서 전략적으로 투찰 포인트를 잡는 것입니다. 단발적인 예가 변동에 흔들리지 않으며,\n" +
                            "장기적으로 예상 출현 예가를 확인하며 신중하게 위험 관리를 하는 접근 방식을 가지고 있으나\n" +
                            "오히려 신중하게 예측한 포인트가 타 경쟁 업체에게 밟힐 확률이 높을 수도 있기 때문에 주의해야합니다.\n" +
                            "\n" +
                            "이에 따라, 고객님의 투찰 전략을 더욱 세밀하게 조율하기 위해 아래와 같은 제안을 드립니다:\n" +
                            "\n" +
                            "▶ 예가 흐름과 경쟁율 사이에서의 균형적인 접근\n" +
                            "고객님의 경우, 예가 흐름을 면밀히 분석하고 경쟁율을 고려하여 안정적이면서도 전략적인 투찰 포인트를 잡는 것이 중요합니다.\n" +
                            "이러한 접근을 통해 변동성이 높은 시장 상황에서도 안정적인 수익을 창출할 수 있습니다.\n" +
                            "\n" +
                            "▶ 장기적인 예가 흐름 분석을 통한 위험 관리\n" +
                            "장기적인 예가 흐름을 분석하고 신중하게 위험을 관리함으로써, 시장의 변화에 효과적으로 대응할 수 있는 기반을 마련하는 것이 바람직합니다.\n" +
                            "이는 고객님께서 장기적으로 안정적인 수익을 확보할 수 있도록 도와줄 것입니다.\n" +
                            "\n" +
                            "내 성향과 맞는 비드톡톡 추천 AI분석가: " + this.tendencyCdArr2[this.tendencyCd][0] + "\n" +
                            "\n"];

                            this.tendencyDetail2 = [
                                "AI분석가 수리: \n" +
                                "최신 예가 자료를 바탕으로 고객님에게 맞는 최적의 투찰 포인트를 제시합니다. 신중한 예가 정보 분석 및 전략을 선호하는 고객님께 적합합니다.\n" +
                                "고객님의 투찰 전략과 가장 잘 맞는 분석가를 선택하여, 보다 체계적이고 전략적인 투찰 접근을 시도해 보시는 것을 권장드립니다\n" +
                                "★부분적극형/중립형/부분중립형/부분안정형 성향에 추천드립니다."+
                                "< 나와의 예상 적합도 >" + "                      < 현재 수리 평균 낙찰률 > 2.95%"];

                            this.tendencyDetail3 = [
                                "내 성향 변화를 위한 비드톡톡 추천 AI분석가\n" +
                                "\n" +
                                "[[AI분석가 "+ this.tendencyCdReverseArr[this.tendencyCd] + "\n" +
                                "기본적으로 "+ this.tendencyCdReverseArr2[this.tendencyCd] + " 성향에 추천드리는 AI분석가입니다.\n" +
                                "하지만 현재 결과가 나오지 않아 내 투찰 성향의 개선을 위해 적극적인 변화를 원하신다면\n" +
                                "AI분석가 "+ this.tendencyCdReverseArr[this.tendencyCd] + " 을 추천드리겠습니다.]]\n" +
                                "\n" +
                                "고객님의 투찰 전략과 가장 잘 맞는 분석가를 선택하여, 보다 체계적이고 전략적인 투찰 접근을 시도해 보시는 것을 권장드립니다.\n" +
                                "\n" +
                                "비드톡톡은 언제나 고객님의 더 많은 낙찰이 되기를 기원하며 \n" +
                                "고객님의 투찰 전략과 가장 잘 맞는 비드톡톡의 AI 분석가를 선택하여 보다 체계적이고 전략적인 투찰 접근을 시도해 보시는 것을 권장드립니다. \n"];
                        } else {
                            this.tendencyCd = 2;
                            this.aiSuggest = ["펭","수리","도그"];
                            this.tendencyDetail2_0_Percent = [89,3.21];
                            this.tendencyDetail2_1_Percent = [88,2.97];
                            this.tendencyDetail2_2_Percent = [89,2.65];

                            this.viewStepText2 = ["대표님의 지난 투찰 기록을 바탕으로 성향 분석 결과, " + this.cmpNm + " 대표님의 <span class=\"textUnderline\">투찰 성향은 [부분중립형]입니다.</span> 이는 고객님께서 중립적인 투찰 접근 방식을 선호하면서도 ",
                                "특정 상황에서는 경쟁율이 낮은 포인트를 확인하여 ",
                                "<span class=\"textUnderline\">일정 부분 위험을 감수하는 전략을 선호한다는 것</span>을 의미합니다. ",
                                "[부분중립형]의 특징은 균형 잡힌 분석을 통해 안정적인 예가 출현을 체크하면서도, <span class=\"textUnderline\">상황에 따라 소극적, 적극적인 태도로 위험도를 감수하며 낙찰률을 높이려는 접근</span> 방식입니다. ",
                                "하지만 다른 성향인 [부분적극형], [부분안정형]과 마찬가지로 일관성을 잃은 투찰 포인트를 산정하여 분배한다면 낙찰 확률을 감소시킬수도 있습니다. ",
                                "이에 따라, 고객님의 투찰 전략을 더욱 세밀하게 조율하기 위해 아래와 같은 제안을 드립니다"];

                            this.viewStepText3 = ["균형 잡힌 투찰 포인트 분석과 위험 관리"];

                            this.viewStepText4 = ["고객님의 경우, 출현 했던 예가와 출현 예상 예가, 그리고 경쟁사 확인을 통한 <span class=\"textUnderline\">균형적인 분석 포인트 산출이 중요</span>합니다. 이를 통해, 안정적인 예가 흐름을 파악하고, 동시에 부분적으로 위험을 감수하여 더 높은 수익을 창출할 수 있는 기회를 포착할 수 있습니다."];

                            this.viewStepText5 = ["적극적인 투찰 포인트 산정과 분산 투찰 전략"];

                            this.viewStepText6 = ["다양한 투찰 지점 포인트에서의 경험을 통해 시장의 변화에 대한 이해도를 높이고, 유연한 대응 전략을 수립하는 것이 바람직합니다. 또한 <span class=\"textUnderline\">적극적인 투찰 포인트 산정을 통해 좀 더 다양한 예가 출현에 대해 전략적으로 대응</span>할 수 있도록 도울 것 입니다."];

                            this.viewStepText7 = ["최신 예가 자료 및 경쟁 업체 참여 자료를 바탕으로, 출현 예가와 <span class=\"textUnderline\">경쟁사와 우위한 구간에서 결정적인 투찰 포인트를 산출</span>합니다. 이런 접근 방식의 분석 능력은 공고 내에서 유리한 포인트를 확보하며, 상황에 따라 <span class=\"textUnderline\">경쟁사와 예가의 전략적 위치를 선점</span>하길 원하는 고객님에게 적극 추천됩니다.",
                                "최신 예가 자료 및 경쟁 업체 참여 자료를 바탕으로 신중한 출현 예가의 흐름을 파악하여 <span class=\"textUnderline\">전체적인 사정율 내에 고객님에게 맞는 최적의 투찰 포인트를 제시합니다</span>. <span class=\"textUnderline\">신중한 예가 정보 분석 및 전략을 선호</span>하는 고객님께 적합합니다.",
                                "최신 예가 자료를 바탕으로 출현 예가 흐름을 파악하여 <span class=\"textUnderline\">다음 예상 출현 구간을 체크해 사정율 내 최적의 투찰 포인트를 산출합니다</span>. 핵심 예가를 파악하는 전문성을 발휘하여 예가 중심적으로 유리한 위치를 확보할 수 있으며 <span class=\"textUnderline\">균형적 투찰을 원하는</span> 고객님께 적합합니다."];

                            this.viewStepText8 = ["적극형/부분적극형/부분중립형 성향에 추천드립니다.",
                                "부분적극형/중립형/부분중립형/부분안정형 성향에 추천드립니다.",
                                "부분중립형/안정형/부분안정형 성향에 추천드립니다."];

                            this.viewStepText8_1 = [89,88,89];

                            this.viewStepText8_2 = [3.21,2.97,2.65];

                            this.viewStepText9 = ["고객님의 경우 AI 펭 / AI 수리 / AI 도그 를 추천드렸습니다. 그렇기 때문에 모든 분석가가 내 투찰 성향과 유연하게 맞으며 변화를 줄 수 있습니다. <span class=\"textUnderline\">앞서 추천드렸던 AI 분석가를 골고루 이용해 보시길 적극 추천드립니다</span>."];

                            this.tendencyDetail = ["대표님의 지난 투찰 기록을 바탕으로 성향 분석 결과, " + this.cmpNm + " 대표님의 투찰 성향은 [부분중립형]으로 분류되었습니다. \n" +
                            "이는 고객님께서 중립적인 투찰 접근 방식을 선호하면서도 특정 상황에서는 경쟁율이 낮은 포인트를 확인하여\n" +
                            "일정 부분 위험을 감수하는 전략을 선호한다는 것을 의미합니다.\n" +
                            "[부분 중립형]의 특징은 균형 잡힌 분석을 통해 안정적인 예가 출현을 체크하면서도,\n" +
                            "상황에 따라 소극적, 적극적인 태도로 위험도를 감수하며 낙찰률을 높이려는 접근 방식입니다.\n" +
                            "하지만 다른 부분 적극형, 부분 안정형과 마찬가지로 일관성을 잃은 투찰 포인트를 산정하여 분배한다면 낙찰 확률을 감소시킬수도 있습니다.\n" +
                            "\n" +
                            "이에 따라, 고객님의 투찰 전략을 더욱 세밀하게 조율하기 위해 아래와 같은 제안을 드립니다:\n" +
                            "\n" +
                            "▶ 균형 잡힌 투찰 포인트 분석과 위험 관리\n" +
                            "고객님의 경우, 출현 했던 예가와 출현 예상 예가, 그리고 경쟁사 확인을 통한 균형적인 분석 포인트 산출이 중요합니다.\n" +
                            "이를 통해, 안정적인 예가 흐름을 파악하고, 동시에 부분적으로 위험을 감수하여 더 높은 수익을 창출할 수 있는 기회를 포착할 수 있습니다.\n" +
                            "\n" +
                            "▶ 적극적인 투찰 포인트 산정과 분산 투찰 전략\n" +
                            "다양한 투찰 지점 포인트에서의 경험을 통해 시장의 변화에 대한 이해도를 높이고, 유연한 대응 전략을 수립하는 것이 바람직합니다.\n" +
                            "또한 적극적인 투찰 포인트 산정을 통해 좀 더 다양한 예가 출현에 대해 전략적으로 대응할 수 있도록 도울 것 입니다.\n" +
                            "\n" +
                            "비드톡톡 분석가 중 고객님께 특히 추천드리는 AI분석가는 [" + this.aiSuggest.join("/") + "] 입니다:\n" +
                            "\n"];
                            this.tendencyDetail2 = ["AI분석가 펭: \n" +
                            "최신 예가 자료 및 경쟁 업체 참여 자료를 바탕으로, 출현 예가와 경쟁사와 우위한 구간에서 결정적인 투찰 포인트를 산출합니다.\n" +
                            "이런 접근 방식의 분석 능력은 공고 내에서 유리한 포인트를 확보하며, 상황에 따라 경쟁사와 예가의 전략적 위치를 선점하길 원하는 고객님에게 적극 추천됩니다.\n" +
                            "★적극형/부분적극형/부분중립형 성향에 추천드립니다.\n" +
                            "< 나와의 예상 적합도 >" + "                      < 현재 펭 평균 낙찰률 > 3.14%"];

                            this.tendencyDetail2_1 = [
                                "AI분석가 수리: \n" +
                                "최신 예가 자료 및 경쟁 업체 참여 자료를 바탕으로 신 중한 출현 예가의 흐름을 파악하여 전체적인 사정율 내에 고객님에게 맞는\n" +
                                "최적의 투찰 포인트를 제시합니다. 신중한 예가 정보 분석 및 전략을 선호하는 고객님께 적합합니다.\n" +
                                "★부분적극형/중립형/부분중립형/부분안정형 성향에 추천드립니다.  \n" +
                                "< 나와의 예상 적합도 >" + "                      < 현재 수리 평균 낙찰률 > 2.95%"];

                            this.tendencyDetail2_2 = [
                                "AI분석가 도그: \n" +
                                "최신 예가 자료를 바탕으로 출현 예가 흐름을 파악하여 다음 예상 출현 구간을 체크해 사정율 내 최적의 투찰 포인트를 산출합니다.\n" +
                                "핵심 예가를 파악하는 전문성을 발휘하여 예가 중심적으로 유리한 위치를 확보할 수 있으며 균형적 투찰을 원하는 고객님께 적합합니다.\n" +
                                "★부분중립형/안정형/부분안정형 성향에 추천드립니다. \n" +
                                "< 나와의 예상 적합도 >" + "                      < 현재 도그 평균 낙찰률 > 2.77%"];

                            this.tendencyDetail3 = [
                                "내 성향 변화를 위한 비드톡톡 추천 AI분석가\n" +
                                "\n" +
                                "[[AI분석가 "+ this.tendencyCdReverseArr[this.tendencyCd] + "\n" +
                                "기본적으로 "+ this.tendencyCdReverseArr2[this.tendencyCd] + " 성향에 추천드리는 AI분석가입니다.\n" +
                                "하지만 현재 결과가 나오지 않아 내 투찰 성향의 개선을 위해 적극적인 변화를 원하신다면\n" +
                                "AI분석가 "+ this.tendencyCdReverseArr[this.tendencyCd] + " 을 추천드리겠습니다.]]\n" +
                                "\n" +
                                "고객님의 투찰 전략과 가장 잘 맞는 분석가를 선택하여, 보다 체계적이고 전략적인 투찰 접근을 시도해 보시는 것을 권장드립니다.\n" +
                                "\n" +
                                "비드톡톡은 언제나 고객님의 더 많은 낙찰이 되기를 기원하며 \n" +
                                "고객님의 투찰 전략과 가장 잘 맞는 비드톡톡의 AI 분석가를 선택하여 보다 체계적이고 전략적인 투찰 접근을 시도해 보시는 것을 권장드립니다. \n"
                            ];
                        }
                        break;
                    case 6:
                        console.log("6구간");
                        if (step2 == 1) {
                            this.tendencyCd = 0;
                            this.tendencyDetail2_0_Percent = [93,3.21];
                            if (imsi) {
                                this.tendencyDetail = ["고객님의 투찰 성향 분석 결과, [적극형]으로 분류되었습니다.\n" +
                                "이는 고객님께서 낙찰 확률의 잠재력이 높은 곳으로 투찰하며 새로운 예가 출현의 기회를 잡으려 하고, 흙 속의 진주를 발견하려는 성향을 가지고 있다는 것을 의미합니다.\n" +
                                "경쟁 업체의 진입이 적으면서도 예상 출현 예가의 흐름을 확인, 파악하며, 분산 투자보다는 집중 투찰 포인트를 선정하여 끈기 있게 투찰하면서 낙찰확률을 높이려고 합니다.\n" +
                                "[적극형]의 특징은 도전적이며 잠재력 높은 투찰 포인트를 찾아내려는 전략적 접근입니다.",
                                    "이에 따라, 고객님의 투찰 전략을 더욱 세밀하게 조율하기 위해 아래와 같은 제안을 드립니다:\n" +
                                    "\n" +
                                    "▶ 경쟁 업체 및 예가 출현의 기회 분석\n" +
                                    "고객님의 경우, 경쟁 업체의 진입이 적고 잠재력 높은 출현 예가의 흐름을 면밀히 분석하여, 새롭고 도전적인 투찰 포인트를 발견하는 것이 중요합니다.\n" +
                                    "이를 통해, 고객님께서는 시장에서 유리한 위치를 확보하며 낙찰 확률을 높일 수 있습니다.\n" +
                                    "▶ 집중 투찰 포인트의 선정 및 끈기 있는 투찰\n" +
                                    "고객님은 분산 투자보다는 하나 혹은 소수의 집중된 투찰 포인트에 끈기 있게 투찰함으로써 낙찰 확률을 높이려는 전략을 선호합니다.\n" +
                                    "이러한 접근은 고객님께서 시장 변화에 민첩하게 대응하고, 장기적으로 안정적인 수익을 창출할 수 있는 기반을 마련해 줄 것입니다.",
                                    "비드톡톡 AI 분석가 중 고객님께 특히 추천드리는 분은 AI분석가 펭입니다:\n" +
                                    "\n" +
                                    "AI분석가 펭: 최신 예가 자료 및 경쟁 업체 참여 자료를 바탕으로, 출현 예가와 더 광범위한 구간에서 결정적인 투찰 포인트를 산출합니다.\n" +
                                    "이런 접근 방식의 분석 능력은 공고 내에서 유리한 포인트를 확보하며, 상황에 따라 경쟁사와 예가의 전략적 위치를 선점하길 원하는 고객님에게 적극 추천됩니다.\n" +
                                    "고객님의 투찰 전략과 가장 잘 맞는 분석가를 선택하여, 보다 도전적이고 전략적인 투찰 접근을 시도해 보시는 것을 권장드립니다\n"
                                ];
                            } else {

                                this.viewStepText2 = ["대표님의 지난 투찰 기록을 바탕으로 성향 분석 결과, " + this.cmpNm + " 대표님의 <span class=\"textUnderline\">투찰 성향은 [적극형]입니다.</span>",
                                    "이는 고객님께서 낙찰 확률의 잠재력이 높은 곳으로 투찰하며 <span class=\"textUnderline\">새로운 예가 출현의 기회를 잡으려 하고,</span> ",
                                    "흙 속의 진주를 발견하려는 성향을 가지고 있다는 것을 의미합니다.<br>",
                                    "[적극형]의 특징은 경쟁 업체의 진입이 적으면서도 예상 출현 예가의 흐름을 확인하고 파악하며, 분산 투자보다는 <span class=\"textUnderline\">집중 투찰 포인트를 도전적으로 찾아내는 전략</span>입니다. ",
                                    "하지만 오히려 도전적인 부분과 잠재력이 높은 포인트를 찾다보니 예가의 흐름을 따라가지 못한다는 말을 듣기도 합니다. ",
                                    "이에 따라, 고객님의 투찰 전략을 더욱 세밀하게 조율하기 위해 아래와 같은 제안을 드립니다"];

                                this.viewStepText3 = ["경쟁 업체 및 예가 출현의 기회 분석"];

                                this.viewStepText4 = ["고객님의 경우, 경쟁 업체의 진입이 적고 잠재력 높은 출현 예가의 흐름을 면밀히 분석하여, <span class=\"textUnderline\">새롭고 도전적인 투찰 포인트를 발견하는 것이 중요</span>합니다. 이를 통해, 고객님께서는 시장에서 유리한 위치를 확보하며 낙찰 확률을 높일 수 있습니다."];

                                this.viewStepText5 = ["집중 투찰 포인트의 선정 및 끈기 있는 투찰"];

                                this.viewStepText6 = ["고객님은 분산 투자보다는 <span class=\"textUnderline\">하나 혹은 소수의 집중된 투찰 포인트에 끈기 있게 투찰함으로써 낙찰 확률을 높이려는 전략을 선호</span>합니다. 이러한 접근은 고객님께서 시장 변화에 민첩하게 대응하고, 장기적으로 안정적인 수익을 창출할 수 있는 기반을 마련해 줄 것입니다."];

                                this.viewStepText7 = ["최신 예가 자료 및 경쟁 업체 참여 자료를 바탕으로, 출현 예가와 <span class=\"textUnderline\">경쟁사와 우위한 구간에서 결정적인 투찰 포인트를 산출</span>합니다. 이런 접근 방식의 분석 능력은 공고 내에서 유리한 포인트를 확보하며, 상황에 따라 경쟁사와 예가의 <span class=\"textUnderline\">전략적 위치를 선점하길 원하는 고객님에게 적극 추천</span>됩니다."];

                                this.viewStepText8 = ["적극형/부분적극형/부분중립형 성향에 추천드립니다."];

                                this.viewStepText8_1 = [93];

                                this.viewStepText8_2 = [3.21];

                                this.viewStepText9 = ["기본적으로 <span class=\"empha textUnderline\">"+ this.tendencyCdReverseArr2[this.tendencyCd] + " 성향에 추천</span>드리는 AI분석가입니다.\n" +
                                "하지만 현재 결과가 나오지 않아 내 투찰 성향의 개선을 위해 적극적인 변화를 원하신다면\n" +
                                "<span class=\"empha textUnderline\">AI분석가 "+ this.tendencyCdReverseArr[this.tendencyCd] + " 를 추천</span>드리겠습니다."];

                                this.tendencyDetail = ["대표님의 지난 투찰 기록을 바탕으로 성향 분석 결과, " + this.cmpNm + " 대표님의 투찰 성향은 [적극형]으로 분류되었습니다. \n" +
                                "이는 고객님께서 낙찰 확률의 잠재력이 높은 곳으로 투찰하며 새로운 예가 출현의 기회를 잡으려 하고,\n" +
                                "흙 속의 진주를 발견하려는 성향을 가지고 있다는 것을 의미합니다.\n" +
                                "[적극형]의 특징은 경쟁 업체의 진입이 적으면서도 예상 출현 예가의 흐름을 확인하고 파악하며,\n" +
                                "분산 투자보다는 집중 투찰 포인트를 도전적으로 찾아내는 전략입니다.\n" +
                                "하지만 오히려 도전적인 부분과 잠재력이 높은 포인트를 찾다보니 예가의 흐름을 따라가지 못한다는 말을 듣기도 합니다.\n" +
                                "특징은 도전적이며 잠재력 높은 투찰 포인트를 찾아내려는 전략적 접근입니다.\n" +
                                "\n" +
                                "이에 따라, 고객님의 투찰 전략을 더욱 세밀하게 조율하기 위해 아래와 같은 제안을 드립니다:\n" +
                                "\n" +
                                "▶ 경쟁 업체 및 예가 출현의 기회 분석\n" +
                                "고객님의 경우, 경쟁 업체의 진입이 적고 잠재력 높은 출현 예가의 흐름을 면밀히 분석하여, 새롭고 도전적인 투찰 포인트를 발견하는 것이 중요합니다.\n" +
                                "이를 통해, 고객님께서는 시장에서 유리한 위치를 확보하며 낙찰 확률을 높일 수 있습니다.\n" +
                                "\n" +
                                "▶ 집중 투찰 포인트의 선정 및 끈기 있는 투찰\n" +
                                "고객님은 분산 투자보다는 하나 혹은 소수의 집중된 투찰 포인트에 끈기 있게 투찰함으로써 낙찰 확률을 높이려는 전략을 선호합니다.\n" +
                                "이러한 접근은 고객님께서 시장 변화에 민첩하게 대응하고, 장기적으로 안정적인 수익을 창출할 수 있는 기반을 마련해 줄 것입니다.\n"];

                                this.tendencyDetail2 = [
                                    "내 성향과 맞는 비드톡톡 추천 AI분석가: " + this.tendencyCdArr2[this.tendencyCd][0] + "\n" +
                                    "\n" +
                                    "AI분석가 펭: \n" +
                                    "최신 예가 자료 및 경쟁 업체 참여 자료를 바탕으로, 출현 예가와 경쟁사와 우위한 구간에서 결정적인 투찰 포인트를 산출합니다.\n" +
                                    "이런 접근 방식의 분석 능력은 공고 내에서 유리한 포인트를 확보하며, 상황에 따라 경쟁사와 예가의 전략적 위치를 선점하길 원하는 고객님에게 적극 추천됩니다.\n" +
                                    "★적극형/부분적극형/부분중립형 성향에 추천드립니다.\n"+
                                    "< 나와의 예상 적합도 >" + "                      < 현재 펭 평균 낙찰률 > 3.14%"
                                ];

                                this.tendencyDetail3 = ["내 성향 변화를 위한 비드톡톡 추천 AI분석가\n" +
                                "\n" +
                                "[[AI분석가 "+ this.tendencyCdReverseArr[this.tendencyCd] + "\n" +
                                "기본적으로 "+ this.tendencyCdReverseArr2[this.tendencyCd] + " 성향에 추천드리는 AI분석가입니다.\n" +
                                "하지만 현재 결과가 나오지 않아 내 투찰 성향의 개선을 위해 적극적인 변화를 원하신다면\n" +
                                "AI분석가 "+ this.tendencyCdReverseArr[this.tendencyCd] + " 을 추천드리겠습니다.]]\n" +
                                "\n" +
                                "고객님의 투찰 전략과 가장 잘 맞는 분석가를 선택하여, 보다 체계적이고 전략적인 투찰 접근을 시도해 보시는 것을 권장드립니다.\n" +
                                "\n" +
                                "비드톡톡은 언제나 고객님의 더 많은 낙찰이 되기를 기원하며 \n" +
                                "고객님의 투찰 전략과 가장 잘 맞는 비드톡톡의 AI 분석가를 선택하여 보다 체계적이고 전략적인 투찰 접근을 시도해 보시는 것을 권장드립니다. \n"
                                ];
                            }

                        } else {
                            this.tendencyCd = 1;
                            this.aiSuggest = ["펭","수리"];
                            this.tendencyDetail2_0_Percent = [87,3.21];
                            this.tendencyDetail2_1_Percent = [88,2.97];

                            this.viewStepText2 = ["대표님의 지난 투찰 기록을 바탕으로 성향 분석 결과, " + this.cmpNm + " 대표님의 <span class=\"textUnderline\">투찰 성향은 [부분적극형]입니다.</span><br>",
                                "이는 고객님께서 예상 출현 예가의 흐름을 파악하여 안정적인 투찰 포인트를 잡으려고 하지만 ",
                                "경쟁율이 낮거나 또는 특정 공고에서는 공격적인 포인트를 찾아 위험을 감수하는 전략을 선호한다는 것을 의미합니다.<br>",
                                "안정적인 예가 출현을 체크하면서도 ",
                                "단편적으로는 <span class=\"textUnderline\">위험도 감수하며 경쟁 업체 빈 구간을 확보</span>하여 낙찰률을 높이고자 합니다.<br>",
                                "이에 따라, 고객님의 투찰 전략을 더욱 세밀하게 조율하기 위해 아래와 같은 제안을 드립니다"];

                            this.viewStepText3 = ["위험 감수와 안정적인 예가 흐름 파악 사이의 균형"];

                            this.viewStepText4 = ["고객님께서는 안정적인 예가 흐름을 파악하면서도 부분적으로는 더 높은 수익을 위해 위험을 감수하는 전략을 선호합니다. 이를 위해, 시장 변화와 경쟁 업체의 움직임을 면밀히 분석하여, <span class=\"textUnderline\">경쟁율이 낮은 투찰 포인트를 선별하는 동시에 안정적인 수익을 보장할 수 있는 포인트도 확인</span>하는 것이 중요합니다."];

                            this.viewStepText5 = ["다양한 시장 상황에 대한 적응력 강화"];

                            this.viewStepText6 = ["고객님의 경우, <span class=\"textUnderline\">다양한 시장 상황에서의 적응력을 강화하여 더 많은 투찰 기회를 포착</span>하는 것이 유리합니다. 이를 위해 시장 변화에 대한 심층적인 이해와 함께, 다양한 예가 흐름과 경쟁 업체의 전략을 예측하는 능력을 키우는 것이 추천됩니다."];

                            this.viewStepText7 = ["최신 예가 자료 및 경쟁 업체 참여 자료를 바탕으로, 출현 예가와 <span class=\"textUnderline\">경쟁사와 우위한 구간에서 결정적인 투찰 포인트를 산출</span>합니다. 이런 접근 방식의 분석 능력은 공고 내에서 유리한 포인트를 확보하며, 상황에 따라 경쟁사와 예가의 <span class=\"textUnderline\">전략적 위치를 선점하길 원하는 고객님에게 적극 추천</span>됩니다.",
                                "최신 예가 자료 및 경쟁 업체 참여 자료를 바탕으로 신중한 출현 예가의 흐름을 파악하여 <span class=\"textUnderline\">전체적인 사정율 내에 고객님에게 맞는 최적의 투찰 포인트를 제시</span>합니다. <span class=\"textUnderline\">신중한 예가 정보 분석 및 전략을 선호</span>하는 고객님께 적합합니다."];

                            this.viewStepText8 = ["적극형/부분적극형/부분중립형 성향에 추천드립니다.",
                                "부분적극형/중립형/부분중립형/부분안정형 성향에 추천드립니다."];

                            this.viewStepText8_1 = [87,88];

                            this.viewStepText8_2 = [3.21,2.97];

                            this.viewStepText9 = ["기본적으로 <span class=\"textUnderline\">"+ this.tendencyCdReverseArr2[this.tendencyCd] + " 성향에 추천</span>드리는 AI분석가입니다.\n" +
                            "하지만 현재 결과가 나오지 않아 내 투찰 성향의 개선을 위해 적극적인 변화를 원하신다면\n" +
                            "<span class=\"textUnderline\">AI분석가 "+ this.tendencyCdReverseArr[this.tendencyCd] + " 를 추천드리겠습니다.</span>"];

                            this.tendencyDetail = ["대표님의 지난 투찰 기록을 바탕으로 성향 분석 결과, " + this.cmpNm + " 대표님의 투찰 성향은 [부분적극형]으로 분류되었습니다. \n" +
                            "이는 고객님께서 예상 출현 예가의 흐름을 파악하여 안정적인 투찰 포인트를 잡으려고 하면서도 일정 공고에서는\n" +
                            "경쟁율이 적은 포인트도 확인해 예가가 출현하지 않을 것이라는 위험을 감수하는 전략을 선호한다는 것을 의미합니다.\n" +
                            "안정적인 예가 출현을 체크하면서도 부분적으로는 위험도 감수하며 경쟁 업체 빈 구간을 확보하여 낙찰률을 높이고자 합니다. \n" +
                            "\n" +
                            "이에 따라, 고객님의 투찰 전략을 더욱 세밀하게 조율하기 위해 아래와 같은 제안을 드립니다:\n" +
                            "\n" +
                            "▶ 위험 감수와 안정적인 예가 흐름 파악 사이의 균형\n" +
                            "고객님께서는 안정적인 예가 흐름을 파악하면서도 부분적으로는 더 높은 수익을 위해 위험을 감수하는 전략을 선호합니다.\n" +
                            "이를 위해, 시장 변화와 경쟁 업체의 움직임을 면밀히 분석하여, 경쟁율이 낮은 투찰 포인트를 선별하는 동시에\n" +
                            "안정적인 수익을 보장할 수 있는 포인트도 확인하는 것이 중요합니다.\n" +
                            "\n" +
                            "▶ 다양한 시장 상황에 대한 적응력 강화\n" +
                            "고객님의 경우, 다양한 시장 상황에서의 적응력을 강화하여 더 많은 투찰 기회를 포착하는 것이 유리합니다.\n" +
                            "이를 위해 시장 변화에 대한 심층적인 이해와 함께, 다양한 예가 흐름과 경쟁 업체의 전략을 예측하는 능력을 키우는 것이 추천됩니다.\n" +
                            "\n" +
                            "비드톡톡 AI 분석가 중 고객님께 특히 추천드리는 분들은 [펭 / 수리]입니다:\n"];
                            this.tendencyDetail2 = [
                                "AI분석가 펭: \n" +
                                "최신 예가 자료 및 경쟁 업체 참여 자료를 바탕으로, 출현 예가와 경쟁사와 우위한 구간에서 결정적인 투찰 포인트를 산출합니다.\n" +
                                "이런 접근 방식의 분석 능력은 공고 내에서 유리한 포인트를 확보하며, 상황에 따라 경쟁사와 예가의 전략적 위치를 선점하길 원하는 고객님에게 적극 추천됩니다.\n" +
                                "★적극형/부분적극형/부분중립형 성향에 추천드립니다.\n" +
                                "< 나와의 예상 적합도 >" + "                      < 현재 펭 평균 낙찰률 > 3.14%"];
                            this.tendencyDetail2_1 = [
                                "AI분석가 수리: \n" +
                                "최신 예가 자료 및 경쟁 업체 참여 자료를 바탕으로 신중한 출현 예가의 흐름을 파악하여 전체적인 사정율 내에\n" +
                                "고객님에게 맞는 최적의 투찰 포인트를 제시합니다. 신중한 예가 정보 분석 및 전략을 선호하는 고객님께 적합합니다.\n" +
                                "★부분적극형/중립형/부분중립형/부분안정형 성향에 추천드립니다.\n" +
                                "< 나와의 예상 적합도 >" + "                      < 현재 수리 평균 낙찰률> 2.95%"];
                            this.tendencyDetail3 = [
                                "내 성향 변화를 위한 비드톡톡 추천 AI분석가\n" +
                                "\n" +
                                "[[AI분석가 "+ this.tendencyCdReverseArr[this.tendencyCd] + "\n" +
                                "기본적으로 "+ this.tendencyCdReverseArr2[this.tendencyCd] + " 성향에 추천드리는 AI분석가입니다.\n" +
                                "하지만 현재 결과가 나오지 않아 내 투찰 성향의 개선을 위해 적극적인 변화를 원하신다면\n" +
                                "AI분석가 "+ this.tendencyCdReverseArr[this.tendencyCd] + " 을 추천드리겠습니다.]]\n" +
                                "\n" +
                                "고객님의 투찰 전략과 가장 잘 맞는 분석가를 선택하여, 보다 체계적이고 전략적인 투찰 접근을 시도해 보시는 것을 권장드립니다.\n" +
                                "\n" +
                                "비드톡톡은 언제나 고객님의 더 많은 낙찰이 되기를 기원하며 \n" +
                                "고객님의 투찰 전략과 가장 잘 맞는 비드톡톡의 AI 분석가를 선택하여 보다 체계적이고 전략적인 투찰 접근을 시도해 보시는 것을 권장드립니다. \n"
                            ];
                        }
                        break;
                    default:
                }

                //callback(this.topGroup, this.topGroup_98);

                // 로딩후 글자 bold 처리
                setTimeout(() => {
                    var bt = document.getElementsByClassName("boldText");
                    $(bt).css("font-weight","bold");// 성향 추출

                    var textUnderline = document.getElementsByClassName("textUnderline");
                    $(textUnderline).css({"text-decoration": "underline","text-underline-offset": "4px"});

                    var rtColor1 = $("#suggestGraph펭").children().children().children();
                    $(rtColor1).css({'background-color':'#7b93e6'});

                    var rtColor2 = $("#suggestGraph수리").children().children().children();
                    $(rtColor2).css({'background-color':'#4199d4'});

                    var rtColor3 = $("#suggestGraph도그").children().children().children();
                    $(rtColor3).css({'background-color':'#E18F56'});

                    var suggestNm = document.getElementsByClassName("analist--title col-change");
                    $(suggestNm).css({'font-size':'3rem'});

                }, 500);

            },
            licnsToggleBtn() {
                this.toggle1 = !this.toggle1;

            },
            areaToggleBtn() {
                this.toggle2 = !this.toggle2;
            },
            clkLicns() {
                this.searchParam.licnsNmList = [];

                for (var i=0 ; i<this.checkboxGroup1.length ; i++) {
                    // console.log(this.checkboxGroup1[i]);
                    // console.log(this.licnsValList[0]);
                    // if (this.licnsValList[0] == this.checkboxGroup1[i]) {
                    // console.log("같다");
                    // }
                    this.searchParam.licnsNmList.push(this.licnsKeyList[_.indexOf(this.licnsValList, this.checkboxGroup1[i])]);
                    //console.log(_.findIndex(this.licnsValList, '수질방지'));
                }

                this.getRealNoticeList();
            },
            clkPrvncl() {
                this.searchParam.prvnclCdList = [];

                for (var i=0 ; i<this.checkboxGroup2.length ; i++) {
                    // console.log(this.checkboxGroup1[i]);
                    // console.log(this.licnsValList[0]);
                    // if (this.licnsValList[0] == this.checkboxGroup1[i]) {
                    // console.log("같다");
                    // }
                    this.searchParam.prvnclCdList.push(this.prvnclKeyList[_.indexOf(this.prvnclValList, this.checkboxGroup2[i])]);
                    //console.log(_.findIndex(this.licnsValList, '수질방지'));
                }

                this.getRealNoticeList();
            },
            doReset() {
                this.sum= 0;
                this.checkboxGroup1= [];
                this.licnsValList= [];
                this.licnsKeyList= [];
                this.licnsCntList= [];
                this.checkboxGroup2= [];
                this.prvnclList= [];
                this.prvnclValList= [];
                this.prvnclKeyList= [];
                this.custRecordPercentList = [0,0,0,0,0,0];
                //this.searchParam.searchLicnsList = [];
                this.tendencyDetail = [];
                this.isLine2 = false;
                this.isLine3 = false;
                this.isTyper1 = false;
                this.isTyper2 = false;
                this.isTyper3 = false;
            },
            doSearch(imsi) {
                this.doReset();
                this.doReport();

                var self = this;
                this.getCustRecordStat(97, async function(topGroup) {
                    setTimeout(() => {
                        self.getCustStatNcstPrargList(topGroup, function(bidTotal, cmpRankListCnt, totalSBidAmt) {
                            console.log(bidTotal, cmpRankListCnt, totalSBidAmt);
                            //self.countingType1('#number1', bidTotal);
                            //self.countingType2('#cmpRankListCnt', cmpRankListCnt);
                            // this.notiSbidRt = (cmpRankListCnt/bidTotal*100).toFixed(2);
                            // console.log("=====>"+this.notiSbidRt);
                            //self.countingType3('#notiSbidRt', (cmpRankListCnt/bidTotal*100).toFixed(2));
                            //self.countingType1('#totalSBidAmt', totalSBidAmt);

                            if (self.bidTotal > 0) {
                                if (imsi == 1) {
                                    self.setTendency(imsi); // 성향 진단
                                } else {
                                    self.setTendency(); // 성향 진단
                                }
                            } else {
                                self.viewStepTextEmpty = "감사합니다, " +self.cmpNm+ " 고객님 <br> 고객님의 지난 최근 1년 동안 참여하신 공고가  <span class=\"textUnderline\">확인되지 않습니다.</span><br>기타 궁금하신 사항은 고객센터 1670-0508로 연락하셔서 문의 부탁드립니다.";
                            }
                        });

                        self.getCustStatTopPoint(topGroup, 97);

                        // 데이터 로딩 후 간격조정
                        setTimeout(() => {
                            //this.showPrint();
                            //this.exportToPDF();
                            if (self.tendencyCd == 1 || self.tendencyCd == '4') {
                                self.spacingDiv = {
                                    height: '325px'
                                }
                            } else if (self.tendencyCd == 2) {
                                self.spacingDiv = {
                                    height: '60px'
                                }
                            }
                        }, 500);

                    }, 500);
                });
                this.getCustRecordStat(98, async function(topGroup, topGroup_98) {

                    self.getCustStatNcstPrargList(topGroup_98, function() {

                    });
                    self.getCustStatTopPoint(topGroup_98, 98);
                });
                // this.getRealNoticeList();
                //this.getLicnsCodeList();
                // this.getAreacodeList();

                setTimeout(() => {
                    this.isTyper1 = true;
                }, 9000);

                /* 스크롤 s*/
                if (this.scrollVersion) {
                    let elMain = document.getElementById('app');

                    elMain.scrollTop = elMain.scrollHeight;

                    //$(elMain).scrollTop($(elMain).prop('scrollHeight'));

                    //var preScrollHeight = 0;
                    setTimeout(() => {
                        this.scrollTime = setInterval(() => {
                            //preScrollHeight = $(elMain).prop('scrollHeight')+300;
                            //$(elMain).scrollTop($(elMain).prop('scrollHeight')+300);

                            $(elMain).animate({
                                scrollTop: $(document).height()
                            }, 1500)

                            //console.log(preScrollHeight);
                            // if (preScrollHeight == $(elMain).prop('scrollHeight')+300) {
                            //     clearInterval(scrollTime);
                            // }

                            //console.log(this.isTyper);
                        }, 2550);
                        //console.log(this.scrollTime);

                    }, 2500);
                }
                /* 스크롤 e*/
            },
            doReport() {

            },
            getLicnsCodeList() {

                this.loading = true;

                var postData = {
                    notiKindCd: 0
                };

                this.$http.post('/api/comnCode/licnsCodeList', postData, this.config).then(res => {

                    this.licnsCodeList = res.data.licnsCodeList;
                    // this.licnsCodeList = (_.orderBy(this.licnsCodeList, ['licnsDstnctCd', 'rpsntOrgnzCd', 'licnsCd', 'licnsCtgyNm']));
                    // console.log(this.licnsCodeList);

                    // this.licnsCodeGroupList = _.groupBy(res.data.licnsCodeList, function(o) {
                    //    return o.licnsCtgyNm;
                    // });

                    // this.licnsCodeList = _.filter(this.licnsCodeGroupList, function(o) {
                    //     if (o.length == 1) {
                    //         return o;
                    //     }
                    // });

                    for (var j=0 ; j<this.licnsCodeList.length; j++) {
                        if (this.licnsCodeList[j].licnsCd != '0006' && this.licnsCodeList[j].licnsCd != '0018') {
                            //console.log(this.licnsCodeList[j].licnsNm);
                            //console.log(this.licnsCodeList[j].licnsCd);
                            //this.checkboxGroup1.push(this.licnsCodeList[j].licnsNm);
                            this.licnsValList.push(this.licnsCodeList[j].licnsNm);
                            this.licnsKeyList.push(this.licnsCodeList[j].licnsCtgyNm);
                            //this.licnsCntList.push(res.data.notiLicnCntList[j].count);
                        }
                    }

                    //console.log(this.checkboxGroup1);
                    this.loading = false;
                }).catch(error => console.log(error));
            },
            getAreacodeList() {
                this.loading = true;

                var postData = {

                };

                this.$http.post('/api/realnotice/areaCodeList', postData, this.config).then(res => {
                    this.prvnclList = _.filter(res.data.areaCodeList, function(o) {
                        return o.areaNm == "";
                    });
                    this.areaCodeList = res.data.areaCodeList;
                    // this.licnsCodeList = (_.orderBy(this.licnsCodeList, ['licnsDstnctCd', 'rpsntOrgnzCd', 'licnsCd', 'licnsCtgyNm']));
                    // console.log(this.licnsCodeList);

                    // this.licnsCodeGroupList = _.groupBy(res.data.licnsCodeList, function(o) {
                    //    return o.licnsCtgyNm;
                    // });

                    // this.licnsCodeList = _.filter(this.licnsCodeGroupList, function(o) {
                    //     if (o.length == 1) {
                    //         return o;
                    //     }
                    // });

                    for (var j=0 ; j<this.prvnclList.length; j++) {
                        //console.log(this.licnsCodeList[j].licnsNm);
                        //console.log(this.licnsCodeList[j].licnsCd);
                        //this.checkboxGroup1.push(this.licnsCodeList[j].licnsNm);
                        this.prvnclValList.push(this.prvnclList[j].prvnclNm);
                        this.prvnclKeyList.push(this.prvnclList[j].prvnclCd);
                        //this.licnsCntList.push(res.data.notiLicnCntList[j].count);

                    }

                    //console.log(this.checkboxGroup1);
                    this.loading = false;
                }).catch(error => console.log(error));
            },
            getRealNoticeList() {

                this.searchParam.findStrtDt = this.$moment(this.strtDate).format('YYYY-MM-DD');
                this.searchParam.findEndDt = this.$moment(this.finDate).format('YYYY-MM-DD');
                this.loading = true;

                var postData = _.defaults({
                    pageUnit: 9999,
                    pageIndex: 0,
                    strtRecordIntex: 0,
                    areaCdList: []
                }, this.searchParam);

                this.$http.post('/api/realnotice/list', postData, this.config).then(res => {
                    //console.log(res);


                    this.realNoticeList[0]['사정율(예가)'] = '예가출현';
                    this.realNoticeList[0]['1구간'] = _.filter(res.data.realNoticeList, function(o) {
                        return o['opbdPrargRt'] <= 98.9999;
                    }).length;
                    this.realNoticeList[0]['2구간'] = _.filter(res.data.realNoticeList, function(o) {
                        return o['opbdPrargRt'] >= 99.0 && o['opbdPrargRt'] <= 99.4999;
                    }).length;
                    this.realNoticeList[0]['3구간'] = _.filter(res.data.realNoticeList, function(o) {
                        return o['opbdPrargRt'] >= 99.4 && o['opbdPrargRt'] <= 99.9999;
                    }).length;
                    this.realNoticeList[0]['4구간'] = _.filter(res.data.realNoticeList, function(o) {
                        return o['opbdPrargRt'] >= 100.0 && o['opbdPrargRt'] <= 100.4999;
                    }).length;
                    this.realNoticeList[0]['5구간'] = _.filter(res.data.realNoticeList, function(o) {
                        return o['opbdPrargRt'] >= 100.5 && o['opbdPrargRt'] <= 100.9999;
                    }).length;
                    this.realNoticeList[0]['6구간'] = _.filter(res.data.realNoticeList, function(o) {
                        return o['opbdPrargRt'] >= 101.0;
                    }).length;

                    this.loading = false;
                }).catch(error => console.log(error));
            },
            countingType1(id, number){
                const element = document.querySelector(id)
                if(number == 0){
                    element.innerHTML = '0';
                } else {
                    /* 입력한 숫자를 33번에 걸쳐 0부터 올림. */
                    const each = Math.ceil(number/33);
                    let time = 0

                    for(let i=0; i<=number; i+=each){
                        setTimeout(() => {
                            element.innerHTML = i;
                        }, 35*time);
                        /* 딱 떨어지지 않는 숫자를 마지막에 그 숫자로 만들어주기 위함 */
                        if(i+each>number){
                            setTimeout(() => {
                                element.innerHTML = number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                            }, 35*(time+1));
                        }
                        time++;
                    }
                }
            },

            /* 한자리씩 순서대로 카운트업 */
            countingType2(id, number){
                const element = document.querySelector(id);

                /* 각자리로 나누어 올리기 위해 쪼개려는 것 */
                let current = new Array(number.length).fill(0);
                let arrayNum = String(number).split('').reverse();

                /* 올라갈 때 걸리는 시간 균등하게 하기위해. 단, 최대 0.06초씩은 되도록 */
                const total = arrayNum.reduce((pre, cur)=>Number(pre)+Number(cur));
                const eachTime = Math.min(1000/total, 150);

                let time = 0
                for(let j=0; j<arrayNum.length; j++){
                    for(let i = 0; i<=arrayNum[j]; i++){
                        setTimeout(()=>{
                            current[arrayNum.length-j-1]=i;
                            element.innerHTML = current.join('');
                        }, eachTime*(time + i))
                    }
                    time += arrayNum[j]-1;
                }
            },

            /* 각 자리별로 따로 카운트업 */
            countingType3(id, number){
                const element = document.querySelector(id);

                /* 각자리로 나누어 올리기 위해 쪼개려는 것 */
                let current = [];
                let arrayNum = String(number).split('');

                for(let i = 0; i<10; i++){
                    setTimeout(()=>{
                        for(let j = 0; j<arrayNum.length; j++){
                            if(i<=arrayNum[j]){
                                current.push(i);
                            } else{
                                current.push(arrayNum[j]);
                            }
                        }
                        element.innerHTML = current.join('');
                        current = [];
                    }, 150*i)
                }
            },
            /* 각 자리별로 따로 카운트업 */
            countingType4(id, number){
                $({ val : 0/*시작숫자*/ }).animate({ val : number/*종료숫자*/ }, {
                    duration: 1500,
                    step: function() {
                        var num = (Math.floor(this.val)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                        $(id).text(num);
                    },
                    complete: function() {
                        var num = (Math.floor(this.val)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                        $(id).text(num);
                    }
                });
            },
            getCustRecordStat(minAsRt, callback) {
                // const config = {
                //   headers: {
                //     'Content-Type': 'application/json',
                //     'auth-token': 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJjbXBObSI6IuynhOu5hOuTnDEiLCJyb2xlIjoiUk9MRV9VU0VSIiwiaXNzIjoiamluYmlkIiwiaWQiOjEsImV4cCI6MTcxMDMxNDkzNSwiY3VzdElkTm0iOiIwMDAwMDAwMDAxIn0.5AnTZAttUtNk5hyFBitXukSUmIWIf9RWl8l_59KudKc', //localStorage.getItem("authToken"),
                //     'refresh-token': 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJjbXBObSI6IuynhOu5hOuTnDEiLCJyb2xlIjoiUk9MRV9VU0VSIiwiaXNzIjoiamluYmlkIiwiaWQiOjEsImV4cCI6MTcxMjg4NTMzNSwiY3VzdElkTm0iOiIwMDAwMDAwMDAxIn0.G8FlXptiTNfX0jcdMxFcfeLDKmWpWIsmIm3bCimAV5M' // localStorage.getItem("refreshToken"),
                //   },
                // };

                this.searchParam.strtDate = this.$moment(this.strtDate).format('YYYY-MM-DD');
                this.searchParam.finDate = this.$moment(this.finDate).format('YYYY-MM-DD');

                var postData = _.defaults({
                    pageUnit: 9999,
                    pageIndex: 0,
                    strtRecordIntex: 0,
                    //strtDate: this.$moment().format('YYYYMMDD HH:mm'),
                    //finDate: this.$moment().format('YYYYMMDD HH:mm'),
                    //custBizId: custInfo.custBizList[0].id,
                    bizNo: this.bizNo,
                    findText: this.findText,
                    // orderBySt: 'bddprFinDt',
                    // notiOpbdListYn: 'Y',
                    minAsRt: minAsRt
                }, this.searchParam);

                //console.log(this.$moment(this.searchParam.strtDate).format('YYYYMMDD HH:mm'));

                this.loading = true;
                this.$http.post('/api/custBiz/getCustRecordStat', postData, this.config).then(res => {
                    const sum = res.data.custRecordStatList[0]['1구간']+res.data.custRecordStatList[0]['2구간']+res.data.custRecordStatList[0]['3구간']+res.data.custRecordStatList[0]['4구간']+res.data.custRecordStatList[0]['5구간']+res.data.custRecordStatList[0]['6구간'];
                    this.sum = this.sum + sum;

                    if (minAsRt == 97) {
                        this.custRecordStatList = _.cloneDeep(res.data.custRecordStatList);

                        // 예가 비율 - 내 지점 비율
                        var tmpArray = [];

                        for (var idx=0 ; idx<this.custRecordStatList.length ; idx++) {
                            const v = this.custRecordStatList[idx];

                            if (idx < 2) {

                                //const a = v['1구간'];
                                this.chartData.datasets[idx].data = [_.round(v['1구간']/sum*100,2).toFixed(1),_.round(v['2구간']/sum*100,2).toFixed(1),_.round(v['3구간']/sum*100,2).toFixed(1),_.round(v['4구간']/sum*100,2).toFixed(1),_.round(v['5구간']/sum*100,2).toFixed(1),_.round(v['6구간']/sum*100,2).toFixed(1)];

                                this.custRecordStatList[idx]['합계'] = v['1구간']+v['2구간']+v['3구간']+v['4구간']+v['5구간']+v['6구간'];

                                if (idx == 0) {
                                    // 백분율 차이 (예가 - 내지점)
                                    tmpArray[0] = _.round(v['1구간']/sum*100,2).toFixed(2);
                                    tmpArray[1] = _.round(v['2구간']/sum*100,2).toFixed(2);
                                    tmpArray[2] = _.round(v['3구간']/sum*100,2).toFixed(2);
                                    tmpArray[3] = _.round(v['4구간']/sum*100,2).toFixed(2);
                                    tmpArray[4] = _.round(v['5구간']/sum*100,2).toFixed(2);
                                    tmpArray[5] = _.round(v['6구간']/sum*100,2).toFixed(2);

                                    this.stdrd100yega.push(((Number(tmpArray[3]) + Number(tmpArray[4]) + Number(tmpArray[5]))).toFixed(1));
                                    this.stdrd100yega.push(((Number(tmpArray[0]) + Number(tmpArray[1]) + Number(tmpArray[2]))).toFixed(1));
                                    this.stdrd100yegaStyle.push({ height: this.stdrd100yega[0] + '%' });
                                    this.stdrd100yegaStyle.push({ height: this.stdrd100yega[1] + '%' });
                                }

                                if (idx == 1) { // 내 투찰율 저장
                                    // 투찰율 백분율 목록
                                    var groupList = _.keys(v);
                                    for (var i=0 ; i<groupList.length ; i++) {
                                        var o = groupList[i];

                                        if (o != '합계' && o != '사정율(예가)') {
                                            this.custRecordPercentList[o.substring(0,1)-1]=_.round(v[o]/sum*100,2).toFixed(2);
                                            //this.custRecordPercentList[0] = _.round(v[o]/sum*100,2).toFixed(2);
                                        }
                                    }

                                    this.stdrd100bid.push(((Number(_.round(v['4구간']/sum*100,2).toFixed(2)) + Number(_.round(v['5구간']/sum*100,2).toFixed(2)) + Number(_.round(v['6구간']/sum*100,2).toFixed(2)))).toFixed(1));
                                    this.stdrd100bid.push(((Number(_.round(v['1구간']/sum*100,2).toFixed(2)) + Number(_.round(v['2구간']/sum*100,2).toFixed(2)) + Number(_.round(v['3구간']/sum*100,2).toFixed(2)))).toFixed(1));
                                    this.stdrd100bidStyle.push({ height: this.stdrd100bid[0] + '%' });
                                    this.stdrd100bidStyle.push({ height: this.stdrd100bid[1] + '%' });
                                }

                                v['1구간'] = v['1구간'] +' (' + _.round(v['1구간']/sum*100,2).toFixed(2) + ")";
                                v['2구간'] = v['2구간'] +' (' + _.round(v['2구간']/sum*100,2).toFixed(2) + ")";
                                v['3구간'] = v['3구간'] +' (' + _.round(v['3구간']/sum*100,2).toFixed(2) + ")";
                                v['4구간'] = v['4구간'] +' (' + _.round(v['4구간']/sum*100,2).toFixed(2) + ")";
                                v['5구간'] = v['5구간'] +' (' + _.round(v['5구간']/sum*100,2).toFixed(2) + ")";
                                v['6구간'] = v['6구간'] +' (' + _.round(v['6구간']/sum*100,2).toFixed(2) + ")";

                                //console.log('1'+_.round(v['1구간']/sum*100,2).toFixed(2));
                                //this.chartData.datasets[idx].data = [res.data.custRecordStatList[idx]['1구간'],res.data.custRecordStatList[idx]['2구간'],res.data.custRecordStatList[idx]['3구간'],res.data.custRecordStatList[idx]['4구간'],res.data.custRecordStatList[idx]['5구간'],res.data.custRecordStatList[idx]['6구간']];
                            } else {
                                this.custRecordStatList3[0]['1구간'] = this.custRecordStatList[idx]['1구간'];
                                this.custRecordStatList3[0]['2구간'] = this.custRecordStatList[idx]['2구간'];
                                this.custRecordStatList3[0]['3구간'] = this.custRecordStatList[idx]['3구간'];
                                this.custRecordStatList3[0]['4구간'] = this.custRecordStatList[idx]['4구간'];
                                this.custRecordStatList3[0]['5구간'] = this.custRecordStatList[idx]['5구간'];
                                this.custRecordStatList3[0]['6구간'] = this.custRecordStatList[idx]['6구간'];
                                this.custRecordStatList3[0]['합계'] = this.custRecordStatList[idx]['1구간']+this.custRecordStatList[idx]['2구간']+this.custRecordStatList[idx]['3구간']+this.custRecordStatList[idx]['4구간']+this.custRecordStatList[idx]['5구간']+this.custRecordStatList[idx]['6구간'];
                            }
                        }

                        delete this.custRecordStatList[2];
//console.log(this.chartData.datasets);
                        //delete this.custRecordStatList[2];
                        // _.forEach(this.custRecordStatList, function(v, idx) {
                        //     console.log(idx);
                        //     console.log(v['1구간']);
                        //     //const a = v['1구간'];
                        //   v['1구간'] = v['1구간'] +' (' + _.round(v['1구간']/sum*100,2).toFixed(2) + ")";
                        //   v['2구간'] = v['2구간'] +' (' + _.round(v['2구간']/sum*100,2).toFixed(2) + ")";
                        //   v['3구간'] = v['3구간'] +' (' + _.round(v['3구간']/sum*100,2).toFixed(2) + ")";
                        //   v['4구간'] = v['4구간'] +' (' + _.round(v['4구간']/sum*100,2).toFixed(2) + ")";
                        //   v['5구간'] = v['5구간'] +' (' + _.round(v['5구간']/sum*100,2).toFixed(2) + ")";
                        //   v['6구간'] = v['6구간'] +' (' + _.round(v['6구간']/sum*100,2).toFixed(2) + ")";
                        //   //this.chartData.datasets[idx].data = [res.data.custRecordStatList[idx]['1구간'],res.data.custRecordStatList[idx]['2구간'],3,4,5,6];
                        // });

                        // 가장 많이 투찰한 구간 순위
                        var tempCustRecordPercentList = _.cloneDeep(this.custRecordPercentList);
                        let num = this.custRecordPercentList.slice().sort((a,b) => b - a);
                        this.groupBidRank = _.map(num, function(o) {
                            return _.findIndex(tempCustRecordPercentList, function(oo) {
                                return oo == o;
                            })+1;
                        });
                        //this.groupBidRank = this.custRecordPercentList.map(el => num.indexOf(el) + 1);
                        //console.log(this.groupBidRank);

                        var tmpRankArr = _.sortBy(this.custRecordPercentList, [function(o) { return Number(o);}]).reverse();
                        var tmpRank = tmpRankArr[0];
                        this.topGroup = _.findIndex(this.custRecordPercentList, function(o){
                            return o == tmpRank;
                        })+1;
                        this.topPercent = tmpRank;

                        if (res.data.custBizInfo != null) {
                            this.cmpNm = res.data.custBizInfo.corpNm;
                        }

                        this.viewStep[0] = true;
                        this.viewStepText1[0] = this.cmpNm + " 대표님의 ";
                        //this.viewStepText1 = [this.cmpNm + " 대표님의 최근 <span class=\"cus-info--empha\">1년 동안의 투찰 활동에 대한 리포트를 제공해드립니다1.</span>\n\n고객님의 투찰 성향을 파악하였으며, 아래 자료를 참고하여 앞으로의 분석 방향에 참고하시길 바랍니다."];

                        document.getElementById('bar-chart-left').style.height = '100%';
                        document.getElementById('bar-chart-left').style.position = 'absolute';
                        document.getElementById('bar-chart-left').style.top = '0';
                        document.getElementById('bar-chart-left').style.left = '0';
                        document.getElementById('bar-chart-left').style.width = '100%';
                        document.getElementById('bar-chart-right').style.height = '100%';
                        document.getElementById('bar-chart-right').style.position = 'absolute';
                        document.getElementById('bar-chart-right').style.top = '0';
                        document.getElementById('bar-chart-right').style.left = '0';
                        document.getElementById('bar-chart-right').style.width = '100%';

                        // 초기 실행
                        //setTimeout(this.typingFn, 500);
                        for (var ii=0 ; ii<2 ; ii++) {
                            this.rtMaxArr.push(Math.max.apply(null, this.chartData.datasets[ii].data.map(Number)));
                        }

                    } else {
                        this.custRecordStatList2 = _.cloneDeep(res.data.custRecordStatList);

                        for (var j=0 ; j<this.custRecordStatList2.length ; j++) {
                            const v2 = this.custRecordStatList2[j];
                            if (j < 2) {
                                this.chartData2.datasets[j].data = [_.round(v2['1구간']/sum*100,2).toFixed(1),_.round(v2['2구간']/sum*100,2).toFixed(1),_.round(v2['3구간']/sum*100,2).toFixed(1),_.round(v2['4구간']/sum*100,2).toFixed(1),_.round(v2['5구간']/sum*100,2).toFixed(1),_.round(v2['6구간']/sum*100,2).toFixed(1)];

                                if (j == 1) { // 내 투찰율 저장
                                    // 투찰율 백분율 목록
                                    var groupList2 = _.keys(v2);
                                    for (var k=0 ; k<groupList2.length ; k++) {
                                        var o2 = groupList2[k];

                                        if (o2 != '합계' && o2 != '사정율(예가)') {
                                            this.custRecordPercentList2[o2.substring(0,1)-1]=_.round(v2[o2]/sum*100,2).toFixed(2);
                                            //this.custRecordPercentList[0] = _.round(v[o]/sum*100,2).toFixed(2);
                                        }
                                    }
                                }
                            }
                            this.custRecordStatList2[j]['합계'] = this.custRecordStatList2[j]['1구간']+
                                this.custRecordStatList2[j]['2구간']+
                                this.custRecordStatList2[j]['3구간']+
                                this.custRecordStatList2[j]['4구간']+
                                this.custRecordStatList2[j]['5구간']+
                                this.custRecordStatList2[j]['6구간'];
                        }

                        // 가장 많이 투찰한 구간 순위2
                        var tempCustRecordPercentList2 = _.cloneDeep(this.custRecordPercentList2);

                        let num = this.custRecordPercentList2.slice().sort((a,b) => b - a);
                        this.groupBidRank_98 = _.map(num, function(o) {
                            return _.findIndex(tempCustRecordPercentList2, function(oo) {
                                return oo == o;
                            })+1;
                        });
                        //this.groupBidRank = this.custRecordPercentList.map(el => num.indexOf(el) + 1);
                        //console.log(this.groupBidRank);

                        var tmpRankArr2 = _.sortBy(this.custRecordPercentList2, [function(o) { return Number(o);}]).reverse();
                        var tmpRank2 = tmpRankArr2[0];
                        this.topGroup_98 = _.findIndex(this.custRecordPercentList2, function(o){
                            return o == tmpRank2;
                        })+1;

                        this.topPercent_98 = tmpRank2;

                        for (var jj=0 ; jj<2 ; jj++) {
                            this.rtMaxArr.push(Math.max.apply(null,this.chartData2.datasets[jj].data.map(Number)));
                        }

                        delete this.custRecordStatList2[2];
                    }
                    this.loading = false;
                    this.isComplete = true;
                    this.rtMax = Math.max.apply(null,this.rtMaxArr);
                    this.chartOptions.scales.yAxis.suggestedMax = this.rtMax+10; // 사정율 차트 안정적으로 표현
                    //ChartJS.update();

                    callback(this.topGroup, this.topGroup_98);
                })
                    .catch(error => console.log(error));
            },
            getCustStatTopPoint(topGroup, minAsRt) { // 가장 많이 투찰한 지점

                var postData = _.defaults({
                    bizNo: this.bizNo,
                    topGroup: topGroup.toString(),
                    minAsRt: minAsRt
                }, this.searchParam);

                if (minAsRt == 98) {
                    this.$http.post('/api/custBiz/getCustRecordTopPoint', postData, this.config).then(res => {
                        this.topPoint_98 = res.data.custRecordTopPoint;
                    })
                        .catch(error => console.log(error));
                } else {
                    this.$http.post('/api/custBiz/getCustRecordTopPoint', postData, this.config).then(res => {
                        this.topPoint = res.data.custRecordTopPoint;
                    })
                        .catch(error => console.log(error));
                }

            },
            getCustStatNcstPrargList(topGroup, callback) {

                var postData = _.defaults({
                    bizNo: this.bizNo,
                }, this.searchParam);

                this.$http.post('/api/custBiz/getCustStatNcstPrargList', postData, this.config).then(res => {

                    if (res.data.custStatNcstPrargList.length > 0) {
                        this.bidTotal = res.data.custStatNcstPrargList.length;

                        //console.log(res.data.custStatNcstPrargList);

                        this.avgCptnRt = Math.round(_.sumBy(res.data.custStatNcstPrargList, function (o) {
                            return o.bddprPrtcCmpCnt;
                        }) / this.bidTotal);

                        const cmpRankList = _.filter(res.data.custStatNcstPrargList, function (o) {
                            return o.cmpRank == 1;
                        });

                        this.cmpRankListCnt = cmpRankList.length;

                        this.totalSBidAmt = _.sumBy(cmpRankList, function (o) {
                            return Number(o.bddprAmt);
                        });

                        //console.log((_.map(_.groupBy(res.data.custStatNcstPrargList, (item) => Math.floor(Number(item.calBddprRt)*10)/10), (group) => ({...group[0], count: group.length}))));

                        // 가장 많이 투찰한 포인트 산출
                        var tmpGroupList = _.map(res.data.custStatNcstPrargList, function (o) {
                            //console.log(o.calBddprRt);
                            //console.log( Number(o.calBddprRt).toFixed(1));
                            //console.log( Math.floor(Number(o.calBddprRt)*10)/10);
                            var obj = o;
                            o.calBddprRt = Math.floor(Number(o.calBddprRt) * 10) / 10;

                            return obj;
                        });

                        var tmpGroupList2 = _.cloneDeep(tmpGroupList);

                        tmpGroupList = _.filter(tmpGroupList, function (o) {

                            switch (topGroup) {
                                case 1:
                                    return o.calBddprRt <= 99.9999;
                                case 2:
                                    return o.calBddprRt >= 99.5 && o.calBddprRt <= 99.9999;
                                case 3:
                                    return o.calBddprRt >= 99.5 && o.calBddprRt <= 99.9999;
                                case 4:
                                    return o.calBddprRt >= 100.0 && o.calBddprRt <= 100.4999;
                                case 5:
                                    return o.calBddprRt >= 100.5 && o.calBddprRt <= 100.9999;
                                case 6:
                                    return o.calBddprRt >= 101.0;
                                default:
                            }

                            // if (topGroup == 3) {
                            //     return o.calBddprRt >= 99.5 && o.calBddprRt <= 99.9999;
                            // }
                            //return o;
                        });

                        tmpGroupList = _.map(_.groupBy(tmpGroupList, 'calBddprRt'), function (o) {
                            o.groupCnt = o.length;
                            return o;
                        });

                        tmpGroupList = _.sortBy(tmpGroupList, 'groupCnt').reverse();

                        this.custBidRt = (tmpGroupList[0]['groupCnt'] / this.sum * 100).toFixed(2);

                        // 실제 예가 출현 비율
                        this.realOpbdPrargRt = (_.filter(tmpGroupList2, function (o) {
                            //console.log(Math.floor(Number(o.opbdPrargRt)*10)/10);
                            if (Math.floor(Number(o.opbdPrargRt) * 10) / 10 == tmpGroupList[0][0]['calBddprRt']) {
                                return o;
                            }
                            //console.log( Math.floor(Number(o.opbdPrargRt)*10)/10);
                            //console.log( tmpGroupList[0][0]['calBddprRt']);
                            //console.log( Math.floor(Number(o.opbdPrargRt)*10)/10 == tmpGroupList[0][0]['calBddprRt']);
                        }).length / this.sum * 100).toFixed(2);

                        this.notiSbidRt = (this.cmpRankListCnt / this.bidTotal * 100).toFixed(2); //
                    }
                    callback(this.bidTotal, this.cmpRankListCnt, this.totalSBidAmt);
                })
                    .catch(error => console.log(error));
            },
        }
    }
</script>
<style src="@/assets/scss/common/report_pdf.scss" lang="scss" scoped />