<template>

  <v-container fluid>

    <div class="monitoring-page">
      <v-row no-gutters class="d-flex justify-space-between mt-10 mb-6">
        <h1 class="page-title">수집기 모니터링 시스템</h1>
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on" color="secondary" class="text-capitalize button-shadow mr-1">SY관리자</v-btn>
          </template>
        </v-menu>
      </v-row>
      <v-row no-gutters class="d-flex justify-space-between mt-10 mb-6">
        <div>
          <h3>일별수집량</h3>
          <ApexChart height="500" width="800" type="bar" :options="mock.chart.chartOptions" :series="mock.chart.series">
          </ApexChart>
        </div>
        <div>
          <h3>발주처별 일별 수집량</h3>
          <ApexChart type="bar" height="570" width="800" :options="mock.distributedColumnChart.chartOptions"
            :series="mock.distributedColumnChart.series"></ApexChart>
        </div>
      </v-row>
      <v-col cols="12">
          <v-card class="support-requests mx-1 mb-1">
            <v-card-title class="pa-6 pb-0">
              <p>실행되지 않은 수집기 Job</p>
              <v-spacer></v-spacer>
              <v-menu>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon v-bind="attrs" v-on="on">
                    <v-icon color="textColor">mdi-dots-vertical</v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item
                    v-for="(item, i) in mock.menu"
                    :key="i"
                    @click="() => {}"
                  >
                    <v-list-item-title>{{ item }}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-card-title>
            <v-card-text class="pa-0">
              <v-simple-table>
                <template v-slot:default>
                  <thead class="pl-2">
                    <tr>
                      <th class="text-left pa-6">NAME</th>
                      <th class="text-left">START_TIME</th>
                      <th class="text-left">STATUS</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="item in mock.table" :key="item.name">
                      <td class="pa-6">{{ item.name }}</td>
                      <td>{{ item.start_time }}</td>
                      <td v-if="item.status === 'Sent'">
                        <v-chip link color="success" class="ma-2 ml-0">
                          Sent
                        </v-chip>
                      </td>
                      <td v-else-if="item.status === 'NULL'">
                        <v-chip link color="warning" class="ma-2 ml-0">
                          NULL
                        </v-chip>
                      </td>
                      <td v-else-if="item.status === 'FAILED'">
                        <v-chip link color="secondary" class="ma-2 ml-0">
                          FAILED
                        </v-chip>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-card-text>
          </v-card>
        </v-col>
      <v-row no-gutters class="d-flex justify-space-between mt-10 mb-6">
        <v-tabs background-color="cyan" dark next-icon="mdi-arrow-right-bold-box-outline"
          prev-icon="mdi-arrow-left-bold-box-outline" show-arrows>
          <v-tabs-slider color="yellow"></v-tabs-slider>
          <v-tab v-for="(i,idx) in clientArr" :key="idx" :href="'#tab-' + i" @click="goMenu(idx,i)" >
            {{ i.rspntOrgnzNm }}
          </v-tab>
        </v-tabs>
      </v-row>
      {{this.activeRpsntOrgnzCd}}
      <v-row>
        <!-- 발주처 별 일일 수집량 -->
        <v-col lg="3" sm="6" md="5" cols="12">
          <v-card class="mx-1 mb-1">
            <v-card-title class="pa-6 pb-3">
              <p>{{this.menuName}} 일일 수집량</p>
              <v-spacer></v-spacer>
              <v-menu>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon v-bind="attrs" v-on="on">
                    <v-icon color="textColor">mdi-dots-vertical</v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item v-for="(item, i) in mock.menu" :key="i" @click="() => {}">
                    <v-list-item-title>{{ item }}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-card-title>
            <v-card-text class="pa-6 pt-0">
              <v-row no-gutters class="pb-5">
                <v-col cols="5" class="my-auto">
                  <span class="font-weight-medium card-dark-grey" style="font-size: 24px">{{this.dailyCountByRpsntOrgnz}}</span>
                </v-col>
                <v-col cols="6">
                  <!-- <Trend
                    :data="getRandomDataForTrends()"
                    :gradient="mock.trend.gradient"
                    :height="40"
                    stroke-width="4"
                    smooth
                  /> -->
                </v-col>
              </v-row>
              <v-row no-gutters class="justify-space-between pb-3">
                <v-col cols="5">
                  <div class="card-light-grey">Registrations</div>
                  <div class="text-h6 card-dark-grey font-weight-regular">
                    860
                  </div>
                </v-col>
                <v-col cols="3">
                  <div class="card-light-grey">Sign Out</div>
                  <div class="text-h6 card-dark-grey font-weight-regular">
                    32
                  </div>
                </v-col>
                <v-col cols="4" xl="2">
                  <div class="text-right card-light-grey">Rate</div>
                  <div class="text-right text-h6 card-dark-grey font-weight-regular">
                    3.25%
                  </div>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
        <!-- 발주처 별 공고 건수 -->
        <v-col lg="3" sm="6" md="7" cols="12">
          <v-card class="mx-1 mb-1">
            <v-card-title class="pa-6 pb-3">
              <p>{{this.menuName}} 공고 건수</p>
              <v-spacer></v-spacer>
              <v-menu>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon v-bind="attrs" v-on="on">
                    <v-icon color="textColor">mdi-dots-vertical</v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item v-for="(item, i) in mock.menu" :key="i" @click="() => {}">
                    <v-list-item-title>{{ item }}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-card-title>
            <v-card-text class="pa-6 pt-0">
              <v-row no-gutters class="pb-5">
                <div class="mr-4">
                  <v-icon color="primary" class="ml-n2">
                    mdi-circle-medium
                  </v-icon>
                  <span class="card-light-grey">완료공고</span>
                </div>
                <div>
                  <v-icon color="warning"> mdi-circle-medium </v-icon>
                  <span class="card-light-grey">대기공고</span>
                </div>
                <div>
                  <v-icon color="pink"> mdi-circle-medium </v-icon>
                  <span class="card-light-grey">폐기공고</span>
                </div>
              </v-row>
              <v-row no-gutters class="pb-3">
                <v-col>
                  <div class="text-h6 card-light-grey font-weight-regular">
                    완료공고
                  </div>
                  <v-progress-linear :value="value" background-color="#ececec" color="primary"></v-progress-linear>
                </v-col>
              </v-row>
              <v-row no-gutters class="pb-1">
                <v-col>
                  <div class="text-h6 card-light-grey font-weight-regular">
                    대기공고
                  </div>
                  <v-progress-linear :value="value2" background-color="#ececec" color="warning"></v-progress-linear>
                </v-col>
              </v-row>
              <v-row no-gutters class="pb-1">
                <v-col>
                  <div class="text-h6 card-light-grey font-weight-regular">
                    폐기공고
                  </div>
                  <v-progress-linear :value="value2" background-color="#ececec" color="warning"></v-progress-linear>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
        <!-- 발주처 별 시설 물품 용역 일일 수집 건수 -->
        <v-col lg="3" sm="6" md="7" cols="12">
          <v-card class="mx-1 mb-1" style="min-height: 228px">
            <v-card-title class="pa-6 pb-3">
              <p>{{this.menuName}} 일일 수집 건수</p>
              <v-spacer></v-spacer>
              <v-menu>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon v-bind="attrs" v-on="on">
                    <v-icon color="textColor">mdi-dots-vertical</v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item v-for="(item, i) in mock.menu" :key="i" @click="() => {}">
                    <v-list-item-title>{{ item }}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-card-title>
            <v-card-text class="pa-6 pt-0">
              <v-row no-gutters>
                <v-col cols="6" md="5" lg="6" xl="4" class="my-auto text-truncate">
                  <span>시설</span>
                </v-col>
                <v-col cols="6" md="7" lg="6" xl="8">
                  <ApexChart v-if="apexLoading" height="35" type="area" :options="mock.apexArea1.options"
                    :series="mock.apexArea1.series"></ApexChart>
                </v-col>
              </v-row>
              <v-row no-gutters class="my-3">
                <v-col cols="6" md="5" lg="6" xl="4" class="my-auto text-truncate">
                  <span>용역</span>
                </v-col>
                <v-col cols="6" md="7" lg="6" xl="8">
                  <ApexChart v-if="apexLoading" height="35" type="area" :options="mock.apexArea2.options"
                    :series="mock.apexArea2.series"></ApexChart>
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col cols="6" md="5" lg="6" xl="4" class="my-auto text-truncate">
                  <span>물품</span>
                </v-col>
                <v-col cols="6" md="7" lg="6" xl="8">
                  <ApexChart v-if="apexLoading" height="35" type="area" :options="mock.apexArea3.options"
                    :series="mock.apexArea3.series"></ApexChart>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
        
        <!-- 발주처 별 시설 물품 용역 하루 수집 건수 -->
        <!-- <v-col lg="3" sm="6" md="5" cols="12">
          <v-card class="mx-1 mb-1" style="height: 228px">
            <v-card-title class="flex-nowrap pa-6 pb-3">
              <p class="text-truncate">Revenue Breakdown</p>
              <v-spacer></v-spacer>
              <v-menu>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon v-bind="attrs" v-on="on">
                    <v-icon color="textColor">mdi-dots-vertical</v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item
                    v-for="(item, i) in mock.menu"
                    :key="i"
                    @click="() => {}"
                  >
                    <v-list-item-title>{{ item }}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-card-title>
            <v-card-text class="pa-6 pt-0 mb-1">
              <v-row no-gutters>
                <v-col cols="12">
                  <ApexChart
                    height="124"
                    type="donut"
                    class="mt-1"
                    :options="mock.apexPie.options"
                    :series="generatePieSeries()"
                  ></ApexChart>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col> -->
      </v-row>
    </div>
  </v-container>
</template>

<script>
  import ApexChart from "vue-apexcharts";
  //import Trend from "vuetrend";
  import mock from './mock'

  export default {
    name: 'test',
    components: {
      ApexChart,
      //Trend
    },
    data() {
      return {
        mock,
        clientArr: [],
        menuIdx: 0,
        menuName: "나라장터",
        activeRpsntOrgnzCd:1,
        dailyCountByRpsntOrgnz:0

      }
    },
    mounted() {
      this.getdailyDataList(),
      this.getRpsntOrgnzList(),
      this.getCountByRpsntOrgnzList(),
      this.getDailyCountByRpsntOrgnz()
    },
    methods: {
      getdailyDataList: function () {
        let getData = {};
        this.$http.get('/dailycount', getData)
          .then(res => {
            this.datalist = res.data

            this.datalist.forEach((e, idx) => {
              var obj = {
                count: this.datalist[idx].count,
                date: this.datalist[idx].date
              }
              this.$set(mock.chart.chartOptions.xaxis.categories, idx, obj.date)
              this.$set(mock.chart.series[0].data, idx, obj.count)
            })
          })
          .catch(error => console.log(error));
      },
      getRpsntOrgnzList: function () {
        this.$http.get('/dailycount/rspntorgnz')
          .then(res => {
            this.datalist = res.data

            this.datalist.forEach((e, idx) => {
              var obj = {
                rspntOrgnzCd: this.datalist[idx].RPSNT_ORGNZ_CD,
                rspntOrgnzNm: this.datalist[idx].RPSNT_ORGNZ_NM
              }
              this.$set(this.clientArr, idx, obj);
            })
          })
          .catch(error => console.log(error));
      },
      getCountByRpsntOrgnzList: function () {
        this.$http.get('/dailycount/countByRpsntOrgnz')
          .then(res => {
            this.datalist = res.data

            this.datalist.forEach((e, idx) => {
              var obj = {
                count: this.datalist[idx].count,
                rspntOrgnzNm: this.datalist[idx].RPSNT_ORGNZ_NM
              }
              this.$set(mock.distributedColumnChart.chartOptions.xaxis.categories, idx, obj.rspntOrgnzNm);
              this.$set(mock.distributedColumnChart.series[0].data, idx, obj.count);
            })
          })
          .catch(error => console.log(error));
      },
      goMenu(idx,i) {
        console.log(idx,i);
        this.menuName = i.rspntOrgnzNm;
        this.activeRpsntOrgnzCd = i.rspntOrgnzCd;
        this.menuIdx=idx;
        this.getDailyCountByRpsntOrgnz();
      },
      //발주처 별 일일 수집량
      getDailyCountByRpsntOrgnz: function(){
        var postData={
          rpsntOrgnzCd: this.activeRpsntOrgnzCd
        };
        console.log(postData)
        this.$http.get('/dailycount/dailyCountByRpsntOrgnz',postData)
        .then(res => {
            this.datalist = res.data
            this.$set(this.dailyCountByRpsntOrgnz,this.datalist)            
          })
          .catch(error => console.log(error));
      }
    }
  }
</script>