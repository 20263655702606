<template>
    <div class="ser-detail wrap suit">
        <h3 class="h3 f-i">딱 맞는 <span>맞춤공고</span></h3>
        <h2 class="h2 f-i">내 정보에 맞는 맞춤공고만 <span>톡톡!</span></h2>
        <h4 class="h4 f-i">내 경영상태, 면허정보를 입력하면 고객 정보에 맞는 공고만 제공해 드립니다.</h4>
        <div class="ser-detail__container descrip-video f-i-d">
            <video src="@/assets/file/suit_analysis_pc.mp4" autoplay muted loop class="video-pc"></video>
            <video src="@/assets/file/suit_analysis_mobile.mp4" autoplay muted loop class="video-mobile"></video>
            <div class="descrip-video__group--move pc">
                <a class="move-page-btn--bg" href="#" onclick="return false" @click="$goMenu('notiSuggest')">
                    <p class="move-page-btn--title">나에게 딱 맞는 맞춤공고 확인하러</p>
                    <div class="move-page-btn--ani">
                        <span class="ani--target"><img src="@/assets/images/main/ic_view-more_service-introduce.png" alt="화살표 모양 아이콘"></span>
                        <span class="ani--txt">TOKTOK</span>
                    </div>
                </a>
            </div>
        </div>
        <a class="descrip-video__group--move mobile" href="#" onclick="return false" @click="$goMenu('notiSuggest')">
            <p class="move-page-btn--title">나에게 딱 맞는 맞춤공고 확인하러</p>
            <div class="move-page-btn--ani">
                <span class="ani--target"><img src="@/assets/images/main/ic_view-more_service-introduce.png" alt="화살표 모양 아이콘"></span>
            </div>
        </a>
    </div>
</template>

<script>
    export default {
        name: "ServiceDetailSuit",
        mounted() {
            const fiItem = document.querySelectorAll('.f-i');
            setTimeout(function() {
                let i = 0;
                function fiItemAddClass() {
                    if (i < fiItem.length) {
                        fiItem[i].classList.add('active');
                        i++;
                        setTimeout(fiItemAddClass, 200);
                    }
                }
                fiItemAddClass();
            }, 1200);

            setTimeout(function() {
                document.querySelector('.f-i-d').classList.add('active');
            }, 2500);

        }
    }


</script>



<!--<style src="@/IntroduceView.scss" lang="scss" scoped />-->