<template>
    <div id="app">
        <BidHelperHeader ref="header"/>
<!--        <v-main class="content">-->
            <transition name="slide-fade" mode="out-in">
                <router-view />
            </transition>
<!--        </v-main>-->
        <BidHelperFooter />
    </div>
</template>

<script>
    import BidHelperHeader from '@/components/Header/BidHelperHeader';
    import BidHelperFooter from "@/components/Footer/BidHelperFooter";

    export default {
        name: 'Layout',
        components: {BidHelperHeader, BidHelperFooter },
        methods: {
        },
        mounted() {
            document.querySelector('body').style.overflowY = 'hidden';
        }
    };
    // document.querySelector('body').style.overflowY = 'hidden';
</script>
<style src="../../assets/scss/common/bid-helper.scss" lang="scss" scoped/>
