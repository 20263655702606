<template>
    <div class="password_set">
        <div class="inner">
            <div class="card">
                <p class="txt__title">아이디/비밀번호 찾기</p>
            </div>
            <div class="container">
                <ul class="tab">
                    <li>
                        <input type="radio" id="tab1-1" name="tab-1" checked>
                        <label for="tab1-1">아이디</label>
                    </li>
                    <li>
                        <input type="radio" id="tab1-2" name="tab-1">
                        <label for="tab1-2">비밀번호</label>
                    </li>
                </ul>
                <div class="tabcont">
                    <div class="tabcont__row" style="display: block;">
                        <div class="input">
                            <input type="text" placeholder=" 이름을 입력하세요">
                        </div>
                        <div class="cerification">
                            <div class="input">
                                <input type="text" placeholder="휴대폰번호를 입력하세요">
                            </div>
                            <button type="submit" class="btn__init cta"><span>인증번호</span></button>
                        </div>
                        <div class="input">
                            <input type="text" placeholder="인증번호를 입력하세요" disabled>
                        </div>
                        <button type="submit" class="btn__basic cta" disabled><span>아이디 찾기</span></button>
                    </div>
                    <div class="tabcont__row">
                        <div class="input">
                            <input type="text" placeholder="새 비밀번호">
                        </div>
                        <div class="input">
                            <input type="text" placeholder="비밀번호 확인">
                        </div>
                        <button type="submit" class="btn__basic cta" disabled><span>비밀번호 변경</span></button>
                    </div>
                </div>
            </div>
        </div>
        <!--        popup     .popup__wrap에 active 클래스 추가-->

        <div class="popup__wrap">
            <div class="popup__item">
                <div class="popup__item--img"><img src="@/assets/ico_check_popup.svg" alt="체크모양 이미지"></div>
                <div class="popup__txt-box"><p class="txt__title">비밀번호가 변경되었습니다.</p></div>
                <a href="javascript:void(0);" class="btn__basic cta"><span>로그인 화면 이동</span></a>
            </div>
            <!--     // popup-->
        </div>
    </div>
</template>


<script>
    export default {
        name: "PasswordSet",
        methods: {
        },
        created() {

        },

        mounted() {
            // 탭에 따른 메뉴 표시
            const tabMenu = document.querySelectorAll('.tab > li');
            const tabCont = document.querySelectorAll('.tabcont__row');

            tabMenu.forEach(function(element, index){
                element.addEventListener('click', function(){
                    for (let i = 0; i < tabMenu.length; i++) {
                        tabCont[i].style.display = 'none';
                    }
                    tabCont[index].style.display = 'block';
                });
            });
            // /탭에 따른 메뉴 표시

            // input 이벤트
            const inputs = document.querySelectorAll('input');
            inputs.forEach((element) => {

                element.addEventListener('keyup', () => {
                    const word =  element.value.length;
                    console.log(word);
                    if (word > 0 && word < 6) {
                        element.classList.add('input-invalid');
                    } else {
                        element.classList.remove('input-invalid');
                        element.classList.add('input-valid');
                    }
                });
                element.addEventListener('focus', () => {
                    //블라블라
                });
                element.addEventListener('blur', () => {
                    const word =  element.value.length;
                    if (word > 0 && word < 6) {
                        element.classList.add('input-invalid');
                    } else {
                        element.classList.remove('input-invalid');
                        element.classList.remove('input-valid');
                    }
                });
            });
            // /input 이벤트
        }
    }


</script>

<style src="./PasswordSet.scss" lang="scss" scoped />