<template>
    <div class="spinner-container">
        <div class="spinner"/>
    </div>
</template>

<script>
    export default {};
</script>

<style scoped>
    .spinner-container {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 100%;
        height: 100%;
        /*border: 1px solid red;*/
        transform: translate(-50%, -50%);
        display: flex;
        justify-content: center;
        align-items: center;
        /*height: 240px;*/
    }

    .spinner {

        width: 18px;
        height: 18px;
        border-radius: 50%;
        border: 5px solid #e0e0e0;
        border-bottom: 5px solid #A1C9FE;
        animation: spin 1s linear infinite;

    }

    @keyframes spin {
        from {
            transform: rotate(0deg);
        }
        to {
            transform: rotate(360deg);
        }
    }
</style>