<template>
    <div class="analysis-detail__wrap">
        <!-- 공고상세 메뉴 이름과 닫기 버튼 -->
        <div class="analysis-detail__container head">
            <p class="analysis-detail__container--item txt--title">공고 상세</p>
            <!-- 공고 원문 버튼과 인쇄버튼 -->
            <div class="analysis-detail__box title-btn">
                <a href="#" onclick="return false" class="title-btn--btn btn-basic btn-line-cw" @click="fnOrignOpen()">
                    <img :src="rpsntOrgnzImg" onerror="this.src='@/assets/images/img_order-logo_empty_analysis-detail.jpg'" alt=""/>
                    <span class="order-name">{{rpsntOrgnzNm}}</span><span> 공고 원문</span> <i class="ic--arrow--view"></i>
                </a>
                <a href="#" onclick="return false" class="title-btn--btn btn-basic btn-line-cw print" @click="printPage()">
                    <i class="ic--print"></i>인쇄
                </a>
            </div>
            <!-- /공고 원문 버튼과 인쇄버튼 -->
            <a href="javascript:void(window.close())" class="analysis-detail__container--item btn-basic popup__close"><i
                    class="ic--close"></i></a>

        </div>
        <!-- /공고상세 메뉴 이름과 닫기 버튼 -->

        <!-- 공고상세 내용 -->
        <div class="analysis-detail__container body" :class="notiDetail.notiStatus =='취소' ? 'analy-cancle' : ''">

            <!-- 공고의 타이틀, 공고 번호, 투찰현황 -->
            <div class="analysis-detail__area info-list">

                <!-- 공고 타이틀, 공고번호, 발주처 -->
                <div class="analysis-detail__box title">
                    <p class="txt--title">{{notiDetail.notiNm}}</p>

                    <!-- 요약정보 pc-->
                    <ul class="analysis-detail__group summary-info__group pc">
                        <li class="summary-info__item">
                            <p class="txt--title">공고 번호</p>
                            <p class="txt--txt">{{notiDetail.notiNo}}{{notiDetail.notiSeq ? '-'+notiDetail.notiSeq : ''}}</p>
                        </li>
                        <li class="summary-info__item">
                            <p class="txt--title">발주처</p>
                            <p class="txt--txt">{{notiDetail.orderOffiNm}}</p>
                        </li>
                        <li class="summary-info__item actual">
                            <p class="txt--title">실수요기관</p>
                            <p class="txt--txt">{{notiDetail.actulDnttCdNm}}</p>
                        </li>
                    </ul>
                    <!-- /요약정보 pc-->

                    <!-- 요약정보- 모바일 -->
                    <ul class="analysis-detail__group summary-info__group mobile">
                        <li class="summary-info__item">
                            <p class="txt--txt">{{notiDetail.orderOffiNm}}</p>
                        </li>
                        <li class="summary-info__item">
                            <div class="summary-info__element">
                                <p class="txt--txt">{{notiDetail.notiNo}}{{notiDetail.notiSeq ? '-'+notiDetail.notiSeq : ''}}</p>
                                <button type="button" class="btn__copy" @click="copyNotiNo()"></button>
                            </div>

                        </li>
                    </ul>
                    <!-- 요약정보- 모바일 -->
                </div>
                <!--/공고 타이틀, 공고번호, 발주처 -->

                <!-- 투찰현황 analy-situon__item 옆에 active 추가 하면 활성화됨  -->
                <ul class="analysis-detail__box analy-situon__box">
                    <li class="analy-situon__item analy-before" :class="notiDetail.notiStatus == '투찰전' ? 'active':''">
                        <p class="txt--title">투찰 전</p>
                    </li>
                    <li class="analy-situon__item analy-ing" :class="notiDetail.notiStatus == '투찰가능' ? 'active':''">
                        <p class="txt--title">투찰 가능</p>
                    </li>
                    <li class="analy-situon__item analy-fin" :class="notiDetail.notiStatus == '투찰마감' ? 'active':''">
                        <p class="txt--title">투찰 마감</p>
                    </li>
                    <li class="analy-situon__item analy-announce" :class="notiDetail.notiStatus == '개찰완료' ? 'active':''">
                        <p class="txt--title">개찰완료</p>
                    </li>
                </ul>
                <!-- /투찰현황 -->
            </div>
            <!-- /공고의 타이틀, 공고 번호, 투찰현황 -->

            <!-- 공고 정보와 개찰정보 -->
            <div class="analysis-detail__area content">
                <ul class="analysis-detail__group--tab tab">
                    <li class="analysis-detail__group--tab menu" :class="tabMenu==0 ? 'active' : ''" @click="tabMenu=0">
                        공고정보
                    </li>
                    <li class="analysis-detail__group--tab menu" :class="tabMenu==1 ? 'active' : ''" @click="tabMenu=1">
                        개찰정보
                    </li>
                </ul>
                <div class="analysis-detail__group--tabcont tabcont">
                    <!-- 공고 정보 탭 -->
                    <div class="analy-info" v-show="tabMenu==0">
                        <!-- 정보 카드  pc-->
                        <div class="analysis-detail__unit info-card__unit pc">
                            <div class="info-card__box">
                                <!-- 기본정보 카드 -->
                                <ul class="info-card__item basic-info">
                                    <li class="info-card__element head">기본정보</li>
                                    <ul class="info-card__element body info-card">
                                        <li class="info-card__part">
                                            <span class="txt--title">지역</span>
                                            <span class="txt--txt">{{notiDetail.notiAreaDisp}}</span>
                                        </li>
                                        <li class="info-card__part">
                                            <span class="txt--title">면허</span>
                                            <span class="txt--txt">{{notiDetail.notiLicnsDisp}}</span>
                                        </li>
                                        <li class="info-card__part"  v-show="notiDetail.noticeMainLicnsList != null && notiDetail.noticeMainLicnsList.length > 0">
                                            <span class="txt--title">주력면허</span>
                                            <span class="txt--txt" >{{noticeMainLicnsList}}</span>
                                        </li>
                                        <li class="info-card__part">
                                            <span class="txt--title">공사구분</span>
                                            <span class="txt--txt">{{notiDetail.mtltyAdvcPsblYnCnstwkNm == null ? '-' : notiDetail.mtltyAdvcPsblYnCnstwkNm}}</span>
                                        </li>
                                        <li class="info-card__part">
                                            <span class="txt--title">추정가격</span>
                                            <span class="txt--txt">{{notiDetail.presmAmt | currency}}원</span>
                                        </li>
                                        <li class="info-card__part">
                                            <span class="txt--title">기초금액</span>
                                            <span class="txt--txt">{{notiDetail.baseAmt | currency}}원</span>
                                        </li>
                                    </ul>
                                </ul>
                                <!-- /기본정보 카드 -->

                                <ul class="info-card__item space"></ul>

                                <!-- 투찰일정 카드 -->
                                <ul class="info-card__item analy-info">
                                    <li class="info-card__element head">투찰일정</li>
                                    <ul class="info-card__element body info-card">
                                        <li class="info-card__part">
                                            <span class="txt--title">공고게시</span>
                                            <span class="txt--txt">{{notiDetail.notiStrtDt | notidate}}</span>
                                        </li>
                                        <li class="info-card__part">
                                            <span class="txt--title">투찰시작</span>
                                            <span class="txt--txt">{{notiDetail.bddprStrtDt | notidate}}</span>
                                        </li>
                                        <li class="info-card__part">
                                            <span class="txt--title">참가마감</span>
                                            <span class="txt--txt">{{notiDetail.regstFinDt | notidate}}</span>
                                        </li>
                                        <li class="info-card__part">
                                            <span class="txt--title">협정마감</span>
                                            <span class="txt--txt">{{notiDetail.spdmagmtFinDt | notidate}}</span>
                                        </li>
                                        <li class="info-card__part">
                                            <span class="txt--title">투찰마감</span>
                                            <span class="txt--txt">{{notiDetail.bddprFinDt | notidate}}</span>
                                        </li>
                                        <li class="info-card__part">
                                            <span class="txt--title">공고개찰</span>
                                            <span class="txt--txt">{{notiDetail.opbdDt | notidate}}</span>
                                        </li>
                                    </ul>
                                </ul>
                                <!-- /투찰일정 카드 -->
                            </div>
                            <div class="info-card__box">
                                <!-- 추가정보 카드 -->
                                <ul class="info-card__item add-info">
                                    <li class="info-card__element head">추가정보 </li>
                                    <ul class="info-card__element body info-card">
                                        <li class="info-card__part">
                                            <span class="txt--title">적격심사</span>
                                            <span class="txt--txt">{{notiDetail.cfmtUndwrtStIdNm == null ? '-' : notiDetail.cfmtUndwrtStIdNm}}</span>
                                        </li>
                                        <li class="info-card__part">
                                            <span class="txt--title">하한율</span>
                                            <span class="txt--txt">{{notiDetail.dwnlmtRt}}{{notiDetail.dwnlmtRt !== "0" ? '%' : ''}}</span>
                                        </li>
                                        <li class="info-card__part">
                                            <span class="txt--title">사정률</span>
                                            <span class="txt--txt">{{notiDetail.minAsRt}}{{notiDetail.minAsRt !== "0" ? '%' : ''}}~{{notiDetail.maxAsRt}}{{notiDetail.maxAsRt !== "0" ? '%' : ''}}</span>
                                        </li>
                                        <li class="info-card__part">
                                            <span class="txt--title">순공사원가</span>
                                            <span class="txt--txt">{{notiDetail.purityCntrwkPrmpc | currency}}원<span class="empha"> (기초금액 대비: {{notiDetail.purityCntrwkPrmpcRt}}%)</span></span>
                                        </li>
                                        <li class="info-card__part">
                                            <span class="txt--title">법정금액(A)</span>
                                            <span class="txt--txt">{{notiDetail.stttAmt | currency}}원</span>
                                        </li>
                                    </ul>
                                </ul>
                                <!-- /추가정보 카드 -->
                                <ul class="info-card__item space"></ul>
                                <!-- 기타정보 카드 -->
                                <ul class="info-card__item etc-info">
                                    <li class="info-card__element head">기타정보</li>
                                    <ul class="info-card__element body info-card">
                                        <li class="info-card__part">
                                            <span class="txt--title">공사기간</span>
                                            <span class="txt--txt">{{notiDetail.cnstPerd == null ? '-' : notiDetail.cnstPerd }}</span>
                                        </li>
                                        <li class="info-card__part">
                                            <span class="txt--title">공사지역</span>
                                            <span class="txt--txt">{{notiDetail.cnstAreaLocat == null?  '-' : notiDetail.cnstAreaLocat}}</span>
                                        </li>
                                        <li class="info-card__part">
                                            <span class="txt--title">내역입찰여부</span>
                                            <span class="txt--txt">{{notiDetail.spcfctBidYn=='Y' ? '내역서 첨부' : '내역서 첨부안함'}}</span>
                                        </li>
                                        <li class="info-card__part">
                                            <span class="txt--title">보증금납부방법</span>
                                            <span class="txt--txt">{{notiDetail.guramtPymYn=='Y' ? '입찰보증금 납부' : '입찰보증금 납부면제'}}</span>
                                        </li>
                                    </ul>
                                </ul>
                                <!-- / 기타정보 카드 -->
                            </div>
                        </div>
                        <!-- /정보 카드 pc-->

                        <!-- 정보 카드 모바일 -->
                        <div class="analysis-detail__unit info-card__unit mobile">
                            <div class="info-card__box center">
                                <!-- 기본정보 카드 -->
                                <div class="info-card__slide">
                                    <ul class="info-card__item basic-info">
                                        <li class="info-card__element head">기본정보</li>
                                        <ul class="info-card__element body info-card">
                                            <li class="info-card__part">
                                                <span class="txt--title">지역</span>
                                                <span class="txt--txt">{{notiDetail.notiAreaDisp}}</span>
                                            </li>
                                            <li class="info-card__part">
                                                <span class="txt--title">면허</span>
                                                <span class="txt--txt">{{notiDetail.notiLicnsDisp}}</span>
                                            </li>
                                            <li class="info-card__part">
                                                <span class="txt--title">공사구분</span>
                                                <span class="txt--txt">{{notiDetail.mtltyAdvcPsblYnCnstwkNm == null ? '-' : notiDetail.mtltyAdvcPsblYnCnstwkNm}}</span>
                                            </li>
                                            <li class="info-card__part">
                                                <span class="txt--title">추정가격</span>
                                                <span class="txt--txt">{{notiDetail.presmAmt | currency}}</span>
                                            </li>
                                            <li class="info-card__part">
                                                <span class="txt--title">기초금액</span>
                                                <span class="txt--txt">{{notiDetail.baseAmt | currency}}</span>
                                            </li>
                                        </ul>
                                    </ul>
                                </div>
                                <!-- /기본정보 카드 -->

                                <!-- 투찰일정 카드 -->
                                <div class="info-card__slide">
                                    <ul class="info-card__item analy-info">
                                        <li class="info-card__element head">투찰일정</li>
                                        <ul class="info-card__element body info-card">
                                            <li class="info-card__part">
                                                <span class="txt--title">공고게시</span>
                                                <span class="txt--txt">{{notiDetail.notiStrtDt | notidate}}</span>
                                            </li>
                                            <li class="info-card__part">
                                                <span class="txt--title">투찰시작</span>
                                                <span class="txt--txt">{{notiDetail.bddprStrtDt | notidate}}</span>
                                            </li>
                                            <li class="info-card__part">
                                                <span class="txt--title">참가마감</span>
                                                <span class="txt--txt">{{notiDetail.regstFinDt | notidate}}</span>
                                            </li>
                                            <li class="info-card__part">
                                                <span class="txt--title">협정마감</span>
                                                <span class="txt--txt">{{notiDetail.spdmagmtFinDt | notidate}}</span>
                                            </li>
                                            <li class="info-card__part">
                                                <span class="txt--title">투찰마감</span>
                                                <span class="txt--txt">{{notiDetail.bddprFinDt | notidate}}</span>
                                            </li>
                                            <li class="info-card__part">
                                                <span class="txt--title">공고개찰</span>
                                                <span class="txt--txt">{{notiDetail.opbdDt | notidate}}</span>
                                            </li>
                                        </ul>
                                    </ul>
                                </div>

                                <!-- /투찰일정 카드 -->

                                <!-- 추가정보 카드 -->
                                <div class="info-card__slide">
                                    <ul class="info-card__item add-info">
                                        <li class="info-card__element head">추가정보 </li>
                                        <ul class="info-card__element body info-card">
                                            <li class="info-card__part">
                                                <span class="txt--title">적격심사</span>
                                                <span class="txt--txt">{{notiDetail.cfmtUndwrtStIdNm == null ? '-' : notiDetail.cfmtUndwrtStIdNm}}</span>
                                            </li>
                                            <li class="info-card__part">
                                                <span class="txt--title">하한율</span>
                                                <span class="txt--txt">{{notiDetail.dwnlmtRt}}{{notiDetail.dwnlmtRt !== "0" ? '%' : ''}}</span>
                                            </li>
                                            <li class="info-card__part">
                                                <span class="txt--title">사정률</span>
                                                <span class="txt--txt">{{notiDetail.minAsRt}}{{notiDetail.minAsRt !== "0" ? '%' : ''}}~{{notiDetail.maxAsRt}}{{notiDetail.maxAsRt !== "0" ? '%' : ''}}</span>
                                            </li>
                                            <li class="info-card__part">
                                                <span class="txt--title">순공사원가</span>
                                                <span class="txt--txt">{{notiDetail.purityCntrwkPrmpc | currency}}원<span class="empha"> (기초금액 대비: {{notiDetail.purityCntrwkPrmpcRt}}%)</span></span>
                                            </li>
                                            <li class="info-card__part">
                                                <span class="txt--title">법정금액(A)</span>
                                                <span class="txt--txt">{{notiDetail.stttAmt | currency}}원</span>
                                            </li>
                                        </ul>
                                    </ul>
                                </div>
                                <!-- /추가정보 카드 -->

                                <!-- 기타정보 카드 -->
                                <div class="info-card__slide">
                                    <ul class="info-card__item etc-info">
                                        <li class="info-card__element head">기타정보</li>
                                        <ul class="info-card__element body info-card">
                                            <li class="info-card__part">
                                                <span class="txt--title">공사기간</span>
                                                <span class="txt--txt">{{notiDetail.cnstPerd == null ? '-': notiDetail.cnstPerd}}</span>
                                            </li>
                                            <li class="info-card__part">
                                                <span class="txt--title">공사지역</span>
                                                <span class="txt--txt">{{notiDetail.cnstAreaLocat == null ? '-': notiDetail.cnstAreaLocat}}</span>
                                            </li>
                                            <li class="info-card__part">
                                                <span class="txt--title">내역입찰여부</span>
                                                <span class="txt--txt">{{notiDetail.spcfctBidYn=='Y' ? '내역서 첨부' : '내역서 첨부안함'}}</span>
                                            </li>
                                            <li class="info-card__part">
                                                <span class="txt--title">보증금납부방법</span>
                                                <span class="txt--txt">{{notiDetail.guramtPymYn=='Y' ? '입찰보증금 납부' : '입찰보증금 납부면제'}}</span>
                                            </li>
                                        </ul>
                                    </ul>
                                </div>
                                <!-- / 기타정보 카드 -->
                            </div>
                        </div>
                        <!-- /정보 카드 모바일 -->

                        <!-- 첨부파일 다운로드 -->
                        <div class="analysis-detail__unit file-download__unit">
                            <p class="txt--title">첨부파일</p>
                            <div class="file-download__group" v-show="notiFile != null">
                                <p class="file-download__box file-download__box--title">공고문</p>
                                <ul class="file-download__box">
                                    <li class="file-download__item">
                                        <a href="#" onclick="return false" class="file-download--view" @click="openNotiAtchmnfl(notiFile)">{{notiFile != null ? notiFile.fileNm : ''}}</a>
                                        <a href="#" onclick="return false" class="file-download--btn" @click="fnAtchmnFlDown(notiFile)"><i class="ic--download"></i></a>
                                    </li>
                                </ul>
                            </div>
                            <div class="file-download__group" v-show="notiAtchmnflList.length > 0">
                                <p class="file-download__box file-download__box--title">첨부파일</p>
                                <ul class="file-download__box">
                                    <li class="file-download__item" v-for="items in notiAtchmnflList" v-bind:key="items.id">
                                        <a href="#" onclick="return false" class="file-download--view" @click="openNotiAtchmnfl(items)">{{items.fileNm}}</a>
                                        <a href="#" onclick="return false" class="file-download--btn" @click="fnAtchmnFlDown(items)"><i class="ic--download"></i></a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <!-- /첨부파일 다운로드 -->
                    </div>
                    <!-- /공고 정보 탭 -->

                    <!-- 개찰정보 탭 -->
                    <div class="annonc-info" v-show="tabMenu==1">
                        <!-- 알람 -->
                        <div class="analysis-detail__unit notice" v-show="notiDetail.notiStatus != '개찰완료'">
                            <span>개찰 정보는 개찰이 완료된 후, 확인할 수 있습니다.</span>
                        </div>
                        <!-- /알람 -->

                        <!-- 내투찰순위 -->
                        <div class="analysis-detail__unit my-rank">
                            <p class="txt--title">내 투찰 순위</p>
                            <!-- pc -->
                            <div class="grid__wrap analysis-detail__grid my-rank__grid pc">
                                <div class="grid__container">
                                    <ul class="grid__group analysis-detail__grid--group head">
                                        <li class="grid__cell analysis-detail__grid--cell my-rank__cell">
                                            <p>순위</p>
                                        </li>
                                        <li class="grid__cell analysis-detail__grid--cell my-rank__cell">
                                            <p>사업자번호</p>
                                        </li>
                                        <li class="grid__cell analysis-detail__grid--cell my-rank__cell">
                                            <p>업체명</p>
                                        </li>
                                        <li class="grid__cell analysis-detail__grid--cell my-rank__cell">
                                            <p>대표자명</p>
                                        </li>
                                        <li class="grid__cell analysis-detail__grid--cell my-rank__cell">
                                            <p>투찰금액</p>
                                        </li>
                                        <li class="grid__cell analysis-detail__grid--cell my-rank__cell">
                                            <p>편차</p>
                                        </li>
                                        <li class="grid__cell analysis-detail__grid--cell my-rank__cell">
                                            <p>예가대비<br>투찰율(%)</p>
                                        </li>
                                        <li class="grid__cell analysis-detail__grid--cell my-rank__cell">
                                            <p>기초대비<br>투찰율(%)</p>
                                        </li>
                                        <li class="grid__cell analysis-detail__grid--cell my-rank__cell">
                                            <p>가격<br>점수</p>
                                        </li>
                                        <li class="grid__cell analysis-detail__grid--cell my-rank__cell">
                                            <p>추첨 번호</p>
                                        </li>
                                        <li class="grid__cell analysis-detail__grid--cell my-rank__cell">
                                            <p>비고</p>
                                        </li>
                                    </ul>
                                    <ul class="grid__group analysis-detail__grid--group body" v-show="!notiBddprPrtcCmp  || (notiBddprPrtcCmp && notiBddprPrtcCmp.cmpRank != 1)">
                                        <li class="grid__cell analysis-detail__grid--cell my-rank__cell">
                                            <p>{{notiBddprPrtcCmp ? notiBddprPrtcCmp.cmpRank : '-'}}</p>
                                        </li>
                                        <li class="grid__cell analysis-detail__grid--cell my-rank__cell">
                                            <p>{{notiBddprPrtcCmp ? notiBddprPrtcCmp.bizNo : '-' | formatbizno}}</p>
                                        </li>
                                        <li class="grid__cell analysis-detail__grid--cell my-rank__cell">
                                            <p>{{notiBddprPrtcCmp ? notiBddprPrtcCmp.cmpNm : '-'}}</p>
                                        </li>
                                        <li class="grid__cell analysis-detail__grid--cell my-rank__cell">
                                            <p>{{notiBddprPrtcCmp ? notiBddprPrtcCmp.rpsntvNm : '-'}}</p>
                                        </li>
                                        <li class="grid__cell analysis-detail__grid--cell my-rank__cell">
                                            <p>{{notiBddprPrtcCmp ? notiBddprPrtcCmp.bddprAmt : '-' | currency}}</p>
                                        </li>
                                        <li class="grid__cell analysis-detail__grid--cell my-rank__cell">
                                            <p>{{notiBddprPrtcCmp ? notiBddprPrtcCmp.bddprAmt - notiDetail.opbdPrargAmt : '-' | currency}}</p>
                                        </li>
                                        <li class="grid__cell analysis-detail__grid--cell my-rank__cell">
                                            <p>{{notiBddprPrtcCmp ? notiBddprPrtcCmp.opbdPrargPricePercent : '-'}}</p>
                                        </li>
                                        <li class="grid__cell analysis-detail__grid--cell my-rank__cell">
                                            <p>{{notiBddprPrtcCmp ? notiBddprPrtcCmp.calBddprRt : '-'}}</p>
                                        </li>
                                        <li class="grid__cell analysis-detail__grid--cell my-rank__cell">
                                            <p>{{notiBddprPrtcCmp ? notiBddprPrtcCmp.priceScore : '-'}}</p>
                                        </li>
                                        <li class="grid__cell analysis-detail__grid--cell my-rank__cell">
                                            <p>{{notiBddprPrtcCmp ? notiBddprPrtcCmp.choicePrdprcNo : '-'}}</p>
                                        </li>
                                        <li class="grid__cell analysis-detail__grid--cell my-rank__cell">
                                            <p>{{ notiBddprPrtcCmp && notiBddprPrtcCmp.lastSbidYn === 'Y' ? '최종낙찰' : (notiBddprPrtcCmp && notiBddprPrtcCmp.rmark ? notiBddprPrtcCmp.rmark : '-') }}</p>
                                        </li>
                                    </ul>
                                    <!-- 1순위 일 때,  analysis-detail__grid--group 옆에 analysis-detail__grid--1th 클래스명 추가 -->
                                    <ul class="grid__group analysis-detail__grid--group body analysis-detail__grid--1th" v-show="notiBddprPrtcCmp && notiBddprPrtcCmp.cmpRank == 1">
                                        <li class="grid__cell analysis-detail__grid--cell my-rank__cell"></li>
                                        <li class="grid__cell analysis-detail__grid--cell my-rank__cell">
                                            <p>{{notiBddprPrtcCmp ? notiBddprPrtcCmp.bizNo : '-' | formatbizno}}</p>
                                        </li>
                                        <li class="grid__cell analysis-detail__grid--cell my-rank__cell">
                                            <p>{{notiBddprPrtcCmp ? notiBddprPrtcCmp.cmpNm : ''}}</p>
                                        </li>
                                        <li class="grid__cell analysis-detail__grid--cell my-rank__cell">
                                            <p>{{notiBddprPrtcCmp ? notiBddprPrtcCmp.rpsntvNm : ''}}</p>
                                        </li>
                                        <li class="grid__cell analysis-detail__grid--cell my-rank__cell">
                                            <p>{{notiBddprPrtcCmp ? notiBddprPrtcCmp.bddprAmt : '' | currency}}</p>
                                        </li>
                                        <li class="grid__cell analysis-detail__grid--cell my-rank__cell">
                                            <p>{{notiBddprPrtcCmp ? notiBddprPrtcCmp.bddprAmt - notiDetail.opbdPrargAmt : '' | currency}}</p>
                                        </li>
                                        <li class="grid__cell analysis-detail__grid--cell my-rank__cell">
                                            <p>{{notiBddprPrtcCmp ? notiBddprPrtcCmp.opbdPrargPricePercent : ''}}</p>
                                        </li>
                                        <li class="grid__cell analysis-detail__grid--cell my-rank__cell">
                                            <p>{{notiBddprPrtcCmp ? notiBddprPrtcCmp.calBddprRt : ''}}</p>
                                        </li>
                                        <li class="grid__cell analysis-detail__grid--cell my-rank__cell">
                                            <p>{{notiBddprPrtcCmp ? notiBddprPrtcCmp.priceScore : ''}}</p>
                                        </li>
                                        <li class="grid__cell analysis-detail__grid--cell my-rank__cell">
                                            <p>{{notiBddprPrtcCmp ? notiBddprPrtcCmp.choicePrdprcNo : ''}}</p>
                                        </li>
                                        <li class="grid__cell analysis-detail__grid--cell my-rank__cell">
                                            <p>{{ notiBddprPrtcCmp && notiBddprPrtcCmp.lastSbidYn === 'Y' ? '최종낙찰' : (notiBddprPrtcCmp && notiBddprPrtcCmp.rmark ? notiBddprPrtcCmp.rmark : '-') }}</p>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <!-- /pc -->

                            <!-- mobile -->
                            <div class="grid__wrap analysis-detail__grid my-rank__grid mobile">
                                <div class="grid__container analysis-detail__grid--1th" v-show="notiBddprPrtcCmp && notiBddprPrtcCmp.cmpRank == 1">
                                    <ul class="grid__group analysis-detail__grid--group head">
                                        <li class="grid__cell">
                                            <p>순위</p>
                                        </li>
                                        <li class="grid__cell">
                                            <p></p>
                                        </li>
                                    </ul>
                                    <ul class="grid__group analysis-detail__grid--group head">
                                        <li class="grid__cell">
                                            <p>사업자번호</p>
                                        </li>
                                        <li class="grid__cell">
                                            <p>{{notiBddprPrtcCmp ? notiBddprPrtcCmp.bizNo : '-' | formatbizno}}</p>
                                        </li>
                                    </ul>
                                    <ul class="grid__group analysis-detail__grid--group head">
                                        <li class="grid__cell">
                                            <p>업체명</p>
                                        </li>
                                        <li class="grid__cell">
                                            <p>{{notiBddprPrtcCmp ? notiBddprPrtcCmp.cmpNm : ''}}</p>
                                        </li>
                                    </ul>
                                    <ul class="grid__group analysis-detail__grid--group head">
                                        <li class="grid__cell">
                                            <p>대표자명</p>
                                        </li>
                                        <li class="grid__cell">
                                            <p>{{notiBddprPrtcCmp ? notiBddprPrtcCmp.rpsntvNm : ''}}</p>
                                        </li>
                                    </ul>
                                    <ul class="grid__group analysis-detail__grid--group head">
                                        <li class="grid__cell">
                                            <p>투찰금액</p>
                                        </li>
                                        <li class="grid__cell">
                                            <p>{{notiBddprPrtcCmp ? notiBddprPrtcCmp.bddprAmt : '' | currency}}</p>
                                        </li>
                                    </ul>
                                    <ul class="grid__group analysis-detail__grid--group head">
                                        <li class="grid__cell">
                                            <p>편차</p>
                                        </li>
                                        <li class="grid__cell">
                                            <p>{{notiBddprPrtcCmp ? notiBddprPrtcCmp.bddprAmt - notiDetail.opbdPrargAmt : '' | currency}}</p>
                                        </li>
                                    </ul>
                                    <ul class="grid__group analysis-detail__grid--group head">
                                        <li class="grid__cell analysis-detail__grid--two-line">
                                            <p>예가대비 <span>투찰율(%)</span></p>
                                        </li>
                                        <li class="grid__cell analysis-detail__grid--two-line">
                                            <p>{{notiBddprPrtcCmp ? notiBddprPrtcCmp.opbdPrargPricePercent : ''}}</p>
                                        </li>
                                    </ul>
                                    <ul class="grid__group analysis-detail__grid--group head">
                                        <li class="grid__cell analysis-detail__grid--two-line">
                                            <p>기초대비 <span>투찰율(%)</span></p>
                                        </li>
                                        <li class="grid__cell analysis-detail__grid--two-line">
                                            <p>{{notiBddprPrtcCmp ? notiBddprPrtcCmp.calBddprRt : ''}}</p>
                                        </li>
                                    </ul>
                                    <ul class="grid__group analysis-detail__grid--group head">
                                        <li class="grid__cell">
                                            <p>가격 점수</p>
                                        </li>
                                        <li class="grid__cell">
                                            <p>{{notiBddprPrtcCmp ? notiBddprPrtcCmp.priceScore : ''}}</p>
                                        </li>
                                    </ul>
                                    <ul class="grid__group analysis-detail__grid--group head">
                                        <li class="grid__cell">
                                            <p>추첨 번호</p>
                                        </li>
                                        <li class="grid__cell">
                                            <p>{{notiBddprPrtcCmp ? notiBddprPrtcCmp.choicePrdprcNo : ''}}</p>
                                        </li>
                                    </ul>
                                    <ul class="grid__group analysis-detail__grid--group head">
                                        <li class="grid__cell">
                                            <p>비고</p>
                                        </li>
                                        <li class="grid__cell">
                                            <p>{{ notiBddprPrtcCmp && notiBddprPrtcCmp.lastSbidYn === 'Y' ? '최종낙찰' : (notiBddprPrtcCmp && notiBddprPrtcCmp.rmark ? notiBddprPrtcCmp.rmark : '-') }}</p>
                                        </li>
                                    </ul>

                                </div>
                                <div class="grid__container" v-show="!notiBddprPrtcCmp  || (notiBddprPrtcCmp && notiBddprPrtcCmp.cmpRank != 1)">
                                    <ul class="grid__group analysis-detail__grid--group head">
                                        <li class="grid__cell">
                                            <p>순위</p>
                                        </li>
                                        <li class="grid__cell">
                                            <p>{{notiBddprPrtcCmp ? notiBddprPrtcCmp.cmpRank : '-'}}</p>
                                        </li>
                                    </ul>
                                    <ul class="grid__group analysis-detail__grid--group head">
                                        <li class="grid__cell">
                                            <p>사업자번호</p>
                                        </li>
                                        <li class="grid__cell">
                                            <p>{{notiBddprPrtcCmp ? notiBddprPrtcCmp.bizNo : '-' | formatbizno}}</p>
                                        </li>
                                    </ul>
                                    <ul class="grid__group analysis-detail__grid--group head">
                                        <li class="grid__cell">
                                            <p>업체명</p>
                                        </li>
                                        <li class="grid__cell">
                                            <p>{{notiBddprPrtcCmp ? notiBddprPrtcCmp.cmpNm : '-'}}</p>
                                        </li>
                                    </ul>
                                    <ul class="grid__group analysis-detail__grid--group head">
                                        <li class="grid__cell">
                                            <p>대표자명</p>
                                        </li>
                                        <li class="grid__cell">
                                            <p>{{notiBddprPrtcCmp ? notiBddprPrtcCmp.rpsntvNm : '-'}}</p>
                                        </li>
                                    </ul>
                                    <ul class="grid__group analysis-detail__grid--group head">
                                        <li class="grid__cell">
                                            <p>투찰금액</p>
                                        </li>
                                        <li class="grid__cell">
                                            <p>{{notiBddprPrtcCmp ? notiBddprPrtcCmp.bddprAmt : '-' | currency}}</p>
                                        </li>
                                    </ul>
                                    <ul class="grid__group analysis-detail__grid--group head">
                                        <li class="grid__cell">
                                            <p>편차</p>
                                        </li>
                                        <li class="grid__cell">
                                            <p>{{notiBddprPrtcCmp ? notiBddprPrtcCmp.bddprAmt - notiDetail.opbdPrargAmt : '-' | currency}}</p>
                                        </li>
                                    </ul>
                                    <ul class="grid__group analysis-detail__grid--group head">
                                        <li class="grid__cell analysis-detail__grid--two-line">
                                            <p>예가대비 <span>투찰율(%)</span></p>
                                        </li>
                                        <li class="grid__cell analysis-detail__grid--two-line">
                                            <p>{{notiBddprPrtcCmp ? notiBddprPrtcCmp.opbdPrargPricePercent : '-'}}</p>
                                        </li>
                                    </ul>
                                    <ul class="grid__group analysis-detail__grid--group head">
                                        <li class="grid__cell analysis-detail__grid--two-line">
                                            <p>기초대비 <span>투찰율(%)</span></p>
                                        </li>
                                        <li class="grid__cell analysis-detail__grid--two-line">
                                            <p>{{notiBddprPrtcCmp ? notiBddprPrtcCmp.calBddprRt : '-'}}</p>
                                        </li>
                                    </ul>
                                    <ul class="grid__group analysis-detail__grid--group head">
                                        <li class="grid__cell">
                                            <p>가격 점수</p>
                                        </li>
                                        <li class="grid__cell">
                                            <p>{{notiBddprPrtcCmp ? notiBddprPrtcCmp.priceScore : '-'}}</p>
                                        </li>
                                    </ul>
                                    <ul class="grid__group analysis-detail__grid--group head">
                                        <li class="grid__cell">
                                            <p>추첨 번호</p>
                                        </li>
                                        <li class="grid__cell">
                                            <p>{{notiBddprPrtcCmp ? notiBddprPrtcCmp.choicePrdprcNo : '-'}}</p>
                                        </li>
                                    </ul>
                                    <ul class="grid__group analysis-detail__grid--group head">
                                        <li class="grid__cell">
                                            <p>비고</p>
                                        </li>
                                        <li class="grid__cell">
                                            <p>{{ notiBddprPrtcCmp && notiBddprPrtcCmp.lastSbidYn === 'Y' ? '최종낙찰' : (notiBddprPrtcCmp && notiBddprPrtcCmp.rmark ? notiBddprPrtcCmp.rmark : '-') }}</p>
                                        </li>
                                    </ul>

                                </div>
                            </div>
                            <!-- /mobile -->
                        </div>
                        <div>

                        </div>
                        <!-- /내투찰순위 -->

                        <!-- 복수예비가 -->
                        <div class="analysis-detail__unit random-num">
                            <p class="txt--title">복수예비가</p>
                            <!-- 기초금액, 발주처 표 (수평으로 나열된 표) pc -->
                            <div class="analysis-detail__unit--group random-num__group pc">
                                <div class="grid__wrap analysis-detail__grid random-num__grid base">
                                    <div class="grid__container">
                                        <ul class="grid__group analysis-detail__grid--group head">
                                            <li class="grid__cell analysis-detail__grid--cell random-num__grid--cell">
                                                <p>기초금액</p>
                                            </li>
                                            <li class="grid__cell analysis-detail__grid--cell random-num__grid--cell">
                                                <p>예정가격</p>
                                            </li>
                                            <li class="grid__cell analysis-detail__grid--cell random-num__grid--cell">
                                                <p>순공사원가</p>
                                            </li>
                                            <li class="grid__cell analysis-detail__grid--cell random-num__grid--cell">
                                                <p>법정금액</p>
                                            </li>
                                        </ul>
<!--                                        <p>{{notiDetail.notiStatus == '개찰완료' ? notiDetail.baseAmt  : '-' | currency}}원</p>-->

                                        <ul class="grid__group analysis-detail__grid--group body">
                                            <li class="grid__cell analysis-detail__grid--cell random-num__grid--cell">
                                                <p v-if="notiDetail.notiStatus == '개찰완료'">{{ notiDetail.baseAmt | currency }}원</p>
                                                <p v-else>-</p>
                                            </li>
                                            <li class="grid__cell analysis-detail__grid--cell random-num__grid--cell">
                                                <p v-if="notiDetail.notiStatus == '개찰완료'">{{ notiDetail.opbdPrargPrice | currency }}원</p>
                                                <p v-else>-</p>
                                            </li>
                                            <li class="grid__cell analysis-detail__grid--cell random-num__grid--cell">
                                                <p v-if="notiDetail.notiStatus == '개찰완료'">{{ notiDetail.purityCntrwkPrmpc | currency }}원</p>
                                                <p v-else>-</p>
                                            </li>
                                            <li class="grid__cell analysis-detail__grid--cell random-num__grid--cell">
                                                <p v-if="notiDetail.notiStatus == '개찰완료'">{{ notiDetail.stttAmt | currency }}원</p>
                                                <p v-else>-</p>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div class="grid__wrap analysis-detail__grid random-num__grid order">
                                    <div class="grid__container">
                                        <ul class="grid__group analysis-detail__grid--group head">
                                            <li class="grid__cell analysis-detail__grid--cell">
                                                <p>발주처</p>
                                            </li>
                                            <li class="grid__cell analysis-detail__grid--cell">
                                                <p>낙찰하한가</p>
                                            </li>
                                            <li class="grid__cell analysis-detail__grid--cell">
                                                <p>예가 대비 투찰율(%)</p>
                                            </li>
                                            <li class="grid__cell analysis-detail__grid--cell">
                                                <p>기초 대비 투찰율(%)</p>
                                            </li>
                                            <li class="grid__cell analysis-detail__grid--cell">
                                                <p>추첨 번호</p>
                                            </li>
                                        </ul>
                                        <ul class="grid__group analysis-detail__grid--group body">
                                            <li class="grid__cell analysis-detail__grid--cell">
                                                <p>{{notiDetail.notiStatus == '개찰완료' ? notiDetail.orderOffiNm : '-'}}</p>
                                            </li>
                                            <li class="grid__cell analysis-detail__grid--cell">
                                                <p>{{notiDetail.notiStatus == '개찰완료' ? notiDetail.opbdPrargAmt : '-'  | currency }}</p>
                                            </li>
                                            <li class="grid__cell analysis-detail__grid--cell">
                                                <p>{{notiDetail.notiStatus == '개찰완료' ? notiDetail.dwnlmtRt : '-'}}</p>
                                            </li>
                                            <li class="grid__cell analysis-detail__grid--cell">
                                                <p>{{notiDetail.notiStatus == '개찰완료' ? notiDetail.opbdPrargRt : '-'}}</p>
                                            </li>
                                            <li class="grid__cell analysis-detail__grid--cell">
                                                <p>{{notiDetail.selectedYega ? notiDetail.selectedYega : '-'}}</p>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <!-- 기초금액, 발주처 표(수평으로 나열된 표) pc -->

                            <!-- 기초금액, 발주처 표 (수평으로 나열된 표) mobile -->
                            <div class="analysis-detail__unit--group random-num__group mobile">
                                <div class="grid__wrap analysis-detail__grid random-num__grid base">
                                    <div class="grid__container">
                                        <ul class="grid__group analysis-detail__grid--group head">
                                            <li class="grid__cell analysis-detail__grid--cell random-num__grid--cell">
                                                <p>기초금액</p>
                                            </li>
                                            <li class="grid__cell analysis-detail__grid--cell random-num__grid--cell">
                                                <p v-if="notiDetail.notiStatus == '개찰완료'">{{ notiDetail.baseAmt | currency }}원</p>
                                                <p v-else>-</p>
                                            </li>
                                        </ul>
                                        <ul class="grid__group analysis-detail__grid--group head">
                                            <li class="grid__cell analysis-detail__grid--cell random-num__grid--cell">
                                                <p>예정가격</p>
                                            </li>
                                            <li class="grid__cell analysis-detail__grid--cell random-num__grid--cell">
                                                <p v-if="notiDetail.notiStatus == '개찰완료'">{{ notiDetail.opbdPrargPrice | currency }}원</p>
                                                <p v-else>-</p>
                                            </li>
                                        </ul>
                                        <ul class="grid__group analysis-detail__grid--group head">
                                            <li class="grid__cell analysis-detail__grid--cell random-num__grid--cell">
                                                <p>순공사원가</p>
                                            </li>
                                            <li class="grid__cell analysis-detail__grid--cell random-num__grid--cell">
                                                <p v-if="notiDetail.notiStatus == '개찰완료'">{{ notiDetail.purityCntrwkPrmpc | currency }}원</p>
                                                <p v-else>-</p>
                                            </li>
                                        </ul>
                                        <ul class="grid__group analysis-detail__grid--group head">
                                            <li class="grid__cell analysis-detail__grid--cell random-num__grid--cell">
                                                <p>법정금액</p>
                                            </li>
                                            <li class="grid__cell analysis-detail__grid--cell random-num__grid--cell">
                                                <p v-if="notiDetail.notiStatus == '개찰완료'">{{ notiDetail.stttAmt | currency }}원</p>
                                                <p v-else>-</p>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div class="grid__wrap analysis-detail__grid random-num__grid order">
                                    <div class="grid__container">
                                        <ul class="grid__group analysis-detail__grid--group head">
                                            <li class="grid__cell analysis-detail__grid--cell random-num__grid--cell">
                                                <p>발주처</p>
                                            </li>
                                            <li class="grid__cell analysis-detail__grid--cell random-num__grid--cell">
                                                <p>{{notiDetail.notiStatus == '개찰완료' ? notiDetail.orderOffiNm : '-'}}</p>
                                            </li>
                                        </ul>
                                        <ul class="grid__group analysis-detail__grid--group head">
                                            <li class="grid__cell analysis-detail__grid--cell random-num__grid--cell">
                                                <p>낙찰하한가</p>
                                            </li>
                                            <li class="grid__cell analysis-detail__grid--cell random-num__grid--cell">
                                                <p>{{notiDetail.notiStatus == '개찰완료' ? notiDetail.opbdPrargAmt : '-'  | currency }}</p>
                                            </li>
                                        </ul>
                                        <ul class="grid__group analysis-detail__grid--group head">
                                            <li class="grid__cell analysis-detail__grid--cell random-num__grid--cell analysis-detail__grid--two-line">
                                                <p>예가대비 <span>투찰율(%)</span></p>
                                            </li>
                                            <li class="grid__cell analysis-detail__grid--cell random-num__grid--cell analysis-detail__grid--two-line">
                                                <p>{{notiDetail.notiStatus == '개찰완료' ? notiDetail.dwnlmtRt : '-'}}</p>
                                            </li>
                                        </ul>
                                        <ul class="grid__group analysis-detail__grid--group head">
                                            <li class="grid__cell analysis-detail__grid--cell random-num__grid--cell analysis-detail__grid--two-line">
                                                <p>기초대비 <span>투찰율(%)</span></p>
                                            </li>
                                            <li class="grid__cell analysis-detail__grid--cell random-num__grid--cell analysis-detail__grid--two-line">
                                                <p>{{notiDetail.notiStatus == '개찰완료' ? notiDetail.opbdPrargRt : '-'}}</p>
                                            </li>
                                        </ul>
                                        <ul class="grid__group analysis-detail__grid--group head">
                                            <li class="grid__cell analysis-detail__grid--cell random-num__grid--cell">
                                                <p>추첨 번호</p>
                                            </li>
                                            <li class="grid__cell analysis-detail__grid--cell random-num__grid--cell">
                                                <p>{{notiDetail.selectedYega ? notiDetail.selectedYega : '-'}}</p>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <!-- /기초금액, 발주처 표 (수평으로 나열된 표) mobile -->


                            <!-- 수직으로 나열된 표  pc,mobile -->
                            <div class="analysis-detail__unit--group random-num__group">
                                <div class="grid__wrap analysis-detail__grid random-num__grid si-by-si analysis-detail__grid--vertical">
                                    <div class="grid__container" v-show="notiCompnoList.length != 0">
                                        <ul class="grid__group analysis-detail__grid--group head">
                                            <li class="grid__cell analysis-detail__grid--cell">번호</li>
                                            <li class="grid__cell analysis-detail__grid--cell">복수예가</li>
                                            <li class="grid__cell analysis-detail__grid--cell">사정률</li>
                                            <li class="grid__cell analysis-detail__grid--cell">추첨</li>
                                        </ul>
                                        <ul class="grid__group analysis-detail__grid--group body" v-for="item in notiCompnoListA" :key="item.sortOrdr">
                                            <li class="grid__cell analysis-detail__grid--cell">{{item.sortOrdr}}</li>
                                            <li class="grid__cell analysis-detail__grid--cell">{{item.compnoPrdprc | currency}}</li>
                                            <li class="grid__cell analysis-detail__grid--cell">{{item.value}}</li>
                                            <li class="grid__cell analysis-detail__grid--cell">{{item.opbdChoiceTmcnt}}</li>
                                        </ul>
                                    </div>
                                    <div class="grid__container" v-show="notiCompnoList.length == 0">
                                        <ul class="grid__group analysis-detail__grid--group head">
                                            <li class="grid__cell analysis-detail__grid--cell">번호</li>
                                            <li class="grid__cell analysis-detail__grid--cell">복수예가</li>
                                            <li class="grid__cell analysis-detail__grid--cell">사정률</li>
                                            <li class="grid__cell analysis-detail__grid--cell">추첨</li>
                                        </ul>
                                        <ul class="grid__group analysis-detail__grid--group body">
                                            <li class="grid__cell analysis-detail__grid--cell">1</li>
                                            <li class="grid__cell analysis-detail__grid--cell">-</li>
                                            <li class="grid__cell analysis-detail__grid--cell">-</li>
                                            <li class="grid__cell analysis-detail__grid--cell">-</li>
                                        </ul>
                                        <ul class="grid__group analysis-detail__grid--group body">
                                            <li class="grid__cell analysis-detail__grid--cell">2</li>
                                            <li class="grid__cell analysis-detail__grid--cell">-</li>
                                            <li class="grid__cell analysis-detail__grid--cell">-</li>
                                            <li class="grid__cell analysis-detail__grid--cell">-</li>
                                        </ul>
                                        <ul class="grid__group analysis-detail__grid--group body">
                                            <li class="grid__cell analysis-detail__grid--cell">3</li>
                                            <li class="grid__cell analysis-detail__grid--cell">-</li>
                                            <li class="grid__cell analysis-detail__grid--cell">-</li>
                                            <li class="grid__cell analysis-detail__grid--cell">-</li>
                                        </ul>
                                        <ul class="grid__group analysis-detail__grid--group body">
                                            <li class="grid__cell analysis-detail__grid--cell">4</li>
                                            <li class="grid__cell analysis-detail__grid--cell">-</li>
                                            <li class="grid__cell analysis-detail__grid--cell">-</li>
                                            <li class="grid__cell analysis-detail__grid--cell">-</li>
                                        </ul>
                                        <ul class="grid__group analysis-detail__grid--group body">
                                            <li class="grid__cell analysis-detail__grid--cell">5</li>
                                            <li class="grid__cell analysis-detail__grid--cell">-</li>
                                            <li class="grid__cell analysis-detail__grid--cell">-</li>
                                            <li class="grid__cell analysis-detail__grid--cell">-</li>
                                        </ul>
                                        <ul class="grid__group analysis-detail__grid--group body">
                                            <li class="grid__cell analysis-detail__grid--cell">6</li>
                                            <li class="grid__cell analysis-detail__grid--cell">-</li>
                                            <li class="grid__cell analysis-detail__grid--cell">-</li>
                                            <li class="grid__cell analysis-detail__grid--cell">-</li>
                                        </ul>
                                        <ul class="grid__group analysis-detail__grid--group body">
                                            <li class="grid__cell analysis-detail__grid--cell">7</li>
                                            <li class="grid__cell analysis-detail__grid--cell">-</li>
                                            <li class="grid__cell analysis-detail__grid--cell">-</li>
                                            <li class="grid__cell analysis-detail__grid--cell">-</li>
                                        </ul>
                                        <ul class="grid__group analysis-detail__grid--group body">
                                            <li class="grid__cell analysis-detail__grid--cell">8</li>
                                            <li class="grid__cell analysis-detail__grid--cell">-</li>
                                            <li class="grid__cell analysis-detail__grid--cell">-</li>
                                            <li class="grid__cell analysis-detail__grid--cell">-</li>
                                        </ul>
                                    </div>
                                </div>
                                <div
                                        class="grid__wrap analysis-detail__grid random-num__grid si-by-si analysis-detail__grid--vertical">
                                    <div class="grid__container" v-show="notiCompnoList.length != 0">
                                        <ul class="grid__group analysis-detail__grid--group head">
                                            <li class="grid__cell analysis-detail__grid--cell">번호</li>
                                            <li class="grid__cell analysis-detail__grid--cell">복수예가</li>
                                            <li class="grid__cell analysis-detail__grid--cell">사정률</li>
                                            <li class="grid__cell analysis-detail__grid--cell">추첨</li>
                                        </ul>
                                        <ul class="grid__group analysis-detail__grid--group body" v-for="item in notiCompnoListB" :key="item.sortOrdr">
                                            <li class="grid__cell analysis-detail__grid--cell">{{item.sortOrdr}}</li>
                                            <li class="grid__cell analysis-detail__grid--cell">{{item.compnoPrdprc | currency}}</li>
                                            <li class="grid__cell analysis-detail__grid--cell">{{item.value}}</li>
                                            <li class="grid__cell analysis-detail__grid--cell">{{item.opbdChoiceTmcnt}}</li>
                                        </ul>
                                        <ul class="grid__group analysis-detail__grid--group body replenish" v-for="item in notiCompnoListC" :key="item.id">
                                            <li class="grid__cell analysis-detail__grid--cell">ㅤ</li>
                                            <li class="grid__cell analysis-detail__grid--cell"></li>
                                            <li class="grid__cell analysis-detail__grid--cell"></li>
                                            <li class="grid__cell analysis-detail__grid--cell"></li>
                                        </ul>
                                    </div>
                                    <div class="grid__container" v-show="notiCompnoList.length == 0">
                                        <ul class="grid__group analysis-detail__grid--group head">
                                            <li class="grid__cell analysis-detail__grid--cell">번호</li>
                                            <li class="grid__cell analysis-detail__grid--cell">복수예가</li>
                                            <li class="grid__cell analysis-detail__grid--cell">사정률</li>
                                            <li class="grid__cell analysis-detail__grid--cell">추첨</li>
                                        </ul>
                                        <ul class="grid__group analysis-detail__grid--group body">
                                            <li class="grid__cell analysis-detail__grid--cell">9</li>
                                            <li class="grid__cell analysis-detail__grid--cell">-</li>
                                            <li class="grid__cell analysis-detail__grid--cell">-</li>
                                            <li class="grid__cell analysis-detail__grid--cell">-</li>
                                        </ul>
                                        <ul class="grid__group analysis-detail__grid--group body">
                                            <li class="grid__cell analysis-detail__grid--cell">10</li>
                                            <li class="grid__cell analysis-detail__grid--cell">-</li>
                                            <li class="grid__cell analysis-detail__grid--cell">-</li>
                                            <li class="grid__cell analysis-detail__grid--cell">-</li>
                                        </ul>
                                        <ul class="grid__group analysis-detail__grid--group body">
                                            <li class="grid__cell analysis-detail__grid--cell">11</li>
                                            <li class="grid__cell analysis-detail__grid--cell">-</li>
                                            <li class="grid__cell analysis-detail__grid--cell">-</li>
                                            <li class="grid__cell analysis-detail__grid--cell">-</li>
                                        </ul>
                                        <ul class="grid__group analysis-detail__grid--group body">
                                            <li class="grid__cell analysis-detail__grid--cell">12</li>
                                            <li class="grid__cell analysis-detail__grid--cell">-</li>
                                            <li class="grid__cell analysis-detail__grid--cell">-</li>
                                            <li class="grid__cell analysis-detail__grid--cell">-</li>
                                        </ul>
                                        <ul class="grid__group analysis-detail__grid--group body">
                                            <li class="grid__cell analysis-detail__grid--cell">13</li>
                                            <li class="grid__cell analysis-detail__grid--cell">-</li>
                                            <li class="grid__cell analysis-detail__grid--cell">-</li>
                                            <li class="grid__cell analysis-detail__grid--cell">-</li>
                                        </ul>
                                        <ul class="grid__group analysis-detail__grid--group body">
                                            <li class="grid__cell analysis-detail__grid--cell">14</li>
                                            <li class="grid__cell analysis-detail__grid--cell">-</li>
                                            <li class="grid__cell analysis-detail__grid--cell">-</li>
                                            <li class="grid__cell analysis-detail__grid--cell">-</li>
                                        </ul>
                                        <ul class="grid__group analysis-detail__grid--group body">
                                            <li class="grid__cell analysis-detail__grid--cell">15</li>
                                            <li class="grid__cell analysis-detail__grid--cell">-</li>
                                            <li class="grid__cell analysis-detail__grid--cell">-</li>
                                            <li class="grid__cell analysis-detail__grid--cell">-</li>
                                        </ul>
                                        <ul class="grid__group analysis-detail__grid--group body replenish">
                                            <li class="grid__cell analysis-detail__grid--cell">replenish</li>
                                            <li class="grid__cell analysis-detail__grid--cell"></li>
                                            <li class="grid__cell analysis-detail__grid--cell"></li>
                                            <li class="grid__cell analysis-detail__grid--cell"></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <!-- /수직으로 나열된 표  pc,mobile -->

                        </div>
                        <!-- /복수예비가 -->

                        <!-- 참여업체순위 -->
                        <div class="analysis-detail__unit cpny-rank">
                            <p class="txt--title">참여업체 순위</p>
                            <div class="analysis-detail__group cpny-rank__top">
                                <div class="analysis-detail__item rank">
                                    <span class="rank--txt--total">총 {{bddprPrtcCnt != 0 ? bddprPrtcCnt : '-'}}업체</span>
                                    <span class="rank--txt--my">내 투찰 순위</span>
                                    <span class="rank--txt--rank">{{notiBddprPrtcCmp ? notiBddprPrtcCmp.cmpRank : '-'}}위</span>
                                    <div class="cpny-rank__btn mobile">
                                        <a class="btn-basic btn-line-cw btn__non-active" v-show="bddprPrtcCnt == 0">참여업체 모두 보기</a>
                                        <a href="#" onclick="return false" class="btn-basic btn-cta" v-show="bddprPrtcCnt != 0"  @click="popBddprPrtcCmpList('')">참여업체 모두 보기</a>
                                    </div>
                                </div>
                                <div class="analysis-detail__item search-bar">
                                    <div class="select-box">
                                        <button class="select-box__sel">업체명</button>
                                        <ul class="select-box__list">
                                            <li><button type="button" value="CMP_NM">업체명</button></li>
                                            <li><button type="button" value="RPSNTV_NM">대표자명</button></li>
                                            <li><button type="button" value="BIZ_NO">사업자번호</button></li>
                                        </ul>
                                    </div>
                                    <div class="search">
                                        <input type="text" placeholder="참여업체를 검색해 보세요." v-model="searchString" @keyup.enter="popBddprPrtcCmpList(searchString)">
                                        <button type="reset" class="btn__cancel">취소</button>
                                    </div>
                                    <button type="button" class="btn-basic btn-line-cw cpny-rank--btn btn__non-active" v-show="bddprPrtcCnt == 0">검색</button>
                                    <button type="button" class="btn-basic btn-line-cw cpny-rank--btn" v-show="bddprPrtcCnt != 0" @click="popBddprPrtcCmpList(searchString)">검색</button>
                                </div>
                            </div>
                            <div class="analysis-detail__group cpny-rank__bottom">
                                <div class="grid__wrap analysis-detail__grid analysis-detail__grid--vertical">
                                    <div class="pc grid__container" v-show="bddprPrtcCnt != 0">
                                        <ul class="grid__group analysis-detail__grid--group head">
                                            <li class="grid__cell analysis-detail__grid--cell my-rank__cell">순위</li>
                                            <li class="grid__cell analysis-detail__grid--cell my-rank__cell">사업자번호</li>
                                            <li class="grid__cell analysis-detail__grid--cell my-rank__cell">업체명</li>
                                            <li class="grid__cell analysis-detail__grid--cell my-rank__cell">대표자명</li>
                                            <li class="grid__cell analysis-detail__grid--cell my-rank__cell">투찰금액</li>
                                            <li class="grid__cell analysis-detail__grid--cell my-rank__cell">편차</li>
                                            <li class="grid__cell analysis-detail__grid--cell my-rank__cell">예가대비<br>투찰율(%)</li>
                                            <li class="grid__cell analysis-detail__grid--cell my-rank__cell">기초대비<br>투찰율(%)</li>
                                            <li class="grid__cell analysis-detail__grid--cell my-rank__cell">가격<br>점수</li>
                                            <li class="grid__cell analysis-detail__grid--cell my-rank__cell">추첨 번호</li>
                                            <li class="grid__cell analysis-detail__grid--cell my-rank__cell">비고</li>
                                        </ul>
                                        <ul class="grid__group analysis-detail__grid--group body" v-for="items in bddprPrtcCmp10" :key="items.id" :class="{ 'analysis-detail__grid--my-rank': notiBddprPrtcCmp && notiBddprPrtcCmp.cmpRank == items.cmpRank }">
                                            <li class="grid__cell analysis-detail__grid--cell my-rank__cell">{{items.cmpRank}}</li>
                                            <li class="grid__cell analysis-detail__grid--cell my-rank__cell">{{items.bizNo | formatbizno}}</li>
                                            <li class="grid__cell analysis-detail__grid--cell my-rank__cell">{{items.cmpNm}}</li>
                                            <li class="grid__cell analysis-detail__grid--cell my-rank__cell">{{items.rpsntvNm}}</li>
                                            <li class="grid__cell analysis-detail__grid--cell my-rank__cell">{{items.bddprAmt | currency}}</li>
                                            <li class="grid__cell analysis-detail__grid--cell my-rank__cell">{{items.bddprAmt - notiDetail.opbdPrargAmt | currency}}</li>
                                            <li class="grid__cell analysis-detail__grid--cell my-rank__cell">{{items.opbdPrargPricePercent}}</li>
                                            <li class="grid__cell analysis-detail__grid--cell my-rank__cell">{{items.calBddprRt}}</li>
                                            <li class="grid__cell analysis-detail__grid--cell my-rank__cell">{{items.priceScore}}</li>
                                            <li class="grid__cell analysis-detail__grid--cell my-rank__cell">{{items.choicePrdprcNo}}</li>
                                            <li class="grid__cell analysis-detail__grid--cell my-rank__cell">{{items.lastSbidYn == 'Y' ? '최종낙찰' : items.rmark}}</li>
                                        </ul>
                                    </div>
                                    <div class="pc grid__container" v-show="bddprPrtcCnt == 0">
                                        <ul class="grid__group analysis-detail__grid--group head">
                                            <li class="grid__cell analysis-detail__grid--cell my-rank__cell">순위</li>
                                            <li class="grid__cell analysis-detail__grid--cell my-rank__cell">사업자번호</li>
                                            <li class="grid__cell analysis-detail__grid--cell my-rank__cell">업체명</li>
                                            <li class="grid__cell analysis-detail__grid--cell my-rank__cell">대표자명</li>
                                            <li class="grid__cell analysis-detail__grid--cell my-rank__cell">투찰금액</li>
                                            <li class="grid__cell analysis-detail__grid--cell my-rank__cell">편차</li>
                                            <li class="grid__cell analysis-detail__grid--cell my-rank__cell">예가대비<br>투찰율(%)</li>
                                            <li class="grid__cell analysis-detail__grid--cell my-rank__cell">기초대비<br>투찰율(%)</li>
                                            <li class="grid__cell analysis-detail__grid--cell my-rank__cell">가격<br>점수</li>
                                            <li class="grid__cell analysis-detail__grid--cell my-rank__cell">추첨 번호</li>
                                            <li class="grid__cell analysis-detail__grid--cell my-rank__cell">비고</li>
                                        </ul>
                                        <ul class="grid__group analysis-detail__grid--group body">
                                            <li class="grid__cell analysis-detail__grid--cell my-rank__cell">-</li>
                                            <li class="grid__cell analysis-detail__grid--cell my-rank__cell">-</li>
                                            <li class="grid__cell analysis-detail__grid--cell my-rank__cell">-</li>
                                            <li class="grid__cell analysis-detail__grid--cell my-rank__cell">-</li>
                                            <li class="grid__cell analysis-detail__grid--cell my-rank__cell">-</li>
                                            <li class="grid__cell analysis-detail__grid--cell my-rank__cell">-</li>
                                            <li class="grid__cell analysis-detail__grid--cell my-rank__cell">-</li>
                                            <li class="grid__cell analysis-detail__grid--cell my-rank__cell">-</li>
                                            <li class="grid__cell analysis-detail__grid--cell my-rank__cell">-</li>
                                            <li class="grid__cell analysis-detail__grid--cell my-rank__cell">-</li>
                                            <li class="grid__cell analysis-detail__grid--cell my-rank__cell">-</li>
                                        </ul>
                                        <ul class="grid__group analysis-detail__grid--group body">
                                            <li class="grid__cell analysis-detail__grid--cell my-rank__cell">-</li>
                                            <li class="grid__cell analysis-detail__grid--cell my-rank__cell">-</li>
                                            <li class="grid__cell analysis-detail__grid--cell my-rank__cell">-</li>
                                            <li class="grid__cell analysis-detail__grid--cell my-rank__cell">-</li>
                                            <li class="grid__cell analysis-detail__grid--cell my-rank__cell">-</li>
                                            <li class="grid__cell analysis-detail__grid--cell my-rank__cell">-</li>
                                            <li class="grid__cell analysis-detail__grid--cell my-rank__cell">-</li>
                                            <li class="grid__cell analysis-detail__grid--cell my-rank__cell">-</li>
                                            <li class="grid__cell analysis-detail__grid--cell my-rank__cell">-</li>
                                            <li class="grid__cell analysis-detail__grid--cell my-rank__cell">-</li>
                                            <li class="grid__cell analysis-detail__grid--cell my-rank__cell">-</li>
                                        </ul>
                                        <ul class="grid__group analysis-detail__grid--group body">
                                            <li class="grid__cell analysis-detail__grid--cell my-rank__cell">-</li>
                                            <li class="grid__cell analysis-detail__grid--cell my-rank__cell">-</li>
                                            <li class="grid__cell analysis-detail__grid--cell my-rank__cell">-</li>
                                            <li class="grid__cell analysis-detail__grid--cell my-rank__cell">-</li>
                                            <li class="grid__cell analysis-detail__grid--cell my-rank__cell">-</li>
                                            <li class="grid__cell analysis-detail__grid--cell my-rank__cell">-</li>
                                            <li class="grid__cell analysis-detail__grid--cell my-rank__cell">-</li>
                                            <li class="grid__cell analysis-detail__grid--cell my-rank__cell">-</li>
                                            <li class="grid__cell analysis-detail__grid--cell my-rank__cell">-</li>
                                            <li class="grid__cell analysis-detail__grid--cell my-rank__cell">-</li>
                                            <li class="grid__cell analysis-detail__grid--cell my-rank__cell">-</li>
                                        </ul>
                                        <ul class="grid__group analysis-detail__grid--group body">
                                            <li class="grid__cell analysis-detail__grid--cell my-rank__cell">-</li>
                                            <li class="grid__cell analysis-detail__grid--cell my-rank__cell">-</li>
                                            <li class="grid__cell analysis-detail__grid--cell my-rank__cell">-</li>
                                            <li class="grid__cell analysis-detail__grid--cell my-rank__cell">-</li>
                                            <li class="grid__cell analysis-detail__grid--cell my-rank__cell">-</li>
                                            <li class="grid__cell analysis-detail__grid--cell my-rank__cell">-</li>
                                            <li class="grid__cell analysis-detail__grid--cell my-rank__cell">-</li>
                                            <li class="grid__cell analysis-detail__grid--cell my-rank__cell">-</li>
                                            <li class="grid__cell analysis-detail__grid--cell my-rank__cell">-</li>
                                            <li class="grid__cell analysis-detail__grid--cell my-rank__cell">-</li>
                                            <li class="grid__cell analysis-detail__grid--cell my-rank__cell">-</li>
                                        </ul>
                                        <ul class="grid__group analysis-detail__grid--group body">
                                            <li class="grid__cell analysis-detail__grid--cell my-rank__cell">-</li>
                                            <li class="grid__cell analysis-detail__grid--cell my-rank__cell">-</li>
                                            <li class="grid__cell analysis-detail__grid--cell my-rank__cell">-</li>
                                            <li class="grid__cell analysis-detail__grid--cell my-rank__cell">-</li>
                                            <li class="grid__cell analysis-detail__grid--cell my-rank__cell">-</li>
                                            <li class="grid__cell analysis-detail__grid--cell my-rank__cell">-</li>
                                            <li class="grid__cell analysis-detail__grid--cell my-rank__cell">-</li>
                                            <li class="grid__cell analysis-detail__grid--cell my-rank__cell">-</li>
                                            <li class="grid__cell analysis-detail__grid--cell my-rank__cell">-</li>
                                            <li class="grid__cell analysis-detail__grid--cell my-rank__cell">-</li>
                                            <li class="grid__cell analysis-detail__grid--cell my-rank__cell">-</li>
                                        </ul>
                                        <ul class="grid__group analysis-detail__grid--group body">
                                            <li class="grid__cell analysis-detail__grid--cell my-rank__cell">-</li>
                                            <li class="grid__cell analysis-detail__grid--cell my-rank__cell">-</li>
                                            <li class="grid__cell analysis-detail__grid--cell my-rank__cell">-</li>
                                            <li class="grid__cell analysis-detail__grid--cell my-rank__cell">-</li>
                                            <li class="grid__cell analysis-detail__grid--cell my-rank__cell">-</li>
                                            <li class="grid__cell analysis-detail__grid--cell my-rank__cell">-</li>
                                            <li class="grid__cell analysis-detail__grid--cell my-rank__cell">-</li>
                                            <li class="grid__cell analysis-detail__grid--cell my-rank__cell">-</li>
                                            <li class="grid__cell analysis-detail__grid--cell my-rank__cell">-</li>
                                            <li class="grid__cell analysis-detail__grid--cell my-rank__cell">-</li>
                                            <li class="grid__cell analysis-detail__grid--cell my-rank__cell">-</li>
                                        </ul>
                                        <ul class="grid__group analysis-detail__grid--group body">
                                            <li class="grid__cell analysis-detail__grid--cell my-rank__cell">-</li>
                                            <li class="grid__cell analysis-detail__grid--cell my-rank__cell">-</li>
                                            <li class="grid__cell analysis-detail__grid--cell my-rank__cell">-</li>
                                            <li class="grid__cell analysis-detail__grid--cell my-rank__cell">-</li>
                                            <li class="grid__cell analysis-detail__grid--cell my-rank__cell">-</li>
                                            <li class="grid__cell analysis-detail__grid--cell my-rank__cell">-</li>
                                            <li class="grid__cell analysis-detail__grid--cell my-rank__cell">-</li>
                                            <li class="grid__cell analysis-detail__grid--cell my-rank__cell">-</li>
                                            <li class="grid__cell analysis-detail__grid--cell my-rank__cell">-</li>
                                            <li class="grid__cell analysis-detail__grid--cell my-rank__cell">-</li>
                                            <li class="grid__cell analysis-detail__grid--cell my-rank__cell">-</li>
                                        </ul>
                                        <ul class="grid__group analysis-detail__grid--group body">
                                            <li class="grid__cell analysis-detail__grid--cell my-rank__cell">-</li>
                                            <li class="grid__cell analysis-detail__grid--cell my-rank__cell">-</li>
                                            <li class="grid__cell analysis-detail__grid--cell my-rank__cell">-</li>
                                            <li class="grid__cell analysis-detail__grid--cell my-rank__cell">-</li>
                                            <li class="grid__cell analysis-detail__grid--cell my-rank__cell">-</li>
                                            <li class="grid__cell analysis-detail__grid--cell my-rank__cell">-</li>
                                            <li class="grid__cell analysis-detail__grid--cell my-rank__cell">-</li>
                                            <li class="grid__cell analysis-detail__grid--cell my-rank__cell">-</li>
                                            <li class="grid__cell analysis-detail__grid--cell my-rank__cell">-</li>
                                            <li class="grid__cell analysis-detail__grid--cell my-rank__cell">-</li>
                                            <li class="grid__cell analysis-detail__grid--cell my-rank__cell">-</li>
                                        </ul>
                                        <ul class="grid__group analysis-detail__grid--group body">
                                            <li class="grid__cell analysis-detail__grid--cell my-rank__cell">-</li>
                                            <li class="grid__cell analysis-detail__grid--cell my-rank__cell">-</li>
                                            <li class="grid__cell analysis-detail__grid--cell my-rank__cell">-</li>
                                            <li class="grid__cell analysis-detail__grid--cell my-rank__cell">-</li>
                                            <li class="grid__cell analysis-detail__grid--cell my-rank__cell">-</li>
                                            <li class="grid__cell analysis-detail__grid--cell my-rank__cell">-</li>
                                            <li class="grid__cell analysis-detail__grid--cell my-rank__cell">-</li>
                                            <li class="grid__cell analysis-detail__grid--cell my-rank__cell">-</li>
                                            <li class="grid__cell analysis-detail__grid--cell my-rank__cell">-</li>
                                            <li class="grid__cell analysis-detail__grid--cell my-rank__cell">-</li>
                                            <li class="grid__cell analysis-detail__grid--cell my-rank__cell">-</li>
                                        </ul>
                                        <ul class="grid__group analysis-detail__grid--group body">
                                            <li class="grid__cell analysis-detail__grid--cell my-rank__cell">-</li>
                                            <li class="grid__cell analysis-detail__grid--cell my-rank__cell">-</li>
                                            <li class="grid__cell analysis-detail__grid--cell my-rank__cell">-</li>
                                            <li class="grid__cell analysis-detail__grid--cell my-rank__cell">-</li>
                                            <li class="grid__cell analysis-detail__grid--cell my-rank__cell">-</li>
                                            <li class="grid__cell analysis-detail__grid--cell my-rank__cell">-</li>
                                            <li class="grid__cell analysis-detail__grid--cell my-rank__cell">-</li>
                                            <li class="grid__cell analysis-detail__grid--cell my-rank__cell">-</li>
                                            <li class="grid__cell analysis-detail__grid--cell my-rank__cell">-</li>
                                            <li class="grid__cell analysis-detail__grid--cell my-rank__cell">-</li>
                                            <li class="grid__cell analysis-detail__grid--cell my-rank__cell">-</li>
                                        </ul>
                                    </div>

                                    <div class="mobile cpny-rank__slide--unit">
                                        <div class="cpny-rank__slide--menu grid__group head analysis-detail__grid--group">
                                            <p class="grid__cell analysis-detail__grid--cell cpny-rank__grid--cell">순위</p>
                                            <p class="grid__cell analysis-detail__grid--cell cpny-rank__grid--cell">사업자번호</p>
                                            <p class="grid__cell analysis-detail__grid--cell cpny-rank__grid--cell">업체명</p>
                                            <p class="grid__cell analysis-detail__grid--cell cpny-rank__grid--cell">대표자명</p>
                                            <p class="grid__cell analysis-detail__grid--cell cpny-rank__grid--cell">투찰금액</p>
                                            <p class="grid__cell analysis-detail__grid--cell cpny-rank__grid--cell">편차</p>
                                            <p class="grid__cell analysis-detail__grid--cell cpny-rank__grid--cell analysis-detail__grid--two-line">예가대비 <span>투찰율(%)</span></p>
                                            <p class="grid__cell analysis-detail__grid--cell cpny-rank__grid--cell analysis-detail__grid--two-line">기초대비 <span>투찰율(%)</span></p>
                                            <p class="grid__cell analysis-detail__grid--cell cpny-rank__grid--cell">가격 점수</p>
                                            <p class="grid__cell analysis-detail__grid--cell cpny-rank__grid--cell">추첨 번호</p>
                                            <p class="grid__cell analysis-detail__grid--cell cpny-rank__grid--cell">비고</p>
                                        </div>
                                        <div class="cpny-rank__slide--box"  v-show="bddprPrtcCnt != 0">
                                            <!-- 내순위 클래스 표시 mo) analysis-detail__grid--my-rank--mo -->
                                            <div class="cpny-rank__slide--group grid__group body analysis-detail__grid--group cpny-rank__grid--group" v-for="items in bddprPrtcCmp10" :key="items.id" :class="{ 'analysis-detail__grid--my-rank--mo': notiBddprPrtcCmp && notiBddprPrtcCmp.cmpRank == items.cmpRank }">
                                                <p class="cpny-rank__slide--item grid__cell analysis-detail__grid--cell cpny-rank__grid--cell">{{items.cmpRank}}</p>
                                                <p class="cpny-rank__slide--item grid__cell analysis-detail__grid--cell cpny-rank__grid--cell">{{items.bizNo | formatbizno}}</p>
                                                <p class="cpny-rank__slide--item grid__cell analysis-detail__grid--cell cpny-rank__grid--cell">{{items.cmpNm}}</p>
                                                <p class="cpny-rank__slide--item grid__cell analysis-detail__grid--cell cpny-rank__grid--cell">{{items.rpsntvNm}}</p>
                                                <p class="cpny-rank__slide--item grid__cell analysis-detail__grid--cell cpny-rank__grid--cell">{{items.bddprAmt | currency}}</p>
                                                <p class="cpny-rank__slide--item grid__cell analysis-detail__grid--cell cpny-rank__grid--cell">{{items.bddprAmt - notiDetail.opbdPrargAmt | currency}}</p>
                                                <p class="cpny-rank__slide--item grid__cell analysis-detail__grid--cell cpny-rank__grid--cell  analysis-detail__grid--two-line">{{items.opbdPrargPricePercent}}</p>
                                                <p class="cpny-rank__slide--item grid__cell analysis-detail__grid--cell cpny-rank__grid--cell analysis-detail__grid--two-line">{{items.calBddprRt}}</p>
                                                <p class="cpny-rank__slide--item grid__cell analysis-detail__grid--cell cpny-rank__grid--cell">{{items.priceScore}}</p>
                                                <p class="cpny-rank__slide--item grid__cell analysis-detail__grid--cell cpny-rank__grid--cell">{{items.choicePrdprcNo ? items.choicePrdprcNo : '-'}}</p>
                                                <p class="cpny-rank__slide--item grid__cell analysis-detail__grid--cell cpny-rank__grid--cell">{{items.lastSbidYn == 'Y' ? '최종낙찰' : (items.rmark ? items.rmark : '-')}}</p>
                                            </div>
                                        </div>
                                        <div class="cpny-rank__slide--box" v-show="bddprPrtcCnt == 0">
                                            <div class="cpny-rank__slide--group grid__group body analysis-detail__grid--group cpny-rank__grid--group">
                                                <p class="cpny-rank__slide--item grid__cell analysis-detail__grid--cell cpny-rank__grid--cell">-</p>
                                                <p class="cpny-rank__slide--item grid__cell analysis-detail__grid--cell cpny-rank__grid--cell">-</p>
                                                <p class="cpny-rank__slide--item grid__cell analysis-detail__grid--cell cpny-rank__grid--cell">-</p>
                                                <p class="cpny-rank__slide--item grid__cell analysis-detail__grid--cell cpny-rank__grid--cell">-</p>
                                                <p class="cpny-rank__slide--item grid__cell analysis-detail__grid--cell cpny-rank__grid--cell">-</p>
                                                <p class="cpny-rank__slide--item grid__cell analysis-detail__grid--cell cpny-rank__grid--cell">-</p>
                                                <p class="cpny-rank__slide--item grid__cell analysis-detail__grid--cell cpny-rank__grid--cell analysis-detail__grid--two-line">-</p>
                                                <p class="cpny-rank__slide--item grid__cell analysis-detail__grid--cell cpny-rank__grid--cell analysis-detail__grid--two-line">-</p>
                                                <p class="cpny-rank__slide--item grid__cell analysis-detail__grid--cell cpny-rank__grid--cell">-</p>
                                                <p class="cpny-rank__slide--item grid__cell analysis-detail__grid--cell cpny-rank__grid--cell">-</p>
                                                <p class="cpny-rank__slide--item grid__cell analysis-detail__grid--cell cpny-rank__grid--cell">-</p>
                                            </div>
                                            <div class="cpny-rank__slide--group grid__group body analysis-detail__grid--group cpny-rank__grid--group">
                                                <p class="cpny-rank__slide--item grid__cell analysis-detail__grid--cell cpny-rank__grid--cell">-</p>
                                                <p class="cpny-rank__slide--item grid__cell analysis-detail__grid--cell cpny-rank__grid--cell">-</p>
                                                <p class="cpny-rank__slide--item grid__cell analysis-detail__grid--cell cpny-rank__grid--cell">-</p>
                                                <p class="cpny-rank__slide--item grid__cell analysis-detail__grid--cell cpny-rank__grid--cell">-</p>
                                                <p class="cpny-rank__slide--item grid__cell analysis-detail__grid--cell cpny-rank__grid--cell">-</p>
                                                <p class="cpny-rank__slide--item grid__cell analysis-detail__grid--cell cpny-rank__grid--cell">-</p>
                                                <p class="cpny-rank__slide--item grid__cell analysis-detail__grid--cell cpny-rank__grid--cell analysis-detail__grid--two-line">-</p>
                                                <p class="cpny-rank__slide--item grid__cell analysis-detail__grid--cell cpny-rank__grid--cell analysis-detail__grid--two-line">-</p>
                                                <p class="cpny-rank__slide--item grid__cell analysis-detail__grid--cell cpny-rank__grid--cell">-</p>
                                                <p class="cpny-rank__slide--item grid__cell analysis-detail__grid--cell cpny-rank__grid--cell">-</p>
                                                <p class="cpny-rank__slide--item grid__cell analysis-detail__grid--cell cpny-rank__grid--cell">-</p>
                                            </div>
                                            <div class="cpny-rank__slide--group grid__group body analysis-detail__grid--group cpny-rank__grid--group">
                                                <p class="cpny-rank__slide--item grid__cell analysis-detail__grid--cell cpny-rank__grid--cell">-</p>
                                                <p class="cpny-rank__slide--item grid__cell analysis-detail__grid--cell cpny-rank__grid--cell">-</p>
                                                <p class="cpny-rank__slide--item grid__cell analysis-detail__grid--cell cpny-rank__grid--cell">-</p>
                                                <p class="cpny-rank__slide--item grid__cell analysis-detail__grid--cell cpny-rank__grid--cell">-</p>
                                                <p class="cpny-rank__slide--item grid__cell analysis-detail__grid--cell cpny-rank__grid--cell">-</p>
                                                <p class="cpny-rank__slide--item grid__cell analysis-detail__grid--cell cpny-rank__grid--cell">-</p>
                                                <p class="cpny-rank__slide--item grid__cell analysis-detail__grid--cell cpny-rank__grid--cell analysis-detail__grid--two-line">-</p>
                                                <p class="cpny-rank__slide--item grid__cell analysis-detail__grid--cell cpny-rank__grid--cell analysis-detail__grid--two-line">-</p>
                                                <p class="cpny-rank__slide--item grid__cell analysis-detail__grid--cell cpny-rank__grid--cell">-</p>
                                                <p class="cpny-rank__slide--item grid__cell analysis-detail__grid--cell cpny-rank__grid--cell">-</p>
                                                <p class="cpny-rank__slide--item grid__cell analysis-detail__grid--cell cpny-rank__grid--cell">-</p>
                                            </div>
                                            <div class="cpny-rank__slide--group grid__group body analysis-detail__grid--group cpny-rank__grid--group">
                                                <p class="cpny-rank__slide--item grid__cell analysis-detail__grid--cell cpny-rank__grid--cell">-</p>
                                                <p class="cpny-rank__slide--item grid__cell analysis-detail__grid--cell cpny-rank__grid--cell">-</p>
                                                <p class="cpny-rank__slide--item grid__cell analysis-detail__grid--cell cpny-rank__grid--cell">-</p>
                                                <p class="cpny-rank__slide--item grid__cell analysis-detail__grid--cell cpny-rank__grid--cell">-</p>
                                                <p class="cpny-rank__slide--item grid__cell analysis-detail__grid--cell cpny-rank__grid--cell">-</p>
                                                <p class="cpny-rank__slide--item grid__cell analysis-detail__grid--cell cpny-rank__grid--cell">-</p>
                                                <p class="cpny-rank__slide--item grid__cell analysis-detail__grid--cell cpny-rank__grid--cell analysis-detail__grid--two-line">-</p>
                                                <p class="cpny-rank__slide--item grid__cell analysis-detail__grid--cell cpny-rank__grid--cell analysis-detail__grid--two-line">-</p>
                                                <p class="cpny-rank__slide--item grid__cell analysis-detail__grid--cell cpny-rank__grid--cell">-</p>
                                                <p class="cpny-rank__slide--item grid__cell analysis-detail__grid--cell cpny-rank__grid--cell">-</p>
                                                <p class="cpny-rank__slide--item grid__cell analysis-detail__grid--cell cpny-rank__grid--cell">-</p>
                                            </div>
                                            <div class="cpny-rank__slide--group grid__group body analysis-detail__grid--group cpny-rank__grid--group">
                                                <p class="cpny-rank__slide--item grid__cell analysis-detail__grid--cell cpny-rank__grid--cell">-</p>
                                                <p class="cpny-rank__slide--item grid__cell analysis-detail__grid--cell cpny-rank__grid--cell">-</p>
                                                <p class="cpny-rank__slide--item grid__cell analysis-detail__grid--cell cpny-rank__grid--cell">-</p>
                                                <p class="cpny-rank__slide--item grid__cell analysis-detail__grid--cell cpny-rank__grid--cell">-</p>
                                                <p class="cpny-rank__slide--item grid__cell analysis-detail__grid--cell cpny-rank__grid--cell">-</p>
                                                <p class="cpny-rank__slide--item grid__cell analysis-detail__grid--cell cpny-rank__grid--cell">-</p>
                                                <p class="cpny-rank__slide--item grid__cell analysis-detail__grid--cell cpny-rank__grid--cell analysis-detail__grid--two-line">-</p>
                                                <p class="cpny-rank__slide--item grid__cell analysis-detail__grid--cell cpny-rank__grid--cell analysis-detail__grid--two-line">-</p>
                                                <p class="cpny-rank__slide--item grid__cell analysis-detail__grid--cell cpny-rank__grid--cell">-</p>
                                                <p class="cpny-rank__slide--item grid__cell analysis-detail__grid--cell cpny-rank__grid--cell">-</p>
                                                <p class="cpny-rank__slide--item grid__cell analysis-detail__grid--cell cpny-rank__grid--cell">-</p>
                                            </div>
                                            <div class="cpny-rank__slide--group grid__group body analysis-detail__grid--group cpny-rank__grid--group">
                                                <p class="cpny-rank__slide--item grid__cell analysis-detail__grid--cell cpny-rank__grid--cell">-</p>
                                                <p class="cpny-rank__slide--item grid__cell analysis-detail__grid--cell cpny-rank__grid--cell">-</p>
                                                <p class="cpny-rank__slide--item grid__cell analysis-detail__grid--cell cpny-rank__grid--cell">-</p>
                                                <p class="cpny-rank__slide--item grid__cell analysis-detail__grid--cell cpny-rank__grid--cell">-</p>
                                                <p class="cpny-rank__slide--item grid__cell analysis-detail__grid--cell cpny-rank__grid--cell">-</p>
                                                <p class="cpny-rank__slide--item grid__cell analysis-detail__grid--cell cpny-rank__grid--cell">-</p>
                                                <p class="cpny-rank__slide--item grid__cell analysis-detail__grid--cell cpny-rank__grid--cell analysis-detail__grid--two-line">-</p>
                                                <p class="cpny-rank__slide--item grid__cell analysis-detail__grid--cell cpny-rank__grid--cell analysis-detail__grid--two-line">-</p>
                                                <p class="cpny-rank__slide--item grid__cell analysis-detail__grid--cell cpny-rank__grid--cell">-</p>
                                                <p class="cpny-rank__slide--item grid__cell analysis-detail__grid--cell cpny-rank__grid--cell">-</p>
                                                <p class="cpny-rank__slide--item grid__cell analysis-detail__grid--cell cpny-rank__grid--cell">-</p>
                                            </div>
                                            <div class="cpny-rank__slide--group grid__group body analysis-detail__grid--group cpny-rank__grid--group">
                                                <p class="cpny-rank__slide--item grid__cell analysis-detail__grid--cell cpny-rank__grid--cell">-</p>
                                                <p class="cpny-rank__slide--item grid__cell analysis-detail__grid--cell cpny-rank__grid--cell">-</p>
                                                <p class="cpny-rank__slide--item grid__cell analysis-detail__grid--cell cpny-rank__grid--cell">-</p>
                                                <p class="cpny-rank__slide--item grid__cell analysis-detail__grid--cell cpny-rank__grid--cell">-</p>
                                                <p class="cpny-rank__slide--item grid__cell analysis-detail__grid--cell cpny-rank__grid--cell">-</p>
                                                <p class="cpny-rank__slide--item grid__cell analysis-detail__grid--cell cpny-rank__grid--cell">-</p>
                                                <p class="cpny-rank__slide--item grid__cell analysis-detail__grid--cell cpny-rank__grid--cell analysis-detail__grid--two-line">-</p>
                                                <p class="cpny-rank__slide--item grid__cell analysis-detail__grid--cell cpny-rank__grid--cell analysis-detail__grid--two-line">-</p>
                                                <p class="cpny-rank__slide--item grid__cell analysis-detail__grid--cell cpny-rank__grid--cell">-</p>
                                                <p class="cpny-rank__slide--item grid__cell analysis-detail__grid--cell cpny-rank__grid--cell">-</p>
                                                <p class="cpny-rank__slide--item grid__cell analysis-detail__grid--cell cpny-rank__grid--cell">-</p>
                                            </div>
                                            <div class="cpny-rank__slide--group grid__group body analysis-detail__grid--group cpny-rank__grid--group">
                                                <p class="cpny-rank__slide--item grid__cell analysis-detail__grid--cell cpny-rank__grid--cell">-</p>
                                                <p class="cpny-rank__slide--item grid__cell analysis-detail__grid--cell cpny-rank__grid--cell">-</p>
                                                <p class="cpny-rank__slide--item grid__cell analysis-detail__grid--cell cpny-rank__grid--cell">-</p>
                                                <p class="cpny-rank__slide--item grid__cell analysis-detail__grid--cell cpny-rank__grid--cell">-</p>
                                                <p class="cpny-rank__slide--item grid__cell analysis-detail__grid--cell cpny-rank__grid--cell">-</p>
                                                <p class="cpny-rank__slide--item grid__cell analysis-detail__grid--cell cpny-rank__grid--cell">-</p>
                                                <p class="cpny-rank__slide--item grid__cell analysis-detail__grid--cell cpny-rank__grid--cell analysis-detail__grid--two-line">-</p>
                                                <p class="cpny-rank__slide--item grid__cell analysis-detail__grid--cell cpny-rank__grid--cell analysis-detail__grid--two-line">-</p>
                                                <p class="cpny-rank__slide--item grid__cell analysis-detail__grid--cell cpny-rank__grid--cell">-</p>
                                                <p class="cpny-rank__slide--item grid__cell analysis-detail__grid--cell cpny-rank__grid--cell">-</p>
                                                <p class="cpny-rank__slide--item grid__cell analysis-detail__grid--cell cpny-rank__grid--cell">-</p>
                                            </div>
                                            <div class="cpny-rank__slide--group grid__group body analysis-detail__grid--group cpny-rank__grid--group">
                                                <p class="cpny-rank__slide--item grid__cell analysis-detail__grid--cell cpny-rank__grid--cell">-</p>
                                                <p class="cpny-rank__slide--item grid__cell analysis-detail__grid--cell cpny-rank__grid--cell">-</p>
                                                <p class="cpny-rank__slide--item grid__cell analysis-detail__grid--cell cpny-rank__grid--cell">-</p>
                                                <p class="cpny-rank__slide--item grid__cell analysis-detail__grid--cell cpny-rank__grid--cell">-</p>
                                                <p class="cpny-rank__slide--item grid__cell analysis-detail__grid--cell cpny-rank__grid--cell">-</p>
                                                <p class="cpny-rank__slide--item grid__cell analysis-detail__grid--cell cpny-rank__grid--cell">-</p>
                                                <p class="cpny-rank__slide--item grid__cell analysis-detail__grid--cell cpny-rank__grid--cell analysis-detail__grid--two-line">-</p>
                                                <p class="cpny-rank__slide--item grid__cell analysis-detail__grid--cell cpny-rank__grid--cell analysis-detail__grid--two-line">-</p>
                                                <p class="cpny-rank__slide--item grid__cell analysis-detail__grid--cell cpny-rank__grid--cell">-</p>
                                                <p class="cpny-rank__slide--item grid__cell analysis-detail__grid--cell cpny-rank__grid--cell">-</p>
                                                <p class="cpny-rank__slide--item grid__cell analysis-detail__grid--cell cpny-rank__grid--cell">-</p>
                                            </div>
                                            <div class="cpny-rank__slide--group grid__group body analysis-detail__grid--group cpny-rank__grid--group">
                                                <p class="cpny-rank__slide--item grid__cell analysis-detail__grid--cell cpny-rank__grid--cell">-</p>
                                                <p class="cpny-rank__slide--item grid__cell analysis-detail__grid--cell cpny-rank__grid--cell">-</p>
                                                <p class="cpny-rank__slide--item grid__cell analysis-detail__grid--cell cpny-rank__grid--cell">-</p>
                                                <p class="cpny-rank__slide--item grid__cell analysis-detail__grid--cell cpny-rank__grid--cell">-</p>
                                                <p class="cpny-rank__slide--item grid__cell analysis-detail__grid--cell cpny-rank__grid--cell">-</p>
                                                <p class="cpny-rank__slide--item grid__cell analysis-detail__grid--cell cpny-rank__grid--cell">-</p>
                                                <p class="cpny-rank__slide--item grid__cell analysis-detail__grid--cell cpny-rank__grid--cell analysis-detail__grid--two-line">-</p>
                                                <p class="cpny-rank__slide--item grid__cell analysis-detail__grid--cell cpny-rank__grid--cell analysis-detail__grid--two-line">-</p>
                                                <p class="cpny-rank__slide--item grid__cell analysis-detail__grid--cell cpny-rank__grid--cell">-</p>
                                                <p class="cpny-rank__slide--item grid__cell analysis-detail__grid--cell cpny-rank__grid--cell">-</p>
                                                <p class="cpny-rank__slide--item grid__cell analysis-detail__grid--cell cpny-rank__grid--cell">-</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="cpny-rank__btn pc">
                                    <!-- 비활성화시 btn__non-active 추가, 활성화시 btn__non-active 삭제-->
                                    <a class="btn-basic btn-line-cw btn__non-active" v-show="bddprPrtcCnt == 0">참여업체 모두 보기</a>
                                    <a href="javascript:void(0);" class="btn-basic btn-cta" v-show="bddprPrtcCnt != 0" @click="popBddprPrtcCmpList('')">참여업체 모두 보기</a>
                                </div>
                            </div>
                        </div>
                        <!-- /참여업체순위 -->

                    </div>
                    <!-- /개찰정보 탭 -->
                </div>
            </div>
            <!-- /공고 정보와 개찰정보 -->
        </div>
        <!-- /공고 상세 내용 -->
    </div>
</template>

<script>
    import mock from './mock';
    import $ from 'jquery';
    import 'slick-carousel/slick/slick.css';
    import 'slick-carousel/slick/slick.min.js';

    export default {
        name: "notiDetail",
        metaInfo: {
            title: '[비드톡톡 공고상세] - 전자입찰 나라장터(g2b) 공고 투찰 낙찰',
            link: [{ rel: "canonical", href: 'https://www.bidtok.co.kr/' }],
            meta: [
                { vmid: "description", name: 'description', content: '비드톡톡에서 공고 상세를 확인해 보세요. 공고 정보(기본정보, 투찰일정, 추가정보, 기타정보, 첨부파일)와 개찰 정보와 내 투찰 순위 등을 확인할 수 있습니다.' },
                { vmid: "og:title", property: 'og:title', content: '[비드톡톡 공고상세] - 전자입찰 나라장터(g2b) 공고 투찰 낙찰' },
                { vmid: "og:url", property: 'og:url', content: 'https://www.bidtok.co.kr/' },
                { vmid: "og:description", property: 'og:description', content: '비드톡톡에서 공고 상세를 확인해 보세요. 공고 정보(기본정보, 투찰일정, 추가정보, 기타정보, 첨부파일)와 개찰 정보와 내 투찰 순위 등을 확인할 수 있습니다.' },
                { vmid: "og:image", property: "og:image" , content : "favicon.ico"},
            ]
        },
        data(){
            return{

                //공고상세
                notiDetail : {},
                notiAtchmnflList : {}, //첨부파일
                notiFile: null, // 공고문
                notiBddprPrtcCmp : {},
                notiCompnoList : {},

                //복수예비가 순위
                notiCompnoListA : {},
                notiCompnoListB : {},
                notiCompnoListC : {},

                //참여업체 순위
                bddprPrtcCmp : {},
                bddprPrtcCnt : 0,
                bddprPrtcCmp10 : {},

                //가격점수
                notiPriceScore : {},

                //탭메뉴
                tabMenu : 0,

                //슬릭 로드 여부
                isSlickLoad : false,

                //참여업체순위 검색 텍스트
                searchString : "",
                searchType : "CMP_NM",

                //대표기관목록
                rpsntOrgnzCdList: [],
                rpsntOrgnzNm : '',
                rpsntOrgnzImg: '',

                //주력면허
                noticeMainLicnsList: [],

            }
        },

        filters: {
            notidate : function (value) {
                if (!value || value.trim() === '') {
                    return '-';
                }

                var date = new Date(value);

                var days = ['일', '월', '화', '수', '목', '금', '토'];

                var dayOfWeek = days[date.getDay()];

                var month = String(date.getMonth() + 1).padStart(2, '0');
                var day = String(date.getDate()).padStart(2, '0');
                var hour = String(date.getHours()).padStart(2, '0');
                var minute = String(date.getMinutes()).padStart(2, '0');

                var formattedDateTime = `${date.getFullYear()}/${month}/${day} (${dayOfWeek}) ${hour}:${minute}`;

                return formattedDateTime;
            },

            formatbizno: function(value) {
                if (!value) {
                    return '-';
                }

                var formattedBizno = value.replace(/^(\d{3})(\d{2})(\d{5})$/, "$1-$2-$3");
                return formattedBizno;
            }
        },

        methods: {

            setSlick() {

                $('.info-card__box.center').slick({
                    //setPosition : 0,
                    slidesToShow: 1,
                    SlidesToScroll: 1,
                    dots: true,
                    arrows: false,

                });

                $('.cpny-rank__slide--box').slick({
                    //setPosition : 0,
                    dots: true,
                    arrows: false,
                    slidesToShow: 1,
                });
            },
            async loadData(){
                try{
                    await this.getPriceScore();
                    await this.getNotiDetailList();
                    this.getRpsntOrgnzCdList();
                    await this.getNotiCmpList();
                    this.setSlick();
                } catch(error){
                    console.error(error)
                }
            },
            //대표기관목록 가져오기
            //가격점수 세팅
            getRpsntOrgnzCdList(){
                var param = {
                    notiId: this.$route.params.id,
                };

                const config = {
                    headers: {
                        'Content-Type': 'application/json',
                        'auth-token': localStorage.getItem("authToken"),
                        'refresh-token': localStorage.getItem("refreshToken"),
                    },
                };

                this.$http.post('/api/comn/rpsntOrgnzCdList',param, config)
                    .then(res => {
                        this.rpsntOrgnzCdList = res.data;

                        var rpsntOrgnz =  this.rpsntOrgnzCdList.find((element) => element.rpsntOrgnzCd == this.notiDetail.rpsntOrgnzCd);

                        if(rpsntOrgnz != null){
                            this.rpsntOrgnzNm = rpsntOrgnz.rpsntOrgnzNm;
                            this.rpsntOrgnzImg = '/'+ rpsntOrgnz.ref1;
                        }
                    })
                    .catch(error => console.log(error));
            },

            //가격점수 세팅
            async getPriceScore(){
                var param = {
                    notiId: this.$route.params.id,
                };

                const config = {
                    headers: {
                        'Content-Type': 'application/json',
                        'auth-token': localStorage.getItem("authToken"),
                        'refresh-token': localStorage.getItem("refreshToken"),
                    },
                };

                await this.$http.post('/api/cfmtUndwrt/notiPriceScore',param, config)
                    .then(res => {
                        this.notiPriceScore = res.data.notiPriceScore;

                    })
                    .catch(error => console.log(error));
            },

            //공고상세 불러오기
            async getNotiDetailList() {
                var custInfo = JSON.parse(localStorage.getItem("custInfo"));

                var postData = {
                    notiId: this.$route.params.id,
                    custBizId: custInfo != null ?custInfo.custBizList[0].id : null,

                };

                const config = {
                    headers: {
                        'Content-Type': 'application/json',
                        'auth-token': localStorage.getItem("authToken"),
                        'refresh-token': localStorage.getItem("refreshToken"),
                    },
                };


                await this.$http.post('/api/popup/getNotiAnlsDetail',postData, config)
                    .then(res => {

                        this.notiDetail = res.data.notiDetail;
                        this.notiAtchmnflList = res.data.notiAtchmnflList;
                        this.notiBddprPrtcCmp = res.data.notiBddprPrtcCmp;
                        this.notiCompnoList = res.data.notiCompnoList;
                        this.noticeMainLicnsList = this.notiDetail.noticeMainLicnsList.map((e) => e.licnsNm).join(" | "); // 주력면허 변수처리

                        mock.notiTable.noti.push(res.data.notiDetail);
                        mock.notiTable.notiAtchmnflList.push(res.data.notiAtchmnflList);
                        mock.notiTable.notiBddprPrtcCmp.push(res.data.notiBddprPrtcCmp);
                        mock.notiTable.notiCompnoList.push(res.data.notiCompnoList);

                        //공고문 확인
                        var idx = this.notiAtchmnflList.findIndex((element) => {
                            if (element.fileDstnctNm != null) {
                                element.fileDstnctNm.toString().length > 2 && !element.fileNm.toString().includes('.zip') &&
                                (element.fileDstnctNm.toString().substring(0, 3) == '공고서' || element.fileDstnctNm.toString().substring(0, 3) == '공고문')
                            }
                        });

                        if(idx != -1){
                            this.notiFile =  this.notiAtchmnflList[idx];
                            this.notiAtchmnflList.splice(idx,1);
                        }

                        //지역
                        var notiArea = this.notiDetail.notiAreaList.map(o => o.areaNm ? o.prvnclNm + o.areaNm : o.prvnclNm);
                        notiArea = Array.from(new Set(notiArea)).join(" | ");
                        this.notiDetail.notiAreaDisp = notiArea;

                        //면허
                        var notiLicns = this.notiDetail.notiLicnsList.map(o => o.licnsNm);
                        notiLicns = Array.from(new Set(notiLicns)).join(" | ");
                        this.notiDetail.notiLicnsDisp = notiLicns;

                        if(this.notiBddprPrtcCmp){
                            //내 투찰순위 추첨번호 쉼표
                            this.notiBddprPrtcCmp.choicePrdprcNo = this.notiBddprPrtcCmp.choicePrdprcNo.replace(/\s{2,}/g, ",");

                            //예가대비 투찰율
                            var stttAmt = 0;
                            if (this.notiBddprPrtcCmp.stttAmtCfmtCd == '1') { // 법정금액적격코드(0:일반/1:적용/2:미적용)
                                stttAmt = Number(this.notiBddprPrtcCmp.stttAmt);
                            }
                            if ((this.notiBddprPrtcCmp.rpsntOrgnzCd == '6' || this.notiBddprPrtcCmp.rpsntOrgnzCd == '23') && this.notiBddprPrtcCmp.stttAmtCfmtCd == '1') { // (도로공사 or 한국국토정보공사) && 법정금액적용(0:일반/1:적용/2:미적용)
                                // 예가대비 투찰율 = (투찰금액 / 개찰예정금액) * 100
                                this.notiBddprPrtcCmp.opbdPrargPricePercent = ((this.removeComma(this.notiBddprPrtcCmp.bddprAmt) / this.removeComma(this.notiBddprPrtcCmp.opbdPrargPrice)) * 100).toFixed(4);
                            } else {
                                // 예가대비 투찰율 = ((투찰금액 - 법정금액합산) / (개찰예정금액 - 법정금액합산)) * 100
                                this.notiBddprPrtcCmp.opbdPrargPricePercent = (((this.removeComma(this.notiBddprPrtcCmp.bddprAmt) - stttAmt) / (this.removeComma(this.notiBddprPrtcCmp.opbdPrargPrice) - stttAmt)) * 100).toFixed(4);
                            }
                            if (this.notiBddprPrtcCmp.opbdPrargPricePercent == "Infinity") {
                                this.notiBddprPrtcCmp.opbdPrargPricePercent = (0).toFixed(4);
                            }

                            //가격점수
                            this.notiBddprPrtcCmp.priceScore = this.calPriceScore((this.notiBddprPrtcCmp.opbdPrargPricePercent * 1).toFixed(2));
                        }

                        //복수 예가
                        if(this.notiCompnoList){
                            this.notiCompnoList = res.data.notiCompnoList.sort((a, b) => a.sortOrdr - b.sortOrdr);
                            this.notiDetail.yegaSum = 0;
                            this.notiDetail.selectedYega = "";

                            //예가 추첨
                            for (let i = 0; i < this.notiCompnoList.length; i++) {
                                const one = this.notiCompnoList[i];
                                one.value = (-100 + one.compnoPrdprcRt).toFixed(4) * 1;
                                this.notiDetail.yegaSum = (this.notiDetail.yegaSum + one.value).toFixed(4) * 1;

                                // 선택된예가
                                if (one.opbdChoiceYn == 'Y') {
                                    this.notiDetail.selectedYega += " " + one.sortOrdr;
                                }
                            }
                            this.notiDetail.selectedYega = this.notiDetail.selectedYega.trim().replace(/ /g, ",");

                            //예가 순위
                            this.notiCompnoListA = this.notiCompnoList.slice(0, 8);
                            this.notiCompnoListB = this.notiCompnoList.slice(8);

                            //빈배열생성(복수예비가 10개나올때도있어서 분기처리)
                            this.notiCompnoListC = Array(8 - this.notiCompnoListB.length).fill([]);
                        }

                        if(this.notiDetail.notiStatus == '개찰완료'){
                            this.tabMenu = 1;
                        }

                    })
                    .catch(error => console.log(error));
            },

            //참여업체 순위
            async getNotiCmpList() {

                var postData = {
                    "pageIndex": 1,
                    "strtRecordIntex": 0,
                    "pageUnit": 10,
                    "notiId": this.$route.params.id,
                    "searchType": this.searchType,
                    "searchString": this.searchString
                };

                const config = {
                    headers: {
                        'Content-Type': 'application/json',
                        'auth-token': localStorage.getItem("authToken"),
                        'refresh-token': localStorage.getItem("refreshToken"),
                    },
                };



                await this.$http.post('/api/noti/notiBddprPrtcCmpList',postData, config)
                    .then(res => {
                        this.bddprPrtcCmp = res.data.notiBddprPrtcCmpList;
                        this.bddprPrtcCnt = res.data.notiBddprPrtcCmpCnt;

                        this.bddprPrtcCmp10 = this.bddprPrtcCmp.slice(0, 10);

                        for (var i = 0; i < this.bddprPrtcCmp10.length; i++) {
                            //내 투찰순위 추첨번호 쉼표
                            this.bddprPrtcCmp10[i].choicePrdprcNo = this.bddprPrtcCmp10[i].choicePrdprcNo.replace(/\s{2,}/g, ",");

                            //예가대비 투찰율
                            var stttAmt = 0;
                            if (this.bddprPrtcCmp10[i].stttAmtCfmtCd == '1') { // 법정금액적격코드(0:일반/1:적용/2:미적용)
                                stttAmt = Number(this.bddprPrtcCmp10[i].stttAmt);
                            }
                            if ((this.bddprPrtcCmp10[i].rpsntOrgnzCd == '6' || this.bddprPrtcCmp10[i].rpsntOrgnzCd == '23') && this.bddprPrtcCmp10[i].stttAmtCfmtCd == '1') { // (도로공사 or 한국국토정보공사) && 법정금액적용(0:일반/1:적용/2:미적용)
                                // 예가대비 투찰율 = (투찰금액 / 개찰예정금액) * 100
                                this.bddprPrtcCmp10[i].opbdPrargPricePercent = ((this.removeComma(this.bddprPrtcCmp10[i].bddprAmt) / this.removeComma(this.bddprPrtcCmp10[i].opbdPrargPrice)) * 100).toFixed(4);
                            } else {
                                // 예가대비 투찰율 = ((투찰금액 - 법정금액합산) / (개찰예정금액 - 법정금액합산)) * 100
                                this.bddprPrtcCmp10[i].opbdPrargPricePercent = (((this.removeComma(this.bddprPrtcCmp10[i].bddprAmt) - stttAmt) / (this.removeComma(this.bddprPrtcCmp10[i].opbdPrargPrice) - stttAmt)) * 100).toFixed(4);
                            }
                            if (this.bddprPrtcCmp10[i].opbdPrargPricePercent == "Infinity") {
                                this.bddprPrtcCmp10[i].opbdPrargPricePercent = (0).toFixed(4);
                            }

                            //참여업체순위 가격점수
                            this.bddprPrtcCmp10[i].priceScore = this.calPriceScore((this.bddprPrtcCmp10[i].opbdPrargPricePercent * 1).toFixed(2));
                        }
                    })
                    .catch(error => console.log(error));
            },

            popBddprPrtcCmpList(value) {
                if (this.$route.path != "/bddprList") // 현재 route와 다른 경우만 페이지 이동
                    window.open('/bddprList'+'/'+this.$route.params.id + '?searchString='+value+'&searchType='+this.searchType,'_blank' ,'width=1200,height=1000');

            },

            /**
             * 주어진 값에 숫자를 제외한 문자를 제거한다.
             *
             * @param number 숫자
             * @param isPoint 소수점 여부(기본값: false)
             * @param isZero 공백일 경우 0 여부
             * @returns {string} 콤마 제거된 값
             */
            removeComma: function(number, isPoint, isZero) {
                number = String(number);
                if (number === '0') return number;

                number = isPoint ? number.replace(/[^\d]+/g, '') : number.replace(/[^.\d]+/g, '');
                number = number.replace(/(^0+)/, '');
                return !number && isZero ? 0 : number;
            },

            //가격점수 계산
            calPriceScore: function(yegaBddprRt) {
                var a = this.notiPriceScore;

                var priceScore = 0;
                if (yegaBddprRt < 88.25) {
                    priceScore = a.stDistmk - Math.abs(88 - yegaBddprRt) * a.mltplcDistmk;
                } else {
                    priceScore = a.stPriceScore;
                }
                priceScore = (Math.round(priceScore * 100) / 100);
                return priceScore;
            },

            //첨부파일 뷰어
            openNotiAtchmnfl(atchmnfl) {
                const config = {
                    headers: {
                        'Content-Type': 'application/json',
                        'docs-Token': 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJjbXBObSI6Iuq0gOumrOyekCIsInJvbGUiOiJST0xFX1VTRVIiLCJpc3MiOiJzeXNvZnQiLCJpZCI6IjAiLCJleHAiOjQ3MjU0ODMzNDIsImN1c3RJZE5tIjoidjAxLW1hbmFnZXIifQ.ZKVurE97HW07nwYljgjMj8fFCL0UyKU9SKfv0hSiWoc'
                    },
                };
                try {
                        this.$http.get('/docs/collector/viewHtml?docId=' + atchmnfl.id , config)
                        .then(res => {
                            console.log(res);
                            window.open(res.data.filePath , '_blank' , "width=1000,height=800,history=no,resizable=yes,status=no,scrollbars=yes,menubar=no,directories=0");
                        })
                        .catch(error => console.log(error));
                } catch (error) {
                    console.error('Error:', error);
                }
            },

            // 첨부파일 다운로드
            fnAtchmnFlDown(atchmnfl) {
                const link = document.createElement('a');
                link.href = this.getAtchmnflPathNm(atchmnfl);
                link.target = '_blank';
                link.download = atchmnfl.fileNm;
                link.click();
            },

            getAtchmnflPathNm(atchmnfl) {
                try {
                    // 내부서버(OLD)
                    if(atchmnfl.filePathNm.indexOf('/home/File/html/NoticeFile') != -1 ) {
                        var filePathArray = atchmnfl.filePathNm.split('/');
                        var filePathNm = 'http://file.bidin.co.kr/?Class=Notice&Mode=Down&NewOffice=1';
                        filePathNm += atchmnfl.filePathNm.indexOf('NoticeFileKep') != -1 ? '&rpsntOrgnzCd=2' : '&rpsntOrgnzCd=';
                        filePathNm += '&fileName=' + filePathArray[5];
                        filePathNm += '&fileNm=' + encodeURI(atchmnfl.fileNm);
                        return filePathNm;
                    }

                    // 내부서버(NEW)
                    if(atchmnfl.filePathNm.indexOf('/home/sysoft/') != -1 ) {
                        // return 'http://file.sysofting.co.kr' + atchmnfl.filePathNm;
                        return 'http://docs.sysofting.co.kr:8092/docs/collector/downOrg?docId=' + atchmnfl.id;
                    }

                    // 수자원공사
                    if(atchmnfl.filePathNm.indexOf("do?xmlValue=") != -1 ) {
                        var afph = atchmnfl.filePathNm.split("=");
                        return afph[0] + "=" + encodeURIComponent(afph[1]);
                    }

                    // 토지주택공사
                    if(atchmnfl.filePathNm.indexOf('ebid.lh.or.kr') != -1 ) {
                        var afph2 = atchmnfl.filePathNm.split("&");
                        var filePathNm2 = afph[0];
                        for( var i=1 ; i<afph2.length ; i++ ) {
                            var afphloop = afph2[i].split("=");
                            filePathNm += afphloop[1] != '' ? "&"+afphloop[0] + "=" + encodeURI(afphloop[1]) : "&"+afphloop[0] + "=" + afphloop[1];
                        }
                        return filePathNm2;
                    }

                    return atchmnfl.filePathNm;
                } catch( e ) {
                    return '';
                }
            },


            //인쇄하기
            printPage() {
                window.print();
            },

            //복사하기
            copyNotiNo() {
                this.$copyText(this.notiDetail.notiNo);
                this.snackbar = true;
                this.copyText = this.notiDetail.notiNo;
                this.$stopEventBubble();


                this.$toast.success("공고번호를 복사했습니다.", {
                    position:'top-center',
                    timeout: 2000,
                    closeOnClick: true,
                    pauseOnHover: true,
                    transitionDuration: 750,
                    draggable: true,
                    draggablePercent: 0.6,
                    showCloseButtonOnHover: false,
                    hideProgressBar: true,
                    closeButton: "button",
                    icon: true
                });

            },

            //공고원문
            fnOrignOpen() {
                const url = this.getNotiOrignUrl(this.notiDetail);
                if (url) {
                    window.open(url);
                }
            },

            getNotiOrignUrl(g_notice) {
                var url = '';

                switch (g_notice.rpsntOrgnzCd) {
                    case '1':   // 나라장터
                        //var taskClCd = '';
                        //if (g_notice.notiKindCd == '0') taskClCd = '3';      // 시설
                        //else if (g_notice.notiKindCd == '1') taskClCd = '5';  // 용역
                        //else if (g_notice.notiKindCd == '2') taskClCd = '1';  // 물품
                        // url = 'https://www.g2b.go.kr:8081/ep/invitation/publish/bidInfoDtl.do';
                        // url += '?bidno=' + g_notice.notiNo + '&bidseq=' + g_notice.notiSeq + '&releaseYn=Y&taskClCd=' + taskClCd;
                        url = 'https://www.g2b.go.kr';
                        url += encodeURI("/link/PNPE027_01/single/?bidPbancNo="+g_notice.notiNo+"&bidPbancOrd="+g_notice.notiSeq);
                        break;
                    case '2':   // 한국전력공사
                        url = 'http://srm.kepco.net/';
                        break;
                    case '3':   // 국방부
                        if (g_notice.vltrnNotiYn == 'N') {  // 경쟁입찰
                            if (g_notice.notiKindCd == '0') {   // 시설
                                url = 'http://www.d2b.go.kr/Internet/jsp/peb/HI_PEB_Main.jsp?md=421&cfn=HI_PEB_Announce_Lst&txtG2bNumb=' + g_notice.notiNo;
                            } else {
                                url = 'http://www.d2b.go.kr/Internet/jsp/pdb/HI_PDB_Main.jsp?md=221&cfn=HI_PDB_Announce_Lst&txtG2bNumb=' + g_notice.notiNo;
                            }
                        } else {    // 공개수의협상
                            if (g_notice.notiKindCd == '0') {   // 시설
                                url = 'http://www.d2b.go.kr/Internet/jsp/peb/HI_PEB_Main.jsp?md=441&cfn=HI_PEB_OpenNego_Lst&txtCsrtName=' + encodeURIComponent(g_notice.notiNm);
                            } else {
                                url = 'http://www.d2b.go.kr/Internet/jsp/pdb/HI_PDB_Main.jsp?md=241&cfn=HI_PDB_OpenNego_Lst&txtRpstItnm=' + encodeURIComponent(g_notice.notiNm);
                            }
                        }

                        // 2018 07 24 추가
                        if (g_notice.orignPathNm != "" && g_notice.orignPathNm != null) {
                            url = 'http://www.d2b.go.kr/peb/bid/announceList.do?';
                            if (g_notice.cntrMthdNm.indexOf("경쟁") != -1) {
                                url = 'http://www.d2b.go.kr/peb/bid/announceView.do?';
                            }
                            if (g_notice.cntrMthdNm.indexOf("수의") != -1) {
                                url = 'http://www.d2b.go.kr/peb/openNego/openNegoPlanView.do?';
                            }

                            if (g_notice.orignPathUrl != null && g_notice.orignPathUrl != "") {
                                url = g_notice.orignPathUrl + g_notice.orignPathNm;
                            } else {
                                url += g_notice.orignPathNm;
                            }
                        }

                        break;
                    case '4':   // 전자통신연구원
                        url = 'https://ebid.etri.re.kr/ebid/';
                        break;
                    case '5':   // 토지주택공사
                        url = 'http://ebid.lh.or.kr/ebid.et.tp.cmd.BidMasterListCmd.dev';
                        break;
                    case '6':   // 한국도로공사
                        url = 'https://ebid.ex.co.kr';
                        break;
                    case '8':   // 한국마사회
                        url = 'http://ebid.kra.co.kr/bid/notice/all/view.do?b_code=' + g_notice.notiNo.replace('KRA', '');
                        break;
                    case '9':   // 한국가스공사
                        url = 'http://bid.kogas.or.kr/supplier/contents/bid/bid_detail_view_notice.jsp?notice_code=' + g_notice.notiNo + '&bid_code=001&round=01&is_gongo=true&is_estimate=false&is_mine=false';
                        break;
                    case '10':  // 수자원공사
                        url = 'http://ebid.kwater.or.kr/';
                        break;
                    case '11':  // 인천국제공항공사
                        url = 'http://ebid.airport.kr/UserMain.dc';
                        break;
                    case '12':  // 포스코
                        url = 'http://ebid.airport.kr/UserMain.dc';
                        break;
                    case '13':  // 수력원자력
                        url = 'http://ebiz.khnp.co.kr/MB_BID/B/mbqb110.jsp?depart=0370&number=' + g_notice.notiNo;
                        break;
                    case '14':  // 한전KDN
                        url = 'https://www.power-ec.com/';
                        break;
                    case '19':  // 국가철도공단
                        var notiNo = g_notice.notiNo;
                        var ggNyeondo = notiNo.substr(0, 4);
                        var ggGubun = notiNo.substr(5, 2);
                        var ggIrBeonho = notiNo.substr(8);
                        url = 'http://ebid.kr.or.kr/eprocm01/IeProcTenderCS?popup=pop&cmd=get-f32&gg_nyeondo=' + ggNyeondo + '&gg_gubun=' + ggGubun + '&gg_ir_beonho=' + ggIrBeonho + '&gg_chasu=' + g_notice.notiSeq;
                        break;
                    case '20':  // 한국철도공사
                        url = 'http://ebid.korail.com/goods/printIn.do?zzbidinv=' + g_notice.notiNo + '&zzstnum=' + g_notice.notiSeq;
                        break;
                    case '21':    // 지역난방공사
                        url = 'http://econtract.kdhc.co.kr/';
                        break;
                    case '23':    // 대한지적공사
                        url = 'http://ebid.lx.or.kr/bid/cus/bid_cus_noti_r02.jsp?spageno=1&plan_num=' + g_notice.notiNo + '&plan_deg=1';
                        break;
                    case '24':    // 석유공사
                        url = 'http://ebid.knoc.co.kr/supplier/bid/bid_detail_view_notice.jsp?b_code=' + g_notice.notiNo + '&is_gongo=true';
                        break;
                    case '25':    // 학교장터
                        url = 'http://www.s2b.kr/';
                        break;
                    case '101':    // 강원랜드
                        url = 'https://ebd.high1.co.kr:444/index.jsp';
                        break;
                    default:
                        break;
                }

                return url;


            },

            },

        created() {

        },

        mounted() {

            this.loadData();

            /* 로드, 리사이즈시 실행해야할 함수 */
            window.addEventListener("resize", function(){

            });
            // window.addEventListener('load', function(){
            //     defaultAtivTab(); // 로드시 첫번째 탭메뉴를 활성화 시키는 함수.
            //
            // });

            /* 탭메뉴 */

            // const tabMenu = document.querySelectorAll('.tab > li');
            // const tabCont = document.querySelectorAll('.tabcont__row');
            //
            // if(tabMenu.length) {
            //     tabMenu.forEach(function(element, index){
            //         tabCont[index].style.display = 'block';
            //         element.addEventListener('click', function(){
            //             for (let i = 0; i < tabMenu.length; i++) {
            //                 tabCont[i].style.display = 'none';
            //             }
            //             tabCont[index].style.display = 'block';
            //         });
            //     });
            // }
            //
            // /* 문서 로드시 첫번째 탭메뉴를 active하기 */
            // function defaultAtivTab() {
            //     if (tabMenu.length) {
            //         tabCont[0].style.display = 'block';
            //     }
            // }

            /* 탭메뉴 클릭시  setPosition 호출하기*/
            $(function() {
                $('.tab li').click(function() {
                    // if(!this.isSlickLoad){
                    //     /* 개찰정보 - 참여업체 슬라이드 */
                    //     $('.cpny-rank__slide--box').slick({
                    //         //setPosition : 0,
                    //         dots: true,
                    //         arrows: false,
                    //         slidesToShow: 1,
                    //     });
                    //     this.isSlickLoad = true;
                    // }
                    $('.info-card__box.center').slick('setPosition');
                    $('.cpny-rank__slide--box').slick('setPosition');
                })
            });

            $(document).ready(function(){
                /* 공고 정보 슬라이드 */

            });

            /* 셀렉트박스 */
            const selectBox = document.querySelector('.select-box__sel');
            if (selectBox) {
                selectBox.addEventListener('click', () => selectBox.classList.toggle('on'));

                /* 셀렉트박스 활성화 중 다른 곳 클릭시 꺼짐 */
                document.addEventListener('click', (event) => {
                    const target = event.target;
                    if (!selectBox.contains(target)) {
                        selectBox.classList.remove('on');
                    }
                });
                /* /셀렉트박스 활성화 중 다른 곳 클릭시 꺼짐 */
            }

            const selectBoxItem = document.querySelector('.select-box__list');
            if (selectBoxItem) {
                selectBoxItem.addEventListener('click', (event) => {
                    selectBox.innerText = event.target.innerText;
                    this.searchType = event.target.value;
                    selectBox.classList.remove('on');
                });
            }
            /* /셀렉트박스 */


        }
    }


</script>



