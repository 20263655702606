<template>
    <div class="support wrap">
        <div class="filters">
            <a href="javascript:void(0);" class="filters__item active">공지사항</a>
            <a href="javascript:void(0);" class="filters__item" @click="$goMenu('faq')">자주하는 질문</a>
            <a href="javascript:void(0);" class="filters__item" @click="openRemoteSupport()">원격지원</a>
        </div>

        <div>
            <div class="layout__half--wrap wrap notice-view">
                <div class="layout__half--group notice__side-space--wrap notice-view__side">
                    <div class="notice__side-space--group notice-view__group">
                        <h2 class="menu__title">{{boardDetail.boardTitle}}</h2>
                        <p class="txt__sub date">{{$moment(boardDetail.firstInputDt).format('YY-MM-DD') }}</p>
                    </div>
                </div>
                <div class="layout__half--group notice__cont--wrap notice-view__cont">
                    <div class="layout__detaile-page--cont introduce-view__cont">
                        <p class="layout__detaile-page--txt" v-html="boardDetail.boardContent"></p>
                    </div>
                    <div class="layout__detaile-page--list" @click="$router.go(-1)">
                        <button type="button" class="btn-basic btn-line-cw">
                            <span>목록</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>


<script>
    export default {
        name: "NoticeView",
        metaInfo: {
            title: '비드톡톡',
            link: [{rel: "canonical", href: 'https://www.bidtok.co.kr/home'}],
            meta: [
                { charset: 'utf-8' },
                { name: 'description', content: 'AI 전자입찰 AI분석 입찰 나라장터 조달청' },
                { name: 'keywords', content: '전자입찰, 나라장터, AI분석, 경쟁사분석, 시설, 용역, 물품, 낙찰, 자재, 제안요청서' },
                { property: 'og:title', content: '나라장터 AI 전자입찰 비드톡톡'},
                { property: 'og:type', content: 'article'},
                { property: 'og:url', content: 'https://www.bidtok.co.kr'},
                { property: 'og:description', content: 'AI 전자입찰 AI분석 입찰 나라장터 조달청'}
            ]
        },
        data(){
            return{
                boardList : [],
                boardDetail: {},
            }
        },

        methods: {
            goMenu(menuUrl) {
                if (this.$route.path != "/"+menuUrl) // 현재 route와 다른 경우만 페이지 이동
                    this.$router.push('/'+menuUrl);
            },

            fnSearchBoard(){
                var params = {
                    "id": this.$route.params.id
                };

                this.$http.post('/api/board/boardDetaillist',params)
                // eslint-disable-next-line no-unused-vars
                    .then(res => {
                        this.boardDetail = res.data.boardDetailList;
                    })

                    .catch(error => {console.log(error);}
                    );

            },
            openRemoteSupport() {
                window.open("https://939.co.kr/sysoft/", "_blank", "width=865,height=700,scrollbars=yes");

            },
        },
        created() {

        },

        mounted() {
            this.fnSearchBoard();

            const headerNoticeElement = document.getElementById("notice");
            if (headerNoticeElement) {
                headerNoticeElement.classList.add("active");
            }
        }
    }


</script>



<!--<style src="./NoticeView.scss" lang="scss" scoped />-->