<template>
    <div class="entire-search__container srch">
        <div class="srch--input search">
            <input type="text" placeholder="공고명, 공고번호를 검색해보세요." v-model="searchText" @keyup.enter="searchNotiList(searchText)">
            <button type="reset" class="btn__cancel" @click="searchText='';">취소</button>
        </div>
        <div class="srch__area filter">
            <a href="#" onclick="return false" class="srch--move-btn srch--btn" @click="openFilter(0)">전체 <span class="num"></span></a>
            <a href="#" onclick="return false" class="srch--move-btn srch--btn" @click="openFilter(1)" :class="mock.searchParam.findNotiStatList.length > 0 && mock.searchParam.findNotiStatList[0] != '0' ?'active': ''">상태 <span class="num" v-show="mock.searchParam.findNotiStatList.length > 0 && mock.searchParam.findNotiStatList[0] != '0'">{{mock.searchParam.findNotiStatList.length}}</span></a>
            <a href="#" onclick="return false" class="srch--move-btn srch--btn" @click="openFilter(2)" :class="mock.searchParam.notiSearchAreaCdList.length > 0 ?'active': ''">지역 <span class="num" v-show="mock.searchParam.notiSearchAreaCdList.length > 0">{{mock.searchParam.notiSearchAreaCdList.length}}</span></a>
            <a href="#" onclick="return false" class="srch--move-btn srch--btn" @click="openFilter(3)" :class="mock.searchParam.notiSearchLicnsCdList.length > 0 ?'active': ''">면허 <span class="num" v-show="mock.searchParam.notiSearchLicnsCdList.length > 0">{{mock.searchParam.notiSearchLicnsCdList.length}}</span></a>
            <a href="#" onclick="return false" class="srch--move-btn srch--btn" @click="openFilter(4)" :class="(mock.searchParam.findStrtAmt != null || mock.searchParam.findEndAmt != null) ?'active': ''">금액 <span class="num"></span></a>
            <a href="#" onclick="return false" class="srch--move-btn company srch--btn" @click="openFilter(5)" :class="mock.searchParam.findRpsntOrgnzList.length > 0 ?'active': ''">발주처 <span class="num" v-show="mock.searchParam.findRpsntOrgnzList.length > 0">{{mock.searchParam.findRpsntOrgnzList.length}}</span></a>
            <a href="#" onclick="return false" class="srch--move-btn srch--btn" @click="openFilter(6)" :class="(mock.searchParam.findStrtDt != this.$moment().format('YYYY-MM-DD') || mock.searchParam.findEndDt != this.$moment().add(15, 'days').format('YYYY-MM-DD')) ?'active': ''">기간 <span class="num"></span></a>
        </div>
        <div class="flt-save">
            <div class="flt-save--toggle toggle" v-show="loginInfo != null">
                <span class="txt__title">필터저장</span>
                <input type="checkbox" id="toggle02" hidden="" :checked="saveFilter" @click="setSaveFilterCookie()">
                <label for="toggle02" class="toggle__switch">
                    <span class="toggle__btn"></span>
                </label>
            </div>
            <span class="flt-save--btn srch-layer--btn srch--btn srch--btn--del" v-for="(item,index) in mock.searchParam.findNotiStatList" v-bind:key="item.id" v-show="mock.searchParam.findNotiStatList.length > 0 && mock.searchParam.findNotiStatList[0] != '0'" @click="delFindNotiStatList(index,true);">
                <a href="#" onclick="return false" class="srch-layer--resech"  @click="delFindNotiStatList(index,true);">{{mock.notiStatusList.find(e => e.comnCd == item).comnCdNm}}</a>
            </span>
            <span class="flt-save--btn srch-layer--btn srch--btn srch--btn--del" v-for="(item,index) in mock.searchParam.notiSearchAreaCdList" v-bind:key="item.id" @click="delNotiSearchAreaCdList(index,true);">
                <a href="#" onclick="return false" class="srch-layer--resech" @click="delNotiSearchAreaCdList(index,true);">{{mock.prvnclList.find(e => e.areaCd == item).prvnclNm}} {{mock.prvnclList.find(e => e.areaCd == item).areaNm.length > 0 ? ' '+ mock.prvnclList.find(e => e.areaCd == item).areaNm : ''}}</a></span>
            <span class="flt-save--btn srch-layer--btn srch--btn srch--btn--del" v-for="(item,index) in mock.searchParam.notiSearchLicnsCdList" v-bind:key="item.id" @click="delNotiSearchLicnsCdList(index,true);">
                <a href="#" onclick="return false" class="srch-layer--resech" @click="delNotiSearchLicnsCdList(index,true);">{{mock.licnsCodeList.find(e => e.licnsCd == item).licnsCtgyNm}}</a></span>
            <span class="flt-save--btn srch-layer--btn srch--btn srch--btn--del" v-for="(item,index) in mock.searchParam.findRpsntOrgnzList" v-bind:key="item.id" @click="delFindRpsntOrgnzList(index,true);">
                <a href="#" onclick="return false" class="srch-layer--resech" @click="delFindRpsntOrgnzList(index,true);">{{mock.rpsntOrgnzList.find(e => e.rpsntOrgnzCd == item).rpsntOrgnzNm}}</a></span>
            <span class="flt-save--btn srch-layer--btn srch--btn srch--btn--del" v-show="(mock.searchParam.findStrtAmt != null || mock.searchParam.findEndAmt != null)" @click="delSelAmt(true)">
                <a href="#" onclick="return false" class="srch-layer--resech" @click="delSelAmt(true)">{{sliderValIndicators[sliderVals.findIndex(e => e == mock.searchParam.findStrtAmt)]}} ~ {{sliderValIndicators[sliderVals.findIndex(e => e == mock.searchParam.findEndAmt)]}}</a></span>
            <span class="flt-save--btn srch-layer--btn srch--btn srch--btn--del" v-show="(mock.searchParam.findStrtDt != this.$moment().format('YYYY-MM-DD') || mock.searchParam.findEndDt != this.$moment().add(15, 'days').format('YYYY-MM-DD'))" @click="delSearchDate(true);">
                <a href="#" onclick="return false" class="srch-layer--resech" @click="delSearchDate(true); ">{{mock.searchParam.findStrtDt}} ~ {{mock.searchParam.findEndDt}}</a></span>

        </div>

        <!-- 검색창 레이어 -->
        <div class="srch__area srch-layer srch--lyr-sty">
            <div class="srch-layer__group" v-show="loginInfo != null">
                <span class="txt">최근 검색어</span>
                <button class="btn-del del-all" @click="delSearchHist('')">모두 지우기</button>
            </div>
            <span class="srch-layer--btn srch--btn srch--btn--del" v-for="item in searchHistList" v-bind:key="item.id" @click="delSearchHist(item.searchContent)" v-show="loginInfo != null">
                <a href="#" onclick="return false" class="srch-layer--resech" @click="searchNotiList(item.searchContent); $stopEventBubble();">{{item.searchContent}}</a>
            </span>

            <div class="srch-layer__group" v-show="loginInfo == null">
                <span class="txt">최근 검색어</span>
                <button class="btn-del del-all" @click="delSearchHistory()">모두 지우기</button>
            </div>
            <span class="srch-layer--btn srch--btn srch--btn--del" v-for="(item,index) in searchHistory" v-bind:key="item.id" @click.stop="delSearchHistory(index)" v-show="loginInfo == null">
                <a href="#" onclick="return false" class="srch-layer--resech" @click="searchNotiList(item); $stopEventBubble();">{{item}}</a>
            </span>

        </div>

        <!-- /검색창 레이어 -->

        <!-- 상세 검색 필터 레이어 -->
        <div class="srch__area fil-layer srch--lyr-sty">
            <a href="#" onclick="return false" class="fil-layer--clsbtn">닫기</a>
            <div class="fil-layer__group">
                <ul class="fil-layer--tab tab">
                    <li>
                        <input type="radio" id="tab1-1" name="tab-1" @click="tabNo = 0" :checked="tabNo == 0 ? true : false">
                        <label for="tab1-1">전체</label>
                    </li>
                    <li @click="tabNo==1">
                        <input type="radio" id="tab1-2" name="tab-1" @click="tabNo = 1" :checked="tabNo == 1 ? true : false">
                        <label for="tab1-2">상태</label>
                    </li>
                    <li>
                        <input type="radio" id="tab1-3" name="tab-1" @click="tabNo = 2" :checked="tabNo == 2 ? true : false">
                        <label for="tab1-3">지역</label>
                    </li>
                    <li>
                        <input type="radio" id="tab1-4" name="tab-1" @click="tabNo = 3" :checked="tabNo == 3 ? true : false">
                        <label for="tab1-4">면허</label>
                    </li>
                    <li>
                        <input type="radio" id="tab1-5" name="tab-1" @click="tabNo = 4" :checked="tabNo == 4 ? true : false">
                        <label for="tab1-5">금액</label>
                    </li>
                    <li>
                        <input type="radio" id="tab1-6" name="tab-1" @click="tabNo = 5" :checked="tabNo == 5 ? true : false">
                        <label for="tab1-6">발주처</label>
                    </li>
                    <li>
                        <input type="radio" id="tab1-7" name="tab-1" @click="tabNo = 6" :checked="tabNo == 6 ? true : false">
                        <label for="tab1-7">기간</label>
                    </li>
                </ul>
                <div class="fil-layer--tabcont tabcont">
                    <!-- 전체 필터 flt-ent -->
                    <ul class="fil-layer__row tabcont__row flt-entire" :class="tabNo==0 ? 'active' : ''">
                        <li class="flt-entire--list srch-flt--undlin">
                            <a href="#" onclick="return false" @click="tabNo = 1">상태
                                <span class="txt-sel" v-show="selNotiStatusList.length > 1 ">{{ selNotiStatusList.length > 0 ? selNotiStatusList[0].comnCdNm : ''}} 외 <span>{{selNotiStatusList.length -1 }}건</span></span>
                                <span class="txt-sel" v-show="selNotiStatusList.length == 1 ">{{ selNotiStatusList.length > 0 ? selNotiStatusList[0].comnCdNm : ''}}  </span>
                                <span class="txt-sel" v-show="selNotiStatusList.length == 0 ">전체</span>
                            </a>
                        </li>
                        <li class="flt-entire--list srch-flt--undlin">
                            <a href="#" onclick="return false" @click="tabNo = 2">지역
                                <span class="txt-sel" v-show="selAreaCdList.length > 1 ">{{ selAreaCdList.length > 0 ? selAreaCdList[0].prvnclNm + (selAreaCdList[0].areaNm.length > 0 ? ' '+ selAreaCdList[0].areaNm : '') : ''}} 외 <span>{{selAreaCdList.length -1 }}건</span></span>
                                <span class="txt-sel" v-show="selAreaCdList.length == 1 ">{{ selAreaCdList.length > 0 ? selAreaCdList[0].prvnclNm + (selAreaCdList[0].areaNm.length > 0 ? ' '+ selAreaCdList[0].areaNm : '') : ''}}  </span>
                                <span class="txt-sel" v-show="selAreaCdList.length == 0 ">전체</span>
                            </a>
                        </li>
                        <li class="flt-entire--list srch-flt--undlin">
                            <a href="#" onclick="return false" @click="tabNo = 3">면허
                                <span class="txt-sel" v-show="selLicnsCodeList.length > 1 ">{{ selLicnsCodeList.length > 0 ? selLicnsCodeList[0].licnsCtgyNm : ''}} 외 <span>{{selLicnsCodeList.length -1 }}건</span></span>
                                <span class="txt-sel" v-show="selLicnsCodeList.length == 1 ">{{ selLicnsCodeList.length > 0 ? selLicnsCodeList[0].licnsCtgyNm : ''}}</span>
                                <span class="txt-sel" v-show="selLicnsCodeList.length == 0 ">전체</span>
                            </a>
                        </li>
                        <li class="flt-entire--list srch-flt--undlin">
                            <a href="#" onclick="return false" @click="tabNo = 4">금액
                                <span class="txt-sel" v-show="sliderValue[0] != 0 || sliderValue[1] != 29">{{sliderValIndicators[sliderValue[0]]}} ~ {{sliderValIndicators[sliderValue[1]]}}</span>
                                <span class="txt-sel" v-show="sliderValue[0] == 0 && sliderValue[1] == 29">전체</span>
                            </a>
                        </li>
                        <li class="flt-entire--list srch-flt--undlin">
                            <a href="#" onclick="return false" @click="tabNo = 5">발주처
                                <span class="txt-sel" v-show="selRpsntOrgnzList.length > 1 ">{{ selRpsntOrgnzList.length > 0 ? selRpsntOrgnzList[0].rpsntOrgnzNm : ''}} 외 <span>{{selRpsntOrgnzList.length -1 }}건</span></span>
                                <span class="txt-sel" v-show="selRpsntOrgnzList.length == 1 ">{{ selRpsntOrgnzList.length > 0 ? selRpsntOrgnzList[0].rpsntOrgnzNm : ''}}</span>
                                <span class="txt-sel" v-show="selRpsntOrgnzList.length == 0 ">전체</span>
                            </a>
                        </li>
                        <li class="flt-entire--list srch-flt--undlin">
                            <a href="#" onclick="return false" @click="tabNo = 6">기간<span class="txt-sel">{{$moment(startDt).format('YYYY.MM.DD')}} ~ {{$moment(endDt).format('YYYY.MM.DD')}}</span></a>
                        </li>
                    </ul>
                    <!-- /전체 필터 flt-ent -->

                    <!-- 상태 필터 flt-state-->
                    <ul class="fil-layer__row tabcont__row flt-state" :class="tabNo==1 ? 'active' : ''">
                        <li class="flt-state--btn srch-flt--btn" v-for="item in mock.notiStatusList" v-bind:key="item.id" @click="clickStatus(item)"
                            :class="selNotiStatusList.some((e) => e == item) ? 'active' : ''">{{item.comnCdNm}}</li>
                    </ul>
                    <!-- /상태 필터 flt-state-->

                    <!-- 지역 필터 flt-local-->
                    <div class="fil-layer__row tabcont__row flt-local row" :class="tabNo==2 ? 'active' : ''">
                        <ul class="city">
                            <li class="city--list srch-flt--undlin" v-for="item in mock.areaCodeList" v-bind:key="id=item.id" :class="mock.selPrvncl == item.prvnclCd ? 'active': ''">
                                <a href="#" onclick="return false" @click="clickPrvncl(item)">{{item.prvnclNm}}<span class="txt-sel"> {{mock.prvnclList.filter((e) => e.prvnclCd == item.prvnclCd).length }}</span></a>
                            </li>
                        </ul>
                        <div class="district">
                            <ul class="district__list active">
                                <li class="district__list--item srch-flt--undlin" v-for="(item,index) in mock.selPrvnclList" v-bind:key="item.id" :class="selAreaCdList.some((e) => e == item ) ? 'active' : ''">
                                    <a href="#" onclick="return false" @click="clickArea(index)" >{{item.areaNm.length != 0 ? item.areaNm :'전체'}}</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <!-- /지역 필터 flt-local-->

                    <!-- 면허 필터 flt-lics -->
                    <div class="fil-layer__row tabcont__row flt-lics" :class="tabNo==3 ? 'active' : ''">
                        <div class="flt-lics--search search">
                            <input type="text" placeholder="면허검색" v-model="licnsSearchText">
                            <button type="reset" class="btn__cancel">취소</button>
                        </div>
                        <ul class="flt-lics__list">
                            <li class="flt-lics__list--item srch-flt--undlin" v-for="(item,index) in filtLicnsCodeList" v-bind:key="item.id" :class="selLicnsCodeList.some((e) => e == item ) ? 'active' : ''">
                                <a href="#" onclick="return false" @click="clickLicns(index)">{{item.licnsCtgyNm}}</a>
                            </li>
                        </ul>
                    </div>
                    <!-- /면허 필터 flt-lics -->

                    <!-- 금액 필터 amt-->
                    <div class="fil-layer__row tabcont__row amt" :class="tabNo==4 ? 'active' : ''">
                            <span class="amt--title"><span class="from-amt txt">{{sliderValIndicators[sliderValue[0]]}}</span>~<span
                                    class="to-amt txt">{{sliderValIndicators[sliderValue[1]]}}</span></span>
                        <span class="amt--sub">*기초금액 기준</span>
                        <div class="amt--control range-control" data-role="rangeslider">
                            <el-slider
                                    ref="slider"
                                    v-model="sliderValue"
                                    range
                                    :show-tooltip="false"
                                    :max="29">
                            </el-slider>
<!--                            <input class="to-slider range-slider" type="range" min="0" max="28" v-model="sliderValue[0]" @input="validateSlider1"/>-->
<!--                            <input class="from-slider range-slider" type="range" min="1" max="29" v-model="sliderValue[1]" @input="validateSlider2"/>-->
<!--                            <span class="range-control&#45;&#45;sel"></span>-->
                        </div>
                    </div>
                    <!-- /금액 필터 amt-->

                    <!-- 발주처 필터 flt-ord-->
                    <ul class="fil-layer__row tabcont__row flt-ord" :class="tabNo==5 ? 'active' : ''">
                        <li class="flt-ord--btn srch-flt--btn" v-for="(item,index) in mock.rpsntOrgnzList" v-bind:key="item.id" @click="clickOrgnz(index)" :class="selRpsntOrgnzList.some((e) => e == item ) ? 'active' : ''">
                            <img :src="item.ref1" alt="발주처 로고"
                                 class="flt-ord--img">
                            <p>{{item.rpsntOrgnzNm}}</p>
                        </li>
                    </ul>
                    <!-- /발주처 필터 flt-ord-->

                    <!-- 기간 필터 flt-prd-->
                    <div class="fil-layer__row tabcont__row flt-prd" :class="tabNo==6 ? 'active' : ''">
                        <ul class="flt-prd__group">
                            <li class="flt-prd--btn srch-flt--btn" @click="dateBtn = 0; clickDate(15,'days')" :class="dateBtn == 0 ? 'active' : ''">
                                15일
                            </li>
                            <li class="flt-prd--btn srch-flt--btn" @click="dateBtn = 1; clickDate(1,'month')" :class="dateBtn == 1 ? 'active' : ''">
                                1개월
                            </li>
                            <li class="flt-prd--btn srch-flt--btn" @click="dateBtn = 2; clickDate(3,'month')" :class="dateBtn == 2 ? 'active' : ''">
                                3개월
                            </li>
                        </ul>
                        <div class="flt-prd__group set-dte">
                            <p class="set-dte--title">직접설정</p>
                            <v-date-picker v-model="startDt" >
                                <template v-slot="{ inputValue, togglePopover }">
                                    <a href="#" onclick="return false" class="set-dte--calendar input btn btn-calendar"
                                         @click="togglePopover">
                                        <input
                                                class="input edit-pop--input manage--input"
                                                placeholder="YYYY-MM-DD"
                                                :value="inputValue"
                                        />
                                    </a>
                                </template>
                            </v-date-picker>
                            <span class="set-dte--txt">~</span>
                            <v-date-picker v-model="endDt" >
                                <template v-slot="{ inputValue, togglePopover }">
                                    <a href="#" onclick="return false" class="set-dte--calendar input btn btn-calendar"
                                       @click="togglePopover">
                                        <input
                                                class="input edit-pop--input manage--input"
                                                placeholder="YYYY-MM-DD"
                                                :value="inputValue"
                                        />
                                    </a>
                                </template>
                            </v-date-picker>
                        </div>
                    </div>
                    <!-- /기간 필터 flt-prd-->
                </div>
            </div>
            <div class="fil-layer__group opt-bnr">
                <div class="sel-opt" v-show="tabNo == 0">
                </div>
                <div class="sel-opt" v-show="tabNo == 1">
                    <p class="txt" v-show="selNotiStatusList.length > 1 ">{{ selNotiStatusList.length > 0 ? selNotiStatusList[0].comnCdNm : ''}} 외 <span>{{selNotiStatusList.length -1 }}건</span></p>
                    <p class="txt" v-show="selNotiStatusList.length == 1 ">{{ selNotiStatusList.length > 0 ? selNotiStatusList[0].comnCdNm : ''}}  </p>
                    <a href="#" onclick="return false" class="opt-filt--btn srch--btn srch--btn--del" v-for="(item,index) in selNotiStatusList" v-bind:key="item.id" @click="delSelNotiStatusList(index,false)">{{item.comnCdNm}}</a>
                </div>
                <div class="sel-opt" v-show="tabNo == 2">
                    <p class="txt" v-show="selAreaCdList.length > 1 ">{{ selAreaCdList.length > 0 ? selAreaCdList[0].prvnclNm + (selAreaCdList[0].areaNm.length > 0 ? ' '+ selAreaCdList[0].areaNm : '') : ''}} 외 <span>{{selAreaCdList.length -1 }}건</span></p>
                    <p class="txt" v-show="selAreaCdList.length == 1 ">{{ selAreaCdList.length > 0 ? selAreaCdList[0].prvnclNm + (selAreaCdList[0].areaNm.length > 0 ? ' '+ selAreaCdList[0].areaNm : '') : ''}}  </p>
                    <a href="#" onclick="return false" class="opt-filt--btn srch--btn srch--btn--del" v-for="(item,index) in selAreaCdList" v-bind:key="item.id" @click="delSelAreaCdList(index,false)">{{item.prvnclNm}} {{item.areaNm.length > 0 ? item.areaNm : ''}}</a>
                </div>
                <div class="sel-opt" v-show="tabNo == 3">
                    <p class="txt" v-show="selLicnsCodeList.length > 1 ">{{ selLicnsCodeList.length > 0 ? selLicnsCodeList[0].licnsCtgyNm : ''}} 외 <span>{{selLicnsCodeList.length -1 }}건</span></p>
                    <p class="txt" v-show="selLicnsCodeList.length == 1 ">{{ selLicnsCodeList.length > 0 ? selLicnsCodeList[0].licnsCtgyNm : ''}}</p>
                    <a href="#" onclick="return false" class="opt-filt--btn srch--btn srch--btn--del" v-for="(item,index) in selLicnsCodeList" v-bind:key="item.id" @click="delSelLicnsCodeList(index,false)">{{item.licnsCtgyNm}}</a>
                </div>
                <div class="sel-opt" v-show="tabNo == 4">
                    <p class="txt">ㅤ</p>
                    <a href="#" onclick="return false" class="opt-filt--btn srch--btn srch--btn--del" @click="initFilter()">{{sliderValIndicators[sliderValue[0]]}} ~ {{sliderValIndicators[sliderValue[1]]}}</a>
                </div>
                <div class="sel-opt" v-show="tabNo == 5">
                    <p class="txt" v-show="selRpsntOrgnzList.length > 1 ">{{ selRpsntOrgnzList.length > 0 ? selRpsntOrgnzList[0].rpsntOrgnzNm : ''}} 외 <span>{{selRpsntOrgnzList.length -1 }}건</span></p>
                    <p class="txt" v-show="selRpsntOrgnzList.length == 1 ">{{ selRpsntOrgnzList.length > 0 ? selRpsntOrgnzList[0].rpsntOrgnzNm : ''}}</p>
                    <a href="#" onclick="return false" class="opt-filt--btn srch--btn srch--btn--del" v-for="(item,index) in selRpsntOrgnzList" v-bind:key="item.id" @click="delSelRpsntOrgnzList(index,false)">{{item.rpsntOrgnzNm}}</a>
                </div>
                <div class="sel-opt" v-show="tabNo == 6">
                    <p class="txt">ㅤ</p>
                    <a href="#" onclick="return false" class="opt-filt--btn srch--btn srch--btn--del" @click="delSearchDate(false)">{{$moment(startDt).format('YYYY.MM.DD')}} ~ {{$moment(endDt).format('YYYY.MM.DD')}}</a>
                </div>
                <div class="sel-btn pair">
                    <a href="#" onclick="return false" class="btn-basic btn-line-cb btn-initial" @click="initFilter()">
                        {{tabNo == 1 ? '상태' : ''}}
                        {{tabNo == 2 ? '지역' : ''}}
                        {{tabNo == 3 ? '면허' : ''}}
                        {{tabNo == 4 ? '금액' : ''}}
                        {{tabNo == 5 ? '발주처' : ''}}
                        {{tabNo == 6 ? '기간' : ''}}
                        초기화
                    </a>
                    <a href="#" onclick="return false" class="btn-basic btn-cta" @click="searchNoti()">공고 검색하기</a>
                </div>
            </div>
        </div>
        <!-- 상세 검색 필터 레이어 -->
    </div>
    <!-- 업체 정보 -->
<!--    <div class="edit__area cmpy-info">-->
<!--        <ul>-->
<!--            <li v-for="item in NotiSerachData.notiStatusList" v-bind:key="item.id">{{item}}</li>-->
<!--        </ul>-->
<!--        <ul>-->
<!--            <li v-for="item in NotiSerachData.rpsntOrgnzList" v-bind:key="item.id">{{item}}</li>-->
<!--        </ul>-->
<!--        <ul>-->
<!--            <li v-for="item in NotiSerachData.areaCodeList" v-bind:key="item.id">{{item}}</li>-->
<!--        </ul>-->
<!--        <ul>-->
<!--            <li v-for="item in NotiSerachData.selPrvnclList" v-bind:key="item.id">{{item}}</li>-->
<!--        </ul>-->
<!--        <ul>-->
<!--            <li v-for="item in NotiSerachData.licnsCodeList" v-bind:key="item.id">{{item}}</li>-->
<!--        </ul>-->

<!--        <a href="#" onclick="return false">검색</a>-->

<!--    </div>-->
    <!-- /업체 정보 -->
</template>
<script>
    import mock from '../NotiSearchData';

    export default {
        data() {
            return {
                mock,
                loginInfo: {},
                tabNo: 0,
                searchText: '', //임시 검색어
                searchHistList: [], //최근 검색어

                selNotiStatusList: [],  //공고상태 선택 리스트
                selAreaCdList: [],  //지역 선택 리스트
                filtLicnsCodeList : [], //면허 검색 목록

                selLicnsCodeList : [],  //면허선택 리스트
                licnsSearchText: '',    //면허 검색 텍스트

                sliderVals: [
                   0,
                   10000000,
                   20000000,
                   30000000,
                   40000000,
                   50000000,
                   60000000,
                   70000000,
                   80000000,
                   90000000,
                   100000000,
                   150000000,
                   200000000,
                   300000000,
                   400000000,
                   500000000,
                   600000000,
                   700000000,
                   800000000,
                   900000000,
                   1000000000,
                   2000000000,
                   3000000000,
                   4000000000,
                   5000000000,
                   6000000000,
                   7000000000,
                   8000000000,
                   9000000000,
                   0,
                 ],
             sliderValIndicators: [
               "최소",
               "1천",
               "2천",
               "3천",
               "4천",
               "5천",
               "6천",
               "7천",
               "8천",
               "9천",
               "1억",
               "1억5천",
               "2억",
               "3억",
               "4억",
               "5억",
               "6억",
               "7억",
               "8억",
               "9억",
               "10억",
               "20억",
               "30억",
               "40억",
               "50억",
               "60억",
               "70억",
               "80억",
               "90억",
               "최대",
             ],

                selRpsntOrgnzList: [],  //선택 발주처

                /** 기간 탭 관련 변수*/
                startDt : this.$moment().format('YYYY-MM-DD'),
                endDt : this.$moment().add(15, 'days').format('YYYY-MM-DD'),
                dateBtn: 0,
                /** /기간 탭 관련 변수*/

                searchHistory: [],

                saveFilter: false,
                sliderValue: [0, 29]

            }
        },
        watch: {
            licnsSearchText(){
                this.filtLicnsCodeList = mock.licnsCodeList.filter((e) => e.licnsCtgyNm.indexOf(this.licnsSearchText) != -1);

            },
        },
        methods: {
            openFilter(index){
                this.tabNo = index;
                this.$refs.slider.setValues();
            },
            setSaveFilterCookie() {
                this.saveFilter = !this.saveFilter;
                this.$cookies.set('saveFilter', this.saveFilter ? 'Y' : 'N', { expires: '1Y' });
            },

            loadSearchHistory() {
                const searchHistory = this.$cookies.get('searchHistory');

                if (searchHistory) {
                    this.searchHistory = searchHistory;
                }
            },
            saveSearchQuery() {
                if (this.searchText.trim() !== '' && !this.searchHistory.some((e)=> e == this.searchText)) {
                    // 쿠키에 최근 검색어 저장
                    let updatedSearchHistory = [...this.searchHistory, this.searchText];

                    // 검색어 개수가 최대 허용 개수를 초과하면 가장 오래된 검색어 제거
                    if (updatedSearchHistory.length > 10) {
                        updatedSearchHistory = updatedSearchHistory.slice(1);
                    }
                    this.$cookies.set('searchHistory', JSON.stringify(updatedSearchHistory), { expires: '1Y' });
                }
            },
            delSearchHistory(index) {
                if(index == null){
                    this.searchHistory = [];
                } else {
                    this.searchHistory.splice(index,1);
                }
                this.$cookies.set('searchHistory', JSON.stringify(this.searchHistory), { expires: '1Y' });
            },
            delSearchDate(isSearch) {
                this.startDt = this.$moment().format('YYYY-MM-DD');
                this.endDt = this.$moment().add(15, 'days').format('YYYY-MM-DD');
                if(isSearch){
                    this.searchNoti();
                }
            },
            delSelRpsntOrgnzList(index ,isSearch) {
                if(mock.isSearching == true) {
                    return;
                }
                this.selRpsntOrgnzList.splice(index,1);
                if(isSearch){
                    this.searchNoti();
                }
            },
            delFindRpsntOrgnzList(index,isSearch) {
                if(mock.isSearching == true) {
                    return;
                }
                this.selRpsntOrgnzList = [];
                mock.searchParam.findRpsntOrgnzList.splice(index,1);

                for(var idx in mock.searchParam.findRpsntOrgnzList){
                    this.selRpsntOrgnzList.push(mock.rpsntOrgnzList.find(e => e.rpsntOrgnzCd == mock.searchParam.findRpsntOrgnzList[idx]));
                }

                if(isSearch){
                    this.searchNoti();
                }
            },
            delSelAmt(isSearch) {
                if(mock.isSearching == true) {
                    return;
                }
                this.sliderValue[0] = 0 ;
                this.sliderValue[1] = 29;
                if(isSearch){
                    this.searchNoti();
                }
            },
            delSelLicnsCodeList(index,isSearch) {
                if(mock.isSearching == true) {
                    return;
                }
                this.selLicnsCodeList.splice(index,1);
                if(isSearch){
                    this.searchNoti();
                }
            },
            delNotiSearchLicnsCdList(index,isSearch) {
                if(mock.isSearching == true) {
                    return;
                }
                this.selLicnsCodeList = [];
                mock.searchParam.notiSearchLicnsCdList.splice(index,1);

                for(var idx in mock.searchParam.notiSearchLicnsCdList){
                    this.selLicnsCodeList.push(mock.licnsCodeList.find(e => e.licnsCd == mock.searchParam.notiSearchLicnsCdList[idx]));
                }

                if(isSearch){
                    this.searchNoti();
                }
            },
            delSelAreaCdList(index,isSearch) {
                if(mock.isSearching == true) {
                    return;
                }
                this.selAreaCdList.splice(index,1);
                if(isSearch){
                    this.searchNoti();
                }
            },

            delNotiSearchAreaCdList(index,isSearch) {
                if(mock.isSearching == true) {
                    return;
                }
                this.selAreaCdList = [];
                mock.searchParam.notiSearchAreaCdList.splice(index,1);

                for(var idx in mock.searchParam.notiSearchAreaCdList){
                    this.selAreaCdList.push(mock.prvnclList.find(e => e.areaCd == mock.searchParam.notiSearchAreaCdList[idx]));
                }

                if(isSearch){
                    this.searchNoti();
                }
            },

            delSelNotiStatusList(index,isSearch) {
                if(mock.isSearching == true) {
                    return;
                }
                this.selNotiStatusList.splice(index,1);

                if(this.selNotiStatusList.length == 0 ) {
                    this.selNotiStatusList.push(mock.notiStatusList[0]);
                }
                if(isSearch){
                    this.searchNoti();
                }
            },

            delFindNotiStatList(index,isSearch) {
                if(mock.isSearching == true) {
                    return;
                }
                this.selNotiStatusList = [];
                mock.searchParam.findNotiStatList.splice(index,1);

                for(var idx in mock.searchParam.findNotiStatList){
                    this.selNotiStatusList.push(mock.notiStatusList.find(e => e.comnCd == mock.searchParam.findNotiStatList[idx]));
                }

                if(this.selNotiStatusList.length == 0 ) {
                    this.selNotiStatusList.push(mock.notiStatusList[0]);
                }
                if(isSearch){
                    this.searchNoti();
                }
            },

            searchNotiList(text) {
                this.searchText = text;
                mock.searchParam.searchText = text;
                const srchLayer = document.querySelector('.srch-layer');
                srchLayer.classList.toggle('active');

                this.saveSearchQuery();
                this.loadSearchHistory();

                this.$parent.initNotiSearchList();
            },
            //최근 검색어 목록
            getSearchHistList(){
                if(this.loginInfo != null){
                    var param = {
                        "custId" : this.loginInfo.id,
                    };

                    this.$post('/api/search/searchHistList',param)
                        .then(res => {
                            if(res.status == 'OK'){
                                this.searchHistList = res.searchHistList;
                            }

                        })
                        .catch(error => {console.log(error);}
                        );
                } else {
                    //비 로그인 시 쿠키로 가져오기
                    this.loadSearchHistory();
                }
            },
            //검색어 지우기
            delSearchHist(searchContent) {
                if(this.loginInfo != null){
                    var param = {
                        "custId" : this.loginInfo.id,
                        "searchContent": searchContent
                    };

                    this.$post('/api/search/delSearchHist',param)
                        .then(res => {
                            if(res.code != 'OK'){
                                console.log('최근검색어 삭제 실패');
                            } else {
                                this.getSearchHistList();
                            }

                        })
                        .catch(error => {console.log(error);}
                        );
                }

            },
            searchNoti(){
                mock.searchParam.notiSearchAreaCdList = [];
                mock.searchParam.notiSearchLicnsCdList = [];
                mock.searchParam.findNotiStatList = [];
                mock.searchParam.findRpsntOrgnzList = []

                for(var idx1 in this.selNotiStatusList){
                    if(this.selNotiStatusList[idx1].comnCd == '0'){
                        continue;
                    }
                    mock.searchParam.findNotiStatList.push(this.selNotiStatusList[idx1].comnCd);
                }

                for(var idx2 in this.selAreaCdList){
                    mock.searchParam.notiSearchAreaCdList.push(this.selAreaCdList[idx2].areaCd);
                }

                for(var idx3 in this.selLicnsCodeList){
                    mock.searchParam.notiSearchLicnsCdList.push(this.selLicnsCodeList[idx3].licnsCd);
                }

                for(var idx4 in this.selRpsntOrgnzList){
                    mock.searchParam.findRpsntOrgnzList.push(this.selRpsntOrgnzList[idx4].rpsntOrgnzCd);
                }
                mock.searchParam.findStrtAmt = this.sliderValue[0] != 0 ? this.sliderVals[this.sliderValue[0]] : null;
                mock.searchParam.findEndAmt = this.sliderValue[1] != 29  ? this.sliderVals[this.sliderValue[1]] : null;

                mock.searchParam.findStrtDt = this.$moment(this.startDt).format('YYYY-MM-DD');
                mock.searchParam.findEndDt = this.$moment(this.endDt).format('YYYY-MM-DD');

                this.$parent.initNotiSearchList();

                //창 닫기
                const fltLay = document.querySelector('.fil-layer');
                fltLay.classList.remove('active');
                document.body.style.overflow = '';

            },

            initFilter(){
                switch(this.tabNo){
                    case 0:
                        this.selNotiStatusList = [];
                        this.selNotiStatusList.push(mock.notiStatusList[0]);
                        this.selAreaCdList = [];
                        this.selLicnsCodeList = [];
                        this.sliderValue = [0, 29];
                        this.$refs.slider.setValues();
                        this.selRpsntOrgnzList = [];
                        this.startDt = this.$moment().format('YYYY-MM-DD');
                        this.endDt = this.$moment().add(15, 'days').format('YYYY-MM-DD');
                        this.licnsSearchText = '';
                        break;
                    case 1:
                        this.selNotiStatusList = [];
                        this.selNotiStatusList.push(mock.notiStatusList[0]);
                        break;
                    case 2:
                        this.selAreaCdList = [];
                        break;
                    case 3:
                        this.selLicnsCodeList = [];
                        break;
                    case 4:
                        this.sliderValue = [0, 29];
                        this.$refs.slider.setValues();
                        break;
                    case 5:
                        this.selRpsntOrgnzList = [];
                        break;
                    case 6:
                        this.startDt = this.$moment().format('YYYY-MM-DD');
                        this.endDt = this.$moment().add(15, 'days').format('YYYY-MM-DD');
                        break;
                    default:
                        break;
                }
            },
            clickStatus(item){
                var index = this.selNotiStatusList.findIndex((e) => e == item);

                if(item.comnCd == '0'){
                    this.selNotiStatusList = [];
                    this.selNotiStatusList.push(mock.notiStatusList[0]);
                } else {
                    var allIndex = this.selNotiStatusList.findIndex((e) => e.comnCd == '0');
                    if(allIndex != -1){
                        this.selNotiStatusList.splice(allIndex,1);
                    }

                    if(index == -1) {
                        this.selNotiStatusList.push(item);
                    } else {
                        this.selNotiStatusList.splice(index,1);
                    }



                    if(this.selNotiStatusList.length == 0 ){
                        this.selNotiStatusList.push(mock.notiStatusList[0]);
                    } else if( this.selNotiStatusList.length == this.mock.notiStatusList.length -1){
                        this.selNotiStatusList = [];
                        this.selNotiStatusList.push(mock.notiStatusList[0]);
                    }
                }

            },
            clickDate(lange,kind){
                this.startDt = this.$moment().subtract(lange, kind).format('YYYY-MM-DD');
                this.endDt = this.$moment().add(lange,kind).format('YYYY-MM-DD');
            },
            clickPrvncl(area){
                mock.selPrvncl = area.prvnclCd;

                mock.selPrvnclList =  mock.prvnclList.filter((e) => e.prvnclCd == mock.selPrvncl);

                if (this.selAreaCdList.every(element => element !== area)) {
                    if (this.selAreaCdList.every(element => element.areaLvl !== 1)) {
                        this.selAreaCdList = this.selAreaCdList.filter(
                            element => !(element.prvnclCd === area.prvnclCd && element.areaLvl === 2)
                        );
                        this.selAreaCdList.push(area);
                    } else {
                        this.selAreaCdList = this.selAreaCdList.filter(element => element.areaLvl !== 1);
                        this.selAreaCdList = this.selAreaCdList.filter(
                            element => !(element.prvnclCd === area.prvnclCd && element.areaLvl === 2)
                        );
                        this.selAreaCdList.push(area);
                    }
                }
            },
            clickArea(index){

                if (!this.selAreaCdList.some(element => element === mock.selPrvnclList[index])) {
                    if (mock.selPrvnclList[index].areaLvl === 1) {
                        this.selAreaCdList = this.selAreaCdList.filter((element) => !(element.prvnclCd === mock.selPrvnclList[index].prvnclCd && element.areaLvl === 2));
                        this.selAreaCdList.push(mock.selPrvnclList[index]);
                    } else {
                        this.selAreaCdList = this.selAreaCdList.filter((element) => !(element.prvnclCd === mock.selPrvnclList[index].prvnclCd && element.areaLvl === 1));
                        this.selAreaCdList.push(mock.selPrvnclList[index]);
                    }
                } else {
                    this.selAreaCdList = this.selAreaCdList.filter((element) => element !== mock.selPrvnclList[index]);
                }
            },
            clickLicns(index) {
                const targetElement = this.filtLicnsCodeList[index];
                const foundIndex = this.selLicnsCodeList.findIndex(e => e === targetElement);

                if (foundIndex === -1) {
                    this.selLicnsCodeList.push(targetElement);
                } else {
                    this.selLicnsCodeList.splice(foundIndex, 1);
                }

            },
            clickOrgnz(index) {
                const rpsntOrgnzCd = mock.rpsntOrgnzList[index];

                if (!this.selRpsntOrgnzList.includes(rpsntOrgnzCd)) {
                    this.selRpsntOrgnzList.push(rpsntOrgnzCd);
                } else {
                    const index = this.selRpsntOrgnzList.indexOf(rpsntOrgnzCd);
                    if (index !== -1) {
                        this.selRpsntOrgnzList.splice(index, 1);
                    }
                }

            },
            srchLayerScript() {
                /* 검색창 활성화 스크립트 */
                const srchInput = document.querySelector('.srch--input');
                const srchLayer = document.querySelector('.srch-layer');
                const btnDel = document.querySelectorAll('.srch--btn--del');

                function srchLayerActive() {
                    event.preventDefault();
                    srchLayer.classList.toggle('active');
                }


                srchInput.addEventListener('click', function (event) {
                    srchLayerActive(event);
                    event.stopPropagation();
                });


                document.body.addEventListener('click', function (event) {
                    let target = event.target;
                    if (srchLayer.classList.contains('active')) {
                        if (!srchLayer.contains(target) && !Array.from(btnDel).includes(target)) {
                            srchLayer.classList.remove('active');
                        }
                    }
                });
                /* /검색창 활성화 스크립트 */
            },
            srchFilterScript() {
                /* 모바일 - 주소창 높이 뺀 높이 계산하기*/
                let vh = window.innerHeight * 0.01;
                document.documentElement.style.setProperty("--vh", `${vh}px`);

                const fltMoveBtn = document.querySelectorAll('.srch--move-btn');
                const fltLay = document.querySelector('.fil-layer');
                const fltLayOff =  document.querySelector('.fil-layer--clsbtn');
                const periodDate =  document.querySelector('.period--date');

                function activeClassmulti(ele) { /* active가 여러개일 경우 */
                    ele.classList.toggle('active');
                    var screenWidth = window.innerWidth;
                    if(screenWidth < 800 &&  ele.classList.contains('active')){
                        document.body.style.overflow = 'hidden';
                    } else {
                        document.body.style.overflow = '';
                    }
                }
                function checkScreenSize(){
                    var screenWidth = window.innerWidth;

                    if(screenWidth < 800 &&  fltLay.classList.contains('active')) {
                        document.body.style.overflow = 'hidden';
                    } else {
                        document.body.style.overflow = '';
                    }
                }

                fltMoveBtn.forEach(moveBtn => {
                    moveBtn.addEventListener('click', function () {
                        activeClassmulti(fltLay);
                    });
                });
                fltLayOff.addEventListener('click', function() {
                    event.preventDefault();
                    activeClassmulti(fltLay);
                });
                periodDate.addEventListener('click', function() {
                    activeClassmulti(fltLay);
                });

                /* 로드, 리사이즈시 실행해야할 함수 */
                window.addEventListener("resize", function(){
                    checkScreenSize();
                    // runCardLayoutAnimation(); // 카드 레이아웃 애니메이션 1280px ~ 801px까지 적용시키는 함수.
                });
                window.addEventListener('load', function(){
                    checkScreenSize();
                    // runCardLayoutAnimation(); // 카드 레이아웃 애니메이션 1280px ~ 801px까지 적용시키는 함수.
                });
            },
            btnCancelScript(){
                const btnCancel = document.querySelector('.btn__cancel');

                if(btnCancel) {
                    const searchInput = btnCancel.previousElementSibling; //input
                    /* 검색어창 포커스 on */
                    searchInput.addEventListener('focus', () =>{ // 검색어창 포커스 on
                        searchInput.parentElement.classList.add('active');
                    });

                    /* 검색어창 포커스 off */
                    searchInput.addEventListener('blur', () =>{ // 검색어창 포커스 off
                        searchInput.parentElement.classList.remove('active');
                    });

                    /* 검색어 입력시 취소버튼 나타나기 */
                    searchInput.addEventListener('keyup', () => {  // 검색어 입력시 취소버튼 나타나기
                        const word = searchInput.value.length;
                        if (word > 0) {
                            btnCancel.style.opacity = 1;
                        } else {
                            btnCancel.style.opacity = 0;
                        }
                    });

                    /* 텍스트 초기화 */
                    btnCancel.addEventListener('click', function(){ //텍스트 초기화
                        btnCancel.previousElementSibling.value = '';
                        btnCancel.style.opacity = 0;
                    });

                }

            },



            getSearchFilter() {

                var param = {
                    "custId" : this.loginInfo.id
                };

                this.$post('/api/search/searchFilter',param)
                    .then(res => {
                        var searchFilter = res.searchFilter;

                        if(searchFilter == null || searchFilter.length == 0){
                            this.$parent.initNotiSearchList();
                        } else {
                            if (searchFilter['selectNotiStatus'] != null && searchFilter['selectNotiStatus'] != 'null') {
                                this.selNotiStatusList = [];
                                var findNotiStatList = searchFilter['selectNotiStatus'].replaceAll(' ', '').split(',');
                                for (var statCd of findNotiStatList) {
                                    this.selNotiStatusList.push(mock.notiStatusList.find(element => element['comnCd'] == statCd));
                                }
                            }
                            if (searchFilter['selectAreaCd'] != null && searchFilter['selectAreaCd'] != 'null') {
                                var notiSearchAreaCdList = searchFilter['selectAreaCd'].replaceAll(' ', '').split(',');
                                for (var areaCd of notiSearchAreaCdList) {
                                    this.selAreaCdList.push(mock.prvnclList.find(element => element['areaCd'] == areaCd));
                                }
                            }
                            if (searchFilter['selectLicnsCode'] != null && searchFilter['selectLicnsCode'] != 'null') {
                                var notiSearchLicnsCdList = searchFilter['selectLicnsCode'].replaceAll(' ', '').split(',');
                                for (var licnsCd of notiSearchLicnsCdList) {
                                    this.selLicnsCodeList.push(mock.licnsCodeList.find(element => element['licnsCd'] == licnsCd));
                                }
                            }
                            if (searchFilter['selectStartAmt'] != null && searchFilter['selectStartAmt'] != 'null') {
                                this.sliderValue[0] = this.sliderVals.indexOf(parseInt(searchFilter['selectStartAmt']));
                            }
                            if (searchFilter['selectEndAmt'] != null && searchFilter['selectEndAmt'] != 'null') {
                                this.sliderValue[1] = this.sliderVals.indexOf(parseInt(searchFilter['selectEndAmt']));
                                if(this.sliderValue[1] == 0){
                                    this.sliderValue[1] = 29;
                                }
                            }
                            if (searchFilter['selectRpsntOrgnz'] != null && searchFilter['selectRpsntOrgnz'] != 'null') {
                                var findRpsntOrgnzList = searchFilter['selectRpsntOrgnz'].replaceAll(' ', '').split(',');
                                for (var rpsntOrgnzCd of findRpsntOrgnzList) {
                                    this.selRpsntOrgnzList.push(mock.rpsntOrgnzList.find(element => element['rpsntOrgnzCd'] == rpsntOrgnzCd));
                                }
                            }
                        }



                        this.searchNoti();

                    })
                    .catch(error => {console.log(error);}
                    );

            },
        },

        mounted() {
            this.loginInfo =JSON.parse(localStorage.getItem("custInfo"));

            var filter = this.$cookies.get('saveFilter');

            if(filter == null || filter == 'N'){
                this.saveFilter = false;
            } else {
                this.saveFilter = true;
            }

            // this.getNotiSearchList();
            this.getSearchHistList();

            this.srchLayerScript();

            this.srchFilterScript();

            this.btnCancelScript();

            this.loadSearchHistory();

        },

    }


</script>