<template>
    <div class="blog-view wrap">
        <div class="blog-view--header">
            <p class="txt">{{boardDetail.firstInputDt}}</p>
            <h1 class="h1">{{boardDetail.boardTitle}}</h1>
        </div>
        <div class="blog-view--body" v-html="boardDetail.boardContent">
        </div>
        <div class="layout__detaile-page--list">
            <button type="button" class="btn-basic btn-line-cw blog-view--btn" @click="$router.go(-1)">목록</button>
        </div>
    </div>
</template>

<script>
//    import sanitizeHTML from 'sanitize-html';

    export default {
        name: "TokBlogDetail",
        metaInfo: {
            title: '[비드톡톡 톡블로그 상세] - 전자입찰 나라장터(g2b) 공고 투찰 입찰 낙찰',
            link: [{ rel: "canonical", href: 'https://www.bidtok.co.kr/tokBlog' }],
            meta: [
                { vmid: "description", name: 'description', content: '비드톡톡의 블로그를 확인해 보세요! 전자입찰의 정보, 데이터, 새로운 소식, 나라장터 소식 등 확인하실 수 있습니다.' },
                { vmid: "og:title", property: 'og:title', content: '[비드톡톡 블로그] - 전자입찰 나라장터(g2b) 공고 투찰 입찰 낙찰' },
                { vmid: "og:url", property: 'og:url', content: 'https://www.bidtok.co.kr/' },
                { vmid: "og:description", property: 'og:description', content: '비드톡톡의 블로그를 확인해 보세요! 전자입찰의 정보, 데이터, 새로운 소식, 나라장터 소식 등 확인하실 수 있습니다.' },
                { vmid: "og:image", property: "og:image" , content : "favicon.ico"},
            ]
        },
        data(){
            return{
                blogList : [],
                currentPage: 1,
                itemsPerPage: 10,
                boardDetail: {},
                message: ''
            }
        },

        watch: {
            currentPage() {
                window.scrollTo({ top: 0, behavior: 'smooth' });
            }
        },

        computed: {
            pageNumbers() {
                const maxVisiblePages = window.innerWidth <= 800 ? 5 : 10;
                const firstVisiblePage = Math.max(1, this.currentPage - Math.floor(maxVisiblePages / 2));
                const lastVisiblePage = Math.min(this.totalPages, firstVisiblePage + maxVisiblePages - 1);
                return Array.from({ length: lastVisiblePage - firstVisiblePage + 1 }, (_, i) => firstVisiblePage + i);
            },

            //페이징 시작
            displayedBoardList() {


                //최상단고정 세팅
                // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                const sortedList = this.boardList.sort((a, b) => {
                    if (a.topNoticeYn === 'Y' && b.topNoticeYn !== 'Y') {
                        return -1;
                    } else if (a.topNoticeYn !== 'Y' && b.topNoticeYn === 'Y') {
                        return 1;
                    }
                    return 0;
                });

                const startIndex = (this.currentPage - 1) * this.itemsPerPage;
                const endIndex = startIndex + this.itemsPerPage;
                return sortedList.slice(startIndex, endIndex);

            },

            totalPages() {
                return Math.ceil(this.blogList.length / this.itemsPerPage);
            },

            hasPreviousPage() {
                return this.currentPage > 1;
            },

            hasNextPage() {
                return this.currentPage < this.totalPages;
            },
            //페이징 끝
            // sanitize() {
            //     const sanitized = sanitizeHTML(this.message);
            //     // const sanitized = sanitizeHTML(this.message, options);
            //     console.log(sanitized);
            //     return sanitized;
            // },
        },

        methods: {
            //오늘날짜 확인
            isToday(date) {
                const today = new Date();
                const inputDate = new Date(date);
                return (
                    inputDate.getFullYear() === today.getFullYear() &&
                    inputDate.getMonth() === today.getMonth() &&
                    inputDate.getDate() === today.getDate()
                );
            },
            fnSearchBoard(){
                var params = {
                    "id": this.$route.params.id
                };

                this.$http.post('/api/board/boardDetaillist',params)
                // eslint-disable-next-line no-unused-vars
                        .then(res => {
                            this.boardDetail = res.data.boardDetailList;

                            this.boardDetail.firstInputDt = this.$moment(this.boardDetail.firstInputDt).format('YYYY-MM-DD');
                        })

                        .catch(error => {console.log(error);}
                        );

            },
        },
        created() {

        },

        mounted() {
            this.fnSearchBoard();

            // 페이지네이션 클릭 이벤트
            let pagenation = document.querySelectorAll('.pagination > ul > li');
            pagenation.forEach(function (element) {
                element.addEventListener('click', function(){
                    for (let i = 0; i < pagenation.length; i++) {
                        pagenation[i].classList.remove('active');
                    }
                    element.classList.add('active');
                    window.scroll({top: 0, left: 0,behavior: 'smooth'});
                });
            });

            const headerNoticeElement = document.getElementById("tokStory");
            if (headerNoticeElement) {
                headerNoticeElement.classList.add("active");
            }
        }
    }


</script>



<!--<style src="./TokStory.scss" lang="scss" scoped />-->