<template>
    <div style="text-align: center">
        <a href="https://bidtok.co.kr" class="link__download main__visual--link pc" style="width: 30%; margin: 1.25rem auto;">비드톡톡 바로가기</a>
        <div class="">
            <img src="@/assets/survey/leaflet3.jpg" style="width: 80%; height: auto; margin: 0 auto 2rem;" alt="리플렛">
            <img src="@/assets/survey/leaflet4.jpg" style="width: 80%; height: auto;  margin: 0 auto 2rem;;" alt="리플렛">
        </div>
    </div>
</template>
<script>
    export default {
        name: "g2bNoti",
        metaInfo: {
            title: '[비드톡톡] 조달업체 입찰 참여 및 낙찰 전자입찰 공고 낙찰 - 나라장터(g2b) ',
            link: [{ rel: "canonical", href: 'https://www.bidtok.co.kr/' }],
            meta: [
                { vmid: "description",name: 'description', content: '나라장터(g2b), 조달청, 조달업체, 입찰공고, 시설공고, 전자입찰, 입찰 공고 확인, 입찰 적격심사 점수 확인, 분석가 분석가격 요청, AI 분석, 분석기업, 낙찰, bid 등 입찰에 관한 모든 정보를 비드톡톡에서 확인하시고 낙찰 받으세요.' },
                { vmid: "og:title",property: 'og:title', content: '[비드톡톡] 조달업체 입찰 참여 및 낙찰 전자입찰 공고 낙찰 - 나라장터(g2b) ' },
                { vmid: "og:url",property: 'og:url', content: 'https://www.bidtok.co.kr/' },
                { vmid: "og:description",property: 'og:description', content: '나라장터(g2b), 조달청, 조달업체, 입찰공고, 시설공고, 전자입찰, 입찰 공고 확인, 입찰 적격심사 점수 확인, 분석가 분석가격 요청, AI 분석, 분석기업, 낙찰, bid 등 입찰에 관한 모든 정보를 비드톡톡에서 확인하시고 낙찰 받으세요.' },
                { vmid: "og:image",property: "og:image" , content : "favicon.ico"},
            ]
        },
        data() {
            return {
            }
        },
        methods: {
        }
    };
</script>