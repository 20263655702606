<template>
  <div class="report rep-ent">
    <div class="loading-bar" v-show="isLoading">
      <div class="loading-bar--element"></div>
    </div>
    <!-- 로그인 -->
    <div class="rep-ent__container aft-loin aft-loin__container wrap" style="margin-top:-30px" v-if="Object.keys(loginInfo).length != 0">

      <!-- 상단 타이틀 부분 -->
      <div class="rep-ent__head aft-loin__head">
        <span class="rep-ent__head--title aft-loin__head--title"></span>
        <p class="rep-ent__head--sub aft-loin__head--sub">
          고객님의 지난 투찰 데이터를 분석, 진단하여<span class="line-break">효과적인 결과가 나올 수 있도록 제안해 드립니다.</span><span class="line-break">비드톡톡의 톡톡리포트를 보고 싶다면, 조회 버튼을 클릭하세요.</span>
        </p>
      </div>
      <!-- /상단 타이틀 부분 -->

      <!-- 조회 버튼 박스  -->
      <div class="rep-ent__box inquiry glass">
        <div class="inquiry--input input">
          <span class="inquiry--input--title">사업자 번호</span>
          <input type="text" v-model="bizNo" disabled>
          <a href="#" onclick="return false" class="inquiry--btn btn-basic btn-line-cb" @click="doSearch()">
            <i class="inquiry--btn--img"></i>조회
          </a>
        </div>
        <p class="inquiry--noti">조회기준일 : <span>{{today}} 기준</span></p>
      </div>
      <!-- /조회 버튼 박스  -->
    </div>
    <!-- /로그인 -->

    <!-- 비로그인 -->

    <div class="rep-ent__container bf-lgin bf-lgin__container wrap" style="margin-top:-30px" v-if="Object.keys(loginInfo).length == 0">
      <!-- 상단 타이틀 부분 -->
      <div class="rep-ent__head bf-lgin__head">
        <span class="rep-ent__head--title bf-lgin__head--title"></span>
        <p class="rep-ent__head--sub bf-lgin__head--sub">
          고객님의 지난 투찰 데이터를 분석, 진단하여<span class="line-break">효과적인 결과가 나올 수 있도록 제안해 드립니다.</span><span class="line-break">비드톡톡의 톡톡리포트를 보고 싶다면, 조회 버튼을 클릭하세요.</span>
        </p>
      </div>
      <!-- /상단 타이틀 부분 -->

      <!-- 비로그인시 로그인 박스 -->
      <div class="rep-ent__box non-log-noti glass">
        <div class="non-log-noti--txt">
          <p class="title">로그인 후, 이용 가능한 기능입니다.</p>
          <p class="sub">회원이 아닌 경우, 회원가입 후 로그인해 주세요.</p>
        </div>
        <div class="non-log-noti--button">
          <a href="#" onclick="return false" class="btn-basic btn-line-cb" @click="join()">회원가입</a>
          <a href="#" onclick="return false" class="btn-basic btn-cta" @click="login()">로그인</a>
        </div>
      </div>
      <!-- /비로그인시 로그인 박스 -->

      <!-- 배경 이미지의 텍스트-->
      <!-- <ul class="bf-lgin__list advantage">
          <li class="advantage__item">
              <p class="txt">내 투찰 요약</p>
              <p class="sub">참여했던 투찰 기록을 요약 제공</p>
          </li>
          <li class="advantage__item">
              <p class="txt">내 맞춤 분석가 추천</p>
              <p class="sub">나의 성향에 맞는 AI분석가 추천</p>
          </li>
          <li class="advantage__item">
              <p class="txt">투찰 성향 진단</p>
              <p class="sub">지난 투찰 기록을 통해 내 투찰 성향 진단</p>
          </li>
      </ul> -->
      <!-- /배경 이미지의 텍스트-->
    </div>
    <!-- /비로그인 -->
  </div>
</template>

<script>
    // import * as _ from 'lodash'
    // import $ from 'jquery';

    export default {
        name: "TokReportMain",
        metaInfo: {
            title: '[비드톡톡 톡톡리포트] - 전자입찰 나라장터(g2b) 공고 투찰 낙찰',
            link: [{ rel: "canonical", href: 'https://www.bidtok.co.kr/' }],
            meta: [
                { vmid: "description", name: 'description', content: '비드톡톡에 관한 공지사항을 확인해 보세요! 비드톡톡의 업데이트 소식, 이벤트 소식, 새로운 기능의 소개, 나라장터 소식 등 비드톡톡의 중요한 공지사항을 확인하실 수 있습니다.' },
                { vmid: "og:title", property: 'og:title', content: '[비드톡톡 공지사항] - 전자입찰 나라장터(g2b) 공고 투찰 낙찰' },
                { vmid: "og:url", property: 'og:url', content: 'https://www.bidtok.co.kr/' },
                { vmid: "og:description", property: 'og:description', content: '비드톡톡에 관한 공지사항을 확인해 보세요! 비드톡톡의 업데이트 소식, 이벤트 소식, 새로운 기능의 소개, 나라장터 소식 등 비드톡톡의 중요한 공지사항을 확인하실 수 있습니다.' },
                { vmid: "og:image", property: "og:image" , content : "favicon.ico"},
            ]
        },
        components: {
        },
        data() {
            return {
                bizNo: '',
                loginInfo: null,
                today: this.$moment().format('LL'), //오늘날짜
                isLoading: true
            }
        },
        async created() {
            const urlParams = new URLSearchParams(window.location.search);

            this.authToken = urlParams.get('auth-token');
            if(this.authToken != null){
                localStorage.setItem("authToken",this.authToken);
            }

            const currentPage = this.$route.name;

            if (currentPage === 'TokReportMainApp') {
                // this.setDynamicViewport();
            }
        },
        mounted() {
            this.$get('/api/cust/custInfo/select')
                .then(res => {
                    localStorage.setItem("custInfo",JSON.stringify(res.data));
                    this.loginInfo = JSON.parse(localStorage.getItem("custInfo"));

                    this.custInfo = res.data;
                    this.custBiz = this.custInfo.custBizList.find(item => item.id === this.custInfo.custBizList[0].id,);

                    // if(this.loginInfo.custIdNm.indexOf('000000000') != -1){ // TODO 결제창 적용 시 삭제 (임시)
                    //     this.isTestId = true;
                    // }

                    // 헤더의 상태바 체크
                    // this.$refs.header.chkAISubStatus();

                    if (this.loginInfo != null) {
                        this.bizNo = this.loginInfo.bizNo;
                    }
                })
                .catch(error => {
                    if (error.response.status == 403) {
                        this.loginInfo = {};
                        //localStorage.setItem("custInfo", null);
                    }
                });
            this.isLoading = false;
        },
        methods: {
            doSearch() {
              // this.$goMenu('tokReport');
                this.$router.push('/trp/'+this.bizNo);
            },
          login() {
            // eslint-disable-next-line no-undef
            JavaScriptChannel.postMessage('LOGIN')
          },
          join() {
            // eslint-disable-next-line no-undef
            JavaScriptChannel.postMessage('JOIN')
          },
        }
    }
</script>
<!--<style src="@/assets/scss/common/report_pc.scss" lang="scss" scoped />-->