<template>
  <div class="join gate">
    <div class="gate__wrap join__wrap">
      <h1 class="gate__logo"></h1>
      <p class="gate__title join__txt--title">비드톡톡 앱 다운로드 안내</p>
      <p class="join__txt--nor"><strong>휴대폰 카메라로 아래의 QR코드를 스캔하신 후,</strong><br>앱을 다운로드해 주세요.</p>
      <ul class="join__box">

        <li class="join__box--item ios">
          <p class="gate__item--title"></p>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>

  export default {
    name: 'Join',
  }

</script>
