<template>
    <div class="entire-search">
        <!-- ai 멤버십 배너 -->
        <a href="javascript:void(0);" class="ai-mbr--banner" v-if="loginInfo == null || ($parent.custCntrctList.length > 0 && loginInfo.subStatusCd != '1')" @click="$router.push('/membership');">
            <p class="txt">AI멤버십으로 가볍게 시작하세요!
                <span class="pc">AI멤버십으로 AI분석요청 할 경우 성공보수료 0원!</span>
                <span class="mobile"></span>
            </p>
            <span class="btn btn-cta">AI멤버십 신청하기</span>
        </a>
        <!-- /ai 멤버십 배너 -->
        <!-- 필터 부분-->
        <FilterComp ref="filter"></FilterComp>
        <!-- /필터 부분-->
        <!-- 리스트 부분-->
        <ListComp></ListComp>
        <!-- /리스트 정보-->
        <div class="entire-search--moveTop btn__moveTop" @click="moveTop();">
            <a href="#" onclick="return false"></a>
        </div>
    </div>
</template>

<script>
    import FilterComp from './Component/FilterComp';
    import ListComp from './Component/ListComp';
    import notiSearchData from './NotiSearchData';

    export default {
        name: "NotiSearch",
        metaInfo: {
            title: '[비드톡톡 공고검색] - 전자입찰 나라장터(g2b) 공고 투찰 낙찰',
            link: [{ rel: "canonical", href: 'https://www.bidtok.co.kr/' }],
            meta: [
                { vmid: "description", name: 'description', content: '비드톡톡에 관한 공지사항을 확인해 보세요! 비드톡톡의 업데이트 소식, 이벤트 소식, 새로운 기능의 소개, 나라장터 소식 등 비드톡톡의 중요한 공지사항을 확인하실 수 있습니다.' },
                { vmid: "og:title", property: 'og:title', content: '[비드톡톡 공지사항] - 전자입찰 나라장터(g2b) 공고 투찰 낙찰' },
                { vmid: "og:url", property: 'og:url', content: 'https://www.bidtok.co.kr/' },
                { vmid: "og:description", property: 'og:description', content: '비드톡톡에 관한 공지사항을 확인해 보세요! 비드톡톡의 업데이트 소식, 이벤트 소식, 새로운 기능의 소개, 나라장터 소식 등 비드톡톡의 중요한 공지사항을 확인하실 수 있습니다.' },
                { vmid: "og:image", property: "og:image" , content : "favicon.ico"},
            ]
        },
        components: {
            FilterComp,
            ListComp,
        },
        data(){
            return{
                notiSearchData,
                loginInfo: {},  //로그인 정보

                pageUnit: 30,
                page: 0,
            }
        },
        watch: {
        },
        methods: {
            moveTop() {
                //페이지 이동
                window.scroll({top: 0, left: 0,behavior: 'smooth'});
            },
            getNotiStatCd() {
                var param = {
                    "comnCdGroupId" : "M_NOTI_STAT"
                };

                this.$post('/api/comnCode/list',param)
                    .then(res => {
                        notiSearchData.notiStatusList= res.comnCodeList;
                        this.$refs.filter.selNotiStatusList.push(res.comnCodeList[0]);

                    })
                    .catch(error => {console.log(error);}
                    );

            },
            getRpsntOrgnzCd() {
                var param = {
                    "comnCdGroupId" : "BOARD_RPSNT_ORGNZ_CD"
                };

                this.$post('/api/comn/rpsntOrgnzCdList',param)
                    .then(res => {
                        notiSearchData.rpsntOrgnzList= res;

                    })
                    .catch(error => {console.log(error);}
                    );

            },
            getAreaCodeList() {

                this.$get('/api/noti/areaCodeList')
                    .then(res => {
                        var areaList = res.areaList;

                        notiSearchData.prvnclList = res.areaList;
                        notiSearchData.areaCodeList = areaList.filter((e) => e.areaLvl != 2);
                        notiSearchData.selPrvncl =  notiSearchData.areaCodeList[0].prvnclCd;

                        notiSearchData.selPrvnclList =  notiSearchData.prvnclList.filter((e) => e.prvnclCd == notiSearchData.selPrvncl);

                    })
                    .catch(error => {console.log(error);}
                    );

            },
            getLicnsCodeList() {

                this.$get('/api/noti/licnsList')
                    .then(res => {
                        notiSearchData.licnsCodeList= res.facLicnsList;

                        this.$refs.filter.filtLicnsCodeList = res.facLicnsList;

                    })
                    .catch(error => {console.log(error);}
                    );

            },
            initNotiSearchList() {
                this.page = 0;
                notiSearchData.notiSearchList = [];
                notiSearchData.notiSearchListCnt = 0;

                if(notiSearchData.isSearching == false) {
                    notiSearchData.isSearching = true;
                    this.getNotiSearchList();
                    this.insertSearchHist();
                }
            },
            async insertSearchHist(){
                if (notiSearchData.searchParam.findStrtDt == '') {
                    notiSearchData.searchParam.findStrtDt = this.$moment().format('YYYY-MM-DD');
                }
                if (notiSearchData.searchParam.findEndDt == '') {
                    notiSearchData.searchParam.findEndDt = this.$moment().add(15, 'days').format('YYYY-MM-DD');
                }
                if(this.loginInfo != null){
                    var param = {
                        "loginYn": "Y",
                        "clientIp": await this.$getPublicIP(),
                        "custId": this.loginInfo.id,
                        "searchContent": notiSearchData.searchParam.searchText == "" ? null : notiSearchData.searchParam.searchText,
                        "selectNotiStatus":
                            notiSearchData.searchParam.findNotiStatList.toString().replaceAll('[', '').replaceAll(']', '') == "" ? null : notiSearchData.searchParam.findNotiStatList.toString().replaceAll('[', '').replaceAll(']', ''),
                        "selectLicnsCode": notiSearchData.searchParam.notiSearchLicnsCdList.toString().replaceAll('[', '').replaceAll(']', '') == ""
                            ? null
                            : notiSearchData.searchParam.notiSearchLicnsCdList.toString().replaceAll('[', '').replaceAll(']', ''),
                        "selectAreaCd": notiSearchData.searchParam.notiSearchAreaCdList.toString().replaceAll('[', '').replaceAll(']', '') == ""
                            ? null
                            : notiSearchData.searchParam.notiSearchAreaCdList.toString().replaceAll('[', '').replaceAll(']', ''),
                        "selectStartAmt": notiSearchData.searchParam.findStrtAmt,
                        "selectEndAmt": notiSearchData.searchParam.findEndAmt,
                        "selectStartDate": notiSearchData.searchParam.findStrtDt + ' 00:00:00',
                        "selectEndDate":notiSearchData.searchParam.findEndDt + ' 23:59:59',
                        "selectRpsntOrgnz":
                            notiSearchData.searchParam.findRpsntOrgnzList.toString().replaceAll('[', '').replaceAll(']', '') == "" ? null : notiSearchData.searchParam.findRpsntOrgnzList.toString().replaceAll('[', '').replaceAll(']', ''),
                    };

                    this.$post('/api/search/insertSearchHist',param)
                        .then(res => {
                            if(res.code == 'OK'){
                                this.searchHistList = res.searchHistList;
                                this.$refs.filter.getSearchHistList();
                            }

                        })
                        .catch(error => {console.log(error);}
                        );
                } else { //비 로그인 시 쿠키로 저장
                }

             },
            getNotiSearchList() {
                if (notiSearchData.searchParam.findStrtDt == '') {
                    notiSearchData.searchParam.findStrtDt = this.$moment().format('YYYY-MM-DD');
                }
                if (notiSearchData.searchParam.findEndDt == '') {
                    notiSearchData.searchParam.findEndDt = this.$moment().add(15, 'days').format('YYYY-MM-DD');
                }
                var param = {
                    "pageUnit": this.pageUnit,
                    //페이지당 보여줄 수
                    "pageIndex": this.page,
                    //마지막 index
                    "strtRecordIntex": this.pageUnit * this.page,
                    // 마지막건수
                    "startDt": notiSearchData.searchParam.findStrtDt + ' 00:00:00',
                    "endDt": notiSearchData.searchParam.findEndDt + ' 23:59:59',
                    "notiSearchAreaCdList": notiSearchData.searchParam.notiSearchAreaCdList,
                    "notiSearchLicnsCdList": notiSearchData.searchParam.notiSearchLicnsCdList,
                    "columnNm": "BASE_AMT",
                    // 검색 금액 타입 (base:'BASE_AMT'기초금액/presm:'PRESM_AMT'추정금액)
                    "notiType": "0",
                    // (0:진행정보/1:지난정보/2:개찰정보/3:최종낙찰)
                    "areaSearchType": "OR",
                    // 지역 검색 타입 (OR/AND)
                    "licnsAreaSearchYn": "Y",
                    // 면허/지역내 검색 여부 (Y/N)
                    "notiKindCd": "0",
                    // (0:시설/1:용역/2:물품)
                    "sbidPrargCmpInfoYn": "N",
                    // 낙찰예정업체 정보 조회 여부 (N: 진행정보 페이지/Y:낙찰정보 페이지)
                    "orderBySt": "bddprFinDt",
                    // 정렬 기준(bddprFinDt:투찰마감보기/opbdDt:입찰일시보기/regstFinDt:등록마감보기/firstInputDt:입력일보기)
                    "kword": 'bddprFinDt',
                    "findTxt": notiSearchData.searchParam.searchText,
                    "findStrtAmt": notiSearchData.searchParam.findStrtAmt,
                    "findEndAmt": notiSearchData.searchParam.findEndAmt,
                    "findNotiStatList": notiSearchData.searchParam.findNotiStatList,
                    "findRpsntOrgnzList": notiSearchData.searchParam.findRpsntOrgnzList,
                    "custBizId": '',
                    "custId": this.loginInfo != null ? this.loginInfo.id : null,
                };

                this.$parent.isLoadingBar(true);
                this.$post('/api/noti/notiSearchList', param)
                    .then(res => {
                        var tempNotiSearchList = res.notiSearchList;
                        notiSearchData.notiSearchListCnt = res.notiSearchListCnt;
                        const today = this.$moment();

                        for (var i in tempNotiSearchList) {
                            var regstFinDt = this.$moment(tempNotiSearchList[i].regstFinDt);
                            var bddprFinDt = this.$moment(tempNotiSearchList[i].bddprFinDt);

                            tempNotiSearchList[i].regstFinDtText = regstFinDt.format('MM/DD(ddd) HH:mm');
                            tempNotiSearchList[i].bddprFinDtText = bddprFinDt.format('MM/DD(ddd) HH:mm');

                            //오늘 날짜 여부
                            if (regstFinDt.format('YYYY-MM-DD') == today.format('YYYY-MM-DD') && regstFinDt.isAfter(today)) {
                                tempNotiSearchList[i].isRegDt = true;
                            } else {
                                tempNotiSearchList[i].isRegDt = false;
                            }
                            if (bddprFinDt.format('YYYY-MM-DD') == today.format('YYYY-MM-DD') && bddprFinDt.isAfter(today)) {
                                tempNotiSearchList[i].isBddprDt = true;
                            } else {
                                tempNotiSearchList[i].isBddprDt = false;
                            }

                            notiSearchData.notiSearchList.push(tempNotiSearchList[i]);
                        }
                        notiSearchData.isSearching = false;
                        this.$parent.isLoadingBar(false);
                    })
                    .catch(error => {
                            console.log(error);
                            this.$parent.isLoadingBar(false);
                        }
                    );
                },
                initNotiSearchData() {
                    notiSearchData.searchParam.findNotiStatList = [];
                    notiSearchData.searchParam.notiSearchAreaCdList = [];
                    notiSearchData.searchParam.notiSearchLicnsCdList = [];
                    notiSearchData.searchParam.findStrtAmt = null;
                    notiSearchData.searchParam.findEndAmt = null;
                    notiSearchData.searchParam.findRpsntOrgnzList = [];
                    notiSearchData.searchParam.findStrtDt = '';
                    notiSearchData.searchParam.findEndDt = '';
                }
            },
        async mounted() {
            this.loginInfo = JSON.parse(localStorage.getItem("custInfo"));
            this.initNotiSearchData();

            await this.getNotiStatCd();
            await this.getRpsntOrgnzCd();

            await this.getLicnsCodeList();
            await this.getAreaCodeList();

            const saveFilter = this.$cookies.get('saveFilter');

            if (this.loginInfo != null && saveFilter != null && saveFilter == 'Y') {
                await this.$refs.filter.getSearchFilter();
            } else {

                this.initNotiSearchList();
            }



        }
    }


</script>



<!--<style src="./TokStory.scss" lang="scss" scoped />-->