<template xmlns:div="http://www.w3.org/1999/html">
  <v-container fluid class="notification-page">
    <h1 class="page-title mt-10 mb-9">분석실</h1>
    <v-row class="mb-5">
      <v-card width="100%" class="d-flex flex-row mx-3">
       <v-row no-gutters>
         <v-col cols="12">
           <v-card class="employee-list mb-1">
             <v-card-title class="pa-6 pb-3">
               <p>검색</p>
               <!--              <vue-cropper-->
               <!--                      ref="cropper"-->
               <!--                      :aspect-ratio="2"-->
               <!--                      :src="imgSrc"-->
               <!--                      preview=".preview"-->
               <!--                      alt="Source Image"-->
               <!--              >-->
               <!--              </vue-cropper>-->
               <v-spacer></v-spacer>
               <!--<a
                       href="#"
                       role="button"
                       @click.prevent="cropImage"
               >  Crop
               </a>
               <a
                       href="#"
                       role="button"
                       @click.prevent="ratio('1')"
               >
                 Rotate +90deg
               </a>
               <a
                       href="#"
                       role="button"
                       @click.prevent="ratio('')"
               >
                 free
               </a>-->
               <v-text-field
                       v-model="mock.notiTable.search"
                       append-icon="mdi-magnify"
                       label="Search"
                       clearable
                       single-line
                       hide-details
               ></v-text-field>
               <!--<section class="preview-area">
                 <p>Preview</p>
                 <div class="preview" />
                 <p>Cropped Image</p>
                 <div class="cropped-image">
                   <img
                           v-if="cropImg"
                           :src="cropImg"
                           alt="Cropped Image"
                   />
                   <div v-else class="crop-placeholder" />
                 </div>
               </section>-->
             </v-card-title>
             <v-data-table
                     v-model="mock.notiTable.selected"
                     :headers="mock.notiTable.headers"
                     :items="mock.notiTable.noti"
                     :search="mock.notiTable.search"
                     item-key="name"
                     show-select>
               <template #[`item.rpsntv_nm`]="{ item }">
                 <td v-show="$moment().format('YYYYMMDD') <= $moment(item.bddprFinDt).format('YYYYMMDD')" @click="goSite(item.notiNo)"><v-btn
                         rounded
                         color="primary"
                         dark
                      >
                   투찰
                 </v-btn></td>
               </template>
             </v-data-table>
           </v-card>
         </v-col>
<!--         <v-col lg="4" cols=12>-->
<!--           <v-card-title class="pa-6 pb-3">-->
<!--             <p>분석구간 선택</p>-->
<!--           </v-card-title>-->
<!--           <v-card-text class="pa-6 pt-0">-->
<!--             <p>1부터 6까지 분석구간을 선택하세요</p>-->
<!--             <div class="location-selector">-->
<!--               <div class="bit top left" :class="position === 'top-left' ? 'active centered' : 'centered'" @click="toggleLocation('top-left')">1구간</div>-->
<!--               <div class="bit top right" :class="position === 'top-right' ? 'active centered' : 'centered'" @click="toggleLocation('top-right')">3구간</div>-->
<!--               <div class="bit top center" :class="position === 'top-center' ? 'active centered' : 'centered'" @click="toggleLocation('top-center')">2구간</div>-->
<!--               <div class="bit bottom left" :class="position === 'bottom-left' ? 'active centered' : 'centered'" @click="toggleLocation('bottom-left')">4구간</div>-->
<!--               <div class="bit bottom right" :class="position === 'bottom-right' ? 'active centered' : 'centered'" @click="toggleLocation('bottom-right')">6구간</div>-->
<!--               <div class="bit bottom center" :class="position === 'bottom-center' ? 'active centered' : 'centered'" @click="toggleLocation('bottom-center')">5구간</div>-->
<!--              <div class="text-position" @click="addInfoNotification">분석시작</div>-->
<!--             </div>-->
<!--           </v-card-text>-->
<!--         </v-col>-->
<!--         <v-col lg="4" cols=12>-->
<!--           <v-card-title class="pa-6 pb-3">-->
<!--             <p>...</p>-->
<!--           </v-card-title>-->
<!--           <v-card-text class="pa-6 pt-0">-->
<!--             <p>...</p>-->
<!--             <v-row no-gutters class="action-buttons pb-6">-->
<!--               <v-col cols="12">-->
<!--                 <div class="w-100"></div>-->
<!--                 <v-btn-->
<!--                     block-->
<!--                     @click="addErrorNotification"-->
<!--                     color="error"-->
<!--                     class="text-capitalize my-2"-->
<!--                 >공고맵핑 시작</v-btn>-->
<!--                 <div class="w-100"></div>-->
<!--&lt;!&ndash;                 <v-btn&ndash;&gt;-->
<!--&lt;!&ndash;                     block&ndash;&gt;-->
<!--&lt;!&ndash;                     @click="addSuccessNotification"&ndash;&gt;-->
<!--&lt;!&ndash;                     color="success"&ndash;&gt;-->
<!--&lt;!&ndash;                     class="text-capitalize my-2"&ndash;&gt;-->
<!--&lt;!&ndash;                 >Success Message</v-btn>&ndash;&gt;-->
<!--               </v-col>-->
<!--             </v-row>-->
<!--           </v-card-text>-->
<!--         </v-col>-->
<!--         <v-col lg="4" cols=12>-->
<!--           <v-card-title class="pa-6 pb-3">-->
<!--             <p>Usage</p>-->
<!--           </v-card-title>-->
<!--           <v-card-text class="pa-6 pt-0">-->
<!--             <p>Notifications are created with the help of <a href="https://maronato.github.io/vue-toastification/">vue-toastification</a></p>-->
<!--             <v-row no-gutters class="">-->
<!--               <v-col cols="12">-->
<!--              <pre class='text-caption pa-3' style="background-color: #F3F5FF"><span>// register the plugin on vue</span>-->
<!--<span>import Toast from "vue-toastification";-->
<!--import "vue-toastification/dist/index.css";</span>-->

<!--<span>Vue.use(Toast)</span>-->

<!--<span>// you can call like this in your component</span>-->
<!--<span>this.$toast.show("I'm a toast!", {options})</span></pre>-->
<!--                 <p class="pt-4">For more API information refer to the library documentation</p>-->
<!--               </v-col>-->
<!--             </v-row>-->
<!--           </v-card-text>-->
<!--         </v-col>-->
       </v-row>
      </v-card>
    </v-row>
<!--    <v-row>-->
<!--      <v-col lg=4 md="12" cols=12>-->
<!--        <v-card class="mx-1 mb-1">-->
<!--          <v-card-title class="pa-6 pb-0">-->
<!--            <p>Notification Types Examples</p>-->
<!--          </v-card-title>-->
<!--          <v-card-text class="pa-6 pt-0">-->
<!--            <v-row no-gutters>-->
<!--              <v-col cols="12">-->
<!--                <template v-for="item in notificationsType" >-->
<!--                  <v-chip-->
<!--                    large-->
<!--                    class="d-block mt-4"-->
<!--                    :color="item.color"-->
<!--                    :key="item.text"-->
<!--                  >-->
<!--                    <v-icon left size="28" color="rgba(255,255,255, 0.5)" class="ml-0 mr-2">{{ item.icon }}</v-icon>-->
<!--                    {{  item.text }}-->
<!--                  </v-chip>-->
<!--                </template>-->
<!--              </v-col>-->
<!--            </v-row>-->
<!--          </v-card-text>-->
<!--        </v-card>-->
<!--      </v-col>-->
<!--      <v-col lg=4 md="6" cols=12>-->
<!--        <v-card class="mx-1 mb-1">-->
<!--          <v-card-title class="pa-6 pb-0">-->
<!--            <p>Notification Types Examples</p>-->
<!--          </v-card-title>-->
<!--          <v-card-text class="pa-6 pt-0">-->
<!--            <v-row no-gutters>-->
<!--              <v-col cols="12">-->
<!--                <template v-for="item in notificationsTypeLight" >-->
<!--                  <v-chip-->
<!--                      large-->
<!--                      text-color="black"-->
<!--                      class="d-block mt-4 font-weight-regular"-->
<!--                      :color="item.color"-->
<!--                      :key="item.text"-->
<!--                  >-->
<!--                    <v-icon :color="item.iconColor" left size="28" class="ml-0 mr-2">{{ item.icon }}</v-icon>-->
<!--                    {{  item.text }}-->
<!--                  </v-chip>-->
<!--                </template>-->
<!--              </v-col>-->
<!--            </v-row>-->
<!--          </v-card-text>-->
<!--        </v-card>-->
<!--      </v-col>-->
<!--      <v-col lg=4 md="6" cols=12>-->
<!--        <v-card class="mx-1 mb-1">-->
<!--          <v-card-title class="pa-6 pb-0">-->
<!--            <p>Notification Types Examples</p>-->
<!--          </v-card-title>-->
<!--          <v-card-text class="pa-6 pt-0">-->
<!--            <v-row no-gutters >-->
<!--              <v-col cols="12">-->
<!--                <template v-for="item in notificationsTypeButton" >-->
<!--                  <div :key="item.text" class="mt-4">-->
<!--                  <v-btn-->
<!--                    small-->
<!--                    height="45"-->
<!--                    width="45"-->
<!--                    class="mr-2"-->
<!--                    fab-->
<!--                    elevation="0"-->
<!--                    :color="item.color"-->
<!--                  >-->
<!--                    <v-icon size="28" :color="item.iconColor">{{ item.icon }}</v-icon>-->
<!--                  </v-btn>-->
<!--                  <span>{{ item.text }}</span>-->
<!--                  </div>-->
<!--                </template>-->
<!--              </v-col>-->
<!--            </v-row>-->
<!--          </v-card-text>-->
<!--        </v-card>-->
<!--      </v-col>-->
<!--    </v-row>-->
  </v-container>
</template>

<script>
import mock from './mock';

export default {
  name: 'Notifications',
  data() {
    return {
      mock,
      notificationsType: [
        {
          icon: 'mdi-email',
          text: 'Thanks for Checking out Messenger',
          color: "error"
        },
        {
          icon: 'mdi-message-alert',
          text: 'New user feedback received',
          color: 'primary'
        },
        {
          icon: 'mdi-account-box',
          text: 'New customer is registered',
          color: 'success'
        },
        {
          icon: 'mdi-check',
          text: 'The order was shipped',
          color: 'warning'
        },
        {
          icon: 'mdi-briefcase-variant',
          text: 'The order was deliver',
          color: 'primary'
        },
        {
          icon: 'mdi-information-outline',
          text: '5 Defence alerts',
          color: 'info'
        },
      ],
      notificationsTypeLight: [
        {
          icon: 'mdi-alert-octagon',
          text: 'New report has been received',
          color: '#ff5c9326',
          iconColor: 'error'
        },
        {
          icon: 'mdi-message-alert',
          text: 'New user feedback received',
          color: '#e5e9ff',
          iconColor: 'primary'
        },
        {
          icon: 'mdi-check',
          text: 'The item was shipped',
          color: '#e2f9f1',
          iconColor: 'success'
        },
        {
          icon: 'mdi-email',
          text: 'The new message from user @nahawaii',
          iconColor: 'warning',
          color: '#fff6e7',
        },
        {
          icon: 'mdi-arrow-collapse-up',
          text: 'Your file is ready to upload',
          iconColor: 'primary',
          color: '#e5e9ff',
        },
        {
          icon: 'mdi-disc-alert',
          text: 'The disc is full',
          iconColor: 'info',
          color: '#eedcff',
        },
      ],
      notificationsTypeButton: [
        {
          icon: 'mdi-alert-octagon',
          text: 'New report has been received',
          iconColor: 'error',
          color: '#ff5c9326',
        },
        {
          icon: 'mdi-message-alert',
          text: 'New user feedback received',
          iconColor: 'primary',
          color: '#e5e9ff',
        },
        {
          icon: 'mdi-check',
          text: 'The item was shipped',
          iconColor: 'success',
          color: '#e2f9f1',
        },
        {
          icon: 'mdi-email',
          text: 'The new message from user @nahawaii',
          iconColor: 'warning',
          color: '#fff6e7',
        },
        {
          icon: 'mdi-arrow-collapse-up',
          text: 'Your file is ready to upload',
          iconColor: 'primary',
          color: '#e5e9ff',
        },
        {
          icon: 'mdi-disc-alert',
          text: 'The disc is full',
          iconColor: 'info',
          color: '#eedcff',
        },
      ],
      position: 'top-right'
    }
  },
  mounted() {
    this.getNotiAnlsProgInfoList();
  },
  methods: {
    goSite(notiNo) {
      var url = 'https://www.g2b.go.kr/pt/menu/selectSubFrame.do?framesrc=/pt/menu/frameTgong.do?url=https://www.g2b.go.kr:8101/ep/tbid/tbidList.do?bidSearchType=1&bidno='+notiNo+'&searchType=2';
      window.open(url,"popup", "width=1024, height=600, left=30, top=30, scrollbars=no,titlebar=no,status=no,resizable=no,fullscreen=no");
    },
    getNotiAnlsProgInfoList() {
      var custInfo = JSON.parse(localStorage.getItem("custInfo"));

      var postData = {pageUnit: 9999,
        pageIndex: 0,
        strtRecordIntex: 0,
        searchDate: this.$moment().subtract(1, 'years').format('YYYYMMDD'),
        custBizId: custInfo.custBizList[0].id,
        orderBySt: 'bddprFinDt'};

      const config = {
        headers: {
          'Content-Type': 'application/json',
          'auth-token': localStorage.getItem("authToken"),
          'refresh-token': localStorage.getItem("refreshToken"),
        },
      };

      mock.notiTable.noti = [];

      this.$http.post('/api/noti/notiAnlsProgInfoList',postData, config)
              .then(res => {
                //console.log(res.data);
                var notiList = res.data.notiProgInfoList;

                for (var i in notiList) {
                  var obj = {
                    id: notiList[i].id,
                    notiNo: notiList[i].notiNo,
                    notiNm: notiList[i].notiNm,
                    bddprFinDt: notiList[i].bddprFinDt,
                  }
                  mock.notiTable.noti.push(obj)
                }

              })
              .catch(error => console.log(error));
    },
    addInfoNotification() {
      this.$toast.info("분석중입니다.......", {
        position: this.position,
        timeout: 6000,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: "button",
        icon: true
      });
    },
    addErrorNotification() {
      this.$toast.error("공고확인 중........", {
        position: this.position,
        timeout: 6000,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: "button",
        icon: true
      });
    },
    addSuccessNotification() {
      this.$toast.success("The item was shipped", {
        position: this.position,
        timeout: 6000,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: "button",
        icon: true
      });
    },

    toggleLocation(position) {
      this.position = position;
      this.$toasted.options.position = this.position;
      this.$toasted.show(null);
    },
  }
};

</script>

<style src="./Notifications.scss" lang="scss"></style>
