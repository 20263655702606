<template>
    <div class="analysis-detail__wrap order-veiw">
        <!-- 공고상세 메뉴 이름과 닫기 버튼 -->
        <div class="analysis-detail__container head">
            <p class="analysis-detail__container--item txt--title">참여업체 모두 보기</p>
            <a href="javascript:void(window.close())" class="analysis-detail__container--item btn-basic popup__close"><i
                    class="ic--close"></i></a>
        </div>
        <!-- /공고상세 메뉴 이름과 닫기 버튼 -->

        <!-- 공고상세 내용 -->
        <div class="analysis-detail__container body">


            <!-- 공고 정보와 개찰정보 -->
            <div class="analysis-detail__area content">
                <!-- 참여업체순위 -->
                <div class="analysis-detail__unit cpny-rank">
                    <p class="txt--title">참여업체 순위</p>
                    <div class="analysis-detail__group cpny-rank__top">
                        <div class="analysis-detail__item rank">
                            <span class="rank--txt--total">총 {{bddprPrtcCnt}}업체</span>
                            <span class="rank--txt--my">내 투찰 순위</span>
                            <span class="rank--txt--rank">{{notiBddprPrtcCmp ? notiBddprPrtcCmp.cmpRank : '-'}}위</span>
                            <div class="cpny-rank__btn mobile">
                                <a href="" class="btn-basic btn-line-cw">참여업체 모두 보기</a>
                            </div>
                        </div>
                        <div class="analysis-detail__item search-bar">
                            <div class="select-box">
                                <button class="select-box__sel">업체명</button>
                                <ul class="select-box__list">
                                    <li><button type="button" value="CMP_NM">업체명</button></li>
                                    <li><button type="button" value="RPSNTV_NM">대표자명</button></li>
                                    <li><button type="button" value="BIZ_NO">사업자번호</button></li>
                                </ul>
                            </div>
                            <div class="search">
                                <input type="text" placeholder="참여업체를 검색해 보세요." v-model="searchString" @keyup.enter="searchKeyword()">
                                <button type="reset" class="btn__cancel">취소</button>
                            </div>
                            <button type="button" class="btn-basic btn-line-cw cpny-rank--btn" @click="searchKeyword()">검색</button>
                        </div>
                    </div>
                    <div class="analysis-detail__group cpny-rank__bottom">
                        <div class="grid__wrap analysis-detail__grid analysis-detail__grid--vertical">
                            <div class="pc grid__container">
                                <ul class="grid__group analysis-detail__grid--group head">
                                    <li class="grid__cell analysis-detail__grid--cell my-rank__cell">순위</li>
                                    <li class="grid__cell analysis-detail__grid--cell my-rank__cell">사업자번호</li>
                                    <li class="grid__cell analysis-detail__grid--cell my-rank__cell">업체명</li>
                                    <li class="grid__cell analysis-detail__grid--cell my-rank__cell">대표자명</li>
                                    <li class="grid__cell analysis-detail__grid--cell my-rank__cell">투찰금액</li>
                                    <li class="grid__cell analysis-detail__grid--cell my-rank__cell">편차</li>
                                    <li class="grid__cell analysis-detail__grid--cell my-rank__cell">
                                        예가대비<br>투찰율(%)</li>
                                    <li class="grid__cell analysis-detail__grid--cell my-rank__cell">
                                        기초대비<br>투찰율(%)</li>
                                    <li class="grid__cell analysis-detail__grid--cell my-rank__cell">가격<br>점수</li>
                                    <li class="grid__cell analysis-detail__grid--cell my-rank__cell">추첨 번호</li>
                                    <li class="grid__cell analysis-detail__grid--cell my-rank__cell">비고</li>
                                </ul>
                                <ul class="grid__group analysis-detail__grid--group body"  v-for="items in bddprPrtcCmpList" :key="items.id" :class="{ 'analysis-detail__grid--my-rank':custBiz.bizNo == items.bizNo }">
                                    <li class="grid__cell analysis-detail__grid--cell my-rank__cell">{{items.cmpRank}}</li>
                                    <li class="grid__cell analysis-detail__grid--cell my-rank__cell">
                                        {{items.bizNo | formatbizno}}</li>
                                    <li class="grid__cell analysis-detail__grid--cell my-rank__cell">{{items.cmpNm}}
                                    </li>
                                    <li class="grid__cell analysis-detail__grid--cell my-rank__cell">{{items.rpsntvNm}}</li>
                                    <li class="grid__cell analysis-detail__grid--cell my-rank__cell">
                                        {{items.bddprAmt | currency}}</li>
                                    <li class="grid__cell analysis-detail__grid--cell my-rank__cell">{{items.bddprAmt - items.opbdPrargAmt | currency}}</li>
                                    <li class="grid__cell analysis-detail__grid--cell my-rank__cell">{{items.opbdPrargPricePercent}}
                                    </li>
                                    <li class="grid__cell analysis-detail__grid--cell my-rank__cell">{{items.calBddprRt}}
                                    </li>
                                    <li class="grid__cell analysis-detail__grid--cell my-rank__cell">{{items.priceScore}}
                                    </li>
                                    <li class="grid__cell analysis-detail__grid--cell my-rank__cell">{{items.choicePrdprcNo ? items.choicePrdprcNo : '-'}}</li>
                                    <li class="grid__cell analysis-detail__grid--cell my-rank__cell">{{items.lastSbidYn == 'Y' ? '최종낙찰' : (items.rmark ? items.rmark : '-')}}
                                    </li>
                                </ul>
                            </div>

                            <div class="mobile cpny-rank__slide--unit">
                                <div class="order-veiw__group cpny-rank__slide--box"  v-for="items in bddprPrtcCmpList" :key="items.id" :class="{ 'analysis-detail__grid--my-rank':custBiz.bizNo == items.bizNo }">
                                    <!-- 표 항목 -->
                                    <div class="cpny-rank__slide--menu grid__group head analysis-detail__grid--group">
                                        <p class="grid__cell analysis-detail__grid--cell cpny-rank__grid--cell">순위
                                        </p>
                                        <p class="grid__cell analysis-detail__grid--cell cpny-rank__grid--cell">
                                            사업자번호</p>
                                        <p class="grid__cell analysis-detail__grid--cell cpny-rank__grid--cell">업체명
                                        </p>
                                        <p class="grid__cell analysis-detail__grid--cell cpny-rank__grid--cell">대표자명
                                        </p>
                                        <p class="grid__cell analysis-detail__grid--cell cpny-rank__grid--cell">투찰금액
                                        </p>
                                        <p class="grid__cell analysis-detail__grid--cell cpny-rank__grid--cell">편차
                                        </p>
                                        <p
                                                class="grid__cell analysis-detail__grid--cell cpny-rank__grid--cell analysis-detail__grid--two-line">
                                            예가대비 <span>투찰율(%)</span></p>
                                        <p
                                                class="grid__cell analysis-detail__grid--cell cpny-rank__grid--cell analysis-detail__grid--two-line">
                                            기초대비 <span>투찰율(%)</span></p>
                                        <p class="grid__cell analysis-detail__grid--cell cpny-rank__grid--cell">가격
                                            점수</p>
                                        <p class="grid__cell analysis-detail__grid--cell cpny-rank__grid--cell">추첨
                                            번호</p>
                                        <p class="grid__cell analysis-detail__grid--cell cpny-rank__grid--cell">비고
                                        </p>

                                    </div>
                                    <!-- /표 항목 -->

                                    <!-- 표 내용 -->
                                    <div class="cpny-rank__slide--group grid__group body analysis-detail__grid--group cpny-rank__grid--group">
                                        <p
                                                class="cpny-rank__slide--item grid__cell analysis-detail__grid--cell cpny-rank__grid--cell">
                                            {{items.cmpRank}}</p>
                                        <p
                                                class="cpny-rank__slide--item grid__cell analysis-detail__grid--cell cpny-rank__grid--cell">
                                            {{items.bizNo | formatbizno}}</p>
                                        <p
                                                class="cpny-rank__slide--item grid__cell analysis-detail__grid--cell cpny-rank__grid--cell">
                                            {{items.cmpNm}}</p>
                                        <p
                                                class="cpny-rank__slide--item grid__cell analysis-detail__grid--cell cpny-rank__grid--cell">
                                            {{items.rpsntvNm}}</p>
                                        <p
                                                class="cpny-rank__slide--item grid__cell analysis-detail__grid--cell cpny-rank__grid--cell">
                                            {{items.bddprAmt | currency}}</p>
                                        <p
                                                class="cpny-rank__slide--item grid__cell analysis-detail__grid--cell cpny-rank__grid--cell">
                                            {{items.bddprAmt - notiDetail.opbdPrargAmt | currency}}</p>
                                        <p
                                                class="cpny-rank__slide--item grid__cell analysis-detail__grid--cell cpny-rank__grid--cell analysis-detail__grid--two-line">
                                            {{items.opbdPrargPricePercent}}</p>
                                        <p
                                                class="cpny-rank__slide--item grid__cell analysis-detail__grid--cell cpny-rank__grid--cell analysis-detail__grid--two-line">
                                            {{items.calBddprRt}}</p>
                                        <p
                                                class="cpny-rank__slide--item grid__cell analysis-detail__grid--cell cpny-rank__grid--cell">
                                            {{items.priceScore}}</p>
                                        <p
                                                class="cpny-rank__slide--item grid__cell analysis-detail__grid--cell cpny-rank__grid--cell">
                                            {{items.choicePrdprcNo ? items.choicePrdprcNo : '-'}}</p>
                                        <p
                                                class="cpny-rank__slide--item grid__cell analysis-detail__grid--cell cpny-rank__grid--cell">
                                            {{items.lastSbidYn == 'Y' ? '최종낙찰' : (items.rmark ? items.rmark : '-')}}</p>
                                    </div>
                                    <!-- /표 내용 -->
                                </div>
                            </div>
                        </div>
<!--                        <div class="cpny-rank__btn pc ">-->
<!--                            &lt;!&ndash; 비활성화시 btn__non-active 추가, 활성화시 btn__non-active 삭제&ndash;&gt;-->

<!--                            <a href="#" onclick="return false" class="btn-basic btn-line-cw" :class="bddprPrtcCmpList.length >= bddprPrtcCnt ? 'btn__non-active' : ''" @click="pageIndex++; getNotiBddprPrtcCmpList()">더보기 &#8595;</a>-->
<!--                        </div>-->
<!--                        <div class="order-veiw__btn mobile">-->
<!--                            <a href="#" onclick="return false" class="btn-basic btn-line-cb"  :class="bddprPrtcCmpList.length >= bddprPrtcCnt ? 'btn__non-active' : ''"  @click="pageIndex++; getNotiBddprPrtcCmpList()">20개 더 보기</a>-->
<!--                        </div>-->
                    </div>
                </div>
                <!-- /참여업체순위 -->
            </div>
            <!-- /공고 정보와 개찰정보 -->
        </div>
        <!-- /공고 상세 내용 -->
    </div>
</template>

<script>
    export default {
        name: "notiDetail",
        metaInfo: {
            title: '[비드톡톡 공고상세] - 전자입찰 나라장터(g2b) 공고 투찰 낙찰',
            link: [{ rel: "canonical", href: 'https://www.bidtok.co.kr/' }],
            meta: [
                { vmid: "description", name: 'description', content: '비드톡톡에서 공고 상세를 확인해 보세요. 공고 정보(기본정보, 투찰일정, 추가정보, 기타정보, 첨부파일)와 개찰 정보와 내 투찰 순위 등을 확인할 수 있습니다.' },
                { vmid: "og:title", property: 'og:title', content: '[비드톡톡 공고상세] - 전자입찰 나라장터(g2b) 공고 투찰 낙찰' },
                { vmid: "og:url", property: 'og:url', content: 'https://www.bidtok.co.kr/' },
                { vmid: "og:description", property: 'og:description', content: '비드톡톡에서 공고 상세를 확인해 보세요. 공고 정보(기본정보, 투찰일정, 추가정보, 기타정보, 첨부파일)와 개찰 정보와 내 투찰 순위 등을 확인할 수 있습니다.' },
                { vmid: "og:image", property: "og:image" , content : "favicon.ico"},
            ]
        },
        data(){
            return{
                //참여업체 순위
                bddprPrtcCmpList : [],
                bddprPrtcCnt : 0,

                pageIndex: 0,
                pageUnit: 9999,
                searchType: "CMP_NM",

                //참여업체순위 검색 텍스트
                searchString : "",

                custBiz: {},

                //가격점수
                notiPriceScore : {},

                notiDetail: {},
                notiBddprPrtcCmp: '',
            }
        },

        filters: {
            notidate : function (value) {
                if (!value || value.trim() === '') {
                    return '-';
                }

                var date = new Date(value);

                var days = ['일', '월', '화', '수', '목', '금', '토'];

                var dayOfWeek = days[date.getDay()];

                var month = String(date.getMonth() + 1).padStart(2, '0');
                var day = String(date.getDate()).padStart(2, '0');
                var hour = String(date.getHours()).padStart(2, '0');
                var minute = String(date.getMinutes()).padStart(2, '0');

                var formattedDateTime = `${date.getFullYear()}/${month}/${day} (${dayOfWeek}) ${hour}:${minute}`;

                return formattedDateTime;
            },

            formatbizno: function(value) {
                if (!value) {
                    return '-';
                }

                var formattedBizno = value.replace(/^(\d{3})(\d{2})(\d{5})$/, "$1-$2-$3");
                return formattedBizno;
            }
        },

        methods: {
            //검색
            searchKeyword(){
                this.bddprPrtcCmpList=  [];
                this.bddprPrtcCnt = 0;
                this.pageIndex = 0;
                this.getNotiBddprPrtcCmpList();
            },
            //공고상세 불러오기
            async getNotiDetail() {
                var custInfo = JSON.parse(localStorage.getItem("custInfo"));

                if(custInfo == null) {
                    return;
                }

                var postData = {
                    notiId: this.$route.params.id,
                    custBizId: custInfo.custBizList[0].id,

                };

                const config = {
                    headers: {
                        'Content-Type': 'application/json',
                        'auth-token': localStorage.getItem("authToken"),
                        'refresh-token': localStorage.getItem("refreshToken"),
                    },
                };


                this.$http.post('/api/popup/getNotiAnlsDetail',postData, config)
                    .then(res => {

                        this.notiDetail = res.data.notiDetail;
                        this.notiAtchmnflList = res.data.notiAtchmnflList;
                        this.notiBddprPrtcCmp = res.data.notiBddprPrtcCmp;
                        this.notiCompnoList = res.data.notiCompnoList;

                        this.notiTable.noti.push(res.data.notiDetail);
                        this.notiTable.notiAtchmnflList.push(res.data.notiAtchmnflList);
                        this.notiTable.notiBddprPrtcCmp.push(res.data.notiBddprPrtcCmp);
                        this.notiTable.notiCompnoList.push(res.data.notiCompnoList);

                        //지역
                        var notiArea = this.notiDetail.notiAreaList.map(o => o.areaNm ? o.prvnclNm + o.areaNm : o.prvnclNm);
                        notiArea = Array.from(new Set(notiArea)).join(" | ");
                        this.notiDetail.notiAreaDisp = notiArea;

                        //면허
                        var notiLicns = this.notiDetail.notiLicnsList.map(o => o.licnsNm);
                        notiLicns = Array.from(new Set(notiLicns)).join(" | ");
                        this.notiDetail.notiLicnsDisp = notiLicns;

                        if(this.notiBddprPrtcCmp){
                            //내 투찰순위 추첨번호 쉼표
                            this.notiBddprPrtcCmp.choicePrdprcNo = this.notiBddprPrtcCmp.choicePrdprcNo.replace(/\s{2,}/g, ",");

                            //예가대비 투찰율
                            var stttAmt = 0;
                            if (this.notiBddprPrtcCmp.stttAmtCfmtCd == '1') { // 법정금액적격코드(0:일반/1:적용/2:미적용)
                                stttAmt = Number(this.notiBddprPrtcCmp.stttAmt);
                            }
                            if ((this.notiBddprPrtcCmp.rpsntOrgnzCd == '6' || this.notiBddprPrtcCmp.rpsntOrgnzCd == '23') && this.notiBddprPrtcCmp.stttAmtCfmtCd == '1') { // (도로공사 or 한국국토정보공사) && 법정금액적용(0:일반/1:적용/2:미적용)
                                // 예가대비 투찰율 = (투찰금액 / 개찰예정금액) * 100
                                this.notiBddprPrtcCmp.opbdPrargPricePercent = ((this.removeComma(this.notiBddprPrtcCmp.bddprAmt) / this.removeComma(this.notiBddprPrtcCmp.opbdPrargPrice)) * 100).toFixed(4);
                            } else {
                                // 예가대비 투찰율 = ((투찰금액 - 법정금액합산) / (개찰예정금액 - 법정금액합산)) * 100
                                this.notiBddprPrtcCmp.opbdPrargPricePercent = (((this.removeComma(this.notiBddprPrtcCmp.bddprAmt) - stttAmt) / (this.removeComma(this.notiBddprPrtcCmp.opbdPrargPrice) - stttAmt)) * 100).toFixed(4);
                            }
                            if (this.notiBddprPrtcCmp.opbdPrargPricePercent == "Infinity") {
                                this.notiBddprPrtcCmp.opbdPrargPricePercent = (0).toFixed(4);
                            }

                            //가격점수
                            this.notiBddprPrtcCmp.priceScore = this.calPriceScore((this.notiBddprPrtcCmp.opbdPrargPricePercent * 1).toFixed(2));
                        }

                        //복수 예가
                        if(this.notiCompnoList){
                            this.notiCompnoList = res.data.notiCompnoList.sort((a, b) => a.sortOrdr - b.sortOrdr);
                            this.notiDetail.yegaSum = 0;
                            this.notiDetail.selectedYega = "";

                            //예가 추첨
                            for (let i = 0; i < this.notiCompnoList.length; i++) {
                                const one = this.notiCompnoList[i];
                                one.value = (-100 + one.compnoPrdprcRt).toFixed(4) * 1;
                                this.notiDetail.yegaSum = (this.notiDetail.yegaSum + one.value).toFixed(4) * 1;

                                // 선택된예가
                                if (one.opbdChoiceYn == 'Y') {
                                    this.notiDetail.selectedYega += " " + one.sortOrdr;
                                }
                            }
                            this.notiDetail.selectedYega = this.notiDetail.selectedYega.trim().replace(/ /g, ",");

                            //예가 순위
                            this.notiCompnoListA = this.notiCompnoList.slice(0, 8);
                            this.notiCompnoListB = this.notiCompnoList.slice(8);

                            //빈배열생성(복수예비가 10개나올때도있어서 분기처리)
                            this.notiCompnoListC = Array(8 - this.notiCompnoListB.length).fill([]);
                        }

                    })
                    .catch(error => console.log(error));
            },

            //참여업체순위
            getNotiBddprPrtcCmpList() {

                var postData = {
                    "pageIndex": this.pageIndex,
                    "strtRecordIntex": this.pageIndex * this.pageUnit,
                    "pageUnit": this.pageUnit,
                    "notiId": this.$route.params.id,
                    "searchType": this.searchType,
                    "searchString": this.searchString
                };

                const config = {
                    headers: {
                        'Content-Type': 'application/json',
                        'auth-token': localStorage.getItem("authToken"),
                        'refresh-token': localStorage.getItem("refreshToken"),
                    },
                };



                this.$http.post('/api/noti/notiBddprPrtcCmpList',postData, config)
                    .then(res => {
                        var tempBddprPrtcCmpList = res.data.notiBddprPrtcCmpList;
                        this.bddprPrtcCnt = res.data.notiBddprPrtcCmpCnt;

                        for (var i in tempBddprPrtcCmpList) {
                            //내 투찰순위 추첨번호 쉼표
                            tempBddprPrtcCmpList[i].choicePrdprcNo = tempBddprPrtcCmpList[i].choicePrdprcNo.replace(/\s{2,}/g, ",");

                            //예가대비 투찰율
                            var stttAmt = 0;
                            if (tempBddprPrtcCmpList[i].stttAmtCfmtCd == '1') { // 법정금액적격코드(0:일반/1:적용/2:미적용)
                                stttAmt = Number(tempBddprPrtcCmpList[i].stttAmt);
                            }
                            if ((tempBddprPrtcCmpList[i].rpsntOrgnzCd == '6' || tempBddprPrtcCmpList[i].rpsntOrgnzCd == '23') && tempBddprPrtcCmpList[i].stttAmtCfmtCd == '1') { // (도로공사 or 한국국토정보공사) && 법정금액적용(0:일반/1:적용/2:미적용)
                                // 예가대비 투찰율 = (투찰금액 / 개찰예정금액) * 100
                                tempBddprPrtcCmpList[i].opbdPrargPricePercent = ((this.removeComma(tempBddprPrtcCmpList[i].bddprAmt) / this.removeComma(tempBddprPrtcCmpList[i].opbdPrargPrice)) * 100).toFixed(4);
                            } else {
                                // 예가대비 투찰율 = ((투찰금액 - 법정금액합산) / (개찰예정금액 - 법정금액합산)) * 100
                                tempBddprPrtcCmpList[i].opbdPrargPricePercent = (((this.removeComma(tempBddprPrtcCmpList[i].bddprAmt) - stttAmt) / (this.removeComma(tempBddprPrtcCmpList[i].opbdPrargPrice) - stttAmt)) * 100).toFixed(4);
                            }
                            if (tempBddprPrtcCmpList[i].opbdPrargPricePercent == "Infinity") {
                                tempBddprPrtcCmpList[i].opbdPrargPricePercent = (0).toFixed(4);
                            }

                            //참여업체순위 가격점수
                            tempBddprPrtcCmpList[i].priceScore = this.calPriceScore((tempBddprPrtcCmpList[i].opbdPrargPricePercent * 1).toFixed(2));
                            this.bddprPrtcCmpList.push(tempBddprPrtcCmpList[i]);
                        }
                    })
                    .catch(error => console.log(error));
            },
            //가격점수 세팅
            async getPriceScore(){
                var param = {
                    notiId: this.$route.params.id,
                };

                const config = {
                    headers: {
                        'Content-Type': 'application/json',
                        'auth-token': localStorage.getItem("authToken"),
                        'refresh-token': localStorage.getItem("refreshToken"),
                    },
                };

                await this.$http.post('/api/cfmtUndwrt/notiPriceScore',param, config)
                    .then(res => {
                        this.notiPriceScore = res.data.notiPriceScore;

                    })
                    .catch(error => console.log(error));
            },


            /**
             * 주어진 값에 숫자를 제외한 문자를 제거한다.
             *
             * @param number 숫자
             * @param isPoint 소수점 여부(기본값: false)
             * @param isZero 공백일 경우 0 여부
             * @returns {string} 콤마 제거된 값
             */
            removeComma: function(number, isPoint, isZero) {
                number = String(number);
                if (number === '0') return number;

                number = isPoint ? number.replace(/[^\d]+/g, '') : number.replace(/[^.\d]+/g, '');
                number = number.replace(/(^0+)/, '');
                return !number && isZero ? 0 : number;
            },

            //가격점수 계산
            calPriceScore: function(yegaBddprRt) {
                var a = this.notiPriceScore;

                var priceScore = 0;
                if (yegaBddprRt < 88.25) {
                    priceScore = a.stDistmk - Math.abs(88 - yegaBddprRt) * a.mltplcDistmk;
                } else {
                    priceScore = a.stPriceScore;
                }
                priceScore = (Math.round(priceScore * 100) / 100);
                return priceScore;
            },
            setCustBiz() {
                var custInfo = JSON.parse(localStorage.getItem("custInfo"));

                if (custInfo != null)
                    this.custBiz = custInfo.custBizList[0];
            },
            async initLoad(){
                await this.getPriceScore();
                this.getNotiDetail();
                this.getNotiBddprPrtcCmpList();
            }
        },

        computed: {

        },
        created() {

        },

        mounted() {
            this.searchString = this.$route.query.searchString;
            this.searchType = this.$route.query.searchType;
            this.setCustBiz();
            this.initLoad();

            /* 셀렉트박스 */
            const selectBox = document.querySelector('.select-box__sel');
            if (selectBox) {
                selectBox.addEventListener('click', () => selectBox.classList.toggle('on'));

                /* 셀렉트박스 활성화 중 다른 곳 클릭시 꺼짐 */
                document.addEventListener('click', (event) => {
                    const target = event.target;
                    if (!selectBox.contains(target)) {
                        selectBox.classList.remove('on');
                    }
                });
                /* /셀렉트박스 활성화 중 다른 곳 클릭시 꺼짐 */
            }

            const selectBoxItem = document.querySelector('.select-box__list');
            if (selectBoxItem) {
                selectBoxItem.addEventListener('click', (event) => {
                    selectBox.innerText = event.target.innerText;
                    this.searchType = event.target.value;
                    selectBox.classList.remove('on');
                });
            }
            if( this.searchType == "CMP_NM"){
                selectBox.innerText = '업체명';
            } else if( this.searchType == "RPSNTV_NM"){
                selectBox.innerText = '대표자명';
            } else if( this.searchType == "BIZ_NO") {
                selectBox.innerText = '사업자번호';
            }
        }
    }


</script>
<!--<script src="../../../assets/js/common.js" defer></script>-->


