
////////////////////////////////////////////////////////////////////////////////////////////////////////
                                         /* 새로고침시 스크롤 위치 기억하기 */                                                           
// ////////////////////////////////////////////////////////////////////////////////////////////////////

// 페이지가 로드될 때
window.addEventListener('load', () => {
    // 이전에 저장된 스크롤 위치를 가져옵니다.
    const scrollPosition = localStorage.getItem('scrollPosition');
    
    // 저장된 스크롤 위치가 있다면 해당 위치로 스크롤을 이동합니다.
    if (scrollPosition !== null) {
        window.scrollTo(0, parseInt(scrollPosition));
    }
});

// 페이지를 떠날 때
window.addEventListener('beforeunload', () => {
    // 현재 스크롤 위치를 localStorage에 저장합니다.
    localStorage.setItem('scrollPosition', window.scrollY);
});

////////////////////////////////////////////////////////////////////////////////////////////////////////
                                         /* 해상도 체크 */                                                           
// ////////////////////////////////////////////////////////////////////////////////////////////////////

/* 변수설정 */
let cardLayotAniRun; // 카드 레이아웃 애니메이션 실행 여부 결정
cardLayotAniRun = false; //eslint-disable-line no-unused-vars
/* /변수설정 */

function checkScreenSize(){
    var screenWidth = window.innerWidth;


    if (screenWidth >= 1001){
        if (screenWidth <= 1280) {
            cardLayotAniRun = true;
        }
        toggleHamResponse(); /* 리사이즈 or 리로드시 햄버거 메뉴 열림 > 닫힘 */
        browser(); /* 익스 접속시 엣지 전환*/
    } else if (screenWidth >= 801){ 
        cardLayotAniRun = true;
        browser();
    } else {
        cardLayotAniRun = false;
        browser();
    }
}

/* 로드, 리사이즈시 실행해야할 함수 */
window.addEventListener("resize", function(){
    checkScreenSize();
    // runCardLayoutAnimation(); // 카드 레이아웃 애니메이션 1280px ~ 801px까지 적용시키는 함수.
});
window.addEventListener('load', function(){
    checkScreenSize();
    // runCardLayoutAnimation(); // 카드 레이아웃 애니메이션 1280px ~ 801px까지 적용시키는 함수.
});



////////////////////////////////////////////////////////////////////////////////////////////////////
                                        /* 레이아웃 애니메이션 */
////////////////////////////////////////////////////////////////////////////////////////////////////

//////////////////////////////////////////////////////////////////
                          /* 카드 레이아웃 애니메이션 */                                                           
//////////////////////////////////////////////////////////////////


// 1. 전역변수  cardLayotAniRun가 해상도 1280px ~ 801px true로 변경됨.
// 2. true가 되면 runCardLayoutAnimation()이 실행되어 카드 애니메이션이 실행됨.

var animation = function () {
    var items, parents, winH;

    var initModule = function () {
        items = document.querySelectorAll(".cd-lyot-ani"); // 애니메이션 시킬 모든 아이템
        parents = document.querySelectorAll('.cd-lyot-stnd'); // 모든 부모 요소
        winH = window.innerHeight; // 윈도우 높이
        _addEventHandlers();
    }

    var _addEventHandlers = function () {
        window.addEventListener("scroll", _checkPosition);
        window.addEventListener("load", _checkPosition);
        window.addEventListener("resize", function () {
        winH = window.innerHeight; // 윈도우 높이 업데이트
        initModule(); // 아이템과 부모 요소 다시 설정
        });
    };

    var _checkPosition = function () {
        parents.forEach((parent, index) => {
            if (index >= items.length) return; // 아이템 수 보다 많은 부모 요소를 방지
            var item = items[index]; // 해당 인덱스의 아이템
            var parentRect = parent.getBoundingClientRect();
            var itemRect = item.getBoundingClientRect();

            var parentTop = Math.round(parentRect.top);
            var parentBottom = Math.round(parentRect.bottom);
            var parentH = Math.round(parentRect.height);
            var itemH = Math.round(itemRect.height);
            var reCalWidth = parentRect.width * 0.6 - 15;

            if (parentTop >= 0) {
                item.classList.remove('active');
                item.classList.remove('active02');
            }  else if (parentTop < 0 && parentH > winH) { // 경우1) 도착 && 컨테이너 height > 뷰포트 height
    
                if (parentBottom > 0 && parentBottom >= itemH) {
                    //진입
                    item.classList.add('active');
                    item.classList.remove('active02');
                    items[index].style.width = `${reCalWidth}` + 'px';
    
                } else if (parentBottom > 0 && parentBottom < itemH) {
                    //마지막
                    item.classList.remove('active');
                    item.classList.add('active02')
                }
    
            } else if (parentTop < 0 && parentTop > -parentH && parentH < winH){ // 경우2) 도착 && 컨테이너 height < 뷰포트 height
                item.classList.remove('active');
                item.classList.remove('active02');
                if (parentTop >= -itemH){
                    //진입
                    item.classList.add('active');
                    item.classList.remove('active02');
                } else if (parentTop < -itemH && parentBottom >= itemH + 10 || parentBottom < itemH + 10){
                    //마지막
                    item.classList.remove('active');
                    item.classList.add('active02')
    
                }
            }
        });
    }

    return {
        init: initModule
    }
}



if (document.querySelector(".cd-lyot-ani")) {

    // var runCardLayoutAnimation = function() {
    //     if (cardLayotAniRun) {
    //         animation().init(); // 조건 충족 시 애니메이션 실행
    //     }
    // };

}

// eslint-disable-next-line no-unused-vars
function runCardLayoutAnimation() {
    if (cardLayotAniRun && document.querySelector(".cd-lyot-ani")) {

        animation().init(); // 조건 충족 시 애니메이션 실행
    }
}





////////////////////////////////////////////////////////////////////////////////////////////////////////
                                         /* 익스 접속시 엣지 전환 */                                                           
// ////////////////////////////////////////////////////////////////////////////////////////////////////

function browser(){
    function ieClose(){
        top.window.open('about:blank','_self').close();
        top.window.opener=self;
        top.self.close();
    }

    if(navigator.userAgent.indexOf("Trident") > 0){

        document.querySelector(".body").classList.add("shield");

        alert("비드톡톡은 Microsoft Edge, Chrome 브라우저를 권장합니다.\n확인버튼을 누르면 Edge브라우저로 자동으로 이동됩니다.");

        setTimeout(ieClose, 1000);

        window.location = 'microsoft-edge:' + 'https://www.bidtok.co.kr/'; //http://localhost:1234
    } else if(/MSIE \d |Trident.*rv:/.test(navigator.userAgent)){

        document.querySelector(".body").classList.add("shield");

        setTimeout(ieClose, 1000);

        alert("비드톡톡은 Microsoft Edge, Chrome 브라우저를 권장합니다.\n확인버튼을 누르면 Edge브라우저로 자동으로 이동됩니다.");

        window.location = 'microsoft-edge:http:' + 'http://www.newjini.co.kr/'; //http://localhost:1234
    }
}


////////////////////////////////////////////////////////////////////////////////////////////////////
                                        /* 확대축소 막기, 위로가기, 주소창 제외 높이 계산 */                                                           
////////////////////////////////////////////////////////////////////////////////////////////////////




/* 확대축소 막기 */
document.body.addEventListener('touchstart', function(e) {
if ( (e.touches.length > 1) || e.targetTouches.length > 1) {
    e.preventDefault();
    e.stopPropagation();
    e.stopImmediatePropagation();
}
}, {passive: false});
/* /확대축소 막기 */


/* 위로가기 */
let goTop = document.querySelector(".btn__moveTop > a");
if (goTop) {
    goTop.addEventListener("click", function(){
        window.scroll({top: 0, left: 0,behavior: 'smooth'});
    });
}
/* /위로가기 */

/* 모바일 - 주소창 높이 뺀 높이 계산하기  */
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);
/* /모바일 - 주소창 높이 뺀 높이 계산하기  */


////////////////////////////////////////////////////////////////////////////////////////////////////
                                        /* gnb */                                                           
////////////////////////////////////////////////////////////////////////////////////////////////////

/* pc 메뉴 클릭 이벤트 */
    var menu = document.querySelectorAll('.depth1');
    menu.forEach(function(element){
        element.addEventListener('click', function(){
            for (let i = 0; i < menu.length; i++) {
                menu[i].classList.remove('active');
            }
            element.className += ' active';
        });
    })
/* /pc 메뉴 클릭 이벤트 */


/* 햄메뉴 클릭 이벤트  */
const ham = document.querySelector('.btn__ham');
const hamWrap = document.querySelector('.header__ham--wrap');
const hamBtnTop = document.querySelector('.btn__ham--top');
const hamBtnMiddle = document.querySelector('.btn__ham--middle');
const hamBtnBottom = document.querySelector('.btn__ham--bottom');


if(ham) {
    ham.addEventListener('click', toggleHam);
}

function toggleHam() {
    hamWrap.classList.toggle('on');
    document.body.classList.toggle('expand');
    hamBtnTop.classList.toggle('btn__ham--top-click');
    hamBtnMiddle.classList.toggle('btn__ham--middle-click');
    hamBtnBottom.classList.toggle('btn__ham--bottom-click');
}

/* 해상도-pc 일 때 햄메뉴 끄기  */
// 로드, 리사이즈시 해상도가 pc일 때 checkScreenSize()에서 toggleHamResponse()호출.
function toggleHamResponse() {    
    if (hamWrap && hamWrap.classList.contains('on')) {
        console.log('정상');
        toggleHam();
     
    }
}
/* /해상도-pc 일 때 햄메뉴 끄기  */


let menuItem = document.querySelectorAll('.header__ham--wrap .depth1');

if (menuItem) {
    menuItem.forEach(element => {
        element.addEventListener('click', function(){
            
            for (let i = 0; i < menuItem.length; i++) {
                const element = menuItem[i];
                if (element.classList.contains('active')) {
                    element.classList.remove('active')
                }  
            }
            element.classList.add('active');
        });
       
    });
}
/* /햄메뉴 클릭 이벤트  */


/* /해상도 1000 이상일 때, 햄메뉴 닫힘  */


////////////////////////////////////////////////////////////////////////////////////////////////////
                                        /* 팝업 */                                                           
////////////////////////////////////////////////////////////////////////////////////////////////////


/* 팝업 닫기  */ 
let closeButtons = document.querySelectorAll('.popup__close');
let popActiveCount = document.querySelectorAll('.popup__wrap');
let popCount = closeButtons.length;
let activeCount = 0; //활성화 된 팝업 갯수
let scolYposion = 0;

if(popCount > 0) {
    //활성화된 팝업이 있으면 현재 스크롤 위치를 저장하고, body를 고정시킨다.
    popActiveCount.forEach(function(popup) { 
        if (popup.classList.contains('active')) {
            activeCount++;
        }
    });
    if (activeCount > 0) {
        scolYposion = window.scrollY;
        scrollNon();
        
    }
    // /활성화된 팝업이 있으면 현재 스크롤 위치를 저장하고, body를 고정시킨다.
    
    /* 닫기 버튼 이벤트 */
    closeButtons.forEach(function(element) {
        let parent = element.parentElement;
        element.addEventListener('click', function(){
            while (parent && !parent.classList.contains('popup__wrap')) { // 'popup__wrap' 클래스를 포함한 부모를 찾을 때 까지 따라 올라감
                parent = parent.parentElement;
            }
            if (parent) {
                parent.classList.remove('active');
                scrollActive();
                window.scroll({ top: scolYposion, left: 0 });
            }
        })
    });

   
}


function scrollNon() {
    document.body.style.position = 'fixed';
    document.body.style.top = `-${scolYposion}px`;
    document.body.style.overflow = 'hidden';
    
}
function scrollActive() {
    document.body.style.position = '';
    document.body.style.top = '';
    document.body.style.overflow = '';
}


if(closeButtons.length > 0) {
    popActiveCount.forEach(ele => {
        if(ele.classList.contains('active')) {
            document.querySelector('body').style.overflowY = 'hidden';
        }

    });
 
    // console.log('진입1 ' + popCount);
    // let chekNum = 0;
    // closeButtons.forEach(function(element) {
    //     let parent = element.parentElement;
    //     element.addEventListener('click', function(){
    //         while (parent && !parent.classList.contains('popup__wrap')) { // 'popup__wrap' 클래스를 포함한 부모 요소를 찾을 때까지 부모 요소를 따라 올라감
    //             parent = parent.parentElement;
    //         }
    //         if (parent) {
    //             parent.classList.remove('active');
    //             chekNum++;
    //             console.log('진입2 ' + chekNum);
    //             if (chekNum === popCount) {
    //                 console.log('진입3');
    //                 scrollActive();
    //                 document.querySelector('body').style.overflowY = '';
    //             } 
    //         }
    //     })
    // });
}
/* /팝업 닫기  */ 

/* 메인 최상단 배너 */
let ad = document.querySelector('.ad');
if (ad) {
    let _adHide = function () {
        ad.classList.toggle('active');
    }
    _adHide();
    ham.addEventListener('click', _adHide);
}

/* /메인 최상단 배너 */


////////////////////////////////////////////////////////////////////////////////////////////////////
                                        /* 탭메뉴, 아코디언메뉴, 게시글 필터*/                                                           
////////////////////////////////////////////////////////////////////////////////////////////////////

/* 탭메뉴 */

const tabMenu = document.querySelectorAll('.tab > li');
const tabCont = document.querySelectorAll('.tabcont__row');

if(tabMenu.length) {
    tabMenu.forEach(function(element, index){
        element.addEventListener('click', function(){
            for (let i = 0; i < tabMenu.length; i++) {
                tabMenu[i].classList.remove('active');
                tabCont[i].classList.remove('active');
            }
            tabMenu[index].classList.add('active');
            tabCont[index].classList.add('active');
          
        });
    });
}

/* 탭스타일02 */
const tabMenu02 = document.querySelectorAll('.tab02 > li');
tabMenu02.forEach(function(element, index){
    element.addEventListener('click', function(){
        for (let i = 0; i < tabMenu02.length; i++) {
            tabMenu02[i].classList.remove('active');
        }
        tabMenu02[index].classList.add('active');
    });
});

/* /탭스타일02 */




/* /탭메뉴 */

/* 아코디언 메뉴 - qna 형식 */
const accordionItems = document.querySelectorAll('.accordion__item');

if (accordionItems.length) {
    accordionItems.forEach(item => {
        item.addEventListener('click', function() {
            // 기존에 활성화된 아코디언 닫기
            accordionItems.forEach(accordion => {
                accordion.querySelector('.accordion__head').classList.remove('active');
            });
            // 클릭한 아이템의 헤더에 active 클래스 추가
            const header = item.querySelector('.accordion__head');
            header.classList.add('active');
        });
    });
}

function closeAccordion() {
    accordionItems.forEach(accordion => {
        accordion.querySelector('.accordion__head').classList.remove('active');
    });
}

// 아코디언 이외의 부분을 클릭하면 아코디언 메뉴 닫기
document.addEventListener('click', function(event) {
    if (!event.target.closest('.accordion__item')) {
        if (valueExcept){
            return;
        }
        closeAccordion();
    }
});

/* 아코디언 이외의 부분 클릭 이벤트에서 제외할 대상 */
let exceptItem = ['join-form__wrap'];
let valueExcept = false;

evtExcept();
function evtExcept() {
    
    for (let item of exceptItem) {
        if (document.querySelector('.' + item)) {
            valueExcept = true;
            break; // 존재하는 요소를 찾으면 루프를 종료
        }
    }
}
/* /아코디언 이외의 부분 클릭 이벤트에서 제외할 대상 */
/* /아코디언 메뉴 - qna 형식 */



/*  게시글 필터 */
const filters = document.querySelectorAll('.filter__item'); // 게시글 필터 종류
const items = document.querySelectorAll('.accordion__item'); //게시글 필터 개선 전 > 개선하고 나면 지우기
const filtersCont = document.querySelectorAll('.filter__cont'); // 게시글 필터 내용. 게시글 필터 코드 리팩토링 후. filter__cont로 filter와 내용을 묶어줌.

if (filters.length > 0) {
    filters.forEach(element => element.addEventListener('click', (e) => {
        e.preventDefault();

        // 버튼의 dataset값을 매개변수로 전달
        const filterValue = e.target.dataset.filter;
        arrayItem(filterValue, element);
        
        filters.forEach(f => f.classList.remove('active'));
        element.classList.add('active');
    }));
}

function arrayItem(filterValue) {
    const filterElements = (elements) => {
        elements.forEach(ele => {
            const itemAttr = ele.getAttribute('data-filter');
            if (itemAttr) {
                const itemFilters = itemAttr.split(' '); // 공백을 기준으로 filter를 나눔
                if (itemFilters.includes(filterValue) || filterValue === 'all') {
                    ele.style.display = 'block';
                } else {
                    ele.style.display = 'none';
                }
            }
        });
    };

    filterElements(items); //게시글 필터 개선 전 > 개선하고 나면 지우기
    filterElements(filtersCont);
}


/*  /게시글 필터 */




////////////////////////////////////////////////////////////////////////////////////////////////////
                                        /* input */                                                           
////////////////////////////////////////////////////////////////////////////////////////////////////

/* 포커스 이벤트 */
const inputs = document.querySelectorAll('input--valid');
if(inputs) {
    
    inputs.forEach((element) => {
        /* 입력 6글자 미만시 붉은색 표시*/
        element.addEventListener('keyup', () => {
            const word =  element.value.length;
            console.log(word);
            if (word > 0 && word < 6) {
                element.classList.add('input-invalid');
               
            } else {
                element.classList.remove('input-invalid');
                element.classList.add('input-valid');
                document.querySelector('.input-valid + button').dataset.dateOfValue = 'true';
                document.querySelector('.input-valid + button').classList.add('btn__active');
                document.querySelector('.input-valid + button').classList.remove('btn__non-active');
            }
        });
        /* 포커스 이벤트*/
        element.addEventListener('focus', () => {
            element.style.zIndex = 10;
        });
            /* 포커스 아웃 이벤트*/
        element.addEventListener('blur', () => {
            const word =  element.value.length;
            element.style.zIndex = '';
            if (word > 0 && word < 6) {
                element.classList.add('input-invalid');
            } else {
                element.classList.remove('input-invalid');
                element.classList.remove('input-valid');
            }
        });
    });
} 
/* /포커스 이벤트 */

/* 비밀번호 인풋박스 - 눈모양 클릭시 이미지 변경 */ /* 지우기 */
 
let passwordEye = document.querySelectorAll('.input__combo.password button');
if(passwordEye > 0) {
    
    passwordEye.forEach(element => {
        element.addEventListener('click', function () {
           
            let hidePasswordIcon = element.querySelector('.input__combo--hide-password');
            let showPasswordIcon = element.querySelector('.input__combo--show-password');
    
            // 토글 아이콘의 가시성
            if (hidePasswordIcon.style.display === 'block') {
                hidePasswordIcon.style.display = 'none';
                showPasswordIcon.style.display = 'block';
            } else {
                hidePasswordIcon.style.display = 'block';
                showPasswordIcon.style.display = 'none';
            }
        });
    });
}


/* /비밀번호 인풋박스 - 눈모양 클릭시 이미지 변경 *//* 지우기 */



/* 비밀번호 인풋박스 - 눈모양 클릭시 이미지 변경 */ /* 리팩토링 후 */
let passwordEye02 = document.querySelectorAll('.input__combo.password button');

if(passwordEye02.length > 0) {
    passwordEye02.forEach(element => {
        element.addEventListener('click', function () {
           
            let hidePasswordIcon = element.querySelector('.input__combo--hide-password');
            let showPasswordIcon = element.querySelector('.input__combo--show-password');
    
            // 토글 아이콘의 가시성
            if (hidePasswordIcon.style.display === 'block') {
                hidePasswordIcon.style.display = 'none';
                showPasswordIcon.style.display = 'block';
            } else {
                hidePasswordIcon.style.display = 'block';
                showPasswordIcon.style.display = 'none';
            }
        });
    });
}
/* /비밀번호 인풋박스 - 눈모양 클릭시 이미지 변경 */ /* 리팩토링 후 */


////////////////////////////////////////////////////////////////////////////////////////////////////
                                        /* 셀렉트박스 */                                                           
////////////////////////////////////////////////////////////////////////////////////////////////////
    
    /* 셀렉트박스 */
 
    const selectBoxes = document.querySelectorAll('.select-box__sel');

    selectBoxes.forEach(selectBox => {
        const selBoxParent = selectBox.parentElement;
        const selBoxDisable = selBoxParent.classList.contains('disable');
        
        if (!selBoxDisable) {
            /* 선택창 켜짐 */
            selectBox.addEventListener('click', () => {
                selectBox.classList.toggle('on');
            });
            /* /선택창 켜짐 */
    
            /* 셀렉트박스 활성화 중 다른 곳 클릭시 꺼짐 */
            document.addEventListener('click', (event) => {
                const target = event.target;
                if (!selectBox.contains(target)) {
                    selectBox.classList.remove('on');
                }
            });
            /* /셀렉트박스 활성화 중 다른 곳 클릭시 꺼짐 */
    
            const selectBoxItems = selectBox.closest('.select-box').querySelectorAll('.select-box__list');
    
            if (selectBoxItems.length > 0) {
                selectBoxItems.forEach(selectBoxItem => {
                    selectBoxItem.addEventListener('click', (event) => {
                        const selectedText = event.target.innerText;
                        selectBox.innerText = selectedText;
                    });
                });
            }
        }
    });
    

    /* /셀렉트박스 */

    /* 태그 select disable시 border 제거 */

    let selDisItems = document.querySelectorAll('select');

    // 클래스 변경을 감지하는 MutationObserver 생성
// eslint-disable-next-line no-unused-vars
    const observer02 = new MutationObserver((mutationsList, observer) => {
        for(let mutation of mutationsList) {
            // 'disabled' 속성 변경이 발생한 경우만 처리
            if (mutation.type === 'attributes' && mutation.attributeName === 'disabled') {
                if (mutation.target.tagName.toLowerCase() === 'select') {
                    let parent = mutation.target.parentElement;
    
                    if (mutation.target.disabled) {
                        parent.classList.add('disable'); 
                    } else {
                        parent.classList.remove('disable'); 
                    }
                }
            }
        }
    });
    
    // 각 select 요소에 대해 observer를 연결
    selDisItems.forEach(function(select) {
        observer02.observe(select, { attributes: true });
    });
    





    /* /태그 select disable시 border 제거 */

    


////////////////////////////////////////////////////////////////////////////////////////////////////
                                        /* 검색창 취소버튼 */                                                           
////////////////////////////////////////////////////////////////////////////////////////////////////

const btnCancel = document.querySelector('.btn__cancel');

if(btnCancel) {
    const searchInput = btnCancel.previousElementSibling; //input
    /* 검색어창 포커스 on */
    searchInput.addEventListener('focus', () =>{ // 검색어창 포커스 on
        searchInput.parentElement.classList.add('active');
    });

    /* 검색어창 포커스 off */
    searchInput.addEventListener('blur', () =>{ // 검색어창 포커스 off
        searchInput.parentElement.classList.remove('active');
    });

    /* 검색어 입력시 취소버튼 나타나기 */
    searchInput.addEventListener('keyup', () => {  // 검색어 입력시 취소버튼 나타나기
        const word = searchInput.value.length;
        if (word > 0) {
            btnCancel.style.opacity = 1;
        } else {
            btnCancel.style.opacity = 0;
        }
    });

    /* 텍스트 초기화 */
    btnCancel.addEventListener('click', function(){ //텍스트 초기화
        btnCancel.previousElementSibling.value = '';
        btnCancel.style.opacity = 0;
    });
    
}


////////////////////////////////////////////////////////////////////////////////////////////////////
                                        /* 페이지네이션 */                                                           
////////////////////////////////////////////////////////////////////////////////////////////////////


let pagenation = document.querySelectorAll('.pagination > ul > li');
if (pagenation) {
    pagenation.forEach(function (element) {
        element.addEventListener('click', function(){
            for (let i = 0; i < pagenation.length; i++) {
                pagenation[i].classList.remove('active');
            }
            element.classList.add('active');
            window.scroll({top: 0, left: 0,behavior: 'smooth'});
        });
    });
}




////////////////////////////////////////////////////////////////////////////////////////////////////
                                        /* 로그인/ 아이디 찾기, 비밀번호 재설정 */
////////////////////////////////////////////////////////////////////////////////////////////////////
if (document.querySelector('.login')) {
    document.querySelector('body').style.backgroundColor = '#f5f6fa';
}

////////////////////////////////////////////////////////////////////////////////////////////////////
                                        /* analysisV2 */
////////////////////////////////////////////////////////////////////////////////////////////////////

/* 분석제시가를 확인 후, 투찰완료 버튼을 클릭하면 투찰하러 가기 버튼이 비활성화 되는 스크립트*/

//1. 분석제시가확인 버튼 클릭 > 가격열람 여부 함수 호출
const costCheck = document.querySelectorAll('.announce__analysis--button');

costCheck.forEach(element => {
    element.addEventListener('click', () => chekPrice(element));
});

//2. 투찰완료 버튼 클릭 > 가격열람 여부 함수 호출
const bIdChekBoxs = document.querySelectorAll('.announce__item--bid-chek input[type=checkbox]');

bIdChekBoxs.forEach(element => {
    element.addEventListener('click', function() {

        // 투찰하러가기 버튼 검색한 후
        const closestTestElement = findClosestSibling(element, '.announce__analysis'); 
        // 가격열람 여부 함수 호출
        chekPrice(closestTestElement);

    });    
});

//3. 가격열람 여부 함수 
function chekPrice(ele) {
    const checkValue = ele.querySelector('input[type=radio]:checked'); 

    if (checkValue) { 
        const check = checkValue.value;
        if (check === 'on') {
            bidChekBtnActive(ele);
        } else {
            return null;
        }
    } else {
        bidChekBtnNon(event);
        // bidChekBtnNon(); 
    }
}


//4. 클릭 이벤트가 일어난 투찰완료 버튼과 같은 부모 아래에 있는 투찰하러가기 버튼을 검색하는 함수
function findClosestSibling(element, siblingSelector) {
    let sibling = element.parentElement.nextElementSibling;

    while (sibling) {
        if (sibling.matches(siblingSelector)) {
            return sibling;
        }
        sibling = sibling.nextElementSibling;
    }
    return null;
}


//5. 활성화
function bidChekBtnActive(priceBtn) {
        
    const bIdChekBoxs = document.querySelectorAll('.announce__item--bid-chek input[type=checkbox]');

    bIdChekBoxs.forEach(element => {        
        element.addEventListener('click', function() {

            const activBtns = element.parentElement.querySelectorAll('label');
            const isChecked = element.checked;

            if (isChecked) {
                activBtns.forEach(ele => {
                    ele.classList.add('btn-active');
                });
                priceBtn.querySelector('.announce__analysis--go-order').classList.add('btn__non-active');

                } else {
                activBtns.forEach(ele => {
                    ele.classList.remove('btn-active');
                });

                 priceBtn.querySelector('.announce__analysis--go-order').classList.remove('btn__non-active');
            }

        });
    });
}

//6. 비활성화
function  bidChekBtnNon(event) {
    const activBtns =  event.target.parentElement.querySelectorAll('label');
    activBtns.forEach(element => {
        element.classList.remove('btn-active');
    });
}

////////////////////////////////////////////////////////////////////////////////////////////////////
                                        /* 회원가입 양식 */
////////////////////////////////////////////////////////////////////////////////////////////////////


/* background 색상 변경 */
if (document.querySelector('.join-form__wrap') && window.innerWidth > 800 ) {
    document.querySelector('body').style.backgroundColor = '#f5f6fa';
}
/* /background 색상 변경 */


/* 약관 동의 체크박스 이벤트 */
let linkTarget =  document.querySelectorAll('.join-form__wrap--move');

if (linkTarget) {
    linkTarget.forEach(element => {
        element.addEventListener('click', function () {
        
        // 약관동의 체크박스 
            let nonCheck = element.querySelector('.check-none');
            let activeCheck = element.querySelector('.check-active');

            if (nonCheck) {
            if (nonCheck.style.display === 'inline-block') {
                nonCheck.style.display = 'none';
                activeCheck.style.display = 'inline-block';
            } else {
                nonCheck.style.display = 'inline-block';
                activeCheck.style.display = 'none';
            }
            }

            // 광고성 정보 수신동의 더보기 버튼
            let viewMore = document.querySelector('.ad-accept');
            if (element.classList.contains('adver')) {
            if (window.getComputedStyle(viewMore).display === 'block') {
                viewMore.style.display = 'none';
            }else {
                viewMore.style.display = 'block';
            }
            } 
            
        });
    });
}
/* /약관 동의 체크박스 이벤트 */

/* 스크롤 포커스 이벤트 */
let scrollFocusTrigger = document.querySelector('.join-form__wrap .refer-character'); // 이벤트 트리거 
let scrollFocusTarget = document.querySelector('.set-password'); // 포커스 대상

if(scrollFocusTarget) {
    scrollFocusTrigger.addEventListener('click', joinScollFocus);
}

function joinScollFocus() {

    let scrlFcsEle = document.querySelector('.join-form__wrap--input.password input');
    scrlFcsEle.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});

    scrollFocusTarget.classList.add('input-valid');
}

/* /스크롤 포커스 이벤트 */

////////////////////////////////////////////////////////////////////////////////////////////////////
                                        /* 툴팁 */
////////////////////////////////////////////////////////////////////////////////////////////////////

//툴팁 수정 (before after 선택자에 내용 x)
let toolTip01 = document.querySelectorAll('.tooltip');
if (toolTip01.length > 0) {
    toolTip01.forEach(element => {
        element.addEventListener('click', function(event) {
            toolTipClass01(event.target);
            event.stopPropagation(); // 이벤트 전파 중단
        });    
    });
}
function toolTipClass01(target){
    let parent = target.parentElement;
    while(parent) {
        if (parent && parent.classList.contains('tooltip')) {
            parent.classList.toggle('active');
            break;
        }
        parent = parent.parentElement; // 부모 요소 업데이트
    }
}

document.addEventListener('click', function(event){
    const clickedElement = event.target;
    if (!clickedElement.classList.contains('tooltip__box')) {
        toolTip01.forEach(element => {
            element.classList.remove('active');
        });
    }
});


//툴팁 (before after 선택자에 내용)
let toolTip = document.querySelectorAll('.tooltip__group');
if (toolTip.length > 0) {
    toolTip.forEach(element => {
        element.addEventListener('click', function(event) {
            toolTipClass(event.target);
            event.stopPropagation(); // 이벤트 전파 중단
        });    
    });
}

function toolTipClass(target){
    let parent = target.parentElement;
    while(parent) {
        if (parent && parent.classList.contains('tooltip__group')) {
            parent.classList.toggle('active');
            break;
        }
        parent = parent.parentElement; // 부모 요소 업데이트
    }
}

document.addEventListener('click', function(event){
    const clickedElement = event.target;
    if (!clickedElement.classList.contains('tooltip__box')) {
        toolTip.forEach(element => {
            element.classList.remove('active');
        });
    }
});



////////////////////////////////////////////////////////////////////////////////////////////////////
                                        /* 제작 슬라이드 */
////////////////////////////////////////////////////////////////////////////////////////////////////

/* 
  아이템의 갯수에 따라 넘길 수 있는 횟수를 지정해주고 ( = count 변수)
  count 변수에 따라 움직이게 한다.
*/


let slideContainers = document.querySelectorAll('.tok-slide__wrap');


slideContainers.forEach(container => {
    let slideCont = container.querySelector('.tok-slide--list');
    let slideItems = container.querySelectorAll('.tok-slide__item');
    
    let nextBtn = container.querySelector('.next');
    let preBtn = container.querySelector('.pre');
    let currentPosition = 0;
    let count = slideItems.length - 4; 
    
    if(slideItems.length > 0){
        /* 페이지 넘김 버튼 클릭시 움직임 */
        nextBtn.addEventListener('click', function(){
            if (count > 0 ) {
                currentPosition -= 260;
                slideCont.style.transform = `translateX(${currentPosition}px)`;
                count -= 1;
                console.log(count + 'next count');
           
            } else {
                return;
            }
        })
        
        preBtn.addEventListener('click', function(){
            if (currentPosition < 0 && count < slideItems.length - 4) {
                currentPosition += 260;
                slideCont.style.transform = `translateX(${currentPosition}px)`;
                count += 1;
                console.log(count + 'pre count');
            }
        })
        /* /페이지 넘김 버튼 클릭시 움직임 */

        /* 슬라이드 아이템에 count 변수 설정하기 */
        slideItems.forEach((element, index) => {
            let temp = Math.floor(slideItems.length / 3);  
            for (let i = 1; i <=  temp;  i++) {

            if (index < 3 * i) {
                count = i;
                break;
                }
            }
        });
        /* /슬라이드 아이템에 count 변수 설정하기 */

    }

});




////////////////////////////////////////////////////////////////////////////////////////////////////
                                        /* 사용 가이드 */
////////////////////////////////////////////////////////////////////////////////////////////////////

/* 해상도 1000 이상일 때 231213 추가사항 사용가이드 팝업 (MO 버전) 닫기  */

if(document.querySelector('.guide--mo')) {
    if (screen.width > 1000){
        if (document.querySelector('.guide--mo').classList.contains('on')) {
            (document.querySelector('.guide--mo').classList.remove('on'));
            document.body.style.overflowY = 'auto';
            document.body.style.height = 'auto';
        }
    }
}

/* 231213 추가사항 사용가이드 팝업 탭화면 (PC버전) */
if(document.getElementsByClassName("tab__box--cnts")) {
    openTab();
}

function openTab(evt, tabName) {
    var i, tabcnts, tabbtns, tab;
    tabcnts = document.getElementsByClassName("tab__box--cnts");
    for (i = 0; i < tabcnts.length; i++) {
        tabcnts[i].style.display = "none";
    }
    tabbtns = document.getElementsByClassName("tab--btn");
    for (i = 0; i < tabbtns.length; i++) {
        tabbtns[i].className = tabbtns[i].className.replace(" btn-cta", "");
    }
    tab = document.getElementById(tabName);
    if (tab) {
        document.getElementById(tabName).style.display = "block";
    }
   
    //  evt.currentTarget.className += " btn-cta";
} 

////////////////////////////////////////////////////////////////////////////////////////////////////
                                        /* 공고 카드  */
////////////////////////////////////////////////////////////////////////////////////////////////////


/* 분석제시가 확인 버튼  */

    let viewPrice = document.querySelectorAll('.announce--price');
    viewPrice.forEach(element => {
        element.addEventListener('click', function(){
            element.classList.add('active');
        });
    });
/* /분석제시가 확인 버튼  */


/* 참가신청 체크 버튼 */

    let applicationImte = document.querySelectorAll('.application-check');
    applicationImte.forEach(element => {
        element.addEventListener('click', function(){
            element.classList.toggle('on');
        });    
    });
/* /참가신청 체크 버튼 */


////////////////////////////////////////////////////////////////////////////////////////////////////
                                        /* 클래스  active */
////////////////////////////////////////////////////////////////////////////////////////////////////


// function activeClass(ele) { /* active가 여러개일 경우 */
//     ele.classList.toggle('active');
// }


// function activeClassmulti(ele) { /* active가 하나일 경우 */
//     const siblings = ele.parentElement.children;
//     for (let i = 0; i < siblings.length; i++) {
//         const child = siblings[i];
//         child.classList.remove('active');   
//     }
//     ele.classList.toggle('active');
// }





////////////////////////////////////////////////////////////////////////////////////////////////////
                                        /* 맞춤공고 관련 팝업 */
////////////////////////////////////////////////////////////////////////////////////////////////////

/* 분석가 다중 선택 이벤트 */ 
// eslint-disable-next-line no-unused-vars
let analiParent = document.querySelectorAll('.analist--btn');
let analist = document.querySelectorAll('.analist--btn input[type="checkbox"]');
analist.forEach(element => {
    element.addEventListener('click', function(event) {
        let parent = event.target.closest('.analist--btn');
        if ( parent.classList.contains('active')) {
            parent.classList.remove('active');
        } else {
            parent.classList.add('active');
        }
    }) 
});
/* /분석가 다중 선택 이벤트 */ 


////////////////////////////////////////////////////////////////////////////////////////////////////
                                        /* /맞춤공고 관련 팝업 */
////////////////////////////////////////////////////////////////////////////////////////////////////