<template>
    <div class="ser-detail wrap helper">
        <h3 class="h3 f-i"><span>톡톡도우미</span></h3>
        <h2 class="h2 f-i">투찰을 좀 더 간편하게 <span>톡톡!</span></h2>
        <h4 class="h4 f-i">PC로 분석실을 열지 않아도 톡톡도우미로 내 투찰 리스트를 확인할 수 있습니다.<span
                class="line-break--01">내 컴퓨터에 톡톡도우미를 설치해 지금 투찰 가능한 공고를 확인하고 편리하게 투찰하세요!</span></h4>
        <div class="ser-detail__container helper__container f-i-d">
            <div class="helper__area img"></div>
            <ul class="helper__area spec">
                <li class="spec__item spec--title">다운로드 안내</li>
                <li class="spec__item">
                    <p class="txt--title">지원운영체제</p>
                    <p class="txt--sub">윈도우 7,8,10,11(32/64bit)</p>
                </li>
                <li class="spec__item">
                    <p class="txt--title">사용환경</p>
                    <p class="txt--sub">pentium4 / 512RAM 이상</p>
                </li>
                <li class="spec__item">
                    <p class="txt--title">파일크기</p>
                    <p class="txt--sub">200MB</p>
                </li>
<!--                <li class="spec__item">-->
<!--                    <p class="txt&#45;&#45;title">등록일</p>-->
<!--                    <p class="txt&#45;&#45;sub">2024년 05월 07일</p>-->
<!--                </li>-->
                <li class="spec__item spec--button"><a href="web/assets/file/BidAsist.exe" download>톡톡도우미 다운로드</a></li>
            </ul>
        </div>
    </div>
</template>

<script>
    export default {
        name: "ServiceDetailHelper",
        data() {
            return {
            };
        },
        methods: {
        },
        created() {

        },
        mounted() {
            const fiItem = document.querySelectorAll('.f-i');
            setTimeout(function () {
                let i = 0;
                function fiItemAddClass() {
                    if (i < fiItem.length) {
                        fiItem[i].classList.add('active');
                        i++;
                        setTimeout(fiItemAddClass, 200);
                    }
                }
                fiItemAddClass();
            }, 1200);

            setTimeout(function () {
                document.querySelector('.f-i-d').classList.add('active');
            }, 2500);
        }
    }


</script>



<!--<style src="@/IntroduceView.scss" lang="scss" scoped />-->