<template>
  <ul>
  </ul>
</template>

<script language="javascript" type="text/javascript" src="<%=requestUrl%>/stdjs/INIStdPay_close.js" charset="UTF-8"></script>
<script>
  export default {
    data() {
      return {
      }
    },
    mounted() {

      let recaptchaScript = document.createElement('script')
      recaptchaScript.setAttribute('src', 'https://stdpay.inicis.com/stdjs/INIStdPay_close.js')
      document.head.appendChild(recaptchaScript);

      alert("결제가 취소되었습니다.");

      window.parent.close();
    },
    methods: {
    }
  }
</script>

<style>

</style>