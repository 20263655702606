<template>
    <div class="login find-id-password">
        <div class="find-id-password__wrap login__wrap">
            <a href="/home" class="link__logo"></a>
            <div class="login__title">
                <p class="menu__title">아이디 찾기/비밀번호 재설정</p>
            </div>
            <ul class="tab login__tab" @click="fnTabEvent">
                <li>
                    <input type="radio" id="tab2-1" name="tab2" checked>
                    <label for="tab2-1">아이디 찾기</label>
                </li>
                <li>
                    <input type="radio" id="tab2-2" name="tab2">
                    <label for="tab2-2">비밀번호 재설정</label>
                </li>
            </ul>
            <div class="tabcont login__tabcont--wrap">
                <div class="tabcont__row login__tabcont--row" style="display: block;">
                    <!-- 아이디 찾기 클래스: find-id -->
                    <!-- 고객 정보  클래스: profile -->

                    <div class="login__tabcont--group find-id" v-show="pageStep == 1">
                        <div class="input login__input">
                            <input type="text" placeholder="대표자명을 입력해 주세요." v-model="userNm">
                        </div>
                        <div class="cerification">
                            <div class="input login__input">
                                <input type="text" placeholder="휴대폰번호를 입력해 주세요." v-mask="['###-###-####','###-####-####']"  v-model="phoneNo">
                            </div>
                            <button type="submit" class="btn-basic btn-line-cw" @click="fnCustCheck(1)" v-bind:disabled="phoneNo.length < 12"><span>인증번호발송</span></button>
                        </div>
                        <div data-v-88f11a80="" class="input login__input"><input data-v-88f11a80="" type="text" placeholder="인증번호를 입력해 주세요." v-model="certNo" v-on:keyup="fnCheckSMS"  v-bind:disabled="timeCounter==0"></div>
                        <p v-if="timeStr != '00:00'">{{timeStr}}</p>
                        <div classsic="login__btn">
                            <button type="submit" class="btn-basic btn-cta" @click="fnFindId" v-bind:disabled="userNm=='' || phoneNo == '' || certNo == '' || !smsValidate"><span>아이디 찾기</span></button>
                        </div>
                    </div>
                    <div class="login__tabcont--group profile" v-show="pageStep == 2">
                        <div class="profile__group">
                            <p class="txt__title">고객님의 정보와 일치하는 아이디입니다.</p>
                            <div class="profile__info--wrap">
                                <span class="txt__sub">아이디</span>
                                <p class="txt__title">{{custIdNm}}</p>
                            </div>
                            <div class="profile__info--wrap">
                                <span class="txt__sub">가입일</span>
                                <p class="txt__title">{{insrdDt}}</p>
                            </div>
                        </div>
                        <a href="javascript:void(0);"  disabled="disabled" class="btn-basic btn-cta profile__btn" @click="$router.push('/login')"><span>로그인</span></a>
                    </div>
                </div>
                <div id="naver_id_login" class="login-bar__sns"></div>
                <div class="tabcont__row login__tabcont--row">
                    <!-- 아이디 찾기 클래스: find-password -->
                    <!-- 아이디 찾기 클래스: reset-password -->
                    <div class="login__tabcont--group find-password" v-show="pageStep==1">
                        <div class="input login__input">
                            <input type="text" placeholder="사업자번호를 입력해 주세요." v-mask="['###-##-#####']" v-model="bizNo">
                        </div>
                        <div class="input login__input">
                            <input type="text" placeholder="대표자명을 입력해 주세요." v-model="userNm">
                        </div>
                        <div class="cerification">
                            <div class="input login__input">
                                <input type="text" placeholder="휴대폰번호를 입력해 주세요." v-mask="['###-###-####','###-####-####']" v-model="phoneNo">
                            </div>
                            <button type="submit" class="btn-basic btn-line-cw" @click="fnCustCheck(2)" v-bind:disabled="phoneNo.length < 12"><span>인증번호발송</span></button>
                        </div>
                        <div data-v-88f11a80="" class="input login__input"><input data-v-88f11a80="" type="text" placeholder="인증번호를 입력해 주세요." v-model="certNo"  v-on:keyup="fnCheckSMS" v-bind:disabled="timeCounter==0"></div>
                        <p v-if="timeStr != '00:00'">{{timeStr}}</p>
                        <div classsic="login__btn">
                            <button data-v-88f11a80="" type="submit" class="btn-basic btn-cta" @click="fnFindPassword" v-bind:disabled="bizNo == '' || userNm=='' || phoneNo == '' || certNo == '' || !smsValidate"><span data-v-88f11a80="">확인</span></button>
                        </div>
                    </div>
                    <div class="login__tabcont--group reset-password" v-show="pageStep==2">
                        <p class="txt__title">재설정하실 비밀번호를 입력해 주세요.</p>
                        <div class="input login__input">
                            <input type="password" class="login__input--change" placeholder="비밀번호(영문소문자, 숫자 포함 6 ~15자)" v-model="custPw">
                        </div>
                        <div class="input login__input">
                            <input type="password" placeholder="비밀번호 확인"  v-model="custPwRepeat">
                        </div>
                        <div classsic="login__btn">
                            <button data-v-88f11a80="" type="submit" class="btn-basic btn-cta" @click="fnUpdatePassword"  v-bind:disabled="custPw == '' || custPwRepeat=='' || custPw != custPwRepeat"><span data-v-88f11a80="">비밀번호 재설정</span></button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- 비밀번호 변경 완료 팝업 -->
        <div class="popup__wrap confim">
            <div class="popup__item--container">
                <div class="popup__item--group">
                    <div class="popup__item--head">
                        <div class="popup__img"></div>
                    </div>
                    <div class="popup__item--body">
                        <p class="txt__sub">비밀번호가 변경되었습니다.</p>
                    </div>
                </div>
                <div class="popup__button--wrap single">
                    <a href="" class="btn-basic btn-cta"  disabled="disabled" @click="$router.push('/login')"><span>로그인 화면 이동</span></a>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
    export default {
        name: "FindId",
        metaInfo: {
            title: '[비드톡톡 아이디 찾기] - 전자입찰 나라장터(g2b) 공고 투찰 낙찰',
            link: [{ rel: "canonical", href: 'https://www.bidtok.co.kr/' }],
            meta: [
                { vmid: "description", name: 'description', content: '비드톡톡 아이디가 기억나지 않으실 때는, 아이디 찾기에서 쉽게 찾아보세요! 이름과 휴대폰번호 입력 인증을 통해 찾으실 수 있습니다.' },
                { vmid: "og:title", property: 'og:title', content: '[비드톡톡 아이디 찾기] - 전자입찰 나라장터(g2b) 공고 투찰 낙찰' },
                { vmid: "og:url", property: 'og:url', content: 'https://www.bidtok.co.kr/' },
                { vmid: "og:description", property: 'og:description', content: '비드톡톡 아이디가 기억나지 않으실 때는, 아이디 찾기에서 쉽게 찾아보세요! 이름과 휴대폰번호 입력 인증을 통해 찾으실 수 있습니다.' },
                { vmid: "og:image", property: "og:image" , content : "favicon.ico"},
            ]
        },
        data(){
            return {
                userNm: '',
                phoneNo: '',
                bizNo: '',
                certNo: '',
                custInfo: {},
                randomString : '',
                smsValidate: false,
                timer: null,
                timeCounter: 0,
                timeStr: "00:00",
                pageStep: 1,
                custIdNm: '',
                insrdDt: '',
                custPw: '',
                custPwRepeat: '',
                custId: ''
            }
        },

        methods: {
            changeHolder() {
                let screenWidth = window.innerWidth;

                if (screenWidth >= 501) {
                    console.log('진입');
                    document.querySelector('.login__input--change').placeholder = '비밀번호(영문소문자, 숫자 포함 6 ~15자)';
                } else if (screenWidth >= 360) {
                    document.querySelector('.login__input--change').placeholder = '영문소문자, 숫자 포함 6 ~15자';
                }
            },
            fnTabEvent(event) {
                console.log(event);
                this.pageStep = 1;
                this.certNo = '';
                this. userNm =  '';
                this.phoneNo =  '';
                this.bizNo =  '';
            },
            fnSendCertNo() {
                const random = (length = 8) => {
                    return Math.random().toString(16).substr(2, length);
                };

                    this.randomString = random(20);
                    this.smsValidate = false;

                    var params = {
                        "cryalTelno": this.phoneNo.replaceAll('-', ''),
                        "fbToken": this.randomString
                        //"fbToken": params["fbToken"].toString() // firebase token
                    };

                    this.$http.post('/api/launcher/extrnl/sendSms', params)
                        .then(res => {
                            var resObj = JSON.parse(JSON.stringify(res.data.status));

                            if (resObj == "OK") {
                                this.timer = this.timerStart();
                            } else {
                                this.$toast.error("일시적 오류로 인증번호발송에 실패하였습니다.\n잠시 후 다시 시도해주세요.");
                            }
                        })
                        .catch(error => {
                                console.log(error);
                                this.$toast.error("일시적 오류로 인증번호발송에 실패하였습니다.\n잠시 후 다시 시도해주세요.");
                            }
                        );

            },
            fnCustCheck(tab){
                var params = {};
                if(tab == 1 ){
                    params = {
                        "custTelno": this.phoneNo,
                        "userNm": this.userNm,
                    };
                } else {
                    params = {
                        "custTelno": this.phoneNo,
                        "userNm": this.userNm,
                        "bizNo" : this.bizNo.replaceAll('-','')
                    };
                }

                this.$http.post('/api/cust/isBizNoExist',params)
                    .then(res => {
                        var resObj = JSON.parse(JSON.stringify(res.data));

                        if(resObj['code'] == "OK" && resObj['isBizNoExist'] == true){
                            this.fnSendCertNo();
                           return true;
                        } else {
                            this.$toast.error("대표자명과 휴대폰번호의 정보가 일치하는 사용자가 없습니다.\n확인 후 다시 입력해 주세요.");
                        }
                    })
                    .catch(error => {
                            console.log(error);
                            return false;
                        }
                    );
            },
            fnCheckSMS() {
                if(this.certNo.length != 6){
                    return;
                }
                var params = {
                    "cryalTelno": this.phoneNo.replaceAll('-',''),
                    "fbToken": this.randomString,
                    "authzNo": this.certNo
                    //"fbToken": params["fbToken"].toString() // firebase token
                };

                this.$http.post('/api/launcher/extrnl/checkSms',params)
                    .then(res => {
                        var resObj = JSON.parse(JSON.stringify(res.data.status));

                        if(resObj == "OK"){
                            this.smsValidate = true;
                            this.timerStop(this.timer);
                            this.timeStr = "00:00";
                            this.timeCounter = 0;
                            document.querySelector('.alert-text').innerHTML = "인증되었습니다";
                            // this.timerStart();
                        }
                    })
                    .catch(error => {
                            console.log(error);
                            document.querySelector('.alert-text').innerHTML = "계정정보를 다시 확인해 주세요.";
                            document.querySelector('.alert-text').classList.add('on');
                        }
                    );

            },
            fnFindId() {
                if (this.userNm.trim() == "") {
                    document.querySelector('.alert-text').innerHTML = "대표자명을 입력해 주세요.";
                    document.querySelector('.alert-text').classList.add('on');
                    return false;
                } else if (this.phoneNo.trim() == "") {
                    document.querySelector('.alert-text').innerHTML = "휴대폰번호를 입력해 주세요.";
                    document.querySelector('.alert-text').classList.add('on');
                } else {
                    var params = {
                        "userNm": this.userNm,
                        "custTelno": this.phoneNo
                        //"fbToken": params["fbToken"].toString() // firebase token
                    };

                    this.$http.post('/api/cust/findId',params)
                        .then(res => {
                            this.custInfo = JSON.parse(JSON.stringify(res.data.custInfo));
                            //this.saveStateToStorage(res.data.authToken);
                            this.existToken = true;
                            // this.custInfo = JSON.parse(localStorage.getItem("custInfo"));
                            if(this.custInfo != null && this.custInfo != "null"){
                                this.pageStep = 2;
                                this.custIdNm =  this.custInfo['custIdNm'];
                                this.insrdDt =  this.$moment(this.custInfo['insrdDt']).format('YYYY-MM-DD');

                                // this.$router.push({name: 'LoginProfile', query : {custIdNm : this.custInfo['custIdNm'], insrdDt : this.$moment(this.custInfo['insrdDt']).format('YYYY-MM-DD') } });
                            }
                        })
                        .catch(error => {
                                console.log(error);
                                document.querySelector('.alert-text').innerHTML = "계정정보를 다시 확인해 주세요.";
                                document.querySelector('.alert-text').classList.add('on');
                            }
                        );
                }
            },
            fnFindPassword(){
                if (this.userNm.trim() == "") {
                    document.querySelector('.alert-text').innerHTML = "대표자명을 입력해 주세요.";
                    document.querySelector('.alert-text').classList.add('on');
                    return false;
                } else if (this.phoneNo.trim() == "") {
                    document.querySelector('.alert-text').innerHTML = "휴대폰번호를 입력해 주세요.";
                    document.querySelector('.alert-text').classList.add('on');
                } else {
                    var params = {
                        "custIdNm": this.bizNo.replaceAll('-',''),
                        "userNm": this.userNm,
                        "custTelno": this.phoneNo
                        //"fbToken": params["fbToken"].toString() // firebase token
                    };

                    this.$http.post('/api/cust/findId',params)
                        .then(res => {
                            this.custInfo = JSON.parse(JSON.stringify(res.data.custInfo));
                            //this.saveStateToStorage(res.data.authToken);
                            this.existToken = true;
                            // this.custInfo = JSON.parse(localStorage.getItem("custInfo"));
                            if(this.custInfo != null && this.custInfo != "null"){
                                this.pageStep = 2;
                                this.custId =  this.custInfo['id'];
                                this.custIdNm =  this.custInfo['custIdNm'];
                                this.insrdDt =  this.$moment(this.custInfo['insrdDt']).format('YYYY-MM-DD');

                                // this.$router.push({name: 'PasswordSet', query : {custIdNm : this.custInfo['custIdNm'], insrdDt : this.$moment(this.custInfo['insrdDt']).format('YYYY-MM-DD') } });
                            }
                        })
                        .catch(error => {
                                console.log(error);
                                document.querySelector('.alert-text').innerHTML = "계정정보를 다시 확인해 주세요.";
                                document.querySelector('.alert-text').classList.add('on');
                            }
                        );
                }
            },
            fnUpdatePassword(){
                var params = {
                    "id": this.custId,
                    "custPw": this.custPw,
                };

                this.$http.post('/api/cust/updatePassword',params)
                    .then(res => {
                        var resObj = JSON.parse(JSON.stringify(res.data.code));
                        //this.saveStateToStorage(res.data.authToken);
                        this.existToken = true;
                        // this.custInfo = JSON.parse(localStorage.getItem("custInfo"));
                        console.log(resObj);
                        if(resObj == "OK"){
                            document.querySelector('.popup__wrap').classList.add('active');
                            console.log('비밀번호 업데이트 완료');
                            // this.$router.push('/login');
                        }
                    })
                    .catch(error => {
                            console.log(error);
                            document.querySelector('.alert-text').innerHTML = "계정정보를 다시 확인해 주세요.";
                            document.querySelector('.alert-text').classList.add('on');
                        }
                    );
            },
            timerStart: function() {
                // 1초에 한번씩 start 호출
                this.timeCounter = 180;
                var interval = setInterval(() => {
                    this.timeCounter--; //1초씩 감소
                    this.timeStr = this.prettyTime();
                    if (this.timeCounter <= 0) this.timerStop(interval);
                }, 1000);
                return interval;
            },
            timerStop: function(timer) {
                clearInterval(timer);
                this.timeCounter = 0;
            },
            prettyTime: function() {
                // 시간 형식으로 변환 리턴
                let time = this.timeCounter / 60;
                let minutes = parseInt(time);
                let secondes = Math.round((time - minutes) * 60);
                return (
                    minutes.toString().padStart(2, "0") +
                    ":" +
                    secondes.toString().padStart(2, "0")
                );
            },
        },
        created() {

        },

        mounted() {
            // 탭에 따른 메뉴 표시
            const tabMenu = document.querySelectorAll('.tab > li');
            const tabCont = document.querySelectorAll('.tabcont__row');

            /* 로드, 리사이즈시 실행해야할 함수 */
            window.addEventListener("resize", function(){
                changeHolder();
            });
            window.addEventListener('load', function(){
                changeHolder();
            });

            tabMenu.forEach(function(element, index){
                element.addEventListener('click', function(){
                    for (let i = 0; i < tabMenu.length; i++) {
                        tabCont[i].style.display = 'none';
                    }
                    tabCont[index].style.display = 'block';
                });
            });
            // /탭에 따른 메뉴 표시

            //문자발송성공시 호출
            if(this.timer != null){
                this.timerStop(this.timer);
                this.timer = null;
            }

            function changeHolder() {
                let screenWidth = window.innerWidth;

                if (screenWidth >= 501) {
                    document.querySelector('.login__input--change').placeholder = '비밀번호(영문소문자, 숫자 포함 6 ~15자)';
                } else if (screenWidth >= 360) {
                    document.querySelector('.login__input--change').placeholder = '영문소문자, 숫자 포함 6 ~15자';
                }
            }
            // this.timer = this.timerStart();
        }
    }


</script>

<!--<style src="./FindId.scss" lang="scss" scoped />-->


