<template>
    <el-main id="contents">
    <el-row :gutter="20">
      <el-col :span="1.5">사업자번호:</el-col>
      <el-col :span="3"><el-input placeholder="사업자번호" v-model="bizNo"></el-input></el-col>
      <el-col :span="3.5">
        <el-date-picker
                v-model="searchParam.strtDate"
                type="date"
                placeholder="시작날짜"
                format="yyyyMMdd"
        >
        </el-date-picker>
      </el-col>
      <el-col :span="3.5">
        <el-date-picker
                v-model="finDate"
                type="date"
                placeholder="종료날짜"
                format="yyyyMMdd"
        >
        </el-date-picker>
      </el-col>
      <el-col :span="3"><el-button type="primary" plain @click="doSearch">검색</el-button></el-col>
      <el-col :span="3"><el-button type="primary" plain @click="doSearch(1)">재검색</el-button></el-col>
      <el-col :span="3"><button class="mb-5 float-right" @click="exportToPDF">PDF 다운로드</button></el-col>
    </el-row>

<div ref="pdfarea">
    <el-row :gutter="20" v-show="this.isComplete">
      <el-col align="left" style="font-size:2.3vh" v-loading="loading" :offset=4.5>

        <pre>
<el-row :gutter="12" class="text_area"><el-col :span="6" :xs="{ span: 12 }">
<vue-typer class="display-3"
           v-loading="loading"
           :text=line1
           :repeat='0'
           initial-action='typing'
           :pre-type-delay='100'
           :type-delay='17'
           :pre-erase-delay='1500'
           :erase-delay='250'
           erase-style='backspace'
           :erase-on-complete='false'
           caret-animation='blink'
           @completed='line1Complete'
></vue-typer>
  </el-col>
</el-row>
<div v-if="isLine2"><span style="font-size:x-large;font-weight:bold;">투찰 현황</span> ({{today}} 기준)

먼저 {{cmpNm}} 대표님께서 최근 1년동안의 투찰 활동을 확인, 파악해보겠습니다.


<div class="text1">{{today}} 기준 최근 1년 동안 참여한 공고는 총</div> <div id="number1" class="number"></div><div class="text3">건이며, 낙찰은 총 </div><div id="cmpRankListCnt" class="number"></div>건이며

<div class="text1">낙찰율 </div><div id="notiSbidRt" class="number"></div><div class="text3">% 이며, 총 낙찰 수주액은</div><div id="totalSBidAmt" class="number"></div>원 입니다.

</div>
        </pre>

      </el-col>
      <!--            <el-col :span="8" align="right">(지난 1년 데이터)</el-col>-->
      <div v-if="isLine2">
      <el-col :span="7" align="center">
        <Bar
                v-loading="loading"
                :chart-options="chartOptions"
                :chart-data="chartData"
                :chart-id="chartId"
                :dataset-id-key="datasetIdKey"
                :plugins="plugins"
                :css-classes="cssClasses"
                :styles="styles"
                :width="width"
                :height="height"
        />
        사정율 3% 자료
      </el-col>
      <el-col :span="1" align="center">
      </el-col>
      <el-col :span="7" align="center">
        <Bar
                v-loading="loading"
                :chart-options="chartOptions"
                :chart-data="chartData2"
                :chart-id="chartId"
                :dataset-id-key="datasetIdKey"
                :plugins="plugins"
                :css-classes="cssClasses"
                :styles="styles"
                :width="width"
                :height="height"
        />
        사정율 2% 자료
      </el-col>
      </div>
    </el-row>
    <el-row :gutter="20" v-show="this.isComplete">
      <el-col align="left" style="font-size:2.3vh" v-loading="loading" :offset=4.5>
        <pre>
<div v-if="isLine2">
<el-row :gutter="12" class="text_area"><el-col :span="6" :xs="{ span: 12 }">
위 그래프 자료는 사정율 100을 기점으로 표현하였으며 97% ~ 103%, 98% ~ 102% 그래프로,

고객님이 지난 1년 동안 참여했던 공고의 출현 예가 비율과 투찰하셨던 포인트 비율을 표시하였습니다.


투찰하셨던 내용을 각 사정률마다 가장 많이 투찰한 포인트를 확인해 본다면 아래와 같습니다.


사정율 97% ~ 103% 자료 기준으로 확인했을 때 고객님의 경우 [{{this.sectionArr[this.topGroup-1]}}]부분에 [{{this.topPercent}}%]로 전체 중 가장 많은 투찰 포인트를

산정했음을 확인할 수 있습니다.

그 중에서도 [{{this.topPoint}}]포인트를 전체 공고 중 약 [{{custBidRt}}%]로 투찰 포인트를 산정하였고,

가장 많이 투찰 포인트로 산정했던 [{{this.topPoint}}]포인트의 실제 출현했던 예가 비율은 [{{this.realOpbdPrargRt}}]%입니다.


사정율 98% ~ 102% 자료를 확인했을 때 [{{this.sectionArr[this.topGroup-1]}}] 부분에 [{{this.topPercent}}%]로 전체 중 가장 많은 투찰 포인트를 산정하였으며, 그중에서도 [{{this.topPoint_98}}] 포인트를 많이 산정하였습니다.
  </el-col>
</el-row>
<div v-if="isLine3"><el-row :gutter="4" v-show="this.isComplete">  <el-col :span="2" align="center">
  <div class="box">{{this.stdrd100yega[0]}}</div> <div class="box2">{{this.stdrd100yega[1]}}</div>
  </el-col>  <el-col :span="2" align="center">    <div class="box3">{{this.stdrd100bid[0]}}</div> <div class="box4">{{this.stdrd100bid[1]}}</div>
  </el-col>
</el-row><div style="position:absolute;margin-top:-80px;">
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span style="font-size:small">예가 출현 비율</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span style="font-size:small">내 투찰 비율</span>
</div>위의 표는 참여했던 예가 출현 비율을 100을 기점으로 마이너스 부분과 플러스 부분의 합을 표현한 그래프입니다.

<el-row :gutter="12" class="text_area"><el-col :span="6" :xs="{ span: 12 }">
예가 출현 비율을 확인 했을 때 우리가 알고 있는 이론보다 사정율 내 100.0 이하에서 예가 출현 성향이 매우 강합니다.

하지만 고객님이 투찰 포인트 산정 비율은 100.0 이상 구간으로 더 형성되어있음을 확인할 수 있습니다.

마이너스 예가가 더 출현되고 있는 이론적 사실과 함께 실제 예가의 흐름을 놓치지는 않도록 마이너스 구간으로 균형적인 투찰 포인트를 점검해야함을 고려해야할 것으로 보이며,

지속적으로 강세인 예가 흐름과 경쟁률을 파악하여 전략적인 투찰 포인트를 산정하셔야합니다.

</el-col></el-row>
</div>
<span style="font-size:x-large;font-weight:bold;" v-if="isTyper1">나의 분석 투찰 성향은?</span>

<el-steps :space="200"  :active=tendencyCd align-center v-if="isTyper1">
  <el-step title="적극형" description=""></el-step>
  <el-step title="부분적극형" description=""></el-step>
  <el-step title="부분중립형" description=""></el-step>
  <el-step title="중립형" description=""></el-step>
  <el-step title="부분안정형" description=""></el-step>
  <el-step title="안정형" description=""></el-step>
</el-steps>
</div>
<span style="line-height: 45px;" v-if="!scrollVersion">{{this.tendencyDetail[0]}}</span>
<vue-typer class="text4"
            v-loading="loading"
            :text=tendencyDetail
            :repeat='0'
            initial-action='typing'
            :pre-type-delay='100'
            :type-delay='15'
            :pre-erase-delay='1200'
            :erase-delay='3'
            erase-style='backspace'
            :erase-on-complete='false'
            caret-animation='blink'
            @completed='typerComplete'
            v-if="isTyper1 && scrollVersion"
></vue-typer>
<span style="line-height: 45px;" v-if="isTyper2 && this.tendencyDetail2[0] != null">
{{this.tendencyDetail2[0]}}
<el-progress :percentage="this.tendencyDetail2_0_Percent[0]" style="width:250px" v-if="tendencyDetail2_0_Percent.length > 0"></el-progress>
</span>
<span style="line-height: 45px;" v-if="isTyper2 && this.tendencyDetail2_1[0] != null">
{{this.tendencyDetail2_1[0]}}
<el-progress :percentage="this.tendencyDetail2_1_Percent[0]" style="width:250px" v-if="tendencyDetail2_1_Percent.length > 0"></el-progress>
</span>
<span style="line-height: 45px;" v-if="isTyper2 && this.tendencyDetail2_2[0] != null">
{{this.tendencyDetail2_2[0]}}
<el-progress :percentage="this.tendencyDetail2_2_Percent[0]" style="width:250px" v-if="tendencyDetail2_2_Percent.length > 0"></el-progress>
</span>
<span style="line-height: 45px;" v-if="!scrollVersion">{{this.tendencyDetail3[0]}}</span>
<vue-typer class="text4"
            v-loading="loading"
            :text=tendencyDetail3
            :repeat='0'
            initial-action='typing'
            :pre-type-delay='100'
            :type-delay='25'
            :pre-erase-delay='1200'
            :erase-delay='3'
            erase-style='backspace'
            :erase-on-complete='false'
            caret-animation='blink'
            @completed='typerComplete2'
            v-if="isTyper3 && tendencyDetail3[0] != null && scrollVersion"
></vue-typer>
</pre>

      </el-col>
    </el-row>
</div>
    <el-row :gutter="20">
      <el-col :span="20">
        <div style="margin-top: 1px" @change="clkPrvncl" v-show="toggle2">
          <el-checkbox-group v-model="checkboxGroup2" size="medium">
            <el-checkbox-button v-for="prvncl in prvnclValList" :label="prvncl" :key="prvncl">{{prvncl}}</el-checkbox-button>
          </el-checkbox-group>
        </div>
      </el-col>
    </el-row>
    <!--    <el-row type="flex" class="row-bg" justify="center">-->
    <!--      <el-col :span="20">-->
    <!--        <el-table-->
    <!--                border-->
    <!--                v-loading="loading"-->
    <!--                :data="realNoticeList"-->
    <!--                :header-cell-style="{background: '#f5f5f5'}"-->
    <!--        >-->
    <!--          <el-table-column-->
    <!--                  prop="사정율(예가)"-->
    <!--                  label="예가"-->
    <!--                  header-align="center"-->
    <!--          >-->
    <!--          </el-table-column>-->
    <!--          <el-table-column-->
    <!--                  prop="1구간"-->
    <!--                  label="1구간 (~98.9)"-->
    <!--                  header-align="center"-->
    <!--                  align="right"-->
    <!--          >-->
    <!--          </el-table-column>-->
    <!--          <el-table-column-->
    <!--                  prop="2구간"-->
    <!--                  label="2구간 (99.0~99.4)"-->
    <!--                  header-align="center"-->
    <!--                  align="right"-->
    <!--          >-->
    <!--          </el-table-column>-->
    <!--          <el-table-column-->
    <!--                  prop="3구간"-->
    <!--                  label="3구간 (99.5~99.9)"-->
    <!--                  header-align="center"-->
    <!--                  align="right"-->
    <!--          >-->
    <!--          </el-table-column>-->
    <!--          <el-table-column-->
    <!--                  prop="4구간"-->
    <!--                  label="4구간 (100.0~100.4)"-->
    <!--                  header-align="center"-->
    <!--                  align="right"-->
    <!--          >-->
    <!--          </el-table-column>-->
    <!--          <el-table-column-->
    <!--                  prop="5구간"-->
    <!--                  label="5구간 (100.5~100.9)"-->
    <!--                  header-align="center"-->
    <!--                  align="right"-->
    <!--          >-->
    <!--          </el-table-column>-->
    <!--          <el-table-column-->
    <!--                  prop="6구간"-->
    <!--                  label="6구간 (101.0~)"-->
    <!--                  header-align="center"-->
    <!--                  align="right"-->
    <!--          >-->
    <!--          </el-table-column>-->
    <!--        </el-table>-->
    <!--      </el-col>-->
    <!--    </el-row>-->
  </el-main>
</template>

<script>
    import * as _ from 'lodash'
    import mock from './mock';
    import $ from 'jquery';
    import { Bar } from 'vue-chartjs/legacy'
    import {
        Chart as ChartJS,
        Title,
        Tooltip,
        Legend,
        BarElement,
        CategoryScale,
        LinearScale
    } from 'chart.js'
    import html2pdf from 'html2pdf.js';
    import ChartJSPluginDatalabels from 'chartjs-plugin-datalabels';

    ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale, ChartJSPluginDatalabels);

    // let chart;
    //
    // chart = new ChartJS(this.$refs.barChart, {
    //     type: 'bar',
    //     data: this.chartData,
    //     options:this.options
    // })

    export default {
        name: "Dashboard",
        components: {
            Bar,
        },
        props: {
            chartId: {
                type: String,
                default: 'bar-chart'
            },
            datasetIdKey: {
                type: String,
                default: 'label'
            },
            width: {
                type: Number,
                default: 400
            },
            height: {
                type: Number,
                default: 300
            },
            cssClasses: {
                default: '',
                type: String
            },
            styles: {
                type: Object,
                default: () => {}
            },
            plugins: {
                type: Array,
                default: () => []
            }
        },
        data() {
            return {
                bizNo: '2118668278',
                sectionArr: ["~98.9","99.0~99.4","99.5~99.9","100.0~100.4","100.5~100.9","101.0~"], // 구간
                custRecordStatList: [],
                custRecordStatList2: [],
                custRecordStatList3: [{'낙찰구간':'낙찰건수','1구간':0,'2구간':0,'3구간':0,'4구간':0,'5구간':0,'6구간':0}], //구간별 낙찰 건수
                custRecordPercentList: [], //투찰율 구간별 백분율
                custRecordPercentList2: [], //투찰율 구간별 백분율 (98~102)
                strtDate: new Date(new Date().getFullYear()-1, new Date().getMonth(), new Date().getDate()).toLocaleDateString(),
                finDate: new Date(),
                config: {
                    headers: {
                        'Content-Type': 'application/json',
                        'auth-token': localStorage.getItem("authToken"), //localStorage.getItem("authToken"),
                        'refresh-token': localStorage.getItem("refreshToken") // localStorage.getItem("refreshToken"),
                    },
                },
                searchParam: {
                    strtDate: new Date(new Date().getFullYear()-1, new Date().getMonth(), new Date().getDate()).toLocaleDateString(),
                    finDate: new Date()
                },
                realNoticeList: [{'1구간':0,'2구간':0,'3구간':0,'4구간':0,'5구간':0,'6구간':0}],
                licnsCodeList: [],
                licnsCodeGroupList: [],
                areaCodeList: [],
                loading: false,
                checkboxGroup1: [],
                licnsValList: [],
                licnsKeyList: [],
                licnsCntList: [],
                checkboxGroup2: [],
                prvnclList: [],
                prvnclValList: [],
                prvnclKeyList: [],
                areaValList: [],
                areaKeyList: [],
                areaCntList: [],
                toggle1: false,
                toggle2: false,
                cmpNm: '',
                //maxNum1 : 256,
                maxNum2 : '549876',
                maxNum3 : 759214,
                cmpRankLicns: '상하수도 1건 / 지반조성포장 5건 / 철콘 2건',
                bidTotal:0, // 참여한 총 공고수
                cmpRankListCnt: 0, //총 낙찰 건수
                totalSBidAmt: 0, //낙찰한 공고 총금액
                notiSbidRt: 0, //낙찰률
                today: this.$moment().format('LL'), //오늘날짜
                topGroup:'', // 가장 많이 투찰한 구간
                topGroup_98:'', // 가장 많이 투찰한 구간 (98~102)
                topPoint:'', // 가장 많이 투찰한 지점
                topPoint_98:'', // 가장 많이 투찰한 지점 (98~102)
                custBidRt:0, // 가장 많이 투찰한 포인트의 전체 투찰 비율
                realOpbdPrargRt:0, // 실제 예가 출현 비율
                topPercent:'', // 가장 많이 투찰한 구간의 비율
                groupBidRank: [], // 구간별 투찰 순위
                groupBidRank_98: [], // 구간별 투찰 순위 (98~102)
                chartData: {
                    labels: [
                        '~98.9',
                        '99.0~99.4',
                        '99.5~99.9',
                        '100.0~100.4',
                        '100.5~100.9',
                        '101.0~'
                    ],
                    datasets: [
                        {
                            label: '예가 출현 비율',
                            backgroundColor: '#a0c5e7',
                            data: [0, 0, 0, 0, 0, 0, 0]
                        },
                        {
                            label: '내 투찰 비율',
                            backgroundColor: '#1956c9',
                            data: [0, 0, 0, 0, 0, 0, 0]
                        }
                    ],
                },
                chartData2: {
                    labels: [
                        '~98.9',
                        '99.0~99.4',
                        '99.5~99.9',
                        '100.0~100.4',
                        '100.5~100.9',
                        '101.0~'
                    ],
                    datasets: [
                        {
                            label: '예가 출현 비율',
                            backgroundColor: '#a0c5e7',
                            data: [0, 0, 0, 0, 0, 0, 0]
                        },
                        {
                            label: '내 투찰 비율',
                            backgroundColor: '#1956c9',
                            data: [0, 0, 0, 0, 0, 0, 0]
                        }
                    ],
                },
                chartOptions: {
                    responsive: false,
                    maintainAspectRatio: false,
                    // legend: { // 범례 사용 안 함
                    //     display: false,
                    // },
                    // tooltip: { // 기존 툴팁 사용 안 함
                    //     enabled: false
                    // },
                    plugins: {
                        datalabels: {
                            anchor: 'end',
                            align: 'top',
                            formatter: (value) => {
                                let percent = value;
                                return percent;
                            }
                            // font: {
                            //     weight: 'bold'
                            // }
                        },
                    }
                },
                isComplete: false, // 조회완료
                tendencyCd: 0, // 투찰 성향
                tendencyCdArr: ['적극형','부분적극형','부분중립형','중립형','부분안정형','안정형'], // 투찰 성향 배열
                tendencyCdArr2: [['펭'],['펭','수리'],['펭','수리','도그'],['수리'],['수리','도그'],['도그']], // 투찰 성향별 추천AI
                tendencyCdReverseArr: ['도그','도그','펭','없음','펭','수리'], // 투찰 반대 성향 배열
                tendencyCdReverseArr2: ['부분중립형/안정형/부분안정형','부분중립형/안정형/부분안정형','적극형/부분적극형/부분중립형','없음','적극형/부분적극형/부분중립형','부분적극형/중립형/부분중립형/부분안정형'], // 투찰 반대 성향 배열
                tendencyDetail: [], // 투찰 성향 상세 type1 멘트
                tendencyDetail2: [], // 투찰 성향 상세 type2 멘트
                tendencyDetail2_1: [], // 투찰 성향 상세 type2 멘트
                tendencyDetail2_2: [], // 투찰 성향 상세 type2 멘트
                tendencyDetail3: [], // 투찰 성향 상세 type3 멘트
                aiSuggest: [], // 추천 AI
                setTyping: null,
                line1: [],
                scrollTime: 0,
                tendencyDetail2_0_Percent: [],
                tendencyDetail2_1_Percent: [],
                tendencyDetail2_2_Percent: [],
                isLine2: false,
                isLine3: false,
                isTyper1: false,
                isTyper2: false,
                isTyper3: false,
                stdrd100yega: [], // 100기준 상하 예가 출현비율
                stdrd100bid: [], // 100기준 상하 내 투찰비율
                scrollVersion: true,
            }
        },
        mounted() {
        },
        methods: {
            exportToPDF () {
                //window.scrollTo(0, 0);
                html2pdf(this.$refs.pdfarea, {
                    margin: [10, 15, 10, 15],
                    filename: 'document.pdf',
                    image: {type: "jpg", quality: 0.95},
                    //	allowTaint 옵션추가
                    html2canvas: {scrollY: 0, scale: 1, dpi: 300, letterRendering: true, allowTaint: true},
                    jsPDF: {orientation: 'portrait', unit: 'mm', format: 'a4', compressPDF: true}
                })
            },
            line1Complete: function() {
                setTimeout(() => {
                    this.isLine2 = true;
                }, 500);
                setTimeout(() => {
                    this.isLine3 = true;
                }, 5000);

                if (!this.scrollVersion) {
                    this.isTyper2 = true;
                    setTimeout(() => {
                        this.isTyper3 = true;
                    }, 500);
                    setTimeout(() => {
                        clearInterval(this.scrollTime);
                    }, 10000);
                }
            },
            typerComplete: function() {
                this.isTyper2 = true;

                setTimeout(() => {
                    this.isTyper3 = true;
                }, 500);
            },
            typerComplete2: function() {
                clearInterval(this.scrollTime);
            },
            setTendency(imsi) { // 성향 추출
                var step1 = this.groupBidRank[0];
                var step2 = this.groupBidRank[1];
                switch (step1) {
                    case 1:
                        console.log("1구간");
                        if (step2 == 6) {
                            this.tendencyCd = 0;
                            this.tendencyDetail2_0_Percent = [95,3.14];
                            this.tendencyDetail = ["대표님의 지난 투찰 기록을 바탕으로 성향 분석 결과, " + this.cmpNm + " 대표님의 투찰 성향은 [적극형]으로 분류되었습니다. \n" +
                            "이는 고객님께서 낙찰 확률의 잠재력이 높은 곳으로 투찰하며 새로운 예가 출현의 기회를 잡으려 하고,\n" +
                            "흙 속의 진주를 발견하려는 성향을 가지고 있다는 것을 의미합니다.\n" +
                            "[적극형]의 특징은 경쟁 업체의 진입이 적으면서도 예상 출현 예가의 흐름을 확인하고 파악하며,\n" +
                            "분산 투자보다는 집중 투찰 포인트를 도전적으로 찾아내는 전략입니다.\n" +
                            "하지만 오히려 도전적인 부분과 잠재력이 높은 포인트를 찾다보니 예가의 흐름을 따라가지 못한다는 말을 듣기도 합니다.\n" +
                            "특징은 도전적이며 잠재력 높은 투찰 포인트를 찾아내려는 전략적 접근입니다.\n" +
                            "\n" +
                            "이에 따라, 고객님의 투찰 전략을 더욱 세밀하게 조율하기 위해 아래와 같은 제안을 드립니다:\n" +
                            "\n" +
                            "▶ 경쟁 업체 및 예가 출현의 기회 분석\n" +
                            "고객님의 경우, 경쟁 업체의 진입이 적고 잠재력 높은 출현 예가의 흐름을 면밀히 분석하여, 새롭고 도전적인 투찰 포인트를 발견하는 것이 중요합니다.\n" +
                            "이를 통해, 고객님께서는 시장에서 유리한 위치를 확보하며 낙찰 확률을 높일 수 있습니다.\n" +
                            "\n" +
                            "▶ 집중 투찰 포인트의 선정 및 끈기 있는 투찰\n" +
                            "고객님은 분산 투자보다는 하나 혹은 소수의 집중된 투찰 포인트에 끈기 있게 투찰함으로써 낙찰 확률을 높이려는 전략을 선호합니다.\n" +
                            "이러한 접근은 고객님께서 시장 변화에 민첩하게 대응하고, 장기적으로 안정적인 수익을 창출할 수 있는 기반을 마련해 줄 것입니다.\n"];

                            this.tendencyDetail2 = [
                            "내 성향과 맞는 비드톡톡 추천 AI분석가: " + this.tendencyCdArr2[this.tendencyCd][0] + "\n" +
                            "\n" +
                            "AI분석가 펭: \n" +
                            "최신 예가 자료 및 경쟁 업체 참여 자료를 바탕으로, 출현 예가와 경쟁사와 우위한 구간에서 결정적인 투찰 포인트를 산출합니다.\n" +
                            "이런 접근 방식의 분석 능력은 공고 내에서 유리한 포인트를 확보하며, 상황에 따라 경쟁사와 예가의 전략적 위치를 선점하길 원하는 고객님에게 적극 추천됩니다.\n" +
                            "★적극형/부분적극형/부분중립형 성향에 추천드립니다.\n"+
                            "< 나와의 예상 적합도 >" + "                      < 현재 펭 평균 낙찰률 > 3.14%"
                            ];

                            this.tendencyDetail3 = ["내 성향 변화를 위한 비드톡톡 추천 AI분석가\n" +
                            "\n" +
                            "[[AI분석가 "+ this.tendencyCdReverseArr[this.tendencyCd] + "\n" +
                            "기본적으로 "+ this.tendencyCdReverseArr2[this.tendencyCd] + " 성향에 추천드리는 AI분석가입니다.\n" +
                            "하지만 현재 결과가 나오지 않아 내 투찰 성향의 개선을 위해 적극적인 변화를 원하신다면\n" +
                            "AI분석가 "+ this.tendencyCdReverseArr[this.tendencyCd] + " 을 추천드리겠습니다.]]\n" +
                            "\n" +
                            "고객님의 투찰 전략과 가장 잘 맞는 분석가를 선택하여, 보다 체계적이고 전략적인 투찰 접근을 시도해 보시는 것을 권장드립니다.\n" +
                            "\n" +
                            "비드톡톡은 언제나 고객님의 더 많은 낙찰이 되기를 기원하며 \n" +
                            "고객님의 투찰 전략과 가장 잘 맞는 비드톡톡의 AI 분석가를 선택하여 보다 체계적이고 전략적인 투찰 접근을 시도해 보시는 것을 권장드립니다. \n"
                            ];
                        } else {
                            this.tendencyCd = 1;
                            this.aiSuggest = ["펭","수리"];
                            this.tendencyDetail2_0_Percent = [85,3.14];
                            this.tendencyDetail2_1_Percent = [85,2.95];
                            this.tendencyDetail = ["대표님의 지난 투찰 기록을 바탕으로 성향 분석 결과, " + this.cmpNm + " 대표님의 투찰 성향은 [부분적극형]으로 분류되었습니다. \n" +
                            "이는 고객님께서 예상 출현 예가의 흐름을 파악하여 안정적인 투찰 포인트를 잡으려고 하면서도 일정 공고에서는\n" +
                            "경쟁율이 적은 포인트도 확인해 예가가 출현하지 않을 것이라는 위험을 감수하는 전략을 선호한다는 것을 의미합니다.\n" +
                            "안정적인 예가 출현을 체크하면서도 부분적으로는 위험도 감수하며 경쟁 업체 빈 구간을 확보하여 낙찰률을 높이고자 합니다. \n" +
                            "\n" +
                            "이에 따라, 고객님의 투찰 전략을 더욱 세밀하게 조율하기 위해 아래와 같은 제안을 드립니다:\n" +
                            "\n" +
                            "▶ 위험 감수와 안정적인 예가 흐름 파악 사이의 균형\n" +
                            "고객님께서는 안정적인 예가 흐름을 파악하면서도 부분적으로는 더 높은 수익을 위해 위험을 감수하는 전략을 선호합니다.\n" +
                            "이를 위해, 시장 변화와 경쟁 업체의 움직임을 면밀히 분석하여, 경쟁율이 낮은 투찰 포인트를 선별하는 동시에\n" +
                            "안정적인 수익을 보장할 수 있는 포인트도 확인하는 것이 중요합니다.\n" +
                            "\n" +
                            "▶ 다양한 시장 상황에 대한 적응력 강화\n" +
                            "고객님의 경우, 다양한 시장 상황에서의 적응력을 강화하여 더 많은 투찰 기회를 포착하는 것이 유리합니다.\n" +
                            "이를 위해 시장 변화에 대한 심층적인 이해와 함께, 다양한 예가 흐름과 경쟁 업체의 전략을 예측하는 능력을 키우는 것이 추천됩니다.\n" +
                            "\n" +
                            "비드톡톡 AI 분석가 중 고객님께 특히 추천드리는 분들은 [펭 / 수리]입니다:\n"];
                            this.tendencyDetail2 = [
                            "AI분석가 펭: \n" +
                            "최신 예가 자료 및 경쟁 업체 참여 자료를 바탕으로, 출현 예가와 경쟁사와 우위한 구간에서 결정적인 투찰 포인트를 산출합니다.\n" +
                            "이런 접근 방식의 분석 능력은 공고 내에서 유리한 포인트를 확보하며, 상황에 따라 경쟁사와 예가의 전략적 위치를 선점하길 원하는 고객님에게 적극 추천됩니다.\n" +
                            "★적극형/부분적극형/부분중립형 성향에 추천드립니다.\n" +
                            "< 나와의 예상 적합도 >" + "                      < 현재 펭 평균 낙찰률 > 3.14%"];
                            this.tendencyDetail2_1 = [
                            "AI분석가 수리: \n" +
                            "최신 예가 자료 및 경쟁 업체 참여 자료를 바탕으로 신중한 출현 예가의 흐름을 파악하여 전체적인 사정율 내에\n" +
                            "고객님에게 맞는 최적의 투찰 포인트를 제시합니다. 신중한 예가 정보 분석 및 전략을 선호하는 고객님께 적합합니다.\n" +
                            "★부분적극형/중립형/부분중립형/부분안정형 성향에 추천드립니다.\n" +
                            "< 나와의 예상 적합도 >" + "                      < 현재 수리 평균 낙찰률> 2.95%"];
                            this.tendencyDetail3 = [
                              "내 성향 변화를 위한 비드톡톡 추천 AI분석가\n" +
                            "\n" +
                            "[[AI분석가 "+ this.tendencyCdReverseArr[this.tendencyCd] + "\n" +
                            "기본적으로 "+ this.tendencyCdReverseArr2[this.tendencyCd] + " 성향에 추천드리는 AI분석가입니다.\n" +
                            "하지만 현재 결과가 나오지 않아 내 투찰 성향의 개선을 위해 적극적인 변화를 원하신다면\n" +
                            "AI분석가 "+ this.tendencyCdReverseArr[this.tendencyCd] + " 을 추천드리겠습니다.]]\n" +
                            "\n" +
                            "고객님의 투찰 전략과 가장 잘 맞는 분석가를 선택하여, 보다 체계적이고 전략적인 투찰 접근을 시도해 보시는 것을 권장드립니다.\n" +
                            "\n" +
                            "비드톡톡은 언제나 고객님의 더 많은 낙찰이 되기를 기원하며 \n" +
                            "고객님의 투찰 전략과 가장 잘 맞는 비드톡톡의 AI 분석가를 선택하여 보다 체계적이고 전략적인 투찰 접근을 시도해 보시는 것을 권장드립니다. \n"
                            ];
                        }
                        break;
                    case 2:
                        console.log("2구간");
                        if (step2 == 5) {
                            this.tendencyCd = 3;
                            this.tendencyDetail2_0_Percent = [91,2.95];
                            this.tendencyDetail = ["대표님의 지난 투찰 기록을 바탕으로 성향 분석 결과, " + this.cmpNm + " 대표님의 투찰 성향은 [중립형]으로 분류되었습니다. \n" +
                            "이는 고객님께서 예상 출현 예가의 흐름을 파악하여 안정적인 투찰 포인트를 잡으려는 경향이 있음을 의미합니다.\n" +
                            "[중립형]의 특징은 예가 흐름과 경쟁율 사이에서 균형적이면서 전략적으로 투찰 포인트를 잡는 것입니다. 단발적인 예가 변동에 흔들리지 않으며,\n" +
                            "장기적으로 예상 출현 예가를 확인하며 신중하게 위험 관리를 하는 접근 방식을 가지고 있으나\n" +
                            "오히려 신중하게 예측한 포인트가 타 경쟁 업체에게 밟힐 확률이 높을 수도 있기 때문에 주의해야합니다.\n" +
                            "\n" +
                            "이에 따라, 고객님의 투찰 전략을 더욱 세밀하게 조율하기 위해 아래와 같은 제안을 드립니다:\n" +
                            "\n" +
                            "▶ 예가 흐름과 경쟁율 사이에서의 균형적인 접근\n" +
                            "고객님의 경우, 예가 흐름을 면밀히 분석하고 경쟁율을 고려하여 안정적이면서도 전략적인 투찰 포인트를 잡는 것이 중요합니다.\n" +
                            "이러한 접근을 통해 변동성이 높은 시장 상황에서도 안정적인 수익을 창출할 수 있습니다.\n" +
                            "\n" +
                            "▶ 장기적인 예가 흐름 분석을 통한 위험 관리\n" +
                            "장기적인 예가 흐름을 분석하고 신중하게 위험을 관리함으로써, 시장의 변화에 효과적으로 대응할 수 있는 기반을 마련하는 것이 바람직합니다.\n" +
                            "이는 고객님께서 장기적으로 안정적인 수익을 확보할 수 있도록 도와줄 것입니다.\n" +
                            "\n" +
                            "내 성향과 맞는 비드톡톡 추천 AI분석가: " + this.tendencyCdArr2[this.tendencyCd][0] + "\n" +
                            "\n"];

                            this.tendencyDetail2 = [
                            "AI분석가 수리: \n" +
                            "최신 예가 자료를 바탕으로 고객님에게 맞는 최적의 투찰 포인트를 제시합니다. 신중한 예가 정보 분석 및 전략을 선호하는 고객님께 적합합니다.\n" +
                            "고객님의 투찰 전략과 가장 잘 맞는 분석가를 선택하여, 보다 체계적이고 전략적인 투찰 접근을 시도해 보시는 것을 권장드립니다\n" +
                            "★부분적극형/중립형/부분중립형/부분안정형 성향에 추천드립니다."+
                            "< 나와의 예상 적합도 >" + "                      < 현재 수리 평균 낙찰률 > 2.95%"];

                            this.tendencyDetail3 = [
                            "내 성향 변화를 위한 비드톡톡 추천 AI분석가\n" +
                            "\n" +
                            "[[AI분석가 "+ this.tendencyCdReverseArr[this.tendencyCd] + "\n" +
                            "기본적으로 "+ this.tendencyCdReverseArr2[this.tendencyCd] + " 성향에 추천드리는 AI분석가입니다.\n" +
                            "하지만 현재 결과가 나오지 않아 내 투찰 성향의 개선을 위해 적극적인 변화를 원하신다면\n" +
                            "AI분석가 "+ this.tendencyCdReverseArr[this.tendencyCd] + " 을 추천드리겠습니다.]]\n" +
                            "\n" +
                            "고객님의 투찰 전략과 가장 잘 맞는 분석가를 선택하여, 보다 체계적이고 전략적인 투찰 접근을 시도해 보시는 것을 권장드립니다.\n" +
                            "\n" +
                            "비드톡톡은 언제나 고객님의 더 많은 낙찰이 되기를 기원하며 \n" +
                            "고객님의 투찰 전략과 가장 잘 맞는 비드톡톡의 AI 분석가를 선택하여 보다 체계적이고 전략적인 투찰 접근을 시도해 보시는 것을 권장드립니다. \n"];
                        } else {
                            this.tendencyCd = 2;
                            this.aiSuggest = ["펭","수리","도그"];
                            this.tendencyDetail2_0_Percent = [91,3.14];
                            this.tendencyDetail2_1_Percent = [85,2.95];
                            this.tendencyDetail2_2_Percent = [87,2.77];
                            this.tendencyDetail = ["대표님의 지난 투찰 기록을 바탕으로 성향 분석 결과, " + this.cmpNm + " 대표님의 투찰 성향은 [부분중립형]으로 분류되었습니다. \n" +
                            "이는 고객님께서 중립적인 투찰 접근 방식을 선호하면서도 특정 상황에서는 경쟁율이 낮은 포인트를 확인하여\n" +
                            "일정 부분 위험을 감수하는 전략을 선호한다는 것을 의미합니다.\n" +
                            "[부분 중립형]의 특징은 균형 잡힌 분석을 통해 안정적인 예가 출현을 체크하면서도,\n" +
                            "상황에 따라 소극적, 적극적인 태도로 위험도를 감수하며 낙찰률을 높이려는 접근 방식입니다.\n" +
                            "하지만 다른 부분 적극형, 부분 안정형과 마찬가지로 일관성을 잃은 투찰 포인트를 산정하여 분배한다면 낙찰 확률을 감소시킬수도 있습니다.\n" +
                            "\n" +
                            "이에 따라, 고객님의 투찰 전략을 더욱 세밀하게 조율하기 위해 아래와 같은 제안을 드립니다:\n" +
                            "\n" +
                            "▶ 균형 잡힌 투찰 포인트 분석과 위험 관리\n" +
                            "고객님의 경우, 출현 했던 예가와 출현 예상 예가, 그리고 경쟁사 확인을 통한 균형적인 분석 포인트 산출이 중요합니다.\n" +
                            "이를 통해, 안정적인 예가 흐름을 파악하고, 동시에 부분적으로 위험을 감수하여 더 높은 수익을 창출할 수 있는 기회를 포착할 수 있습니다.\n" +
                            "\n" +
                            "▶ 적극적인 투찰 포인트 산정과 분산 투찰 전략\n" +
                            "다양한 투찰 지점 포인트에서의 경험을 통해 시장의 변화에 대한 이해도를 높이고, 유연한 대응 전략을 수립하는 것이 바람직합니다.\n" +
                            "또한 적극적인 투찰 포인트 산정을 통해 좀 더 다양한 예가 출현에 대해 전략적으로 대응할 수 있도록 도울 것 입니다.\n" +
                            "\n" +
                            "비드톡톡 분석가 중 고객님께 특히 추천드리는 AI분석가는 [" + this.aiSuggest.join("/") + "] 입니다:\n" +
                            "\n"];
                            this.tendencyDetail2 = ["AI분석가 펭: \n" +
                            "최신 예가 자료 및 경쟁 업체 참여 자료를 바탕으로, 출현 예가와 경쟁사와 우위한 구간에서 결정적인 투찰 포인트를 산출합니다.\n" +
                            "이런 접근 방식의 분석 능력은 공고 내에서 유리한 포인트를 확보하며, 상황에 따라 경쟁사와 예가의 전략적 위치를 선점하길 원하는 고객님에게 적극 추천됩니다.\n" +
                            "★적극형/부분적극형/부분중립형 성향에 추천드립니다.\n" +
                            "< 나와의 예상 적합도 >" + "                      < 현재 펭 평균 낙찰률 > 3.14%"];

                            this.tendencyDetail2_1 = [
                            "AI분석가 수리: \n" +
                            "최신 예가 자료 및 경쟁 업체 참여 자료를 바탕으로 신 중한 출현 예가의 흐름을 파악하여 전체적인 사정율 내에 고객님에게 맞는\n" +
                            "최적의 투찰 포인트를 제시합니다. 신중한 예가 정보 분석 및 전략을 선호하는 고객님께 적합합니다.\n" +
                            "★부분적극형/중립형/부분중립형/부분안정형 성향에 추천드립니다.  \n" +
                            "< 나와의 예상 적합도 >" + "                      < 현재 수리 평균 낙찰률 > 2.95%"];

                            this.tendencyDetail2_2 = [
                            "AI분석가 도그: \n" +
                            "최신 예가 자료를 바탕으로 출현 예가 흐름을 파악하여 다음 예상 출현 구간을 체크해 사정율 내 최적의 투찰 포인트를 산출합니다.\n" +
                            "핵심 예가를 파악하는 전문성을 발휘하여 예가 중심적으로 유리한 위치를 확보할 수 있으며 균형적 투찰을 원하는 고객님께 적합합니다.\n" +
                            "★부분중립형/안정형/부분안정형 성향에 추천드립니다. \n" +
                            "< 나와의 예상 적합도 >" + "                      < 현재 도그 평균 낙찰률 > 2.77%"];

                            this.tendencyDetail3 = [
                            "내 성향 변화를 위한 비드톡톡 추천 AI분석가\n" +
                            "\n" +
                            "[[AI분석가 "+ this.tendencyCdReverseArr[this.tendencyCd] + "\n" +
                            "기본적으로 "+ this.tendencyCdReverseArr2[this.tendencyCd] + " 성향에 추천드리는 AI분석가입니다.\n" +
                            "하지만 현재 결과가 나오지 않아 내 투찰 성향의 개선을 위해 적극적인 변화를 원하신다면\n" +
                            "AI분석가 "+ this.tendencyCdReverseArr[this.tendencyCd] + " 을 추천드리겠습니다.]]\n" +
                            "\n" +
                            "고객님의 투찰 전략과 가장 잘 맞는 분석가를 선택하여, 보다 체계적이고 전략적인 투찰 접근을 시도해 보시는 것을 권장드립니다.\n" +
                            "\n" +
                            "비드톡톡은 언제나 고객님의 더 많은 낙찰이 되기를 기원하며 \n" +
                            "고객님의 투찰 전략과 가장 잘 맞는 비드톡톡의 AI 분석가를 선택하여 보다 체계적이고 전략적인 투찰 접근을 시도해 보시는 것을 권장드립니다. \n"
                            ];
                        }
                        break;
                    case 3:
                        console.log("3구간");
                        if (step2 == 4) {
                            this.tendencyCd = 5;
                            this.tendencyDetail2_0_Percent = [90,2.77];
                            this.tendencyDetail = ["대표님의 지난 투찰 기록을 바탕으로 성향 분석 결과, " + this.cmpNm + " 대표님의 투찰 성향은 [안정형]으로 분류되었습니다. \n" +
                            "이는 고객님께서 예상 예가의 출현을 중점적으로 파악하며, 산출 예가의 비중이 높은 부분에 주로 투찰하시며,\n" +
                            "동시에 예가와 근접하지 못하는 위험을 최소화하려고 노력하신다는 것을 의미합니다.\n" +
                            "이와 같은 접근 방식은 전체적으로 신중하게 정보를 파악하고 분석하며, 예정 가격 예상 출현에 집중하여 예가의 흐름을 앞서가려합니다.\n" +
                            "하지만 예가의 흐름을 앞서려다 보니 근소한 차이에서만 낙찰이 이루어지기 때문에 때로는 입찰 참여함에 있어 지침을 토로할 수 있습니다. \n" +
                            "\n" +
                            "이에 따라, 고객님의 투찰 전략을 더욱 세밀하게 조율하기 위해 아래와 같은 제안을 드립니다\n" +
                            "\n" +
                            "▶ 더 높은 낙찰 확률을 위한 신중한 투찰 포인트 산정\n" +
                            "고객님께서 예가와 근접한 부분에 집중하시되, 낙찰 확률을 높일 수 있는 신중한 투찰 포인트를 선정하며, 좀 더 적극적인 태도의 접근이 필요할 것으로 보입니다.\n" +
                            "\n" +
                            "▶ 전체적인 예가 흐름 파악을 위한 투찰 포인트 분산\n" +
                            "동시에, 투찰 포인트를 분산시켜 전체적으로 안정적인 예가 흐름을 파악하고 이에 기반한 투찰을 진행하는 전략도 고려할 수 있습니다.\n" +
                            "이는 예상치 못한 시장 변화에도 유연하게 대응할 수 있는 기반을 마련해줄 것입니다."];
                            this.tendencyDetail2 = [
                                "내 성향과 맞는 비드톡톡 추천 AI분석가: " + this.tendencyCdArr2[this.tendencyCd][0] + "\n" +
                                "\n" +
                                "AI분석가 도그: \n" +
                                "최신 예가 자료를 바탕으로 출현 예가 흐름을 파악하여 다음 예상 출현 구간을 체크해 사정율 내 최적의 투찰 포인트를 산출합니다.\n" +
                                "핵심 예가를 파악하는 전문성을 발휘하여 예가 중심적으로 유리한 위치를 확보할 수 있으며 균형적 투찰을 원하는 고객님께 적합합니다.\n" +
                                "★부분중립형/안정형/부분안정형 성향에 추천드립니다.\n" +
                                "< 나와의 예상 적합도 >                        < 현재 도그 평균 낙찰률 > 2.77%"
                            ];
                            this.tendencyDetail3 =
                                ["내 성향 변화를 위한 비드톡톡 추천 AI분석가\n" +
                                "\n" +
                                "[[AI분석가 "+ this.tendencyCdReverseArr[this.tendencyCd] + "\n" +
                                "기본적으로 "+ this.tendencyCdReverseArr2[this.tendencyCd] + " 성향에 추천드리는 AI분석가입니다.\n" +
                                "하지만 현재 결과가 나오지 않아 내 투찰 성향의 개선을 위해 적극적인 변화를 원하신다면\n" +
                                "AI분석가 "+ this.tendencyCdReverseArr[this.tendencyCd] + " 을 추천드리겠습니다.]]\n" +
                                "\n" +
                                "고객님의 투찰 전략과 가장 잘 맞는 분석가를 선택하여, 보다 체계적이고 전략적인 투찰 접근을 시도해 보시는 것을 권장드립니다.\n" +
                                "\n" +
                                "비드톡톡은 언제나 고객님의 더 많은 낙찰이 되기를 기원하며 \n" +
                                "고객님의 투찰 전략과 가장 잘 맞는 비드톡톡의 AI 분석가를 선택하여 보다 체계적이고 전략적인 투찰 접근을 시도해 보시는 것을 권장드립니다. \n"];
                        } else {
                            this.tendencyCd = 4;
                            this.aiSuggest = ["수리","도그"];
                            this.tendencyDetail2_0_Percent = [78,2.95];
                            this.tendencyDetail2_1_Percent = [85,2.77];
                            this.tendencyDetail = ["대표님의 지난 투찰 기록을 바탕으로 성향 분석 결과, " + this.cmpNm + " 대표님의 투찰 성향은 [부분안정형]입니다\n" +
                                "이는 고객님께서 안정적인 수익 창출을 중시하면서도 일정 부분 위험을 감수하여 다양한 투찰 기회를 탐색하는 전략을 선호한다는 것을 의미합니다. \n" +
                                "[부분안정형]의 특징은 안정성과 유연성 사이에서 균형을 이루려는 접근 방식을 가지고 있지만 오히려 다양한 범위 내의\n" +
                                "균형을 추구하거나 전략적으로 투찰하려 했지만 각 구간의 산출 예가 흐름의 장점의 기회를 놓칠 수가 있습니다. \n" +
                                "\n" +
                                "이에 따라, 고객님의 투찰 전략을 더욱 세밀하게 조율하기 위해 아래와 같은 제안을 드립니다\n" +
                                "\n" +
                                "▶ 신중한 정보 분석을 통한 전략적 투찰 포인트 설정\n" +
                                "고객님의 경우, 시장의 변화와 출현 예가의 흐름을 면밀히 분석하여, 안정적이면서도 잠재적인 수익성이 높은 투찰 포인트를 선별하는 것이 중요합니다. \n" +
                                "이를 통해, 변동성이 높은 시장 상황에서도 유리한 위치를 확보할 수 있습니다.\n" +
                                "\n" +
                                "▶ 다양한 투찰 지점 포인트의 경험\n" +
                                "안정적인 수익 창출과 함께, 다양한 투찰 지점 포인트에서의 경험을 통해 시장의 변화에 대한 이해도를 높이고, 유연한 대응 전략을 수립하는 것이 바람직합니다. \n" +
                                "이는 고객님께서 보다 넓은 범위에서 기회를 포착하고, 시장 변화에 효과적으로 대응할 수 있는 기반을 마련해 줄 것입니다.\n"+
                                "비드톡톡 분석가 중 고객님께 특히 추천드리는 AI분석가는 [" + this.aiSuggest.join("/") + "] 입니다"];
                            this.tendencyDetail2 = [
                                "AI분석가 수리:\n" +
                                " 최신 예가 자료를 바탕으로 고객님에게 맞는 최적의 투찰 포인트를 제시합니다. 신중한 예가 정보 분석 및 전략을 선호하는 고객님께 적합합니다.\n" +
                                "★부분적극형/중립형/부분중립형/부분안정형 성향에 추천드립니다.\n\n" +
                                "< 나와의 예상 적합도 >" + "                      < 현재 수리 평균 낙찰률 > 2.95%"];
                            this.tendencyDetail2_1 = [
                                "AI분석가 도그:\n" +
                                "예상 출현 예가의 다양한 구간을 체크하여 최고의 투찰 포인트를 산출합니다.\n" +
                                "핵심 예가를 파악하는 전문성을 발휘하여 경쟁율이 높은 상황에서도 유리한 위치를 확보할 수 있습니다.\n" +
                                "★부분중립형/안정형/부분안정형 성향에 추천드립니다.\n\n"+
                                "\n" +
                                "< 나와의 예상 적합도 >" + "                      < 현재 도그 평균 낙찰률 > 2.77%"];
                            this.tendencyDetail3 = [
                                "내 성향 변화를 위한 비드톡톡 추천 AI분석가\n" +
                                "\n" +
                                "[[AI분석가 "+ this.tendencyCdReverseArr[this.tendencyCd] + "\n" +
                                "기본적으로 "+ this.tendencyCdReverseArr2[this.tendencyCd] + " 성향에 추천드리는 AI분석가입니다.\n" +
                                "하지만 현재 결과가 나오지 않아 내 투찰 성향의 개선을 위해 적극적인 변화를 원하신다면\n" +
                                "AI분석가 "+ this.tendencyCdReverseArr[this.tendencyCd] + " 을 추천드리겠습니다.]]\n\n" +
                                "고객님의 투찰 전략과 가장 잘 맞는 분석가를 선택하여, 보다 체계적이고 전략적인 투찰 접근을 시도해 보시는 것을 권장드립니다.\n" +
                                "\n" +
                                "비드톡톡은 언제나 고객님의 더 많은 낙찰이 되기를 기원하며 \n" +
                                "고객님의 투찰 전략과 가장 잘 맞는 비드톡톡의 AI 분석가를 선택하여 보다 체계적이고 전략적인 투찰 접근을 시도해 보시는 것을 권장드립니다. \n"];
                        }
                        break;
                    case 4:
                        console.log("4구간");
                        if (step2 == 3) {
                            this.tendencyCd = 5;
                            this.tendencyDetail2_0_Percent = [90,2.77];
                            this.tendencyDetail = ["대표님의 지난 투찰 기록을 바탕으로 성향 분석 결과, " + this.cmpNm + " 대표님의 투찰 성향은 [안정형]으로 분류되었습니다. \n" +
                            "이는 고객님께서 예상 예가의 출현을 중점적으로 파악하며, 산출 예가의 비중이 높은 부분에 주로 투찰하시며,\n" +
                            "동시에 예가와 근접하지 못하는 위험을 최소화하려고 노력하신다는 것을 의미합니다.\n" +
                            "이와 같은 접근 방식은 전체적으로 신중하게 정보를 파악하고 분석하며, 예정 가격 예상 출현에 집중하여 예가의 흐름을 앞서가려합니다.\n" +
                            "하지만 예가의 흐름을 앞서려다 보니 근소한 차이에서만 낙찰이 이루어지기 때문에 때로는 입찰 참여함에 있어 지침을 토로할 수 있습니다. \n" +
                            "\n" +
                            "이에 따라, 고객님의 투찰 전략을 더욱 세밀하게 조율하기 위해 아래와 같은 제안을 드립니다\n" +
                            "\n" +
                            "▶ 더 높은 낙찰 확률을 위한 신중한 투찰 포인트 산정\n" +
                            "고객님께서 예가와 근접한 부분에 집중하시되, 낙찰 확률을 높일 수 있는 신중한 투찰 포인트를 선정하며, 좀 더 적극적인 태도의 접근이 필요할 것으로 보입니다.\n" +
                            "\n" +
                            "▶ 전체적인 예가 흐름 파악을 위한 투찰 포인트 분산\n" +
                            "동시에, 투찰 포인트를 분산시켜 전체적으로 안정적인 예가 흐름을 파악하고 이에 기반한 투찰을 진행하는 전략도 고려할 수 있습니다.\n" +
                            "이는 예상치 못한 시장 변화에도 유연하게 대응할 수 있는 기반을 마련해줄 것입니다."];
                            this.tendencyDetail2 = [
                            "내 성향과 맞는 비드톡톡 추천 AI분석가: " + this.tendencyCdArr2[this.tendencyCd][0] + "\n" +
                            "\n" +
                            "AI분석가 도그: \n" +
                            "최신 예가 자료를 바탕으로 출현 예가 흐름을 파악하여 다음 예상 출현 구간을 체크해 사정율 내 최적의 투찰 포인트를 산출합니다.\n" +
                            "핵심 예가를 파악하는 전문성을 발휘하여 예가 중심적으로 유리한 위치를 확보할 수 있으며 균형적 투찰을 원하는 고객님께 적합합니다.\n" +
                            "★부분중립형/안정형/부분안정형 성향에 추천드립니다.\n" +
                            "< 나와의 예상 적합도 >                        < 현재 도그 평균 낙찰률 > 2.77%"
                            ];
                            this.tendencyDetail3 =
                            ["내 성향 변화를 위한 비드톡톡 추천 AI분석가\n" +
                            "\n" +
                            "[[AI분석가 "+ this.tendencyCdReverseArr[this.tendencyCd] + "\n" +
                            "기본적으로 "+ this.tendencyCdReverseArr2[this.tendencyCd] + " 성향에 추천드리는 AI분석가입니다.\n" +
                            "하지만 현재 결과가 나오지 않아 내 투찰 성향의 개선을 위해 적극적인 변화를 원하신다면\n" +
                            "AI분석가 "+ this.tendencyCdReverseArr[this.tendencyCd] + " 을 추천드리겠습니다.]]\n" +
                            "\n" +
                            "고객님의 투찰 전략과 가장 잘 맞는 분석가를 선택하여, 보다 체계적이고 전략적인 투찰 접근을 시도해 보시는 것을 권장드립니다.\n" +
                            "\n" +
                            "비드톡톡은 언제나 고객님의 더 많은 낙찰이 되기를 기원하며 \n" +
                            "고객님의 투찰 전략과 가장 잘 맞는 비드톡톡의 AI 분석가를 선택하여 보다 체계적이고 전략적인 투찰 접근을 시도해 보시는 것을 권장드립니다. \n"];
                        } else {
                            this.tendencyCd = 4;
                            this.aiSuggest = ["수리","도그"];
                            this.tendencyDetail2_0_Percent = [78,2.95];
                            this.tendencyDetail2_1_Percent = [85,2.77];
                            this.tendencyDetail = ["대표님의 지난 투찰 기록을 바탕으로 성향 분석 결과, " + this.cmpNm + " 대표님의 투찰 성향은 [부분안정형]으로 분류되었습니다. \n" +
                                "이는 고객님께서 안정적인 수익 창출을 중시하면서도 일정 부분 위험을 감수하여 다양한 투찰 기회를 탐색하는 전략을 선호한다는 것을 의미합니다. \n" +
                                "[부분안정형]의 특징은 안정성과 유연성 사이에서 균형을 이루려는 접근 방식을 가지고 있지만 오히려 다양한 범위 내의\n" +
                                "균형을 추구하거나 전략적으로 투찰하려 했지만 각 구간의 산출 예가 흐름의 장점의 기회를 놓칠 수가 있습니다. \n" +
                                "\n" +
                                "이에 따라, 고객님의 투찰 전략을 더욱 세밀하게 조율하기 위해 아래와 같은 제안을 드립니다\n" +
                                "\n" +
                                "▶ 신중한 정보 분석을 통한 전략적 투찰 포인트 설정\n" +
                                "고객님의 경우, 시장의 변화와 출현 예가의 흐름을 면밀히 분석하여, 안정적이면서도 잠재적인 수익성이 높은 투찰 포인트를 선별하는 것이 중요합니다. \n" +
                                "이를 통해, 변동성이 높은 시장 상황에서도 유리한 위치를 확보할 수 있습니다.\n" +
                                "\n" +
                                "▶ 다양한 투찰 지점 포인트의 경험\n" +
                                "안정적인 수익 창출과 함께, 다양한 투찰 지점 포인트에서의 경험을 통해 시장의 변화에 대한 이해도를 높이고, 유연한 대응 전략을 수립하는 것이 바람직합니다. \n" +
                                "이는 고객님께서 보다 넓은 범위에서 기회를 포착하고, 시장 변화에 효과적으로 대응할 수 있는 기반을 마련해 줄 것입니다.\n"+
                                "비드톡톡 분석가 중 고객님께 특히 추천드리는 AI분석가는 [" + this.aiSuggest.join("/") + "] 입니다"];
                            this.tendencyDetail2 = [
                                "AI분석가 수리:\n" +
                                " 최신 예가 자료를 바탕으로 고객님에게 맞는 최적의 투찰 포인트를 제시합니다. 신중한 예가 정보 분석 및 전략을 선호하는 고객님께 적합합니다.\n" +
                                "★부분적극형/중립형/부분중립형/부분안정형 성향에 추천드립니다.\n\n" +
                                "< 나와의 예상 적합도 >" + "                      < 현재 수리 평균 낙찰률 > 2.95%\n"];
                            this.tendencyDetail2_1 = [
                                "AI분석가 도그:\n" +
                                "예상 출현 예가의 다양한 구간을 체크하여 최고의 투찰 포인트를 산출합니다.\n" +
                                "핵심 예가를 파악하는 전문성을 발휘하여 경쟁율이 높은 상황에서도 유리한 위치를 확보할 수 있습니다.\n" +
                                "★부분중립형/안정형/부분안정형 성향에 추천드립니다.\n\n"+
                                "\n" +
                                "< 나와의 예상 적합도 >" + "                      < 현재 도그 평균 낙찰률 > 2.77%\n"];
                            this.tendencyDetail3 = [
                                "내 성향 변화를 위한 비드톡톡 추천 AI분석가\n" +
                                "\n" +
                                "[[AI분석가 "+ this.tendencyCdReverseArr[this.tendencyCd] + "\n" +
                                "기본적으로 "+ this.tendencyCdReverseArr2[this.tendencyCd] + " 성향에 추천드리는 AI분석가입니다.\n" +
                                "하지만 현재 결과가 나오지 않아 내 투찰 성향의 개선을 위해 적극적인 변화를 원하신다면\n" +
                                "AI분석가 "+ this.tendencyCdReverseArr[this.tendencyCd] + " 을 추천드리겠습니다.]]\n" +
                                "고객님의 투찰 전략과 가장 잘 맞는 분석가를 선택하여, 보다 체계적이고 전략적인 투찰 접근을 시도해 보시는 것을 권장드립니다.\n" +
                                "\n" +
                                "비드톡톡은 언제나 고객님의 더 많은 낙찰이 되기를 기원하며 \n" +
                                "고객님의 투찰 전략과 가장 잘 맞는 비드톡톡의 AI 분석가를 선택하여 보다 체계적이고 전략적인 투찰 접근을 시도해 보시는 것을 권장드립니다. \n"];
                        }
                        break;
                    case 5:
                        console.log("5구간");
                        if (step2 == 2) {
                            this.tendencyCd = 3;
                            this.tendencyDetail2_0_Percent = [91,2.95];
                            this.tendencyDetail = ["대표님의 지난 투찰 기록을 바탕으로 성향 분석 결과, " + this.cmpNm + " 대표님의 투찰 성향은 [중립형]으로 분류되었습니다. \n" +
                            "이는 고객님께서 예상 출현 예가의 흐름을 파악하여 안정적인 투찰 포인트를 잡으려는 경향이 있음을 의미합니다.\n" +
                            "[중립형]의 특징은 예가 흐름과 경쟁율 사이에서 균형적이면서 전략적으로 투찰 포인트를 잡는 것입니다. 단발적인 예가 변동에 흔들리지 않으며,\n" +
                            "장기적으로 예상 출현 예가를 확인하며 신중하게 위험 관리를 하는 접근 방식을 가지고 있으나\n" +
                            "오히려 신중하게 예측한 포인트가 타 경쟁 업체에게 밟힐 확률이 높을 수도 있기 때문에 주의해야합니다.\n" +
                            "\n" +
                            "이에 따라, 고객님의 투찰 전략을 더욱 세밀하게 조율하기 위해 아래와 같은 제안을 드립니다:\n" +
                            "\n" +
                            "▶ 예가 흐름과 경쟁율 사이에서의 균형적인 접근\n" +
                            "고객님의 경우, 예가 흐름을 면밀히 분석하고 경쟁율을 고려하여 안정적이면서도 전략적인 투찰 포인트를 잡는 것이 중요합니다.\n" +
                            "이러한 접근을 통해 변동성이 높은 시장 상황에서도 안정적인 수익을 창출할 수 있습니다.\n" +
                            "\n" +
                            "▶ 장기적인 예가 흐름 분석을 통한 위험 관리\n" +
                            "장기적인 예가 흐름을 분석하고 신중하게 위험을 관리함으로써, 시장의 변화에 효과적으로 대응할 수 있는 기반을 마련하는 것이 바람직합니다.\n" +
                            "이는 고객님께서 장기적으로 안정적인 수익을 확보할 수 있도록 도와줄 것입니다.\n" +
                            "\n" +
                            "내 성향과 맞는 비드톡톡 추천 AI분석가: " + this.tendencyCdArr2[this.tendencyCd][0] + "\n" +
                            "\n"];

                            this.tendencyDetail2 = [
                                "AI분석가 수리: \n" +
                                "최신 예가 자료를 바탕으로 고객님에게 맞는 최적의 투찰 포인트를 제시합니다. 신중한 예가 정보 분석 및 전략을 선호하는 고객님께 적합합니다.\n" +
                                "고객님의 투찰 전략과 가장 잘 맞는 분석가를 선택하여, 보다 체계적이고 전략적인 투찰 접근을 시도해 보시는 것을 권장드립니다\n" +
                                "★부분적극형/중립형/부분중립형/부분안정형 성향에 추천드립니다."+
                                "< 나와의 예상 적합도 >" + "                      < 현재 수리 평균 낙찰률 > 2.95%"];

                            this.tendencyDetail3 = [
                                "내 성향 변화를 위한 비드톡톡 추천 AI분석가\n" +
                                "\n" +
                                "[[AI분석가 "+ this.tendencyCdReverseArr[this.tendencyCd] + "\n" +
                                "기본적으로 "+ this.tendencyCdReverseArr2[this.tendencyCd] + " 성향에 추천드리는 AI분석가입니다.\n" +
                                "하지만 현재 결과가 나오지 않아 내 투찰 성향의 개선을 위해 적극적인 변화를 원하신다면\n" +
                                "AI분석가 "+ this.tendencyCdReverseArr[this.tendencyCd] + " 을 추천드리겠습니다.]]\n" +
                                "\n" +
                                "고객님의 투찰 전략과 가장 잘 맞는 분석가를 선택하여, 보다 체계적이고 전략적인 투찰 접근을 시도해 보시는 것을 권장드립니다.\n" +
                                "\n" +
                                "비드톡톡은 언제나 고객님의 더 많은 낙찰이 되기를 기원하며 \n" +
                                "고객님의 투찰 전략과 가장 잘 맞는 비드톡톡의 AI 분석가를 선택하여 보다 체계적이고 전략적인 투찰 접근을 시도해 보시는 것을 권장드립니다. \n"];
                        } else {
                            this.tendencyCd = 2;
                            this.aiSuggest = ["펭","수리","도그"];
                            this.tendencyDetail2_0_Percent = [91,3.14];
                            this.tendencyDetail2_1_Percent = [85,2.95];
                            this.tendencyDetail2_2_Percent = [87,2.77];
                            this.tendencyDetail = ["대표님의 지난 투찰 기록을 바탕으로 성향 분석 결과, " + this.cmpNm + " 대표님의 투찰 성향은 [부분중립형]으로 분류되었습니다. \n" +
                            "이는 고객님께서 중립적인 투찰 접근 방식을 선호하면서도 특정 상황에서는 경쟁율이 낮은 포인트를 확인하여\n" +
                            "일정 부분 위험을 감수하는 전략을 선호한다는 것을 의미합니다.\n" +
                            "[부분 중립형]의 특징은 균형 잡힌 분석을 통해 안정적인 예가 출현을 체크하면서도,\n" +
                            "상황에 따라 소극적, 적극적인 태도로 위험도를 감수하며 낙찰률을 높이려는 접근 방식입니다.\n" +
                            "하지만 다른 부분 적극형, 부분 안정형과 마찬가지로 일관성을 잃은 투찰 포인트를 산정하여 분배한다면 낙찰 확률을 감소시킬수도 있습니다.\n" +
                            "\n" +
                            "이에 따라, 고객님의 투찰 전략을 더욱 세밀하게 조율하기 위해 아래와 같은 제안을 드립니다:\n" +
                            "\n" +
                            "▶ 균형 잡힌 투찰 포인트 분석과 위험 관리\n" +
                            "고객님의 경우, 출현 했던 예가와 출현 예상 예가, 그리고 경쟁사 확인을 통한 균형적인 분석 포인트 산출이 중요합니다.\n" +
                            "이를 통해, 안정적인 예가 흐름을 파악하고, 동시에 부분적으로 위험을 감수하여 더 높은 수익을 창출할 수 있는 기회를 포착할 수 있습니다.\n" +
                            "\n" +
                            "▶ 적극적인 투찰 포인트 산정과 분산 투찰 전략\n" +
                            "다양한 투찰 지점 포인트에서의 경험을 통해 시장의 변화에 대한 이해도를 높이고, 유연한 대응 전략을 수립하는 것이 바람직합니다.\n" +
                            "또한 적극적인 투찰 포인트 산정을 통해 좀 더 다양한 예가 출현에 대해 전략적으로 대응할 수 있도록 도울 것 입니다.\n" +
                            "\n" +
                            "비드톡톡 분석가 중 고객님께 특히 추천드리는 AI분석가는 [" + this.aiSuggest.join("/") + "] 입니다:\n" +
                            "\n"];
                            this.tendencyDetail2 = ["AI분석가 펭: \n" +
                            "최신 예가 자료 및 경쟁 업체 참여 자료를 바탕으로, 출현 예가와 경쟁사와 우위한 구간에서 결정적인 투찰 포인트를 산출합니다.\n" +
                            "이런 접근 방식의 분석 능력은 공고 내에서 유리한 포인트를 확보하며, 상황에 따라 경쟁사와 예가의 전략적 위치를 선점하길 원하는 고객님에게 적극 추천됩니다.\n" +
                            "★적극형/부분적극형/부분중립형 성향에 추천드립니다.\n" +
                            "< 나와의 예상 적합도 >" + "                      < 현재 펭 평균 낙찰률 > 3.14%"];

                            this.tendencyDetail2_1 = [
                                "AI분석가 수리: \n" +
                                "최신 예가 자료 및 경쟁 업체 참여 자료를 바탕으로 신 중한 출현 예가의 흐름을 파악하여 전체적인 사정율 내에 고객님에게 맞는\n" +
                                "최적의 투찰 포인트를 제시합니다. 신중한 예가 정보 분석 및 전략을 선호하는 고객님께 적합합니다.\n" +
                                "★부분적극형/중립형/부분중립형/부분안정형 성향에 추천드립니다.  \n" +
                                "< 나와의 예상 적합도 >" + "                      < 현재 수리 평균 낙찰률 > 2.95%"];

                            this.tendencyDetail2_2 = [
                                "AI분석가 도그: \n" +
                                "최신 예가 자료를 바탕으로 출현 예가 흐름을 파악하여 다음 예상 출현 구간을 체크해 사정율 내 최적의 투찰 포인트를 산출합니다.\n" +
                                "핵심 예가를 파악하는 전문성을 발휘하여 예가 중심적으로 유리한 위치를 확보할 수 있으며 균형적 투찰을 원하는 고객님께 적합합니다.\n" +
                                "★부분중립형/안정형/부분안정형 성향에 추천드립니다. \n" +
                                "< 나와의 예상 적합도 >" + "                      < 현재 도그 평균 낙찰률 > 2.77%"];

                            this.tendencyDetail3 = [
                                "내 성향 변화를 위한 비드톡톡 추천 AI분석가\n" +
                                "\n" +
                                "[[AI분석가 "+ this.tendencyCdReverseArr[this.tendencyCd] + "\n" +
                                "기본적으로 "+ this.tendencyCdReverseArr2[this.tendencyCd] + " 성향에 추천드리는 AI분석가입니다.\n" +
                                "하지만 현재 결과가 나오지 않아 내 투찰 성향의 개선을 위해 적극적인 변화를 원하신다면\n" +
                                "AI분석가 "+ this.tendencyCdReverseArr[this.tendencyCd] + " 을 추천드리겠습니다.]]\n" +
                                "\n" +
                                "고객님의 투찰 전략과 가장 잘 맞는 분석가를 선택하여, 보다 체계적이고 전략적인 투찰 접근을 시도해 보시는 것을 권장드립니다.\n" +
                                "\n" +
                                "비드톡톡은 언제나 고객님의 더 많은 낙찰이 되기를 기원하며 \n" +
                                "고객님의 투찰 전략과 가장 잘 맞는 비드톡톡의 AI 분석가를 선택하여 보다 체계적이고 전략적인 투찰 접근을 시도해 보시는 것을 권장드립니다. \n"
                            ];
                        }
                        break;
                    case 6:
                        console.log("6구간");
                        if (step2 == 1) {
                            this.tendencyCd = 0;
                            this.tendencyDetail2_0_Percent = [95,3.14];
                            if (imsi) {
                                this.tendencyDetail = ["고객님의 투찰 성향 분석 결과, [적극형]으로 분류되었습니다.\n" +
                                "이는 고객님께서 낙찰 확률의 잠재력이 높은 곳으로 투찰하며 새로운 예가 출현의 기회를 잡으려 하고, 흙 속의 진주를 발견하려는 성향을 가지고 있다는 것을 의미합니다.\n" +
                                "경쟁 업체의 진입이 적으면서도 예상 출현 예가의 흐름을 확인, 파악하며, 분산 투자보다는 집중 투찰 포인트를 선정하여 끈기 있게 투찰하면서 낙찰확률을 높이려고 합니다.\n" +
                                "[적극형]의 특징은 도전적이며 잠재력 높은 투찰 포인트를 찾아내려는 전략적 접근입니다.",
                                    "이에 따라, 고객님의 투찰 전략을 더욱 세밀하게 조율하기 위해 아래와 같은 제안을 드립니다:\n" +
                                    "\n" +
                                    "▶ 경쟁 업체 및 예가 출현의 기회 분석\n" +
                                    "고객님의 경우, 경쟁 업체의 진입이 적고 잠재력 높은 출현 예가의 흐름을 면밀히 분석하여, 새롭고 도전적인 투찰 포인트를 발견하는 것이 중요합니다.\n" +
                                    "이를 통해, 고객님께서는 시장에서 유리한 위치를 확보하며 낙찰 확률을 높일 수 있습니다.\n" +
                                    "▶ 집중 투찰 포인트의 선정 및 끈기 있는 투찰\n" +
                                    "고객님은 분산 투자보다는 하나 혹은 소수의 집중된 투찰 포인트에 끈기 있게 투찰함으로써 낙찰 확률을 높이려는 전략을 선호합니다.\n" +
                                    "이러한 접근은 고객님께서 시장 변화에 민첩하게 대응하고, 장기적으로 안정적인 수익을 창출할 수 있는 기반을 마련해 줄 것입니다.",
                                    "비드톡톡 AI 분석가 중 고객님께 특히 추천드리는 분은 AI분석가 펭입니다:\n" +
                                    "\n" +
                                    "AI분석가 펭: 최신 예가 자료 및 경쟁 업체 참여 자료를 바탕으로, 출현 예가와 더 광범위한 구간에서 결정적인 투찰 포인트를 산출합니다.\n" +
                                    "이런 접근 방식의 분석 능력은 공고 내에서 유리한 포인트를 확보하며, 상황에 따라 경쟁사와 예가의 전략적 위치를 선점하길 원하는 고객님에게 적극 추천됩니다.\n" +
                                    "고객님의 투찰 전략과 가장 잘 맞는 분석가를 선택하여, 보다 도전적이고 전략적인 투찰 접근을 시도해 보시는 것을 권장드립니다\n"
                                ];
                            } else {

                                this.tendencyDetail = ["대표님의 지난 투찰 기록을 바탕으로 성향 분석 결과, " + this.cmpNm + " 대표님의 투찰 성향은 [적극형]으로 분류되었습니다. \n" +
                                "이는 고객님께서 낙찰 확률의 잠재력이 높은 곳으로 투찰하며 새로운 예가 출현의 기회를 잡으려 하고,\n" +
                                "흙 속의 진주를 발견하려는 성향을 가지고 있다는 것을 의미합니다.\n" +
                                "[적극형]의 특징은 경쟁 업체의 진입이 적으면서도 예상 출현 예가의 흐름을 확인하고 파악하며,\n" +
                                "분산 투자보다는 집중 투찰 포인트를 도전적으로 찾아내는 전략입니다.\n" +
                                "하지만 오히려 도전적인 부분과 잠재력이 높은 포인트를 찾다보니 예가의 흐름을 따라가지 못한다는 말을 듣기도 합니다.\n" +
                                "특징은 도전적이며 잠재력 높은 투찰 포인트를 찾아내려는 전략적 접근입니다.\n" +
                                "\n" +
                                "이에 따라, 고객님의 투찰 전략을 더욱 세밀하게 조율하기 위해 아래와 같은 제안을 드립니다:\n" +
                                "\n" +
                                "▶ 경쟁 업체 및 예가 출현의 기회 분석\n" +
                                "고객님의 경우, 경쟁 업체의 진입이 적고 잠재력 높은 출현 예가의 흐름을 면밀히 분석하여, 새롭고 도전적인 투찰 포인트를 발견하는 것이 중요합니다.\n" +
                                "이를 통해, 고객님께서는 시장에서 유리한 위치를 확보하며 낙찰 확률을 높일 수 있습니다.\n" +
                                "\n" +
                                "▶ 집중 투찰 포인트의 선정 및 끈기 있는 투찰\n" +
                                "고객님은 분산 투자보다는 하나 혹은 소수의 집중된 투찰 포인트에 끈기 있게 투찰함으로써 낙찰 확률을 높이려는 전략을 선호합니다.\n" +
                                "이러한 접근은 고객님께서 시장 변화에 민첩하게 대응하고, 장기적으로 안정적인 수익을 창출할 수 있는 기반을 마련해 줄 것입니다.\n"];

                                this.tendencyDetail2 = [
                                "내 성향과 맞는 비드톡톡 추천 AI분석가: " + this.tendencyCdArr2[this.tendencyCd][0] + "\n" +
                                "\n" +
                                "AI분석가 펭: \n" +
                                "최신 예가 자료 및 경쟁 업체 참여 자료를 바탕으로, 출현 예가와 경쟁사와 우위한 구간에서 결정적인 투찰 포인트를 산출합니다.\n" +
                                "이런 접근 방식의 분석 능력은 공고 내에서 유리한 포인트를 확보하며, 상황에 따라 경쟁사와 예가의 전략적 위치를 선점하길 원하는 고객님에게 적극 추천됩니다.\n" +
                                "★적극형/부분적극형/부분중립형 성향에 추천드립니다.\n"+
                                "< 나와의 예상 적합도 >" + "                      < 현재 펭 평균 낙찰률 > 3.14%"
                                ];

                                this.tendencyDetail3 = ["내 성향 변화를 위한 비드톡톡 추천 AI분석가\n" +
                                "\n" +
                                "[[AI분석가 "+ this.tendencyCdReverseArr[this.tendencyCd] + "\n" +
                                "기본적으로 "+ this.tendencyCdReverseArr2[this.tendencyCd] + " 성향에 추천드리는 AI분석가입니다.\n" +
                                "하지만 현재 결과가 나오지 않아 내 투찰 성향의 개선을 위해 적극적인 변화를 원하신다면\n" +
                                "AI분석가 "+ this.tendencyCdReverseArr[this.tendencyCd] + " 을 추천드리겠습니다.]]\n" +
                                "\n" +
                                "고객님의 투찰 전략과 가장 잘 맞는 분석가를 선택하여, 보다 체계적이고 전략적인 투찰 접근을 시도해 보시는 것을 권장드립니다.\n" +
                                "\n" +
                                "비드톡톡은 언제나 고객님의 더 많은 낙찰이 되기를 기원하며 \n" +
                                "고객님의 투찰 전략과 가장 잘 맞는 비드톡톡의 AI 분석가를 선택하여 보다 체계적이고 전략적인 투찰 접근을 시도해 보시는 것을 권장드립니다. \n"
                                ];
                            }

                        } else {
                            this.tendencyCd = 1;
                            this.aiSuggest = ["펭","수리"];
                            this.tendencyDetail2_0_Percent = [85,3.14];
                            this.tendencyDetail2_1_Percent = [85,2.95];
                            this.tendencyDetail = ["대표님의 지난 투찰 기록을 바탕으로 성향 분석 결과, " + this.cmpNm + " 대표님의 투찰 성향은 [부분적극형]으로 분류되었습니다. \n" +
                            "이는 고객님께서 예상 출현 예가의 흐름을 파악하여 안정적인 투찰 포인트를 잡으려고 하면서도 일정 공고에서는\n" +
                            "경쟁율이 적은 포인트도 확인해 예가가 출현하지 않을 것이라는 위험을 감수하는 전략을 선호한다는 것을 의미합니다.\n" +
                            "안정적인 예가 출현을 체크하면서도 부분적으로는 위험도 감수하며 경쟁 업체 빈 구간을 확보하여 낙찰률을 높이고자 합니다. \n" +
                            "\n" +
                            "이에 따라, 고객님의 투찰 전략을 더욱 세밀하게 조율하기 위해 아래와 같은 제안을 드립니다:\n" +
                            "\n" +
                            "▶ 위험 감수와 안정적인 예가 흐름 파악 사이의 균형\n" +
                            "고객님께서는 안정적인 예가 흐름을 파악하면서도 부분적으로는 더 높은 수익을 위해 위험을 감수하는 전략을 선호합니다.\n" +
                            "이를 위해, 시장 변화와 경쟁 업체의 움직임을 면밀히 분석하여, 경쟁율이 낮은 투찰 포인트를 선별하는 동시에\n" +
                            "안정적인 수익을 보장할 수 있는 포인트도 확인하는 것이 중요합니다.\n" +
                            "\n" +
                            "▶ 다양한 시장 상황에 대한 적응력 강화\n" +
                            "고객님의 경우, 다양한 시장 상황에서의 적응력을 강화하여 더 많은 투찰 기회를 포착하는 것이 유리합니다.\n" +
                            "이를 위해 시장 변화에 대한 심층적인 이해와 함께, 다양한 예가 흐름과 경쟁 업체의 전략을 예측하는 능력을 키우는 것이 추천됩니다.\n" +
                            "\n" +
                            "비드톡톡 AI 분석가 중 고객님께 특히 추천드리는 분들은 [펭 / 수리]입니다:\n"];
                            this.tendencyDetail2 = [
                                "AI분석가 펭: \n" +
                                "최신 예가 자료 및 경쟁 업체 참여 자료를 바탕으로, 출현 예가와 경쟁사와 우위한 구간에서 결정적인 투찰 포인트를 산출합니다.\n" +
                                "이런 접근 방식의 분석 능력은 공고 내에서 유리한 포인트를 확보하며, 상황에 따라 경쟁사와 예가의 전략적 위치를 선점하길 원하는 고객님에게 적극 추천됩니다.\n" +
                                "★적극형/부분적극형/부분중립형 성향에 추천드립니다.\n" +
                                "< 나와의 예상 적합도 >" + "                      < 현재 펭 평균 낙찰률 > 3.14%"];
                            this.tendencyDetail2_1 = [
                                "AI분석가 수리: \n" +
                                "최신 예가 자료 및 경쟁 업체 참여 자료를 바탕으로 신중한 출현 예가의 흐름을 파악하여 전체적인 사정율 내에\n" +
                                "고객님에게 맞는 최적의 투찰 포인트를 제시합니다. 신중한 예가 정보 분석 및 전략을 선호하는 고객님께 적합합니다.\n" +
                                "★부분적극형/중립형/부분중립형/부분안정형 성향에 추천드립니다.\n" +
                                "< 나와의 예상 적합도 >" + "                      < 현재 수리 평균 낙찰률> 2.95%"];
                            this.tendencyDetail3 = [
                                "내 성향 변화를 위한 비드톡톡 추천 AI분석가\n" +
                                "\n" +
                                "[[AI분석가 "+ this.tendencyCdReverseArr[this.tendencyCd] + "\n" +
                                "기본적으로 "+ this.tendencyCdReverseArr2[this.tendencyCd] + " 성향에 추천드리는 AI분석가입니다.\n" +
                                "하지만 현재 결과가 나오지 않아 내 투찰 성향의 개선을 위해 적극적인 변화를 원하신다면\n" +
                                "AI분석가 "+ this.tendencyCdReverseArr[this.tendencyCd] + " 을 추천드리겠습니다.]]\n" +
                                "\n" +
                                "고객님의 투찰 전략과 가장 잘 맞는 분석가를 선택하여, 보다 체계적이고 전략적인 투찰 접근을 시도해 보시는 것을 권장드립니다.\n" +
                                "\n" +
                                "비드톡톡은 언제나 고객님의 더 많은 낙찰이 되기를 기원하며 \n" +
                                "고객님의 투찰 전략과 가장 잘 맞는 비드톡톡의 AI 분석가를 선택하여 보다 체계적이고 전략적인 투찰 접근을 시도해 보시는 것을 권장드립니다. \n"
                            ];
                        }
                        break;
                    default:
                }
            },
            licnsToggleBtn() {
                this.toggle1 = !this.toggle1;

            },
            areaToggleBtn() {
                this.toggle2 = !this.toggle2;
            },
            clkLicns() {
                this.searchParam.licnsNmList = [];

                for (var i=0 ; i<this.checkboxGroup1.length ; i++) {
                    // console.log(this.checkboxGroup1[i]);
                    // console.log(this.licnsValList[0]);
                    // if (this.licnsValList[0] == this.checkboxGroup1[i]) {
                    // console.log("같다");
                    // }
                    this.searchParam.licnsNmList.push(this.licnsKeyList[_.indexOf(this.licnsValList, this.checkboxGroup1[i])]);
                    //console.log(_.findIndex(this.licnsValList, '수질방지'));
                }

                this.getRealNoticeList();
            },
            clkPrvncl() {
                this.searchParam.prvnclCdList = [];

                for (var i=0 ; i<this.checkboxGroup2.length ; i++) {
                    // console.log(this.checkboxGroup1[i]);
                    // console.log(this.licnsValList[0]);
                    // if (this.licnsValList[0] == this.checkboxGroup1[i]) {
                    // console.log("같다");
                    // }
                    this.searchParam.prvnclCdList.push(this.prvnclKeyList[_.indexOf(this.prvnclValList, this.checkboxGroup2[i])]);
                    //console.log(_.findIndex(this.licnsValList, '수질방지'));
                }

                this.getRealNoticeList();
            },
            doReset() {
                this.sum= 0;
                this.checkboxGroup1= [];
                this.licnsValList= [];
                this.licnsKeyList= [];
                this.licnsCntList= [];
                this.checkboxGroup2= [];
                this.prvnclList= [];
                this.prvnclValList= [];
                this.prvnclKeyList= [];
                this.custRecordPercentList = [0,0,0,0,0,0];
                //this.searchParam.searchLicnsList = [];
                this.tendencyDetail = [];
                this.line1 = [];
                this.isLine2 = false;
                this.isLine3 = false;
                this.isTyper1 = false;
                this.isTyper2 = false;
                this.isTyper3 = false;
            },
            doSearch(imsi) {
                console.log(this.tendencyCdArr2[5][0]);
                this.doReset();
                this.doReport();

                var self = this;
                this.getCustRecordStat(97, async function(topGroup) {
                    setTimeout(() => {
                        self.getCustStatNcstPrargList(topGroup, function(bidTotal, cmpRankListCnt, totalSBidAmt) {
                            self.countingType1('#number1', bidTotal);
                            self.countingType2('#cmpRankListCnt', cmpRankListCnt);
                            self.countingType3('#notiSbidRt', (cmpRankListCnt/bidTotal*100).toFixed(2));
                            self.countingType1('#totalSBidAmt', totalSBidAmt);

                            if (imsi == 1) {
                                self.setTendency(imsi); // 성향 진단
                            } else {
                                self.setTendency(); // 성향 진단
                            }
                        });
                        self.getCustStatTopPoint(topGroup, 97);
                    }, 2400);
                });
                this.getCustRecordStat(98, async function(topGroup, topGroup_98) {

                    self.getCustStatNcstPrargList(topGroup_98, function() {

                    });
                    self.getCustStatTopPoint(topGroup_98, 98);
                });
                // this.getRealNoticeList();
                //this.getLicnsCodeList();
                // this.getAreacodeList();

                setTimeout(() => {
                    this.isTyper1 = true;
                }, 9000);

                /* 스크롤 s*/
                if (this.scrollVersion) {
                    let elMain = document.getElementById('contents');

                    elMain.scrollTop = elMain.scrollHeight;
                    //console.log($(document).height());
                    //$(elMain).scrollTop($(elMain).prop('scrollHeight'));

                    //var preScrollHeight = 0;
                    setTimeout(() => {
                        this.scrollTime = setInterval(() => {

                            //preScrollHeight = $(elMain).prop('scrollHeight')+300;
                            //$(elMain).scrollTop($(elMain).prop('scrollHeight')+300);

                            $(elMain).animate({
                                scrollTop: $(elMain).prop('scrollHeight')+180
                            }, 1500)

                            //console.log(preScrollHeight);
                            // if (preScrollHeight == $(elMain).prop('scrollHeight')+300) {
                            //     clearInterval(scrollTime);
                            // }

                            //console.log(this.isTyper);
                        }, 2550);
                        //console.log(this.scrollTime);

                    }, 2500);
                }
                /* 스크롤 e*/
            },
            doReport() {

            },
            getLicnsCodeList() {

                this.loading = true;

                var postData = {
                    notiKindCd: 0
                };

                this.$http.post('/api/comnCode/licnsCodeList', postData, this.config).then(res => {

                    this.licnsCodeList = res.data.licnsCodeList;
                    // this.licnsCodeList = (_.orderBy(this.licnsCodeList, ['licnsDstnctCd', 'rpsntOrgnzCd', 'licnsCd', 'licnsCtgyNm']));
                    // console.log(this.licnsCodeList);

                    // this.licnsCodeGroupList = _.groupBy(res.data.licnsCodeList, function(o) {
                    //    return o.licnsCtgyNm;
                    // });

                    // this.licnsCodeList = _.filter(this.licnsCodeGroupList, function(o) {
                    //     if (o.length == 1) {
                    //         return o;
                    //     }
                    // });

                    for (var j=0 ; j<this.licnsCodeList.length; j++) {
                        if (this.licnsCodeList[j].licnsCd != '0006' && this.licnsCodeList[j].licnsCd != '0018') {
                            //console.log(this.licnsCodeList[j].licnsNm);
                            //console.log(this.licnsCodeList[j].licnsCd);
                            //this.checkboxGroup1.push(this.licnsCodeList[j].licnsNm);
                            this.licnsValList.push(this.licnsCodeList[j].licnsNm);
                            this.licnsKeyList.push(this.licnsCodeList[j].licnsCtgyNm);
                            //this.licnsCntList.push(res.data.notiLicnCntList[j].count);
                        }
                    }

                    //console.log(this.checkboxGroup1);
                    this.loading = false;
                }).catch(error => console.log(error));
            },
            getAreacodeList() {
                this.loading = true;

                var postData = {

                };

                this.$http.post('/api/realnotice/areaCodeList', postData, this.config).then(res => {
                    this.prvnclList = _.filter(res.data.areaCodeList, function(o) {
                        return o.areaNm == "";
                    });
                    this.areaCodeList = res.data.areaCodeList;
                    // this.licnsCodeList = (_.orderBy(this.licnsCodeList, ['licnsDstnctCd', 'rpsntOrgnzCd', 'licnsCd', 'licnsCtgyNm']));
                    // console.log(this.licnsCodeList);

                    // this.licnsCodeGroupList = _.groupBy(res.data.licnsCodeList, function(o) {
                    //    return o.licnsCtgyNm;
                    // });

                    // this.licnsCodeList = _.filter(this.licnsCodeGroupList, function(o) {
                    //     if (o.length == 1) {
                    //         return o;
                    //     }
                    // });

                    for (var j=0 ; j<this.prvnclList.length; j++) {
                        //console.log(this.licnsCodeList[j].licnsNm);
                        //console.log(this.licnsCodeList[j].licnsCd);
                        //this.checkboxGroup1.push(this.licnsCodeList[j].licnsNm);
                        this.prvnclValList.push(this.prvnclList[j].prvnclNm);
                        this.prvnclKeyList.push(this.prvnclList[j].prvnclCd);
                        //this.licnsCntList.push(res.data.notiLicnCntList[j].count);

                    }

                    //console.log(this.checkboxGroup1);
                    this.loading = false;
                }).catch(error => console.log(error));
            },
            getRealNoticeList() {

                this.searchParam.findStrtDt = this.$moment(this.strtDate).format('YYYY-MM-DD');
                this.searchParam.findEndDt = this.$moment(this.finDate).format('YYYY-MM-DD');
                this.loading = true;

                var postData = _.defaults({
                    pageUnit: 9999,
                    pageIndex: 0,
                    strtRecordIntex: 0,
                    areaCdList: []
                }, this.searchParam);

                this.$http.post('/api/realnotice/list', postData, this.config).then(res => {
                    //console.log(res);


                    this.realNoticeList[0]['사정율(예가)'] = '예가출현';
                    this.realNoticeList[0]['1구간'] = _.filter(res.data.realNoticeList, function(o) {
                        return o['opbdPrargRt'] <= 98.9999;
                    }).length;
                    this.realNoticeList[0]['2구간'] = _.filter(res.data.realNoticeList, function(o) {
                        return o['opbdPrargRt'] >= 99.0 && o['opbdPrargRt'] <= 99.4999;
                    }).length;
                    this.realNoticeList[0]['3구간'] = _.filter(res.data.realNoticeList, function(o) {
                        return o['opbdPrargRt'] >= 99.4 && o['opbdPrargRt'] <= 99.9999;
                    }).length;
                    this.realNoticeList[0]['4구간'] = _.filter(res.data.realNoticeList, function(o) {
                        return o['opbdPrargRt'] >= 100.0 && o['opbdPrargRt'] <= 100.4999;
                    }).length;
                    this.realNoticeList[0]['5구간'] = _.filter(res.data.realNoticeList, function(o) {
                        return o['opbdPrargRt'] >= 100.5 && o['opbdPrargRt'] <= 100.9999;
                    }).length;
                    this.realNoticeList[0]['6구간'] = _.filter(res.data.realNoticeList, function(o) {
                        return o['opbdPrargRt'] >= 101.0;
                    }).length;

                    this.loading = false;
                }).catch(error => console.log(error));
            },
            countingType1(id, number){
                const element = document.querySelector(id)
                if(number == 0){
                    element.innerHTML = '0';
                } else {
                    /* 입력한 숫자를 33번에 걸쳐 0부터 올림. */
                    const each = Math.ceil(number/33);
                    let time = 0

                    for(let i=0; i<=number; i+=each){
                        setTimeout(() => {
                            element.innerHTML = i;
                        }, 35*time);
                        /* 딱 떨어지지 않는 숫자를 마지막에 그 숫자로 만들어주기 위함 */
                        if(i+each>number){
                            setTimeout(() => {
                                element.innerHTML = number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                            }, 35*(time+1));
                        }
                        time++;
                    }
                }
            },

            /* 한자리씩 순서대로 카운트업 */
            countingType2(id, number){
                const element = document.querySelector(id);

                /* 각자리로 나누어 올리기 위해 쪼개려는 것 */
                let current = new Array(number.length).fill(0);
                let arrayNum = String(number).split('').reverse();

                /* 올라갈 때 걸리는 시간 균등하게 하기위해. 단, 최대 0.06초씩은 되도록 */
                const total = arrayNum.reduce((pre, cur)=>Number(pre)+Number(cur));
                const eachTime = Math.min(1000/total, 150);

                let time = 0
                for(let j=0; j<arrayNum.length; j++){
                    for(let i = 0; i<=arrayNum[j]; i++){
                        setTimeout(()=>{
                            current[arrayNum.length-j-1]=i;
                            element.innerHTML = current.join('');
                        }, eachTime*(time + i))
                    }
                    time += arrayNum[j]-1;
                }
            },

            /* 각 자리별로 따로 카운트업 */
            countingType3(id, number){
                const element = document.querySelector(id);

                /* 각자리로 나누어 올리기 위해 쪼개려는 것 */
                let current = [];
                let arrayNum = String(number).split('');

                for(let i = 0; i<10; i++){
                    setTimeout(()=>{
                        for(let j = 0; j<arrayNum.length; j++){
                            if(i<=arrayNum[j]){
                                current.push(i);
                            } else{
                                current.push(arrayNum[j]);
                            }
                        }
                        element.innerHTML = current.join('');
                        current = [];
                    }, 150*i)
                }
            },
            /* 각 자리별로 따로 카운트업 */
            countingType4(id, number){
                $({ val : 0/*시작숫자*/ }).animate({ val : number/*종료숫자*/ }, {
                    duration: 1500,
                    step: function() {
                        var num = (Math.floor(this.val)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                        $(id).text(num);
                    },
                    complete: function() {
                        var num = (Math.floor(this.val)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                        $(id).text(num);
                    }
                });
            },
            getCustRecordStat(minAsRt, callback) {
                // const config = {
                //   headers: {
                //     'Content-Type': 'application/json',
                //     'auth-token': 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJjbXBObSI6IuynhOu5hOuTnDEiLCJyb2xlIjoiUk9MRV9VU0VSIiwiaXNzIjoiamluYmlkIiwiaWQiOjEsImV4cCI6MTcxMDMxNDkzNSwiY3VzdElkTm0iOiIwMDAwMDAwMDAxIn0.5AnTZAttUtNk5hyFBitXukSUmIWIf9RWl8l_59KudKc', //localStorage.getItem("authToken"),
                //     'refresh-token': 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJjbXBObSI6IuynhOu5hOuTnDEiLCJyb2xlIjoiUk9MRV9VU0VSIiwiaXNzIjoiamluYmlkIiwiaWQiOjEsImV4cCI6MTcxMjg4NTMzNSwiY3VzdElkTm0iOiIwMDAwMDAwMDAxIn0.G8FlXptiTNfX0jcdMxFcfeLDKmWpWIsmIm3bCimAV5M' // localStorage.getItem("refreshToken"),
                //   },
                // };

                this.searchParam.strtDate = this.$moment(this.strtDate).format('YYYY-MM-DD');
                this.searchParam.finDate = this.$moment(this.finDate).format('YYYY-MM-DD');

                var postData = _.defaults({
                    pageUnit: 9999,
                    pageIndex: 0,
                    strtRecordIntex: 0,
                    //strtDate: this.$moment().format('YYYYMMDD HH:mm'),
                    //finDate: this.$moment().format('YYYYMMDD HH:mm'),
                    //custBizId: custInfo.custBizList[0].id,
                    bizNo: this.bizNo,
                    findText: this.findText,
                    // orderBySt: 'bddprFinDt',
                    // notiOpbdListYn: 'Y',
                    minAsRt: minAsRt
                }, this.searchParam);

                //console.log(this.$moment(this.searchParam.strtDate).format('YYYYMMDD HH:mm'));

                mock.notiTable.noti = [];

                this.loading = true;
                this.$http.post('/api/custBiz/getCustRecordStat', postData, this.config).then(res => {
                    const sum = res.data.custRecordStatList[0]['1구간']+res.data.custRecordStatList[0]['2구간']+res.data.custRecordStatList[0]['3구간']+res.data.custRecordStatList[0]['4구간']+res.data.custRecordStatList[0]['5구간']+res.data.custRecordStatList[0]['6구간'];
                    this.sum = this.sum + sum;

                    if (minAsRt == 97) {
                        this.custRecordStatList = _.cloneDeep(res.data.custRecordStatList);

                        // 예가 비율 - 내 지점 비율
                        var tmpArray = [];

                        for (var idx=0 ; idx<this.custRecordStatList.length ; idx++) {
                            const v = this.custRecordStatList[idx];

                            if (idx < 2) {

                                //const a = v['1구간'];
                                this.chartData.datasets[idx].data = [_.round(v['1구간']/sum*100,2).toFixed(2),_.round(v['2구간']/sum*100,2).toFixed(2),_.round(v['3구간']/sum*100,2).toFixed(2),_.round(v['4구간']/sum*100,2).toFixed(2),_.round(v['5구간']/sum*100,2).toFixed(2),_.round(v['6구간']/sum*100,2).toFixed(2)];

                                this.custRecordStatList[idx]['합계'] = v['1구간']+v['2구간']+v['3구간']+v['4구간']+v['5구간']+v['6구간'];

                                if (idx == 0) {
                                    // 백분율 차이 (예가 - 내지점)
                                    tmpArray[0] = _.round(v['1구간']/sum*100,2).toFixed(2);
                                    tmpArray[1] = _.round(v['2구간']/sum*100,2).toFixed(2);
                                    tmpArray[2] = _.round(v['3구간']/sum*100,2).toFixed(2);
                                    tmpArray[3] = _.round(v['4구간']/sum*100,2).toFixed(2);
                                    tmpArray[4] = _.round(v['5구간']/sum*100,2).toFixed(2);
                                    tmpArray[5] = _.round(v['6구간']/sum*100,2).toFixed(2);

                                    this.stdrd100yega.push(((Number(tmpArray[3]) + Number(tmpArray[4]) + Number(tmpArray[5]))).toFixed(2));
                                    this.stdrd100yega.push(((Number(tmpArray[0]) + Number(tmpArray[1]) + Number(tmpArray[2]))).toFixed(2));
                                }

                                if (idx == 1) { // 내 투찰율 저장
                                    // 투찰율 백분율 목록
                                    var groupList = _.keys(v);
                                    for (var i=0 ; i<groupList.length ; i++) {
                                        var o = groupList[i];

                                        if (o != '합계' && o != '사정율(예가)') {
                                            this.custRecordPercentList[o.substring(0,1)-1]=_.round(v[o]/sum*100,2).toFixed(2);
                                            //this.custRecordPercentList[0] = _.round(v[o]/sum*100,2).toFixed(2);
                                        }
                                    }

                                    this.stdrd100bid.push(((Number(_.round(v['4구간']/sum*100,2).toFixed(2)) + Number(_.round(v['5구간']/sum*100,2).toFixed(2)) + Number(_.round(v['6구간']/sum*100,2).toFixed(2)))).toFixed(2));
                                    this.stdrd100bid.push(((Number(_.round(v['1구간']/sum*100,2).toFixed(2)) + Number(_.round(v['2구간']/sum*100,2).toFixed(2)) + Number(_.round(v['3구간']/sum*100,2).toFixed(2)))).toFixed(2));
                                }

                                v['1구간'] = v['1구간'] +' (' + _.round(v['1구간']/sum*100,2).toFixed(2) + ")";
                                v['2구간'] = v['2구간'] +' (' + _.round(v['2구간']/sum*100,2).toFixed(2) + ")";
                                v['3구간'] = v['3구간'] +' (' + _.round(v['3구간']/sum*100,2).toFixed(2) + ")";
                                v['4구간'] = v['4구간'] +' (' + _.round(v['4구간']/sum*100,2).toFixed(2) + ")";
                                v['5구간'] = v['5구간'] +' (' + _.round(v['5구간']/sum*100,2).toFixed(2) + ")";
                                v['6구간'] = v['6구간'] +' (' + _.round(v['6구간']/sum*100,2).toFixed(2) + ")";

                                //console.log('1'+_.round(v['1구간']/sum*100,2).toFixed(2));
                                //this.chartData.datasets[idx].data = [res.data.custRecordStatList[idx]['1구간'],res.data.custRecordStatList[idx]['2구간'],res.data.custRecordStatList[idx]['3구간'],res.data.custRecordStatList[idx]['4구간'],res.data.custRecordStatList[idx]['5구간'],res.data.custRecordStatList[idx]['6구간']];
                            } else {
                                this.custRecordStatList3[0]['1구간'] = this.custRecordStatList[idx]['1구간'];
                                this.custRecordStatList3[0]['2구간'] = this.custRecordStatList[idx]['2구간'];
                                this.custRecordStatList3[0]['3구간'] = this.custRecordStatList[idx]['3구간'];
                                this.custRecordStatList3[0]['4구간'] = this.custRecordStatList[idx]['4구간'];
                                this.custRecordStatList3[0]['5구간'] = this.custRecordStatList[idx]['5구간'];
                                this.custRecordStatList3[0]['6구간'] = this.custRecordStatList[idx]['6구간'];
                                this.custRecordStatList3[0]['합계'] = this.custRecordStatList[idx]['1구간']+this.custRecordStatList[idx]['2구간']+this.custRecordStatList[idx]['3구간']+this.custRecordStatList[idx]['4구간']+this.custRecordStatList[idx]['5구간']+this.custRecordStatList[idx]['6구간'];
                            }
                        }


                        console.log(this.stdrd100yega);
                        console.log(this.stdrd100bid);

                        delete this.custRecordStatList[2];
//console.log(this.chartData.datasets);
                        //delete this.custRecordStatList[2];
                        // _.forEach(this.custRecordStatList, function(v, idx) {
                        //     console.log(idx);
                        //     console.log(v['1구간']);
                        //     //const a = v['1구간'];
                        //   v['1구간'] = v['1구간'] +' (' + _.round(v['1구간']/sum*100,2).toFixed(2) + ")";
                        //   v['2구간'] = v['2구간'] +' (' + _.round(v['2구간']/sum*100,2).toFixed(2) + ")";
                        //   v['3구간'] = v['3구간'] +' (' + _.round(v['3구간']/sum*100,2).toFixed(2) + ")";
                        //   v['4구간'] = v['4구간'] +' (' + _.round(v['4구간']/sum*100,2).toFixed(2) + ")";
                        //   v['5구간'] = v['5구간'] +' (' + _.round(v['5구간']/sum*100,2).toFixed(2) + ")";
                        //   v['6구간'] = v['6구간'] +' (' + _.round(v['6구간']/sum*100,2).toFixed(2) + ")";
                        //   //this.chartData.datasets[idx].data = [res.data.custRecordStatList[idx]['1구간'],res.data.custRecordStatList[idx]['2구간'],3,4,5,6];
                        // });

                        // 가장 많이 투찰한 구간 순위
                        var tempCustRecordPercentList = _.cloneDeep(this.custRecordPercentList);
                        let num = this.custRecordPercentList.slice().sort((a,b) => b - a);
                        this.groupBidRank = _.map(num, function(o) {
                            return _.findIndex(tempCustRecordPercentList, function(oo) {
                                return oo == o;
                            })+1;
                        });
                        //this.groupBidRank = this.custRecordPercentList.map(el => num.indexOf(el) + 1);
                        //console.log(this.groupBidRank);

                        var tmpRankArr = _.sortBy(this.custRecordPercentList, [function(o) { return Number(o);}]).reverse();
                        var tmpRank = tmpRankArr[0];
                        this.topGroup = _.findIndex(this.custRecordPercentList, function(o){
                            return o == tmpRank;
                        })+1;
                        this.topPercent = tmpRank;

                        this.cmpNm = res.data.custBizInfo.corpNm;

                        this.line1 = [res.data.custBizInfo.corpNm + " 대표님의 최근 1년 동안의 투찰 활동에 대한 리포트를 제공해드립니다.\n\n고객님의 투찰 성향을 파악하였으며, 아래 자료를 참고하여 앞으로의 분석 방향에 참고하시길 바랍니다."];
                    } else {
                        this.custRecordStatList2 = _.cloneDeep(res.data.custRecordStatList);

                        for (var j=0 ; j<this.custRecordStatList2.length ; j++) {
                            const v2 = this.custRecordStatList2[j];
                            if (j < 2) {
                                this.chartData2.datasets[j].data = [_.round(v2['1구간']/sum*100,2).toFixed(2),_.round(v2['2구간']/sum*100,2).toFixed(2),_.round(v2['3구간']/sum*100,2).toFixed(2),_.round(v2['4구간']/sum*100,2).toFixed(2),_.round(v2['5구간']/sum*100,2).toFixed(2),_.round(v2['6구간']/sum*100,2).toFixed(2)];

                                if (j == 1) { // 내 투찰율 저장
                                    // 투찰율 백분율 목록
                                    var groupList2 = _.keys(v2);
                                    for (var k=0 ; k<groupList2.length ; k++) {
                                        var o2 = groupList2[k];

                                        if (o2 != '합계' && o2 != '사정율(예가)') {
                                            this.custRecordPercentList2[o2.substring(0,1)-1]=_.round(v2[o2]/sum*100,2).toFixed(2);
                                            //this.custRecordPercentList[0] = _.round(v[o]/sum*100,2).toFixed(2);
                                        }
                                    }
                                }

                            }
                            this.custRecordStatList2[j]['합계'] = this.custRecordStatList2[j]['1구간']+
                                this.custRecordStatList2[j]['2구간']+
                                this.custRecordStatList2[j]['3구간']+
                                this.custRecordStatList2[j]['4구간']+
                                this.custRecordStatList2[j]['5구간']+
                                this.custRecordStatList2[j]['6구간'];
                        }

                        // 가장 많이 투찰한 구간 순위2
                        var tempCustRecordPercentList2 = _.cloneDeep(this.custRecordPercentList2);

                        let num = this.custRecordPercentList2.slice().sort((a,b) => b - a);
                        this.groupBidRank_98 = _.map(num, function(o) {
                            return _.findIndex(tempCustRecordPercentList2, function(oo) {
                                return oo == o;
                            })+1;
                        });
                        //this.groupBidRank = this.custRecordPercentList.map(el => num.indexOf(el) + 1);
                        //console.log(this.groupBidRank);

                        var tmpRankArr2 = _.sortBy(this.custRecordPercentList2, [function(o) { return Number(o);}]).reverse();
                        var tmpRank2 = tmpRankArr2[0];
                        this.topGroup_98 = _.findIndex(this.custRecordPercentList2, function(o){
                            return o == tmpRank2;
                        })+1;

                        delete this.custRecordStatList2[2];
                    }
                    this.loading = false;
                    this.isComplete = true;
                    //ChartJS.update();
                    callback(this.topGroup, this.topGroup_98);
                })
                    .catch(error => console.log(error));
            },
            getCustStatTopPoint(topGroup, minAsRt) { // 가장 많이 투찰한 지점

                var postData = _.defaults({
                    bizNo: this.bizNo,
                    topGroup: topGroup.toString(),
                    minAsRt: minAsRt
                }, this.searchParam);

                if (minAsRt == 98) {
                    this.$http.post('/api/custBiz/getCustRecordTopPoint', postData, this.config).then(res => {
                        this.topPoint_98 = res.data.custRecordTopPoint;
                    })
                        .catch(error => console.log(error));
                } else {
                    this.$http.post('/api/custBiz/getCustRecordTopPoint', postData, this.config).then(res => {
                        this.topPoint = res.data.custRecordTopPoint;
                    })
                        .catch(error => console.log(error));
                }

            },
            getCustStatNcstPrargList(topGroup, callback) {

                var postData = _.defaults({
                    bizNo: this.bizNo,
                }, this.searchParam);

                this.$http.post('/api/custBiz/getCustStatNcstPrargList', postData, this.config).then(res => {

                    this.bidTotal = res.data.custStatNcstPrargList.length;

                    //console.log(res.data.custStatNcstPrargList);

                    this.avgCptnRt = Math.round(_.sumBy(res.data.custStatNcstPrargList, function(o) {
                        return o.bddprPrtcCmpCnt;
                    }) / this.bidTotal);

                    const cmpRankList = _.filter(res.data.custStatNcstPrargList, function(o) {
                        return o.cmpRank == 1;
                    });

                    this.cmpRankListCnt = cmpRankList.length;

                    this.totalSBidAmt = _.sumBy(cmpRankList, function(o) {
                        return Number(o.bddprAmt);
                    });

                    //console.log((_.map(_.groupBy(res.data.custStatNcstPrargList, (item) => Math.floor(Number(item.calBddprRt)*10)/10), (group) => ({...group[0], count: group.length}))));

                    // 가장 많이 투찰한 포인트 산출
                    var tmpGroupList = _.map(res.data.custStatNcstPrargList, function(o) {
                        //console.log(o.calBddprRt);
                        //console.log( Number(o.calBddprRt).toFixed(1));
                        //console.log( Math.floor(Number(o.calBddprRt)*10)/10);
                        var obj = o;
                        o.calBddprRt = Math.floor(Number(o.calBddprRt)*10)/10;

                        return obj;
                    });

                    var tmpGroupList2 = _.cloneDeep(tmpGroupList);

                    tmpGroupList = _.filter(tmpGroupList, function(o) {

                        switch (topGroup) {
                            case 1:
                                return o.calBddprRt <= 99.9999;
                            case 2:
                                return o.calBddprRt >= 99.5 && o.calBddprRt <= 99.9999;
                            case 3:
                                return o.calBddprRt >= 99.5 && o.calBddprRt <= 99.9999;
                            case 4:
                                return o.calBddprRt >= 100.0 && o.calBddprRt <= 100.4999;
                            case 5:
                                return o.calBddprRt >= 100.5 && o.calBddprRt <= 100.9999;
                            case 6:
                                return o.calBddprRt >= 101.0;
                            default:
                        }

                        // if (topGroup == 3) {
                        //     return o.calBddprRt >= 99.5 && o.calBddprRt <= 99.9999;
                        // }
                        //return o;
                    });

                    tmpGroupList = _.map(_.groupBy(tmpGroupList, 'calBddprRt'),function(o) {
                        o.groupCnt = o.length;
                        return o;
                    });

                    tmpGroupList = _.sortBy(tmpGroupList, 'groupCnt').reverse();

                    this.custBidRt = (tmpGroupList[0]['groupCnt'] / this.sum * 100).toFixed(2);

                    // 실제 예가 출현 비율
                    this.realOpbdPrargRt = (_.filter(tmpGroupList2, function(o) {
                        //console.log(Math.floor(Number(o.opbdPrargRt)*10)/10);
                        if (Math.floor(Number(o.opbdPrargRt)*10)/10 == tmpGroupList[0][0]['calBddprRt'] ) {
                            return o;
                        }
                        //console.log( Math.floor(Number(o.opbdPrargRt)*10)/10);
                        //console.log( tmpGroupList[0][0]['calBddprRt']);
                        //console.log( Math.floor(Number(o.opbdPrargRt)*10)/10 == tmpGroupList[0][0]['calBddprRt']);
                    }).length / this.sum * 100).toFixed(2) ;

                    callback(this.bidTotal, this.cmpRankListCnt, this.totalSBidAmt);
                })
                    .catch(error => console.log(error));
            },
        }
    }
</script>