<template>
    <div>
<!--        <div v-for="bList in boardList" :key="bList.id">-->
<!--            {{bList.boardTitle}} //  {{bList.firstInputDt}}-->
<!--&lt;!&ndash;            <img :src="bList.filePathNm+bList.serverFileNm">&ndash;&gt;-->
<!--&lt;!&ndash;            <img :src="$parent.BASE_URL+`${bList.filePathNm}`+`${bList.serverFileNm}`">&ndash;&gt;-->
<!--            <p v-dompurify-html="bList.boardContents"></p>-->
<!--            <button type="button" class="sy-btn sy-btn-xs sy-btn-icon sy-btn-icon-only" @click="fnFileDown(boardAttach)" v-for="boardAttach in bList.boardAttachList" :key="boardAttach.id">-->
<!--                <i class="sy-icon">{{boardAttach}}</i>-->
<!--            </button>-->

<!--            <br><br><br>-->
<!--        </div>-->
<!--        <button class="btn-basic btn-line-cb analist&#45;&#45;btn&#45;&#45;more" v-show="boardList.length < boardListCnt" @click="currentPage++; fnSearchBoard();"><span>{{boardListCnt - boardList.length > itemsPerPage ? itemsPerPage : boardListCnt - boardList.length}}개 더 보기↓</span></button>-->
        <div class="info-center">
            <div class="info-center--top">
                <h1 class="h1 bt-f30">전자입찰과 관련된 소식을 비드톡톡이 생생하게 전해드려요</h1>
                <h2 class="h2 bt-f22">#전자입찰 #AI분석 #맞춤공고 #나라장터 #조달청</h2>
            </div>
            <div class="info-center--bottom wrap analysis">
                <div class="bt--filters bt--filter--rp info-center--filters">
                    <a href="javascript:void(0);" class="bt--filters__item" @click="$router.push('/tokBlog')">톡블로그</a>
                    <a href="javascript:void(0);" class="bt--filters__item" @click="$router.push('/tokG2BNotice')">발주처 공지사항</a>
                    <a href="javascript:void(0);" class="bt--filters__item active" @click="$router.push('/tokG2BCfmtUndwrt')">적격심사</a>
                </div>

                <!-- 발주처 공지사항  -->
                <div class="search-banner info-center--srch-bnr">
                    <div class="search-banner__box">
                        <p class="inquiry__total txt__title analysis__total" v-if="boardListCnt > 0">총<span>{{boardListCnt}}</span>건</p>
                    </div>
                    <div class="bt-srch--rev search-banner__box srch-ban__box">
                        <div class="select-box">
                            <button class="select-box__sel">제목</button>
                            <ul class="select-box__list">
                                <li><button type="button">제목</button></li>
                                <li><button type="button">내용</button></li>
                            </ul>
                        </div>
                        <div class="search info-center--srch">
                            <input type="text" placeholder="" v-model="searchParam.findText" @keyup.enter="fnSearchBoard(0)">
                            <!--                <button type="reset" class="btn__cancel">취소</button>-->
                        </div>
                    </div>
                </div>
                <div class="info-center--grid bt--grid--grey info-center__acc--wrap">
                    <ul class="gr bt-f-ac bt--grid bt--grid--grey info-center__acc--subject">
                        <li class="col-1 gh gd num">번호</li>
                        <li class="col-2 gh gd">분류</li>
                        <li class="col-3 gh gd">제목</li>
                        <li class="col-4 gh gd">첨부파일</li>
                        <li class="col-5 gh gd date">날짜</li>
                    </ul>
                    <div class="accordion__item" v-for="(bList, idx) in boardList" :key="bList.id">
                        <ul class="gr bt-f-ac accordion__head info-center__acc--head">
                            <li class="col-1 gd num" @click="dropDownMenu($event)">{{boardListCnt - idx}}</li>
                            <li class="col-2 gd">{{bList.categoryNm}}</li>
                            <li class="col-3 gd tit" @click="dropDownMenu($event)">{{bList.boardTitle}}</li>
                            <li class="col-4 gd atc-file">
                                <!--ic_hwp,ic_pdf,ic_zip,ic_etc-->>
                                <a href="#" onclick="return false" v-for="boardAttach in bList.boardAttachList" :key="boardAttach.id" @click="fnFileDown(boardAttach)" :title="boardAttach.originFileNm">
                                    <i :class="boardAttach.attachType == 'hwp' ? 'ic_hwp' : boardAttach.attachType == 'pdf' ? 'ic_pdf' : boardAttach.attachType == 'zip' ? 'ic_zip' : 'ic_etc'">df</i>{{boardAttach.attachType}}
                                </a>
                            </li>
                            <li class="col-5 gd date">{{bList.firstInputDt}}</li>
                        </ul>
                        <div class="accordion__body info-center__acc--body ">
                            <ul class="gr bt-f-strch">
                                <li class="gd sbj">제목</li>
                                <li class="gd conts tit">{{bList.boardTitle}}</li>
                            </ul>
                            <ul class="gr bt-f-strch">
                                <li class="gd sbj">내용</li>
                                <li class="gd conts ful impor-conts">
                                    <div style="width: 1000px; height: 80px;" v-html="bList.boardContents">
                                    </div>

                                </li>
                            </ul>
                            <ul class="gr bt-f-strch analysis-detail__container body atc-file">
                                <li class="gd sbj">첨부파일</li>
                                <ul class="gd conts">
                                    <li class="file-download__item" v-for="boardAttach in bList.boardAttachList" :key="boardAttach.id">
                                        <a href="#" onclick="return false" class="file-download--view" @click="fnFileDown(boardAttach)">{{boardAttach.originFileNm+"."+boardAttach.attachType}}</a>
                                        <a href="#" onclick="return false" class="file-download--btn" @click="fnFileDown(boardAttach)">
                                            <i class="ic--download"></i>
                                        </a>
                                    </li>
                                    <!--                    <li class="file-download__item">-->
                                    <!--                      <a href="#" onclick="return false"-->
                                    <!--                         class="file-download&#45;&#45;view">20230113127-00_1673586883593_공사입찰설명서-부산대학교 양산캠퍼스-->
                                    <!--                        천연물안전관리원 신축 기계설비 공사-220520900.hwp</a>-->
                                    <!--                      <a href="#" onclick="return false" class="file-download&#45;&#45;btn"><i-->
                                    <!--                              class="ic&#45;&#45;download"></i></a>-->
                                    <!--                    </li>-->
                                    <!--                    <li class="file-download__item">-->
                                    <!--                      <a href="#" onclick="return false" class="file-download&#45;&#45;view">1-3. 공사 시방서.pdf</a>-->
                                    <!--                      <a href="#" onclick="return false" class="file-download&#45;&#45;btn"><i-->
                                    <!--                              class="ic&#45;&#45;download"></i></a>-->
                                    <!--                    </li>-->
                                    <!--                    <li class="file-download__item">-->
                                    <!--                      <a href="#" onclick="return false" class="file-download&#45;&#45;view">1-4. 인천계약특수조건-->
                                    <!--                        개정(9차).pdf</a>-->
                                    <!--                      <a href="#" onclick="return false" class="file-download&#45;&#45;btn"><i-->
                                    <!--                              class="ic&#45;&#45;download"></i></a>-->
                                    <!--                    </li>-->
                                    <!--                    <li class="file-download__item">-->
                                    <!--                      <a href="#" onclick="return false" class="file-download&#45;&#45;view">1-6. 품질보증시방서(공시분야) 개정전문(8차-->
                                    <!--                        개정).pdf</a>-->
                                    <!--                      <a href="#" onclick="return false" class="file-download&#45;&#45;btn"><i-->
                                    <!--                              class="ic&#45;&#45;download"></i></a>-->
                                    <!--                    </li>-->
                                </ul>
                            </ul>
                        </div>
                    </div>
                    <button class="btn-basic btn-line-cb bt--btn--more" v-if="boardList.length < boardListCnt" @click="currentPage++; fnSearchBoard(1);"><span>{{boardListCnt - boardList.length > itemsPerPage ? itemsPerPage : boardListCnt - boardList.length}}개 더 보기↓</span></button>
                    <div class="bt__list--fin active" v-if="currentPage != 1 && boardListCnt - boardList.length == 0"><span class="bt__list--fin--txt">모두 확인하셨습니다.</span></div>
                </div>
                <!-- /발주처 공지사항  -->
            </div>
        </div>
    </div>
</template>

<script>
    import * as _ from 'lodash';

    export default {
        name: "TokG2BNotice",
        metaInfo: {
            title: '[비드톡톡 발주처 적격심사] - 전자입찰 나라장터(g2b) 공고 투찰 입찰 낙찰',
            link: [{ rel: "canonical", href: 'https://www.bidtok.co.kr/tokBlog' }],
            meta: [
                { vmid: "description", name: 'description', content: '비드톡톡의 발주처 적격심사를 확인해 보세요! 전자입찰의 정보, 데이터, 새로운 소식, 나라장터 소식 등 확인하실 수 있습니다.' },
                { vmid: "og:title", property: 'og:title', content: '[비드톡톡 블로그] - 전자입찰 나라장터(g2b) 공고 투찰 입찰 낙찰' },
                { vmid: "og:url", property: 'og:url', content: 'https://www.bidtok.co.kr/' },
                { vmid: "og:description", property: 'og:description', content: '비드톡톡의 발주처 적격심사를 확인해 보세요! 전자입찰의 정보, 데이터, 새로운 소식, 나라장터 소식 등 확인하실 수 있습니다.' },
                { vmid: "og:image", property: "og:image" , content : "favicon.ico"},
            ]
        },
        data(){
            return{
                boardList : [],
                boardListCnt: 0,
                currentPage: 1,
                itemsPerPage: 30,
                searchParam: {
                    categoryCd : "100" // 발주처 종류
                    ,findText : "" // 검색
                    ,kword : "boardTitle" // 검색 select
                },
                //page: 0,
                //pageUnit: 30
                befMenu: ''
            }
        },

        watch: {
            currentPage() {
                //window.scrollTo({ top: 0, behavior: 'smooth' });
            }
        },

        computed: {

            pageNumbers() {
                const maxVisiblePages = window.innerWidth <= 800 ? 5 : 10;
                const firstVisiblePage = Math.max(1, this.currentPage - Math.floor(maxVisiblePages / 2));
                const lastVisiblePage = Math.min(this.totalPages, firstVisiblePage + maxVisiblePages - 1);
                return Array.from({ length: lastVisiblePage - firstVisiblePage + 1 }, (_, i) => firstVisiblePage + i);
            },

            //페이징 시작
            displayedBoardList() {


                //최상단고정 세팅
                // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                const sortedList = this.boardList.sort((a, b) => {
                    if (a.topNoticeYn === 'Y' && b.topNoticeYn !== 'Y') {
                        return -1;
                    } else if (a.topNoticeYn !== 'Y' && b.topNoticeYn === 'Y') {
                        return 1;
                    }
                    return 0;
                });

                const startIndex = (this.currentPage - 1) * this.itemsPerPage;
                const endIndex = startIndex + this.itemsPerPage;
                return sortedList.slice(startIndex, endIndex);

            },

            totalPages() {
                return Math.ceil(this.blogList.length / this.itemsPerPage);
            },

            hasPreviousPage() {
                return this.currentPage > 1;
            },

            hasNextPage() {
                return this.currentPage < this.totalPages;
            },
            //페이징 끝

        },

        methods: {
            // 아코디언 메뉴 상세보기
            dropDownMenu(event) {
                var currentTarget = event.target.parentElement;

                if (this.befMenu != '') {
                    this.befMenu.classList.remove('active');
                }
                if (this.befMenu == currentTarget) {
                    currentTarget.classList.remove('active');
                    this.befMenu = '';
                } else {
                    this.befMenu = event.target.parentElement;
                    this.befMenu.classList.toggle('active');
                }
            },
            //오늘날짜 확인
            isToday(date) {
                const today = new Date();
                const inputDate = new Date(date);
                return (
                    inputDate.getFullYear() === today.getFullYear() &&
                    inputDate.getMonth() === today.getMonth() &&
                    inputDate.getDate() === today.getDate()
                );
            },

            fnSearchBoard(type){
                if (type == 0) {
                    this.currentPage = 1;
                }

                var params = _.defaults({
                    currentPage: this.currentPage,
                    pageSize: this.itemsPerPage,
                    //strtRecordIntex: (this.currentPage - 1) * this.itemsPerPage,
                    boardCd: 0, //적격심사
                }, this.searchParam);

                this.$http.post('/api/board/G2BBoardList',params)
                // eslint-disable-next-line no-unused-vars
                    .then(res => {
                        var tempBoardList = res.data.boardList;
                        this.boardListCnt = res.data.boardListCnt;

                        if (type == 1) {
                            for (var i in tempBoardList) {
                                this.boardList.push(tempBoardList[i]);
                            }
                        } else {
                            this.boardList = tempBoardList;
                        }

                        this.boardList.forEach(e => {
                            e.firstInputDt = this.$moment(e.firstInputDt).format('YYYY-MM-DD');
                            e.boardContents = e.boardContents.replaceAll("\n", "<br />");
                        });
                    })
                    .catch(error => {console.log(error);}
                    );

            },
            goBlogDetail(id) {
                this.$router.push('/tokBlogDetail/'+id);
            },
            fnFileDown(file){
                const link = document.createElement('a');
                link.href = this.$parent.BASE_URL+'/api/board/boardFileDown/' + file.id;
                link.target = '_blank';
                //link.download = file.originFileNm + "." + file.attachType;
                link.click();

                // var linkObj = angular.element('<a href="' + Config.serverURL+ '/board/boardFileDown/' + file.id +'" target="_blank" download></a>');
                //
                // angular.element('body').append(linkObj);
                // linkObj[0].click();
                // linkObj.remove();
            },

        },
        created() {

        },

        mounted() {
            this.fnSearchBoard();

            // 페이지네이션 클릭 이벤트
            let pagenation = document.querySelectorAll('.pagination > ul > li');
            pagenation.forEach(function (element) {
                element.addEventListener('click', function(){
                    for (let i = 0; i < pagenation.length; i++) {
                        pagenation[i].classList.remove('active');
                    }
                    element.classList.add('active');
                    window.scroll({top: 0, left: 0,behavior: 'smooth'});
                });
            });

            const headerNoticeElement = document.getElementById("tokStory");
            if (headerNoticeElement) {
                headerNoticeElement.classList.add("active");
            }

            /* 셀렉트박스 */

            const selectBoxes = document.querySelectorAll('.select-box__sel');

            selectBoxes.forEach(selectBox => {
                const selBoxParent = selectBox.parentElement;
                const selBoxDisable = selBoxParent.classList.contains('disable');

                if (!selBoxDisable) {
                    /* 선택창 켜짐 */
                    selectBox.addEventListener('click', () => {
                        selectBox.classList.toggle('on');
                    });
                    /* /선택창 켜짐 */

                    /* 셀렉트박스 활성화 중 다른 곳 클릭시 꺼짐 */
                    document.addEventListener('click', (event) => {
                        const target = event.target;
                        if (!selectBox.contains(target)) {
                            selectBox.classList.remove('on');
                        }
                    });
                    /* /셀렉트박스 활성화 중 다른 곳 클릭시 꺼짐 */

                    const selectBoxItems = selectBox.closest('.select-box').querySelectorAll('.select-box__list');

                    if (selectBoxItems.length > 0) {
                        selectBoxItems.forEach(selectBoxItem => {
                            selectBoxItem.addEventListener('click', (event) => {
                                const selectedText = event.target.innerText;

                                if (selectedText == "제목") {
                                    this.searchParam.kword = "boardTitle";
                                } else if (selectedText == "내용") {
                                    this.searchParam.kword = "boardContents";
                                } else if (selectedText == "분류") {
                                    this.searchParam.kword = "boardCategory";
                                }
                                selectBox.innerText = selectedText;
                            });
                        });
                    }
                }
            });

            let selDisItems = document.querySelectorAll('.select');

            // 클래스 변경을 감지하는 MutationObserver 생성
            const observer02 = new MutationObserver((mutationsList) => {
                for(let mutation of mutationsList) {
                    // 'disabled' 속성 변경이 발생한 경우만 처리
                    if (mutation.type === 'attributes' && mutation.attributeName === 'disabled') {
                        if (mutation.target.tagName.toLowerCase() === 'select') {
                            let parent = mutation.target.parentElement;

                            if (mutation.target.disabled) {
                                parent.classList.add('disable');
                            } else {
                                parent.classList.remove('disable');
                            }
                        }
                    }
                }
            });

            // 각 select 요소에 대해 observer를 연결
            selDisItems.forEach(function(select) {
                observer02.observe(select, { attributes: true });
            });
        }
    }


</script>



<!--<style src="./TokStory.scss" lang="scss" scoped />-->