<template>
    <div>
        <p>Naver Login Access Pages</p>
    </div>
</template>

<script>
    export default {
        mounted() {
            const naver_id_login =  new window.naver_id_login("gyKhMT5uLjW6l8AmB6H8");
            console.log("access token", naver_id_login.getAccessToken()); // 정상적 로그인이 된 경우 access token값 출력

            var params = {
                "accessToken": naver_id_login.getAccessToken(),
                //"fbToken": params["fbToken"].toString() // firebase token
            };

            this.$http.post('/api/naver/webLogin',params)
                .then(res => {
                    localStorage.setItem("authToken",res.data.authToken);
                    localStorage.setItem("refreshToken",res.data.refreshToken);
                    localStorage.setItem("custInfo",JSON.stringify(res.data.cust));

                    this.custInfo = JSON.parse(localStorage.getItem("custInfo"));

                    this.$socket.emit('login',{
                        id: this.custInfo.id,
                        alias: this.custInfo.cmpNm

                    });

                    opener.location.replace("/home");
                    window.close();
                    // this.$router.replace('/analysis');
                })
                .catch(error => {
                        console.log(error);
                    }
                );
        },
    };
</script>