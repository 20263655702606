<template>
  <div style="text-align: center">
    <a href="https://bidtok.co.kr" class="link__download main__visual--link pc" style="width: 30%; margin: 1.25rem auto;">비드톡톡 바로가기</a>
    <div class="">
      <img src="@/assets/survey/leaflet1.jpg" style="width: 80%; height: auto; margin: 0 auto 2rem;" alt="리플렛">
      <img src="@/assets/survey/leaflet2.jpg" style="width: 80%; height: auto;  margin: 0 auto 2rem;;" alt="리플렛">
    </div>
  </div>
</template>
<script>
export default {
  name: "g2bNoti",
  metaInfo: {
      title: '비드톡톡',
      link: [{rel: "canonical", href: 'https://www.bidtok.co.kr/g2bNoti'}],
      meta: [
          { charset: 'utf-8' },
          { name: 'description', content: 'AI 전자입찰 AI분석 입찰 나라장터 조달청' },
          { name: 'keywords', content: '전자입찰, 나라장터, AI분석, 경쟁사분석, 시설, 용역, 물품, 낙찰, 자재, 제안요청서, 입찰공고, 개찰결과' },
          { property: 'og:title', content: '나라장터 AI 전자입찰 비드톡톡'},
          { property: 'og:type', content: 'article'},
          { property: 'og:url', content: 'https://www.bidtok.co.kr'},
          { property: 'og:description', content: 'AI 전자입찰 AI분석 입찰 나라장터 조달청, 나라장터(g2b), 조달청, 조달업체, 입찰공고, 입찰정보, 시설 공고, 개찰결과, 최종낙찰'}
      ]
  },
  data() {
    return {
    }
  },
  methods: {
  }
};
</script>