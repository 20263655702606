<template>
  <!-- redirect -->
  <div class="redirect">
  </div>
  <!-- /redirect -->
</template>

<script>
export default {
  name: 'kakao',
  data() {
    return {
      tracking: [{trackingCd: 2, trackingNm: '카카오 비즈니스'}]
    }
  },
  created(){
    this.saveTracking();
  },
  methods: {
    async saveTracking() {

      var tracking = {
        "trackingCd": "2",
        "trackingAction" : "ACCESS",
        "trackingNm": "카카오 비지니스",
        "trackingDesc" : "접속",
      };

      await this.$insertTracking(tracking);

      window.location.href = '/home';
    },

    // async saveTracking() {
    //   var trackingCd = this.decoding(this.$route.query.trackingCd);
    //   var trackingNM = this.decoding(this.$route.query.trackingNm);
    //
    //   var clintIP = await this.$getPublicIP();
    //
    //   this.$insertTracking(clintIP,trackingCd, trackingNM);
    //   window.location.href = '/home';
    // },
    // async saveTracking() {
    //   try{
    //     var param = parseInt(this.decoding(this.$route.query.t));
    //
    //     var tracking = this.tracking.find((element) => element.trackingCd == param);
    //
    //     if(tracking != null){
    //       var clintIP = await this.$getPublicIP();
    //
    //       this.$insertTracking(clintIP,tracking.trackingCd, tracking.trackingNm);
    //     }
    //   } catch (e) {
    //     console.log(e);
    //   }
    //   window.location.href = '/home';
    // },
    decoding(text) {
      return decodeURIComponent(window.atob(text))
    },
  },
};
</script>

