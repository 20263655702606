<template>
  <div style="text-align: center">
    <a href="https://bidtok.co.kr" class="link__download main__visual--link pc" style="width: 30%; margin: 1.25rem auto;">비드톡톡 바로가기</a>
    <div class="">
      <img src="@/assets/survey/leaflet1.jpg" style="width: 80%; height: auto; margin: 0 auto 2rem;" alt="리플렛">
      <img src="@/assets/survey/leaflet2.jpg" style="width: 80%; height: auto;  margin: 0 auto 2rem;;" alt="리플렛">
    </div>
  </div>
</template>
<script>
export default {
  name: "bidding",
  metaInfo: {
    title: '[비드톡톡] 전자입찰 서비스 - 나라장터(g2b) 전자입찰 공고 낙찰',
    link: [{ rel: "canonical", href: 'https://www.bidtok.co.kr/' }],
    meta: [
      { vmid: "description",name: 'description', content: '나라장터(g2b), 조달청, g2b, 조달업체, 지방자치단체, 전자입찰, 전자 조달, 입찰공고 확인, 입찰 적격심사 점수 확인, 분석가 분석가격 요청, AI 분석, 분석기업, 낙찰, 입찰컨설팅, 사업자, bid 등 모든 것이 비드톡톡에 있습니다.' },
      { vmid: "og:title",property: 'og:title', content: '[비드톡톡] 전자입찰 서비스 - 나라장터(g2b) 전자입찰 공고 낙찰' },
      { vmid: "og:url",property: 'og:url', content: 'https://www.bidtok.co.kr/' },
      { vmid: "og:description",property: 'og:description', content: '나라장터(g2b), 조달청, g2b, 조달업체, 지방자치단체, 전자입찰, 전자 조달, 입찰공고 확인, 입찰 적격심사 점수 확인, 분석가 분석가격 요청, AI 분석, 분석기업, 낙찰, 입찰컨설팅, 사업자, bid 등 모든 것이 비드톡톡에 있습니다.' },
      { vmid: "og:image",property: "og:image" , content : "favicon.ico"},
    ]
  },
  data() {
    return {
    }
  },
  methods: {
  }
};
</script>