<template>
    <!-- 헤더 -->
    <div class="help-sticky help-head">
        <div class="help-sticky__wrap help-head__wrap">
            <div class="help-head__group cust-info">
                <div class="cust-info--top">
                    <p class="txt--cmpny">{{loginInfo.cmpNm}}</p>
                    <div class="cust-info__box">
                        <p class="txt--license" >{{formattedBizNo}}</p>
                        <a href="#" onclick="return false" class="cust-info--logout"  @click="doLogout">로그아웃</a>
                    </div>

                </div>

            </div>
            <!-- 자동분석 상태 알림
             분석이 켜진 상태면 on / 분석이 꺼진 상태면 off
             자동분석 기간 남은 날짜가 d-3일 부터 클래스명 red-->
            <a href="#" onclick="return false"  class="help-head__group auto-analy-situ on" v-show="isAutoAnls === true" @click="openSite('notiSuggest')">
                <span class="txt--title">자동분석 <span class="empha">켜짐</span></span>
                <span class="txt--sub" :class="dDay > 3 ? '': 'red'" >D-{{dDay}}</span>

            </a>

            <a href="#" onclick="return false" class="help-head__group auto-analy-situ off" v-show="isAutoAnls === false" @click="openSite('notiSuggest')">
                <span class="txt--title">자동분석</span>
                <span class="txt--sub">꺼짐</span>

            </a>
            <!-- /자동분석 상태 알림 -->
        </div>
    </div>
    <!-- //헤더 -->
</template>

<script>
    import Vue from 'vue'
    import VueCryptojs from 'vue-cryptojs'
    Vue.use(VueCryptojs)

    export default {
        name: 'BidHelperHeader',
        data() {
            return {
                loginInfo: {},
                formattedBizNo : '',
                autoAnlsConfigList : [],    //자동분석설정목록
                holidayMngmtList : [],
                isAutoAnls: false,  //자동분석 여부
                anlstStartDt: '',   //분석 시작일
                anlstEndDt: '',     //분석 종료일
                endDt : '',
                dDay : 0,
            }
        },
        async mounted() {
            this.loginInfo = await JSON.parse(localStorage.getItem("custInfo"));
            this.getHolidayMngmtList();
            // console.log(this.loginInfo);
            this.formattedBizNo = this.loginInfo.bizNo.replace(/(\d{3})(\d{2})(\d{5})/, '$1-$2-$3');
            await this.getAutoAnlsConfigList();
        },
        methods: {
            openSite(url) {
                this.loginInfo = JSON.parse(localStorage.getItem("custInfo"));

                var param = 'custId=' +  this.loginInfo.id.toString();
                param += '&custDstnctCd=' +  this.loginInfo.custDstnctCd.toString();
                param += '&empId=' + '1';
                param += '&notiId=' + '';
                param += '&bizNo='+ this.loginInfo.bizNo.toString();
                param += '&nextPage=' + url;
                // console.log(url);
                param += '&linkMode=' + '';

                var encrypted  = this.$CryptoJS.AES.encrypt(param, 'MeMbErL@Gin!.!.!');
                encrypted = encrypted.toString().replace(/\+/gi, '.').replace(/\//gi, '_').replace(/=/gi, '-');

                window.open('/home/managerLogin?q=' + encrypted, "popup", "width=1024, height=600, left=30, top=30, scrollbars=no,titlebar=no,status=no,resizable=no,fullscreen=no");
            },

            getAutoAnlsConfigList(){
                var param = {
                    "custBizId" : this.loginInfo.custBizList[0].id,
                };
                this.$post('/api/autoAnlsConfig/list',param)
                    .then(res => {
                        this.autoAnlsConfigList = res.autoAnlsConfigList;

                        if(this.autoAnlsConfigList.length>0) {
                            // console.log(res.autoAnlsConfigList);
                            this.isAutoAnls = true;
                            this.anlstStartDt = this.$moment(this.autoAnlsConfigList[0].anlsStartDt).format('yyyy-MM-DD');
                            this.anlstEndDt = this.$moment(this.autoAnlsConfigList[0].anlsEndDt).format('yyyy-MM-DD');
                            if(this.autoAnlsConfigList[0].anlsPeriod != '0'){
                                this.anlsPeriod = this.autoAnlsConfigList[0].anlsPeriod;
                            }

                            // for (var i in this.autoAnlsConfigList) {
                            //     this.selAnlstList.push(this.autoAnlsConfigList[i].notiAnlstId);
                            // }
                            this.getDateDiff(this.anlstEndDt);
                        } else {
                            // this.calsNextDate(7);
                            // this.calsBddprNextDate(7);
                        }
                    })
                    .catch(error => {console.log(error);}
                    );

            },
            getDateDiff(future){
                const startDate = new Date();
                const futureDate = new Date(future);

                const diffInMs = Math.abs(futureDate.getTime() - startDate.getTime());
                var diffInDays = Math.ceil(diffInMs / (1000 * 60 * 60 * 24));
                this.dDay = diffInDays - this.calsNextDate(diffInDays);

            },
            //공휴일 정보
            getHolidayMngmtList(){

                var param = {
                    "startDt" : this.$moment().format('YYYY-MM-DD'),
                    "endDt" : this.$moment().add(90, 'days') .format('YYYY-MM-DD'),
                };
                this.$post('/api/holidayMngmt/list',param)
                    .then(res => {
                        this.holidayMngmtList = res.holidayMngmtList;
                    })
                    .catch(error => {console.log(error);}
                    );

            },
            calsNextDate(date){
                date--;
                var targetDt = this.$moment();
                var holidayCount = 0;

                while(date > 0){
                    targetDt = targetDt.add(1,'days');
                    date--;
                    if(this.isHolidayCheck(targetDt)){
                        holidayCount++;
                        continue;
                    }

                }
                return holidayCount
                //holidayMngmtList
            },
            isHolidayCheck(targetDt){
                var index = this.holidayMngmtList.findIndex(item => item.holiday ===  targetDt.format('YYYY-MM-DD'));

                var dayOfWeek = targetDt.weekday();

                if(index != -1 || dayOfWeek == 0 || dayOfWeek == 6) {
                    return true;
                } else {
                    return false;
                }

            },
            doLogout: function () {
                window.localStorage.setItem('authenticated', false);
                // this.$router.push('/home');
                localStorage.removeItem("authToken");
                localStorage.removeItem("refreshToken");
                localStorage.removeItem("custInfo");
                this.bizNo = "";
                this.loginInfo = null;
                window.location = '/bidHelperLogin';
            },

        }
    };

</script>
<style src="../../assets/scss/common/bid-helper.scss" lang="scss" scoped/>
<!--<style src="./Header.scss" lang="scss"></style>-->
