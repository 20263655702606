<template>
    <div class="clause wrap agree">
        <!-- 상단 테이블 -->
        <div class="agree__table--wrap">
            <ul class="agree__table--group">
                <li class="agree__table--item subject"><span class="agree__txt">계약자</span></li>
                <li class="agree__table--item content">
                    <div class="agree__table--box">
                        <div class="agree__table--element head couple"><span class="agree__txt agree__txt--head">정보 제공자 (“동”) </span></div>
                        <div class="agree__table--element body">
                            <p class="agree__txt agree__table--sub">
                                <span>회  사  명</span>
                                <span class="agree__table--title"> 주식회사 비드톡톡</span></p>
                            <p class="agree__txt agree__table--sub"><span>대 표 이 사</span><span class="agree__table--title"> 최경호</span></p>
                        </div>
                    </div>
                    <div class="agree__table--box">
                        <div class="agree__table--element head couple"><span class="agree__txt agree__txt--head">정보 이용자 (“행”) </span></div>
                        <div class="agree__table--element body">
                            <p class="agree__txt agree__table--sub"><span>회  사  명</span><span class="agree__table--title" id ="cmpNm"><strong></strong></span></p>
                            <p class="agree__txt agree__table--sub"><span>대 표 이 사</span><span class="agree__table--title" id ="userNm"><strong></strong></span></p>
                        </div>
                    </div>
                </li>
            </ul>
            <ul class="agree__table--group">
                <li class="agree__table--item subject"><span class="agree__txt">계약내용</span></li>
                <li class="agree__table--item content">
                    <div class="agree__table--box couple">
                        <div class="agree__table--element head">
                            <span class="agree__txt agree__txt--head">계약명</span>
                        </div>
                        <div class="agree__table--element body">
                            <p class="agree__txt agree__table--sub"><span>전자입찰 분석정보 제공계약서 </span></p>
                        </div>
                    </div>
                    <div class="agree__table--box couple">
                        <div class="agree__table--element head">
                            <span class="agree__txt agree__txt--head">계약 금액</span>
                        </div>
                        <div class="agree__table--element body">
                            <p class="agree__txt agree__table--sub">금<span class="agree__table--title"><strong>0</strong></span>원 (VAT포함) </p>
                        </div>
                    </div>
                    <div class="agree__table--box couple">
                        <div class="agree__table--element head">
                            <span class="agree__txt agree__txt--head">성공 보수료</span>
                        </div>
                        <div class="agree__table--element body">
                            <p class="agree__txt agree__table--sub agree__table--title">※아래 <span>&lt;표1&gt;</span>에서 제시하는 바에 따름 (분석가 선택 시 수수료율 적용)</p>
                        </div>
                    </div>
                    <div class="agree__table--box">
                        <div class="agree__table--element head couple">
                            <span class="agree__txt agree__txt--head">성공 보수료 지급 조건</span>
                        </div>
                        <div class="agree__table--element body">
                            <p class="agree__txt agree__table--sub agree__table--title">※낙찰일로 부터 14일 이내 (별첨 계약내용 참조)</p>
                        </div>
                    </div>
                    <div class="agree__table--box">
                        <div class="agree__table--element head couple">
                            <span class="agree__txt agree__txt--head">계약 기간</span>
                        </div>
                        <div class="agree__table--element body">
                            <p class="agree__txt agree__table--sub agree__table--title">{{currentDate}} ~ {{nextYearDate}}</p>
                        </div>
                    </div>
                    <div class="agree__table--box">
                        <div class="agree__table--element head couple">
                            <span class="agree__txt agree__txt--head">기타</span>
                        </div>
                        <div class="agree__table--element body">
                            <p class="agree__txt agree__table--sub agree__table--title">※ 별첨 계약 내용에 정한 바에 따름 *계약 기간 경과 후 상호 이의가 없을 경우 자동 연장 되며 계약금은 발생하지 않습니다.</p>
                        </div>
                    </div>
                </li>
            </ul>
        </div>
        <!-- /상단 테이블 -->
        <div class="clause__group">
            <p class="clause__group--info agree__table--notice"><span> 주식회사 비드톡톡과 정보이용자(“행”)는 표지 및 별첨계약내용에 따라 본 “분석서비스 제공 계약”을 체결하고 신의에 따라 성실하게 계약상 의무를 이행할 것을 확약하였으며 이 계약의 증거로서 내용 확인 후 동의함으로서 계약 성립은 유효하다.</span></p>
        </div>
        <!-- 분석가에 따른 성공 보수료 -->
        <AnlsFee></AnlsFee>
        <!-- //분석가에 다른 성공 보수료 -->
        <div class="clause__group agree__company">
            <h2 class="clause__group--tit align-c auto-input">{{currentDate}}</h2>
            <p class="clause__group--info agree__company--announce">
                <span>※ 주식회사 비드톡톡 임직원들은 전자입찰 분석정보 제공계약서에 명시된 계약금 및 성공보수료 이외에 어떠한 금품, 향응, 선물 그리고 편의를 수수하거나 제공을 요청하지 않으며 이를 위배한 임직원은 사규에 따라 징계합니다.</span>
            </p>
            <div class="comppany-info__group agree__company--info">
                <p class="clause__group--info align-c"><span>대전광역시 서구 계룡로553번길 18, 조이빌딩 9층</span></p>
                <p class="clause__group--info align-c"><span>주식회사 비드톡톡 </span></p>
                <p class="clause__group--info align-c"><span>TEL 1670-0508</span><span>FAX 042-367-3313</span></p>
                <!-- <div class="agree__company--info mobile">
                    <p class="clause__group--info align-c"><span>주식회사 지니 </span></p>
                    <p class="clause__group--info align-c"><span>TEL 1670-0508</span><span>FAX 042-367-3313</span></p>
                </div> -->
            </div>
        </div>
        <h2 class="menu__title clause__txt--title add-message">계 약 내 용</h2>
        <!-- 1죠 -->
        <div class="clause__group">
            <h2 class="clause__group--tit">제 1조 (계약의 목적)</h2>
            <p class="clause__group--info">
                <span>본 계약은 “동”이 “행”에게 전자 입찰 분석 정보를 제공하고 “행”은 “동”이 제공하는 전자 입찰 분석 정보를 이용하며 그 대가를 “동”에게 지급하는 것과 관련하여 필요한 사항을 정함을 목적으로 한다.</span>
            </p>
        </div>
        <!-- //1조 -->
        <div class="clause__group">
            <h2 class="clause__group--tit">제2조 (“동”의 의무)</h2>
            <p class="clause__group--info">
                <span>본 계약에 따라 “동”은 “동”이 운영하는 인터넷 웹페이지(www.bidtok.co.kr)와 어플리케이션(비드톡톡)을 통하여 국가나 지방자치단체가 공고한 각종 입찰 관련 정보를 “행”에게 제공하기로 한다. 또한 “행”이 “동”에게 요청하는 입찰건에 대해서도 동일하게 정보를 제공하기로 한다. “동”이 “행”에게 제공하는 정보의 종류와 범위는 “동”의 웹페이지와 어플리케이션을 통해 제공되고 있는 내용을 기본으로 하며 유선, 문자, SNS, 이메일, 팩스 등도 포함된다.</span>
            </p>
        </div>
        <div class="clause__group">
            <h2 class="clause__group--tit">제3조 (“행”의 의무) </h2>
            <p class="clause__group--info">
                <span class="clause__group--info indent">
                    <span>① “행”은 “동”으로부터 제2조의 정보를 제공 받는데에 대한 대가로 제4조 ①항에 정한 액수에 따라 “동”에게 이용대금을 지급하여야 한다. 계약 금액이 지급된 후부터 분석실을 통한 가격 제시가 시작되는 것으로 한다.</span>
                    <span>② “행”은 본 계약 체결일로부터 분석요청한 입찰 공고에 대하여 분석실을 통하여 제시 가격을 확인하며 확인한 가격에 대하여 가격 이행을 하여야 한다. 단, 가격 불이행을 했을 경우 횟수에 따라 “동”은 “행”에 대해 등급을 변동 할 수 있다. (단, 가격 불이행 가격이 낙찰가가 된 경우에는 횟수에 관계없이 즉시 등급을 변동할 수 있다) (비드톡톡 이용약관 제10조 참조)</span>
                </span>
            </p>
        </div>
        <div class="clause__group">
            <h2 class="clause__group--tit">제4조 (이용 대금) </h2>
            <p class="clause__group--info">
                <span class="clause__group--info indent">
                    <span>① “행”은 본 계약 체결과 동시에 “동”에게 본 계약서 표지에 정한 “계약 금액”을 지급한다. “동”의 귀책사유로 “행”에 대한 정보 제공이 불가능해진 경우를 제외하고 “행”은 “동”에게 기본 계약 금액의 반환을 청구할 수 없다.</span>
                    <span>② “행”이 “동”으로부터 제시 받은 가격으로 입찰에 참가하여 최종 낙찰자로 선정 된 경우(“행”이 동가의 1순위가 된 후 추첨을 통하여 최종 낙찰이 확정된 경우, “행”보다 선 순위의 입찰 참가자가 있음에도 불구하고 “행”이 최종 낙찰이 된 경우 등을 포함) “행”은 “동”에게 본 계약서 표지에 정한 “성공 보수료”를 지급한다.단, 단가 계약(공사, 납품, 구매 설치, 용역 수행 등)의 경우 총 공사 예정 금액 또는 총 배정 예산으로 성공 보수료를 지급한다.</span>
                    <span>③ 공동 도급을 진행하여(구성 업체,분담 업체) 최종 낙찰자가될 경우 최종 공사 비율에 따라 본 계약서 표지에 정한 성공 보수료를 지급한다. </span>
                    <span>④ “행”은 “동”의 웹페이지 및 어플리케이션 상의 “분석실”을 통해 “분석제시가 확인” 버튼을 클릭한 공고 건에 관여하는 “행”이 해당 공고건의 입찰에 참여한 경우 “동”으로부터 제시 받은 가격으로 입찰에 참여한 것으로 보아 ②항을 적용하기로 한다. </span>
                    <span>⑤ 가격이행 여부의 표준 오차는 ±0.1% 범위로 인정하거나 또는 비드톡톡 가격 제시 기준 절상,절하 (ex 만원단위, 십만단위, 백만단위 등) 일때 혹은 임의로 수정할 경우 또한 이행 여부 범위로 인정하여 성공 보수료를 지급한다. (분석서비스 이용약관 제13조 참조) </span>
                    <span>⑥ “행”은 본 계약서 표지에 정한 “성공 보수료”를 “동”의 성공 낙찰 일로부터 14 일 이내에 지급하여야 하며 지체하는 경우 “동”은 “행”에 대한 정보 제공을 중단할 수 있다. 다만 성공 보수 금액이 1,000만원 이상일 경우 “행”은 성공 보수 금액 중 50%의 지급 유예를 신청할 수 있으며, “동”은 “행”의 지불 능력 기타 제반 사정을 참작하여 지급 유예를 승인할 수 있다.(단,지급 유예 기간은 최장 60일 이내로 한다.)</span>
                </span>
            </p>
        </div>
        <div class="clause__group">
            <h2 class="clause__group--tit">제5조(비밀 보장) </h2>
            <p class="clause__group--info">
                <span class="clause__group--info indent">
                    <span>① “행”은 본 계약에 따라 “동”으로부터 제시 받은 가격을 제3자에게 공개하거나 양도 할 수 없으며 이를 위반함으로 인해 “동” 이 입은 손해를 배상해야 한다. 또한 “행”은 본 계약에 의하여 지득한 “동”의 자료 및 정보 등의 비밀 (이미 일반에 알려진 정보는 제외)을 타인에게 누설 또는 공개 할 수 없으며 이를 위반함으로 인해 “동” 이 입은 손해를 배상해야 한다.</span>
                </span>
            </p>
        </div>
        <div class="clause__group">
            <h2 class="clause__group--tit">제6조(분쟁의 해결) </h2>
            <p class="clause__group--info">
                <span>본 계약과 관련하여 발생하는 분쟁은 수원지방법원 성남지원(택일 결정 필요)을 제1심 관할법원으로 하여 해결한다.</span>
            </p>
        </div>
        <div class="agree__check--area">
            <div class="agree__check--group pc">
                <div class="agree__check--box content clause__group--info"><span>“행”은 본 계약서 내용을 반드시 숙지하고 동의하며 원만히 계약이 성사되었음을 인정합니다. 위 계약 내용에 대해 동의 하십니까?</span></div>
                <div class="agree__check--box company clause__group--info"><span>회사명:&nbsp;</span><span></span></div>
                <div class="agree__check--box agree clause__group--info">
                    <span>동의</span>
                    <input type="checkbox" id="check12">
                    <label for="check12"></label>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import AnlsFee from '@/pages/Comn/AnlsFee'
    export default {
        name: 'Typography',
        data() {
            return {
                siteNm: '비드톡톡',
                siteUrl: 'https://www.bidtok.co.kr',
            }
        },
        components: {
            AnlsFee
        },
        computed: {
            currentDate() {
                const today = new Date();
                const year = today.getFullYear();
                const month = today.getMonth() + 1;
                const day = today.getDate();

                const formattedDate = `${year}년 ${month}월 ${day}일`;

                return formattedDate;
            },

            nextYearDate(){
                const today = new Date();
                const year = today.getFullYear() + 1;
                const month = today.getMonth() + 1;
                const day = today.getDate() - 1;

                const formattedDate = `${year}년 ${month}월 ${day}일`;

                return formattedDate;
            }
        },
        mounted () {
            window.addEventListener('scroll', this.checkBottom);
        },
        methods: {
            move(message) {
                //페이지 이동
                document.getElementById(message).scrollIntoView({behavior:'smooth'});
            },
            checkBottom () {
                let isBottom = (document.documentElement.scrollTop + window.innerHeight + 200 > document.documentElement.offsetHeight);
                if(isBottom && window.innerWidth <= 800){
                    // eslint-disable-next-line no-undef
                    JavaScriptChannel.postMessage("Y");
                }
            },
        }
    }
</script>