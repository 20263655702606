<template>
    <div class="main">

        <!-- 낙찰 리스트 -->
        <div class="main__container bid-result">
            <div class="main__area bid-result__area">
                <div class="bid-result--icon pc"></div>
                <div class="bid-result__slick">
                    <div class="bid-result__slick--item" v-for="item in homeSbidList" v-bind:key="item.id" tabindex="-1" style="pointer-events: none;">
                        <div class="bid-result__slick--element" tabindex="-1">
                            <p class="bid-result__slick--txt date" tabindex="-1">
                                <span class="bid-result--icon mobile" tabindex="-1"></span>
                                {{item.opbdDt}}
                            </p>
                            <p class="bid-result__slick--txt anounce-title" tabindex="-1">{{item.notiNm}}</p>

                        </div>
                        <div class="bid-result__slick--element" aria-hidden="false" tabindex="-1">
                            <p class="bid-result__slick--txt sum" tabindex="-1">{{item.bddprAmt}}<span>원</span></p>
                            <p class="bid-result__slick--txt ment" tabindex="-1">낙찰</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- /낙찰 리스트 -->
        <!-- 최상단 배너 -->
<!--        <div class="top-banner ai-promo" @click="$router.push('/membership')">-->
<!--            <div class="ai-promo&#45;&#45;img"></div>-->
<!--        </div>-->
        <!-- /최상단 배너 -->

        <!-- 메인 비주얼
            logout 경우 :  visual 옆에 visual--logout 클래스 추가
            login 경우 :  visual 옆에 visual--login 클래스 추가
        -->
        <div class="main__container std  visual" :class="(Object.keys(loginInfo).length == 0 ? 'visual--logout' : 'visual--login') + (isEmptyMyAnalst ? ' empty': '')">
            <!-- 로그아웃 -->
            <div class="main__area visual__area visual__area--log-out">
                <!-- 실시간 분석가 순위 -->
                <div class="indivi-chart rank rank--logout">
                    <div class="rank__unit active">
                        <div class="indivi-chart__head rank__head" >
                            <span class="rank__arcc--head txt--title">실시간 분석가 순위</span>
                            <div class="select-box rank--accordion">

                            </div>
                        </div>

                        <!-- 실시간 분석가 순위

                           -1위 일 때 .gold
                           -2위 일 때 .silver
                           -3위 일 때 .bronze
                           -순위가 상승 했을 때 .rank__list--element에 .active 추가
                       -->
                        <div class="indivi-chart__body">
                            <div class="rank__box">
                                <div class="rank--title">
                                    <span class="rank--txt num">순위</span>
                                    <span class="rank--txt company">분석가</span>
                                    <span class="rank--txt sum">분석요청률&nbsp;&nbsp;</span>
                                </div>
                                <ul class="rank__list">
                                    <li class="rank__list--item" v-for="item in rankStndrdHistList" v-bind:key="item.id">
                                        <span class="rank__list--element rank--txt num" :class="item.rankClass">{{item.ranking}}</span>
                                        <div class="rank__list--element rank--txt company">
                                            <img :src="item.iconImg" alt="분석 회사 로고" loading="lazy">
                                            <span class="company--txt">{{item.anlstNickNm}}</span>
                                        </div>
                                        <span class="rank__list--element rank--txt sum" :class="item.valueDiff > 0 ? 'active':''">{{item.rankValue  | decimal1}}%
                                            <i class="sum--ic"></i>
                                        </span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <!-- /실시간 분석가 순위-->
                    </div>
                </div>
                <!-- /실시간 분석가 순위 -->
                <div class="visual-slide">
                    <!-- 페이지네이션 -->
                    <div class="visual-slide__area pagntion">
                        <p class="h2">그래서 비드톡톡은 <span class="line-break">뭐가 다른데?</span></p>
                    </div>
                    <!-- /페이지네이션 -->

                    <!-- 컨텐츠 부분 -->
                    <div class="visual-slide__area contents">
                        <div class="contents__item fee">
                            <a href="#" onclick="return false" @click="$goMenu('homeBannerDetail#content5')" tabindex="-1">
                                <span class="h4">01</span>
                                <p class="h2">AI멤버십 <span class="empha">낙찰 수수료가 0원</span></p>
                                <p class="h3">늘 낙찰을 원하면서도 고민되었던 한 가지,<br>낙찰 수수료 <span class="empha">AI멤버십은 대표님의 고민을 없애드립니다.</span></p>
                                <p class="txt">더보기<i class="ic--arrow--view"></i></p>
                                <div class="contents--bg fee"></div>
                            </a>
                        </div>
                        <div class="contents__item ai">
                            <a href="#" onclick="return false" @click="$goMenu('homeBannerDetail#content2')" tabindex="-1">
                                <span class="h4">02</span>
                                <p class="h2">특허 출원 중인 <span class="empha">AI</span></p>
                                <p class="h3">
                                    <span class="empha">여기저기 무늬만 다 AI?<br></span>
                                    AI기술 발전과 함께 많은 기업이 <span class="line-break--01">AI를 활용한 서비스를 선보이고 있습니다.</span>
                                    <span class="line-break--02">하지만 AI라고 같은 가치를 제공하는 것은 아닙니다.</span>
                                </p>
                                <p class="txt">더보기<i class="ic--arrow--view"></i></p>
                                <div class="contents--bg ai"></div>
                            </a>
                        </div>
                        <div class="contents__item select">
                            <a href="#" onclick="return false" @click="$goMenu('homeBannerDetail#content3')" tabindex="-1">
                                <span class="h4">03</span>
                                <p class="h2"><span class="empha">마음이 가는 </span>대로</p>
                                <p class="h3">
                                    <span class="empha">입찰 업체가 주는 가격만 받아서 투찰 보시고 있나요? </span><br>
                                    낙찰이 없음에도 계약기간 때문에<br>어쩔 수 없이 사용하고 있진 않나요?</p>
                                <p class="txt">더보기<i class="ic--arrow--view"></i></p>
                                <div class="contents--bg select">

                                </div>
                            </a>
                        </div>
                        <div class="contents__item propensity">
                            <a href="#" onclick="return false" @click="$goMenu('homeBannerDetail#content1')" tabindex="-1">
                                <span class="h4">04</span>
                                <p class="h2">나도 모르는 <span class="empha">내 투찰 성향?!</span></p>
                                <p class="h3">나를 잘 알아야 낙찰도 가능한 법!<br><span class="line-break-01">익숙한 듯 익숙하지 않은 투찰!</span></p>
                                <span class="empha line-break-01">고객님도 몰랐던 나의 새로운 투찰 성향을 발견해 보세요.</span>
                                <p class="txt">더보기<i class="ic--arrow--view"></i></p>
                                <div class="contents--bg propensity"></div>
                            </a>
                        </div>
<!--                        <li class="contents__item app">-->
<!--                            <a href="#" onclick="return false" @click="$goMenu('homeBannerDetail#content4')">-->
<!--                                <span class="h4">04</span>-->
<!--                                <p class="h2">꼭 필요한 순간 <span class="empha">앱</span>으로!</p>-->
<!--                                <p class="h3">원하는 정보만 쏙쏙<br><span class="empha">비드톡톡은 작은 부분 하나도 놓치지 않습니다.</span></p>-->
<!--                                <p class="txt">더보기<i class="ic&#45;&#45;arrow&#45;&#45;view"></i></p>-->
<!--                                <div class="contents&#45;&#45;bg app"></div>-->
<!--                            </a>-->
<!--                        </li>-->
                    </div>
                    <!-- /컨텐츠 부분 -->
                </div>
            </div>
            <!-- /로그아웃 -->

            <!-- 로그인 -->
            <div class="main__area visual__area visual__area--log-in">
                <div class="visual__group rank customer-chart">
                    <div class="customer-chart__unit">
                        <div class="customer-chart__box">
                            <div class="customer-chart__item hello-message glass">
                                <p class="txt--sub">
                                    <span class="customer-name">{{Object.keys(loginInfo).length != 0 ? loginInfo.cmpNm : ''}} 님, 안녕하세요 !</span>
                                    <!-- <span class="sub">님 의</span> -->
                                </p>
                                <a href="#" class="hello-message--announce" @click="$goMenu('tokReportMain')">
                                    <i class="announce--icon"></i>
                                    <p class="announce--title"><span class="empha">나와 딱 맞는 AI</span>가 궁금하세요?<i class="ic--arrow"></i></p>
                                </a>
                                <div class="glass--bg">
                                    <svg class="frame">
                                        <rect rx="32" stroke="url(#gradient-half)" />
                                    </svg>
                                </div>
                            </div>
                            <!-- 오늘의 투찰 리포트 .today-report-->
                            <div class="customer-chart__item indivi-chart today-report">
                                <div class="indivi-chart__head rank__head">
                                    <span class="txt--title">오늘의 투찰 리포트</span>
                                </div>
                                <ul class="indivi-chart__body today-report__list">
                                    <li class="today-report__list--item">
                                        <span class="txt--title" @click="$goMenu('analysis')">투찰 미완료</span>
                                        <span class="txt--sub" @click="$goMenu('analysis')"><span class="count">{{bddprFinCnt}}</span>건</span>
                                    </li>
                                    <li class="today-report__list--item">
                                        <span class="txt--title" @click="$goMenu('analysis')">참가 신청 미완료</span>
                                        <span class="txt--sub" @click="$goMenu('analysis')"><span class="count">{{regstCnt}}</span>건</span>
                                    </li>
                                </ul>
                            </div>
                            <!-- /오늘의 투찰 리포트 -->
                        </div>
                        <!-- 나의 분석요청률 .customer-request-->
                        <div class="customer-chart__box indivi-chart customer-request">
                            <div class="indivi-chart__head rank__head">
                                <span class="txt--title">나의 분석요청률</span>
                            </div>
                            <div class="indivi-chart__body customer-request__body" :class="isEmptyMyAnalst? 'glass' : ''">
                                <ul class="comp__list">
                                    <li class="comp__list--item" :class="chartData.datasets[0].compListItemClass[index]" v-for="(item, index) in chartData.labels" v-bind:key="item.id">{{item}}</li>
                                </ul>
                                <!-- 차트 여기에 넣어주세요 -->
                                <div class="comp__chart">
                                    <doughnut-chart
                                            ref="fruitChart"
                                            :chartData="chartData"
                                            :chartOptions="options"
                                            id="chart"
                                    ></doughnut-chart>

                                </div>
                                <!-- /차트 여기에 넣어주세요 -->
                                <a href="#" onclick="return false" class="glass--bg" @click="$goMenu('notiSuggest')">
                                    <div href="#" onclick="return false" class="customer-request--move">
                                        <span>지금 바로 내 맞춤공고에서</span>
                                        <p class="txt--title">분석 요청 <span>TOK</span><i class="ic--arrow--view "></i></p>
                                    </div>
                                </a>
                            </div>
                        </div>
                        <!-- /나의 분석요청률 -->
                    </div>

                    <!-- 나의 분석가 순위 -->
                    <div class="customer-chart__unit indivi-chart rank custom-analy-rank">
                        <div class="rank__unit active custom-analy-rank__unit">
                            <div class="indivi-chart__head rank__head">
                                <span class="txt--title">나의 분석가 순위</span>
                            </div>
                            <div class="indivi-chart__body custom-analy-rank__body">
                                <div class="rank__box custom-analy-rank__box" v-for="items in myAnalstStatisticsGroup" v-bind:key="items.id">
                                    <div class="rank--title">
                                        <span class="rank--txt custom-analy-rank--txt num">순위</span>
                                        <span class="rank--txt custom-analy-rank--txt company">분석가</span>
                                        <span class="rank--txt custom-analy-rank--txt count">분석요청수</span>
                                        <span class="rank--txt custom-analy-rank--txt sum">분석요청률</span>
                                    </div>
                                    <ul class="rank__list">
                                        <li class="rank__list--item" v-for="item in items" v-bind:key="item.id">
                                            <span class="rank__list--element rank--txt custom-analy-rank--txt num">{{item.anlsRank}}</span>
                                            <div class="rank__list--element rank--txt custom-analy-rank--txt company">
                                                <img :src="item.iconImg" alt="분석 회사 로고" loading="lazy">
                                                <span class="company--txt">{{item.anlstNickNm}}</span>
                                            </div>
                                            <span class="rank__list--element rank--txt custom-analy-rank--txt count">{{item.anlsCnt}}</span>
                                            <span class="rank__list--element rank--txt custom-analy-rank--txt sum">{{item.anlsRatio | decimal1}}%</span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- /나의 분석가 순위 -->
                </div>
                <div class="visual__group indivi-chart rank rank--login">
                    <div class="rank__unit rank--logout__unit">
                        <div class="indivi-chart__head rank__head">
                            <span class="txt--title">실시간 분석가 순위</span>
                        </div>

                        <!-- 실시간 분석가 순위

                           -1위 일 때 .gold
                           -2위 일 때 .silver
                           -3위 일 때 .bronze
                           -순위가 상승 했을 때 .rank__list--element에 .active 추가
                       -->
                        <div class="indivi-chart__body">
                            <div class="rank__box">
                                <div class="rank--title">
                                    <span class="rank--txt num">순위</span>
                                    <span class="rank--txt company">분석가</span>
                                    <span class="rank--txt sum">분석요청률&nbsp;&nbsp;</span>
                                </div>
                                <ul class="rank__list">
                                    <li class="rank__list--item" v-for="item in rankStndrdHistList" v-bind:key="item.id">
                                        <span class="rank__list--element rank--txt num" :class="item.rankClass">{{item.ranking}}</span>
                                        <div class="rank__list--element rank--txt company">
                                            <img :src="item.iconImg" alt="분석 회사 로고" loading="lazy">
                                            <span class="company--txt">{{item.anlstNickNm}}</span>
                                        </div>
                                        <span class="rank__list--element rank--txt sum" :class="item.valueDiff > 0 ? 'active':''">{{item.rankValue  | decimal1}}%
                                            <i class="sum--ic"></i>
                                        </span>
                                    </li>

                                </ul>
                            </div>
                        </div>
                        <!-- /실시간 분석가 순위-->
                    </div>
                </div>
            </div>
            <!-- /로그인 -->
        </div>
        <!-- /메인 비주얼 -->

        <!-- 비드톡톡 서비스 -->
        <div class="main__container main__area std intro-service">
            <div class="intro-service__area">
                <p class="main__area--title intro-service--title">뻔하지 않은 FUN한 서비스</p>
                <ul class="intro-service__group intro-service__list">
                    <li class="main__list--item intro-service__list--item suit">
                        <a href="#" onclick="return false" @click="$goMenu('serviceDetailSuit')">
                            <i class="main__list--img intro-service__list--icon"></i>
                            <p class="intro-service__list--title">딱 맞는 맞춤공고<span class="intro-service__list--sub mobile higlight">TOK!</span></p>
                            <span class="intro-service__list--sub higlight pc">TOK!</span>
                            <i class="btn__view-more intro-service__list--move"></i>
                        </a>
                    </li>
                    <li class="main__list--item intro-service__list--item auto-analy">
                        <a href="#" onclick="return false" @click="$goMenu('serviceDetailAnaly')">
                            <i class="main__list--img intro-service__list--icon"></i>
                            <p class="intro-service__list--title">분석요청<span class="line-break">한번의 클릭으로</span><span class="intro-service__list--sub mobile higlight">TOK!</span></p>
                            <span class="intro-service__list--sub higlight pc">TOK!</span>
                            <i class="btn__view-more intro-service__list--move"></i>
                        </a>
                    </li>
                    <li class="main__list--item intro-service__list--item download">
                        <a href="#" onclick="return false" @click="$goMenu('serviceDetailDownload')">
                            <i class="main__list--img intro-service__list--icon"></i>
                            <p class="intro-service__list--title">언제 어디서나<span class="line-break">비드톡톡 앱 다운</span><span class="intro-service__list--sub mobile higlight">TOK!</span></p>
                            <span class="intro-service__list--sub higlight pc">TOK!</span>
                            <i class="btn__view-more intro-service__list--move"></i>
                        </a>
                    </li>
                    <li class="main__list--item intro-service__list--item tokhelper">
                        <a href="#" onclick="return false" @click="$goMenu('serviceDetailHelper')">
                            <i class="main__list--img intro-service__list--icon"></i>
                            <p class="intro-service__list--title">편리한 입찰을 위한 <span class="line-break">톡톡 도우미</span><span class="intro-service__list--sub mobile higlight">TOK!</span></p>
                            <span class="intro-service__list--sub higlight pc">TOK!</span>
                            <i class="btn__view-more intro-service__list--move"></i>
                        </a>
                    </li>
                </ul>
            </div>
        </div>
        <!-- /비드톡톡 서비스 -->

        <!-- 분석가 소개 -->
        <div class="main__container std main__area intro-analysist">
            <p class="main__area main__area--title f-i">분석가 소개</p>
            <div class="rolling-banner"> <!-- 배너 표시영역 -->
                <div class="rolling-list"> <!-- 원본배너 -->
                    <ul class="rolling-list intro-analysist__list intro-analysist__slick">
                        <li class="main__list--item intro-analysist__list--item suri">
                            <a href="/introduceAnalysist#int-anlst-suri">
                                <img src="@/assets/images/analyist/img_analyist_suri.png" alt="분석가소개_AI수리" class="intro-analysist__list--icon" loading="lazy">
                                <p class="intro-analysist__list--title">AI수리</p>
                                <i class="btn__view-more intro-analysist__list--move"></i>
                            </a>
                        </li>
                        <li class="main__list--item intro-analysist__list--item dog">
                            <a href="/introduceAnalysist#int-anlst-dog">
                                <img src="@/assets/images/analyist/img_analyist_dog.png" alt="분석가소개_AI도그" class="intro-analysist__list--icon" loading="lazy">
                                <p class="intro-analysist__list--title">AI도그</p>
                                <i class="btn__view-more intro-analysist__list--move"></i>
                            </a>
                        </li>
                        <li class="main__list--item intro-analysist__list--item peng">
                            <a href="/introduceAnalysist#int-anlst-peng">
                                <img src="@/assets/images/analyist/img_analyist_peng.png" alt="분석가소개_AI펭" class="intro-analysist__list--icon" loading="lazy">
                                <p class="intro-analysist__list--title">AI펭</p>
                                <i class="btn__view-more intro-analysist__list--move"></i>
                            </a>
                        </li>
                        <li class="main__list--item intro-analysist__list--item jin">
                            <a href="/introduceAnalysist#int-anlst-jin">
                                <img src="@/assets/images/analyist/logo_jinbid_lg.png" alt="분석가소개_진비드" class="intro-analysist__list--icon" loading="lazy">
                                <p class="intro-analysist__list--title">진비드</p>
                                <i class="btn__view-more intro-analysist__list--move"></i>
                            </a>
                        </li>
                        <li class="main__list--item intro-analysist__list--item sol">
                            <a href="/introduceAnalysist#int-anlst-sol">
                                <img src="@/assets/images/analyist/logo_solbid_lg.png" alt="분석가소개_솔비드" class="intro-analysist__list--icon" loading="lazy">
                                <p class="intro-analysist__list--title">솔비드</p>
                                <i class="btn__view-more intro-analysist__list--move"></i>
                            </a>
                        </li>
                        <li class="main__list--item intro-analysist__list--item inbid">
                            <a href="/introduceAnalysist#int-anlst-inbid">
                                <img src="@/assets/images/analyist/logo_inbid_lg.png" alt="분석가소개_인비드" class="intro-analysist__list--icon" loading="lazy">
                                <p class="intro-analysist__list--title">인비드</p>
                                <i class="btn__view-more intro-analysist__list--move"></i>
                            </a>
                        </li>
                        <li class="main__list--item intro-analysist__list--item chung">
                            <a href="/introduceAnalysist#int-anlst-chung">
                                <img src="@/assets/images/analyist/logo_chung_lg.png" alt="분석가소개_청비드" class="intro-analysist__list--icon" loading="lazy">
                                <p class="intro-analysist__list--title">청비드</p>
                                <i class="btn__view-more intro-analysist__list--move"></i>
                            </a>
                        </li>
                        <li class="main__list--item intro-analysist__list--item bidin">
                            <a href="/introduceAnalysist#int-anlst-bidin">
                                <img src="@/assets/images/analyist/logo_bidin_lg.png" alt="분석가소개_비드인" class="intro-analysist__list--icon" loading="lazy">
                                <p class="intro-analysist__list--title">비드인</p>
                                <i class="btn__view-more intro-analysist__list--move"></i>
                            </a>
                        </li>
                        <li class="main__list--item intro-analysist__list--item plus">
                            <a href="/introduceAnalysist#int-anlst-plus">
                                <img src="@/assets/images/analyist/logo_bidplus_lg.png" alt="분석가소개_비드플러스" class="intro-analysist__list--icon" loading="lazy">
                                <p class="intro-analysist__list--title">비드플러스</p>
                                <i class="btn__view-more intro-analysist__list--move"></i>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <!-- /분석가 소개 -->

        <!--궁금한 점이 있으신가요?-->
        <div class="main__area std question">
            <p class="main__area--title f-i">궁금한 점이 있으신가요?</p>
            <ul class="question__list">
                <li class="main__list--item question__list--item guide">
                    <a href="#" onclick="return false" @click="$goMenu('howtoDetail')">
                    <p class="txt--title">사용 가이드</p>
                        <p class="txt--sub">비드톡톡의 생생한 사용법과 팁을<br>알고 싶다면?</p>
                        <i class="question__list--icon guide--icon"></i>
                    </a>
                </li>
                <li class="main__list--item question__list--item tokstory">
                    <a href="#" onclick="return false" @click="$goMenu('tokStory')">
                    <p class="txt--title">톡스토리</p>
                        <p class="txt--sub">비드톡톡의 최신 소식과 일상이<br>궁금하다면?</p>
                        <i class="question__list--icon tokstory--icon"></i>
                    </a>
                </li>
                <li class="main__list--item question__list--item blog">
                    <a href="https://blog.naver.com/bidtoktok_official" target="_blank">
                    <p class="txt--title">블로그</p>
                        <p class="txt--sub">비드톡톡에서 전하는 입찰 관련된 최신 정보를<br>알고 싶다면?</p>
                        <i class="question__list--icon blog--icon"></i>
                    </a>
                </li>
                <li class="main__list--item question__list--item youtube">
                    <a href="https://www.youtube.com/@bidtoktok_official" target="_blank">
                    <p class="txt--title">유튜브</p>
                        <p class="txt--sub">비드톡톡에서 전하는 비드톡톡 매력을<br>알고 싶다면?</p>
                        <i class="question__list--icon youtube--icon"></i>
                    </a>
                </li>
            </ul>
        </div>
        <!--/궁금한 점이 있으신가요?-->

        <!-- 비드톡톡은 이렇습니다 -->
        <div class="main__area std company">
            <p class="main__area--title f-i">비드톡톡은 이렇습니다</p>
            <ul class="company__list korean">
                <li class="company__list--item korean--item active">자신감</li>
                <li class="company__list--item korean--item">혁신</li>
                <li class="company__list--item korean--item">차별화</li>
                <li class="company__list--item korean--item">변화</li>
                <li class="company__list--item korean--item">기회</li>
                <li class="company__list--item korean--item">지식</li>
                <li class="company__list--item korean--item blank">blank</li>
            </ul>
            <ul class="company__list english">
                <li class="company__list--item english--item active"><span class="empha">B</span>oldness</li>
                <li class="company__list--item english--item"><span class="empha">I</span>nnovation</li>
                <li class="company__list--item english--item"><span class="empha">D</span>ifference</li>
                <li class="company__list--item english--item"><span class="empha">T</span>ransformation</li>
                <li class="company__list--item english--item"><span class="empha">O</span>pportunity</li>
                <li class="company__list--item english--item"><span class="empha">K</span>nowledge</li>
                <li class="company__list--item english--item"><span class="empha">F</span>un</li>
            </ul>
            <ul class="company__list description">
                <li class="company__list--item description--item active">비드톡톡은 세상에 없던 <span class="line-break--02"> 새로운 분석가 매칭 서비스로 <span class="line-break--01">전자 입찰의 새로운 길을 제시합니다.</span></span></li>
                <li class="company__list--item description--item">비드톡톡은 항상 새로운 아이디어와 <span class="line-break--02"> 편리함을 추구하여 <span class="line-break--01">입찰 산업의 선두 주자가 되고자 합니다.</span></span></li>
                <li class="company__list--item description--item">비드톡톡은 다양한 AI 분석가와 <span class="line-break--02">분석 기업을 통해 고객들이 차별화된 <span class="line-break--01">분석 매칭 서비스를 경험할 수 있도록 합니다.</span></span></li>
                <li class="company__list--item description--item">비드톡톡이 전자 입찰의 새로운 변화의 바람이 되어, <span class="line-break--01">혁신적인 솔루션을 제공합니다.</span></li>
                <li class="company__list--item description--item">비드톡톡은 고객들에게 새로운 낙찰의 경험의 기회를 <span class="line-break--01">제공하고 성장을 도모합니다.</span></li>
                <li class="company__list--item description--item">20년 이상의 입찰 경력자들의 지식과 전문성을 <span class="line-break--02">바탕으로 고객이 좀 더 최상의 서비스를 <span class="line-break--01">이용할 수 있는 서비스를 제공합니다.</span></span></li>
                <li class="company__list--item description--item">톡톡 터지는 낙찰의 재미를 비드톡톡으로 <span class="line-break--02">뻔하게 말고 <span class="line-break--01">FUN하게 경험하세요!</span></span></li>
            </ul>
        </div>
        <div class="bg-earth--position">
            <ul class="bg-earth--txt">
                <li class="bg-earth--txt--list">B</li>
                <li class="bg-earth--txt--list">I</li>
                <li class="bg-earth--txt--list">D&nbsp;</li>
                <li class="bg-earth--txt--list">T</li>
                <li class="bg-earth--txt--list">O</li>
                <li class="bg-earth--txt--list">K&nbsp;</li>
                <li class="bg-earth--txt--list">FUN</li>
            </ul>
            <div class="bg-earth--bg circle"></div>
            <ul class="bg-earth--bg map ani-container">
                <li class="map--list"></li>
                <!-- <li class="map--list"></li> -->
            </ul>
            <!-- <ul class="bg-earth--bg map second">
                <li class="map--list"></li>
                <li class="map--list"></li>
            </ul> -->
        </div>
        <!-- /비드톡톡은 이렇습니다 -->

        <!-- 그라디언트 -->
        <svg style="visibility: hidden; width: 0; height: 0;">
            <defs>
                <linearGradient id="gradient-full" x1="0%" y1="0%" x2="100%" y2="100%">
                    <stop offset="0%" stop-color="#ffffff" />
                    <stop offset="100%" stop-color="#ffffff00" />
                </linearGradient>
                <linearGradient id="gradient-half" x1="-50%" y1="-50%" x2="100%" y2="100%">
                    <stop offset="0%" stop-color="#ffffff" />
                    <stop offset="100%" stop-color="#ffffff00" />
                </linearGradient>
            </defs>
        </svg>
        <!-- /그라디언트 -->

        <!-- /비드톡톡은 이렇습니다 -->

        <!-- 앱다운로드 팝업 - QR추가 -->
        <div class="popup__wrap dowlod-guide__wrap" :class="isPopAppDown ? 'active' : ''">
            <div class="popup__item--container">
                <div class="popup__item--group dowlod-guide__group">
                    <div class="popup__item--head">
                        <p class="txt__title">비드톡톡 앱 다운로드</p>
                    </div>
                    <div class="popup__item--body dowlod-guide__group--body">
                        <p class="dowlod-guide--txt h2">Welcome 환영합니다!<span>원하시는 방법으로 앱을 다운로드 받아주세요.</span></p>
                        <div class="dowlod-guide__box dowlod-guide--img--suri">
                            <div class="dowlod-guide__item mail">
                                <div class="dowlod-guide--img--title mail"></div>
                                <p class="dowlod-guide--txt h3 dowlod-guide--img--num one">문자로 앱 다운로드 링크 받기</p>
                                <p class="dowlod-guide--txt h4">휴대폰번호를 입력해주시면 <br> 앱 다운로드 링크를 문자로 보내드려요.</p>
                                <div class="input dowlod-guide--input">
                                    <input type="text" class="input--valid disable" placeholder="휴대폰번호를 입력해 주세요." v-model="phoneNum" >
                                </div>
                                <div class="dowlod-guide__element">
                                    <div class="input--check dowlod-guide--check">
                                        <input type="checkbox" id="check13" v-model="indvdlinfoAuthz">
                                        <label for="check13"></label>
                                    </div>
                                    <a href="#" onclick="return false" @click="indvdlinfoAuthz = !indvdlinfoAuthz"> <span class="dowlod-guide--txt h4">(필수) 개인정보 수집 및 이용 동의</span></a>
                                    <p class="dowlod-guide--txt h5">앱 다운로드 링크 전송을 위해 전화번호를 수집하며,<br>수집일로부터 3개월간 보관됩니다.</p>
                                </div>
                                <div class="dowlod-guide--btn">
                                    <a href="#" onclick="return false" class="btn-basic btn-line-cb" @click="sendSms()">문자 발송</a>
                                </div>
                            </div>
                            <div class="dowlod-guide__item qr">
                                <div class="dowlod-guide--img--title lens"></div>
                                <p class="dowlod-guide--txt h3  dowlod-guide--img--num two">QR 코드 스캔하기</p>
                                <p class="dowlod-guide--txt h4">카메라로 QR코드를 스캔해 주세요.</p>
                                <div class="dowlod-guide--img qr"></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="popup__button--wrap single">
                    <a href="#" onclick="return false" class="btn-basic btn-cta popup__close"  @click="isPopAppDown = false;">닫기</a>
                </div>
            </div>
        </div>

        <!-- /앱다운로드 팝업 - QR추가 -->

        <!-- 분석 요청 제한 해제 안내 팝업 -->
        <div class="popup__wrap rstrt-over" :class="isPopNonFulfliment ? 'active' : ''">
            <div class="popup__item--container">
                <div class="popup__item--group">
                    <div class="popup__item--head rstrt-over--head">
                        <h3 class="txt__title">분석 요청 제한 해제 안내</h3>
                    </div>
                    <div class="popup__item--body rstrt-over--body">
                        <p class="txt__sub">분석 요청 제한이 해제되었습니다.</p>
                        <p class="txt__sub strong">{{custFulfilmentMngt != null ? ($moment(custFulfilmentMngt.lockEndDt).format('YYYY.MM.DD (ddd)')) : ''}}부터<br>분석 요청이 가능합니다.</p>
                        <div class="rstrt-over--img"></div>
                        <p class="txt__sub">AI 분석가와 분석기업 분석가 모두<br>좋은 결과를 위해 노력하고 있습니다.</p>
                        <p class="txt__sub strong">필요한 공고만 분석 요청하시고,<br>분석 가격 이행 잘 부탁드립니다.</p>
                    </div>
                </div>
                <div class="popup__button--wrap single">
                    <a href="#" onclick="return false" class="btn-basic btn-cta popup__close" @click="updateUnlockConfirm">확인</a>
                </div>
            </div>
        </div>
        <!-- /분석 요청 제한 해제 안내 팝업 -->
    </div>
</template>

<script>
    import $ from 'jquery';
    import 'slick-carousel/slick/slick.css';
    import 'slick-carousel/slick/slick.min.js';
    import DoughnutChart from "./doughnutChart.js";
    import * as _ from 'lodash';

    export default {
        name: 'HomeV2',
        components: {
            DoughnutChart
        },
        data() {
            return {
                loginInfo: {},
                regstCnt: 0,    //참가신청 미완료  건수
                bddprFinCnt: 0, //투찰 미완료 건수
                myAnalstStatistics: [],     //나의 분석가 순위
                myAnalstStatisticsGroup: [],//나의 분석가 순위 그룹
                isEmptyMyAnalst: true,
                custFulfilmentMngt: [],     //불이행 정보

                isPopAppDown: false,        //앱다운로드 팝업 여부
                isPopNonFulfliment: false,  //불이행 팝업 여부

                phoneNum : "",  //핸드폰번호
                indvdlinfoAuthz : false,    //개인정보 약관 동의 여부
                rankStndrdHistList: [],     //최근 랭킹 정보 가져오기
                homeSbidList: [],           //최근 낙찰 순위

                accorActive: false,

                //차트 데이트
                chartData: {
                    labels: [],
                    datasets: [
                        {
                            compListItemClass: [],
                            backgroundColor: [],
                            borderColor: "#eee",
                            hoverBorderColor: "#eee",
                            data: []
                        }
                    ]
                },
                //차트 옵션
                options: {
                    plugins: {
                        legend: {
                            display: false,
                        },
                        tooltip: {
                            boxWidth: 15,
                            bodyFont: {
                                size: 14
                            }
                        },
                        datalabels: {
                            display: false,
                        }
                        // datalabels: { // datalables 플러그인 세팅
                        //     // formatter: function (value, context) {
                        //     //     var idx = context.dataIndex; // 각 데이터 인덱스
                        //     //
                        //     //     // 출력 텍스트
                        //     //     return context.chart.data.labels[idx] + value + '%';
                        //     // },
                        //     // align: 'top', // 도넛 차트에서 툴팁이 잘리는 경우 사용
                        //     font: { // font 설정
                        //         weight: 'bold',
                        //         size: '12px',
                        //     },
                        //     color: '#222', // font color
                        // },
                    },
                    responsive: true,
                    // maintainAspectRatio: false,
                    animation: {
                        duration: 1300
                    }
                }
            }
        },
        watch: {
            isPopAppDown(newValue){
                if(newValue == true){
                    this.$scrollNon();
                }else{
                    this.$scrollActive();
                }
            },
            isPopNonFulfliment(newValue){
                if(newValue == true){
                    this.$scrollNon();
                }else{
                    this.$scrollActive();
                }
            },
        },
        filters: {
            decimal1: function (value) {
                var num = new Number(value);
                return num.toFixed(1);
            }
        },
        methods: {
            //문자전송팝업 오픈
            async openPop() {

                var tracking = {
                    "trackingCd": "1",
                    "trackingNm": "앱링크 다운로드",
                    "trackingAction" : "CLICK",
                    "trackingDesc" : "",
                };

                await this.$insertTracking(tracking);

                this.isPopAppDown = true;
            },

            //문자전송
            async sendSms() {
                if(this.phoneNum == ""){
                    this.$toast.success("휴대폰번호를 입력해 주세요.");
                    return ;
                }
                if(this.indvdlinfoAuthz == false){
                    this.$toast.success("개인정보 수집에 동의해 주세요.");
                    return ;
                }

                var clintIP = await this.$getPublicIP();

                var params = {
                    "recptnTelno": this.phoneNum.toString(),
                    "clientIp": clintIP,
                    "indvdlinfoAuthzYn": this.indvdlinfoAuthz ? 'Y' : 'N'
                };

                this.$http.post('/api/launcher/extrnl/sendAppLink',params)
                // eslint-disable-next-line no-unused-vars
                    .then(res => {
                        this.$toast.success("문자 발송이 완료되었습니다.");
                        this.phoneNum = "";
                        this.indvdlinfoAuthz = false;
                        this.isPopAppDown = false;
                    })

                    .catch(error => {
                        console.log(error);
                        this.isPopAppDown = false;
                    }
                    );

            },
            //불이행 여부 체크
            getFulfilmentMngt(){
                if(this.loginInfo == null){
                    return;
                }
                this.$get('/api/cust/fulfilmentMngt/info')
                    .then(res => {
                        const today = this.$moment();
                        this.custFulfilmentMngt = res.data;

                        if(this.custFulfilmentMngt != null){
                            var lockEndDt= this.$moment(this.custFulfilmentMngt.lockEndDt);

                            //오늘날짜가 분석요청제한 마감일 이 후 인데 anlsUnlockConfirmDt 가 null 일 경우
                            if( today.isAfter(lockEndDt) &&  this.custFulfilmentMngt.anlsUnlockConfirmDt == null && this.custFulfilmentMngt.lockStatus == 'N') {
                                this.isPopNonFulfliment = true;
                            }
                        }
                    })
                    .catch(error => console.log(error));
            },
            //불이행 해제 팝업 확인
            updateUnlockConfirm(){
                var param = {
                    "id":  this.custFulfilmentMngt.id
                };

                this.$post('/api/cust/fulfilmentMngt/unlockConfirm',param)
                    .then(res => {
                        console.log(res);
                        this.isPopNonFulfliment = false;
                    })
                    .catch(error => console.log(error));
            },
            mainSlideSlick() {
                $('.visual-slide__area.contents').slick({
                    dots: true,
                    arrows: false,
                    infinite: true,
                    autoplay: true,
                    autoplaySpeed: 5500,
                    appendDots: '.visual-slide__area.pagntion',
                    customPaging: function (slider, i, ment) {
                        if (i == '0') {
                            ment = '<span class="new">AI멤버십 낙찰 </span>' + '<span class="empha">수수료가 0원</span>';

                        } else if (i == '1') {
                            ment = '특허 출원 중인 ' + '<span class="empha">AI</span>';

                        } else if (i == '2') {
                            ment = '<span class="empha">마음이 가는</span>' + ' 대로';
                        } else if (i == '3') {
                            ment = '나도 모르는' + '<span class="empha"> 내 투찰 성향!?</span>';
                        }
                        return '<a class="h3" role="tab"><span class="h4">0' + (i + 1) + '</span>' + ment + '</span><i class="btn__view-more"></i></a>';
                    },
                });
            },
            topSlickAnimation() {
                ///슬릭
                $('.bid-result__slick').slick({
                    slidesToShow: 1,
                    SlidesToScroll: 1,
                    dots: false,
                    arrows: false,
                    vertical: true,
                    infinite: true,
                    speed: 1000,
                    autoplay: true,
                    swipe: false,
                    touchMove: false,
                    useTransform: true,
                    cssEase: 'cubic-bezier(0.645, 0.045, 0.355, 1.000)',
                    autoplaySpeed: 3000,
                });
                $('.bid-result__slick').slick('setPosition');
            },

            //실시간 분석가 순위 애니매이션
            anlsRankAnimation() {
                const rankAccoHead = document.querySelector('.rank__arcc--head');
                const rankAccobody = document.querySelector('.rank--logout');

                rankAccoHead.addEventListener('click', function(){
                    rankAccobody.classList.toggle('accor-active');
                });

                /* 일정 시간 지난 뒤 .accor-active 클래스 제거하기  */
                setTimeout(function(){
                    rankAccobody.classList.toggle('accor-active');
                }, 6500);
                /* /일정 시간 지난 뒤 .accor-active 클래스 제거하기  */

                /*  떠오르는 애니메이션 */
                setTimeout(function() {
                    const itemRank = document.querySelectorAll('.rank__unit');
                    itemRank.forEach(element => {
                        element.classList.add('active')
                    });

                }, 700);
                /*  /떠오르는 애니메이션 */

                /* 뒤집히는 애니메이션 */
                setTimeout(function() {
                    const itemRankList =  document.querySelectorAll('.rank__list--item');
                    itemRankList.forEach(element => {
                        element.classList.add('ani-reverse')
                    });

                }, 700);

                /* 화살표 애니메이션 */

                setTimeout(function() {
                    const itemsArrow = document.querySelectorAll('.sum.active .sum--ic');
                    let arrowIndex = 0;
                    let interval = setInterval(function() {
                        if(itemsArrow.length <= arrowIndex){
                            clearInterval(interval);
                        } else {
                            itemsArrow[arrowIndex].style.animation = 'sum-ic-Ani 0.5s forwards';
                            arrowIndex++;
                        }
                    }, 400);
                }, 2500);

                /* /화살표 애니메이션 */

                /* 메달 애니메이션 */
                setTimeout(function() {
                    let itemMedal = document.querySelectorAll('.medal');
                    setInterval(function() {
                        itemMedal.forEach(element => {
                            element.classList.toggle('active');
                        });
                    }, 3000);
                }, 4000);
                /* /메달 애니메이션 */
            },
            //비드톡톡 서비스 에니메이션
            bidtokServiceAnimation() {
                let itemService = document.querySelectorAll('.intro-service__list--item');
                let serviceIndex = 0;

                function serviceActvie() {

                    itemService[serviceIndex].classList.add('active');
                    serviceIndex++;

                    if (serviceIndex === itemService.length) {
                        serviceIndex = 0;
                    }
                }

                setTimeout(function() {
                    setInterval(serviceActvie, 200);
                }, 800);
            },
            rollingAnimation(){
                // 롤링 배너 복제본 생성
                let roller = document.querySelector('.rolling-list');
                roller.id = 'roller1'; // 아이디 부여

                let clone = roller.cloneNode(true)
                // cloneNode : 노드 복제. 기본값은 false. 자식 노드까지 복제를 원하면 true 사용
                clone.id = 'roller2';
                document.querySelector('.rolling-banner').appendChild(clone); // wrap 하위 자식으로 부착

                document.querySelector('#roller1').style.left = '0px';
                document.querySelector('#roller2').style.left = document.querySelector('.rolling-list ul').offsetWidth + 'px';
                // offsetWidth : 요소의 크기 확인(margin을 제외한 padding값, border값까지 계산한 값)

                roller.classList.add('original');
                clone.classList.add('clone');

                //hover시 리스트 멈추기
                let listAll = document.querySelectorAll('.rolling-list');
                listAll.forEach(list => {

                    list.addEventListener('mouseover', function() {
                        //list.style.animationPlayState = 'paused';
                        listAll.forEach(element => {
                            element.style.animationPlayState = 'paused';
                        });
                    });

                    list.addEventListener('mouseleave', function() {
                        //list.style.animationPlayState = 'running';
                        listAll.forEach(element => {
                            element.style.animationPlayState = 'running';
                        });
                    });
                });
            },
            //비드톡톡 소개 애니메이션
            introBidtokAnimation() {
                const itemsKorea = document.querySelectorAll('.korean--item');
                const itemsEnglish = document.querySelectorAll('.english--item');
                const itemsDescription = document.querySelectorAll('.description--item');
                const itemsEarth = document.querySelectorAll('.bg-earth--txt--list');
                let index = 0;


                function toggleActiveClass() {
                    itemsKorea.forEach(item => item.classList.remove('active'));
                    itemsEnglish.forEach(item => item.classList.remove('active'));
                    itemsDescription.forEach(item => item.classList.remove('active'));
                    itemsEarth.forEach(item => item.classList.remove('active'));

                    itemsKorea[index].classList.add('active');
                    itemsEnglish[index].classList.add('active');
                    itemsDescription[index].classList.add('active');
                    itemsEarth[index].classList.add('active');

                    index++;
                    if (index === itemsKorea.length) {
                        index = 0;
                    }

                }

                toggleActiveClass();
                setInterval(toggleActiveClass, 7500);
            },
            /** 분석가 목록*/
            async getNotiAnlst() {
                this.notiAnlstList = [];

                await this.$http.get('/api/noti/anlstList')
                    .then(res => {
                        var notiAnlstList = res.data.notiAnlstList;
                        this.notiAnlstList = notiAnlstList;
                    })
                    .catch(error => console.log(error));
            },
            //스크롤 애니메이션
            scrollAnimation() {
                ////////////////////////////////////////////////////////////////////

                /*  스크롤 애니메이션  */

                ////////////////////////////////////////////////////////////////////

                /*  기준 영역 변수 설정  */
                const boxes = document.querySelectorAll('.std');


                //1. 스크린 크기에 따른 애니메이션 구간 설정

                function calculateAnimationAreas() {
                    const windowWidth = window.innerWidth;

                    if (windowWidth >= 1001) {
                        return {
                            areaTxt: window.innerHeight / 5 * 4
                        };
                    } else if (windowWidth >= 801) {
                        return {
                            areaTxt: window.innerHeight * 0.8
                        };
                    } else  {
                        return {
                            areaTxt: window.innerHeight * 0.8
                        };
                    }
                }


                //2. 텍스트 애니메이션 영역에 해당할 때 클래스 추가 / 제거
                function applyTxtAni(elements, condition) {

                    elements.forEach((element, index) => {
                        setTimeout(() => {
                            if (condition) {
                                element.classList.add('active');
                            } else {
                                element.classList.remove('active');
                            }
                        }, index * 150);
                    });
                }

                // 3. 텍스트 애니메이션이 될 클래스 & 애니메이션이 될 영역 매개변수로 넘김
                function txtAni() {

                    const { areaTxt } = calculateAnimationAreas();
                    // 스크린에 따라 애니메이션 되어야 할 영역 가져옴

                    boxes.forEach((box) => {

                        const boxTop = box.getBoundingClientRect().top;
                        const boxHeight = box.getBoundingClientRect().height;

                        const fIElements = box.querySelectorAll('.f-i')
                        const highlight = box.querySelectorAll('.higlight');

                        setTimeout(() => {
                            applyTxtAni(fIElements, boxTop <=  areaTxt  && boxTop > -boxHeight);
                            applyTxtAni(highlight, boxTop <=  areaTxt  && boxTop > -boxHeight);
                        }, 1000);

                    });
                }
                txtAni();
                window.addEventListener('resize', txtAni);
                window.addEventListener('load', txtAni);
                window.addEventListener('scroll', txtAni);
            },
            //오늘의 투찰 리포트
            getWebHomeInfo() {
                if (this.loginInfo != null) {

                    this.$get('/api/home/webHomeInfo')
                        .then(res => {
                            this.regstCnt = res.regstCnt;
                            this.bddprFinCnt = res.bddprFinCnt;
                            this.myAnalstStatistics = res.myAnalstStatistics;
                            this.myAnalstStatistics = _.filter(this.myAnalstStatistics, function(o) {
                                return o.id == '1' || o.id == '2' || o.id == '3' || o.id == '4' || o.id == '5' || o.id == '6' ||
                                        o.id == '7' || o.id == '8' || o.id == '9';
                            });
                            this.myAnalstStatisticsGroup = [];

                            var index = 0;
                            //나의 분석가 순위
                            this.myAnalstStatistics.forEach(item => {
                                if (!this.myAnalstStatisticsGroup[index]) {
                                    this.myAnalstStatisticsGroup[index] = []; // Initialize an array for the category
                                }
                                this.myAnalstStatisticsGroup[index].push(item);

                                if(  this.myAnalstStatisticsGroup[index].length > 4) {
                                    index ++;
                                }
                            });

                            //차트데이터
                            this.setChartData();

                        })
                        .catch(error => console.log(error));
                }
            },
            setChartData() {
                var temp =[
                    { classNm : "dog" , color : "#F3B386" },
                    { classNm : "peng" , color : "#7B93E6" },
                    { classNm : "suri" , color : "#5CAEE5" },
                    { classNm : "jin" , color : "#EC9B00" },
                    { classNm : "inbid" , color : "#5688F9" },
                    { classNm : "bidplus" , color : "#9752FA" },
                    { classNm : "sol" , color : "#017855" },
                    { classNm : "bidin" , color : "#F86F6F" },
                    { classNm : "chung" , color : "#3844F5" },
                ]

                this.chartData.labels = [];
                this.chartData.datasets[0].backgroundColor = [];
                this.chartData.datasets[0].data = [];
                this.isEmptyMyAnalst = true;

                this.myAnalstStatistics.forEach(item => {
                    if(item.anlsCnt != 0){
                        this.chartData.labels.push(item.anlstNickNm);
                        this.chartData.datasets[0].backgroundColor.push(temp[item.id-1].color);
                        this.chartData.datasets[0].compListItemClass.push(temp[item.id-1].classNm);
                        var num = new Number(item.anlsRatio);
                        this.chartData.datasets[0].data.push(num.toFixed(1));
                        this.isEmptyMyAnalst = false;
                    }

                });
            },
            getRankStndrdList() {

                this.$get('/api/comnHome/rankStndrdList')
                    .then(res => {
                        var rankStndrdList = res.rankStndrdHistList;
                        rankStndrdList = _.filter(rankStndrdList, function(o) {
                            return o.notiAnlstId == '1' || o.notiAnlstId == '2' || o.notiAnlstId == '3' || o.notiAnlstId == '4' || o.notiAnlstId == '5' || o.notiAnlstId == '6' ||
                                    o.notiAnlstId == '7' || o.notiAnlstId == '8' || o.notiAnlstId == '9';
                        });

                        for(var i in rankStndrdList) {
                            var index = this.notiAnlstList.findIndex((e) => e.id == rankStndrdList[i].notiAnlstId);

                            if (index != -1) {
                                rankStndrdList[i].iconImg = this.notiAnlstList[index].iconImg;
                                rankStndrdList[i].anlstNickNm = this.notiAnlstList[index].anlstNickNm;
                                rankStndrdList[i].anlstType = this.notiAnlstList[index].anlstType;
                            } else {
                                rankStndrdList[i].iconImg = '/assets/images/logo_bidtok_other-bid.png';
                                rankStndrdList[i].anlstNickNm = '';
                                rankStndrdList[i].anlstType = '';
                            }
                            if(rankStndrdList[i].ranking == 1){
                                rankStndrdList[i].rankClass = 'medal gold';
                            } else if(rankStndrdList[i].ranking == 2){
                                rankStndrdList[i].rankClass = 'medal silver';
                            } else if (rankStndrdList[i].ranking == 3){
                                rankStndrdList[i].rankClass = 'medal bronze';
                            }

                            this.rankStndrdHistList.push(rankStndrdList[i]);

                        }
                    })
                    .catch(error => console.log(error));
            },

            getHomeSbidList() {

                this.$get('/api/comnHome/homeSbidList')
                    .then(res => {
                        var sbidList = res.homeSbidList;
                        for(var i in sbidList) {
                           //금액 치환
                            sbidList[i].bddprAmt = sbidList[i].bddprAmt + ',***,***';

                            //날짜 조절
                            sbidList[i].opbdDt = this.$moment(sbidList[i].opbdDt).format('MM월 DD일');

                            this.homeSbidList.push(sbidList[i]);

                        }

                        setTimeout(function(){
                            $('.bid-result__slick').slick({
                                slidesToShow: 1,
                                SlidesToScroll: 1,
                                dots: false,
                                arrows: false,
                                vertical: true,
                                infinite: true,
                                speed: 1000,
                                autoplay: true,
                                swipe: false,
                                touchMove: false,
                                useTransform: true,
                                cssEase: 'cubic-bezier(0.645, 0.045, 0.355, 1.000)',
                                autoplaySpeed: 3000,
                            });
                            $('.bid-result__slick').slick('setPosition');
                        }, 2000);

                    })
                    .catch(error => console.log(error));
            },

            //비드톡톡 관리자 로그인
            async bidtokLogin(encryptedData) {
                try {
                    if(encryptedData == null){
                        return;
                    }

                    const res = await this.$http.post("/api/managerLogin?q=" + encryptedData);

                    if(res.data.cust != null){

                        localStorage.setItem("authToken",res.data.authToken);
                        localStorage.setItem("refreshToken",res.data.refreshToken);
                        localStorage.setItem("custInfo",JSON.stringify(res.data.cust));
                        this.existToken = true;
                        this.custInfo = JSON.parse(localStorage.getItem("custInfo"));

                        // this.$socket.emit('login',{
                        //     id: this.custInfo.id,
                        //     alias: this.custInfo.cmpNm
                        //
                        // });
                        var nextPage = res.data.nextPage;

                        if(nextPage != null && nextPage != '') {
                            this.$router.replace('/' + nextPage);
                        } else {

                            this.$router.replace('/home');
                        }

                        if (!this.isPageRefreshed) {
                            window.location.reload(true);
                            this.isPageRefreshed = true;
                        }
                    } else {
                        this.$toast.error('로그인실패');
                    }

                } catch (error) {
                    console.error("error", error);
                }
            },
        },
        async created() {
            // 회원정보 갱신
            var self = this;
            this.$parent.getCustInfoComn(function() {
                self.loginInfo = self.$parent.loginInfo;

                //오늘의 투찰 리포트
                self.getWebHomeInfo();

                //불이행 정보
                self.getFulfilmentMngt();
            });

            // this.loginInfo = JSON.parse(localStorage.getItem("custInfo"));

            await this.getNotiAnlst();

            //실시간 분석 순위
            this.getRankStndrdList();

            //최근 낙찰 순위
            this.getHomeSbidList();

            // if(Object.keys(this.loginInfo).length != 0) {
                //나의 분석 요청율

                //오늘의 투찰 리포트
                // this.getWebHomeInfo();

                //불이행 정보
                // this.getFulfilmentMngt();
            // }

        },
        mounted() {
            // const script = document.createElement('script');
            // script.src = 'https://cdnjs.cloudflare.com/ajax/libs/lazysizes/5.3.2/lazysizes.min.js';
            // document.head.appendChild(script);

            // todo iOS만 판별하게 추가
            window.onpopstate = function() {
                setTimeout(() => {
                    //$("#clickBtn").trigger("click");
                    window.scroll({top: 1, left: 0, behavior: 'smooth'});
                }, 150);
            };
            //
            // document.addEventListener("backbutton", this.yourCallBackFunction, false);
            //
            // if (document.addEventListener) {
            //     window.addEventListener('pageshow', function(e) {
            //         console.log(e);
            //         if (e.persisted) {
            //             window.location.reload();
            //         }
            //     })
            // }

            window.scroll({top: 0, left: 0, behavior: 'smooth'});
            //hover시 리스트 멈추기
            let listAll = document.querySelectorAll('.rolling-list');
            window.onpageshow = function(event){
                if ( event.persisted || (window.performance && window.performance.navigation.type == 2) ){

                    listAll.forEach(element => {
                        element.style.animationPlayState = 'running';
                    });
                }
            };
            this.bidtokLogin(this.$route.query.q);
            this.mainSlideSlick();
            // this.subTitleAnimation();
            this.anlsRankAnimation();
            this.bidtokServiceAnimation();
            this.rollingAnimation();
            this.introBidtokAnimation();
            this.scrollAnimation();
        },
    }


</script>
<style>
  /*.lazyload {*/
  /*  background-color: #f0f0f0;*/
  /*  background-size: cover;*/
  /*  background-position: center;*/
  /*  width: 100%;*/
  /*  height: 400px;*/
  /*}*/
</style>