<template>
    <div>
        <div v-if="loading">
            <div class="loading loading-box">
            <LoadingSpinner></LoadingSpinner>ㅤ
            </div>
        </div>
        <div v-else-if="cntrctHtml" v-html="cntrctHtml"></div>
<!--<div class="clause wrap agree">-->
<!--  <div class="container">-->
<!--    <div class="docmngmt-wrap">-->
<!--      &lt;!&ndash; 1page &ndash;&gt;-->
<!--      <div class="page">-->
<!--        <h1 class="sy-title tc">계&nbsp;&nbsp;&nbsp;약&nbsp;&nbsp;&nbsp;서</h1>-->
<!--        <table class="sy-grid sy-grid-border sy-grid-fixed">-->
<!--          <colgroup>-->
<!--            <col style="width: 12%;" />-->
<!--            <col style="width: 23%;" />-->
<!--            <col style="width: 65%;" />-->
<!--          </colgroup>-->

<!--          <tbody>-->
<!--          <tr>-->
<!--            <td class="darkgray-bgcolor item-bold tc" rowspan="4">계약자</td>-->
<!--            <td class="darkgray-bgcolor2 item-bold tc" rowspan="2">정보 제공자 ("동")</td>-->
<!--            <td class="sy-item-group font0">-->
<!--              <strong class="">회사명</strong>-->
<!--              <strong class="pl5 item-bold">주식회사 비드톡톡</strong>-->
<!--            </td>-->
<!--          </tr>-->
<!--          <tr>-->
<!--            <td class="sy-item-group font0">-->
<!--              <strong class="">대표이사</strong>-->
<!--              <strong class="pl5 item-bold">최경호</strong>-->
<!--            </td>-->
<!--          </tr>-->
<!--          <tr>-->
<!--            <td class="darkgray-bgcolor2 item-bold tc" rowspan="2">정보 이용자 ("행")</td>-->
<!--            <td class="sy-item-group font0">-->
<!--              <strong class="">회사명</strong>-->
<!--              <strong class="pl5 item-bold">#{CMP_NM}</strong>-->
<!--            </td>-->
<!--          </tr>-->
<!--          <tr>-->
<!--            <td class="sy-item-group font0">-->
<!--              <strong class="">대표이사</strong>-->
<!--              <strong class="pl5 item-bold">#{USER_NM}</strong>-->
<!--            </td>-->
<!--          </tr>-->
<!--          <tr>-->
<!--            <td class="darkgray-bgcolor item-bold tc" rowspan="6">계약내용</td>-->
<!--            <td class="darkgray-bgcolor2 item-bold tc">계약명</td>-->
<!--            <td>AI멤버십 서비스 동의 계약 (#{planNm})</td>-->
<!--          </tr>-->
<!--          <tr>-->
<!--            <td class="darkgray-bgcolor2 item-bold tc">계약 금액</td>-->
<!--            <td class="sy-item-group font0">-->
<!--              <strong class="">금</strong>-->
<!--              <strong class="pr5 pl5 item-bold item-undl">#{planPrice}</strong>-->
<!--              <strong class="">원&nbsp;(VAT포함)</strong>-->
<!--            </td>-->
<!--          </tr>-->
<!--          <tr>-->
<!--            <td class="darkgray-bgcolor2 item-bold tc">성공 보수료</td>-->
<!--            <td class="item-bold">※ 표1에서 제시하는 바에 따름</td>-->
<!--          </tr>-->
<!--          <tr>-->
<!--            <td class="darkgray-bgcolor2 item-bold tc">성공 보수료 지급 조건</td>-->
<!--            <td class="item-bold">※ 분석기업의 경우 낙찰일로부터 14일 이내</td>-->
<!--          </tr>-->
<!--          <tr>-->
<!--            <td class="darkgray-bgcolor2 item-bold tc">계약 기간</td>-->
<!--            <td class="sy-item-group font0 item-bold">-->
<!--              <strong class="">#{subStrtDt}</strong>-->
<!--              <strong class="pr5 pl5">~</strong>-->
<!--              <strong class="">#{subEndDt}</strong>-->
<!--            </td>-->
<!--          </tr>-->
<!--          <tr>-->
<!--            <td class="darkgray-bgcolor2 item-bold tc">기타</td>-->
<!--            <td class="item-bold lh120p">※ 별첨 계약 내용에 정한 바에 따름</td>-->
<!--          </tr>-->
<!--          </tbody>-->
<!--        </table>-->
<!--        <p class="mt10 lh140p">주식회사 비드톡톡과 정보이용자(“행”)는 표지 및 별첨 계약내용에 따라 본 “AI멤버십 서비스 동의 계약”을 체결하고 신의에 따라 성실하게 계약상 의무를 이행할 것을 확약하였으며 이 계약의 증거로서 내용 확인 후 동의함으로서 계약 성립은 유효하다.</p>-->

<!--        <h2 class="sy-title clearfix">-->
<!--          <span>※ 분석가에 따른 성공보수료</span>-->
<!--          <span>(VAT 포함)</span>-->
<!--        </h2>-->

<!--        <table class="sy-grid sy-grid-border sy-grid-fixed mt10 tc">-->
<!--          <colgroup>-->
<!--            <col style="width:12%;" />-->
<!--            <col style="width:21%;" />-->
<!--          </colgroup>-->
<!--          <tbody>-->
<!--          <tr>-->
<!--            <td class="darkgray-bgcolor item-bold" colspan="2">AI멤버십 동의 후 <i class="unline">이용 대금을 납부한</i> 경우 아래와 같은 성공 보수료율 적용</td>-->
<!--          </tr>-->
<!--          <tr>-->
<!--            <td class="darkgray-bgcolor2 item-bold lh120p">AI분석<br>펭, 도그, 수리</td>-->
<!--            <td>없음</td>-->
<!--          </tr>-->
<!--          <tr>-->
<!--            <td class="darkgray-bgcolor2 item-bold lh120ps">분석기업<br>-->
<!--              진비드, 인비드, 청비드, 솔비드,<br>비드플러스, 인비드</td>-->
<!--            <td>낙찰가의 2% (부가세 포함)</td>-->
<!--          </tr>-->
<!--          </tbody>-->
<!--        </table>-->
<!--        <p class="mt10 tc lh140p">※ AI분석으로 낙찰할 경우 성공보수료 없으며, 분석기업으로 낙찰할 경우 낙찰가의 2% 성공보수료율이 적용됩니다.<br/>-->
<!--          ※ AI멤버십 동의 후 이용 대금을 납부하지 않은 경우에는 [전자입찰 분석정보 제공계약서]의 계약 내용에 따릅니다.-->
<!--        </p>-->
<!--        <p class="mt5 tc">-->
<!--          〈표1〉-->
<!--        </p>-->

<!--&lt;!&ndash;              <table class="sy-grid sy-grid-border sy-grid-fixed mt10 tc">&ndash;&gt;-->
<!--&lt;!&ndash;                <colgroup>&ndash;&gt;-->
<!--&lt;!&ndash;                  <col style="width:12%;" />&ndash;&gt;-->
<!--&lt;!&ndash;                  <col style="width:21%;" />&ndash;&gt;-->
<!--&lt;!&ndash;                </colgroup>&ndash;&gt;-->
<!--&lt;!&ndash;                <tbody>&ndash;&gt;-->
<!--&lt;!&ndash;                <tr>&ndash;&gt;-->
<!--&lt;!&ndash;                  <td class="darkgray-bgcolor item-bold" colspan="2">AI멤버십 동의 후 <i class="unline">이용 대금을 납부하지 않은 경우</i>에는 [전자입찰 분석정보 제공계약서]의 계약 내용에 따른다.</td>&ndash;&gt;-->
<!--&lt;!&ndash;                </tr>&ndash;&gt;-->
<!--&lt;!&ndash;                </tbody>&ndash;&gt;-->
<!--&lt;!&ndash;              </table>&ndash;&gt;-->
<!--        <p class="docmngmt-wrap_date item-bold tc"><strong>#{currentDate}</strong></p>-->

<!--        <p class="lh140p">※ 주식회사 비드톡톡 임직원들은 [AI멤버십 서비스 동의 계약]에 명시된 계약금 및 성공보수료 이외에 어떠한 금품, 향응, 선물 그리고 편의를 수수하거나 제공을 요청하지 않으며 이를 위배한 임직원은 사규에 따라 징계합니다.</p>-->

<!--        <table class="sy-grid sy-grid-border sy-grid-fixed mt20 tc">-->
<!--          <colgroup>-->
<!--            <col style="width:50%;" />-->
<!--            <col style="width:20%;" />-->
<!--            <col style="width:30%;" />-->
<!--          </colgroup>-->
<!--          <tbody>-->
<!--          <tr>-->
<!--            <td class="lh120p">대전광역시 서구 계룡로553번길 18, 조이빌딩 9층</td>-->
<!--            <td>주식회사 비드톡톡</td>-->
<!--            <td class="lh120p">TEL 1670-0508<br />FAX 042-367-3313</td>-->
<!--          </tr>-->
<!--          </tbody>-->
<!--        </table>-->
<!--      </div>-->
<!--      &lt;!&ndash; //1page &ndash;&gt;-->

<!--      &lt;!&ndash; 2page &ndash;&gt;-->
<!--      <div class="page">-->
<!--        <h1 class="sy-title tc clearfix">계&nbsp;&nbsp;&nbsp;약&nbsp;&nbsp;&nbsp;내&nbsp;&nbsp;&nbsp;용<sub class="mt10">-별첨-</sub></h1>-->

<!--        <h2 class="sy-title mt15">제 1조 (계약의 목적)</h2>-->
<!--        <p class="mt5 lh120p">본 계약은 “동”이 “행”에게 전자 입찰 분석 정보를 제공하고 “행”은 “동”이 제공하는 전자 입찰 분석 정보를 이용하며 그 대가를 “동”에게 지급하는 것과 관련하여 필요한 사항을 정함을 목적으로 한다.</p>-->

<!--        <h2 class="sy-title mt15">제2조 (“동”의 의무)</h2>-->
<!--        <p class="mt5 lh120p">본 계약에 따라 “동”은 “동”이 운영하는 인터넷 웹페이지(www.bidtok.co.kr)와 어플리케이션(비드톡톡)을 통하여 국가나 지방자치단체가 공고한 각종 입찰 관련 정보를 “행”에게 제공하기로 한다. 또한 “행”이 “동”에게 요청하는 입찰건에 대해서도 동일하게 정보를 제공하기로 한다. “동”이 “행”에게 제공하는 정보의 종류와 범위는 “동”의 웹페이지와 어플리케이션을 통해 제공되고 있는 내용을 기본으로 하며 유선, 문자, SNS, 이메일, 팩스 등도 포함된다.</p>-->

<!--        <h2 class="sy-title mt15">제3조 (“행”의 의무)</h2>-->
<!--        <p class="mt5 lh120p">① “행”은 “동”으로부터 제2조의 정보를 제공 받는데에 대한 대가로 제4조 ①항에 정한 액수에 따라 “동”에게 이용대금을 지급하여야 한다. 계약 금액이 지급된 후부터 분석실을 통한 가격 제시가 시작되는 것으로 한다.</p>-->
<!--        <p class="lh120p">② “행”은 본 계약 체결일로부터 분석기업(진비드, 인비드, 비드인, 청비드, 솔비드, 비드플러스를 칭하며 이하 “분석기업”으로 정한다)으로 분석요청한 입찰 공고에 대하여 분석실을 통하여 제시 가격을 확인하며 확인한 가격에 대하여 가격 이행을 하여야 한다. 단, 가격 불이행을 했을 경우 횟수에 따라 “동”은 “행”에 대해 등급을 변동 할 수 있다. (단, 가격 불이행 가격이 낙찰가가 된 경우에는 횟수에 관계없이 즉시 등급을 변동할 수 있다) (비드톡톡 이용약관 제10조 참조)</p>-->

<!--        <h2 class="sy-title mt15">제4조 (이용 대금)</h2>-->
<!--        <p class="mt5 lh120p">① “행”은 본 계약 체결과 동시에 “동”에게 본 계약서 표지에 정한 “계약 금액”을 지급한다. “동”의 귀책사유로 “행”에 대한 정보 제공이 불가능해진 경우를 제외하고 “행”은 “동”에게 기본 계약 금액의 반환을 청구할 수 없다.</p>-->
<!--        <p class="lh120p">② “행”이 “동”으로부터 분석기업에게 분석요청하여 제시 받은 가격으로 입찰에 참가하여 최종 낙찰자로 선정 된 경우(“행”이 동가의 1순위가 된 후 추첨을 통하여 최종 낙찰이 확정된 경우, “행”보다 선 순위의 입찰 참가자가 있음에도 불구하고 “행”이 최종 낙찰이 된 경우 등을 포함) “행”은 “동”에게 본 계약서 표지에 정한 “성공 보수료”를 지급한다.단, 단가 계약(공사, 납품, 구매 설치, 용역 수행 등)의 경우 총 공사 예정 금액 또는 총 배정 예산으로 성공 보수료를 지급한다.</p>-->
<!--        <p class="lh120p">③ 공동 도급을 진행하여(구성 업체,분담 업체) 최종 낙찰자가될 경우 최종 공사 비율에 따라 본 계약서 표지에 정한 성공 보수료를 지급한다.</p>-->
<!--        <p class="lh120p">④ “행”은 “동”의 웹페이지 및 어플리케이션 상의 “분석실”을 통해 “분석제시가 확인” 버튼을 클릭한 공고 건에 관여하는 “행”이 해당 공고건의 입찰에 참여한 경우 “동”으로부터 제시 받은 가격으로 입찰에 참여한 것으로 보아 ②항을 적용하기로 한다.</p>-->
<!--        <p class="lh120p">⑤ 분석기업으로 분석요청한 공고의 가격이행 여부의 표준 오차는 ±0.1% 범위로 인정하거나 또는 비드톡톡 가격 제시 기준 절상, 절하 (ex 만원단위, 십만단위, 백만단위 등) 일때 혹은 임의로 수정할 경우 또한 이행 여부 범위로 인정하여 성공 보수료를 지급한다. (분석서비스 이용약관 제13조 참조)</p>-->
<!--        <p class="lh120p">⑥ “행”은 본 계약서 표지에 정한 “성공 보수료”를 “동”의 성공 낙찰 일로부터 14 일 이내에 지급하여야 하며 지체하는 경우 “동”은 “행”에 대한 정보 제공을 중단할 수 있다. 다만 성공 보수 금액이 1,000만원 이상일 경우 “행”은 성공 보수 금액 중 50%의 지급 유예를 신청할 수 있으며, “동”은 “행”의 지불 능력 기타 제반 사정을 참작하여 지급 유예를 승인할 수 있다.(단,지급 유예 기간은 최장 60일 이내로 한다.)</p>-->

<!--        <h2 class="sy-title mt15">제5조 (환불 규정)</h2>-->
<!--        <p class="mt5 lh120p">① “행”이 계약 체결 및 입금이 확인된 이후 “행”의 개인사정으로 인해 계약 해지를 요청한 경우 아래의 환불 규정에 따르며, 환불에 대한 수수료는 회원 개인이 부담합니다.-->
<!--          단, 해지의 효력은 당 사이트 운영자에게 표시한 해지의 의사가 효력이 발하는 익일로 일할 계산됩니다.</p>-->
<!--        <p class="mt5 lh120p">A 총 결제 금액의 10%</p>-->
<!--        <p class="mt5 lh120p">B 결제한 멤버십 이용대금 ÷ 30일 / 180일 / 360일 (소수점 이하 단위는 반올림)</p>-->
<!--        <p class="mt5 lh120p">C 해지 의사를 표현한 날짜까지의 사용일 수</p>-->
<!--        <p class="mt5 lh120p">환불금액 = 결제금액 - A - (B × C)</p>-->
<!--        <p class="mt5 lh120p">② 환불 금액은 요청 후 7일 이내로 입금됩니다.</p>-->


<!--        <h2 class="sy-title mt15">제6조 (비밀 보장)</h2>-->
<!--        <p class="mt5 lh120p">① “행”은 본 계약에 따라 “동”으로부터 제시 받은 가격을 제3자에게 공개하거나 양도 할 수 없으며 이를 위반함으로 인해 “동” 이 입은 손해를 배상해야 한다. 또한 “행”은 본 계약에 의하여 지득한 “동”의 자료 및 정보 등의 비밀 (이미 일반에 알려진 정보는 제외)을 타인에게 누설 또는 공개 할 수 없으며 이를 위반함으로 인해 “동” 이 입은 손해를 배상해야 한다.</p>-->

<!--        <h2 class="sy-title mt15">제7조 (분쟁의 해결)</h2>-->
<!--        <p class="mt5 lh120p">본 계약과 관련하여 발생하는 분쟁은 대전지방법원을 제1심 관할법원으로 하여 해결한다.</p>-->

<!--        <table class="sy-grid sy-grid-border sy-grid-fixed mt20">-->
<!--          <colgroup>-->
<!--            <col style="width:43%;" />-->
<!--            <col style="width:37%;" />-->
<!--            <col style="width:22%;" />-->
<!--          </colgroup>-->
<!--          <tbody>-->
<!--          <tr>-->
<!--            <td class="lh120p">“행”은 본 계약서 내용을 반드시 숙지하고 동의하며 원만히 계약이 성사되었음을 인정합니다. 위 계약 내용에 대해 동의 하십니까?</td>-->
<!--            <td class="sy-item-group font0 lh120p">-->
<!--              <strong>회사명:</strong>-->
<!--              <strong class="ml5 item-bold">#{CMP_NM}</strong>-->
<!--            </td>-->
<!--            <td class="tc">-->
<!--              <div class="item-bold"><i class="sy-icon fa-check-square font18 blue-color"></i>&nbsp;동의</div>-->
<!--              <div class="mt5 item-bold">#{subApplyDt}</div>-->
<!--              <div class="mt5 item-bold">#{todayTime}</div>-->
<!--            </td>-->
<!--          </tr>-->
<!--          </tbody>-->
<!--        </table>-->
<!--      </div>-->
<!--    </div>-->
<!--  </div>-->
<!--</div>-->
    </div>
</template>
<style>
    html, body, div, span, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, abbr, address, cite, code, del, dfn, em, img, ins, kbd, q, samp, small, strong, sub, sup, var, b, i, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, figcaption, figure, footer, header, hgroup, menu, nav, section, summary, time, mark, audio, video{margin:0;padding:0;border:0;font-size:100%;font:inherit;vertical-align:baseline;}
    body{line-height:1;background:#fff;font-family:Tahoma, sans-serif;font-size:9.5pt;}
    article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section{display:block;}
    ul, li, ol{list-style:none;}
    blockquote, q{quotes:none;}
    blockquote:before, blockquote:after, q:before, q:after{content:'';content:none;}
    em{font-style:normal;}
    a{margin:0;padding:0;background:transparent;color:#333;text-decoration:none;outline:none;vertical-align:baseline;-webkit-tap-highlight-color:transparent;}
    a:hover{text-decoration:underline;}
    img{vertical-align:top;border:none;}
    form{border:none;}
    table{border-collapse:collapse;border-spacing:0;}
    *, *:before, *:after{box-sizing:border-box;-webkit-box-sizing:border-box;}
    .sy-title{font-size:24pt;font-weight:bold;}
    .sy-title small{font-weight:normal;font-size:65%;}
    .sy-title .sy-icon{margin-right:3px;font-size:90%;}
    h2.sy-title{font-size:16pt;}
    h3.sy-title{font-size:10pt;}
    .sy-icon{font:normal normal normal 14px/1 'FontAwesome';text-rendering:auto;-webkit-font-smoothing:antialiased;transform:translate(0, 0);}
    .sy-grid-fixed{table-layout:fixed;}
    .sy-grid{width:100%;}
    .sy-grid > thead > tr > th, .sy-grid > tbody > tr > th{background-color:#ebf1f4;font-weight:bold;vertical-align:bottom;text-align:center;}
    .sy-grid > thead > tr > th, .sy-grid > tbody > tr > th, .sy-grid > tfoot > tr > th, .sy-grid > thead > tr > td, .sy-grid > tbody > tr > td, .sy-grid > tfoot > tr > td{padding:10px 6px;border-bottom:1px solid #bfbfbf;vertical-align:middle;}
    .sy-grid > tbody > tr:last-child > th, .sy-grid > tbody > tr:last-child > td{border-bottom:0;}
    .sy-grid-border{border:1px solid #bfbfbf;}
    .sy-grid-border > thead > tr > th + th, .sy-grid-border > tbody > tr > th + th, .sy-grid-border > tfoot > tr > th + th, .sy-grid-border > thead > tr > td + td, .sy-grid-border > tbody > tr > td + td, .sy-grid-border > tfoot > tr > td + td{border-left:1px solid #bfbfbf;}
    .sy-grid-border > thead > tr > th:first-child, .sy-grid-border > thead > tr > td:first-child, .sy-grid-border > tbody > tr > th:first-child, .sy-grid-border > tbody > tr > td:first-child{border-left:1px solid #bfbfbf;}
    .sy-grid-border > thead > tr > th:last-child, .sy-grid-border > thead > tr > td:last-child, .sy-grid-border > tbody > tr > th:last-child, .sy-grid-border > tbody > tr > td:last-child{border-right:1px solid #bfbfbf;}
    .sy-grid-border > thead > tr:first-child > th, .sy-grid-border > thead > tr:first-child > td, .sy-grid-border > tbody > tr:first-child > th, .sy-grid-border > tbody > tr:first-child > td{border-top:1px solid #bfbfbf;}
    .sy-grid-border > tbody > tr:last-child > th, .sy-grid-border > tbody > tr:last-child > td{border-bottom:1px solid #bfbfbf;}
    .sy-grid-strip > tbody > tr:nth-child(even) th, .sy-grid-strip > tbody > tr:nth-child(even) td{background-color:#f2f2f2;}
    .sy-grid-hover > tbody > tr:hover > td{background-color:#fffbe1;}
    .sy-grid-hover > tbody > tr.selected > td{background:#76a3e4;}
    .sy-grid-item > tbody > tr > th, .sy-grid-item > tbody > tr > td{padding:6px 2px;vertical-align:middle;}
    .sy-grid-sort > thead > tr > th{padding-left:3px;padding-right:10px;cursor:pointer;}
    .sy-grid-sort > thead > tr > th:after{content:"\f0dc";position:relative;left:7px;font-size:12px;font:normal normal normal 14px/1 FontAwesome;text-rendering:auto;-webkit-font-smoothing:antialiased;-moz-osx-font-smoothing:grayscale;transform:translate(0, 0);color:#1f8ccc;}
    .sy-grid-sort > thead > tr > th.up:after{content:"\f0de";}
    .sy-grid-sort > thead > tr > th.down:after{content:"\f0dd";}
    .sy-grid-expand > tbody > tr.odd{cursor:pointer;}
    .sy-grid-expand > tbody > tr.odd:hover td{background-color:#f9f9f9;}
    .sy-grid-expand > tbody > tr.odd.open td{background-color:#526b7b;color:#fff;}
    .sy-grid-expand > tbody > tr.even.open td{background-color:#f2f2f2;}
    .sy-grid-expand > tbody > tr.even td{display:none;}
    .sy-grid-expand > tbody > tr.even > td{padding:15px 10px 15px 20px;}
    .sy-grid-expand > tbody > tr.even.open td{display:table-cell;}
    .sy-grid-expand > tbody > tr > td > .icon-fold{margin-right:10px;font:normal normal normal 14px/1 FontAwesome;text-rendering:auto;-webkit-font-smoothing:antialiased;-moz-osx-font-smoothing:grayscale;transform:translate(0, 0);}
    .sy-grid-expand > tbody > tr > td >.icon-fold:before{content:"\f067";}
    .sy-grid-expand > tbody > tr.open > td >.icon-fold:before{content:"\f068";}
    .sy-checkbox{position:relative;display:inline-block;}
    .sy-checkbox input{position:absolute;overflow:hidden;clip:rect(0 0 0 0);height:1px;width:1px;margin:-1px;padding:0;border:0;}
    .sy-checkbox label{position:relative;display:inline-block;height:20px;line-height:20px;padding-left:21px;vertical-align:middle;cursor:pointer;}
    .sy-checkbox label:before{content:"\f096";color:#dcdcdc;position:absolute;left:1px;top:0;font:normal normal normal 20px/1 FontAwesome;text-rendering:auto;-webkit-font-smoothing:antialiased;-moz-osx-font-smoothing:grayscale;transform:translate(0, 0);}
    .sy-checkbox label:hover:before{color:#92c7ea;}
    .sy-checkbox input[disabled] + label:before{content:"\f096";color:#d8d8d8;}
    .sy-checkbox input[disabled] + label{color:#d8d8d8;cursor:no-drop;}
    .sy-checkbox input:checked + label:before{content:"\f14a";color:#8aa8bb;}
    .sy-checkbox input:checked:hover + label:before{color:#92c7ea;}
    .sy-checkbox input[disabled]:checked + label:before{content:"\f14a";color:#d8d8d8;}
    .sy-checkbox input[disabled]:checked + label{color:#d8d8d8;cursor:no-drop;}
    .sy-checkbox.sy-checkbox-lg label{height:24px;line-height:24px;padding-left:23px;}
    .sy-checkbox.sy-checkbox-lg label:before{font-size:24px;}
    .sy-checkbox.sy-checkbox-sm label{height:17px;line-height:17px;padding-left:16px;}
    .sy-checkbox.sy-checkbox-sm label:before{top:-1px;font-size:17px;}
    .sy-checkbox.sy-checkbox-xs label{height:12px;line-height:12px;padding-left:11px;}
    .sy-checkbox.sy-checkbox-xs label:before{top:-1px;font-size:13px;}
    .clearfix{display:block;zoom:1;}
    .clearfix:before, .clearfix:after{display:block;content:"";}
    .clearfix:after{height:0;clear:both;visibility:hidden;}
    .blue-color{color:#137aff;}
    .white-bgcolor{background:#fff !important;}
    .darkgray-bgcolor2{background:#f5f5f5 !important;}
    .darkgray-bgcolor{background:#e5e5e5 !important;}
    .unline {
        text-decoration: underline;
    }
    .item-bold{font-weight: 600;}
    .item-undl{text-decoration: underline;}
    .tc{text-align:center !important;}
    .tr{text-align:right !important;}
    .tl{text-align:left !important;}
    .m0{margin:0 !important;}
    .m5{margin:5px !important;}
    .m10{margin:10px !important;}
    .m15{margin:15px !important;}
    .m20{margin:20px !important;}
    .mt0{margin-top:0 !important;}
    .mt5{margin-top:5px !important;}
    .mt10{margin-top:10px !important;}
    .mt15{margin-top:15px !important;}
    .mt20{margin-top:20px !important;}
    .mr0{margin-right:0 !important;}
    .mr5{margin-right:5px !important;}
    .mr10{margin-right:10px !important;}
    .mr15{margin-right:15px !important;}
    .mr20{margin-right:20px !important;}
    .mb0{margin-bottom:0 !important;}
    .mb5{margin-bottom:5px !important;}
    .mb10{margin-bottom:10px !important;}
    .mb15{margin-bottom:15px !important;}
    .mb20{margin-bottom:20px !important;}
    .ml0{margin-left:0 !important;}
    .ml5{margin-left:5px !important;}
    .ml10{margin-left:10px !important;}
    .ml15{margin-left:15px !important;}
    .ml20{margin-left:20px !important;}
    .p0{padding:0 !important;}
    .p5{padding:5px !important;}
    .p10{padding:10px !important;}
    .p15{padding:15px !important;}
    .p20{padding:20px !important;}
    .pt0{padding-top:0 !important;}
    .pt5{padding-top:5px !important;}
    .pt10{padding-top:10px !important;}
    .pt15{padding-top:15px !important;}
    .pt20{padding-top:20px !important;}
    .pr0{padding-right:0 !important;}
    .pr5{padding-right:5px !important;}
    .pr10{padding-right:10px !important;}
    .pr15{padding-right:15px !important;}
    .pr20{padding-right:20px !important;}
    .pb0{padding-bottom:0 !important;}
    .pb5{padding-bottom:5px !important;}
    .pb10{padding-bottom:10px !important;}
    .pb15{padding-bottom:15px !important;}
    .pb20{padding-bottom:20px !important;}
    .pl0{padding-left:0 !important;}
    .pl5{padding-left:5px !important;}
    .pl10{padding-left:10px !important;}
    .pl15{padding-left:15px !important;}
    .pl20{padding-left:20px !important;}
    .lh120p{line-height:120% !important;}
    .lh140p{line-height:140% !important;}
    .lh160p{line-height:160% !important;}
    .font18{font-size: 18px!important;}

    /* @page {size:210mm 297mm portrait;margin:22mm 22mm 42mm;} */
    .page{width: 210mm;min-height: 297mm;padding: 20mm;margin: 10mm auto;border: 1px #D3D3D3 solid;
        border-radius: 5px;background: #fff;box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);}
    @page {size:210mm 297mm portrait;margin:0;}
    @media print{
        .page {width: initial;min-height: initial;margin: 0;border: initial;
            border-radius: initial;background: initial;box-shadow: initial;page-break-after: always;}
        *{-webkit-print-color-adjust:exact;}
    }

    .docmngmt-wrap h1{margin:0 0 40px;letter-spacing:4px;}
    .docmngmt-wrap h1 sub{float: right;font-size: 10pt;font-weight: normal;}
    .docmngmt-wrap .sy-grid th, .docmngmt-wrap .sy-grid td, .docmngmt-wrap .sy-grid td>*{font-size: 9.5pt;}
    .docmngmt-wrap h2{margin-top:50px;font-size: 11pt;}
    .docmngmt-wrap h2 span{display: inline-block;}
    .docmngmt-wrap h2 span:first-child{float: left;}
    .docmngmt-wrap h2 span:last-child{float: right;}
    .docmngmt-wrap .docmngmt-wrap_date{margin: 60px 0;font-size: 11pt;}
    .docmngmt-wrap .page:last-child p{text-align: justify;}
</style>

<script>
    import LoadingSpinner from '@/components/common/AgreeLoadingSpinner.vue';

    export default {
        components: { LoadingSpinner },
        name: "CustCntrct",
        data() {
            return {
                custCntrct: {},
                loginInfo: {},
                loading: true,
                cntrctHtml: "",
            };
        },

        async created() {
            await this.getCustCntrct();
            this.loading = false;
        },

        methods: {

            setDynamicViewport() {
                const viewportMeta = document.getElementById('dynamic-viewport');
                if (viewportMeta) {
                    viewportMeta.setAttribute('content', '');
                }
            },

            async getCustCntrct() {
                try {
                    console.log(this.$route.params.id);
                    var param = {
                        id: this.$route.params.id
                    };
                    const response = await this.$post('/api/cust/custSubscriptionCntrct',param);
                        if(response.custSubscriptionCntrct.length > 0){
                            this.custSubscriptionCntrct = response.custSubscriptionCntrct[0];
                            //var today = this.$moment();
                            var custtmplHtml = this.custSubscriptionCntrct.cntrctHtml;
                            custtmplHtml = custtmplHtml.replaceAll('#{planNm}', this.custSubscriptionCntrct.planNm);
                            this.formatInput(function(o) {
                                custtmplHtml = custtmplHtml.replaceAll('#{planPrice}', o);
                            }, this.custSubscriptionCntrct.rcptmnAmt);
                            custtmplHtml = custtmplHtml.replaceAll('#{subStrtDt}',  this.$moment(this.custSubscriptionCntrct.subStrtDt).format('YYYY년 MM월 DD일'));
                            custtmplHtml = custtmplHtml.replaceAll('#{subEndDt}', this.$moment(this.custSubscriptionCntrct.subEndDt).format('YYYY년 MM월 DD일'));
                            //custtmplHtml = custtmplHtml.replaceAll('#{todayTime}', today.format('HH:mm'));

                            //console.log(this.custSubscriptionCntrct.cntrctHtml);
                            this.cntrctHtml = custtmplHtml;

                        }
                    //console.log(this.loginInfo.cmpNm);


                    //console.log( this.cntrctHtml );

                } catch (error) {
                    console.log(error);
                }
            },
            formatInput(callback, val) {
                // 입력된 값을 숫자로 파싱하고 천 단위 콤마 추가
                const parsedAmount = parseFloat(val.toString().replace(/,/g, "")) || 0;

                callback(parsedAmount.toLocaleString("en-US"));
            },

        }
    }
</script>
