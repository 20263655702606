<template>
    <div class="join-complete__wrap">
        <div class="join-complete__container">
            <p class="txt__title">
                회원가입이 완료되었습니다.<br> 비드톡톡 회원이 되신 것을 환영합니다.
            </p>
            <ul class="join-complete__group explan">
                <li class="join-complete__group--title">지금 바로 로그인하셔서<br>비드톡톡의 주요 기능인 <span>맞춤공고와<br>가격 분석요청 서비스</span>를<br> 이용하세요!</li>
                <li class="join-complete__group--img"></li>
<!--                <li class="join-complete__group&#45;&#45;sub">비드톡톡 앱을 설치해 주세요.</li>-->
                <li class="join-complete__group--box">
                    <!-- <p class="txt__title">비드톡톡의 주요 기능인 <span class="empha">공고검색과<br> 가격분석요청 등은 앱에서만</span> 가능합니다.</p> -->
                    <p class="txt__title">비드톡톡 앱 다운로드 링크를 문자로 발송해드렸습니다.<br>비드톡톡은 PC와 앱 연동이 최적화되어있어 어떤 화면에서든지 편리하게<br>사용하실 수 있습니다.</p>
                    <!-- <p class="txt__sub"> 앱 다운로드 링크를 문자로 발송해드렸으니,<br>링크를 클릭하시어 앱을 설치하시고<br>비드톡톡의 다양한 기능을 이용해보세요! </p> -->
                </li>
            </ul>
            <a href="/home" class="btn-basic btn-cta join-complete__link">로그인하기</a>
        </div>
    </div>
</template>

<script>

  export default {
    name: 'JoinComplete',
  }

</script>
